import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Identity } from '../../../../models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { ArchivoGeneral } from '../../../../models/archivo-general';
import { ArchivosGeneralesService } from '../../../../services/archivos-generales.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { Catalogo } from '../../../../models/catalogo';
import { Directorio } from '../../../../models/directorio';
import { Pagination } from '../../../../models/pagination';
import Swal from 'sweetalert2';
import { ObjDropTranfers } from '../../../../decorations/drag-drop-folder-file.directive';
import { MESSAGES } from 'src/app/models/messages';
import { Download, DownloadFileService } from 'src/app/services/download-file.service';
import { GLOBAL } from 'src/app/models/global';
import { AuthService } from 'src/app/services/auth/auth.service';
declare function customInitFunctions();

@Component({
  selector: 'app-edit-archivos',
  templateUrl: './edit-archivos.component.html',
  styleUrls: ['./edit-archivos.component.scss']
})
export class EditArchivosComponent implements OnInit, OnDestroy {
  public files: ArchivoGeneral[] = [];
  public currentFiles: ArchivoGeneral[] = [];
  progress = 0;
  etiquetas = '';
  url = GLOBAL.url || '';
  toggleMobileSidebar: any;
  public archivo: ArchivoGeneral = new ArchivoGeneral(null);
  public archivosTemp: ArchivoGeneral[] = [];
  public identity: Identity = new Identity(null);
  private subscripcionAuth: Subscription = new Subscription();
  private subscripcionDirtectorio: Subscription = new Subscription();
  private subscripcionDepartamento: Subscription = new Subscription();
  public folderPath = '';
  public departamentos: Catalogo[] = [];
  directorio: Directorio = new Directorio();
  currentDirectorio: Directorio = new Directorio();
  breadcrumb: string[] = [];
  public actual = 0;
  public paginacion: Pagination = new Pagination();
  public listSearch: string[] = [];
  public buscador = false;
  public moviendo: ObjDropTranfers = { files: [], archivoMovido: null, folderDestino: null, folderMovido: null};
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };
  downloadState: Download = { state: null, content: null, progress: null };
  downloading = false;
  loadedTree = false;

  constructor(
    private archivosService: ArchivosGeneralesService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private downloadService: DownloadFileService,
    private authService: AuthService
  ) {
    this.store.dispatch(new usuariosActions.ConsultaDepartamentos());
    this.store.dispatch(new usuariosActions.CargarDirectoriosArchivoGeneral());
    this.store.dispatch(new usuariosActions.CargarArchivosGeneral());
  }

  ngOnInit() {
    this.subscripcionAuth = this.store.select('auth').subscribe( state => {
      this.identity = state.identity;
    });
    this.subscripcionDirtectorio = this.store.select('archivos').subscribe( state => {
      this.archivo = state.archivo;
      this.files = state.archivos;
      this.directorio = state.directorio;
      if (state.loadedDirectorios) {
        this.chargeListFiles(this.currentDirectorio.id);
        this.cargarTree();
      }
    });
    this.subscripcionDepartamento = this.store.select('consulta').subscribe(
      (state) => {
        this.departamentos = state.departamentos;
      }
    );
    this.route.params.subscribe(params => {
      const path = params.id;
      if (path) {
        this.folderPath = path;
      } else {
        this.folderPath = '';
      }
    });
  }

  ngOnDestroy() {
    this.subscripcionAuth.unsubscribe();
    this.subscripcionDirtectorio.unsubscribe();
    this.subscripcionDepartamento.unsubscribe();
  }

  cargarTree() {
    if (!this.loadedTree) {
      this.loadedTree = true;
      setTimeout( () => {
        customInitFunctions();
      }, 3000);
    }
  }

  uploadFile(folder: Directorio, event) {
    console.log(folder, event);
    const archivos: File[] = Array.from(event);
    if (archivos && archivos.length) {
      const validaImage = true;
      // archivos = archivos.filter(element => {
      //   const mimeType = element.type;
      //   if (mimeType.match(/[*]+\/*/) == null) {
      //     validaImage = false;
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
      this.archivosTemp = archivos.map( (item) => {
        const otro = new ArchivoGeneral(null);
        // const reader = new FileReader();
        // reader.readAsDataURL(item);
        // reader.onload = (_event) => {
        //   otro.urlPath = reader.result;
        // }
        otro.type = item.type.split('/')[1] || item.name.split('.').pop();
        otro.mimeType = item.type || item.name.split('.').pop();
        otro.size = item.size;
        otro.name = item.name;
        otro.file = item;
        otro.directorio = folder.id;
        otro.folderName = folder.nombre;
        otro.folderPath = folder.path;
        otro.fecArchivo = new Date(item.lastModified);
        return otro;
      });
      this.archivosTemp = this.archivosTemp.filter( itm => !this.files.some( element => {
        return element.name === itm.name && element.type === itm.type && element.folderPath === itm.folderPath;
      }));
      // // this.files = [ ...this.archivosTemp, ...this.files ];
      // // this.currentFiles = this.files.filter( itm => itm.directorio === this.currentDirectorio.id);
      this.archivosTemp.forEach( (item, index, ar) => {
        item.loading = true;
        this.archivosService.addFileTransfer(item).subscribe(
          (respuesta) => {
          if (respuesta.progress) {
            item.progressTotal = respuesta.total;
            item.progress = respuesta.progress;
            if (item.progress >= item.progressTotal) {
              item.loaded = true;
              item.loading = false;
            }
          }
        }, error => {
          item.error = true;
          item.loaded = true;
          item.loading = false;
        }, () => {
          item.progress = item.progressTotal;
          item.loaded = true;
          item.loading = false;
          this.currentFiles = this.files.filter( itm => itm.directorio === this.currentDirectorio.id);
          if (ar.every(element => element.loaded)) {
            setTimeout( () => {
              this.archivosTemp = [];
            }, 1500);
            this.store.dispatch(new usuariosActions.CargarArchivosGeneral());
            this.store.dispatch(new usuariosActions.CargarDirectoriosArchivoGeneral());
          }
        });
      });
    } else {
      if (this.moviendo.archivoMovido && this.moviendo.archivoMovido.id) {
        console.log('movio archivo');
        // corregir esto
        this.archivosService.moverArchivo(this.moviendo.archivoMovido, folder).subscribe(
          respuesta => {
            console.log('ok');
            this.store.dispatch(new usuariosActions.CargarArchivosGeneral());
            this.store.dispatch(new usuariosActions.CargarDirectoriosArchivoGeneral());
          }, error => {
            console.log('no')
        });
        // this.files = this.files.map( element => {
        //   if (element.id === this.moviendo.archivoMovido.id) {
        //     element.folderName = folder.nombre;
        //     element.folderPath = folder.path;
        //     element.directorio = folder.id;
        //   }
        //   return element;
        // });
        // this.currentFiles = this.files.filter( itm => itm.directorio === this.currentDirectorio.id);
      } else if (this.moviendo.folderMovido && this.moviendo.folderMovido.id) {
        console.log('movio carpeta');
        // Corregir esto
        this.archivosService.moverCarpeta(this.moviendo.folderMovido, folder).subscribe(
          respuesta => {
            console.log('ok');
            this.store.dispatch(new usuariosActions.CargarArchivosGeneral());
            this.store.dispatch(new usuariosActions.CargarDirectoriosArchivoGeneral());
          }, error => {
            console.log('no')
        });
        // this.files = this.files.map( element => {
        //   if (element.directorio === this.moviendo.folderMovido.id) {
        //     element.folderName = folder.nombre;
        //     element.folderPath = folder.path;
        //     element.directorio = folder.id;
        //     element.path = folder.path + '/' + element.name;
        //   }
        //   return element;
        // });
        // this.directorio.directorios.forEach( itm => {
        //   if (folder.id === itm.id) {
        //     itm.directorios.push(this.moviendo.folderMovido);
        //   }
        // });
        // this.directorio.directorios = this.directorio.directorios.map( itm => {
        //   if (this.moviendo.folderMovido.id === itm.id) {
        //     itm.path = folder.path + '/' + itm.nombre;
        //   }
        //   return itm;
        // });
        // this.currentDirectorio.directorios = this.currentDirectorio.directorios.filter( dir => {
        //   if (dir.id === this.moviendo.folderMovido.id) {
        //     return false;
        //   }
        //   return true;
        // });
        // this.currentFiles = this.files.filter( itm => itm.directorio === this.currentDirectorio.id);
      }
    }
    this.moviendo = { files: [], archivoMovido: null, folderDestino: null, folderMovido: null};
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  validaImagenes() {
    return new Promise( (resolve, reject) => {
      if (!this.files.length) {
        this.toastr.warning('Agrega al menos un archivo.');
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  progressPorcent(item: ArchivoGeneral): number {
    if (item.progress && item.progressTotal) {
      return Math.round(item.progress / item.progressTotal * 100);
    } else {
      return 0;
    }
  }

  quitarTodas() {
    this.files = [];
  }

  chargeListFiles(event: number) {
    this.currentFiles = this.files.filter( itm => itm.directorio === event);
    this.currentDirectorio = this.searchTree(this.directorio, 'id', event);
    this.actual = this.currentDirectorio.id;
    this.breadcrumb = this.currentDirectorio.path.split('/').filter( item => item);
  }

  breadcrumbNav(index: number) {
    this.breadcrumb = this.breadcrumb.splice(0, index + 1);
    const breadcrumbPath = this.breadcrumb.join('/');
    this.currentDirectorio = this.searchTree(this.directorio, 'path', breadcrumbPath);
    this.actual = this.currentDirectorio.id;
    this.currentFiles = this.files.filter( itm => itm.directorio === this.currentDirectorio.id);
  }

  searchTree(element: Directorio, param: string, value: any): Directorio {
    if(element[param] === value) {
      return element;
    } else if (element.directorios.length) {
      let result = null;
      element.directorios.some( item => {
        result = this.searchTree(item, param, value);
        if (result && result.id) {
          return result;
        }
      });
      return result;
    } else {
      return new Directorio();
    }
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(',').map( item => item.trim() );
    this.buscador = true;
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(',').trim();
    this.buscador = true;
  }

  crearCarpeta(id: number, nombreCarpeta?: string) {
    Swal.fire({
      title: 'Nombre de carpeta',
      input: 'text',
      inputValue: nombreCarpeta,
      inputAttributes: {
        autocapitalize: 'on',
        max: '100',
        min: '2'
      },
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      showCancelButton: true,
      confirmButtonText: 'Crear carpeta',
      showLoaderOnConfirm: true,
      preConfirm: (texto) => {
        return new Promise( (resolve, reject) => {
          texto = texto.trim();
          if (nombreCarpeta.toLowerCase() === texto.toLowerCase()) {
            Swal.close();
            resolve(false);
          } else if (this.currentDirectorio.directorios.every(itm => itm.nombre !== texto)) {
            const prevPath = this.currentDirectorio.path && this.currentDirectorio.path !== '/' ? this.currentDirectorio.path + '/' + nombreCarpeta : nombreCarpeta;
            this.archivosService.registrarDirectorio(this.currentDirectorio, new Directorio({id, nombre: texto, path: prevPath, nombreAnterior: nombreCarpeta}))
            .subscribe( respuesta => {
              resolve(respuesta.directorio);
            }, error => {
              Swal.showValidationMessage(
                `Ocurrio un error`
              );
              resolve(false);
            });
          } else {
            Swal.showValidationMessage(
              `Ya existe una carpeta con ese nombre`
            );
            resolve(false);
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.directorio = {...result.value};
        this.chargeListFiles(this.actual);
        Swal.fire({
          title: `Carpeta creada`,
          timer: 1500
        })
      }
    });
  }

  moviendoFiles(event: ObjDropTranfers){
    this.moviendo = event;
  }

  // updateFolderTree(element: Directorio, param: string, value: any, newPath: string): Directorio {
  //   if(element[param] === value) {
  //     return element;
  //   } else if (element.directorios.length) {
  //     let result = null;
  //     element.directorios.some( item => {
  //       result = this.searchTree(item, param, value);
  //       if (result && result.id) {
  //         return result;
  //       }
  //     });
  //     return result;
  //   } else {
  //     return new Directorio();
  //   }
  // }

  eliminarCarpeta(directorioSeleccionado: Directorio) {
    Swal.fire({
      title: '¿Deseas eliminar esta carpeta?',
      text: 'Si eliminas esta carpeta, eliminarás todo su contenido de archivos y subcarpetas sin poder recuperarlos',
      confirmButtonColor: MESSAGES.C_BTN_DANGER,
      confirmButtonText: 'Eliminar',
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise( (resolve) => {
          this.archivosService.eliminarDirectorio(directorioSeleccionado)
          .subscribe( respuesta => {
            resolve(respuesta.directorio);
          }, error => {
            Swal.showValidationMessage(
              `Ocurrio un error`
            );
            resolve(false);
          })
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.directorio = {...result.value};
        this.chargeListFiles(this.actual);
        Swal.fire({
          title: `Carpeta eliminada`,
          timer: 1500
        })
      }
    });
  }

  eliminarFile(archivo: ArchivoGeneral) {
    Swal.fire({
      title: '¿Deseas eliminar este archivo?',
      confirmButtonColor: MESSAGES.C_BTN_DANGER,
      confirmButtonText: 'Eliminar',
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise( (resolve) => {
          this.archivosService.eliminarArchivo(archivo)
          .subscribe( respuesta => {
            resolve(respuesta.archivo);
          }, error => {
            Swal.showValidationMessage(
              `Ocurrio un error`
            );
            resolve(false);
          })
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.files = this.files.filter( itm => itm.id !== archivo.id);
        this.chargeListFiles(this.actual);
        Swal.fire({
          title: `Archivo eliminado`,
          timer: 1500
        })
      }
    });
  }

  download(name: string, url: string) {
    this.downloadService.download(url, name).subscribe( respuesta => {
      this.downloading = true;
      this.downloadState = respuesta;
      if (respuesta.state === 'DONE') {
        setTimeout( () => {
          this.downloading = false;
        }, 2000 );
      }
    }, error => {
      this.downloading = false;
    });
  }

}
