import * as fromArchivos from '../actions/archivos-generales.actions';
import { ArchivoGeneral } from '../../models/archivo-general';
import { Directorio } from 'src/app/models/directorio';

export interface ArchivoGeneralState {
  archivos: ArchivoGeneral[];
  archivo: ArchivoGeneral;
  directorio: Directorio;
  loaded: boolean;
  loadedDirectorios: boolean;
  loading: boolean;
  error: any;
}

const initState: ArchivoGeneralState = {
  archivos: [],
  archivo: new ArchivoGeneral(null),
  directorio: new Directorio(),
  loaded: false,
  loadedDirectorios: false,
  loading: false,
  error: null
};

export function archivosGeneralesReducer( state = initState, action: fromArchivos.archivosGeneralesAcciones ): ArchivoGeneralState {

  switch ( action.type ) {
    case fromArchivos.CARGAR_ARCHIVOS_GENERALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_ARCHIVOS_GENERALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        archivos: [...action.archivos]
      };
    case fromArchivos.CARGAR_ARCHIVOS_GENERALES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.CARGAR_ARCHIVO_GRAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_ARCHIVO_GRAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        archivo: {...action.archivo}
      };
    case fromArchivos.CARGAR_ARCHIVO_GRAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.REGISTRAR_ARCHIVO_GENERAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.REGISTRAR_ARCHIVO_GENERAL_SUCCESS:
      const item = state.archivos.find( element => element.id === action.archivo.id);
      if (item && item.id && item.id > 0) {
        state.archivos = state.archivos.map(
          element => {
            if (element.id === action.archivo.id) {
              return action.archivo;
            }
            return element;
          }
        );
      } else {
        state.archivos.push({...action.archivo});
      }
      if (state.archivo.id === action.archivo.id) {
        state.archivo = {...action.archivo};
      }
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.REGISTRAR_ARCHIVO_GENERAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.ELIMINAR_ARCHIVO_GRAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.ELIMINAR_ARCHIVO_GRAL_SUCCESS:
      state.archivos = state.archivos.filter( respuesta => respuesta.id !== action.archivo.id );
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.ELIMINAR_ARCHIVO_GRAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadedDirectorios: true,
        directorio: {...action.directorio}
      };
    case fromArchivos.CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL_FAIL:
      return {
        ...state,
        loadedDirectorios: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadedDirectorios: true,
        directorio: {...action.directorio}
      };
    case fromArchivos.ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL_FAIL:
      return {
        ...state,
        loadedDirectorios: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadedDirectorios: true,
        directorio: {...action.directorio}
      };
    case fromArchivos.REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL_FAIL:
      return {
        ...state,
        loadedDirectorios: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}
