import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from './../../services/auth/auth-guard.service';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SettingProfileComponent } from './users/setting-profile/setting-profile.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { MetaGuard } from '@ngx-meta/core';
import { AccessUserService } from 'src/app/services/auth/access-user.service';
import { NotAuthorizationComponent } from './not-authorization/not-authorization.component';
import { NotFoundComponent } from 'src/app/layout/components/not-found/not-found.component';
import { UsuariosConfigComponent } from './config/usuarios-config/usuarios-config.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ListaArchivosComponent } from './lista-archivos/lista-archivos.component';
import { ContactosComponent } from './contactos/contactos.component';
import { EditContactoComponent } from './contactos/edit-contacto/edit-contacto.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { EmpresasEditComponent } from './empresas/empresas-edit/empresas-edit.component';
import { IndustriasComponent } from './catalogos/industrias/industrias.component';
// import { TipoEmpresasComponent } from './catalogos/tipo-empresas/tipo-empresas.component';
import { EmpresaProfileComponent } from './empresas/empresa-profile/empresa-profile.component';
import { OportunidadesComponent } from './oportunidades/oportunidades.component';
import { OportunidadesEditComponent } from './oportunidades/oportunidades-edit/oportunidades-edit.component';
import { OrigenContactosComponent } from './catalogos/origen-contactos/origen-contactos.component';
import { AllcampanasComponent } from './marketing/allcampanas/allcampanas.component';
import { NuevacampanaComponent } from './marketing/nuevacampana/nuevacampana.component';
import { PlantillasComponent } from './marketing/plantillas/plantillas.component';
import { GuardarYEnviarCampanaComponent } from './marketing/guardar-y-enviar-campana/guardar-y-enviar-campana.component';
import { RepositoriosEditorialComponent } from './repositorios-editorial/repositorios-editorial.component';
import { EditRepositorioComponent } from './repositorios-editorial/edit-repositorio/edit-repositorio.component';
import { PaisesComponent } from './catalogos/paises/paises.component';
import { ContactoProfileComponent } from './contactos/contacto-profile/contacto-profile.component';
import { EditarCampanaComponent } from './marketing/editar-campana/editar-campana.component';
import { OportunidadProfileComponent } from './oportunidades/oportunidad-profile/oportunidad-profile.component';
import { NuevaplantillaComponent } from './marketing/plantillas/nuevaplantilla/nuevaplantilla/nuevaplantilla.component';
import { TicketsComponent } from './tickets/tickets.component';
import { DetailsTicketsComponent } from './tickets/details-tickets/details-tickets.component';
import { VerAlbumRepositoriosComponent } from './repositorios-editorial/ver-album-repositorios/ver-album-repositorios.component';
import { EditArchivosComponent } from './lista-archivos/edit-archivos/edit-archivos.component';
import { CargaContactosComponent } from './contactos/carga-contactos/carga-contactos.component';
import { KpisVendedoresComponent } from './kpis-vendedores/kpis-vendedores.component';
import { ReporteKpisVendedoresComponent } from './reportes/reporte-kpis-vendedores/reporte-kpis-vendedores.component';
import { EditarPlantillaComponent } from './marketing/plantillas/editar-plantilla/editar-plantilla.component';
import { TicketsEditComponent } from './tickets/tickets-edit/tickets-edit.component';
import { DepartamentosComponent } from './catalogos/departamentos/departamentos.component';
import { ReporteSepomexComponent } from './reportes/reporte-sepomex/reporte-sepomex.component';
import { ReporteCumpleannosComponent } from './reportes/reporte-cumpleannos/reporte-cumpleannos.component';
import { ReporteVentasEtapasComponent } from './reportes/reporte-ventas-etapas/reporte-ventas-etapas.component';
import { ReporteVentasPropietariosComponent } from './reportes/reporte-ventas-propietarios/reporte-ventas-propietarios.component';
import { ReportesListComponent } from './reportes/reportes-list.component';
import { ReporteAutoActualizacionComponent } from './reportes/reporte-auto-actualizacion/reporte-auto-actualizacion.component';
import { ReportesCampanaComponent } from './marketing/reportes-campana/reportes-campana.component';
import { ActividadesPendientesComponent } from './actividades-pendientes/actividades-pendientes.component';

export const authRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [ AuthGuardService ]},
  { path: 'change-password', component: ChangePasswordComponent, data: {extraParameter: 'dashboardsMenu'},
    canActivate: [ AuthGuardService ]},
  { path: 'setting-profile', component: SettingProfileComponent, data: {extraParameter: 'dashboardsMenu'},
    canActivate: [ AuthGuardService ]},
  { path: 'kpis-vendedores', component: KpisVendedoresComponent, data: {extraParameter: 'dashboardsMenu'},
    canActivate: [ AuthGuardService ]},
  { path: 'reportes', component: ReportesListComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptOportunidadEtapas', 'rptOportunidadPropietarios', 'rptCumpleannos', 'rptSepomex', 'rptKpiVendedores' ] } },
  { path: 'reportes/oportunidad/etapas', component: ReporteVentasEtapasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptOportunidadEtapas' ] } },
  { path: 'reportes/oportunidad/propietarios', component: ReporteVentasPropietariosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptOportunidadPropietarios' ] } },
  { path: 'reportes/cumpleannos', component: ReporteCumpleannosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptCumpleannos' ] } },
  { path: 'reportes/sepomex', component: ReporteSepomexComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptSepomex' ] } },
  { path: 'reportes/kpis-vendedores', component: ReporteKpisVendedoresComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptKpiVendedores' ] } },
  { path: 'users', component: ListUsersComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [ AuthGuardService ]},
  { path: 'users/register/:id', component: EditUserComponent, data: {extraParameter: 'dashboardsMenu'}, canActivate: [ AuthGuardService ]},
  { path: 'usuarios', component: ListUsersComponent, canActivate: [AccessUserService], canActivateChild: [MetaGuard],
    data: { validate: [ 'usuariosListVer', 'usuariosListEdit' ] } },
  { path: 'usuarios/register/:id', component: EditUserComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'usuariosRegVer', 'usuariosRegEdit' ] } },
  { path: 'profile', component: SettingProfileComponent, canActivate: [AccessUserService], canActivateChild: [MetaGuard] },
  { path: 'notificaciones', component: NotificacionesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AccessUserService] },
  { path: 'not-authorization', component: NotAuthorizationComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'config/usuarios', component: UsuariosConfigComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard] },
  { path: 'archivos', component: EditArchivosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'archivosRegVer', 'archivosRegEdit' ] } },
  { path: 'archivos/register/:id', component: ListaArchivosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'archivosRegVer', 'archivosRegEdit' ] } },
  { path: 'contactos/register/:id', component: EditContactoComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'contactosRegVer', 'contactosRegEdit' ] } },
  { path: 'contactos/load/register', component: CargaContactosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'contactosRegVer', 'contactosRegEdit' ] } },
  { path: 'contactos', component: ContactosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'contactosListVer', 'contactosListEdit' ] } },
  { path: 'empresas', component: EmpresasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'empresasListEdit', 'empresasListVer' ] } },
  { path: 'empresas/register/:id', component: EmpresasEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'empresasRegEdit', 'empresasRegVer' ] } },
  { path: 'empresas/profile/:id', component: EmpresaProfileComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesListEdit', 'actividadesListVer' ] } },
  { path: 'catalogos/industrias', component: IndustriasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catIndustriasVer', 'catIndustriasEdit' ] } },
  { path: 'marketing/allcampanas', component: AllcampanasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'campannasVer', 'campannasEdit' ] } },
  { path: 'marketing/nuevacampana', component: NuevacampanaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'campannasEdit' ] } },
  { path: 'marketing/editar-campana/:id', component: EditarCampanaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'campannasVer', 'campannasEdit' ] } },
  { path: 'marketing/plantillas', component: PlantillasComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'plantillasVer', 'plantillasEdit' ] } },
  { path: 'marketing/reportes/:id', component: ReportesCampanaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptCampanas' ] } },
  { path: 'marketing/guardar-y-enviar-campana/:id', component: GuardarYEnviarCampanaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'campannasVer', 'campannasEdit' ] } },
  { path: 'marketing/plantillas/nueva-plantilla', component: NuevaplantillaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'plantillasVer', 'plantillasEdit' ] } },
  { path: 'marketing/plantillas/editar-plantilla/:id', component: EditarPlantillaComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'plantillasVer', 'plantillasEdit' ] } },
  // { path: 'catalogos/tipo-empresas', component: TipoEmpresasComponent, canActivate: [AccessUserService],
  //   canActivateChild: [MetaGuard], data: { validate: [ 'catTipoEmpresasVer', 'catTipoEmpresasEdit' ] } },
  { path: 'oportunidades', component: OportunidadesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'oportunidadesListEdit', 'oportunidadesListVer' ] } },
  { path: 'oportunidades/register/:id', component: OportunidadesEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'oportunidadesRegEdit', 'oportunidadesRegVer' ] } },
  { path: 'catalogos/origen-contactos', component: OrigenContactosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catOrigenesVer', 'catOrigenesEdit' ] } },
  { path: 'repositorios', component: RepositoriosEditorialComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'repositoriosListEdit', 'repositoriosListVer' ] } },
  { path: 'repositorios/register/:id', component: EditRepositorioComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'repositoriosListEdit', 'repositoriosListVer' ] } },
  { path: 'repositorios/album/:id', component: VerAlbumRepositoriosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'repositoriosListEdit', 'repositoriosListVer' ] } },
  { path: 'catalogos/paises', component: PaisesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catPaisesVer', 'catPaisesEdit' ] } },
  { path: 'contactos/profile/:id', component: ContactoProfileComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesListEdit', 'actividadesListVer' ] } },
  { path: 'oportunidades/profile/:id', component: OportunidadProfileComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesListEdit', 'actividadesListVer' ] } },
  { path: 'tickets', component: TicketsComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'ticketsVer', 'ticketsEdit' ] } },
  { path: 'tickets/detail/:id', component: DetailsTicketsComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'ticketsVer', 'ticketsEdit' ] } },
  { path: 'tickets/register/:id', component: TicketsEditComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'ticketsVer', 'ticketsEdit' ] } },
  { path: 'catalogos/departamentos', component: DepartamentosComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'catDepartamentosVer', 'catDepartamentosEdit' ] } },
  { path: 'reportes/auto-actualizacion', component: ReporteAutoActualizacionComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'rptAutoActualizacion' ] } },
  { path: 'actividades/programadas', component: ActividadesPendientesComponent, canActivate: [AccessUserService],
    canActivateChild: [MetaGuard], data: { validate: [ 'actividadesListEdit', 'actividadesListVer' ] } },
  { path: '**', component: NotFoundComponent }
];
