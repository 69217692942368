import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuarioActions from 'src/app/store/actions';
import { TypeAlert } from 'src/app/store/reducers';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  token: string;

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
    this.authService.getToken().subscribe( (value) => {
      this.token = value;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `${localStorage.getItem('token')}`
      }
    });
    this.store.dispatch(new usuarioActions.ActivarLoadingAction(request.url));
    return next.handle(request).do(
      (event: HttpEvent<any>) => {

      }, (err: any) => {
        this.store.dispatch(new usuarioActions.DesactivarLoadingAction(request.url));
        if (err instanceof HttpErrorResponse) {
          console.log('Error http: ');
          console.log(err);
          if (err.status === 401) {
            this.store.dispatch(new usuarioActions.UnsetAuthAction());
          } else if (err.error && err.error.status && err.error.title && err.error.type ) {
            switch (err.error.type) {
              case TypeAlert.ERROR:
                this.toastr.error(err.error.message, err.error.title);
                break;
              case TypeAlert.SUCCESS:
                this.toastr.success(err.error.message, err.error.title);
                break;
              case TypeAlert.INFO:
                this.toastr.info(err.error.message, err.error.title);
                break;
              case TypeAlert.WARNING:
                this.toastr.warning(err.error.message, err.error.title);
                break;
              default:
                break;
            }
          } else {
            this.toastr.error('Ocurrió un error inesperado', 'Error');
          }
          this.authService.collectFailedRequest(request);
        }
      }, () => {
        this.store.dispatch(new usuarioActions.DesactivarLoadingAction(request.url));
      }
    );
  }

}
