import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';
import { Campana } from '../models/campana';

@Injectable({
  providedIn: 'root'
})
export class CampanasService {

  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerCampanas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/campanas', {headers});
  }

  DuplicarCampanas(campana: Campana): Observable<any> {
    const param = JSON.stringify({campana});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/campanas/duplicar/' + campana.id, param, {headers});   
  }

  guardarCampanas(campana: Campana): Observable<any> {
    const param = JSON.stringify({campana});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/campanas', param, {headers});   
  }

  EliminarCampana(id: number): Observable<any> {
    const param = JSON.stringify({id});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/campanas/eliminar/' + id, param,  {headers});
  }

  obtenerEstatusCampanaAutoActualizacion(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/campana-auto-actualizacion/estatus', {headers});   
  }

  actualizarEstatusCampanaAutoActualizacion(estatus: boolean, clave: string): Observable<any> {
    estatus = !estatus;
    const param = JSON.stringify({estatus, clave});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/campana-auto-actualizacion/estatus', param, {headers});   
  }

}
