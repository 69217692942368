import { Action } from '@ngrx/store';
import { GaleriaRepositorio } from '../../models/galeria-repositorio';
import { Album } from '../../models/album';

export const CARGAR_GALERIA_REPOSITORIO_TODAS = '[Galeria Repositorio] Cargar galeria de repositorio';
export const CARGAR_GALERIA_REPOSITORIO_TODAS_SUCCESS = '[Galeria Repositorio] Cargar galeria de repositorio success';
export const CARGAR_GALERIA_REPOSITORIO_TODAS_FAIL = '[Galeria Repositorio] Cargar galeria de repositorio fail';
export const ELIMINAR_IMAGEN_GALERIA_REPOSITORIO = '[Galeria Repositorio] Eliminar imagen';
export const ELIMINAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS = '[Galeria Repositorio] Eliminar imagen success';
export const ELIMINAR_IMAGEN_GALERIA_REPOSITORIO_FAIL = '[Galeria Repositorio] Eliminar imagen fail';
export const CARGAR_IMAGEN_GALERIA_REPOSITORIO = '[Galeria Repositorio] Cargar imagen';
export const CARGAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS = '[Galeria Repositorio] Cargar imagen success';
export const CARGAR_IMAGEN_GALERIA_REPOSITORIO_FAIL = '[Galeria Repositorio] Cargar imagen fail';
export const REGISTRAR_IMAGEN_GALERIA_REPOSITORIO = '[Galeria Repositorio] Registrar imagen';
export const REGISTRAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS = '[Galeria Repositorio] Registrar imagen success';
export const REGISTRAR_IMAGEN_GALERIA_REPOSITORIO_FAIL = '[Galeria Repositorio] Registrar imagen fail';
export const REGISTRAR_GALERIA_REPOSITORIO = '[Galeria Repositorio] Registrar galeria';
export const REGISTRAR_GALERIA_REPOSITORIO_SUCCESS = '[Galeria Repositorio] Registrar galeria success';
export const REGISTRAR_GALERIA_REPOSITORIO_FAIL = '[Galeria Repositorio] Registrar galeria fail';
export const CARGAR_GALERIA_REPOSITORIO_ALBUMES = '[Galeria Repositorio] Cargar galeria de repositorio albumes';
export const CARGAR_GALERIA_REPOSITORIO_ALBUMES_SUCCESS = '[Galeria Repositorio] Cargar galeria de repositorio albumes success';
export const CARGAR_GALERIA_REPOSITORIO_ALBUMES_FAIL = '[Galeria Repositorio] Cargar galeria de repositorio albumes fail';
export const CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES = '[Galeria Repositorio] Cargar galeria de repositorio album imagenes';
export const CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES_SUCCESS = '[Galeria Repositorio] Cargar galeria de repositorio album imagenes success';
export const CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES_FAIL = '[Galeria Repositorio] Cargar galeria de repositorio album imagenes fail';
export const CARGAR_GALERIA_REPOSITORIO_ALBUM = '[Galeria Repositorio] Cargar galeria de repositorio album';
export const CARGAR_GALERIA_REPOSITORIO_ALBUM_SUCCESS = '[Galeria Repositorio] Cargar galeria de repositorio album success';
export const CARGAR_GALERIA_REPOSITORIO_ALBUM_FAIL = '[Galeria Repositorio] Cargar galeria de repositorio album fail';
export const ELIMINAR_GALERIA_REPOSITORIO_ALBUM = '[Galeria Repositorio] Eliminar album';
export const ELIMINAR_GALERIA_REPOSITORIO_ALBUM_SUCCESS = '[Galeria Repositorio] Eliminar album success';
export const ELIMINAR_GALERIA_REPOSITORIO_ALBUM_FAIL = '[Galeria Repositorio] Eliminar album fail';
export const UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE = '[Galeria Repositorio] Update galeria album ultima imagen';
export const UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE_SUCCESS = '[Galeria Repositorio] Update galeria album ultima imagen success';
export const UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE_FAIL = '[Galeria Repositorio] Update galeria album ultima imagen fail';

export class CargarImagenGaleriaRepositorio implements Action {
  readonly type = CARGAR_IMAGEN_GALERIA_REPOSITORIO;

  constructor( public id: number) {}
}

export class CargarImagenGaleriaRepositorioFail implements Action {
  readonly type = CARGAR_IMAGEN_GALERIA_REPOSITORIO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarImagenGaleriaRepositorioSuccess implements Action {
  readonly type = CARGAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS;

  constructor(public archivo: GaleriaRepositorio) {}
}

export class CargarGaleriaRepositorioTodas implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_TODAS;

  constructor(public etiquetas: string) {}
}

export class CargarGaleriaRepositorioTodasFail implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_TODAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarGaleriaRepositorioTodasSuccess implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_TODAS_SUCCESS;

  constructor(public archivos: GaleriaRepositorio[]) {}
}

export class CargarGaleriaRepositorioAlbumes implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUMES;
}

export class CargarGaleriaRepositorioAlbumesFail implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUMES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarGaleriaRepositorioAlbumesSuccess implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUMES_SUCCESS;

  constructor(public albumes: Album[]) {}
}

export class CargarGaleriaRepositorioAlbumImagenes implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES;

  constructor(public id: number) {}
}

export class CargarGaleriaRepositorioAlbumImagenesFail implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarGaleriaRepositorioAlbumImagenesSuccess implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES_SUCCESS;

  constructor(public archivos: GaleriaRepositorio[], public album: Album) {}
}

export class CargarGaleriaRepositorioAlbum implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUM;

  constructor(public id: number) {}
}

export class CargarGaleriaRepositorioAlbumFail implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUM_FAIL;

  constructor( public payload: any ) {}
}

export class CargarGaleriaRepositorioAlbumSuccess implements Action {
  readonly type = CARGAR_GALERIA_REPOSITORIO_ALBUM_SUCCESS;

  constructor(public album: Album) {}
}

export class EliminarGaleriaRepositorioAlbum implements Action {
  readonly type = ELIMINAR_GALERIA_REPOSITORIO_ALBUM;

  constructor(public album: Album) {}
}

export class EliminarGaleriaRepositorioAlbumFail implements Action {
  readonly type = ELIMINAR_GALERIA_REPOSITORIO_ALBUM_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarGaleriaRepositorioAlbumSuccess implements Action {
  readonly type = ELIMINAR_GALERIA_REPOSITORIO_ALBUM_SUCCESS;

  constructor(public album: Album) {}
}

export class EliminarImagenGaleriaRepositorio implements Action {
  readonly type = ELIMINAR_IMAGEN_GALERIA_REPOSITORIO;

  constructor(public archivo: GaleriaRepositorio) {}
}

export class EliminarImagenGaleriaRepositorioFail implements Action {
  readonly type = ELIMINAR_IMAGEN_GALERIA_REPOSITORIO_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarImagenGaleriaRepositorioSuccess implements Action {
  readonly type = ELIMINAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS;

  constructor(public archivo: GaleriaRepositorio) {}
}

export class RegistrarImagenGaleriaRepositorio implements Action {
  readonly type = REGISTRAR_IMAGEN_GALERIA_REPOSITORIO;

  constructor(public archivo: GaleriaRepositorio) {}
}

export class RegistrarImagenGaleriaRepositorioFail implements Action {
  readonly type = REGISTRAR_IMAGEN_GALERIA_REPOSITORIO_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarImagenGaleriaRepositorioSuccess implements Action {
  readonly type = REGISTRAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS;

  constructor(public archivo: GaleriaRepositorio) {}
}

export class RegistrarGaleriaRepositorio implements Action {
  readonly type = REGISTRAR_GALERIA_REPOSITORIO;

  constructor(public archivos: GaleriaRepositorio[]) {}
}

export class RegistrarGaleriaRepositorioFail implements Action {
  readonly type = REGISTRAR_GALERIA_REPOSITORIO_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarGaleriaRepositorioSuccess implements Action {
  readonly type = REGISTRAR_GALERIA_REPOSITORIO_SUCCESS;

  constructor(public archivos: GaleriaRepositorio[]) {}
}

export class UpdateGaleriaRepositorioAlbumLastImage implements Action {
  readonly type = UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE;

  constructor(public album: Album) {}
}

export class UpdateGaleriaRepositorioAlbumLastImageFail implements Action {
  readonly type = UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE_FAIL;

  constructor( public payload: any ) {}
}

export class UpdateGaleriaRepositorioAlbumLastImageSuccess implements Action {
  readonly type = UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE_SUCCESS;

  constructor(public album: Album) {}
}

export type galeriaRepositorioAcciones =  CargarImagenGaleriaRepositorio |
                                          CargarImagenGaleriaRepositorioFail |
                                          CargarImagenGaleriaRepositorioSuccess |
                                          CargarGaleriaRepositorioTodas |
                                          CargarGaleriaRepositorioTodasFail |
                                          CargarGaleriaRepositorioTodasSuccess |
                                          CargarGaleriaRepositorioAlbumes |
                                          CargarGaleriaRepositorioAlbumesFail |
                                          CargarGaleriaRepositorioAlbumesSuccess |
                                          CargarGaleriaRepositorioAlbumImagenes |
                                          CargarGaleriaRepositorioAlbumImagenesFail |
                                          CargarGaleriaRepositorioAlbumImagenesSuccess |
                                          CargarGaleriaRepositorioAlbum |
                                          CargarGaleriaRepositorioAlbumFail |
                                          CargarGaleriaRepositorioAlbumSuccess |
                                          EliminarGaleriaRepositorioAlbum |
                                          EliminarGaleriaRepositorioAlbumFail |
                                          EliminarGaleriaRepositorioAlbumSuccess |
                                          EliminarImagenGaleriaRepositorio |
                                          EliminarImagenGaleriaRepositorioFail |
                                          EliminarImagenGaleriaRepositorioSuccess |
                                          RegistrarImagenGaleriaRepositorio |
                                          RegistrarImagenGaleriaRepositorioFail |
                                          RegistrarImagenGaleriaRepositorioSuccess |
                                          RegistrarGaleriaRepositorio |
                                          RegistrarGaleriaRepositorioFail |
                                          RegistrarGaleriaRepositorioSuccess |
                                          UpdateGaleriaRepositorioAlbumLastImage |
                                          UpdateGaleriaRepositorioAlbumLastImageFail |
                                          UpdateGaleriaRepositorioAlbumLastImageSuccess;
