<section class="content mt-4">
  <div class="container">
    <div class="card text-darker text-center">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7">
            <div class="d-flex" style="height: 350px;">
              <div style="margin: auto;">
                <h1 class="title-not-found">404</h1>
                <!-- <p class="lead-not-found mb-1">Ooops!...</p> -->
                <p class="lead-not-found">Disculpa, el registro no existe</p>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <img class="demo-image-bg" src="assets/images/confused.gif" />
          </div>
        </div>
        <p class="text-muted h4">Deseas regresar a la <a (click)="back()" class="text-primary">página anterior</a></p>
        <p class="text-muted h4" *ngIf="identity && identity.permisos && (identity.permisos.contactosListVer || identity.permisos.contactosListEdit)">O deseas ver lista de <a [routerLink]="['/auth/contactos']" class="text-primary">contactos</a></p>
        <p class="text-muted h4" *ngIf="identity && identity.permisos && (identity.permisos.empresasListVer || identity.permisos.empresasListEdit)">O deseas ver lista de <a [routerLink]="['/auth/empresas']" class="text-primary">empresas</a></p>
        <p class="text-muted h4" *ngIf="identity && identity.permisos && (identity.permisos.oportunidadesListVer || identity.permisos.oportunidadesListEdit)">O deseas ver lista de <a [routerLink]="['/auth/oportunidades']" class="text-primary">oportunidades</a></p>
      </div>
    </div>
  </div>
</section>
