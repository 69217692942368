import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-authorization',
  templateUrl: './not-authorization.component.html',
  styleUrls: ['./not-authorization.component.sass']
})
export class NotAuthorizationComponent {
  constructor(private locate: Location) { }

  back() {
    this.locate.back();
  }

}
