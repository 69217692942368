import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { CargarNotificacionesMini } from '../actions';

@Injectable()
export class NotificacionesMiniEffects {

  constructor(
    private actions$: Actions,
    public notificacionesService: NotificacionesService
  ) {}

  @Effect()
  cargarMiniNotificacion$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_NOTIFICACIONES_MINI ),
    switchMap( (action: CargarNotificacionesMini ) => {
      return this.notificacionesService.getNotificaciones(action.pag, action.limit, action.usuario)
        .pipe(
          map( (notificaciones) => {
            return new usuariosActions.CargarNotificacionesMiniSuccess(notificaciones.notificaciones,
               notificaciones.total, notificaciones.sinLeer);
          }),
          catchError( error => of(new usuariosActions.CargarNotificacionesMiniFail(error))  )
        );
    })
  );

}
