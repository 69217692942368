<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="identity.permisos">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <!-- <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize" (change)="cargarContactos()">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div> -->
          <div class="form-group">
            <label for="" class="control-label">Ordenar por</label>
            <select class="form-control custom-select" name="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType" (change)="cargarContactos()">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
          <div class="v-options-menu vsm-default">
            <div class="vsm-list">
              <div class="vsm-header">Opciones</div>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Origen del contacto</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterOrigenContacto">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}" #selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeTipos()">
                                      <label class="custom-control-label" for="selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Estatus</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterEstatus">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesEstatusFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesEstatusFilterContacto{{item.id}}" #selectCheckOpcionesEstatusFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeEstatus()">
                                      <label class="custom-control-label" for="selectCheckOpcionesEstatusFilterContacto{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Etapas</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterEtapas">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesEtapasFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesEtapasFilterContacto{{item.id}}" #selectCheckOpcionesEtapasFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeEtapas()">
                                      <label class="custom-control-label" for="selectCheckOpcionesEtapasFilterContacto{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Países</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterPaises">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesPaisFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesPaisFilterEmpresa{{item.id}}" #selectCheckOpcionesPaisFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changePaises()">
                                      <label class="custom-control-label" for="selectCheckOpcionesPaisFilterEmpresa{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                <ngb-panel class="p-0">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="vsm-link">
                      <span class="vsm-title">Propietarios</span>
                      <i class="vsm-arrow"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="vsm-dropdown">
                      <div class="vsm-list">
                        <div class="vsm-item" *ngFor="let item of filterPropietarios">
                          <a class="vsm-link">
                            <span class="vsm-title">
                              <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                  <div class="widget-content-left">
                                    <div class="custom-checkbox custom-control">
                                      <input type="checkbox" id="selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}" #selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changePropietarios()">
                                      <label class="custom-control-label" for="selectCheckOpcionesPropietarioFilterEmpresa{{item.id}}">&nbsp;</label>
                                    </div>
                                  </div>
                                  <div class="widget-content-left flex2">
                                    <div class="widget-heading">{{item.nombre}}</div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Contactos</div>
        <div class="btn-actions-pane-right mr-4">
          ({{paginacion.totalFiltro}} de {{paginacion.totalSize}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchContactos" id="searchContactos" #searchContactos="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="changeTextSearch()">
                <button class="search-icon" (click)="searchClick()" for="searchContactos"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; listSearch = [];"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <div class="table-responsive">
          <table class="table table-hover table-bordered" style="width: 100%;">
            <thead>
              <tr>
                <th (click)="sort('nombre')">Nombre(s)
                  <span *ngIf="paginacion.sortType === '-nombre'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+nombre'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('desEmpresa')">Empresa
                  <span *ngIf="paginacion.sortType === '-desEmpresa'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+desEmpresa'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('emails')">Email(s)
                  <span *ngIf="paginacion.sortType === '-emails'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+emails'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('desPais')">País
                  <span *ngIf="paginacion.sortType === '-desPais'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+desPais'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('codigoPostal')">Código postal
                  <span *ngIf="paginacion.sortType === '-codigoPostal'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+codigoPostal'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('desEstado')">Estado
                  <span *ngIf="paginacion.sortType === '-desEstado'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+desEstado'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('desMunicipio')">Municipio/Ciudad
                  <span *ngIf="paginacion.sortType === '-desMunicipio'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+desMunicipio'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('colonia')">Colonia
                  <span *ngIf="paginacion.sortType === '-colonia'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+colonia'" class="fa fa-caret-up"></span>
                </th>
                <th (click)="sort('calle')">Calle y número
                  <span *ngIf="paginacion.sortType === '-calle'" class="fa fa-caret-down"></span>
                  <span *ngIf="paginacion.sortType === '+calle'" class="fa fa-caret-up"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of contactos">
                <td><a [routerLink]="['/auth/contactos/profile', item.id]">{{item.nombre}} {{item.apellido}}</a></td>
                <td><a [routerLink]="['/auth/empresas/profile', item.empresa]">{{item.desEmpresa}}</a></td>
                <td>{{item.emails}}</td>
                <td>{{item.desPais}}</td>
                <td>{{item.codigoPostal}}</td>
                <td>{{item.desEstadoDireccion}}</td>
                <td>{{item.desMunicipio}}</td>
                <td>{{item.colonia}}</td>
                <td>{{item.calle}}</td>
              </tr>              
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2"
                  [collectionSize]="paginacion.totalFiltro" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5" (pageChange)="cargarContactos(false)">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <button type="button" (click)="printCSV()" class="btn btn-primary mb-2">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
