import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Identity, User } from 'src/app/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit, OnDestroy {

  heading = 'Dashboard';
  loading: boolean;
  public identity: Identity;
  private subscriptionAuth: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>
  ) {
    this.identity = new Identity(null);
  }

  ngOnInit() {
    console.log('Entra al componente dashboard');
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptionAuth.unsubscribe();
  }

}
