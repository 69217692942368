import { AuthEffects } from './auth.effects';
import { UsuariosEffects } from './usuarios.effects';
import { UsuarioEffects } from './usuario.effects';
import { CatalogoEffects } from './catalogos.effects';
import { NotificacionesEffects } from './notificaciones.effects';
import { NotificacionesMiniEffects } from './notificaciones-mini.effects';
import { DashboardEffects } from './dashboard.effects';
import { DireccionEffects } from './direccion.effects';
import { ArchivosGeneralesEffects } from './archivos-generales.effects';
import { ContactoEffects } from './contacto.effects';
import { EmpresaEffects } from './empresa.effects';
import { IndustriaEffects } from './industria.effects';
import { TipoEmpresaEffects } from './tipo-empresa.effects';
import { ConsultarEffects } from './consultas.effects';
import { ActividadesEmpresaEffects } from './actividades-empresa.effects';
import { OportunidadEffects } from './oportunidad.effects';
import { ActividadesArchivosEffects } from './actividades-archivos.effects';
import { OrigenContactoEffects } from './origen-contacto.effects';
import { PaisEffects } from './paises.effects';
import { GaleriaRepositorioEffects } from './galeria-repositorio.effects';
import { KPIsVendedoresEffects } from './kpis-vendedores.effects';
import { ReporteKPIsVendedoresEffects } from './reporte-kpis-vendedores.effects';
import { TicketEffects } from './tickets.effects';
import { ReportesEffects } from './reportes.effects';
import { DepartamentoEffects } from './departamentos.effects';

export const effectsArr: any[] = [
  AuthEffects,
  UsuariosEffects,
  UsuarioEffects,
  CatalogoEffects,
  NotificacionesEffects,
  NotificacionesMiniEffects,
  DashboardEffects,
  DireccionEffects,
  ArchivosGeneralesEffects,
  ContactoEffects,
  EmpresaEffects,
  IndustriaEffects,
  TipoEmpresaEffects,
  ConsultarEffects,
  ActividadesEmpresaEffects,
  OportunidadEffects,
  ActividadesArchivosEffects,
  OrigenContactoEffects,
  PaisEffects,
  GaleriaRepositorioEffects,
  KPIsVendedoresEffects,
  ReporteKPIsVendedoresEffects,
  TicketEffects,
  DepartamentoEffects,
  ReportesEffects,
  DepartamentoEffects,
];

export * from './auth.effects';
export * from './usuarios.effects';
export * from './usuario.effects';
export * from './catalogos.effects';
export * from './notificaciones.effects';
export * from './notificaciones-mini.effects';
export * from './dashboard.effects';
export * from './direccion.effects';
export * from './archivos-generales.effects';
export * from './contacto.effects';
export * from './empresa.effects';
export * from './industria.effects';
export * from './tipo-empresa.effects';
export * from './consultas.effects';
export * from './actividades-empresa.effects';
export * from './oportunidad.effects';
export * from './actividades-archivos.effects';
export * from './origen-contacto.effects';
export * from './paises.effects';
export * from './galeria-repositorio.effects';
export * from './kpis-vendedores.effects';
export * from './reporte-kpis-vendedores.effects';
export * from './tickets.effects';
export * from './departamentos.effects';
export * from './reportes.effects';
export * from './departamentos.effects';
