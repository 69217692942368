import { Action } from '@ngrx/store';

export const SUCCESS_TOASTR = '[Toast] Cargando Success Toastr...';
export const ERROR_TOASTR = '[Toast] Cargando Error Toastr...';
export const WARN_TOASTR = '[Toast] Cargando Warn Toastr...';
export const INFO_TOASTR = '[Toast] Cargando Info Toastr...';
export const CLEAR_TOASTR = '[Toast] Clear Toastrs...';

export class SuccessToastrAction implements Action {
    readonly type = SUCCESS_TOASTR;

    constructor( public message: string, public title?: string, public status?: any, public code?: any) {
        this.title = this.title ? this.title : 'Exito';
        this.status = this.status ? this.status : null;
        this.code = this.code ? this.code : null;
    }
}

export class ErrorToastrAction implements Action {
    readonly type = ERROR_TOASTR;

    constructor( public message: string, public title?: string, public status?: any, public code?: any) {
        this.title = this.title ? this.title : 'Error';
        this.status = this.status ? this.status : null;
        this.code = this.code ? this.code : null;
    }
}

export class WarnToastrAction implements Action {
    readonly type = WARN_TOASTR;

    constructor( public message: string, public title?: string, public status?: any, public code?: any) {
        this.title = this.title ? this.title : 'Advertencia';
        this.status = this.status ? this.status : null;
        this.code = this.code ? this.code : null;
    }
}

export class InfoToastrAction implements Action {
    readonly type = INFO_TOASTR;

    constructor( public message: string, public title?: string, public status?: any, public code?: any) {
        this.title = this.title ? this.title : 'Información';
        this.status = this.status ? this.status : null;
        this.code = this.code ? this.code : null;
    }
}

export class ClearToastrAction implements Action {
    readonly type = CLEAR_TOASTR;
}


export type toastrAcciones = SuccessToastrAction | ErrorToastrAction | WarnToastrAction | InfoToastrAction | ClearToastrAction;
