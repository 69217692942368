import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/models/global';
import { Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getUsuariosConectados(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/reportes/usuarios/conectados', {headers});
  }

  obtenerReporteSepomex(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/sepomex/get', params, {headers}); 
	}

  downloadExcelReporteSepomex(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/sepomex/excel', params, {headers}); 
	}

  obtenerReporteCumpleannos(inicia: NgbDate, termina: NgbDate, opciones: any): Observable<any> {
    const obj = {inicia, termina, opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/cumpleannos', params, {headers}); 
	}

  downloadExcelReporteCumpleannos(inicia: NgbDate, termina: NgbDate, opciones: any): Observable<any> {
    const obj = {inicia, termina, opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/cumpleannos/excel', params, {headers}); 
	}

  obtenerReporteVentasEtapas(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/ventas/etapas', params, {headers}); 
	}

  obtenerReporteVentasPropietario(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/ventas/propietario', params, {headers}); 
	}

  obtenerReporteAutoActualizacion(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/auto-actualizacion/get', params, {headers}); 
	}

  downloadExcelReporteAutoActualizacion(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/reportes/auto-actualizacion/excel', params, {headers}); 
	}

}
