import * as fromUsuario from '../actions';
import { Catalogo } from 'src/app/models/catalogo';

export interface TipoEmpresaState {
  tipos: Catalogo[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: TipoEmpresaState = {
  tipos: [],
  loaded: false,
  loading: false,
  error: null
};

export function tipoEmpresaReducer( state = initState, action: fromUsuario.tipoEmpresaAcciones ): TipoEmpresaState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_TIPO_EMPRESAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_TIPO_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tipos: [...action.tipo]
      };
    case fromUsuario.CARGAR_TIPO_EMPRESAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.ACTUALIZA_TIPO_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_EMPRESA_SUCCESS:
      const itm = state.tipos.find( item => item.id === action.tipo.id);
      if (itm && itm.id) {
        state.tipos = state.tipos.filter(item => item.nombre !== action.tipo.nombre).map( item => {
          if (item.id === action.tipo.id) {
            item = {...action.tipo};
          }
          return item;
        });
      } else {
        state.tipos.push(action.tipo);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_TIPO_EMPRESA_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_EMPRESA_ESTATUS_SUCCESS:
      state.tipos = state.tipos.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_TIPO_EMPRESA_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
