
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SettingProfileComponent } from './users/setting-profile/setting-profile.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { ListUsersComponent } from './users/list-users/list-users.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
// import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LayoutModule } from 'src/app/layout/layout.module';
import { DecorationsModule } from 'src/app/decorations/decorations.module';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MomentModule } from 'ngx-moment';
// import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { FlatpickrModule } from 'angularx-flatpickr';
// import { CalendarModule } from 'angular-calendar';
// import { DateAdapter } from '@angular/material';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { SortablejsModule } from 'ngx-sortablejs';
import { CountUpModule } from 'ngx-countup';
// import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment.prod';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { TextMaskModule } from 'angular2-text-mask';
// import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
// import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { effectsArr } from 'src/app/store/effects';
import { appReducers } from 'src/app/store/app.reducer';
import { MetaModule } from '@ngx-meta/core';
import { StorageModule } from '@ngx-pwa/local-storage';
// import { CookieService } from 'ngx-cookie-service';
import { EncrDecrService } from 'src/app/services/encr-decr.service';
import { ConfigActions } from 'src/app/themeoptions/store/config.actions';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { GaugeModule } from 'angular-gauge';
// import { TrendModule } from 'ngx-trend';
import { ChartsModule } from 'ng2-charts';
import { PipesModule } from 'src/app/pipes/pipes.module';
// import { NgxPrintModule } from 'ngx-print';
import { NotAuthorizationComponent } from './not-authorization/not-authorization.component';
import { UsuariosConfigComponent } from './config/usuarios-config/usuarios-config.component';
import { UserTypeFilterComponent } from './users/user-type-filter/user-type-filter.component';
import { UsersFilterComponent } from './users/users-filter/users-filter.component';
import { FilterTypeNotificationsComponent } from './notificaciones/filter-type-notifications/filter-type-notifications.component';
import { DashboardAdministradoresComponent } from './dashboard/dashboard-administradores/dashboard-administradores.component';
// import { NgbDateCustomParserFormatterService } from 'src/app/services/ngb-date-custom-parser-formatter.service';
import { NotFoundRecordComponent } from './layout/not-found-record/not-found-record.component';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ListaArchivosComponent } from './lista-archivos/lista-archivos.component';
import { EditArchivosComponent } from './lista-archivos/edit-archivos/edit-archivos.component';
import { RepositoriosEditorialComponent } from './repositorios-editorial/repositorios-editorial.component';
import { EditRepositorioComponent } from './repositorios-editorial/edit-repositorio/edit-repositorio.component';
import { ContactosComponent } from './contactos/contactos.component';
import { EditContactoComponent } from './contactos/edit-contacto/edit-contacto.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { IndustriasComponent } from './catalogos/industrias/industrias.component';
import { TipoEmpresasComponent } from './catalogos/tipo-empresas/tipo-empresas.component';
import { EmpresasEditComponent } from './empresas/empresas-edit/empresas-edit.component';
import { EmpresaProfileComponent } from './empresas/empresa-profile/empresa-profile.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { EmpresaProfileActionsComponent } from './empresas/empresa-profile-actions/empresa-profile-actions.component';
import { EmpresaActionsComponent } from './empresas/empresa-actions/empresa-actions.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OportunidadesComponent } from './oportunidades/oportunidades.component';
import { OportunidadesEditComponent } from './oportunidades/oportunidades-edit/oportunidades-edit.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { OrigenContactosComponent } from './catalogos/origen-contactos/origen-contactos.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AllcampanasComponent } from './marketing/allcampanas/allcampanas.component';
import { NuevacampanaComponent } from './marketing/nuevacampana/nuevacampana.component';
import { PlantillasComponent } from './marketing/plantillas/plantillas.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { TrendModule } from 'ngx-trend';
// import { VerListaComponent } from './marketing/ver-lista/ver-lista.component';
import { GuardarYEnviarCampanaComponent } from './marketing/guardar-y-enviar-campana/guardar-y-enviar-campana.component';
import { EditarCampanaComponent } from './marketing/editar-campana/editar-campana.component';
import { PaisesComponent } from './catalogos/paises/paises.component';
import { ContactoProfileComponent } from './contactos/contacto-profile/contacto-profile.component';
import { ContactoProfileActionsComponent } from './contactos/contacto-profile-actions/contacto-profile-actions.component';
import { OportunidadProfileActionsComponent } from './oportunidades/oportunidad-profile-actions/oportunidad-profile-actions.component';
import { OportunidadProfileComponent } from './oportunidades/oportunidad-profile/oportunidad-profile.component';
import { AutonumericModule } from '@angularfy/autonumeric';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

/* Custom Hammer configuration */
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { SharedModule } from '../../shared/shared.module';
import { NuevaplantillaComponent } from './marketing/plantillas/nuevaplantilla/nuevaplantilla/nuevaplantilla.component';
import { TicketsComponent } from './tickets/tickets.component';
import { DetailsTicketsComponent } from './tickets/details-tickets/details-tickets.component';
import { CargaContactosComponent } from './contactos/carga-contactos/carga-contactos.component';
import { VerAlbumRepositoriosComponent } from './repositorios-editorial/ver-album-repositorios/ver-album-repositorios.component';
import { KpisVendedoresComponent } from './kpis-vendedores/kpis-vendedores.component';
import { ReporteKpisVendedoresComponent } from './reportes/reporte-kpis-vendedores/reporte-kpis-vendedores.component';
import { EditarPlantillaComponent } from './marketing/plantillas/editar-plantilla/editar-plantilla.component';
import { getSaver, SAVER } from 'src/app/services/download-file.service';
import { ModalListaCampanaComponent } from './marketing/modal-lista-campana/modal-lista-campana.component';
import { TicketsEditComponent } from './tickets/tickets-edit/tickets-edit.component';
import { DepartamentosComponent } from './catalogos/departamentos/departamentos.component';

import { AccumulationChartModule, AccumulationDataLabelService, AccumulationLegendService, AccumulationTooltipService, FunnelSeriesService } from '@syncfusion/ej2-angular-charts';
import { ReporteSepomexComponent } from './reportes/reporte-sepomex/reporte-sepomex.component';
import { ItemTicketComponent } from './tickets/item-ticket/item-ticket.component';
import { OptionFilterCollapseComponent } from './layout/option-filter-collapse/option-filter-collapse.component';
import { ReporteCumpleannosComponent } from './reportes/reporte-cumpleannos/reporte-cumpleannos.component';
import { ReportesCampanaComponent } from './marketing/reportes-campana/reportes-campana.component';
// import { ReporteVentasComponent } from './reportes/reporte-ventas/reporte-ventas.component';
import { ReporteVentasEtapasComponent } from './reportes/reporte-ventas-etapas/reporte-ventas-etapas.component';
import { ReporteVentasPropietariosComponent } from './reportes/reporte-ventas-propietarios/reporte-ventas-propietarios.component';
import { ReportesListComponent } from './reportes/reportes-list.component';
import { ReporteMenuComponent } from './reportes/reporte-menu/reporte-menu.component';
import { NgxPrintModule } from 'ngx-print';
import { ReporteAutoActualizacionComponent } from './reportes/reporte-auto-actualizacion/reporte-auto-actualizacion.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';


import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ActividadesPendientesComponent } from './actividades-pendientes/actividades-pendientes.component';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: Hammer.DIRECTION_ALL,
    }
  }
}

@NgModule({
  declarations: [
    DashboardComponent,
    // User Pages
    ChangePasswordComponent,
    SettingProfileComponent,
    EditUserComponent,
    ListUsersComponent,
    NotificacionesComponent,
    NotAuthorizationComponent,
    UsuariosConfigComponent,
    UserTypeFilterComponent,
    UsersFilterComponent,
    FilterTypeNotificationsComponent,
    DashboardAdministradoresComponent,
    NotFoundRecordComponent,
    ListaArchivosComponent,
    EditArchivosComponent,
    RepositoriosEditorialComponent,
    EditRepositorioComponent,
    ContactosComponent,
    EditContactoComponent,
    EmpresasComponent,
    IndustriasComponent,
    TipoEmpresasComponent,
    EmpresasEditComponent,
    EmpresaProfileComponent,
    EmpresaProfileActionsComponent,
    EmpresaActionsComponent,
    OportunidadesComponent,
    OportunidadesEditComponent,
    OrigenContactosComponent,
    AllcampanasComponent,
    NuevacampanaComponent,
    PlantillasComponent,
    GuardarYEnviarCampanaComponent,
    PaisesComponent,
    ContactoProfileComponent,
    ContactoProfileActionsComponent,
    OportunidadProfileActionsComponent,
    OportunidadProfileComponent,
    NuevaplantillaComponent,
    EditarCampanaComponent,
    TicketsComponent,
    DetailsTicketsComponent,
    CargaContactosComponent,
    VerAlbumRepositoriosComponent,
    KpisVendedoresComponent,
    ReporteKpisVendedoresComponent,
    EditarPlantillaComponent,
    ModalListaCampanaComponent,
    TicketsEditComponent,
    ReporteSepomexComponent,
    DepartamentosComponent,
    ItemTicketComponent,
    OptionFilterCollapseComponent,
    ReporteCumpleannosComponent,
    ReportesCampanaComponent,
    // ReporteVentasComponent,
    ReporteVentasEtapasComponent,
    ReporteVentasPropietariosComponent,
    ReportesListComponent,
    ReporteMenuComponent,
    ReporteAutoActualizacionComponent,
    ActividadesPendientesComponent,
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    FormsModule,
    CKEditorModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    // LoadingBarRouterModule,
    LayoutModule,
    DecorationsModule,
    PipesModule,
    // NgxExtendedPdfViewerModule,
    MaterialModule,
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    // AngularFontAwesomeModule,
    //   LaddaModule,
    ReactiveFormsModule,
    // NgBootstrapFormValidationModule.forRoot(),
    AutonumericModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true,
    }),
    RoundProgressModule,
    MomentModule,
    // SweetAlert2Module.forRoot({
    //   buttonsStyling: false,
    //   customClass: 'modal-content',
    //   confirmButtonClass: 'btn btn-primary',
    //   cancelButtonClass: 'btn btn-secondary'
    // }),
    ToastrModule,
    SlickCarouselModule,
    // FlatpickrModule.forRoot(),
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    // SortablejsModule.forRoot({ animation: 150 }),
    CountUpModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.apiKeyMaps
    // }),
    ImageCropperModule,
    //   AngularStickyThingsModule,
    //   NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    // TextMaskModule,
    //   ClipboardModule,
    // TextareaAutosizeModule,
    //   ColorPickerModule,
    // DropzoneModule,

    //   // Charts

    ChartsModule,
    NgApexchartsModule,
    // GaugeModule.forRoot(),
    TrendModule,

    //   // Angular Material Components
    // MatCheckboxModule,
    //   MatCheckboxModule,
    //   MatButtonModule,
    //   MatInputModule,
    //   MatAutocompleteModule,
    //   MatDatepickerModule,
    MatNativeDateModule,
    // MatFormFieldModule,
    //   MatRadioModule,
    //   MatSelectModule,
    //   MatSliderModule,
    //   MatSlideToggleModule,
    //   MatMenuModule,
    //   MatSidenavModule,
    //   MatToolbarModule,
    //   MatListModule,
    //   MatGridListModule,
    //   MatCardModule,
    //   MatStepperModule,
    //   MatTabsModule,
    //   MatExpansionModule,
    //   MatButtonToggleModule,
    //   MatChipsModule,
    //   MatIconModule,
    //   MatProgressSpinnerModule,
    //   MatProgressBarModule,
    //   MatDialogModule,
    //   MatTooltipModule,
    //   MatSnackBarModule,
    //   MatTableModule,
    //   MatSortModule,
    //   MatPaginatorModule,
    //   MatTreeModule,
    //   MatRippleModule,

    StoreModule,
    StoreDevtoolsModule,
    // EffectsModule,
    EffectsModule.forRoot(effectsArr),
    MetaModule,
    StorageModule,
    // NgxPrintModule,
    FontAwesomeModule,
    SharedModule,

    AccumulationChartModule,
    NgxPrintModule
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,

    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    ConfigActions,
    // ThemeOptions,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    EncrDecrService,
    { provide: SAVER, useFactory: getSaver },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    // CookieService


    AccumulationDataLabelService,
    AccumulationLegendService,
    AccumulationTooltipService,
    FunnelSeriesService
  ],
  exports: [
    // User Pages
    ChangePasswordComponent,
    SettingProfileComponent,
    EditUserComponent,
    ListUsersComponent,
    NotificacionesComponent,
    NotAuthorizationComponent,
    FilterTypeNotificationsComponent,
    UsersFilterComponent,
    UserTypeFilterComponent,
    UsuariosConfigComponent,
    DashboardAdministradoresComponent,
    NotFoundRecordComponent,
    ListaArchivosComponent,
    EditArchivosComponent,
    RepositoriosEditorialComponent,
    EditRepositorioComponent,
    ContactosComponent,
    EditContactoComponent,
    EmpresasComponent,
    IndustriasComponent,
    TipoEmpresasComponent,
    EmpresasEditComponent,
    EmpresaProfileComponent,
    OrigenContactosComponent,
    ContactoProfileComponent,
    ContactoProfileActionsComponent,
    EmpresaActionsComponent,
    EditarCampanaComponent,
    DetailsTicketsComponent,
    CargaContactosComponent,
    ModalListaCampanaComponent,
    VerAlbumRepositoriosComponent,
    KpisVendedoresComponent,
    ReporteKpisVendedoresComponent,
    EditarPlantillaComponent,
    TicketsEditComponent,
    DepartamentosComponent,
    ItemTicketComponent,
    OptionFilterCollapseComponent,
    OportunidadProfileActionsComponent,
    OportunidadProfileComponent,
    ReporteCumpleannosComponent,
    ReporteSepomexComponent,
    ReportesCampanaComponent,
    // ReporteVentasComponent,
    ReporteVentasEtapasComponent,
    ReporteVentasPropietariosComponent,
    ReportesListComponent,
    ReporteMenuComponent,
    ReporteAutoActualizacionComponent,
  ],
  bootstrap: [
    ModalListaCampanaComponent,
  ],
  entryComponents: [
    ModalListaCampanaComponent
  ]
})
export class AuthModule { }
