import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';


@Injectable({
  providedIn: 'root'
})
export class ReportescampanaService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }


  obtenerLinks(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/reportes/link/' + id, {headers});
  }



  obtenerCampanaEnviada(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/reportes/' + id, {headers});
  }

  obtenerEstatusSuscriptor(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/reportes/estatus/' + id, {headers});
  }





}
