import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-desuscripcion',
  templateUrl: './desuscripcion.component.html',
  styleUrls: ['./desuscripcion.component.scss']
})
export class DesuscripcionComponent implements OnInit {
  public loading: boolean;
  public year = 2020;
  public found = true;
  constructor(@Inject(DOCUMENT) private document: Document,) { }

  ngOnInit(): void {
  }

  suscribirse() {
    this.document.location.href = 'https://www.seguridadenamerica.com.mx/noticias/formulario/25068/suscribete-al-newsletter';
  }

}
