<div class="main-posts">
  <div class="card">
    <div class="card-header card-tabbed-header">
      <div class="btn-actions-pane-left tabs-animated tabs-animated-line">
        <ul role="tablist" class="justify-content-start nav nav-tabs">
          <li class="nav-item ng-star-inserted" (click)="activeTab=1">
            <a class="nav-link" [ngClass]="{'active': activeTab === 1}">
              <span class="ng-star-inserted">Albumes</span>
            </a>
          </li>
          <li class="nav-item ng-star-inserted" (click)="activeTab=2">
            <a class="nav-link" [ngClass]="{'active': activeTab === 2}">
              <span class="ng-star-inserted">Todas</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="btn-actions-pane-right">
        <span *ngIf="activeTab===1">({{(albumes | anyScope: paginacion.textSearch ).length}} de {{(albumes).length}})</span>
        <span *ngIf="activeTab===2">({{(galleryImages | anyScope: paginacion.textSearch ).length}} de {{(galleryImages).length}})</span>
        <div class="btn-group ml-2">
          <div class="search-wrapper active">
            <div class="input-holder">
              <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchGaleriaRepositorio" id="searchGaleriaRepositorio" #searchGaleriaRepositorio="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
              <button class="search-icon"><span></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
            {{texto}}
            <i class="ml-1 fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="activeTab===1">
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-3">
          <div class="card" style="background-color: #ebebeb;">
            <div id="over" class="gallery-list text-center">
              <div class="thumbnail-gallery">
                <a [routerLink]="['/auth/repositorios/register', 0]" class="text-muted">
                  <i class="fa fa-plus-circle fa-3x"></i>
                </a>
              </div>
            </div>
          </div>
          <div>
            <a class="font-album-card" [routerLink]="['/auth/repositorios/register', 0]">Crear album</a>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-3" *ngFor="let album of albumes | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType]; let i= index">
          <div class="card" style="background-color: #ebebeb;">
            <div id="over" class="gallery-list">
              <a [routerLink]="['/auth/repositorios/album', album.id]">
                <img [src]="album.thumbnail" [alt]="album.nombre" class="thumbnail-gallery">
              </a>
              <div class="btn-transition only-hover icon-helper" ngbDropdown  placement="left-top">
                <i type="button" ngbDropdownToggle class="fa fa-ellipsis-h"></i>
                <div ngbDropdownMenu class="dropdown-menu-hover-link dropdown-menu-left-rounded">
                  <button class="dropdown-item" [routerLink]="['/auth/repositorios/album', album.id]"><span>Ver</span></button>
                  <a class="dropdown-item" [routerLink]="['/auth/repositorios/register', album.id]" *ngIf="identity.permisos.repositoriosListEdit"><span>Editar</span></a>
                  <button class="dropdown-item" (click)="deleteAlbum(album)" *ngIf="identity.permisos.repositoriosListEdit"><span>Eliminar</span></button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a class="font-album-card" [routerLink]="['/auth/repositorios/album', album.id]">{{album.nombre}}</a>
          </div>
          <small >{{album.cantidad}} imágenes</small>
        </div>
        <div class="col-12 mt-2">
          <div class="text-center">
            <div class="card no-shadow bg-transparent">
              <div class="widget-chart-content">
                <div class="widget-content-wrapper">
                  <ngb-pagination class="mx-auto"
                    [collectionSize]="(albumes | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                    <ng-template ngbPaginationFirst>First</ng-template>
                    <ng-template ngbPaginationLast>Last</ng-template>
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="activeTab===2">
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-3" *ngFor="let image of galleryImages | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize; let i= index">
          <div class="card" style="background-color: #ebebeb;">
            <div id="over" class="gallery-list">
              <img [src]="image.thumbnail" [alt]="image.nombre" (click)="openModal(i)" class="thumbnail-gallery">
              <div class="btn-transition only-hover icon-helper" ngbDropdown  placement="left-top">
                <i type="button" ngbDropdownToggle class="fa fa-ellipsis-h"></i>
                <div ngbDropdownMenu class="dropdown-menu-hover-link dropdown-menu-left-rounded">
                  <button class="dropdown-item" (click)="openModal(i)"><span>Ver</span></button>
                  <button class="dropdown-item" (click)="deleteFile(image)" *ngIf="identity.permisos.repositoriosListEdit"><span>Eliminar</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="text-center">
            <div class="card no-shadow bg-transparent">
              <div class="widget-chart-content">
                <div class="widget-content-wrapper">
                  <ngb-pagination class="mx-auto"
                    [collectionSize]="(galleryImages | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                    <ng-template ngbPaginationFirst>First</ng-template>
                    <ng-template ngbPaginationLast>Last</ng-template>
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

