import { Action } from '@ngrx/store';
import { User } from 'src/app/models/user';

export const CARGAR_USUARIO = '[Usuario] Cargar usuario';
export const CARGAR_USUARIO_FAIL = '[Usuario] Cargar usuario FAIL';
export const CARGAR_USUARIO_SUCCESS = '[Usuario] Cargar usuario SUCCESS';
export const EDITAR_USUARIO = '[Usuario] Editar usuario';
export const EDITAR_PERFIL = '[Usuario] Editar perfil';
export const EDITAR_USUARIO_SUCCESS = '[Usuario] Editar usuario success';
export const EDITAR_USUARIO_FAIL = '[Usuario] Editar usuario fail';
export const ELIMINAR_USUARIO = '[Usuario] Eliminar usuario';
export const ELIMINAR_USUARIO_SUCCESS = '[Usuario] Eliminar usuario SUCCESS';
export const ELIMINAR_USUARIO_FAIL = '[Usuario] Eliminar usuario fail';

export class CargarUsuario implements Action {
    readonly type = CARGAR_USUARIO;

    constructor( public id: number ) { }
}

export class CargarUsuarioFail implements Action {
    readonly type = CARGAR_USUARIO_FAIL;

    constructor( public payload: any ) {}
}

export class CargarUsuarioSuccess implements Action {
    readonly type = CARGAR_USUARIO_SUCCESS;

    constructor( public usuario: User ) {}
}

export class EditarUsuario implements Action {
    readonly type = EDITAR_USUARIO;

    constructor( public usuario: User ) {}
}

export class EditarPerfil implements Action {
  readonly type = EDITAR_PERFIL;

  constructor( public usuario: User ) {}
}

export class EditarUsuarioSuccess implements Action {
    readonly type = EDITAR_USUARIO_SUCCESS;

    constructor( public usuario: User ) {}
}

export class EditarUsuarioFail implements Action {
    readonly type = EDITAR_USUARIO_FAIL;

    constructor( public payload: any ) { }
}

export class EliminarUsuario implements Action {
    readonly type = ELIMINAR_USUARIO_FAIL;

    constructor( public id: number ) { }
}

export class EliminarUsuarioSuccess implements Action {
    readonly type = ELIMINAR_USUARIO_SUCCESS;

    constructor( public id: number ) { }
}

export class EliminarUsuarioFail implements Action {
    readonly type = ELIMINAR_USUARIO_FAIL;

    constructor( public payload: any ) { }
}

export type usuarioAcciones  = CargarUsuario |
                               CargarUsuarioFail |
                               CargarUsuarioSuccess |
                               EditarUsuario |
                               EditarPerfil |
                               EditarUsuarioSuccess |
                               EditarUsuarioFail |
                               EliminarUsuario |
                               EliminarUsuarioSuccess |
                               EliminarUsuarioFail;
