import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CatalogosService } from 'src/app/services/catalogos.service';


@Injectable()
export class PaisEffects {

  constructor(
    private actions$: Actions,
    public catalogosService: CatalogosService
  ) {}

  @Effect()
  cargarPais$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_PAISES ),
    switchMap( () => {
      return this.catalogosService.obtenerpaises()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarPaisesSuccess(respuesta.paises);
          }),
          catchError( error => of(new usuariosActions.CargarPaisesFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusPais$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_PAIS_ESTATUS),
    switchMap( (action: any) => {
      return this.catalogosService.actualizaEstatusPais(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaPaisEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaPaisEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaPais$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_PAIS),
    switchMap( (action: any) => {
      return this.catalogosService.registraPais(action.pais)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaPaisSuccess(action.pais);
            }),
            catchError( error => of(new usuariosActions.ActualizaPaisFail(error))  )
        );
    })
  );

}
