import { Component, OnInit, OnDestroy } from '@angular/core';
import { Actividad, LlamadaCatalogo, ReunionCatalogo, CorreoCatalogo } from 'src/app/models/actividad';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import * as usuariosActions from 'src/app/store/actions';
import { DatePipe } from '@angular/common';
import { Pagination } from 'src/app/models/pagination';
import { Catalogo } from 'src/app/models/catalogo';
import { GLOBAL } from 'src/app/models/global';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActividadesEmpresasService } from 'src/app/services/actividades-empresas.service';


@Component({
  selector: 'app-actividades-pendientes',
  templateUrl: './actividades-pendientes.component.html',
  styleUrls: ['./actividades-pendientes.component.scss']
})
export class ActividadesPendientesComponent implements OnInit, OnDestroy {
  heading = 'Mis actividades';
  subheading = 'Seguimiento de las actividades pendientes';
  icon = 'fas fa-tags icon-gradient bg-dark';
  public actividades: Actividad[] = [];
  public actividad: Actividad = new Actividad(null);
  public identity: Identity;
  public dates: Date[] = [];
  public fechas: {inicia: string, termina: string} = null; 
  public datePipe = new DatePipe("en-US");
  public propietarios: number = null;
  public version: number = 1;
  public url: string = '';
  public paginacion = new Pagination({sortType: '-fecModificacion', optionSize: [
    { value: 10, description: '10 elementos por hoja' },
    { value: 20, description: '20 elementos por hoja' },
    { value: 50, description: '50 elementos por hoja' }
  ]});
  public Reuniones = [...ReunionCatalogo];
  public llamadas = [...LlamadaCatalogo];
  public correos = [...CorreoCatalogo];
  public filterPropietarios: Catalogo[] = [];
  public listSearch: string[] = [];
  private subcriptionActividad: Subscription = new Subscription();
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();
  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private actividadesEmpresasService: ActividadesEmpresasService

  ) { 
    this.url = GLOBAL.url;
    this.identity = new Identity(null);
    const fecha = new Date();
    fecha.setDate(fecha.getDate() + 30);
    this.dates = [ new Date(), fecha ];
    this.fechas = { inicia: this.datePipe.transform(fecha, 'yyyy-MM-dd'), termina: this.datePipe.transform(new Date, 'yyyy-MM-dd')};
    this.paginacion.pageSize = 10;
    this.actividad = new Actividad(null);
  }
  
  ngOnInit(): void {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionActividad = this.store.select('actividadEmpresa').subscribe(
      (state) => {
        this.actividades = [...state.actividades];
        const date = new Date();
        this.version = date.getTime();
        this.propietarios = this.identity.id;
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.filterPropietarios = [...state.usuarios.map( item => {
          return new Catalogo ({id: item.id, nombre: item.descripcion, status: true});
        })];
      }
    );
    console.log('Inicializa component actividades-pendientes...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionActividad.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  inicializa() {
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.textSearch = this.listSearch.join('%');
    opciones.desPropietario = this.propietarios ? this.propietarios.toString() : '';
    this.store.dispatch(new usuariosActions.CargarActividadesPendientesTotal(this.fechas, opciones));
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchActividades").focus();
  }


  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
    this.listSearch = [];
    this.inicializa();
  }

	changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.inicializa();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.inicializa();
  }

  onDateSelection(fechas: Date[]) {
    if(fechas && fechas.length){
      const dates: string[] = [...fechas.map(item => { return this.datePipe.transform(item, 'yyyy-MM-dd'); })];
      this.fechas = { inicia: dates.shift(), termina: dates.pop()};
      this.inicializa();
    }
  }

  open(concepto: number, id: number, content) {
    if(concepto !== 6){
      if (id > 0) {
        this.actividades.findIndex( (item) => {
          if (item.concepto === concepto && item.id === id) {
            this.actividad = {...item};
            this.actividad.fecha = this.actividad.fecha ? this.pipeDateWithoutTimeZone.transform(this.actividad.fecha) : null;
          }
        });
        this.modalService.open(content, {
          size: 'lg'
        });
      }
   
    }
  }

  onSubmit() {
    return new Promise( (resolve, reject)  => {
      this.actividadesEmpresasService.registraActividadesEmpresa(this.actividad).subscribe(
        (respuesta) => {
          if (respuesta.actividad) {
            this.modalService.dismissAll('Close');
            this.store.dispatch(new usuariosActions.ActualizaActividadesEmpresaSuccess(respuesta.actividad));
            this.store.dispatch(new usuariosActions.CargarDashboardActividadesPendientesDia());
            resolve(respuesta.actividad);
          } else {
            resolve(false);
          }
        }, (error) => {
          resolve(false);
        }
      );
    });
  }

}
