import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Empresa } from 'src/app/models/empresa';
import { Archivo } from 'src/app/models/archivo';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerEmpresas2(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/empresas/get', params, {headers});
  }

  obtenerEmpresas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(this.url + '/empresas', {headers});
  }

  eliminarEmpresa(empresa: Empresa): Observable<any> {
    const param = JSON.stringify({empresa});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/empresas/estatus', param, {headers});
  }

  registraEmpresa(empresa: Empresa): Observable<any> {
    const param = JSON.stringify({empresa});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/empresas', param, {headers});
  }

  obtenerDatoEmpresa(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/empresas/' + id, {headers});
  }

  actualizaEmpresasDuplicados(empresa: Empresa, duplicados: Empresa[]): Observable<any> {
    const param = JSON.stringify({empresa, duplicados});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/empresas/duplicados', param, {headers});
  }

  existeEmpresa(empresa: Empresa): Observable<any> {
    const param = JSON.stringify({empresa});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/empresas/existe', param, {headers});
  }

  downloadExcelEmpresas(opciones: any): Observable<any> {
    const obj = {
      opciones,
      responseType: 'blob'
    };
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/empresas/excel', params, {headers});
  }

}
