<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="identity.permisos">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="form-group">
          <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize">
            <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="" class="control-label">Ordenar por</label>
          <select class="form-control custom-select" name="sortTypeProducto" id="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType">
            <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
          </select>
        </div>
        <div class="v-options-menu vsm-default">
          <div class="vsm-list">
            <div class="vsm-header">Opciones</div>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
              <ngb-panel class="p-0">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <span class="vsm-title">Tipo</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngFor="let item of filterTipos">
                        <a class="vsm-link">
                          <span class="vsm-title">
                            <div class="widget-content p-0">
                              <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                  <div class="custom-checkbox custom-control">
                                    <input type="checkbox" id="selectCheckOpcionesTipoFilter{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTipoFilter{{item.id}}" #selectCheckOpcionesTipoFilter{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeTipos()">
                                    <label class="custom-control-label" for="selectCheckOpcionesTipoFilter{{item.id}}">&nbsp;</label>
                                  </div>
                                </div>
                                <div class="widget-content-left flex2">
                                  <div class="widget-heading">{{item.nombre}}</div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
              <ngb-panel class="p-0">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <span class="vsm-title">Departamento</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngFor="let item of departamentos">
                        <a class="vsm-link">
                          <span class="vsm-title">
                            <div class="widget-content p-0">
                              <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                  <div class="custom-checkbox custom-control">
                                    <input type="checkbox" id="selectCheckOptionDepartamentoFilter{{item.id}}" class="custom-control-input" name="selectCheckOptionDepartamentoFilter{{item.id}}" #selectCheckOptionDepartamentoFilter{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeDepartamentos()">
                                    <label class="custom-control-label" for="selectCheckOptionDepartamentoFilter{{item.id}}">&nbsp;</label>
                                  </div>
                                </div>
                                <div class="widget-content-left flex2">
                                  <div class="widget-heading">{{item.nombre}}</div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Usuarios</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(usuarios | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: filterTipos, parametro: 'tipo'} | multiOpcionesSelected : { opciones: departamentos, parametro: 'departamento'}).length}} de {{(usuarios).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
            </div>
            <button type="button" *ngIf="identity.permisos.usuariosListEdit" [routerLink]="['/auth/usuarios/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let usr of usuarios | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: filterTipos, parametro: 'tipo'} | multiOpcionesSelected : { opciones: departamentos, parametro: 'departamento'} | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
            <div class="no-gutters row">
              <div class="col-sm-6 col-md-4 col-xl-3">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">#{{usr.id}}</div>
                      <div class="widget-content-left mr-3">
                        <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + usr.id + '/imagenes'" alt="Imagen de perfil de usuario">
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading"><a class="text-dark" [routerLink]="['/auth/usuarios/register', usr.id]">{{usr.descripcion}}</a></div>
                        <div class="widget-subheading"><a class="text-dark" [routerLink]="['/auth/usuarios/register', usr.id]">{{usr.usuario}}</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading text-secondary">{{usr.desTipo}} &nbsp; <i class="widget-subheading text-muted">({{usr.registro | amLocale: 'es' | amCalendar: { lastWeek: 'dddd [a las] LT'} }})</i></div>
                        <div class="widget-description opacity-8 text-focus">
                          {{usr.email}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-xl-3">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-subheading text-secondary">Departamento: <b class="text-dark">{{usr.desDepartamento}}</b></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-xl-2">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-right">
                        <button class="btn-square btn btn-sm btn-outline-danger" (click)="eliminarUsuario(usr)" *ngIf="identity.permisos.usuariosListEdit">Eliminar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  [collectionSize]="(usuarios | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: filterTipos, parametro: 'tipo'} | multiOpcionesSelected : { opciones: departamentos, parametro: 'departamento'}).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <button type="button" *ngIf="identity.permisos.usuariosDownload" (click)="printCSV()" class="btn btn-primary mb-2">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
