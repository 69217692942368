<ul class="header-megamenu nav">
  <li class="nav-item" style="padding-block: 0 10px;">
    <div class="footer-text">
      <strong>Copyright &copy; {{year}} <a href="https://jivabe.mx" target="_blank">Jivabe Rating And Consulting</a>.</strong> All rights reserved.
    </div>
  </li>
  <li class="nav-item">
    <a href="https://jivabe.mx" target="_blank">
      <picture>
        <img src="../../../../assets/images/jivabe.png" class="logo-footer" alt="Logo Jivabe">
      </picture>
    </a>
  </li>
</ul>
