import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(tel: any, args?: any): any {
    if (!tel || tel === '0' || tel === 0 ) {
      return '';
    }

    const value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let city: any;
    let numberValue: any;

    switch (value.length) {
      case 1:
      case 2:
      case 3:
        city = value;
        break;
      default:
        city = value.slice(0, 3);
        numberValue = value.slice(3);
    }

    if (numberValue) {
      if (numberValue.length > 3) {
        numberValue = numberValue.slice(0, 3) + '-' + numberValue.slice(3, 7);
      } else {
        numberValue = numberValue;
      }

      return ( '(' + city + ') ' + numberValue).trim();
    } else {
      return '(' + city;
    }
  }

}
