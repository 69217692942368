import { SortOrder } from './sort';
import { UserType } from './user';


export class Empresa{
	public id: number;
	public nombre: string;
	public industria: number;
	public desIndustria: string;
	public tipo: number;
	public desTipo: string;
	public telefonoUno: string;
	public telefonoDos: string;
	public telefonoTres: string;
	public calle: string;
	public colonia: string;
	public pais: number;
	public desPais: string;
	public codigoPostal: string;
	public municipio: number;
	public estado: number;
	public desMunicipio: string;
	public desEstado: string;
	public imagen: string;
	public status: boolean;
	public propietario: number;
	public desPropietario: string;
	public rfc: string;
	public opcDatoFiscal: boolean;
	public direccionDatoFiscal: boolean;
	public razonSocial: string;
  public usoCfdi: string;
  public tipoContribuyente: string;
  public contactoPagar: string;
  public emailContactoPagar: string;
	public paisDatoFiscal: number;
	public desPaisDatoFiscal: string;
	public codigoPostalDatoFiscal: string;
	public municipioDatoFiscal: number;
	public estadoDatoFiscal: number;
	public desMunicipioDatoFiscal: string;
	public desEstadoDatoFiscal: string;
	public calleDatoFiscal: string;
	public coloniaDatoFiscal: string;
	public clasificacion: number;
	public desClasificacion: string;
	public clase: number;
  public desClase: string;
  public fecRegistro: Date;
	public fecModificacion: Date;
	public url: string;
	public fundacion: Date;
  public fecFundacion: Date;
	public permisos: Permisos;
  public publicidad: boolean;
  public pagaPublicidad: boolean;
  public publicidadCompetencia: string;
  public check: boolean;

	constructor(
		obj: DataObjEmpresa
	) {
		this.id = obj && obj.id ? obj.id : 0
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.industria = obj && obj.industria ? obj.industria: 0;
		this.desIndustria = obj && obj.desIndustria ? obj.desIndustria : '';
		this.tipo = obj && obj.tipo ? obj.tipo: 0;
		this.desTipo = obj && obj.desTipo ? obj.desTipo: '';
		this.telefonoUno = obj && obj.telefonoUno ? obj.telefonoUno: '';
		this.telefonoDos = obj && obj.telefonoDos ? obj.telefonoDos: '';
		this.telefonoTres = obj && obj.telefonoTres ? obj.telefonoTres: '';
		this.calle = obj && obj.calle ? obj.calle: '';
		this.colonia = obj && obj.colonia ? obj.colonia : '';
		this.pais = obj && obj.pais ? obj.pais: null;
		this.desPais = obj && obj.desPais ? obj.desPais: '';
		this.codigoPostal = obj && obj.codigoPostal ? obj.codigoPostal : '';
		this.municipio = obj && obj.municipio ? obj.municipio: 0;
		this.estado = obj && obj.estado ? obj.estado : 0;
		this.desMunicipio = obj && obj.desMunicipio ? obj.desMunicipio: '';
		this.desEstado = obj && obj.desEstado ? obj.desEstado : '';
		this.imagen = obj && obj.imagen ? obj.imagen : '';
		this.status = obj && obj.status ? obj.status : false;
		this.propietario = obj && obj.propietario ? obj.propietario : null;
		this.desPropietario = obj && obj.desPropietario ? obj.desPropietario : '';
		this.rfc = obj && obj.rfc ? obj.rfc : '';
		this.opcDatoFiscal = obj && obj.opcDatoFiscal ? obj.opcDatoFiscal : false;
		this.direccionDatoFiscal = obj && obj.direccionDatoFiscal ? obj.direccionDatoFiscal : false;
		this.razonSocial = obj && obj.razonSocial ? obj.razonSocial: '';
    this.usoCfdi = obj && obj.usoCfdi ? obj.usoCfdi : null;
    this.tipoContribuyente = obj && obj.tipoContribuyente ? obj.tipoContribuyente : null;
    this.contactoPagar = obj && obj.contactoPagar ? obj.contactoPagar : null;
    this.emailContactoPagar = obj && obj.emailContactoPagar ? obj.emailContactoPagar : null;
		this.paisDatoFiscal = obj && obj.paisDatoFiscal ? obj.paisDatoFiscal: null;
		this.desPaisDatoFiscal = obj && obj.desPaisDatoFiscal ? obj.desPaisDatoFiscal: '';
		this.codigoPostalDatoFiscal = obj && obj.codigoPostalDatoFiscal ? obj.codigoPostalDatoFiscal : '';
		this.municipioDatoFiscal = obj && obj.municipioDatoFiscal ? obj.municipioDatoFiscal: 0;
		this.estadoDatoFiscal = obj && obj.estadoDatoFiscal ? obj.estadoDatoFiscal : 0;
		this.desMunicipioDatoFiscal = obj && obj.desMunicipioDatoFiscal ? obj.desMunicipioDatoFiscal: '';
		this.desEstadoDatoFiscal = obj && obj.desEstadoDatoFiscal ? obj.desEstadoDatoFiscal : '';
		this.calleDatoFiscal = obj && obj.calleDatoFiscal ? obj.calleDatoFiscal : '';
		this.coloniaDatoFiscal = obj && obj.coloniaDatoFiscal ? obj.coloniaDatoFiscal : '';
		this.clasificacion = obj && obj.clasificacion ? obj.clasificacion : null;
		this.desClasificacion = obj && obj.desClasificacion ? obj.desClasificacion : '';
		this.clase = obj && obj.clase ? obj.clase : null;
    this.desClase = obj && obj.desClase ? obj.desClase : '';
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
		this.fecModificacion = obj && obj.fecModificacion ? obj.fecModificacion : null;
		this.url = obj && obj.url ? obj.url : '';
		this.fundacion = obj && obj.fundacion ? obj.fundacion : null;
    this.fecFundacion = obj && obj.fecFundacion ? obj.fecFundacion : null;
		this.permisos = obj && obj.permisos ? obj.permisos : new Permisos(null);
    this.publicidad = obj && obj.publicidad ? obj.publicidad : false;
    this.pagaPublicidad = obj && obj.pagaPublicidad ? obj.pagaPublicidad : false;
    this.publicidadCompetencia = obj && obj.publicidadCompetencia ? obj.publicidadCompetencia : null;
    this.check = obj && obj.check ? obj.check : false;
  }
}

interface DataObjEmpresa{
  id?: number;
  nombre?: string;
  industria?: number;
  desIndustria?: string;
  tipo?: number;
  desTipo?: string;
  telefonoUno?: string;
  telefonoDos?: string;
  telefonoTres?: string;
  calle?: string;
  colonia?: string;
  pais?: number;
  desPais?: string;
  codigoPostal?: string;
  municipio?: number;
  estado?: number;
  desMunicipio?: string;
  desEstado?: string;
  status?: boolean;
  imagen?: string;
  propietario?: number;
  desPropietario?: string;
  rfc?: string;
  opcDatoFiscal?: boolean;
  direccionDatoFiscal?: boolean;
  razonSocial?: string;
  usoCfdi?: string;
  tipoContribuyente?: string;
  contactoPagar?: string;
  emailContactoPagar?: string;
  paisDatoFiscal?: number;
  desPaisDatoFiscal?: string;
  codigoPostalDatoFiscal?: string;
  municipioDatoFiscal?: number;
  estadoDatoFiscal?: number;
  desMunicipioDatoFiscal?: string;
  desEstadoDatoFiscal?: string;
  calleDatoFiscal?: string;
  coloniaDatoFiscal?: string;
  clasificacion?: number;
	desClasificacion?: string;
	clase?: number;
  desClase?: string;
  fecRegistro?: Date;
	fecModificacion?: Date;
	url?: string;
	fundacion?: Date;
  fecFundacion?: Date;
	permisos?: Permisos;
  publicidad?: boolean;
  pagaPublicidad?: boolean;
  publicidadCompetencia?: string;
  check?: boolean;
}

export class Permisos {
  public alarma: boolean;
  public asociacion: boolean;
  public blindaje: boolean;
  public cableado: boolean;
  public cajaFuerte: boolean;
  public controlAcceso: boolean;
  public controlConfianza: boolean;
  public consultoria: boolean;
  public cursosEntrenamiento: boolean;
  public custodiaMercancia: boolean;
  public distribuidorMayorista: boolean;
  public equipamientoPolicia: boolean;
  public equipoInspeccion: boolean;
  public guardia: boolean;
  public incendio: boolean;
  public integradores: boolean;
  public investigacion: boolean;
  public rastreoVehicular: boolean;
  public telecomTI: boolean;
  public trasladoValor: boolean;
  public videovigilancia: boolean;
  public otros: boolean;
  public descripcion: string;
  public escolta: boolean;

  constructor(
    obj: ObjDataPermisos
  ) {
    this.alarma = obj && obj.alarma ? obj.alarma : false;
    this.asociacion = obj && obj.asociacion ? obj.asociacion : false;
    this.blindaje = obj && obj.blindaje ? obj.blindaje : false;
    this.cableado = obj && obj.cableado ? obj.cableado : false;
    this.cajaFuerte = obj && obj.cajaFuerte ? obj.cajaFuerte : false;
    this.controlAcceso = obj && obj.controlAcceso ? obj.controlAcceso : false;
    this.controlConfianza = obj && obj.controlConfianza ? obj.controlConfianza : false;
    this.consultoria = obj && obj.consultoria ? obj.consultoria : false;
    this.cursosEntrenamiento = obj && obj.cursosEntrenamiento ? obj.cursosEntrenamiento : false;
    this.custodiaMercancia = obj && obj.custodiaMercancia ? obj.custodiaMercancia : false;
    this.distribuidorMayorista = obj && obj.distribuidorMayorista ? obj.distribuidorMayorista : false;
    this.equipamientoPolicia = obj && obj.equipamientoPolicia ? obj.equipamientoPolicia : false;
    this.equipoInspeccion = obj && obj.equipoInspeccion ? obj.equipoInspeccion : false;
    this.guardia = obj && obj.guardia ? obj.guardia : false;
    this.incendio = obj && obj.incendio ? obj.incendio : false;
    this.integradores = obj && obj.integradores ? obj.integradores : false;
    this.investigacion = obj && obj.investigacion ? obj.investigacion : false;
    this.rastreoVehicular = obj && obj.rastreoVehicular ? obj.rastreoVehicular : false;
    this.telecomTI = obj && obj.telecomTI ? obj.telecomTI : false;
    this.trasladoValor = obj && obj.trasladoValor ? obj.trasladoValor : false;
    this.videovigilancia = obj && obj.videovigilancia ? obj.videovigilancia : false;
    this.otros = obj && obj.otros ? obj.otros : false;
    this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
    this.escolta = obj && obj.escolta ? obj.escolta : false;
  }
}

interface ObjDataPermisos {
  alarma?: boolean;
  asociacion?: boolean;
  blindaje?: boolean;
  cableado?: boolean;
  cajaFuerte?: boolean;
  controlAcceso?: boolean;
  controlConfianza?: boolean;
  consultoria?: boolean;
  cursosEntrenamiento?: boolean;
  custodiaMercancia?: boolean;
  distribuidorMayorista?: boolean;
  equipamientoPolicia?: boolean;
  equipoInspeccion?: boolean;
  guardia?: boolean;
  incendio?: boolean;
  integradores?: boolean;
  investigacion?: boolean;
  rastreoVehicular?: boolean;
  telecomTI?: boolean;
  trasladoValor?: boolean;
  videovigilancia?: boolean;
  otros?: boolean;
  descripcion?: string;
  escolta?: boolean;
}

export const EmpresaSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombres'},
  { id: '-nombre', text: 'Nombres desc'},
  { id: '+desIndustria', text: 'Industria'},
  { id: '-desIndustria', text: 'Industria desc'},
  // { id: '+desTipo', text: 'Tipo'},
  // { id: '-desTipo', text: 'Tipo desc'},
  { id: '+codigoPostal', text: 'Código postal'},
  { id: '-codigoPostal', text: 'Código postal desc'},
  { id: '+desMunicipio', text: 'Municipio'},
  { id: '-desMunicipio', text: 'Municipio desc'},
  { id: '+desEstado', text: 'Estado'},
  { id: '-desEstado', text: 'Estado desc'},
  { id: '+desPais', text: 'País'},
  { id: '-desPais', text: 'País desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'},
  { id: '+fecModificacion', text: 'Fecha de modificación'},
  { id: '-fecModificacion', text: 'Fecha de modificación desc'}
];


export const EmpresaPermiFilter: UserType[] = [
  { id: 1, nombre: 'Alarma', descripcion:'alarma', checked: false },
  { id: 2, nombre: 'Asociación', descripcion:'asociacion', checked: false },
  { id: 3, nombre: 'Blindaje', descripcion:'blindaje', checked: false },
  { id: 4, nombre: 'Cableado', descripcion:'cableado', checked: false },
  { id: 5, nombre: 'Caja fuerte', descripcion:'cajaFuerte', checked: false },
  { id: 6, nombre: 'Control de acceso', descripcion:'controlAcceso', checked: false },
  { id: 7, nombre: 'Control de confianza', descripcion:'controlConfianza', checked: false },
  { id: 8, nombre: 'Consultoría', descripcion:'consultoria', checked: false },
  { id: 9, nombre: 'Cursos de entrenamiento', descripcion:'cursosEntrenamiento', checked: false },
  { id: 10, nombre: 'Custodia de mercancía', descripcion:'custodiaMercancia', checked: false },
  { id: 11, nombre: 'Distribuidor mayorista', descripcion:'distribuidorMayorista', checked: false },
  { id: 12, nombre: 'Equipamiento Policíaco', descripcion:'equipamientoPolicia', checked: false },
  { id: 13, nombre: 'Equipo de inspección', descripcion:'equipoInspeccion', checked: false },
  { id: 14, nombre: 'Guardia', descripcion:'guardia', checked: false },
  { id: 15, nombre: 'Escolta', descripcion:'escolta', checked: false },
  { id: 16, nombre: 'Incendio', descripcion:'incendio', checked: false },
  { id: 17, nombre: 'Integradores', descripcion:'integradores', checked: false },
  { id: 18, nombre: 'Investigación', descripcion:'investigacion', checked: false },
  { id: 19, nombre: 'Rastreo vehicular', descripcion:'rastreoVehicular', checked: false },
  { id: 20, nombre: 'Telecom TI', descripcion:'telecomTI', checked: false },
  { id: 21, nombre: 'Traslado de valor', descripcion:'trasladoValor', checked: false },
  { id: 22, nombre: 'Video vigilancia', descripcion:'videovigilancia', checked: false },
  { id: 23, nombre: 'Otros', descripcion:'otros', checked: false }
];
