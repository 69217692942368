<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row mb-5" *ngIf="identity.permisos">
  <div class="col-sm-10 col-md-5">
    <div class="main-card mb-3 card">
      <div class="card-header">
        Captura la información del usuario
      </div>
      <section class="card-body">
        <form #registerUsuarioForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="usuarioUser" class="control-label">Usuario</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit || user.id > 0" type="text" id="usuarioUser" name="usuarioUser" #usuarioUser="ngModel" [(ngModel)]="user.usuario"
                  class="form-control" maxlength="30" minlength="3" placeholder="" autocomplete="off" pattern="[a-zA-Z0-9_\-.]*" 
                  title="Capture el nickname del usuario." (focusout)="usuarioLower()" required />
                <span *ngIf="!usuarioUser.valid && usuarioUser.touched">El formato requiere letras y números sin espacios</span>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="nombreUser" class="control-label">Nombre</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="text" id="nombreUser" name="nombreUser" #nombreUser="ngModel" [(ngModel)]="user.nombre"
                  class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el nombre personal." required />
                <!-- <span *ngIf="!nombreUser.valid && nombreUser.touched"> El nombre es obligatorio</span> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="apellidoPaternoUser" class="control-label">Apellido Paterno</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="text" id="apellidoPaternoUser" name="apellidoPaternoUser" #apellidoPaternoUser="ngModel"
                  [(ngModel)]="user.apellidoPaterno" class="form-control" maxlength="100" minlength="2"
                  placeholder="" title="Capture el apellido paterno." required />
                <!-- <span *ngIf="!apellidoPaternoUser.valid && apellidoPaternoUser.touched"> El apellido paterno es obligatorio!!</span> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <div class="row">
              <div class="col-sm-4">
                <label for="apellidoMaternoUser" class="control-label">Apellido Materno</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="text" id="apellidoMaternoUser" name="apellidoMaternoUser" #apellidoMaternoUser="ngModel"
                  [(ngModel)]="user.apellidoMaterno" class="form-control" maxlength="100" placeholder="" title="Capture el apellido materno." />
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="emailUser" class="control-label">Email</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="email" appLowerChart id="emailUser" name="emailUser" #emailUser="ngModel" [(ngModel)]="user.email"
                  class="form-control" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxlength="100"
                  placeholder="" title="Capture un correo electrónico." required />
                <!-- <span *ngIf="!emailUser.valid && emailUser.touched"> El correo electrónico no es correcto</span> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <div class="row">
              <div class="col-sm-4">
                <label for="telefonoUser" class="control-label">Teléfono</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="text" phone-number id="telefonoUser" name="telefonoUser" #telefonoUser="ngModel" [(ngModel)]="user.telefono"
                  class="form-control" maxlength="50" title="Capture un número de teléfono." />
                <!-- <span *ngIf="!telefonoUser.valid && telefonoUser.touched"> El número de teléfono tiene un formato incorrecto</span> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label for="puestoUser" class="control-label">Puesto</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="text" id="puestoUser" name="puestoUser" #puestoUser="ngModel"
                  [(ngModel)]="user.puesto" class="form-control" maxlength="100" placeholder="" title="Capture el puesto del usuario." required/>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required" *ngIf="user.id <= 0">
            <div class="row">
              <div class="col-sm-4">
                <label for="passwordUser" class="control-label">Contraseña</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="password" id="passwordUser" name="passwordUser" #passwordUser="ngModel" [(ngModel)]="user.password"
                  class="form-control" maxlength="100" minlength="6" placeholder="" title="Capture contraseña de 6 a 50 caracteres." required />
                <!-- <span *ngIf="!passwordUser.valid && passwordUser.touched"> La contraseña es obligatoria</span> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required" *ngIf="user.id <= 0">
            <div class="row">
              <div class="col-sm-4">
                <label for="passwordConfirmUser" class="control-label">Confirmar contraseña</label>
              </div>
              <div class="col-sm-8">
                <input [disabled]="!identity.permisos.usuariosRegEdit" type="password" id="passwordConfirmUser" name="passwordConfirmUser" #passwordConfirmUser="ngModel"
                  [(ngModel)]="user.passwordConfirmar" class="form-control" maxlength="100" minlength="6" placeholder=""
                  title="Reingrese la contraseña de 6 a 50 caracteres." required />
                <!-- <span *ngIf="!passwordConfirmUser.valid && passwordConfirmUser.touched"> La contraseña es obligatoria</span> -->
                <!-- <div class="invalid-feedback"> La contraseña no coincide</div> -->
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-12">
                <div class="alert alert-secondary" role="alert" *ngIf="textDescripcionUser">
                  {{textDescripcionUser}}
                </div>
              </div>
              <div class="col-sm-4">
                <label class="control-label">Roles de usuario: </label>
              </div>
              <div class="col-sm-8">
                <div class="custom-radio custom-control" *ngFor="let tpUser of tipos; let i = index">
                  <input [disabled]="!identity.permisos.usuariosRegEdit" class="custom-control-input" id="tipoUser{{i}}" name="tipoUser{{i}}" [value]="tpUser.id" [(ngModel)]="user.tipo" type="radio" (change)="changeTipo()">
                  <label class="custom-control-label" for="tipoUser{{i}}">{{tpUser.nombre}}</label>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group required">
            <div class="row">
              <div class="col-sm-4">
                <label class="control-label">Departamento: </label>
              </div>
              <div class="col-sm-8">
                <ng-select [items]="departamentos"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture el departamento."
                  id="departamentoUsuario" name="departamentoUsuario" #departamentoUsuario="ngModel" [(ngModel)]="user.departamento" [disabled]="!identity.permisos.usuariosRegEdit" required>
                </ng-select>
              </div>
            </div>
          </fieldset>
        </form>
      </section>
    </div>
  </div>
  <div class="col-sm-10 col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Selección de imagen para el perfil
      </div>
      <div class="card-body">
        <div class="custom-file mb-3">
          <input [disabled]="!identity.permisos.usuariosRegEdit" type="file" class="custom-file-input" (change)="fileChangeEvent($event)" title="Seleccione una imagen." placeholder="Elige un archivo..." required>
          <label class="custom-file-label">Elige un archivo...</label>
          <div class="invalid-feedback">Archivo invalido</div>
        </div>
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="4 / 4"
          [resizeToWidth]="256"
          [cropperMinWidth]="256"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          format="png"
          outputType="base64"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
          [style.display]="showCropper ? null : 'none'"
        ></image-cropper>
      </div>
      <div class="card-footer" *ngIf="user.imagen">
        <div class="text-center">
          <button class="btn btn-sm mr-2 btn-primary" (click)="rotateLeft()">
            <i class="fa fa-undo"></i> Girar izquierda
          </button>
          <button class="btn btn-sm mr-2 btn-primary" (click)="rotateRight()">
            <i class="fa fa-undo fa-flip-horizontal"></i> Girar derecha
          </button>
          <button class="btn btn-sm mr-2 btn-primary" (click)="flipHorizontal()">
            <i class="fab fa-flipboard fa-rotate-270"></i> Invertir horizontal
          </button>
          <button class="btn btn-sm mr-2 btn-primary" (click)="flipVertical()">
            <i class="fab fa-flipboard fa-flip-horizontal"></i> Invertir vertical
          </button>
        </div>
      </div>
      <div class="grid-menu grid-menu-2col">
        <div class="no-gutters row">
          <div class="col-sm-6" *ngIf="identity.permisos.usuariosListVer || identity.permisos.usuariosListEdit">
            <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/users']">
              <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
            </a>
          </div>
          <div class="col-sm-6" *ngIf="identity.permisos.usuariosRegEdit">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" (click)="onSubmit()" *ngIf="!cargando" [disabled]="!registerUsuarioForm.valid">
              <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
            </button>
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="cargando">
              <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
              <br>
              Loading...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
