<div class="app-sidebar sidebar-shadow bg-dark text-lighter app-side-secondary" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content" *ngIf="identity && identity.permisos">
  <div class="app-sidebar-scroll">
      <perfect-scrollbar>
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <div class="vsm-header">Menu principal</div>
            <div class="vsm-item">
              <a routerLinkActive="active-item" routerLink="/auth/dashboard" class="vsm-link">
                <i class="vsm-icon pe-7s-timer"></i>
                <span class="vsm-title">Dashboard</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="(identity.permisos.usuariosListVer || identity.permisos.usuariosListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/users" class="vsm-link">
                <i class="vsm-icon pe-7s-users"></i>
                <span class="vsm-title">Usuarios</span>
              </a>
            </div>
            <!-- identity.permisos.catTipoEmpresasVer || identity.permisos.catTipoEmpresasEdit || -->
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="identity.permisos
              && (identity.permisos.catIndustriasVer || identity.permisos.catIndustriasEdit ||
                 
                  identity.permisos.catOrigenesVer || identity.permisos.catOrigenesEdit ||
                  identity.permisos.catPaisesVer || identity.permisos.catPaisesEdit )">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-notebook"></i>
                    <span class="vsm-title">Catálogos</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngIf="(identity.permisos.catIndustriasVer || identity.permisos.catIndustriasEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/industrias']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Industrias</span>
                        </a>
                      </div>
                      <!-- <div class="vsm-item" *ngIf="(identity.permisos.catTipoEmpresasVer || identity.permisos.catTipoEmpresasEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/tipo-empresas']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Tipo empresas</span>
                        </a>
                      </div> -->
                      <div class="vsm-item" *ngIf="(identity.permisos.catOrigenesVer || identity.permisos.catOrigenesEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/origen-contactos']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Origen contactos</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(identity.permisos.catPaisesVer || identity.permisos.catPaisesEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/paises']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Países</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(identity.permisos.catDepartamentosVer || identity.permisos.catDepartamentosEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/catalogos/departamentos']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Departamentos</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="vsm-item" *ngIf="(identity.permisos.empresasListEdit || identity.permisos.empresasListVer)">
              <a routerLinkActive="active-item" routerLink="/auth/empresas" class="vsm-link">
                <i class="vsm-icon far fa-building"></i>
                <span class="vsm-title">Empresas</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="(identity.permisos.contactosListVer || identity.permisos.contactosListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/contactos" class="vsm-link">
                <i class="vsm-icon pe-7s-id"></i>
                <span class="vsm-title">Contactos</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="(identity.permisos.oportunidadesListVer || identity.permisos.oportunidadesListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/oportunidades" class="vsm-link">
                <i class="vsm-icon fas fa-hand-holding-usd"></i>
                <span class="vsm-title">Oportunidades</span>
              </a>
            </div>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="identity.permisos
              && (identity.permisos.campannasEdit || identity.permisos.campannasVer ||
                  identity.permisos.plantillasVer || identity.permisos.plantillasEdit )">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-mail"></i>
                    <span class="vsm-title">Marketing</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item" *ngIf="(identity.permisos.campannasEdit || identity.permisos.campannasVer)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/marketing/allcampanas']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Campañas</span>
                        </a>
                      </div>
                      <div class="vsm-item" *ngIf="(identity.permisos.plantillasVer || identity.permisos.plantillasEdit)">
                        <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/marketing/plantillas']">
                          <span class="vsm-title"><i class="fa fa-circle-o"></i> Plantillas</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="vsm-item" *ngIf="(identity.permisos.archivosListVer || identity.permisos.archivosListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/archivos" class="vsm-link">
                <i class="vsm-icon pe-7s-file"></i>
                <span class="vsm-title">Archivos</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="(identity.permisos.repositoriosListVer || identity.permisos.repositoriosListEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/repositorios" class="vsm-link">
                <i class="vsm-icon pe-7s-folder"></i>
                <span class="vsm-title">Repositorio editorial</span>
              </a>
            </div>

            <div class="vsm-item" *ngIf="identity.permisos && (
              identity.permisos.rptKpiVendedores || identity.permisos.rptSepomex ||
              identity.permisos.rptCumpleannos || identity.permisos.rptOportunidadEtapas || 
              identity.permisos.rptOportunidadPropietarios)">
              <a routerLinkActive="active-item" routerLink="/auth/reportes" class="vsm-link">
                <i class="vsm-icon pe-7s-display2"></i>
                <span class="vsm-title">Reportes</span>
              </a>
            </div>


            <!-- <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter" *ngIf="identity.permisos && (
              identity.permisos.rptKpiVendedores || identity.permisos.rptSepomex ||
              identity.permisos.rptCumpleannos || identity.permisos.rptOportunidadEtapas || 
              identity.permisos.rptOportunidadPropietarios)">
            <ngb-panel>
              <ng-template ngbPanelHeader>
                <button ngbPanelToggle class="vsm-link">
                  <i class="vsm-icon pe-7s-display2"></i>
                  <span class="vsm-title">Reportes</span>
                  <i class="vsm-arrow"></i>
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item" *ngIf="identity.permisos && identity.permisos.rptKpiVendedores">
                      <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/reportes/kpis-vendedores']">
                        <span class="vsm-title"><i class="fa fa-circle-o"></i> KPIs vendedores</span>
                      </a>
                    </div>
                    <div class="vsm-item" *ngIf="identity.permisos && identity.permisos.rptSepomex">
                      <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/reportes/sepomex']">
                        <span class="vsm-title"><i class="fa fa-circle-o"></i> SEPOMEX</span>
                      </a>
                    </div>
                    <div class="vsm-item" *ngIf="identity.permisos && identity.permisos.rptCumpleannos">
                      <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/reportes/cumpleannos']">
                        <span class="vsm-title"><i class="fa fa-circle-o"></i> Cumpleaños</span>
                      </a>
                    </div>
                    <div class="vsm-item" *ngIf="identity.permisos && identity.permisos.rptOportunidadEtapas">
                      <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/reportes/oportunidad/etapas']">
                        <span class="vsm-title"><i class="fa fa-circle-o"></i> Oportunidad por etapa</span>
                      </a>
                    </div>
                    <div class="vsm-item" *ngIf="identity.permisos && identity.permisos.rptOportunidadPropietarios">
                      <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/reportes/oportunidad/propietarios']">
                        <span class="vsm-title"><i class="fa fa-circle-o"></i> Oportunidad por propietario</span>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion> -->
            <div class="vsm-item" *ngIf="(identity.permisos.ticketsVer || identity.permisos.ticketsEdit)">
              <a routerLinkActive="active-item" routerLink="/auth/tickets" class="vsm-link">
                <i class="vsm-icon pe-7s-ticket"></i>
                <span class="vsm-title">Tickets</span>
              </a>
            </div>
            <hr class="d-block d-lg-none">
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/change-password']">
                <i class="vsm-icon pe-7s-unlock"></i>
                <span class="vsm-title">Cambiar contraseña</span>
              </a>
            </div>
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/setting-profile']">
                <i class="vsm-icon pe-7s-user"></i>
                <span class="vsm-title">Perfil</span>
              </a>
            </div>
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/notificaciones']">
                <i class="vsm-icon lnr-bullhorn"></i>
                <span class="vsm-title">Notificaciones</span>
              </a>
            </div>
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" [routerLink]="['/auth/actividades/programadas']">
                <i class="vsm-icon pe-7s-bell"></i>
                <span class="vsm-title">Actividades</span>
              </a>
            </div>
            <div class="vsm-item d-block d-lg-none">
              <a routerLinkActive="active-item" class="vsm-link" (click)="logout()">
                <i class="vsm-icon pe-7s-back-2"></i>
                <span class="vsm-title">Cerrar sesión</span>
              </a>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
