import { SortOrder } from './sort';

export class Origen {
  public id: number;
  public nombre: string;
  public descripcion: boolean;
  public status: boolean;
  constructor(
    obj: ObjOrigen
  ) {
    this.id = obj && obj.id ? obj.id : 0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.descripcion = obj && obj.descripcion ? obj.descripcion : false;
    this.status = obj && obj.status ? obj.status : false;
  }
}

interface ObjOrigen {
  id: number;
  nombre: string;
  descripcion: boolean;
  status: boolean;
}


export const OrigenSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombre'},
	{ id: '-nombre', text: 'Nombre desc'},
	{ id: '+descripcion', text: 'Descripción'},
  { id: '-descripcion', text: 'Descripción desc'}
];

