<section class="content mt-4">
  <div class="container">
    <div class="card text-darker text-center">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7">
            <div class="d-flex" style="height: 350px;">
              <div style="margin: auto;">
                <h1 class="title-not-found">404</h1>
                <!-- <p class="lead-not-found mb-1">Ooops!...</p> -->
                <p class="lead-not-found">Sorry, the page not found.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <img class="demo-image-bg" src="assets/images/confused.gif" />
          </div>
        </div>
        <p class="text-muted h4">You might want to go to the <a (click)="back()" class="text-primary">previous page</a></p>
      </div>
    </div>
  </div>
</section>
