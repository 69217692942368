import { Action } from '@ngrx/store';
import { Catalogo } from 'src/app/models/catalogo';

export const CARGAR_TIPO_EMPRESAS = '[TipoEmpresa] Cargar tipo empresa';
export const CARGAR_TIPO_EMPRESAS_SUCCESS = '[TipoEmpresa] Cargar tipo empresa success';
export const CARGAR_TIPO_EMPRESAS_FAIL = '[TipoEmpresa] Cargar tipo empresa fail';
export const ACTUALIZA_TIPO_EMPRESA = '[TipoEmpresa] Actualiza tipo empresa';
export const ACTUALIZA_TIPO_EMPRESA_FAIL = '[TipoEmpresa] Actualiza tipo empresa FAIL';
export const ACTUALIZA_TIPO_EMPRESA_SUCCESS = '[TipoEmpresa] Actualiza tipo empresa SUCCESS';
export const ACTUALIZA_TIPO_EMPRESA_ESTATUS = '[TipoEmpresa] Actualiza tipo empresa estatus';
export const ACTUALIZA_TIPO_EMPRESA_ESTATUS_FAIL = '[TipoEmpresa] Actualiza tipo empresa estatus FAIL';
export const ACTUALIZA_TIPO_EMPRESA_ESTATUS_SUCCESS = '[TipoEmpresa] Actualiza tipo empresa estatus SUCCESS';


export class CargarTipoEmpresas implements Action {
  readonly type = CARGAR_TIPO_EMPRESAS;
}

export class CargarTipoEmpresasFail implements Action {
  readonly type = CARGAR_TIPO_EMPRESAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarTipoEmpresasSuccess implements Action {
  readonly type = CARGAR_TIPO_EMPRESAS_SUCCESS;

  constructor(public tipo: Catalogo[]) {}
}

export class ActualizaTipoEmpresa implements Action {
  readonly type = ACTUALIZA_TIPO_EMPRESA;

  constructor( public tipo: Catalogo ) { }
}

export class ActualizaTipoEmpresaFail implements Action {
  readonly type = ACTUALIZA_TIPO_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTipoEmpresaSuccess implements Action {
  readonly type = ACTUALIZA_TIPO_EMPRESA_SUCCESS;

  constructor( public tipo: Catalogo ) {}
}

export class ActualizaTipoEmpresaEstatus implements Action {
  readonly type = ACTUALIZA_TIPO_EMPRESA_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaTipoEmpresaEstatusFail implements Action {
  readonly type = ACTUALIZA_TIPO_EMPRESA_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTipoEmpresaEstatusSuccess implements Action {
  readonly type = ACTUALIZA_TIPO_EMPRESA_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type tipoEmpresaAcciones = CargarTipoEmpresas 
                                | CargarTipoEmpresasFail 
                                | CargarTipoEmpresasSuccess
                                | ActualizaTipoEmpresa 
                                | ActualizaTipoEmpresaFail 
                                | ActualizaTipoEmpresaSuccess 
                                | ActualizaTipoEmpresaEstatus 
                                | ActualizaTipoEmpresaEstatusFail 
                                | ActualizaTipoEmpresaEstatusSuccess;
