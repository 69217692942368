<li class="list-group-item" *ngFor="let ticket of tickets">
  <div class="todo-indicator mr-5" [ngClass]="{'bg-success': ticket.usuarioRegistro === identity.id && ticket.existe, 
  'bg-primary': ticket.usuarioRegistro !== identity.id && ticket.existe,
  'bg-dark': !ticket.existe}"></div>
  <div class="widget-content p-0">
    <div class="widget-content-wrapper">
      <div class="widget-content-left flex2">
        <a class="widget-heading pointer text-dark" [routerLink]="['/auth/tickets/detail', ticket.id]">#{{ticket.id}} - {{ticket.asunto}}</a>
        <div class="widget-subheading">De {{ticket.desUsuarioRegistro}} <i class="fas fa-long-arrow-alt-right"></i> {{ticket.desDepartamento}}</div>
      </div>
      <div class="widget-content-right ml-3">
        <div class="widget-heading">Última modificación: {{ticket.fecModificacion | amLocale: 'es'  | amTimeAgo}}</div>
        <div class="widget-subheading">
          <div class="row">
            <div class="mr-auto">
              <div class="float-left">
                <div class="col-6">
                  <div class="widget-content-right widget-content-actions" *ngIf="ticket.usuarioRegistro === identity.id && ticket.status !== 3">
                    <button type="button" class="border-0 btn-transition btn-square btn btn-outline-danger" (click)="editarEstado(ticket)">
                      <!-- <i class="fa fa-close"></i> -->
                      {{ (ticket.status === 1 ? 'Eliminar' : 'Cerrar') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="float-right">
                <h6><div class="badge badge-pill" [ngClass]="{'badge-success': ticket.usuarioRegistro === identity.id && ticket.existe, 
                                                              'badge-primary': ticket.usuarioRegistro !== identity.id && ticket.existe,
                                                              'badge-dark': !ticket.existe}" *ngIf="ticket.status === 1">Nuevo</div></h6>
                <h6><div class="badge badge-pill" [ngClass]="{'badge-success': ticket.usuarioRespondio === identity.id && ticket.existe, 
                                                              'badge-warning': ticket.usuarioRegistro === identity.id && ticket.usuarioRespondio !== identity.id && ticket.existe, 
                                                              'badge-primary': ticket.usuarioRegistro !== identity.id && ticket.usuarioRespondio !== identity.id && ticket.existe,
                                                              'badge-dark': !ticket.existe}" *ngIf="ticket.status === 2">{{ (identity.id === ticket.usuarioRespondio ? 'Actualizado' : 'Respondido') }}</div></h6>
                <h6><div class="badge badge-pill badge-secondary" *ngIf="ticket.status === 3">Cerrado</div></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</li>