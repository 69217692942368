import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EditarPlantilla } from 'src/app/models/campana';
import { PlantillasService } from 'src/app/services/plantillas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-plantilla',
  templateUrl: './editar-plantilla.component.html',
  styleUrls: ['./editar-plantilla.component.scss']
})
export class EditarPlantillaComponent implements OnInit {

  public editarplantilla: EditarPlantilla = new EditarPlantilla(null);

  constructor(private plantillasservices: PlantillasService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      const id = params.id;
      this.plantillasservices.obtenerPlantillaAuth(id).subscribe(respuesta => {
        this.editarplantilla = respuesta.plantillahtml;
        if (this.editarplantilla.activo == true) {
        } else {
          this.router.navigate(['/auth/marketing/plantillas']);
          Swal.fire(
            'Error',
            'Esta plantilla no puede acceder a esta sección.',
            'error'
          )
        };
      });

    });
  }

  ActualizarPlantilla(editarplantilla) {
    console.log('a')
    this.plantillasservices.actualizarPlantilla(editarplantilla.id, editarplantilla).subscribe(
      (respuesta: any) => {
        this.toastr.success('Se actualizó la campana.');
        // Puedes cambiar de ruta o no hacer nada
        this.router.navigate(['/auth/marketing/plantillas']);
        // this.campana = respuesta.campana;
      }, err => {
        console.log('Ocurrio un error al actualizar la campana', err);
      }
    );
  }

}
