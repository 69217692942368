<div class="header-dots">
  <div class="btn-group" ngbDropdown autoClose="outside" placement="bottom">
    <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-danger"></div>
          <i class="pe-7s-bell text-danger"></i>
          <div class="badge badge-dot badge-dot-sm badge-danger" *ngIf="actividades.length > 0"></div>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-dark">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-light">
            <h5 class="menu-header-title">Actividades</h5>
            <h6 class="menu-header-subtitle">Tienes
              <b>
                <span>{{(actividades).length}}</span>
              </b>
               pendientes
            </h6>
          </div>
        </div>
      </div>
      <div class="card-body p-1">
        <div class="scroll-gradient">
          <div class="scroll-area-md shadow-overflow">
            <perfect-scrollbar [autoPropagation]="true">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let actividad of actividades" (dblclick)="routerUrl(actividad)">
                  <div class="no-gutters row">
                    <div class="col-12">
                      <div class="card no-shadow bg-transparent text-left">
                        <div class="widget-content p-0">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                              <div class="widget-description">
                                <b *ngIf="actividad.concepto === 2">Llamada: </b>
                                <b *ngIf="actividad.concepto === 3">Reunión: </b>
                                <b *ngIf="actividad.concepto === 4">Correo electrónico: </b>
                                <b *ngIf="actividad.concepto === 5">Reunión: </b>
                                {{actividad.descripcion}}, {{actividad.hora | amLocale: 'es' | amDateFormat: 'LTS' | timeFormat}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="nav-justified">
          <ul class="nav flex-column">
            <!-- <li class="nav-item-divider nav-item mt-0"></li> -->
            <li class="nav-item-btn text-center nav-item">
              <a class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" [routerLink]="['/auth/actividades/programadas']">Ver más</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>






