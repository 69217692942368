import { Action } from '@ngrx/store';
import { Catalogo } from 'src/app/models/catalogo';

export const CARGAR_DEPARTAMENTOS = '[Departamentos] Cargar departamentos';
export const CARGAR_DEPARTAMENTOS_SUCCESS = '[Departamentos] Cargar departamentos success';
export const CARGAR_DEPARTAMENTOS_FAIL = '[Departamentos] Cargar departamentos fail';
export const ACTUALIZA_DEPARTAMENTO = '[Departamentos] Actualiza departamentos';
export const ACTUALIZA_DEPARTAMENTO_FAIL = '[Departamentos] Actualiza departamentos FAIL';
export const ACTUALIZA_DEPARTAMENTO_SUCCESS = '[Departamentos] Actualiza departamentos SUCCESS';
export const ACTUALIZA_DEPARTAMENTO_ESTATUS = '[Departamentos] Actualiza departamentos estatus';
export const ACTUALIZA_DEPARTAMENTO_ESTATUS_FAIL = '[Departamentos] Actualiza departamentos estatus FAIL';
export const ACTUALIZA_DEPARTAMENTO_ESTATUS_SUCCESS = '[Departamentos] Actualiza departamentos estatus SUCCESS';


export class CargarDepartamentos implements Action {
  readonly type = CARGAR_DEPARTAMENTOS;
}

export class CargarDepartamentosFail implements Action {
  readonly type = CARGAR_DEPARTAMENTOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDepartamentosSuccess implements Action {
  readonly type = CARGAR_DEPARTAMENTOS_SUCCESS;

  constructor(public departamento: Catalogo[]) {}
}

export class ActualizaDepartamento implements Action {
  readonly type = ACTUALIZA_DEPARTAMENTO;

  constructor( public departamento: Catalogo ) { }
}

export class ActualizaDepartamentoFail implements Action {
  readonly type = ACTUALIZA_DEPARTAMENTO_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaDepartamentoSuccess implements Action {
  readonly type = ACTUALIZA_DEPARTAMENTO_SUCCESS;

  constructor( public departamento: Catalogo ) {}
}

export class ActualizaDepartamentoEstatus implements Action {
  readonly type = ACTUALIZA_DEPARTAMENTO_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaDepartamentoEstatusFail implements Action {
  readonly type = ACTUALIZA_DEPARTAMENTO_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaDepartamentoEstatusSuccess implements Action {
  readonly type = ACTUALIZA_DEPARTAMENTO_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type departamentosAcciones = CargarDepartamentos 
                                  | CargarDepartamentosFail 
                                  | CargarDepartamentosSuccess
                                  | ActualizaDepartamento 
                                  | ActualizaDepartamentoFail 
                                  | ActualizaDepartamentoSuccess 
                                  | ActualizaDepartamentoEstatus 
                                  | ActualizaDepartamentoEstatusFail 
                                  | ActualizaDepartamentoEstatusSuccess;
