import { SortOrder } from './sort';
export class Catalogo {
  public id: number;
  public nombre: string;
  public status: boolean;
  constructor(
    obj?: ObjCatalogo
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.status = obj && obj.status ? obj.status : false;
  }
}

interface ObjCatalogo {
  id?: number;
  nombre?: string;
  status?: boolean;
}


export const CatalogoSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombre'},
  { id: '-nombre', text: 'Nombre desc'}
];

