import * as fromArchivos from '../actions/galeria-repositorio.actions';
import { GaleriaRepositorio } from '../../models/galeria-repositorio';
import { Album } from '../../models/album';

export interface GaleriaRepositorioState {
  archivos: GaleriaRepositorio[];
  archivo: GaleriaRepositorio;
  albumes: Album[];
  album: Album;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: GaleriaRepositorioState = {
  archivos: [],
  archivo: new GaleriaRepositorio(null),
  albumes: [],
  album: new Album(null),
  loaded: false,
  loading: false,
  error: null
};

// tslint:disable-next-line:max-line-length
export function galeriaRepositorioReducer( state = initState, action: fromArchivos.galeriaRepositorioAcciones ): GaleriaRepositorioState {

  switch ( action.type ) {
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_TODAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_TODAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        archivos: [...action.archivos]
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_TODAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUMES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUMES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        albumes: [...action.albumes]
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUMES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        archivos: [...action.archivos],
        album: {...action.album}
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        album: {...action.album}
      };
    case fromArchivos.CARGAR_GALERIA_REPOSITORIO_ALBUM_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.ELIMINAR_GALERIA_REPOSITORIO_ALBUM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.ELIMINAR_GALERIA_REPOSITORIO_ALBUM_SUCCESS:
      state.archivos = state.archivos.filter( element => element.album !== action.album.id );
      state.albumes = state.albumes.filter( element => element.id !== action.album.id );
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.ELIMINAR_GALERIA_REPOSITORIO_ALBUM_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.CARGAR_IMAGEN_GALERIA_REPOSITORIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.CARGAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        archivo: {...action.archivo}
      };
    case fromArchivos.CARGAR_IMAGEN_GALERIA_REPOSITORIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.REGISTRAR_IMAGEN_GALERIA_REPOSITORIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.REGISTRAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS:
      const item = state.archivos.find( element => element.id === action.archivo.id);
      if (item && item.id && item.id > 0) {
        state.archivos = state.archivos.map(
          element => {
            if (element.id === action.archivo.id) {
              return action.archivo;
            }
            return element;
          }
        );
      } else {
        state.archivos.push({...action.archivo});
      }
      if (state.archivo.id === action.archivo.id) {
        state.archivo = {...action.archivo};
      }
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.REGISTRAR_IMAGEN_GALERIA_REPOSITORIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.REGISTRAR_GALERIA_REPOSITORIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.REGISTRAR_GALERIA_REPOSITORIO_SUCCESS:
      action.archivos.forEach(archivo => {
        const archivoItem = state.archivos.find( element2 => element2.id === archivo.id);
        if (archivoItem && archivoItem.id && archivoItem.id > 0) {
          state.archivos = state.archivos.map(
            element => {
              if (element.id === archivo.id) {
                return archivo;
              }
              return element;
            }
          );
        } else {
          state.archivos.push({...archivo});
        }
        if (state.archivo.id === archivo.id) {
          state.archivo = {...archivo};
        }
      });
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.REGISTRAR_GALERIA_REPOSITORIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.ELIMINAR_IMAGEN_GALERIA_REPOSITORIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.ELIMINAR_IMAGEN_GALERIA_REPOSITORIO_SUCCESS:
      state.archivos = state.archivos.filter( archivo => archivo.id !== action.archivo.id );
      state.albumes = state.albumes
        .filter( album => !(album.id === action.archivo.album && album.cantidad <= 1)  )
        .map( (album) => {
          if (album.path === action.archivo.path && state.archivos.length) {
            const imagen = state.archivos.find( oArchivo => oArchivo.album === album.id);
            album.path = imagen.path;
          }
          if (album.id === action.archivo.album) {
            album.cantidad = album.cantidad - 1;
          }
          return album;
        }
      );
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.ELIMINAR_IMAGEN_GALERIA_REPOSITORIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromArchivos.UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromArchivos.UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE_SUCCESS:
      const exist = state.albumes.find( element => element.id === action.album.id);
      if (exist && exist.id) {
        state.albumes = state.albumes.map( element => {
          if (element.id === exist.id) {
            element = exist;
          }
          return element;
        });
      } else {
        state.albumes.unshift(action.album);
      }
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromArchivos.UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}
