import { Directive, Input, OnChanges, HostBinding } from '@angular/core';

enum CardType {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMERICAN_EXPRESS = 'american-express',
  UNKNOWN = 'unknown'
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ccLogo]'
})
export class CreditCardImageDirective implements OnChanges {

  @HostBinding('src')
  imageSource;

  @Input()
  cardNumber: string;

  constructor() { }

  ngOnChanges() {
    this.imageSource = 'assets/images/card-types/' + this.getCardTypeFromNumber() + '.png';
  }

  getCardTypeFromNumber(): CardType {
    if (this.cardNumber) {
      if (this.cardNumber.startsWith('37')) {
        return CardType.AMERICAN_EXPRESS;
      } else if (this.cardNumber.startsWith('4')) {
        return CardType.VISA;
      } else if (this.cardNumber.startsWith('5')) {
        return CardType.MASTERCARD;
      }
    }
    return CardType.UNKNOWN;
  }

}
