import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Identity, FilterType } from 'src/app/models/user';
import { Pagination } from 'src/app/models/pagination';
import { ContactoSortType, Contacto } from 'src/app/models/contacto';
import { Router } from '@angular/router';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { ExcelService } from 'src/app/services/excel.service';
import { ReportesService } from 'src/app/services/reportes.service';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { CampanasService } from 'src/app/services/campanas.service';
import { NgbDate, NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ReporteAutoActualizacion, ReporteAutoActualizacionSortType, StatusActualizacionFilter } from 'src/app/models/auto-actualizacion';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reporte-auto-actualizacion',
  templateUrl: './reporte-auto-actualizacion.component.html',
  styleUrls: ['./reporte-auto-actualizacion.component.scss']
})
export class ReporteAutoActualizacionComponent implements OnInit {
  heading = 'Reporte de AUTO-ACTUALIZACIÓN';
  subheading = 'Reporte de los contactos que han recibido la campaña de auto-actualización';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  title = 'Reporte auto-actualización';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeReporte: Subscription = new Subscription();
  public identity: Identity = new Identity();
  public loading = false;
  public url: string;
  public version = 1;
  public paginacion = new Pagination(null);
  public filterStatus= [...StatusActualizacionFilter];
  public listSearch: string[] = [];
  public filterActive = false;
  public orderType = [...ReporteAutoActualizacionSortType];
  public contactos: ReporteAutoActualizacion[] = [];
  public downloading = false;
  public activado = false;
  public dates: Date[] = [];
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public datePipe = new DatePipe("en-US");

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private excelService: ExcelService,
    private reportesService: ReportesService,
    private campanaService: CampanasService,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.title);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacion = new Pagination({
      sortType: '-fecModificacion', 
      optionSize: [
        { value: 10, description: '10 elementos por hoja' },
        { value: 20, description: '20 elementos por hoja' },
        { value: 50, description: '50 elementos por hoja' },
        { value: 100, description: '100 elementos por hoja' }
      ],
      pageSize: 20
    });
    this.fromDate = calendar.getNext(calendar.getToday(), 'd', - 30);
    this.toDate = calendar.getToday();
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 30);
    this.dates = [ fecha, new Date() ];
  }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeReporte = this.store.select('reportes').subscribe(
      (state) => {
        this.contactos = [...state.autoActualizacion];
        this.paginacion.totalSize = state.totalAutoActualizacion;
        this.paginacion.totalFiltro = state.totalFiltroAutoActualizacion;
      }
    );
    console.log('Inicializa el componente Reporte-sepomex ...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeReporte.unsubscribe();
  }

  inicializa() {
    this.cargarContactos();
    this.campanaService.obtenerEstatusCampanaAutoActualizacion().subscribe(
      (respuesta: any) => {
      this.activado = respuesta.estatus;
    }, error => {

    });
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarContactos();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarContactos();
  }

  changeEstatus() {
    this.filterStatus = [...this.filterStatus];
    this.cargarContactos();
  }


  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchContactos").focus();
  }

  printXLS() {
    this.downloading = true;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = 0;
    opciones.numLimit = null;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desEstatus = this.filterStatus.filter( itm => itm.checked).map( itm => itm.descripcion).join(',');
    opciones.inicia = this.fromDate;
    opciones.termina = this.toDate;
    this.reportesService.downloadExcelReporteAutoActualizacion(opciones).subscribe(
      respuesta => {
        this.excelService.exportAsExcelFile(respuesta.reporte, 'reporte_auto_actualizacion_' + i);
        this.downloading = false;
        // this.downloading = true;
        // // this.downloadState = respuesta;
        // if (respuesta.state === 'DONE') {
        //   setTimeout( () => {
        //     this.downloading = false;
        //   }, 2000 );
        // }
      }, error => {
        this.downloading = false;
    });
  }

  cargarContactos (reset = true) {
    if (reset) {
      this.paginacion.page = 1;
    }
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    // opciones.numLimit = 5;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desEstatus = this.filterStatus.filter( itm => itm.checked).map( itm => itm.descripcion).join(',');
    opciones.inicia = this.fromDate;
    opciones.termina = this.toDate;
    this.store.dispatch(new usuariosActions.CargarReporteAutoActualicacion(opciones));
  }

  consultaDetalle(contacto: Contacto) {
    if (contacto.id && contacto.id > 0) {
      this.router.navigate(['/contacto/profile/', contacto.id]);
    }
  }

  activarCampana() {
    if (!this.activado) {
      Swal.fire({
        title: '¿Deseas activar la campaña de auto-actualización?',
        text: 'Una vez que se active la campaña se iniciará con el envío de correo a todos los contactos activos del sistema que aún no hayan recibido el correo o que la fecha de envío del correo anterior sea mayor a 6 meses. Para confirmar la activación de la campaña escriba OK y de clic en Aceptar.',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
        cancelButtonColor: MESSAGES.C_BTN_CANCEL,
        confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
        cancelButtonText: MESSAGES.M_BTN_CANCEL,
        focusConfirm: false,
        focusCancel: true,
        showLoaderOnConfirm: true,
        preConfirm: (texto) => {
          return new Promise( (resolve)  => {
            this.campanaService.actualizarEstatusCampanaAutoActualizacion(this.activado, texto).subscribe(
              (response) => {
                if (response.campana) {
                  resolve(response.campana);
                } else {
                  Swal.showValidationMessage('Necesitas confirmar el texto');
                  resolve(false);
                }
              }, (error) => {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            );
          });
        },
        allowOutsideClick: false
      }).then(
        (result) => {
          if (result.value) {
            this.activado = !this.activado;
            Swal.fire({
              title: MESSAGES.M_TIT_CONFIRM,
              text: 'Activada correctamente.',
              icon: 'success'
            });
          } else {
            Swal.close();
          }
        }, () => {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () => {}
          }).then( () => {},  () => {});
        }
      );
    } else {
      this.campanaService.actualizarEstatusCampanaAutoActualizacion(this.activado, '').subscribe(
        (respuesta: any) => {
          if (respuesta.campana) {
            this.activado = !this.activado;
          }
        }, error => {}
      );
    }
  }

  onDateSelection(fechas: Date[]) {
    if(fechas && fechas.length){
      const dates: string[] = [...fechas.map(item => { return this.datePipe.transform(item, 'yyyy-MM-dd'); })];
      this.fromDate = this.dateToStructDateModel(dates.shift());
      this.toDate = this.dateToStructDateModel(dates.pop());
      this.cargarContactos();
    }
  }

  dateToStructDateModel(date: string) {
    let data: string[] = date.split('-');
    return new NgbDate ( Number(data[0]),  Number(data[1]),  Number(data[2]));
	}

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
    this.cargarContactos();
  }

}
