<div class="bg-animation">
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<div class="container mx-auto">
			<a class="navbar-brand" href="#">
				<img class="center" style="height: 60px; width: auto;"
					src="https://www.seguridadenamerica.com.mx/noticias//img/site_logo.png?v=1.0.3" alt="">
			</a>
		</div>
	</nav>
	<div class="container mt-5" *ngIf="found">
		<div class="main-card mb-3 card">
			<div class="card-body">
				<div class="text-center"><i class="fas fa-check-circle" style="color:rgb(53, 189, 35); font-size: 20em;"></i>
				</div>
				<div class="text-center" style="font-size:25px;">
					<br><b>¡Desuscripción realizada correctamente!</b>,
					<br> Ya no enviaremos correos electrónicos a su correo electrónico. Si fue un error, puedes volver a
					suscribirte aquí:

					<div class="row text-center">
						<div class="container">
							<div class="row">
								<div class="col text-center">
									<br>
									<button class="btn btn-primary btn-lg" type="button" (click)="suscribirse()">Suscribirse</button>
								</div>
							</div>
						</div>


					</div>
				</div>


			</div>
		</div>
		<div class="text-center opacity-8">
			<div class="row p-3">
				<div class="col-12">
					Editorial Seguridad en América, S. A. de C. V., con domicilio en Cto. Fundadores no. 14 int. 1, Cd.
					Satélite, Zona Verde, Estado de México, C. P. 53100
				</div>
			</div>
			<div class="row p-3 mb-3">
				<div class="col-6">
					<a class="text-gray"
						href="https://www.seguridadenamerica.com.mx/noticias/formulario/14736/template-politicas-de-privacidad"
						target="_blank">Políticas de privacidad</a>
				</div>
				<div class="col-6">
					<a href="https://www.seguridadenamerica.com.mx/noticias/formulario/14134/contacto-sea"
						target="_blank">Contacto</a>
				</div>
			</div>
		</div>
	</div>
	<div class="container mt-5" *ngIf="!found">
		<div class="main-card mb-3 my-auto card">
			<div class="card-body">
				<h3 class="text-center">Lo sentimos tu solicitud no está disponible</h3>
				<div class="text-center text-muted">
					<p>Te solicitamos verifiques el link que este correctamente o utilizar uno más reciente ya que
						posiblemente no es válido.</p>
				</div>
			</div>
		</div>
		<div class="text-center opacity-8">
			<div class="row p-3">
				<div class="col-12">
					Editorial Seguridad en América, S. A. de C. V., con domicilio en Cto. Fundadores no. 14 int. 1, Cd.
					Satélite, Zona Verde, Estado de México, C. P. 53100
				</div>
			</div>
			<div class="row p-3 mb-3">
				<div class="col-6">
					<a class="text-gray"
						href="https://www.seguridadenamerica.com.mx/noticias/formulario/14736/template-politicas-de-privacidad"
						target="_blank">Políticas de privacidad</a>
				</div>
				<div class="col-6">
					<a href="https://www.seguridadenamerica.com.mx/noticias/formulario/14134/contacto-sea"
						target="_blank">Contacto</a>
				</div>
			</div>
		</div>
	</div>
</div>