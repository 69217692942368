import { SortOrder } from './sort';

export class User {
  public id: number;
  public usuario: string;
  public nombre: string;
  public tipo: number;
  public desTipo: string;
  public session: string;
  public apellidoPaterno: string;
  public apellidoMaterno: string;
  public email: string;
  public telefono: number;
  public puesto: string;
  public departamento: number;
  public desDepartamento: string;
  public fecRegistro: Date;
  public permisos: any;
  public registro: string;
  public descripcion: string;
  public password?: string;
  public passwordConfirmar?: string;
  public imagen: string;
  public captcha?: string;
  public checked?: boolean;
  constructor(
    obj: DataObjUser
  ) {
    this.id = obj && obj.id  ? obj.id : 0;
    this.usuario = obj && obj.usuario ? obj.usuario : '';
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.tipo = obj && obj.tipo ? obj.tipo : 0;
    this.desTipo = obj && obj.desTipo ? obj.desTipo : '';
    this.session = obj && obj.session ? obj.session : '';
    this.apellidoPaterno = obj && obj.apellidoPaterno ? obj.apellidoPaterno : '';
    this.apellidoMaterno = obj && obj.apellidoMaterno ? obj.apellidoMaterno : '';
    this.email = obj && obj.email ? obj.email : '';
    this.telefono = obj && obj.telefono ? obj.telefono : null;
    this.puesto = obj && obj.puesto ? obj.puesto : '';
    this.departamento = obj && obj.departamento ? obj.departamento : 0;
    this.desDepartamento = obj && obj.desDepartamento ? obj.desDepartamento : '';
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
    this.permisos = obj && obj.permisos ? obj.permisos : {};
    this.registro = obj && obj.registro ? obj.registro : '';
    this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
    this.password = obj && obj.password ? obj.password : '';
    this.passwordConfirmar = obj && obj.passwordConfirmar ? obj.passwordConfirmar : '';
    this.imagen = obj && obj.imagen ? obj.imagen : '';
    this.captcha = obj && obj.captcha ? obj.captcha : '';
    this.checked = obj && obj.checked ? obj.checked : false;
  }
}

export interface DataObjUser {
  id?: number;
  usuario?: string;
  nombre?: string;
  tipo?: number;
  desTipo?: string;
  session?: string;
  apellidoPaterno?: string;
  apellidoMaterno?: string;
  email?: string;
  telefono?: number;
  puesto?: string;
  departamento?: number;
  desDepartamento?: string;
  fecRegistro?: Date;
  permisos?: any;
  registro?: string;
  descripcion?: string;
  password?: string;
  passwordConfirmar?: string;
  imagen: string;
  captcha?: string;
  checked?: boolean;
}

export class UserType {
  public id: number;
  public nombre: string;
  public checked: boolean;
  public descripcion: string;
  constructor(
    obj: ObjUserType
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.checked = obj && obj.checked ? obj.checked : false;
    this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
  }
}

interface ObjUserType {
  id?: number;
  nombre?: string;
  checked?: boolean;
  descripcion?: string;
}

export class FilterType {
  public id: number;
  public nombre: string;
  public checked: boolean;
  constructor(
    obj: ObjFilterType
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.checked = obj && obj.checked ? obj.checked : false;
  }
}

interface ObjFilterType {
  id: number;
  nombre: string;
  checked: boolean;
}

export class UserChangePassword {
  constructor(
    public id: number,
    public contrasenaActual: string,
    public contrasena: string,
    public confirmaContrasena: string
  ) {}
}

export class Permisos {
  public web: boolean;
  public usuariosListEdit: boolean;
  public usuariosListVer: boolean;
  public usuariosRegEdit: boolean;
  public usuariosRegVer: boolean;
  public usuarios: boolean;
  // public catDatosFiscalesEdit: boolean;
  // public catDatosFiscalesVer: boolean;
  public repositoriosListEdit: boolean;
  public repositoriosListVer: boolean;
  public repositoriosRegEdit: boolean;
  public repositoriosRegVer: boolean;
  public repositorios: boolean;
  public archivosListEdit: boolean;
  public archivosListVer: boolean;
  public archivosRegEdit: boolean;
  public archivosRegVer: boolean;
  public archivos: boolean;
  public catDatosFiscales: boolean;
  public catIndustriasEdit: boolean;
  public catIndustriasVer: boolean;
  public catIndustrias: boolean;
  public catTipoEmpresasEdit: boolean;
  public catTipoEmpresasVer: boolean;
  public catTipoEmpresas: boolean;
  public oportunidadesListEdit: boolean;
  public oportunidadesListVer: boolean;
  public oportunidadesRegEdit: boolean;
  public oportunidadesRegVer: boolean;
  public oportunidades: boolean;
  public contactosListEdit: boolean;
  public contactosListVer: boolean;
  public contactosRegEdit: boolean;
  public contactosRegVer: boolean;
  public contactos: boolean;
  public empresasListEdit: boolean;
  public empresasListVer: boolean;
  public empresasRegEdit: boolean;
  public empresasRegVer: boolean;
  public empresas: boolean;
  public actividadesListEdit: boolean;
  public actividadesListVer: boolean;
  public actividadesRegEdit: boolean;
  public actividadesRegVer: boolean;
  public actividades: boolean;
  public catOrigenesEdit: boolean;
  public catOrigenesVer: boolean;
  public catOrigenes: boolean;
  public catPaisesEdit: boolean;
  public catPaisesVer: boolean;
  public catPaises: boolean;
  public catDepartamentosEdit: boolean;
  public catDepartamentosVer: boolean;
  public catDepartamentos: boolean;
  public plantillasEdit: boolean;
  public plantillasVer: boolean;
  public campannasEdit: boolean;
  public campannasVer: boolean;
  public envioPlantillas: boolean;
  public ticketsEdit: boolean;
  public ticketsVer: boolean;
  public rptCampanas?: boolean;
  public rptKpiVendedores?: boolean;
  public rptSepomex?: boolean;
  public rptCumpleannos?: boolean;
  public rptOportunidadEtapas?: boolean;
  public rptOportunidadPropietarios?: boolean;
  public rptAutoActualizacion: boolean;
  public usuariosDownload: boolean;
  public contactosDownload: boolean;
  public empresasDownload: boolean;
  public oportunidadesDownload: boolean;
  constructor(
    obj?: ObjDataPermisos
  ) {
    this.web = obj && obj.web ? obj.web : false;
    this.usuariosListEdit = obj && obj.usuariosListEdit ? obj.usuariosListEdit : false;
    this.usuariosListVer = obj && obj.usuariosListVer ? obj.usuariosListVer : false;
    this.usuariosRegEdit = obj && obj.usuariosRegEdit ? obj.usuariosRegEdit : false;
    this.usuariosRegVer = obj && obj.usuariosRegVer ? obj.usuariosRegVer : false;
    this.usuarios = obj && obj.usuarios ? obj.usuarios : false;
    this.repositoriosListEdit = obj && obj.repositoriosListEdit ? obj.repositoriosListEdit : false;
    this.repositoriosListVer = obj && obj.repositoriosListVer ? obj.repositoriosListVer : false;
    this.repositoriosRegEdit = obj && obj.repositoriosRegEdit ? obj.repositoriosRegEdit : false;
    this.repositoriosRegVer = obj && obj.repositoriosRegVer ? obj.repositoriosRegVer : false;
    this.repositorios = obj && obj.repositorios ? obj.repositorios : false;
    this.archivosListEdit = obj && obj.archivosListEdit ? obj.archivosListEdit : false;
    this.archivosListVer = obj && obj.archivosListVer ? obj.archivosListVer : false;
    this.archivosRegEdit = obj && obj.archivosRegEdit ? obj.archivosRegEdit : false;
    this.archivosRegVer = obj && obj.archivosRegVer ? obj.archivosRegVer : false;
    this.archivos = obj && obj.archivos ? obj.archivos : false;
    this.catDatosFiscales = obj && obj.catDatosFiscales ? obj.catDatosFiscales : false;
    this.catIndustriasEdit = obj && obj.catIndustriasEdit ? obj.catIndustriasEdit : false;
    this.catIndustriasVer = obj && obj.catIndustriasVer ? obj.catIndustriasVer : false;
    this.catIndustrias = obj && obj.catIndustrias ? obj.catIndustrias : false;
    this.catTipoEmpresasEdit = obj && obj.catTipoEmpresasEdit ? obj.catTipoEmpresasEdit : false;
    this.catTipoEmpresasVer = obj && obj.catTipoEmpresasVer ? obj.catTipoEmpresasVer : false;
    this.catTipoEmpresas = obj && obj.catTipoEmpresas ? obj.catTipoEmpresas : false;
    this.catDepartamentosEdit = obj && obj.catDepartamentosEdit ? obj.catDepartamentosEdit : false;
    this.catDepartamentosVer = obj && obj.catDepartamentosVer ? obj.catDepartamentosVer : false;
    this.catDepartamentos = obj && obj.catDepartamentos ? obj.catDepartamentos : false;
    this.oportunidadesListEdit = obj && obj.oportunidadesListEdit ? obj.oportunidadesListEdit : false;
    this.oportunidadesListVer = obj && obj.oportunidadesListVer ? obj.oportunidadesListVer : false;
    this.oportunidadesRegEdit = obj && obj.oportunidadesRegEdit ? obj.oportunidadesRegEdit : false;
    this.oportunidadesRegVer = obj && obj.oportunidadesRegVer ? obj.oportunidadesRegVer : false;
    this.oportunidades = obj && obj.oportunidades ? obj.oportunidades : false;
    this.contactosListEdit = obj && obj.contactosListEdit ? obj.contactosListEdit : false;
    this.contactosListVer = obj && obj.contactosListVer ? obj.contactosListVer : false;
    this.contactosRegEdit = obj && obj.contactosRegEdit ? obj.contactosRegEdit : false;
    this.contactosRegVer = obj && obj.contactosRegVer ? obj.contactosRegVer : false;
    this.contactos = obj && obj.contactos ? obj.contactos : false;
    this.empresasListEdit = obj && obj.empresasListEdit ? obj.empresasListEdit : false;
    this.empresasListVer = obj && obj.empresasListVer ? obj.empresasListVer : false;
    this.empresasRegEdit = obj && obj.empresasRegEdit ? obj.empresasRegEdit : false;
    this.empresasRegVer = obj && obj.empresasRegVer ? obj.empresasRegVer : false;
    this.empresas = obj && obj.empresas ? obj.empresas : false;
    this.actividadesListEdit = obj && obj.actividadesListEdit ? obj.actividadesListEdit : false;
    this.actividadesListVer = obj && obj.actividadesListVer ? obj.actividadesListVer : false;
    this.actividadesRegEdit = obj && obj.actividadesRegEdit ? obj.actividadesRegEdit : false;
    this.actividadesRegVer = obj && obj.actividadesRegVer ? obj.actividadesRegVer : false;
    this.actividades = obj && obj.actividades ? obj.actividades : false;
    this.catOrigenesEdit = obj && obj.catOrigenesEdit ? obj.catOrigenesEdit : false;
    this.catOrigenesVer = obj && obj.catOrigenesVer ? obj.catOrigenesVer : false;
    this.catOrigenes = obj && obj.catOrigenes ? obj.catOrigenes : false;
    this.catPaisesEdit = obj && obj.catPaisesEdit ? obj.catPaisesEdit : false;
    this.catPaisesVer = obj && obj.catPaisesVer ? obj.catPaisesVer : false;
    this.catPaises = obj && obj.catPaises ? obj.catPaises : false;
    this.plantillasEdit = obj && obj.plantillasEdit ? obj.plantillasEdit : false;
    this.plantillasVer = obj && obj.plantillasVer ? obj.plantillasVer : false;
    this.campannasEdit = obj && obj.campannasEdit ? obj.campannasEdit : false;
    this.campannasVer = obj && obj.campannasVer ? obj.campannasVer : false;
    this.envioPlantillas = obj && obj.envioPlantillas ? obj.envioPlantillas : false;
    this.ticketsEdit = obj && obj.ticketsEdit ? obj.ticketsEdit : false;
    this.ticketsVer = obj && obj.ticketsVer ? obj.ticketsVer : false;
    this.rptCampanas = obj && obj.rptCampanas ? obj.rptCampanas : false;
    this.rptKpiVendedores = obj && obj.rptKpiVendedores ? obj.rptKpiVendedores : false;
    this.rptSepomex = obj && obj.rptSepomex ? obj.rptSepomex : false;
    this.rptCumpleannos = obj && obj.rptCumpleannos ? obj.rptCumpleannos : false;
    this.rptOportunidadEtapas = obj && obj.rptOportunidadEtapas ? obj.rptOportunidadEtapas : false;
    this.rptOportunidadPropietarios = obj && obj.rptOportunidadPropietarios ? obj.rptOportunidadPropietarios : false;
    this.rptAutoActualizacion = obj && obj.rptAutoActualizacion ? obj.rptAutoActualizacion : false;
    this.usuariosDownload = obj && obj.usuariosDownload ? obj.usuariosDownload : false;
    this.contactosDownload = obj && obj.contactosDownload ? obj.contactosDownload : false;
    this.empresasDownload = obj && obj.empresasDownload ? obj.empresasDownload : false;
    this.oportunidadesDownload = obj && obj.oportunidadesDownload ? obj.oportunidadesDownload : false;
  }
}

interface ObjDataPermisos {
  web?: boolean;
  usuariosListEdit?: boolean;
  usuariosListVer?: boolean;
  usuariosRegEdit?: boolean;
  usuariosRegVer?: boolean;
  usuarios?: boolean;
  repositoriosListEdit?: boolean;
  repositoriosListVer?: boolean;
  repositoriosRegEdit?: boolean;
  repositoriosRegVer?: boolean;
  repositorios?: boolean;
  archivosListEdit?: boolean;
  archivosListVer?: boolean;
  archivosRegEdit?: boolean;
  archivosRegVer?: boolean;
  archivos?: boolean;
  catDatosFiscales?: boolean;
  catIndustriasEdit?: boolean;
  catIndustriasVer?: boolean;
  catIndustrias?: boolean;
  catTipoEmpresasEdit?: boolean;
  catTipoEmpresasVer?: boolean;
  catTipoEmpresas?: boolean;
  catDepartamentosEdit?: boolean;
  catDepartamentosVer?: boolean;
  catDepartamentos?: boolean;
  oportunidadesListEdit?: boolean;
  oportunidadesListVer?: boolean;
  oportunidadesRegEdit?: boolean;
  oportunidadesRegVer?: boolean;
  oportunidades?: boolean;
  contactosListEdit?: boolean;
  contactosListVer?: boolean;
  contactosRegEdit?: boolean;
  contactosRegVer?: boolean;
  contactos?: boolean;
  empresasListEdit?: boolean;
  empresasListVer?: boolean;
  empresasRegEdit?: boolean;
  empresasRegVer?: boolean;
  empresas?: boolean;
  actividadesListEdit?: boolean;
  actividadesListVer?: boolean;
  actividadesRegEdit?: boolean;
  actividadesRegVer?: boolean;
  actividades?: boolean;
  catOrigenesEdit?: boolean;
  catOrigenesVer?: boolean;
  catOrigenes?: boolean;
  catPaisesEdit?: boolean;
  catPaisesVer?: boolean;
  catPaises?: boolean;
  plantillasEdit?: boolean;
  plantillasVer?: boolean;
  campannasEdit?: boolean;
  campannasVer?: boolean;
  envioPlantillas?: boolean;
  ticketsEdit?: boolean;
  ticketsVer?: boolean;
  rptCampanas?: boolean;
  rptKpiVendedores?: boolean;
  rptSepomex?: boolean;
  rptCumpleannos?: boolean;
  rptOportunidadEtapas?: boolean;
  rptOportunidadPropietarios?: boolean;
  rptAutoActualizacion: boolean;
  usuariosDownload: boolean;
  contactosDownload: boolean;
  empresasDownload: boolean;
  oportunidadesDownload: boolean;
}


export class Identity {
  public id: number;
  public usuario: string;
  public apellidos: string;
  public nombre: string;
  public email: string;
  public session: number;
  public tipo: number;
  public desTipo: string;
  public departamento: number;
  public desDepartamento: string;
  public puesto: string;
  public registro: Date;
  public permisos: Permisos;
  constructor(
    obj?: ObjDataIdentity
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.usuario = obj && obj.usuario ? obj.usuario : '';
    this.apellidos = obj && obj.apellidos ? obj.apellidos : '';
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.email = obj && obj.email ? obj.email : '';
    this.session = obj && obj.session ? obj.session : null;
    this.tipo = obj && obj.tipo ? obj.tipo : 0;
    this.desTipo = obj && obj.desTipo ? obj.desTipo : '';
    this.departamento = obj && obj.departamento ? obj.departamento : 0;
    this.desDepartamento = obj && obj.desDepartamento ? obj.desDepartamento : '';
    this.puesto = obj && obj.puesto ? obj.puesto : '';
    this.registro = obj && obj.registro ? obj.registro : null;
    this.permisos = obj && obj.permisos ? obj.permisos : new Permisos(null);
  }
}

interface ObjDataIdentity {
  id?: number;
  usuario?: string;
  apellidos?: string;
  nombre?: string;
  email?: string;
  session?: number;
  tipo?: number;
  desTipo?: string;
  departamento?: number;
  desDepartamento?: string;
  puesto?: string;
  registro?: Date;
  permisos?: Permisos;
}

export const UsuariosSortType: SortOrder[] = [
  { id: '+id', text: 'Folio'},
  { id: '-id', text: 'Folio desc'},
  { id: '+usuario', text: 'Usuario'},
  { id: '-usuario', text: 'Usuario desc'},
  { id: '+nombre', text: 'Nombre'},
  { id: '-nombre', text: 'Nombre desc'},
  { id: '+apellidoPaterno', text: 'Apellido Paterno'},
  { id: '-apellidoPaterno', text: 'Apellido Paterno desc'},
  { id: '+apellidoMaterno', text: 'Apellido Materno'},
  { id: '-apellidoMaterno', text: 'Apellido Materno desc'},
  { id: '+puesto', text: 'Puesto'},
  { id: '-puesto', text: 'Puesto desc'},
  { id: '+desTipo', text: 'Tipo de usuario'},
  { id: '-desTipo', text: 'Tipo de usuario desc'},
  { id: '+desDepartamento', text: 'Departamento del usuario'},
  { id: '-desDepartamento', text: 'Departamento del usuario desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'}
];

export class PermisoEdit {
  constructor(
    public type: string,
    public value: boolean
  ) {}
}

export class Suscripciones {
  public id: number;
  public nombre: string;
  public checked: boolean;
  constructor( obj: ObjSuscripciones) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.checked = obj && obj.checked ? obj.checked : false;
  }
}

interface ObjSuscripciones {
  id: number;
  nombre: string;
  checked: boolean;
}

export class Usuario {
  public id: number;
  public usuario: string;
  public nombre: string;
  public tipo: number;
  public desTipo: string;
  public departamento: number;
  public desDepartamento: string;
  public email: string;
  public puesto: string;

  constructor(
    obj: DataObjUsuario
  ) {
    this.id = obj && obj.id  ? obj.id : 0;
    this.usuario = obj && obj.usuario ? obj.usuario : '';
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.tipo = obj && obj.tipo ? obj.tipo : 0;
    this.desTipo = obj && obj.desTipo ? obj.desTipo : '';
    this.departamento = obj && obj.departamento ? obj.departamento : 0;
    this.desDepartamento = obj && obj.desDepartamento ? obj.desDepartamento : '';
    this.email = obj && obj.email ? obj.email : '';
    this.puesto = obj && obj.puesto ? obj.puesto : '';
  }
}

export interface DataObjUsuario {
  id: number;
  usuario: string;
  nombre: string;
  tipo: number;
  desTipo: string;
  departamento: number;
  desDepartamento: string;
  email: string;
  puesto: string;
}
