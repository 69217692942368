import { DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CampanasService } from 'src/app/services/campanas.service';
import { Identity } from 'src/app/models/user';
import { Pagination } from 'src/app/models/pagination';
import { AnyScopePipe } from '../../../../pipes/any-scope.pipe';
import { FilterTypeAllCampana } from 'src/app/models/campana';
import { ExcelService } from 'src/app/services/excel.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { GLOBAL } from '../../../../models/global';

@Component({
  selector: 'app-allcampanas',
  templateUrl: './allcampanas.component.html',
  styleUrls: ['./allcampanas.component.sass'],
})
export class AllcampanasComponent implements OnInit {
  heading = 'Campañas de marketing';
  subheading = 'Información de resultados y contenido de campañas realizadas';
  icon = 'pe-7s-mail icon-gradient bg-dark';
  public filterActive = false;
  public identity: Identity = new Identity(null);
  public campanas: any[] = [];
  public subscribeAuth: Subscription = new Subscription();
  public paginacion: Pagination = new Pagination(null);
  public listSearch: string[] = [];
  public orderType: any[] = [];
  public TextoProcesar: any;
  public consoletest: any;
  public isCollapsed = false;
  public filterTipos: FilterTypeAllCampana[] = [];

  constructor(
    private campanasservices: CampanasService,
    private excelService: ExcelService,
    private router: Router,
    private toastr: ToastrService,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.filterTipos = [
      new FilterTypeAllCampana({ estatus: 1, nombre: 'Borrador', checked: false }),
      new FilterTypeAllCampana({ estatus: 3, nombre: 'Enviado', checked: false }),
      new FilterTypeAllCampana({ estatus: 4, nombre: 'Error', checked: false }),
    ]
  }

  ngOnInit() {
    this.campanasservices.obtenerCampanas().subscribe(respuesta => {
      this.campanas = respuesta.campanas;
    });
  }

  showTipos(event) {
    this.filterTipos = [...event];
  }

  EliminarCampana(campana: any) {
    Swal.fire({
      title: '¿Desea eliminar la campaña ' + campana.tema + '?',
      icon: 'warning',
      text: 'Al realizar esta acción no se podrá deshacer',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.campanasservices.EliminarCampana(campana.id).subscribe(
          (respuesta: any) => {
            this.toastr.success('Se ha eliminado la campaña: ' + campana.tema);
            this.campanas = this.campanas.filter(item => item.id !== campana.id);
          }, err => {
            // console.log('Ocurrio un error al eliminar la campaña', err);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          'No se ha eliminado la campaña',
          'error'
        )
      }
    });
  }

  EditarCampana(campana) {
    this.router.navigate(['/auth/marketing/editar-campana', campana.id]);
  }

  DuplicarCampana(campana) {
    this.campanasservices.DuplicarCampanas(campana).subscribe(
      (respuesta: any) => {
        this.toastr.success('Campaña duplicada correctamente.');
        // Puedes cambiar de ruta o no hacer nada
        this.campanas.unshift(respuesta.campanadup);
        // console.log(respuesta.campanadup)
        // this.campana = respuesta.campana;
      }, err => {
        // console.log('Ocurrio un error al duplicar la campana', err);
      }
    );
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  changeCheck() {
    this.filterTipos = [...this.filterTipos];
    setTimeout(() => {
      this.filterTipos = [...this.filterTipos];
    }, 5);
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter(item => item !== text);
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  printXLS() {
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    let lista = [...this.campanas];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    if (lista.length) {
      lista = lista.map(itemLista => {
        const obj: any = {};
        if (itemLista.fecRegistro) {
          itemLista.fecRegistro = new Date(itemLista.fecRegistro);
        } else {
          itemLista.fecRegistro = null;
        }
        obj.ID = itemLista.id;
        obj.TITULO = itemLista.tema;
        obj.DESTINATARIOS = itemLista.recipientes;
        obj.EXPEDIDO = itemLista.expedido ? new Date(itemLista.expedido) : null;
        obj.APERTURAS = itemLista.aperturas;
        return obj;
      });
      this.excelService.exportAsExcelFile(lista, 'Reporte de todas campanias ' + i);
    }
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
