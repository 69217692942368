import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { ListaCampana } from '../models/lista-campana';

@Injectable({
  providedIn: 'root'
})
export class ListaCampanasService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  guardarLista(listaCampana: ListaCampana): Observable<any> {
    const obj = {
      ...listaCampana,
      paises: listaCampana.paises.map(itm => itm.id),
      propietarios: listaCampana.propietarios.map(itm => itm.id),
      origenContactos: listaCampana.origenContactos.map( itm => itm.id),
      estatus: listaCampana.estatus.map(itm => itm.id),
      etapas: listaCampana.etapas.map(itm => itm.id),
      estados: listaCampana.estados.map(itm => itm.nombre),
      industrias: listaCampana.industrias.map(itm => itm.id),
      clasificaciones: listaCampana.clasificaciones.map(itm => itm.id),
      clases: listaCampana.clases.map(itm => itm.id),
      tiposEmpresas: listaCampana.tiposEmpresas.map(itm => itm.id),
      catalogoSeguridad: listaCampana.catalogoSeguridad.map(itm => itm.descripcion)
    };
    console.log(obj);

    const params = JSON.stringify( {listaCampana: obj });
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/lista', params, {headers});
  }

  obtenerLista(idCampana: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/lista/' + idCampana, {headers});
  }

  obtenerListaContactos(idCampana: number, total: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/lista/' + idCampana + '/contactos/' + total, {headers});
  }

  obtenerListaContactosTotal(idCampana: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/lista/' + idCampana + '/contactos/total', {headers});
  }

  existeCampna(id: number, nombre: String): Observable<any> {
    const params = JSON.stringify( {nombre, id} );
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/existe', params, {headers});
  }

  descargarDestinatarios(idCampana: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/' + idCampana + '/descargar/contactos', {headers});
  }
  
}
