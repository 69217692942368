import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions/actividades-archivos.actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ActividadesArchivosService } from 'src/app/services/actividades-archivos.service';
import { UploadService } from 'src/app/services/upload.service';

@Injectable()
export class ActividadesArchivosEffects {

  constructor(
    private actions$: Actions,
    public actividadesArchivosService: ActividadesArchivosService,
    public uploadService: UploadService
  ) {}

  @Effect()
  cargarArchivos$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ARCHIVOS ),
    switchMap( (action: any) => {
      return this.actividadesArchivosService.obtenerArchivos(action.id, action.tipo)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarArchivosSuccess(respuesta.archivos);
          }),
          catchError( error => of(new usuariosActions.CargarArchivosFail(error))  )
        );
    })
  );

  // @Effect()
  // registrarArchivo$ = this.actions$.pipe(
  //   ofType( usuariosActions.REGISTRAR_ARCHIVO ),
  //   switchMap( (action: any) => {
  //     return this.uploadService.makeFileRequest(action.url, action.params, action.files, action.token, action.name, action.obj)
  //       .pipe(
  //           map( respuesta => {
  //             return new usuariosActions.RegistrarArchivoSuccess(respuesta.archivo);
  //           }),
  //           catchError( error => of(new usuariosActions.RegistrarArchivoFail(error))  )
  //       );
  //   })
	// );

	@Effect()
  registrarArchivo$ = this.actions$.pipe(
    ofType( usuariosActions.REGISTRAR_ARCHIVO ),
    switchMap( (action: any) => {
      return this.uploadService.makeFileRequest(action.url, action.params, action.files, action.token, action.name, action.archivo)
        .then( ( respuesta: any )=> {
					return new usuariosActions.RegistrarArchivoSuccess(respuesta.archivo);
				})
				.catch( error => of(new usuariosActions.RegistrarArchivoFail(error))  )
		})
	);

  @Effect()
  eliminarArchivo$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_ARCHIVO ),
    switchMap( (action: any) => {
      return this.actividadesArchivosService.eliminarArchivo(action.id, action.archivo)
        .pipe(
            map( respuesta => {
              return new usuariosActions.EliminarArchivoSuccess(action.archivo.id);
            }),
            catchError( error => of(new usuariosActions.EliminarArchivoFail(error))  )
        );
    })
  );


}
