import { Pipe, PipeTransform } from '@angular/core';
interface FilterParamInObject {
  search: any;
  param: string;
}
@Pipe({
  name: 'filterParamInObjectEqual'
})
export class FilterParamInObjectEqualPipe implements PipeTransform {

  transform(value: any, args: FilterParamInObject[]): any {
    if (!value.length) { return []; }
    if (!args.length) { return value; }
    return value.filter( (it: any) =>
      args.some(f => f.search === it[f.param])
    );
  }

}
