<div class="row">
  <div class="col-md-4">
    <div class="main-card mb-2 card">
      <div class="card-body">
        <h5 class="card-title">Editar Plantilla</h5>
        <form id="nuevacampana" name="nuevacampana" #datoFiscalForm="ngForm">
          <div class="position-relative form-group"><label for="tema">Nombre para la plantilla</label><input name="tema"
              id="temacampana" placeholder="Tema de la campaña" type="text" class="form-control"
              [(ngModel)]="editarplantilla.tema"></div>
          <div class="position-relative form-group"><label for="exampleText">Versión de texto sin
              formato</label><textarea name="texto_plano" id="texto_plano"
              placeholder="Versión en texto plano de este email" class="form-control"
              [(ngModel)]="editarplantilla.texto_plano">
            </textarea>
          </div>
        </form>
      </div>
      <div class="grid-menu grid-menu-2col border-top">
        <div class="no-gutters row">
          <div class="col-sm-6">
            <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
              [routerLink]="['/auth/marketing/plantillas']">
              <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
            </a>
          </div>
          <div class="col-sm-6">
            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
              (click)="ActualizarPlantilla(editarplantilla)">
              <i class="lnr-download btn-icon-wrapper"></i> Actualizar plantilla
            </button>
            <!-- <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
              <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
              <br>
              Loading...
            </button> -->
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-md-8">
    <div class="card main-card mb-4">
      <div class="card-body">
        <!-- <angular-editor [(ngModel)]="campana.html" [config]="config"></angular-editor> -->
        <ckeditor [(ngModel)]="editarplantilla.texto_html" style="height: 670px;"></ckeditor>

        <div class="divider "></div>
        <b>Etiquetas esenciales:</b>
        <div><br />
          <div class="row">
            <div class="col">
              <b>Etiquetas esenciales:</b><br />
              <br />
              <p>
                <strong>Enlace para darse de baja:</strong><br />
                <code>[desuscribe]</code>
              </p>
            </div>
            <div class="col">
              <b>Etiquetas de personalización:</b><br />
              <br />
              <p>
                <strong>Nombre:</strong><br />
                <code>[nombre]</code>
              </p>
              <p>
                <strong>Apellido:</strong><br />
                <code>[apellido]</code>
              </p>
              <p>
                <strong>Email:</strong><br />
                <code>[email]</code>
              </p>
              <p>
                <strong>Teléfono:</strong><br />
                <code>[telefono]</code>
              </p>
              <p>
                <strong>Celular:</strong><br />
                <code>[celular]</code>
              </p>
              <p>
                <strong>Empresa a la que pertenece:</strong><br />
                <code>[empresa]</code>
              </p>
              <p>
                <strong>Fundación a la que pertenece:</strong><br />
                <code>[fundacion]</code>
              </p>
              <p>
                <strong>Cargo empresarial:</strong><br />
                <code>[cargo]</code>
              </p>
              <p>
                <strong>País de recidencia:</strong><br />
                <code>[pais]</code>
              </p>
              <p>
                <strong>Estado de recidencia:</strong><br />
                <code>[estado]</code>
              </p>
              <p>
                <strong>Ciudad de recidencia:</strong><br />
                <code>[ciudad]</code>
              </p>
              <p>
                <strong>Cumpleaños:</strong><br />
                <code>[cumpleanios]</code>
              </p>
              <p>
                <strong>Aniversario:</strong><br />
                <code>[aniversario]</code>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>