import { Pipe, PipeTransform } from '@angular/core';

interface FiltroMulti {
  opciones: any[];
  parametro: string;
}

@Pipe({
  name: 'multiOpcionesSelected'
})
export class MultiOpcionesSelectedPipe implements PipeTransform {

  transform(value: any, args: FiltroMulti): any {
    if (!value.length) { return []; }
    if (!args.opciones.length || !(args.parametro in value[0])) { return value; }
    const ids = args.opciones.filter((item) => {
      return item.checked;
    }).map( (item) => {
      if (item.id || item.id === 0) {
        return item.id;
      } else if (item.nombre) {
        return item.nombre;
      } else {
        return;
      }
    });
    if (!ids.length) { return value; }
    return value.filter( (it: any) => ids.some(f => f === it[args.parametro]));
  }

}
