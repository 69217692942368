import * as fromUsuario from '../actions';
import { KpisVendedores } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
import { Actividad } from 'src/app/models/actividad';

export interface ReporteKPIsVendedoresState {
  series: SeriesBar[];
	categories: string[];
  totalActividades: number;
	// actividades: Actividad[];
	empresas: KpisVendedores[];
	contactos: KpisVendedores[];
	oportunidadesAbiertas: KpisVendedores[];
  seriesClasificaciones: SeriesBar[];
  labelsClasificaciones: string[];
  seriesClases: number[];
  labelsClases: string[];
  seriesIndustrias: number[];
  labelsIndustrias: string[];
  dataFunnel: FunnelGraphColors[];
  seriesActividad: number[];
	categoriesActividad: string[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: ReporteKPIsVendedoresState = {
  series: [],
	categories: [],
  totalActividades: 0,
	// actividades: [],
	empresas: [],
	contactos: [],
	oportunidadesAbiertas: [],
  seriesClasificaciones: [],
  labelsClasificaciones: [],
  seriesClases: [],
  labelsClases: [],
  seriesIndustrias: [],
  labelsIndustrias: [],
  dataFunnel: [],
  seriesActividad: [],
	categoriesActividad: [],
  loaded: false,
  loading: false,
  error: null
};

export function reporteKpisVendedoresReducer( state = initState, action: fromUsuario.reporteKpisVendedoresAcciones ): ReporteKPIsVendedoresState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				series: [...action.series],
				categories: [...action.categories],
        totalActividades: action.total
      };
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_PENDIENTES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_PENDIENTES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				seriesActividad: [...action.series],
        categoriesActividad: [...action.categories]
      };
    case fromUsuario.CARGAR_REPORTE_ACTIVIDADES_PENDIENTES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_REPORTE_EMPRESAS_REGISTRADAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_EMPRESAS_REGISTRADAS_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				empresas: [...action.empresas]
      };
    case fromUsuario.CARGAR_REPORTE_EMPRESAS_REGISTRADAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_REPORTE_CONTACTOS_REGISTRADAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_CONTACTOS_REGISTRADAS_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				contactos: [...action.contactos]
      };
    case fromUsuario.CARGAR_REPORTE_CONTACTOS_REGISTRADAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
		case fromUsuario.CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				oportunidadesAbiertas: [...action.oportunidades]
      };
    case fromUsuario.CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				seriesClasificaciones: [...action.series],
				labelsClasificaciones: [...action.labels]
      };
    case fromUsuario.CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CARGAR_REPORTE_CLASES_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_CLASES_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				seriesClases: [...action.series],
				labelsClases: [...action.labels]
      };
    case fromUsuario.CARGAR_REPORTE_CLASES_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				seriesIndustrias: [...action.series],
				labelsIndustrias: [...action.categories]
      };
    case fromUsuario.CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
			};
    case fromUsuario.CARGAR_REPORTE_FUNNEL_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
				loaded: true,
				dataFunnel: [...action.datas]
      };
    case fromUsuario.CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}
