<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>

        </div>
        <!-- <h5 class="menu-header-title font-size-md text-capitalize ml-3">
            Filtrar tipos
          </h5> -->
      </div>
      <!-- <div class="card-body p-0" id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="scroll-area-lg mb-2">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">
                <app-user-type-filter (respuesta)="showTipos($event)"></app-user-type-filter>
              </perfect-scrollbar>
            </section>
          </div>
        </div> -->
    </div>
  </div>

  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Todas las plantillas</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(plantillas | anyScope: paginacion.textSearch ).length}} de {{(plantillas).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios"
                  id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacion.textSearch"
                  (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
            </div>
            <button type="button" [routerLink]="['/auth/marketing/plantillas/nueva-plantilla']" [routerLinkActive]=""
              class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Crear nueva
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive"
              (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch"
          (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <table class="table table-stripper">
          <thead>
            <tr>
              <th>Nombre de la plantilla</th>
              <th>Pre-visualización</th>
              <th>Acciones</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let plantilla of plantillas | anyScope: paginacion.textSearch">
              <td>{{plantilla.tema}}</td>

              <td>
                <a data-fancybox data-type="iframe" [attr.data-src]="url +'/marketing/plantilla/public/' + plantilla.id"
                  href="javascript:;"><i class="fas fa-eye"> </i>
                </a>
              </td>
              <td>
                <div class="d-inline-block" ngbDropdown placement="left">
                  <button type="button" class="btn-pill btn btn-outline-primary" ngbDropdownToggle>
                    Acciones
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-xl">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-sunny-morning">
                        <div class="menu-header-content text-dark">
                          <h5 class="menu-header-title">ACCIONES</h5>
                        </div>
                      </div>
                    </div>
                    <div class="grid-menu grid-menu-xl grid-menu-3col">
                      <div class="no-gutters row">
                        <div class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary"
                            title="Editar campaña"
                            [routerLink]="['/auth/marketing/plantillas/editar-plantilla/', plantilla.id]">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-edit"> </i>
                            Editar Plantilla
                          </button>
                        </div>
                        <div class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
                            title="Eliminar campaña" (click)="EliminarPlantilla(plantilla)">
                            <i class="fas fa-trash btn-icon-wrapper btn-icon-lg mb-3"></i> Eliminar plantilla
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  [collectionSize]="(plantillas | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page"
                  [pageSize]="paginacion.pageSize" [maxSize]="5">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>