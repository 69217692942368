import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, User, FilterType } from 'src/app/models/user';
import { Subscription, Subject } from 'rxjs';
import { Contacto, ContactoSortType } from 'src/app/models/contacto';
import { Pagination } from 'src/app/models/pagination';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { ExcelService } from 'src/app/services/excel.service';
import { NgbModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ReportesService } from 'src/app/services/reportes.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reporte-cumpleannos',
  templateUrl: './reporte-cumpleannos.component.html',
  styleUrls: ['./reporte-cumpleannos.component.scss']
})
export class ReporteCumpleannosComponent implements OnInit, OnDestroy {
  heading = 'Reporte de cumpleaños';
  subheading = 'Reporte de los contactos que cumplen años';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeReporte: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public identity: Identity;
  public loading = false;
  public url: string;
  public version = 1;
  public paginacion = new Pagination(null);
  public listSearch: string[] = [];
  public filterActive = false;
  public orderType = [...ContactoSortType];
  public contactos: Contacto[] = [];
  public dates: Date[] = [];
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public downloading = false;
  public datePipe = new DatePipe("en-US");
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private excelService: ExcelService,
    private modalService: NgbModal,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private reportesService: ReportesService,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacion.sortType = '-id';
    this.fromDate = calendar.getNext(calendar.getToday(), 'd', - 30);
    this.toDate = calendar.getToday();
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 30);
    this.dates = [ fecha, new Date() ];
   }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeReporte = this.store.select('reportes').subscribe(
      (state) => {
        this.contactos = [...state.cumpleannos];
        this.paginacion.totalSize = state.totalCumpleannos;
        this.paginacion.totalFiltro = state.totalFiltroCumpleannos;
      }
    );
    console.log('Inicializa el componente Reporte-cumpleannos...');
    this.cargarContactos();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeReporte.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarContactos();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarContactos();
  }


  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchContactos").focus();
  }
  
  printCSV() {
    this.downloading = true;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = 0;
    opciones.numLimit = null;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    this.reportesService.downloadExcelReporteCumpleannos(this.fromDate, this.toDate, opciones).subscribe(
      respuesta => {
        this.excelService.exportAsExcelFile(respuesta.reporte, 'reporte_cumpleaños_' + i);
        this.downloading = false;
      }, error => {
        this.downloading = false;
    });
  }


  cargarContactos (reset = true) {
    if (reset) {
      this.paginacion.page = 1;
    }
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    this.store.dispatch(new usuariosActions.CargarReporteCumpleannos(this.fromDate, this.toDate, opciones));
  }

  consultaDetalle(contacto: Contacto) {
    if (contacto.id && contacto.id > 0) {
      this.router.navigate(['/contacto/register/', contacto.id]);
    }
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
    this.cargarContactos();
  }

  onDateSelection(fechas: Date[]) {
    if(fechas && fechas.length){
      const dates: string[] = [...fechas.map(item => { return this.datePipe.transform(item, 'yyyy-MM-dd'); })];
      this.fromDate = this.dateToStructDateModel(dates.shift());
      this.toDate = this.dateToStructDateModel(dates.pop());
      this.cargarContactos();
    }
  }

  dateToStructDateModel(date: string) {
    let data: string[] = date.split('-');
    return new NgbDate ( Number(data[0]),  Number(data[1]),  Number(data[2]));
	}

}