import { Action } from '@ngrx/store';
import { Origen } from 'src/app/models/origen-contacto';

export const CARGAR_ORIGEN_CONTACTOS = '[OrigenContacto] Cargar origenes contactos';
export const CARGAR_ORIGEN_CONTACTOS_SUCCESS = '[OrigenContacto] Cargar origenes contactos success';
export const CARGAR_ORIGEN_CONTACTOS_FAIL = '[OrigenContacto] Cargar origenes contactos fail';
export const ACTUALIZA_ORIGEN_CONTACTO = '[OrigenContacto] Actualiza origen contacto';
export const ACTUALIZA_ORIGEN_CONTACTO_FAIL = '[OrigenContacto] Actualiza origen contacto FAIL';
export const ACTUALIZA_ORIGEN_CONTACTO_SUCCESS = '[OrigenContacto] Actualiza origen contacto SUCCESS';
export const ACTUALIZA_ORIGEN_CONTACTO_ESTATUS = '[OrigenContacto] Actualiza origen contacto estatus';
export const ACTUALIZA_ORIGEN_CONTACTO_ESTATUS_FAIL = '[OrigenContacto] Actualiza origen contacto estatus FAIL';
export const ACTUALIZA_ORIGEN_CONTACTO_ESTATUS_SUCCESS = '[OrigenContacto] Actualiza origen contacto estatus SUCCESS';


export class CargarOrigenContactos implements Action {
  readonly type = CARGAR_ORIGEN_CONTACTOS;
}

export class CargarOrigenContactosFail implements Action {
  readonly type = CARGAR_ORIGEN_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarOrigenContactosSuccess implements Action {
  readonly type = CARGAR_ORIGEN_CONTACTOS_SUCCESS;

  constructor(public origenes: Origen[]) {}
}

export class ActualizaOrigenContacto implements Action {
  readonly type = ACTUALIZA_ORIGEN_CONTACTO;

  constructor( public origen: Origen ) { }
}

export class ActualizaOrigenContactoFail implements Action {
  readonly type = ACTUALIZA_ORIGEN_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaOrigenContactoSuccess implements Action {
  readonly type = ACTUALIZA_ORIGEN_CONTACTO_SUCCESS;

  constructor( public origen: Origen ) {}
}

export class ActualizaOrigenContactoEstatus implements Action {
  readonly type = ACTUALIZA_ORIGEN_CONTACTO_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaOrigenContactoEstatusFail implements Action {
  readonly type = ACTUALIZA_ORIGEN_CONTACTO_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaOrigenContactoEstatusSuccess implements Action {
  readonly type = ACTUALIZA_ORIGEN_CONTACTO_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type origenContactoAcciones = CargarOrigenContactos 
																		| CargarOrigenContactosFail 
																		| CargarOrigenContactosSuccess
																		| ActualizaOrigenContacto 
																		| ActualizaOrigenContactoFail 
																		| ActualizaOrigenContactoSuccess 
																		| ActualizaOrigenContactoEstatus 
																		| ActualizaOrigenContactoEstatusFail 
																		| ActualizaOrigenContactoEstatusSuccess;
