import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';
import { Campana, NuevaPlantilla } from 'src/app/models/campana';


@Injectable({
  providedIn: 'root'
})
export class GuardaryenviarcampanaService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  //POST
  pruebaenviarCampana(id: number, prueba: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    const param = JSON.stringify({prueba});
    return this.http.post(this.url + '/marketing/guardar-y-enviar-campana/prueba/' + id + '/email', param, {headers});
  }

  ActualizarCampana(id: number, campanaguardada: Campana): Observable<any> {
    const param = JSON.stringify({campanaguardada});
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/editar-campana/' + id, param, {headers});
  }

  //GET
  obtenerCampanaGuardada(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/guardar-y-enviar-campana/' + id, {headers});
  }


  obtenerPlantilla(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/plantilla/' + id, {headers});
  }

  obtenerCorreos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/guardar-y-enviar-campana/correos/', {headers});
  }
  


}