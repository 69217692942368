import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Directorio } from '../../../models/directorio';
// declare function customInitFunctions();

@Component({
  selector: 'app-list-tree',
  templateUrl: './list-tree.component.html',
  styleUrls: ['./list-tree.component.scss']
})
export class ListTreeComponent implements OnInit {
  @Input() lista: Directorio[] = [];
  @Input()
  set actual(valor: number) {
    this.selectedItem(valor);
    this.actualDir = valor|| 0;
  }
  get actual() { return this.actualDir; }
  private actualDir = 0;

  @Output() seleccionado: EventEmitter<number> = new EventEmitter();
  // private navegacion = document.querySelector('#navArchivos');

  constructor() {
  }

  ngOnInit(): void {
    // customInitFunctions();
  }

  selectedItem( seleccionado: number ) {

    const links = document.querySelectorAll('.listree-item');

    links.forEach( elem => {

      elem.classList.remove('listree-active');
      const directorioID = elem.getAttribute('data-sectionvalue');

      if ( directorioID === '' + seleccionado ) {
        elem.classList.add('listree-active');
      }

    });
    this.actualDir = seleccionado;
    this.seleccionado.emit( seleccionado );
  }

  nuevoSeleccion(event) {
    this.actualDir = event;
    this.seleccionado.emit( event );
  }

}
