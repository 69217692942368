<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>


<div class="container">
	<div class="main-card mb-3 card">
		<div class="card-header  mt-3">
			<div class="row">
				<div class="form-group mr-3">
					<input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
					[bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
					<input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #range FechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
					[bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
				</div>
				<ng-select [items]="filterPropietarios" *ngIf="identity.tipo === 1 || identity.tipo === 2" style="min-width: 18rem;    font-weight: normal;;"
                bindLabel="nombre"
                bindValue="id"
                placeholder="Capture el usuario."
								id="propietarioActividad"  
								name="propietarioActividad" 
								#propietarioActividad="ngModel" 
								[(ngModel)]="propietarios" 
								(change)="inicializa()">
				</ng-select>
				<div class="search-wrapper ml-3" [class.active]="paginacion.isActive">
					<div class="input-holder">
						<input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchActividades" id="searchActividades" #searchActividades="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
						<button class="search-icon" (click)="searchClick()"><span></span></button>
					</div>
					<button class="close" (click)="closeSearch()"></button>
				</div>
			</div>
		</div>
		<div class="card-body p-0">
			<ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
				<ngb-tab>
					<ng-template ngbTabTitle><span>TODOS</span></ng-template>
					<ng-template ngbTabContent>
						<ul class="list-group list-group-flush">
							<li class="list-group-item" *ngFor="let actividad of actividades | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
								<div class="no-gutters row">
									<div class="col-sm-6 col-md-4 col-xl-4">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="todo-indicator bg-primary"></div>
													<div class="widget-content-left mr-2 pointer">
														<div class="icon-wrapper rounded-circle">
															<div class="icon-wrapper-bg opacity-10 bg-light"></div>
															<i *ngIf="actividad.concepto === 1" class="fas fa-edit"></i>
															<i *ngIf="actividad.concepto === 2" class="fas fa-phone-alt"></i>
															<i *ngIf="actividad.concepto === 3" class="far fa-calendar-alt"></i>
															<i *ngIf="actividad.concepto === 4" class="far fa-envelope"></i>
															<i *ngIf="actividad.concepto === 5" class="far fa-calendar-alt"></i>
														</div>
													</div>
													<div class="widget-content-left">
														<div class="widget-heading pointer"> 
															<span *ngIf="actividad.concepto === 1">Nota</span>
															<span *ngIf="actividad.concepto === 2">Llamada</span>
															<span *ngIf="actividad.concepto === 3">Reunión</span>
															<span *ngIf="actividad.concepto === 4">Correo electrónico</span>
															<span *ngIf="actividad.concepto === 5">Reunión</span>
														</div>
														<div class="widget-subheading pointer">  {{actividad.descripcion}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary"> 
															<div class="widget-content-left mr-2" *ngIf="actividad.empresa">
																<div class="widget-description text-muted font-weight-light">
																	Empresa
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.empresa">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
																		<div class="avatar-icon">
																			<img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="widget-description text-muted font-weight-light">
																	Contactos
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
																		<div class="avatar-icon">
																			<img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
																<div class="widget-description text-muted font-weight-light">
																	Oportunidades
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
																		<div class="avatar-icon">
																			<img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">{{ (actividad.concepto === 1 ? actividad.fecRegistro : actividad.fecha) | amLocale: 'es' | amDateFormat: 'L'}}</div> 
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div class="mt-2">
							<div class="row p-2">
								<div class="col-sm-6">
									<div class="text-left">
										<ngb-pagination
											[collectionSize]="(actividades ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
											<ng-template ngbPaginationFirst>First</ng-template>
											<ng-template ngbPaginationLast>Last</ng-template>
											<ng-template ngbPaginationPrevious>Prev</ng-template>
											<ng-template ngbPaginationNext>Next</ng-template>
											<ng-template ngbPaginationEllipsis>...</ng-template>
											<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
										</ngb-pagination>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Notas</span></ng-template>
					<ng-template ngbTabContent>
						<ul class="list-group list-group-flush">
							<li class="list-group-item" *ngFor="let actividad of actividades 	| filtroActividadesConcepto: 1
																																								| slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
								<div class="no-gutters row">
									<div class="col-sm-6 col-md-4 col-xl-4">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="todo-indicator bg-primary"></div>
													<div class="widget-content-left mr-2 pointer">
														<div class="icon-wrapper rounded-circle">
															<div class="icon-wrapper-bg opacity-10 bg-light"></div>
															<i class="fas fa-edit"></i>
														</div>
													</div>
													<div class="widget-content-left">
														<div class="widget-heading pointer"> 
															<span>Nota</span>
														</div>
														<div class="widget-subheading pointer">  {{actividad.descripcion}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary"> 
															<div class="widget-content-left mr-2" *ngIf="actividad.empresa">
																<div class="widget-description text-muted font-weight-light">
																	Empresa
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.empresa">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
																		<div class="avatar-icon">
																			<img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="widget-description text-muted font-weight-light">
																	Contactos
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
																		<div class="avatar-icon">
																			<img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
																<div class="widget-description text-muted font-weight-light">
																	Oportunidades
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
																		<div class="avatar-icon">
																			<img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">{{actividad.fecRegistro | amLocale: 'es' | amDateFormat: 'L'}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div class="mt-2">
							<div class="row p-2">
								<div class="col-sm-6">
									<div class="text-left">
										<ngb-pagination
											[collectionSize]="(actividades | filtroActividadesConcepto: 1 ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
											<ng-template ngbPaginationFirst>First</ng-template>
											<ng-template ngbPaginationLast>Last</ng-template>
											<ng-template ngbPaginationPrevious>Prev</ng-template>
											<ng-template ngbPaginationNext>Next</ng-template>
											<ng-template ngbPaginationEllipsis>...</ng-template>
											<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
										</ngb-pagination>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Correos electrónicos</span></ng-template>
					<ng-template ngbTabContent>
						<ul class="list-group list-group-flush">
							<li class="list-group-item" *ngFor="let actividad of actividades 	| filtroActividadesConcepto: 4
																																								| slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
								<div class="no-gutters row">
									<div class="col-sm-6 col-md-4 col-xl-4">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="todo-indicator bg-primary"></div>
													<div class="widget-content-left mr-2 pointer">
														<div class="icon-wrapper rounded-circle">
															<div class="icon-wrapper-bg opacity-10 bg-light"></div>
															<i class="far fa-envelope"></i>
														</div>
													</div>
													<div class="widget-content-left">
														<div class="widget-heading pointer"> 
															<span>Correo electrónico</span>
														</div>
														<div class="widget-subheading pointer">  {{actividad.descripcion}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary"> 
															<div class="widget-content-left mr-2" *ngIf="actividad.empresa">
																<div class="widget-description text-muted font-weight-light">
																	Empresa
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.empresa">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
																		<div class="avatar-icon">
																			<img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="widget-description text-muted font-weight-light">
																	Contactos
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
																		<div class="avatar-icon">
																			<img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
																<div class="widget-description text-muted font-weight-light">
																	Oportunidades
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
																		<div class="avatar-icon">
																			<img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div class="mt-2">
							<div class="row p-2">
								<div class="col-sm-6">
									<div class="text-left">
										<ngb-pagination
											[collectionSize]="(actividades | filtroActividadesConcepto: 4 ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
											<ng-template ngbPaginationFirst>First</ng-template>
											<ng-template ngbPaginationLast>Last</ng-template>
											<ng-template ngbPaginationPrevious>Prev</ng-template>
											<ng-template ngbPaginationNext>Next</ng-template>
											<ng-template ngbPaginationEllipsis>...</ng-template>
											<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
										</ngb-pagination>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Llamadas</span></ng-template>
					<ng-template ngbTabContent>
						<ul class="list-group list-group-flush">
							<li class="list-group-item" *ngFor="let actividad of actividades 	| filtroActividadesConcepto: 2 
																																								| slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
								<div class="no-gutters row">
									<div class="col-sm-6 col-md-4 col-xl-4">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="todo-indicator bg-primary"></div>
													<div class="widget-content-left mr-2 pointer">
														<div class="icon-wrapper rounded-circle">
															<div class="icon-wrapper-bg opacity-10 bg-light"></div>
															<i class="fas fa-phone-alt"></i>
														</div>
													</div>
													<div class="widget-content-left">
														<div class="widget-heading pointer"> 
															<span>Llamada</span>
														</div>
														<div class="widget-subheading pointer">  {{actividad.descripcion}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary"> 
															<div class="widget-content-left mr-2" *ngIf="actividad.empresa">
																<div class="widget-description text-muted font-weight-light">
																	Empresa
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.empresa">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
																		<div class="avatar-icon">
																			<img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="widget-description text-muted font-weight-light">
																	Contactos
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
																		<div class="avatar-icon">
																			<img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
																<div class="widget-description text-muted font-weight-light">
																	Oportunidades
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
																		<div class="avatar-icon">
																			<img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div class="mt-2">
							<div class="row p-2">
								<div class="col-sm-6">
									<div class="text-left">
										<ngb-pagination
											[collectionSize]="(actividades | filtroActividadesConcepto: 2 ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
											<ng-template ngbPaginationFirst>First</ng-template>
											<ng-template ngbPaginationLast>Last</ng-template>
											<ng-template ngbPaginationPrevious>Prev</ng-template>
											<ng-template ngbPaginationNext>Next</ng-template>
											<ng-template ngbPaginationEllipsis>...</ng-template>
											<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
										</ngb-pagination>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Reuniones</span></ng-template>
					<ng-template ngbTabContent>
						<ul class="list-group list-group-flush">
							<li class="list-group-item" *ngFor="let actividad of actividades 	| filtroActividadesConcepto: 3
																																								| slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
								<div class="no-gutters row">
									<div class="col-sm-6 col-md-4 col-xl-4">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="todo-indicator bg-primary"></div>
													<div class="widget-content-left mr-2 pointer">
														<div class="icon-wrapper rounded-circle">
															<div class="icon-wrapper-bg opacity-10 bg-light"></div>
															<i class="far fa-calendar-alt"></i>
														</div>
													</div>
													<div class="widget-content-left">
														<div class="widget-heading pointer"> 
															<span>Reunión</span>
														</div>
														<div class="widget-subheading pointer">  {{actividad.descripcion}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary"> 
															<div class="widget-content-left mr-2" *ngIf="actividad.empresa">
																<div class="widget-description text-muted font-weight-light">
																	Empresa
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.empresa">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
																		<div class="avatar-icon">
																			<img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="widget-description text-muted font-weight-light">
																	Contactos
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
																		<div class="avatar-icon">
																			<img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">
															<div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
																<div class="widget-description text-muted font-weight-light">
																	Oportunidades
																</div>
															</div>
															<div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
																<div class="avatar-wrapper avatar-wrapper-overlap">
																	<a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
																		<div class="avatar-icon">
																			<img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6 col-md-2 col-xl-2">
										<div class="card no-shadow bg-transparent text-left">
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading text-secondary">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div class="mt-2">
							<div class="row p-2">
								<div class="col-sm-6">
									<div class="text-left">
										<ngb-pagination
											[collectionSize]="(actividades | filtroActividadesConcepto: 3).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
											<ng-template ngbPaginationFirst>First</ng-template>
											<ng-template ngbPaginationLast>Last</ng-template>
											<ng-template ngbPaginationPrevious>Prev</ng-template>
											<ng-template ngbPaginationNext>Next</ng-template>
											<ng-template ngbPaginationEllipsis>...</ng-template>
											<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
										</ngb-pagination>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</div>
	</div>
</div>


<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="actividad.concepto === 1">Nota</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 2">Llamada</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 3">Reunión</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 4">Correo electrónico</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 5">Reunión</h4>
  </div>
  <div class="modal-body">
    <form #profileContactoForm="ngForm" (ngSubmit)="onSubmit()" class="col-lg-12">
      <div class="row">
        <div class="col-lg-6" *ngIf="actividad.concepto !== 1">
          <span *ngIf="actividad.concepto === 2">
            <fieldset class="form-group required">
              <label for="actividadResultado" class="control-label">Resultado </label>
              <ng-select [items]="llamadas"
                bindLabel="nombre"
                bindValue="nombre"
                placeholder="Capture el resultado de la llamada."
                id="actividadResultado"  name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                [disabled]="!identity.permisos.empresasRegEdit" required>
              </ng-select>
            </fieldset>
          </span>
          <span *ngIf="actividad.concepto === 3">
            <fieldset class="form-group required" >
              <label for="actividadResultado" class="control-label">Resultado </label>
              <ng-select [items]="Reuniones"
                bindLabel="nombre"
                bindValue="nombre"
                placeholder="Capture el resultado de la llamada."
                id="actividadResultado"  name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                [disabled]="!identity.permisos.empresasRegEdit" required>
              </ng-select>
            </fieldset>
          </span>
					<span *ngIf="actividad.concepto === 4">
            <fieldset class="form-group required" >
              <label for="actividadResultado" class="control-label">Resultado </label>
              <ng-select [items]="correos"
                bindLabel="nombre"
                bindValue="nombre"
                placeholder="Capture el resultado de la llamada."
                id="actividadResultado"  name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                [disabled]="!identity.permisos.empresasRegEdit" required>
              </ng-select>
            </fieldset>
          </span>
          <span *ngIf="actividad.concepto === 5">
            <fieldset class="form-group required">
              <label for="actividadResultado" class="control-label">Motivo</label>
              <input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="actividadResultado" name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                class="form-control" maxlength="100" minlength="2" placeholder="¿De qué trata la reunión?" title="¿De qué trata la reunión?." required />
              <!-- <span *ngIf="!actividadResultado.valid && actividadResultado.touched">El motivo de la reunión es obligatorio</span> -->
            </fieldset>
          </span>
        </div>
      </div>
      <div class="row" *ngIf="actividad.concepto !== 1">
        <div class="col-lg-4">
          <fieldset class="form-group required">
            <label for="actividadFecha" class="control-label">Fecha </label>
            <input type="text"class="form-control" id="actividadFecha" name="actividadFecha" #actividadFecha="ngModel" bsDatepicker [(ngModel)]="actividad.fecha" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" >
          </fieldset>
        </div>
        <div class="col-lg-4">
          <fieldset class="form-group required">
            <label for="actividadHora"  class="control-label">Hora</label>
            <ngb-timepicker id="actividadHora" name="actividadHora" #actividadHora="ngModel" [(ngModel)]="actividad.hora" [meridian]="true" [spinners]="false"></ngb-timepicker>
          </fieldset>
        </div>
        <div class="col-lg-4" *ngIf="actividad.concepto === 3">
          <fieldset class="form-group required">
            <label for="actividadDuracion"  class="control-label">Duración</label>
            <ngb-timepicker id="actividadDuracion" name="actividadDuracion" #actividadDuracion="ngModel" [(ngModel)]="actividad.duracion" [spinners]="false"></ngb-timepicker>
          </fieldset>
        </div>
      </div>
      <fieldset class="form-group required">
        <label for="actividadDescripcion" class="control-label">Descripción</label>
          <textarea [disabled]="!identity.permisos.empresasRegEdit" id="actividadDescripcion" name="actividadDescripcion" #actividadDescripcion="ngModel" [(ngModel)]="actividad.descripcion"
          class="form-control" rows="3" placeholder="Escribe aquí..." title="Descripción de la actividad." required></textarea>
        <!-- <span *ngIf="!actividadDescripcion.valid && actividadDescripcion.touched">La descripción es obligatoria</span> -->
      </fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
    <button type="button" class="btn btn-primary"  (click)="onSubmit()" [disabled]="!profileContactoForm.form.valid || !identity.permisos.actividadesListEdit">Guardar cambios</button>
  </div>
</ng-template>