import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { KPIsVendedoresService } from 'src/app/services/kpis-vendedores.service';


@Injectable()
export class KPIsVendedoresEffects {

  constructor(
    private actions$: Actions,
    public kpisVendedoresService: KPIsVendedoresService
  ) {}

  @Effect()
  cargarActividades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES ),
    switchMap( () => {
      return this.kpisVendedoresService.obtenerActividadesUsuario()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesSuccess(respuesta.series, respuesta.categories);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarActividadesPendientes$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_PENDIENTES ),
    switchMap( () => {
      return this.kpisVendedoresService.obtenerActividadesPendientesUsuario()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesPendientesSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesPendientesFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarEmpresasRegistradas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_EMPRESAS_REGISTRADAS ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerEmpresasRegistradasUsuario(action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarEmpresasRegistradasSuccess(respuesta.empresas, respuesta.totalFiltro, respuesta.total);
          }),
          catchError( error => of(new usuariosActions.CargarEmpresasRegistradasFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarContactosRegistradas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CONTACTOS_REGISTRADAS ),
    switchMap( (action: any) => {
      return this.kpisVendedoresService.obtenerContactosRegistradasUsuario(action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarContactosRegistradasSuccess(respuesta.contactos, respuesta.totalFiltro, respuesta.total);
          }),
          catchError( error => of(new usuariosActions.CargarContactosRegistradasFail(error))  )
        );
    })
	);
	
	@Effect()
  cargarOportunidadesAbiertas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_OPORTUNIDADES_ABIERTAS ),
    switchMap( () => {
      return this.kpisVendedoresService.obtenerOportunidadesAbiertasUsuario()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarOportunidadesAbiertasSuccess(respuesta.oportunidades);
          }),
          catchError( error => of(new usuariosActions.CargarOportunidadesAbiertasFail(error))  )
        );
    })
  );

  @Effect()
  cargarFunnelOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_FUNNEL_OPORTUNIDADES ),
    switchMap( () => {
      return this.kpisVendedoresService.obtenerFunnelOportunidadesUsuario()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarFunnelOportunidadesSuccess(respuesta.dataSource);
          }),
          catchError( error => of(new usuariosActions.CargarFunnelOportunidadesFail(error))  )
        );
    })
	);


}
