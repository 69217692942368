import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { KpisVendedores } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-reporte-kpis-vendedores',
  templateUrl: './reporte-kpis-vendedores.component.html',
  styleUrls: ['./reporte-kpis-vendedores.component.scss']
})
export class ReporteKpisVendedoresComponent implements OnInit, OnDestroy {
  heading = 'Reporte de los KPIs';
  subheading = 'Reporte de los KPIs en el sistema';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeKPIsVendedor: Subscription = new Subscription();
  public identity: Identity;
  public loading = false;
  public url: string;
  public version = 1;
  public series: SeriesBar[] = [];
	public categories: string[] = [];
  public seriesActividades: number[] = [];
	public categoriesActividades: string[] = [];
	public empresas: KpisVendedores[] = [];
	public contactos: KpisVendedores[] = [];
  public oportunidadesAbiertas: KpisVendedores[] = [];
  // public actividadesTotal: Actividad = new Actividad(null);
	public empresasTotal: KpisVendedores = new KpisVendedores(null);
	public contactosTotal: KpisVendedores = new KpisVendedores(null);
  public oportunidadesAbiertasTotal: KpisVendedores = new KpisVendedores(null);
  public paginacionEmpresa = new Pagination(null);
  public paginacionContacto = new Pagination(null);
  public paginacionOportunidad = new Pagination(null);
  public paginacionActividad = new Pagination(null);
  public listSearchActividad: string[] = [];
  public listSearchEmpresa: string[] = [];
  public listSearchContacto: string[] = [];
  public listSearchOportunidad: string[] = [];
  public isCollapsed = false;
  public seriesClasificaciones: SeriesBar[] = [];
  public labelsClasificaciones: string[] = [];
  public seriesClases: number[] = [];
  public labelsClases: string[] = [];
  public seriesIndustrias: number[] = [];
  public labelsIndustrias: string[] = [];
  public dataFunnel: FunnelGraphColors[] = [];
  public colorPalettes = [
    '#831f38',
    '#a569bd',
    '#dc7633',
    '#576d96',
    '#ffcf1a',
    '#a79d7a',
    '#44aca3',
    '#a1ce69'
  ];
  public dates: Date[] = [];
  public chartLabel: Object = {
    visible: true,
    position: 'Inside',
    name: 'text'
  };
  public legend: Object = {
    visible: true,
    position: 'Right'
  };
  public tooltipSettings: object = {
    enable : true,
    format: '${point.x} : <b>$${point.y}</b>'
  };
  private formatterNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public labelsOptionsY = {
    title: {
      text: 'Importe'
    },
    labels: {
      show: true,
      align: 'right',
      minWidth: 0,
      maxWidth: 160,
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      formatter: (value) => { return this.formatterNumber.format(value); },
    }
  }
  palette = ["#E94649", "#F6B53F", "#6FAAB0", "#C4C24A"];
  public datePipe = new DatePipe("en-US");
  constructor(
    private store: Store<AppState>,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacionEmpresa.sortType = '-nombre';
    this.paginacionContacto.sortType = '-nombre';
    this.paginacionOportunidad.sortType = '-nombre';


    this.fromDate = calendar.getNext(calendar.getToday(), 'd', - 30);
    this.toDate = calendar.getToday();


    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 30);
    this.dates = [ fecha, new Date() ];
   }

   

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeKPIsVendedor = this.store.select('reporteKpiVendedor').subscribe(
      (state) => {
        this.empresasTotal = new KpisVendedores(null);
        this.contactosTotal = new KpisVendedores(null);
        this.oportunidadesAbiertasTotal = new KpisVendedores(null);
        
        this.series = [...state.series];
        this.categories = [...state.categories];
        this.seriesActividades = [...state.seriesActividad];
        this.categoriesActividades = [...state.categoriesActividad];
        this.empresas = [...state.empresas];
        this.contactos = [...state.contactos];
        this.oportunidadesAbiertas = [...state.oportunidadesAbiertas];
        this.seriesClasificaciones = [...state.seriesClasificaciones];
        this.labelsClasificaciones = [...state.labelsClasificaciones];
        this.seriesClases = [...state.seriesClases];
        this.labelsClases = [...state.labelsClases];
        this.seriesIndustrias = [...state.seriesIndustrias];
        this.labelsIndustrias = [...state.labelsIndustrias];
        this.dataFunnel = [...state.dataFunnel];

        this.empresasTotal.nombre = 'Total';
        this.contactosTotal.nombre = 'Total';
        this.oportunidadesAbiertasTotal.nombre = 'Total';

        this.empresas.find(item => { this.empresasTotal.cantidad += item.cantidad });
        this.contactos.find(item => { this.contactosTotal.cantidad += item.cantidad });
        this.oportunidadesAbiertas.find(item => { this.oportunidadesAbiertasTotal.cantidad += item.cantidad });

        this.paginacionActividad.totalSize = state.totalActividades;
      }
    );
    console.log('Inicializa el componente Reporte-kpis-vendedores ...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeKPIsVendedor.unsubscribe();
  }
   
  inicializa() {
    const opciones: any = {};
    opciones.numPage = this.paginacionActividad.page;
    opciones.numLimit = this.paginacionActividad.pageSize;
    this.store.dispatch(new usuariosActions.CargarReporteActividades(this.fromDate, this.toDate, opciones));
    this.store.dispatch(new usuariosActions.CargarReporteActividadesPendientes(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteEmpresasRegistradas(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteContactosRegistradas(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteOportunidadesAbiertas(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteClasificacionOportunidades(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteClasesOportunidades(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteIndustriasOportunidades(this.fromDate, this.toDate));
    this.store.dispatch(new usuariosActions.CargarReporteFunnelOportunidades(this.fromDate, this.toDate));
  }

  sortEmpresa(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionEmpresa.sortType || ('+' + descriptionSort) === this.paginacionEmpresa.sortType ) {
      this.paginacionEmpresa.sortReverse = !this.paginacionEmpresa.sortReverse;
      if (this.paginacionEmpresa.sortReverse) {
        this.paginacionEmpresa.sortType = '-' + descriptionSort;
      } else {
        this.paginacionEmpresa.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionEmpresa.sortType = '+' + descriptionSort;
      this.paginacionEmpresa.sortReverse = false;
    }
  }

  changeTextSearchEmpresa() {
    this.listSearchEmpresa = this.paginacionEmpresa.textSearch.split(' ');
  }

  removerTextSearchEmpresa(text: string) {
    this.listSearchEmpresa = this.listSearchEmpresa.filter( item => item !== text );
    this.paginacionEmpresa.textSearch = this.listSearchEmpresa.join(' ');
  }

  sortContacto(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionContacto.sortType || ('+' + descriptionSort) === this.paginacionContacto.sortType ) {
      this.paginacionContacto.sortReverse = !this.paginacionContacto.sortReverse;
      if (this.paginacionContacto.sortReverse) {
        this.paginacionContacto.sortType = '-' + descriptionSort;
      } else {
        this.paginacionContacto.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionContacto.sortType = '+' + descriptionSort;
      this.paginacionContacto.sortReverse = false;
    }
  }

  changeTextSearchContacto() {
    this.listSearchContacto = this.paginacionContacto.textSearch.split(' ');
  }

  removerTextSearchContacto(text: string) {
    this.listSearchContacto = this.listSearchContacto.filter( item => item !== text );
    this.paginacionContacto.textSearch = this.listSearchContacto.join(' ');
  }

  sortOportunidad(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionOportunidad.sortType || ('+' + descriptionSort) === this.paginacionOportunidad.sortType ) {
      this.paginacionOportunidad.sortReverse = !this.paginacionOportunidad.sortReverse;
      if (this.paginacionOportunidad.sortReverse) {
        this.paginacionOportunidad.sortType = '-' + descriptionSort;
      } else {
        this.paginacionOportunidad.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionOportunidad.sortType = '+' + descriptionSort;
      this.paginacionOportunidad.sortReverse = false;
    }
  }

  changeTextSearchOportunidad() {
    this.listSearchOportunidad = this.paginacionOportunidad.textSearch.split(' ');
  }

  removerTextSearchOportunidad(text: string) {
    this.listSearchOportunidad = this.listSearchOportunidad.filter( item => item !== text );
    this.paginacionOportunidad.textSearch = this.listSearchOportunidad.join(' ');
  }

  onDateSelection(fechas: Date[]) {
    if(fechas && fechas.length){
      const dates: string[] = [...fechas.map(item => { return this.datePipe.transform(item, 'yyyy-MM-dd'); })];
      this.fromDate = this.dateToStructDateModel(dates.shift());
      this.toDate = this.dateToStructDateModel(dates.pop());
      this.inicializa();
    }
  }

  dateToStructDateModel(date: string) {
    let data: string[] = date.split('-');
    return new NgbDate ( Number(data[0]),  Number(data[1]),  Number(data[2]));
	}

  cargarActividades(reset = true) {
    if (reset) {
      this.paginacionActividad.page = 1;
    }
    const opciones: any = {};
    opciones.numPage = this.paginacionActividad.page;
    opciones.numLimit = this.paginacionActividad.pageSize;
    // opciones.desOrderBy = this.paginacionActividad.sortType;
    this.store.dispatch(new usuariosActions.CargarReporteActividades(this.fromDate, this.toDate, opciones));
  }


}
