<div class="he-auto">
  <div class="conatiner">
    <div class="justify-content-center align-items-center">
      <div class="mx-auto app-login-box bg-white p-4 app-password-change-box">
        <h4>
          <div>Cambiar la contraseña del usuario actual</div>
          <span>Facil de hacer, solo sigue los siguientes pasos.</span>
        </h4>
        <form (ngSubmit)="onSubmit( f.value )" #f="ngForm">
          <fieldset class="form-group" id="__BVID__132">
            <div tabindex="-1" role="group">
              <label for="oldPasswordChangePassword">
                <span class="text-danger">*</span>
                Contraseña actual
              </label>
              <input ngModel type="password" id="oldPasswordChangePassword" name="oldPassword" #oldPassword="ngModel" placeholder="Contraseña actual..." class="form-control" required>
            </div>
          </fieldset>
          <fieldset class="form-group" id="__BVID__134">
            <div tabindex="-1" role="group">
              <label for="newPasswordChangePassword">
                <span class="text-danger">*</span>
                Nueva contraseña
              </label>
              <input ngModel type="password" id="newPasswordChangePassword" name="newPassword" #newPassword="ngModel" placeholder="Nueva contraseña" class="form-control" required>
            </div>
          </fieldset>
          <fieldset class="form-group" id="__BVID__138">
            <div tabindex="-1" role="group">
              <label for="passwordRepChangePassword">
                <span class="text-danger">*</span>
                Confirma la contraseña
              </label>
              <input ngModel id="passwordRepChangePassword" name="passwordRep" #passwordRep="ngModel" type="password" placeholder="Confirma aquí tu contraseña..." class="form-control" required>
            </div>
          </fieldset>
          <div class="mt-4 d-flex align-items-center">
            <div class="ml-auto">
              <button type="submit" data-style="zoom-in" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-success btn-lg" *ngIf="!cargando">  Registrar cambios</button>
              <button class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondary btn-lg" type="button" disabled *ngIf="cargando">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
