<div class="row p-3 justify-content-center">
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.usuariosListEdit || identity.permisos.usuariosListVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/usuarios']" style="border-color: #a1ce69">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="pe-7s-users font-weight-bold"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Usuarios</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.usuarios" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.empresasListEdit || identity.permisos.empresasListVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/empresas']" style="border-color: #44aca3">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="fa fa-building-o font-weight-light"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Empresas</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.empresas" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.contactosListEdit || identity.permisos.contactosListVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/contactos']" style="border-color: #a79d7a">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="pe-7s-id font-weight-bold"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Contactos</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.contactos" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.oportunidadesListEdit || identity.permisos.oportunidadesListVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/oportunidades']" style="border-color: #ffcf1a">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="fas fa-hand-holding-usd"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Oportunidades</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.oportunidades" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.campannasEdit || identity.permisos.campannasVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/marketing/allcampanas']" style="border-color: #576d96">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="pe-7s-mail font-weight-bold"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Marketing</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.campanias" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.repositoriosListEdit || identity.permisos.repositoriosListVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/repositorios']" style="border-color: #dc7633">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="pe-7s-folder font-weight-bold"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Repositorio</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.repositorio" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.archivosListEdit || identity.permisos.archivosListVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/archivos']" style="border-color: #a569bd">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="pe-7s-file font-weight-bold"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Archivos</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.archivos" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3" *ngIf="identity.permisos && (identity.permisos.ticketsEdit || identity.permisos.ticketsVer)">
    <a class="card-btm-border scale-1-2 text-dark text-decoration-none card-shadow-primary card mb-2" [routerLink]="['/auth/tickets']" style="border-color: #be1c1c">
      <div class="widget-content">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="mr-2 fsize-4">
              <i class="pe-7s-ticket font-weight-bold"></i>
            </div>
          </div>
          <div class="widget-content-left">
            <div class="widget-title text-uppercase">Tickets</div>
          </div>
          <div class="widget-content-right">
            <div class="widget-numbers fsize-4 mb-0 w-100">
              <div class="widget-chart-flex align-items-center count-up-wrapper-2">
                <span [countUp]="resumen.tickets" [options]="opts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
<div class="row pl-3 pr-3">
  <div class="col-sm-12 col-lg-6">
    <div class="mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon lnr-apartment bg-black"> </i>
          Información
        </div>
      </div>
      <div class="p-0 card-body">
        <div class="p-1 slick-slider-sm mx-auto">
          <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig6">
            <div ngxSlickItem>
              <div class="widget-chart widget-chart2 text-left p-0">
                <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content widget-chart-content-lg pb-0 pt-2">
                    <div class="widget-chart-flex">
                      <div class="widget-title opacity-5 text-muted text-uppercase">Nuevos contactos</div>
                    </div>
                    <div class="widget-numbers">
                      <div class="widget-chart-flex">
                        <div>
                          <small class="opacity-5 pr-1">Hoy </small>
                          <span>{{ hoyContactos }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                    <div class="d-block">
                      <apx-chart
                        [series]="seriesContactos"
                        [chart]="{
                                height: '350',
                                type: 'line',
                                stacked: false,
                                locales: [lenguaje],
                                defaultLocale: 'es'
                              }"
                              [colors]="['#3f6ad8', '#3ac47d', '#d92550']"
                        [stroke]="{
                                width: [1, 4, 5],
                                curve: 'smooth'
                              }"
                        [plotOptions]="{
                                bar: {
                                  columnWidth: '50%'
                                }
                              }"
                        [fill]="{
                                opacity: [0.85, 1, 1],
                                gradient: {
                                  inverseColors: false,
                                  shade: 'light',
                                  type: 'vertical',
                                  opacityFrom: 0.85,
                                  opacityTo: 0.55,
                                  stops: [0, 100, 100, 100]
                                }
                              }"
                        [labels]="labelsContactos"
                        [xaxis]="{
                                type: 'datetime'
                              }"
                        [yaxis]="{
                                type: 'numeric',
                                title: {
                                  text: 'Cantidad'
                                },
                                min: 0
                              }"
                      ></apx-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ngxSlickItem>
              <div class="widget-chart widget-chart2 text-left p-0">
                <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content widget-chart-content-lg pb-0 pt-2">
                    <div class="widget-chart-flex">
                      <div class="widget-title opacity-5 text-muted text-uppercase">Nuevas empresas</div>
                    </div>
                    <div class="widget-numbers">
                      <div class="widget-chart-flex">
                        <div>
                          <small class="opacity-5 pr-1">Hoy </small>
                          <span>{{ hoyEmpresas }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                    <div class="d-block">
                      <apx-chart
                        [series]="seriesEmpresas"
                        [chart]="{
                                height: '350',
                                type: 'line',
                                stacked: false,
                                locales: [lenguaje],
                                defaultLocale: 'es'
                              }"
                              [colors]="['#3ac47d']"
                        [stroke]="{
                                width: [4],
                                curve: 'smooth'
                              }"
                        [plotOptions]="{
                                bar: {
                                  columnWidth: '50%'
                                }
                              }"
                        [fill]="{
                                opacity: [0.85, 1, 1],
                                gradient: {
                                  inverseColors: false,
                                  shade: 'light',
                                  type: 'vertical',
                                  opacityFrom: 0.85,
                                  opacityTo: 0.55,
                                  stops: [0, 100, 100, 100]
                                }
                              }"
                        [labels]="labelsEmpresas"
                        [xaxis]="{
                                type: 'datetime'
                              }"
                        [yaxis]="{
                                type: 'numeric',
                                title: {
                                  text: 'Cantidad'
                                },
                                min: 0
                              }"
                      ></apx-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ngxSlickItem>
              <div class="widget-chart widget-chart2 text-left p-0">
                <div class="widget-chat-wrapper-outer">
                  <div class="widget-chart-content widget-chart-content-lg pb-0 pt-2">
                    <div class="widget-chart-flex">
                      <div class="widget-title opacity-5 text-muted text-uppercase">Nuevas oportunidades</div>
                    </div>
                    <div class="widget-numbers">
                      <div class="widget-chart-flex">
                        <div>
                          <small class="opacity-5 pr-1">Hoy </small>
                          <span>{{ hoyOportunidades }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="widget-chart-wrapper he-auto opacity-10 m-0">
                    <div class="d-block">
                      <apx-chart
                        [series]="seriesOportunidades"
                        [chart]="{
                                height: '350',
                                type: 'line',
                                stacked: false,
                                locales: [lenguaje],
                                defaultLocale: 'es'
                              }"
                              [colors]="['#d92550']"
                        [stroke]="{
                                width: [5],
                                curve: 'smooth'
                              }"
                        [plotOptions]="{
                                bar: {
                                  columnWidth: '50%'
                                }
                              }"
                        [fill]="{
                                opacity: [0.85, 1, 1],
                                gradient: {
                                  inverseColors: false,
                                  shade: 'light',
                                  type: 'vertical',
                                  opacityFrom: 0.85,
                                  opacityTo: 0.55,
                                  stops: [0, 100, 100, 100]
                                }
                              }"
                        [labels]="labelsOportunidades"
                        [xaxis]="{
                                type: 'datetime'
                              }"
                        [yaxis]="{
                                type: 'numeric',
                                title: {
                                  text: 'Cantidad'
                                },
                                min: 0
                              }"
                      ></apx-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- <apx-chart
          [series]="seriesContactos"
          [chart]="{
              height: '350',
              type: 'line',
              stacked: false,
              locales: [lenguaje],
              defaultLocale: 'es'
            }"
          [stroke]="{
              width: [1],
              curve: 'smooth'
            }"
          [plotOptions]="{
              bar: {
                columnWidth: '50%'
              }
            }"
          [fill]="{
              opacity: [1],
              gradient: {
                inverseColors: false,
                shade: 'light',
                type: 'vertical',
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
              }
            }"
          [xaxis]="{
              type: 'datetime',
              locale: 'es',
              categories: labelsContactos
            }"
          [yaxis]="{
              type: 'numeric',
              title: {
                text: 'Cantidad'
              },
              min: 0
            }"
        ></apx-chart> -->
    </div>
  </div>
  <div class="col-sm-12 col-lg-6" *ngIf="identity.permisos && (identity.permisos.ticketsEdit || identity.permisos.ticketsVer)">
    <div class="card-hover-shadow-2x mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal"><i
          class="header-icon pe-7s-ticket bg-black"></i> Mis tickets
        </div>
      </div>
      <div class="scroll-area-lg">
        <perfect-scrollbar [autoPropagation]="true">
          <ul class="todo-list-wrapper list-group list-group-flush">
            <app-item-ticket [tickets]="tickets | optionCheckMultiple : statusFilter
              | optionCheckMultiple : existeFilter
              | orderBy: '-fecModificacion' 
              | slice: 0 : 10"></app-item-ticket>
            <li class="text-center list-group-item" *ngIf="!(tickets | optionCheckMultiple : statusFilter | optionCheckMultiple : existeFilter).length"><h3 class="text-muted">Sin tickets pendientes</h3></li>
          </ul>
        </perfect-scrollbar>
      </div>
      <div class="d-flex card-footer">
        <a class="btn btn-link btn-sm mr-auto" *ngIf="identity.permisos && identity.permisos.ticketsVer" [routerLink]="['/auth/tickets']">Ver más</a>
        <a class="btn btn-primary" *ngIf="identity.permisos && identity.permisos.ticketsEdit" [routerLink]="['/auth/tickets/register', 0]">Nuevo ticket</a>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6" *ngIf="identity.permisos && (identity.permisos.actividadesListEdit || identity.permisos.actividadesListVer)">
    <div class="card-hover-shadow-2x mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon fas fa-tags bg-black"></i> Actividades pendientes
        </div>
      </div>
      <div class="scroll-area-lg">
        <perfect-scrollbar [autoPropagation]="true">
          <ul class="list-group list-group-flush" *ngIf="actividades.length">
            <li class="list-group-item" *ngFor="let actividad of actividades">
              <div class="no-gutters row">
                <div class="col-sm-6 col-md-4 col-xl-4">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="todo-indicator bg-primary"></div>
                        <div class="widget-content-left mr-2 pointer">
                          <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg opacity-10 bg-light"></div>
                            <i *ngIf="actividad.concepto === 2" class="fas fa-phone-alt"></i>
                            <i *ngIf="actividad.concepto === 3" class="far fa-calendar-alt"></i>
                            <i *ngIf="actividad.concepto === 4" class="far fa-envelope"></i>
                            <i *ngIf="actividad.concepto === 5" class="far fa-calendar-alt"></i>
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading pointer"> 
                            <span *ngIf="actividad.concepto === 2">Llamada</span>
                            <span *ngIf="actividad.concepto === 3">Reunión</span>
                            <span *ngIf="actividad.concepto === 4">Correo electrónico</span>
                            <span *ngIf="actividad.concepto === 5">Reunión</span>
                          </div>
                          <div class="widget-subheading pointer">  {{actividad.descripcion}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2 col-xl-2">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading text-secondary"> 
                            <div class="widget-content-left mr-2" *ngIf="actividad.empresa">
                              <div class="widget-description text-muted font-weight-light">
                                Empresa
                              </div>
                            </div>
                            <div class="widget-content-left mr-3" *ngIf="actividad.empresa">
                              <div class="avatar-wrapper avatar-wrapper-overlap">
                                <a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
                                  <div class="avatar-icon">
                                    <img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2 col-xl-2">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading text-secondary">
                            <div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
                              <div class="widget-description text-muted font-weight-light">
                                Contactos
                              </div>
                            </div>
                            <div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
                              <div class="avatar-wrapper avatar-wrapper-overlap">
                                <a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
                                  <div class="avatar-icon">
                                    <img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2 col-xl-2">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading text-secondary">
                            <div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
                              <div class="widget-description text-muted font-weight-light">
                                Oportunidades
                              </div>
                            </div>
                            <div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
                              <div class="avatar-wrapper avatar-wrapper-overlap">
                                <a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
                                  <div class="avatar-icon">
                                    <img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2 col-xl-2">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading text-secondary">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="col-12" *ngIf="!actividades.length">
            <p class="ml-2 mt-3 h3 text-primary text-center">NO HAY ACTIVIDADES PENDIENTES</p>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="d-flex card-footer">
        <a class="btn btn-link btn-sm mr-auto" *ngIf="identity.permisos && identity.permisos.actividadesListEdit" [routerLink]="['/auth/actividades/programadas']">Ver más</a>
      </div>
    </div>
  </div>
</div>
<div class="row p-3" *ngIf="identity.permisos && identity.permisos.usuariosListVer">
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-header">Últimas conexiones de usuarios</div>
      <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th>Nombre</th>
              <th>Última conexión</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usuario of usuariosConectados">
              <td class="text-center text-muted">#{{usuario.id}}</td>
              <td>
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                      <div class="widget-content-left">
                        <img width="40" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + usuario.id+ '/imagenes'" alt="Imagen de usuario">
                      </div>
                    </div>
                    <div class="widget-content-left flex2">
                      <div class="widget-heading">{{usuario.descripcion}}</div>
                      <div class="widget-subheading opacity-7">{{usuario.desTipo}}</div>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{usuario.registro | amLocale: 'es' | amCalendar }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
