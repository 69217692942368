import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Identity } from 'src/app/models/user';
import { GLOBAL } from 'src/app/models/global';
import { map } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class AccessUserService implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let regresa = false;
    return this.authService.getIdentity()
            .pipe(
              map( (identity: Identity) => {
                if (identity !== undefined && identity !== null && identity.id > 0) {
                  if (route.data && route.data.validate) {
                    route.data.validate.forEach( (element: string) => {
                      if (element in identity.permisos && identity.permisos[element]) {
                        regresa = true;
                      }
                    });
                  } else {
                    regresa = true;
                  }
                  if (!regresa) {
                    this.router.navigate(['/auth/not-authorization']);
                  }
                } else {
                  this.router.navigate(['/public/login']);
                  regresa = false;
                }
                return regresa;
              }));
  }

}
