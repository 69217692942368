import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ReportesService } from 'src/app/services/reportes.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Injectable()
export class DashboardEffects {

  constructor(
    private actions$: Actions,
    public reportesService: ReportesService,
    private dashboardService: DashboardService
  ) {}

  @Effect()
  cargarDashboardUsuarioConectados$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DASHBOARD_USUARIOS_CONECTADOS ),
    switchMap( (action: any ) => {
      return this.reportesService.getUsuariosConectados()
        .pipe(
          map( (respuesta: any) => {
            return new usuariosActions.CargarDashboardUsuariosConectadosSuccess(respuesta.usuarios);
          }),
          catchError( error => of(new usuariosActions.CargarDashboardUsuariosConectadosFail(error))  )
        );
    })
  );

  @Effect()
  cargarDashboardResumen$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DASHBOARD_RESUMEN ),
    switchMap( (action: any ) => {
      return this.dashboardService.getResumen()
        .pipe(
          map( (respuesta: any) => {
            return new usuariosActions.CargarDashboardResumenSuccess(respuesta.resumen);
          }),
          catchError( error => of(new usuariosActions.CargarDashboardResumenFail(error))  )
        );
    })
  );

  @Effect()
  cargarDashboardLineContactos$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DASHBOARD_LINE_CONTACTOS ),
    switchMap( (action: any ) => {
      return this.dashboardService.getLineContactos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarDashboardLineContactosSuccess(respuesta.labels, respuesta.series, respuesta.hoy);
          }),
          catchError( error => of(new usuariosActions.CargarDashboardLineContactosFail(error))  )
        );
    })
  );

  @Effect()
  cargarDashboardLineEmpresas$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DASHBOARD_LINE_EMPRESAS ),
    switchMap( (action: any ) => {
      return this.dashboardService.getLineEmpresas()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarDashboardLineEmpresasSuccess(respuesta.labels, respuesta.series, respuesta.hoy);
          }),
          catchError( error => of(new usuariosActions.CargarDashboardLineEmpresasFail(error))  )
        );
    })
  );

  @Effect()
  cargarDashboardLineOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DASHBOARD_LINE_OPORTUNIDADES ),
    switchMap( (action: any ) => {
      return this.dashboardService.getLineOportunidades()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarDashboardLineOportunidadesSuccess(respuesta.labels, respuesta.series, respuesta.hoy);
          }),
          catchError( error => of(new usuariosActions.CargarDashboardLineOportunidadesFail(error))  )
        );
    })
  );

  @Effect()
  cargarDashboardActividadesPendientesDia$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA ),
    switchMap( (action: any ) => {
      return this.dashboardService.getActividadesPendientesDia()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarDashboardActividadesPendientesDiaSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarDashboardActividadesPendientesDiaFail(error))  )
        );
    })
  );



}
