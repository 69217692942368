<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <!-- <div class="form-group">
          <label for="" class="control-label">Ordenar por</label>
          <select class="form-control custom-select" name="sortTypeProducto" id="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType">
            <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
          </select>
        </div> -->
        </div>
        <h5 class="menu-header-title font-size-md text-capitalize ml-3">
          Filtrar tipos
        </h5>
        <div class="card-body p-0" id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="scroll-area-lg mb-2">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">
                <ul class="todo-list-wrapper list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let item of filterTipos">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-2">
                          <div class="custom-checkbox custom-control">
                            <input type="checkbox" id="selectCheckTiposCampaniaFilter{{item.nombre}}"
                              class="custom-control-input" name="selectCheckTiposCampaniaFilter{{item.nombre}}"
                              [(ngModel)]="item.checked" (change)="changeCheck()">
                            <label class="custom-control-label"
                              for="selectCheckTiposCampaniaFilter{{item.nombre}}">&nbsp;</label>
                          </div>
                        </div>
                        <div class="widget-content-left flex2">
                          <div class="widget-heading">{{item.nombre}}</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </perfect-scrollbar>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Lista de campañas</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(campanas | filterSelectedCampingSend : { opciones: filterTipos, parametro: 'estatus'} | anyScope:
          paginacion.textSearch ).length}}
          de {{(campanas).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios"
                  id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacion.textSearch"
                  (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
            </div>
            <button type="button" [routerLink]="['/auth/marketing/nuevacampana']" [routerLinkActive]=""
              class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Crear nueva
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive"
              (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <button type="button" class="m-2 btn-hover-shine btn btn btn-light" *ngFor="let texto of listSearch"
          (click)="removerTextSearch(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <table class="table table-stripper">
          <thead>
            <tr>
              <th width="90px" (click)="sort('estatus')">Estatus
                <span *ngIf="paginacion.sortType === '-estatus'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+estatus'" class="fa fa-caret-up"></span>
              </th>
              <th (click)="sort('tema')">Campaña
                <span *ngIf="paginacion.sortType === '-tema'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+tema'" class="fa fa-caret-up"></span>
              </th>
              <th (click)="sort('fecModificacion')">Modificación
                <span *ngIf="paginacion.sortType === '-fecModificacion'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+fecModificacion'" class="fa fa-caret-up"></span>
              </th>
              <th (click)="sort('recipientes')">Destinatarios
                <span *ngIf="paginacion.sortType === '-recipientes'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+recipientes'" class="fa fa-caret-up"></span>
              </th>
              <th (click)="sort('expedido')">Enviado
                <span *ngIf="paginacion.sortType === '-expedido'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+expedido'" class="fa fa-caret-up"></span>
              </th>
              <th (click)="sort('aperturas_unicas')">Aperturas únicas
                <span *ngIf="paginacion.sortType === '-aperturas_unicas'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+aperturas_unicas'" class="fa fa-caret-up"></span>
              </th>
              <th (click)="sort('clicks_unicos')">Clics únicos
                <span *ngIf="paginacion.sortType === '-clicks_unicos'" class="fa fa-caret-down"></span>
                <span *ngIf="paginacion.sortType === '+clicks_unicos'" class="fa fa-caret-up"></span>
              </th>
              <th width="112px">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let campana of campanas | anyScope: paginacion.textSearch | filterSelectedCampingSend : { opciones: filterTipos, parametro: 'estatus'} | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize">
              <td>
                <div class="badge badge pl-2 pr-2 badge-danger" *ngIf="campana.estatus === 5">Error (2)</div>
                <div class="badge badge pl-2 pr-2 badge-danger" *ngIf="campana.estatus === 4">Error (1)</div>
                <div class="badge badge pl-2 pr-2 badge-success" *ngIf="campana.estatus === 3">Enviado</div>
                <div class="badge badge pl-2 pr-2 badge-info" *ngIf="campana.estatus === 2">Enviando...</div>
                <div class="badge badge pl-2 pr-2 badge-secondary" *ngIf="campana.estatus === 1">Borrador</div>
              </td>
              <td>
                <a *ngIf="campana.estatus === 5 || campana.estatus === 4 || campana.estatus === 2" class="text-black">{{campana.tema}}</a>
                <a [routerLink]="['/auth/marketing/reportes', campana.id]" class="text-black"
                  *ngIf="campana.estatus === 3">{{campana.tema}}</a>
                <a [routerLink]="['/auth/marketing/guardar-y-enviar-campana', campana.id]" class="text-black"
                  *ngIf="campana.estatus === 1">{{campana.tema}}</a>
              </td>
              <td>
                {{campana.fecModificacion | amLocale: 'es' | amTimeAgo}}
              </td>
              <td>
                <span *ngIf="campana.estatus === 3">{{campana.recipientes}}</span>
                <span *ngIf="campana.estatus === 1">-</span>
              </td>
              <td>
                <span *ngIf="campana.estatus === 3">
                  ({{campana.expedido | amLocale: 'es' | amTimeAgo }})
                </span>
                <span *ngIf="campana.estatus === 1 ">-</span>
              </td>
              <td>
                <span *ngIf="campana.aperturas_unicas">{{campana.aperturas_unicas}}</span>
                <span *ngIf="!campana.aperturas_unicas">-</span>
              </td>
              <td>
                <span *ngIf="campana.clicks_unicos">{{campana.clicks_unicos}}</span>
                <span *ngIf="!campana.clicks_unicos">-</span>
              </td>
              <td>
                <div class="d-inline-block" ngbDropdown placement="left">
                  <button type="button" *ngIf="campana.estatus !== 2" class="btn-pill btn btn-outline-primary" ngbDropdownToggle>
                    Acciones
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-xl">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-sunny-morning">
                        <div class="menu-header-content text-dark">
                          <h5 class="menu-header-title">ACCIONES</h5>
                        </div>
                      </div>
                    </div>
                    <div class="grid-menu grid-menu-xl grid-menu-3col">
                      <div class="no-gutters row">
                        <div *ngIf="campana.estatus === 1" class="col-sm-6 col-xl-4">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary"
                            title="Editar campaña" [routerLink]="['/auth/marketing/editar-campana/', campana.id]">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-edit"> </i>
                            Editar Campaña
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 1" class="col-sm-6 col-xl-4">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
                            title="Ver / Enviar"
                            [routerLink]="['/auth/marketing/guardar-y-enviar-campana/', campana.id]">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-envelope"> </i>
                            Ver / Enviar
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 1" class="col-sm-6 col-xl-4">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
                            title="Eliminar campaña" (click)="EliminarCampana(campana)">
                            <i class="fas fa-trash btn-icon-wrapper btn-icon-lg mb-3"></i> Eliminar borrador
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 3" class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary"
                            title="Ver Métricas (En desarrollo)"
                            [routerLink]="['/auth/marketing/reportes/', campana.id]">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-bar-chart"> </i>
                            Ver Métricas
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 3" class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary"
                            (click)="DuplicarCampana(campana)" title="Duplicar como borrador.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-clone"> </i>
                            Duplicar como borrador
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 4" class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
                            title="Eliminar campaña" (click)="EliminarCampana(campana)">
                            <i class="fas fa-trash btn-icon-wrapper btn-icon-lg mb-3"></i> Eliminar
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 4" class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary"
                            (click)="DuplicarCampana(campana)" title="Duplicar como borrador.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-clone"> </i>
                            Duplicar como borrador
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 5" class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
                            title="Eliminar campaña" (click)="EliminarCampana(campana)">
                            <i class="fas fa-trash btn-icon-wrapper btn-icon-lg mb-3"></i> Eliminar
                          </button>
                        </div>
                        <div *ngIf="campana.estatus === 5" class="col-sm-6 col-xl-6">
                          <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary"
                            (click)="DuplicarCampana(campana)" title="Duplicar como borrador.">
                            <i class="fas btn-icon-wrapper btn-icon-lg mb-3 fa-clone"> </i>
                            Duplicar como borrador
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="card no-shadow bg-transparent text-left">
              <div class="widget-chart-content">
                <ngb-pagination class="mb-2" [boundaryLinks]="true"
                  [collectionSize]="(campanas | anyScope: paginacion.textSearch | filterSelectedCampingSend : { opciones: filterTipos, parametro: 'estatus'} ).length"
                  [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-right">
              <button type="button" (click)="printXLS()" class="btn btn-primary mb-2">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>