import { SortOrder } from './sort';

export class ArchivoGeneral {
  public id: number;
  public nombre: string;
  public size: number;
  public path: string;
  public directorio: number;
  public folderName: string;
  public folderPath: string;

  public type: string;
  public mimeType: string;
  public name: string;
  public thumbnail: string;
  public imagen: string;
  public fecArchivo: Date;
  public file: File;
  public urlPath: any;
  public progress: number;
  public progressTotal: number;
  public loaded: boolean;
  public loading: boolean;
  public error: boolean;
  public editar: boolean;
  public nombreNuevo: string;
  public fecRegistro: Date;
  public fecActualizo: Date;
  public usuarioRegistro: number;
  public desUsuarioRegistro: string;
  public usuarioModifico: number;
  public desUsuarioModifico: string;
  public departamentos: string[];
  constructor(
    obj?: ObjArchivoGeneral
  ) {
    this.id = obj && obj.id ? obj.id : null;
    this.nombre = obj && obj.nombre ? obj.nombre : null;
    this.size = obj && obj.size ? obj.size : null;
    this.path = obj && obj.path ? obj.path : null;
    this.directorio = obj && obj.directorio ? obj.directorio : null;
    this.folderName = obj && obj.folderName ? obj.folderName : null;
    this.folderPath = obj && obj.folderPath ? obj.folderPath : null;
    this.type = obj && obj.type ? obj.type : null;
    this.mimeType = obj && obj.mimeType ? obj.mimeType : null;
    this.name = obj && obj.name ? obj.name : null;
    this.thumbnail = obj && obj.thumbnail ? obj.thumbnail : null;
    this.imagen = obj && obj.imagen ? obj.imagen : null;
    this.fecArchivo = obj && obj.fecArchivo ? obj.fecArchivo : null;
    this.file = obj && obj.file ? obj.file : null;
    this.urlPath = obj && obj.urlPath ? obj.urlPath : null;
    this.progress = obj && obj.progress ? obj.progress : null;
    this.progressTotal = obj && obj.progressTotal ? obj.progressTotal : null;
    this.loaded = obj && obj.loaded ? obj.loaded : null;
    this.loading = obj && obj.loading ? obj.loading : null;
    this.error = obj && obj.error ? obj.error : null;
    this.editar = obj && obj.editar ? obj.editar : null;
    this.nombreNuevo = obj && obj.nombreNuevo ? obj.nombreNuevo : null;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
    this.fecActualizo = obj && obj.fecActualizo ? obj.fecActualizo : null;
    this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : null;
    this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : null;
    this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : null;
    this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico : null;
    this.departamentos = obj && obj.departamentos ? obj.departamentos : [];
  }
}

interface ObjArchivoGeneral {
  id?: number;
  nombre?: string;
  size?: number;
  path?: string;
  directorio?: number;
  folderName?: string;
  folderPath?: string;
  type?: string;
  mimeType?: string;
  name?: string;
  thumbnail?: string;
  imagen?: string;
  fecArchivo?: Date;
  file?: File;
  urlPath?: any;
  progress?: number;
  progressTotal?: number;
  loaded?: boolean;
  loading?: boolean;
  error?: boolean;
  editar?: boolean;
  nombreNuevo?: string;
  fecRegistro?: Date;
  fecActualizo?: Date;
  usuarioRegistro?: number;
  desUsuarioRegistro?: string;
  usuarioModifico?: number;
  desUsuarioModifico?: string;
  departamentos?: string[];
}

export const ArchivosGeneralSortType: SortOrder[] = [
  { id: '+nombre', text: 'Nombre de Archivo'},
  { id: '-nombre', text: 'Nombre de Archivo desc'},
  { id: '+categoria', text: 'Categoría'},
  { id: '-categoria', text: 'Categoría desc'},
  { id: '+tipo', text: 'Tipo de Archivo'},
  { id: '-tipo', text: 'Tipo de Archivo desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'}
];
