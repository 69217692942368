import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class KPIsVendedoresService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerActividades(inicia: NgbDate, termina: NgbDate, opciones: any): Observable<any> {
    const obj = {inicia, termina, opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/actividad', params, {headers}); 
	}
	
	obtenerActividadesPendientes(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/actividad/pendiente', params, {headers});
	}
	
	obtenerEmpresasRegistradas(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/empresa', params, {headers});
	}
	
	obtenerContactosRegistradas(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/contacto', params, {headers});
	}
	
	obtenerOportunidadesAbiertas(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/oportunidad', params, {headers});
  }

  obtenerClasificacionOportunidades(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/oportunidad/clasificacion', params, {headers});
  }

  obtenerClasesOportunidades(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/oportunidad/clases', params, {headers});
  }

  obtenerIndustriasOportunidades(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/oportunidad/industrias', params, {headers});
  }

  obtenerFunnelOportunidades(inicia: NgbDate, termina: NgbDate): Observable<any> {
    const obj = {inicia, termina};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/oportunidad/funnel', params, {headers});
  }




  obtenerActividadesUsuario(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(this.url + '/kpi-vendedores/actividad/usuario', {headers}); 
	}
	
	obtenerActividadesPendientesUsuario(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(this.url + '/kpi-vendedores/actividad/pendiente/usuario', {headers});
	}
	
	obtenerEmpresasRegistradasUsuario(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/get/empresa/usuario', params, {headers});
	}
	
	obtenerContactosRegistradasUsuario(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.post(this.url + '/kpi-vendedores/get/contacto/usuario', params, {headers});
	}
	
	obtenerOportunidadesAbiertasUsuario(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(this.url + '/kpi-vendedores/oportunidad/usuario', {headers});
  }


  obtenerFunnelOportunidadesUsuario(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(this.url + '/kpi-vendedores/oportunidad/funnel/usuario', {headers});
  }

}
