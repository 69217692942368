import * as fromUsuarios from '../actions';
import { User } from 'src/app/models/user';
import { DashboardResumen, ListaResumen } from 'src/app/models/dashboard-resumen';
import { BarGraph, CircleGraph } from 'src/app/models/graph';
import { ListaGps, CompaniaLineaGps, Serie } from '../../models/dashboard-resumen';
import { Actividad } from 'src/app/models/actividad';


interface MarcasDispositivos {
  disponibles: number;
  asignados: number;
  mermas: number;
  marca: string;
}

interface GPS {
  otros: number;
  fijo: number;
  portatil: number;
  total: number;
}

export interface DashboardState {
  usuariosConectados: User[];
  resumen: DashboardResumen;
  lineasAsignadas: BarGraph;
  estatusLineas: CircleGraph;
  estatusLineasFiliales: CircleGraph;
  tiposLineas: CircleGraph;
  tiposLineasFiliales: CircleGraph;
  tiposCompaniasTelefonicas: BarGraph;
  tiposCompaniasTelefonicasFiliales: BarGraph;
  listaCompaniasTelefonicas: ListaResumen[];
  listaCompaniasTelefonicasFiliales: ListaResumen[];
  estatusDispositivos: BarGraph;
  companiasTelefonicasDispositivos: CircleGraph;
  dispositivosMarcas: MarcasDispositivos[];
  tiposDispositivos: CircleGraph;
  listaMarcaRadios: ListaResumen[];
  listaClientesGps: ListaGps[];
  barClientesGps: BarGraph;
  tiposGps: CircleGraph;
  companiasLineasGps: CompaniaLineaGps[];
  barCompaniasLineasGps: BarGraph;
  gps: GPS;
  labelsContactos: string[];
  seriesContactos: Serie[];
  labelsEmpresas: string[];
  seriesEmpresas: Serie[];
  labelsOportunidades: string[];
  seriesOportunidades: Serie[];
  hoyContactos: number;
  hoyEmpresas: number;
  hoyOportunidades: number;
  actividades: Actividad[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: DashboardState = {
  usuariosConectados: [],
  resumen: new DashboardResumen(null),
  lineasAsignadas: {series: [], categories: []},
  estatusLineas: {series: [], labels: []},
  estatusLineasFiliales: {series: [], labels: []},
  tiposLineas: {series: [], labels: []},
  tiposLineasFiliales: {series: [], labels: []},
  tiposCompaniasTelefonicas: {series: [], categories: []},
  tiposCompaniasTelefonicasFiliales: {series: [], categories: []},
  listaCompaniasTelefonicas: [],
  listaCompaniasTelefonicasFiliales: [],
  listaMarcaRadios: [],
  estatusDispositivos: {series: [], categories: []},
  companiasTelefonicasDispositivos: {series: [], labels: []},
  dispositivosMarcas: [],
  tiposDispositivos: {series: [], labels: []},
  listaClientesGps: [],
  barClientesGps: {series: [], categories: []},
  tiposGps: {series: [], labels: []},
  companiasLineasGps: [],
  barCompaniasLineasGps: {series: [], categories: []},
  gps: { otros: 0, fijo: 0, portatil: 0, total: 0 },
  labelsContactos:[],
  seriesContactos:[],
  labelsEmpresas:[],
  seriesEmpresas:[],
  labelsOportunidades:[],
  seriesOportunidades:[],
  hoyContactos: 0,
  hoyEmpresas: 0,
  hoyOportunidades: 0,
  actividades:[],
  loaded: false,
  loading: false,
  error: null
};

export function dashboardReducer( state = estadoInicial, action: fromUsuarios.dashboardAcciones ): DashboardState {

  switch ( action.type ) {
    case fromUsuarios.CARGAR_DASHBOARD_RESUMEN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_RESUMEN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resumen: {...action.resumen}
      };
    case fromUsuarios.CARGAR_DASHBOARD_RESUMEN_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_USUARIOS_CONECTADOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_USUARIOS_CONECTADOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usuariosConectados: [...action.usuarios]
      };
    case fromUsuarios.CARGAR_DASHBOARD_USUARIOS_CONECTADOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        estatusDispositivos: {...action.estatusDispositivos}
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_LINEAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_LINEAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        estatusLineas: {...action.estatusLineas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_LINEAS_FILIALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_LINEAS_FILIALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        estatusLineasFiliales: {...action.estatusLineas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_ESTATUS_LINEAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tiposCompaniasTelefonicas: {...action.tiposCompaniasTelefonicas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FILIALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FILIALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tiposCompaniasTelefonicas: {...action.tiposCompaniasTelefonicas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_LINEAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_LINEAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tiposLineas: {...action.tiposLineas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_LINEAS_FILIALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_LINEAS_FILIALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tiposLineasFiliales: {...action.tiposLineas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_LINEAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINEAS_ASIGNADAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINEAS_ASIGNADAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        lineasAsignadas: {...action.lineasAsignadas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINEAS_ASIGNADAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        listaCompaniasTelefonicas: [...action.tiposCompaniasTelefonicas]
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FILIALES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FILIALES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        listaCompaniasTelefonicasFiliales: [...action.tiposCompaniasTelefonicas]
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
      case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        companiasTelefonicasDispositivos: {...action.tiposCompaniasTelefonicas}
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        dispositivosMarcas: [...action.dispositivosMarcas]
      };
    case fromUsuarios.CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tiposDispositivos: {...action.tiposDispositivos}
      };
    case fromUsuarios.CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        listaMarcaRadios: [...action.listaMarcasRadios]
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_CLIENTES_GPS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_CLIENTES_GPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        listaClientesGps: [...action.listaClientesGps],
        barClientesGps: {...action.barClientesGps}
      };
    case fromUsuarios.CARGAR_DASHBOARD_LISTA_CLIENTES_GPS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tiposGps: {...action.tiposGps},
        gps: {...action.gps}
      };
    case fromUsuarios.CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        companiasLineasGps: [...action.companiasLineasGps],
        barCompaniasLineasGps: {...action.barCompaniasLineasGps}
      };
    case fromUsuarios.CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
    };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        labelsContactos: [...action.labels],
        seriesContactos: [...action.series],
        hoyContactos: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_EMPRESAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        labelsEmpresas: [...action.labels],
        seriesEmpresas: [...action.series],
        hoyEmpresas: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_EMPRESAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        labelsOportunidades: [...action.labels],
        seriesOportunidades: [...action.series],
        hoyOportunidades: action.hoy
      };
    case fromUsuarios.CARGAR_DASHBOARD_LINE_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        actividades: [...action.actividades]
      };
    case fromUsuarios.CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}

