import * as fromUsuario from '../actions';
import { Catalogo } from 'src/app/models/catalogo';

export interface DepartamentoState {
  departamentos: Catalogo[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: DepartamentoState = {
  departamentos: [],
  loaded: false,
  loading: false,
  error: null
};

export function departamentosReducer( state = initState, action: fromUsuario.departamentosAcciones ): DepartamentoState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_DEPARTAMENTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_DEPARTAMENTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        departamentos: [...action.departamento]
      };
    case fromUsuario.CARGAR_DEPARTAMENTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.ACTUALIZA_DEPARTAMENTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_DEPARTAMENTO_SUCCESS:
      const itm = state.departamentos.find( item => item.id === action.departamento.id);
      if (itm && itm.id) {
        state.departamentos = state.departamentos.map( item => {
          if (item.id === action.departamento.id) {
            item = {...action.departamento};
          }
          return item;
        });
      } else {
        state.departamentos.push(action.departamento);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_DEPARTAMENTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_DEPARTAMENTO_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_DEPARTAMENTO_ESTATUS_SUCCESS:
      state.departamentos = state.departamentos.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_DEPARTAMENTO_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
