import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(time: any): any {
    
    if (time) {
      let hour = ( time.split(':'))[0];
      let min = (time.split(':'))[1];
      const part = hour >= 12 ? 'pm' : 'am';
      hour = hour === "0" ? 24 : hour;
      min = (min + '').length === 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour + '').length === 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`;
    } else {
      return;
    }
  }

}
