import { Component, OnInit, OnDestroy } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo';
import { Contacto } from 'src/app/models/contacto';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { ContactosService } from 'src/app/services/contactos.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { GLOBAL } from 'src/app/models/global';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Oportunidad } from 'src/app/models/oportunidades';
import { Actividad, CorreoCatalogo, LlamadaCatalogo, ReunionCatalogo } from '../../../../models/actividad';
import { ActividadesEmpresasService } from '../../../../services/actividades-empresas.service';
import { Pagination } from 'src/app/models/pagination';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';
import { CorreoContacto } from '../../../../models/contacto';


@Component({
  selector: 'app-contacto-profile',
  templateUrl: './contacto-profile.component.html',
  styleUrls: ['./contacto-profile.component.scss']
})
export class ContactoProfileComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subcriptionContacto: Subscription = new Subscription();
  private subcriptionActividad: Subscription = new Subscription();
  public contacto: Contacto = new Contacto(null);
  public actividades: Actividad[] = [];
  public actividad: Actividad = new Actividad(null);
  public oportunidades: Oportunidad[] = [];
  public loading: boolean;
  public version = 0;
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };
  public Reuniones = [...ReunionCatalogo];
  public llamadas = [...LlamadaCatalogo];
  public correos = [...CorreoCatalogo];
  public url = '';
  public paginacion: Pagination = new Pagination(null);
  private pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();

  constructor(
    private store: Store<AppState>,
    private contactosService: ContactosService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private actividadesEmpresasService: ActividadesEmpresasService
  ) {
    this.identity = new Identity(null);
    this.url = GLOBAL.url;
    this.paginacion.sortType = '-fecModifico';
    this.store.dispatch(new usuariosActions.LimpiarActividades());
  }


  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = { ...state.identity };
      }
    );
    this.subcriptionContacto = this.store.select('contacto').subscribe(
      (state) => {
        this.contacto = { ...state.contacto };
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subcriptionActividad = this.store.select('actividadEmpresa').subscribe(
      (state) => {
        this.actividades = [...state.actividades];
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.oportunidades = [...state.oportunidadesContacto.filter(item => item.status)];
      }
    );
    console.log('Inicializa component empresas-edit...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionContacto.unsubscribe();
    this.subcriptionActividad.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarContacto(id));
          this.store.dispatch(new usuariosActions.CargarActividadesContacto(id));
          this.store.dispatch(new usuariosActions.ConsultaOportunidadesContacto(id));
        } else {
          this.contacto = new Contacto(null);
        }
      });
  }

  onSubmit() {
    return new Promise((resolve, reject) => {
      this.actividadesEmpresasService.registraActividadesEmpresa(this.actividad).subscribe(
        (respuesta) => {
          if (respuesta.actividad) {
            this.modalService.dismissAll('Close');
            this.store.dispatch(new usuariosActions.ActualizaActividadesEmpresaSuccess(respuesta.actividad));
            this.store.dispatch(new usuariosActions.CargarDashboardActividadesPendientesDia());
            resolve(respuesta.actividad);
          } else {
            resolve(false);
          }
        }, (error) => {
          resolve(false);
        }
      );
    });
  }

  verificarResponsable() {
    // if (this.actividad.contactos.length > 0 || this.actividad.oportunidades.length > 0) {
    this.loading = true;
    // }else{
    //   this.loading = false;
    // }
  }

  // en el archivo del componente de Angular
  cambiarEstado(correo:CorreoContacto) {
    Swal.fire({
      title: correo.suscrito ?  '¿Deseas desuscribir al contacto?' : '¿Deseas suscribir al contacto?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      // correo
      preConfirm: () => {
        return new Promise((resolve) => {
          this.contactosService.modificaEstatusCorreo(correo).subscribe(
            (respuesta) => {
              if (respuesta.respond) {
                this.contacto.email.find(item => item.email === correo.email).suscrito = !correo.suscrito;
                resolve(true);

              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaContactoFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Contacto suscrito.',
            icon: 'success'
          });
          this.router.navigate(['/auth/contactos/profile', result.value.id]);
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () => { }
          }).then(() => { }, () => { });
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => { }
        }).then(() => { }, () => { });
      }
    );
    // aquí podrías hacer una llamada a una API o guardar el estado en el almacenamiento local
  }


  limpiarFecha() {
    this.actividad.fecha = null
  }


  open(concepto: number, id: number, content) {
    if (concepto !== 6) {
      if (id > 0) {
        this.actividades.findIndex((item) => {
          if (item.concepto === concepto && item.id === id) {
            this.actividad = { ...item };
            this.actividad.fecha = this.actividad.fecha ? this.pipeDateWithoutTimeZone.transform(this.actividad.fecha) : null;
            this.actividad.contactos.push({ id: this.contacto.id, nombre: this.contacto.nombre, status: true });
          }
        });
      } else {
        this.actividad = new Actividad(null);
        this.actividad.concepto = concepto;
        this.actividad.empresa = this.contacto.empresa;
        this.actividad.contactos.push({ id: this.contacto.id, nombre: this.contacto.nombre, status: true });
      }
      this.modalService.open(content, {
        size: 'lg'
      });
    }
  }

  printDireccion() {
    let coma = '';
    let direccion = '';
    if (this.contacto.desPais) {
      direccion += coma + this.contacto.desPais;
      coma = ', ';
    }
    if (this.contacto.desEstadoDireccion) {
      direccion += coma + this.contacto.desEstadoDireccion;
      coma = ', ';
    }
    if (this.contacto.desMunicipio) {
      direccion += coma + this.contacto.desMunicipio;
      coma = ', ';
    }
    if (this.contacto.colonia) {
      direccion += coma + this.contacto.colonia;
      coma = ', ';
    }
    if (this.contacto.calle) {
      direccion += coma + this.contacto.calle;
      coma = ', ';
    }
    if (this.contacto.codigoPostal) {
      direccion += coma + 'C.P. ' + this.contacto.codigoPostal;
      coma = ', ';
    }
    if (direccion === '') {
      direccion = 'Sin domicilio';
    }
    return direccion;
  }

}
