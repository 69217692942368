<div class="bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto col-md-6">
      <form #f="ngForm" (ngSubmit)="onSubmit(f.value)">
        <div class="modal-dialog w-100">
          <div class="modal-content">
            <div class="modal-header">
              <div class="h5 modal-title">
                Confirma la restauración de tu contraseña
                <h6 class="mt-1 mb-0 opacity-8">
                  <span>Usa el siguiente formulario para confirmar los cambios.</span>
                </h6>
              </div>
            </div>
            <div class="modal-body">
              <fieldset class="form-group">
                <div tabindex="-1" role="group">
                  <label for="passwordRecovery">Nueva contraseña</label>
                  <input type="password" id="passwordRecovery" name="passwordRecovery" #passwordRecovery="ngModel"
                    class="form-control" [(ngModel)]="usuario.contrasena"
                    title="Ingrese contraseña de usuario. Requiere ser mayor de 6 caracteres sin espacios."
                    placeholder="Ingrese mínimo 6 caracteres" minlength="6" maxlength="100" pattern="^\S{6,50}$" required>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <div tabindex="-1" role="group">
                  <label for="confirmPasswordRecovery">Confirmar contraseña </label>
                  <input type="password" id="confirmPasswordRecovery" name="confirmPasswordRecovery" #confirmPasswordRecovery="ngModel"
                    class="form-control" [(ngModel)]="usuario.confirmaContrasena"
                    title="Ingrese contraseña de usuario. Requiere ser mayor de 6 caracteres sin espacios."
                    placeholder="Ingrese mínimo 6 caracteres" minlength="6" maxlength="100" pattern="^\S{6,50}$" required>
                </div>
              </fieldset>
            </div>
            <div class="modal-footer clearfix">
              <h6 class="mb-0 pull-left">
                <a [routerLink]="['/public/login']" class="text-primary">Ir a inicio de sesión</a>
              </h6>
              <div class="float-right">
                <button type="submit" data-style="zoom-in" class="btn btn-primary btn-lg" [disabled]="!f.valid">Confirmar cambios</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Jivabe Rating and Consulting {{year}}
      </div>
    </div>
  </div>
</div>
