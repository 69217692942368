import { Action } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { DashboardResumen } from 'src/app/models/dashboard-resumen';
import { CircleGraph } from 'src/app/models/graph';
import { Serie } from '../../models/dashboard-resumen';
import { Actividad } from 'src/app/models/actividad';


export const CARGAR_DASHBOARD_RESUMEN = '[Dashboard] Cargar dashboard resumen';
export const CARGAR_DASHBOARD_RESUMEN_FAIL = '[Dashboard] Cargar dashboard resumen FAIL';
export const CARGAR_DASHBOARD_RESUMEN_SUCCESS = '[Dashboard] Cargar dashboard resumen SUCCESS';
export const CARGAR_DASHBOARD_USUARIOS_CONECTADOS = '[Dashboard] Cargar dashboard usuarios conectados';
export const CARGAR_DASHBOARD_USUARIOS_CONECTADOS_FAIL = '[Dashboard] Cargar dashboard usuarios conectados FAIL';
export const CARGAR_DASHBOARD_USUARIOS_CONECTADOS_SUCCESS = '[Dashboard] Cargar dashboard usuarios conectados SUCCESS';
export const CARGAR_DASHBOARD_ESTATUS_LINEAS = '[Dashboard] Cargar dashboard estatus lineas';
export const CARGAR_DASHBOARD_ESTATUS_LINEAS_FAIL = '[Dashboard] Cargar dashboard estatus lineas FAIL';
export const CARGAR_DASHBOARD_ESTATUS_LINEAS_SUCCESS = '[Dashboard] Cargar dashboard estatus lineas SUCCESS';
export const CARGAR_DASHBOARD_ESTATUS_LINEAS_FILIALES = '[Dashboard] Cargar dashboard estatus lineas Filiales';
export const CARGAR_DASHBOARD_ESTATUS_LINEAS_FILIALES_SUCCESS = '[Dashboard] Cargar dashboard estatus lineas Filiales SUCCESS';
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS = '[Dashboard] Cargar dashboard tipos de companias lineas';
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FAIL = '[Dashboard] Cargar dashboard tipos de companias lineas FAIL';
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_SUCCESS = '[Dashboard] Cargar dashboard tipos de companias lineas SUCCESS';
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FILIALES = '[Dashboard] Cargar dashboard tipos de companias lineas Filiales';
// tslint:disable-next-line:max-line-length
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FILIALES_SUCCESS = '[Dashboard] Cargar dashboard tipos de companias lineas Filiales SUCCESS';
export const CARGAR_DASHBOARD_TIPOS_LINEAS = '[Dashboard] Cargar dashboard tipos de lineas';
export const CARGAR_DASHBOARD_TIPOS_LINEAS_FAIL = '[Dashboard] Cargar dashboard tipos de lineas FAIL';
export const CARGAR_DASHBOARD_TIPOS_LINEAS_SUCCESS = '[Dashboard] Cargar dashboard tipos de lineas SUCCESS';
export const CARGAR_DASHBOARD_TIPOS_LINEAS_FILIALES = '[Dashboard] Cargar dashboard tipos de lineas Filiales';
export const CARGAR_DASHBOARD_TIPOS_LINEAS_FILIALES_SUCCESS = '[Dashboard] Cargar dashboard tipos de lineas Filiales SUCCESS';
export const CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS = '[Dashboard] Cargar dashboard estatus dispositivos';
export const CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS_FAIL = '[Dashboard] Cargar dashboard estatus dispositivos FAIL';
export const CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS_SUCCESS = '[Dashboard] Cargar dashboard estatus dispositivos SUCCESS';
export const CARGAR_DASHBOARD_LINEAS_ASIGNADAS = '[Dashboard] Cargar dashboard resumen lineas';
export const CARGAR_DASHBOARD_LINEAS_ASIGNADAS_FAIL = '[Dashboard] Cargar dashboard resumen lineas FAIL';
export const CARGAR_DASHBOARD_LINEAS_ASIGNADAS_SUCCESS = '[Dashboard] Cargar dashboard resumen lineas SUCCESS';
export const CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS = '[Dashboard] Cargar dashboard lista tipos de companias lineas';
export const CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FAIL = '[Dashboard] Cargar dashboard lista tipos de companias lineas FAIL';
export const CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_SUCCESS = '[Dashboard] Cargar dashboard lista tipos de companias lineas SUCCESS';
// tslint:disable-next-line:max-line-length
export const CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FILIALES = '[Dashboard] Cargar dashboard lista tipos de companias lineas Filiales';
// tslint:disable-next-line:max-line-length
export const CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FILIALES_SUCCESS = '[Dashboard] Cargar dashboard lista tipos de companias lineas Filiales SUCCESS';
export const CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS = '[Dashboard] Cargar dashboard tipos de dispositivos';
export const CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS_FAIL = '[Dashboard] Cargar dashboard tipos de dispositivos FAIL';
export const CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS_SUCCESS = '[Dashboard] Cargar dashboard tipos de dispositivos SUCCESS';
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS = '[Dashboard] Cargar dashboard lista tipos de companias dispositivos';
// tslint:disable-next-line:max-line-length
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS_FAIL = '[Dashboard] Cargar dashboard lista tipos de companias dispositivos FAIL';
// tslint:disable-next-line:max-line-length
export const CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS_SUCCESS = '[Dashboard] Cargar dashboard lista tipos de companias dispositivos SUCCESS';
export const CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS = '[Dashboard] Cargar dashboard resumen dispositivos marcas';
export const CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS_FAIL = '[Dashboard] Cargar dashboard resumen dispositivos marcas FAIL';
export const CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS_SUCCESS = '[Dashboard] Cargar dashboard resumen dispositivos marcas SUCCESS';
export const CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS = '[Dashboard] Cargar dashboard lista marcas radios';
export const CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS_FAIL = '[Dashboard] Cargar dashboard lista marcas radios FAIL';
export const CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS_SUCCESS = '[Dashboard] Cargar dashboard lista marcas radios SUCCESS';
export const CARGAR_DASHBOARD_LISTA_CLIENTES_GPS = '[Dashboard] Cargar dashboard lista de clientes con gps';
export const CARGAR_DASHBOARD_LISTA_CLIENTES_GPS_FAIL = '[Dashboard] Cargar dashboard lista de clientes con gps FAIL';
export const CARGAR_DASHBOARD_LISTA_CLIENTES_GPS_SUCCESS = '[Dashboard] Cargar dashboard lista de clientes con gps SUCCESS';
export const CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS = '[Dashboard] Cargar dashboard resumen tipos Gps';
export const CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS_FAIL = '[Dashboard] Cargar dashboard resumen tipos Gps FAIL';
export const CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS_SUCCESS = '[Dashboard] Cargar dashboard resumen tipos Gps SUCCESS';
export const CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS = '[Dashboard] Cargar dashboard companias lineas con gps';
export const CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS_FAIL = '[Dashboard] Cargar dashboard companias lineas con gps FAIL';
export const CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS_SUCCESS = '[Dashboard] Cargar dashboard companias lineas con gps SUCCESS';
export const CARGAR_DASHBOARD_LINE_CONTACTOS = '[Dashboard] Cargar dashboard line contactos';
export const CARGAR_DASHBOARD_LINE_CONTACTOS_FAIL = '[Dashboard] Cargar dashboard line contactos FAIL';
export const CARGAR_DASHBOARD_LINE_CONTACTOS_SUCCESS = '[Dashboard] Cargar dashboard line contactos SUCCESS';
export const CARGAR_DASHBOARD_LINE_EMPRESAS = '[Dashboard] Cargar dashboard line empresas';
export const CARGAR_DASHBOARD_LINE_EMPRESAS_FAIL = '[Dashboard] Cargar dashboard line empresas FAIL';
export const CARGAR_DASHBOARD_LINE_EMPRESAS_SUCCESS = '[Dashboard] Cargar dashboard line empresas SUCCESS';
export const CARGAR_DASHBOARD_LINE_OPORTUNIDADES = '[Dashboard] Cargar dashboard line oportunidades';
export const CARGAR_DASHBOARD_LINE_OPORTUNIDADES_FAIL = '[Dashboard] Cargar dashboard line oportunidades FAIL';
export const CARGAR_DASHBOARD_LINE_OPORTUNIDADES_SUCCESS = '[Dashboard] Cargar dashboard line oportunidades SUCCESS';
export const CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA = '[Dashboard] Cargar dashboard actividades dia';
export const CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA_FAIL = '[Dashboard] Cargar dashboard actividades dia FAIL';
export const CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA_SUCCESS = '[Dashboard] Cargar dashboard actividades dia SUCCESS';


export class CargarDashboardResumen implements Action {
  readonly type = CARGAR_DASHBOARD_RESUMEN;
}

export class CargarDashboardResumenFail implements Action {
  readonly type = CARGAR_DASHBOARD_RESUMEN_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardResumenSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_RESUMEN_SUCCESS;

  constructor( public resumen: DashboardResumen) {}
}

export class CargarDashboardTiposLineas implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_LINEAS;
}

export class CargarDashboardTiposLineasFail implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_LINEAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardTiposLineasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_LINEAS_SUCCESS;

  constructor( public tiposLineas: any) {}
}

export class CargarDashboardTiposLineasFiliales implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_LINEAS_FILIALES;
}

export class CargarDashboardTiposLineasFilialesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_LINEAS_FILIALES_SUCCESS;

  constructor( public tiposLineas: any) {}
}

export class CargarDashboardTiposCompaniasLineas implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS;
}

export class CargarDashboardTiposCompaniasLineasFail implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardTiposCompaniasLineasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_SUCCESS;

  constructor( public tiposCompaniasTelefonicas: any) {}
}
export class CargarDashboardTiposCompaniasLineasFiliales implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FILIALES;
}

export class CargarDashboardTiposCompaniasLineasFilialesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_LINEAS_FILIALES_SUCCESS;

  constructor( public tiposCompaniasTelefonicas: any) {}
}

export class CargarDashboardEstatusLineas implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_LINEAS;
}

export class CargarDashboardEstatusLineasFail implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_LINEAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardEstatusLineasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_LINEAS_SUCCESS;

  constructor( public estatusLineas: any) {}
}

export class CargarDashboardEstatusLineasFiliales implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_LINEAS_FILIALES;
}

export class CargarDashboardEstatusLineasFilialesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_LINEAS_FILIALES_SUCCESS;

  constructor( public estatusLineas: any) {}
}

export class CargarDashboardEstatusDispositivos implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS;
}

export class CargarDashboardEstatusDispositivosFail implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardEstatusDispositivosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_ESTATUS_DISPOSITIVOS_SUCCESS;

  constructor( public estatusDispositivos: any) {}
}

export class CargarDashboardUsuariosConectados implements Action {
  readonly type = CARGAR_DASHBOARD_USUARIOS_CONECTADOS;
}

export class CargarDashboardUsuariosConectadosFail implements Action {
  readonly type = CARGAR_DASHBOARD_USUARIOS_CONECTADOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardUsuariosConectadosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_USUARIOS_CONECTADOS_SUCCESS;

  constructor( public usuarios: User[]) {}
}

export class CargarDashboardLineasAsignadas implements Action {
  readonly type = CARGAR_DASHBOARD_LINEAS_ASIGNADAS;
}

export class CargarDashboardLineasAsignadasFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINEAS_ASIGNADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineasAsignadasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINEAS_ASIGNADAS_SUCCESS;

  constructor( public lineasAsignadas: any) {}
}

export class CargarDashboardListaTiposCompaniasLineas implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS;
}

export class CargarDashboardListaTiposCompaniasLineasFail implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardListaTiposCompaniasLineasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_SUCCESS;

  constructor( public tiposCompaniasTelefonicas: any[]) {}
}

export class CargarDashboardListaTiposCompaniasLineasFiliales implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FILIALES;
}

export class CargarDashboardListaTiposCompaniasLineasFilialesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_TIPOS_COMPANIAS_LINEAS_FILIALES_SUCCESS;

  constructor( public tiposCompaniasTelefonicas: any[]) {}
}

export class CargarDashboardTiposDispositivos implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS;
}

export class CargarDashboardTiposDispositivosFail implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardTiposDispositivosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_DISPOSITIVOS_SUCCESS;

  constructor( public tiposDispositivos: CircleGraph) {}
}

export class CargarDashboardTiposCompaniasDispositivos implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS;
}

export class CargarDashboardTiposCompaniasDispositivosFail implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardTiposCompaniasDispositivosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_TIPOS_COMPANIAS_DISPOSITIVOS_SUCCESS;

  constructor( public tiposCompaniasTelefonicas: CircleGraph) {}
}

export class CargarDashboardDispositivosMarcas implements Action {
  readonly type = CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS;
}

export class CargarDashboardDispositivosMarcasFail implements Action {
  readonly type = CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardDispositivosMarcasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_DISPOSITIVOS_MARCAS_SUCCESS;

  constructor( public dispositivosMarcas: any[]) {}
}

export class CargarDashboardListaMarcasRadios implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS;
}

export class CargarDashboardListaMarcasRadiosFail implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardListaMarcasRadiosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_MARCAS_RADIOS_SUCCESS;

  constructor( public listaMarcasRadios: any[]) {}
}

export class CargarDashboardListaClientesGps implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_CLIENTES_GPS;
}

export class CargarDashboardListaClientesGpsFail implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_CLIENTES_GPS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardListaClientesGpsSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LISTA_CLIENTES_GPS_SUCCESS;

  constructor( public listaClientesGps: any[], public barClientesGps: any ) {}
}

export class CargarDashboardResumenTiposGps implements Action {
  readonly type = CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS;
}

export class CargarDashboardResumenTiposGpsFail implements Action {
  readonly type = CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardResumenTiposGpsSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_RESUMEN_TIPOS_GPS_SUCCESS;

  constructor( public tiposGps: CircleGraph, public gps: any) {}
}

export class CargarDashboardCompaniasLineasGps implements Action {
  readonly type = CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS;
}

export class CargarDashboardCompaniasLineasGpsFail implements Action {
  readonly type = CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardCompaniasLineasGpsSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_COMPANIAS_LINEAS_GPS_SUCCESS;

  constructor( public companiasLineasGps: any[], public barCompaniasLineasGps: any ) {}
}

export class CargarDashboardLineContactos implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_CONTACTOS;
}

export class CargarDashboardLineContactosFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_CONTACTOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineContactosSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_CONTACTOS_SUCCESS;

  constructor( public labels: string[], public series: Serie[], public hoy: number) {}
}

export class CargarDashboardLineEmpresas implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_EMPRESAS;
}

export class CargarDashboardLineEmpresasFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_EMPRESAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineEmpresasSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_EMPRESAS_SUCCESS;

  constructor( public labels: string[], public series: Serie[], public hoy: number) {}
}

export class CargarDashboardLineOportunidades implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_OPORTUNIDADES;
}

export class CargarDashboardLineOportunidadesFail implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardLineOportunidadesSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_LINE_OPORTUNIDADES_SUCCESS;

  constructor( public labels: string[], public series: Serie[], public hoy: number) {}
}

export class CargarDashboardActividadesPendientesDia implements Action {
  readonly type = CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA;
}

export class CargarDashboardActividadesPendientesDiaFail implements Action {
  readonly type = CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDashboardActividadesPendientesDiaSuccess implements Action {
  readonly type = CARGAR_DASHBOARD_ACTIVIDADES_PENDIENTES_DIA_SUCCESS;

  constructor( public actividades: Actividad[] ) {}
}

export type dashboardAcciones =   CargarDashboardResumen
                                | CargarDashboardResumenFail
                                | CargarDashboardResumenSuccess
                                | CargarDashboardTiposLineas
                                | CargarDashboardTiposLineasFail
                                | CargarDashboardTiposLineasSuccess
                                | CargarDashboardTiposLineasFiliales
                                | CargarDashboardTiposLineasFilialesSuccess
                                | CargarDashboardEstatusDispositivos
                                | CargarDashboardEstatusDispositivosFail
                                | CargarDashboardEstatusDispositivosSuccess
                                | CargarDashboardEstatusLineas
                                | CargarDashboardEstatusLineasFail
                                | CargarDashboardEstatusLineasSuccess
                                | CargarDashboardEstatusLineasFiliales
                                | CargarDashboardEstatusLineasFilialesSuccess
                                | CargarDashboardTiposCompaniasLineas
                                | CargarDashboardTiposCompaniasLineasFail
                                | CargarDashboardTiposCompaniasLineasSuccess
                                | CargarDashboardTiposCompaniasLineasFiliales
                                | CargarDashboardTiposCompaniasLineasFilialesSuccess
                                | CargarDashboardUsuariosConectados
                                | CargarDashboardUsuariosConectadosFail
                                | CargarDashboardUsuariosConectadosSuccess
                                | CargarDashboardLineasAsignadas
                                | CargarDashboardLineasAsignadasFail
                                | CargarDashboardLineasAsignadasSuccess
                                | CargarDashboardListaTiposCompaniasLineas
                                | CargarDashboardListaTiposCompaniasLineasFail
                                | CargarDashboardListaTiposCompaniasLineasSuccess
                                | CargarDashboardListaTiposCompaniasLineasFiliales
                                | CargarDashboardListaTiposCompaniasLineasFilialesSuccess
                                | CargarDashboardTiposDispositivos
                                | CargarDashboardTiposDispositivosFail
                                | CargarDashboardTiposDispositivosSuccess
                                | CargarDashboardTiposCompaniasDispositivos
                                | CargarDashboardTiposCompaniasDispositivosFail
                                | CargarDashboardTiposCompaniasDispositivosSuccess
                                | CargarDashboardDispositivosMarcas
                                | CargarDashboardDispositivosMarcasFail
                                | CargarDashboardDispositivosMarcasSuccess
                                | CargarDashboardListaMarcasRadios
                                | CargarDashboardListaMarcasRadiosFail
                                | CargarDashboardListaMarcasRadiosSuccess
                                | CargarDashboardListaClientesGps
                                | CargarDashboardListaClientesGpsFail
                                | CargarDashboardListaClientesGpsSuccess
                                | CargarDashboardResumenTiposGps
                                | CargarDashboardResumenTiposGpsFail
                                | CargarDashboardResumenTiposGpsSuccess
                                | CargarDashboardCompaniasLineasGps
                                | CargarDashboardCompaniasLineasGpsFail
                                | CargarDashboardCompaniasLineasGpsSuccess
                                | CargarDashboardLineContactos
                                | CargarDashboardLineContactosFail
                                | CargarDashboardLineContactosSuccess
                                | CargarDashboardLineEmpresas
                                | CargarDashboardLineEmpresasFail
                                | CargarDashboardLineEmpresasSuccess
                                | CargarDashboardLineOportunidades
                                | CargarDashboardLineOportunidadesFail
                                | CargarDashboardLineOportunidadesSuccess
                                | CargarDashboardActividadesPendientesDia
                                | CargarDashboardActividadesPendientesDiaFail
                                | CargarDashboardActividadesPendientesDiaSuccess
                                ;
