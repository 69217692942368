<div class="h-100">
	<div class="row h-100 no-gutters">
		<div class="d-none d-lg-block col-lg-4">
			<div class="slider-light">
        <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-navy">
          <div class="slider-content text-light p-5 text-center">
            <h3 class="m-4">BIENVENIDO</h3>
            <p>
            </p>
          </div>
        </div>
			</div>
		</div>
		<div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8 loading-container">
			<div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <div class="image text-center">
          <img src="assets/images/logo.png" alt="Logotipo">
        </div>
        <h4 class="mb-0 text-center">
          <span>Por favor, acceda a su cuenta a continuación.</span>
        </h4>
        <div class="mb-2"><img src="assets/images/barra.png" alt="barra"></div>
        <div>
          <form (ngSubmit)="onSubmit()" #f="ngForm">
            <fieldset role="group" aria-describedby="exampleInputGroup1__BV_description_" class="form-group">
              <input type="text" id="usuarioLogin" name="usuarioLogin" #usuarioLogin="ngModel" [(ngModel)]="usuario.usuario" autocomplete="email" placeholder="Ingresa usuario..." required="required" aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_" required>
            </fieldset>
            <fieldset role="group" class="form-group">
              <div>
                <input type="password" id="passwordLogin" name="passwordLogin" #passwordLogin="ngModel" [(ngModel)]="usuario.password" autocomplete="password" placeholder="Ingresa contraseña..." required="required" aria-required="true" class="form-control" required>
              </div>
            </fieldset>
            <fieldset class="mb-1">
              <div class="text-center justify-content-center">
                <div class="recaptcha-box">
                  <div #recaptcha style="display: inline-block;"></div>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-group">
              <div tabindex="-1" role="group">
                <div class="input-group">
                  <button type="submit" class="btn-wide btn-shadow btn-hover-shine btn-pill btn btn-primary btn-block btn-lg bg-navy" [disabled]="!f.valid || cargando">
                    Ingresar
                  </button>
                </div>
              </div>
            </fieldset>
            <div class="custom-control custom-checkbox">
              <input id="exampleCheck" type="checkbox" name="check" #check="ngModel" autocomplete="off" class="custom-control-input" value="true" [(ngModel)]="rememberme">
              <label for="exampleCheck" class="custom-control-label">Recordarme</label>
            </div>
            <div class="divider"></div>
            <div class="d-flex justify-content-end align-items-center">
              <div class="ml-auto">
                <a [routerLink]="'/public/restore'" class="btn-lg btn btn-link">Recuperar contraseña</a>
              </div>
            </div>
            <div class="text-center opacity-8 mt-1 mb-2">
              Copyright © Jivabe Rating and Consulting
            </div>
          </form>
        </div>
      </div>
      <ngx-loading [show]="cargando" [template]="loadingTemplate"></ngx-loading>
		</div>
	</div>
</div>
