import { Action } from '@ngrx/store';
import { Oportunidad } from 'src/app/models/oportunidades';

export const CARGAR_OPORTUNIDADES = '[Oportunidad] Cargar oportunidades';
export const CARGAR_OPORTUNIDADES_FAIL = '[Oportunidad] Cargar oportunidades FAIL';
export const CARGAR_OPORTUNIDADES_SUCCESS = '[Oportunidad] Cargar oportunidades SUCCESS';
export const CARGAR_OPORTUNIDAD = '[Oportunidad] Cargar oportunidad';
export const CARGAR_OPORTUNIDAD_FAIL = '[Oportunidad] Cargar oportunidad FAIL';
export const CARGAR_OPORTUNIDAD_SUCCESS = '[Oportunidad] Cargar oportunidad SUCCESS';
export const ACTUALIZA_OPORTUNIDAD = '[Oportunidad] Actualiza oportunidad';
export const ACTUALIZA_OPORTUNIDAD_FAIL = '[Oportunidad] Actualiza oportunidad FAIL';
export const ACTUALIZA_OPORTUNIDAD_SUCCESS = '[Oportunidad] Actualiza oportunidad SUCCESS';
export const ACTUALIZA_OPORTUNIDAD_ESTATUS = '[Oportunidad] Actualiza oportunidad estatus';
export const ACTUALIZA_OPORTUNIDAD_ESTATUS_FAIL = '[Oportunidad] Actualiza oportunidad estatus FAIL';
export const ACTUALIZA_OPORTUNIDAD_ESTATUS_SUCCESS = '[Oportunidad] Actualiza oportunidad estatus SUCCESS';
export const LIMPIAR_OPORTUNIDAD = '[Oportunidad] Limpiar oportunidad';

export class CargarOportunidades implements Action {
  readonly type = CARGAR_OPORTUNIDADES;
}

export class CargarOportunidadesFail implements Action {
  readonly type = CARGAR_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarOportunidadesSuccess implements Action {
  readonly type = CARGAR_OPORTUNIDADES_SUCCESS;

  constructor( public oportunidades: Oportunidad[] ) {}
}

export class CargarOportunidad implements Action {
  readonly type = CARGAR_OPORTUNIDAD;

  constructor(public id: number) {}
}

export class CargarOportunidadFail implements Action {
  readonly type = CARGAR_OPORTUNIDAD_FAIL;

  constructor( public payload: any ) {}
}

export class CargarOportunidadSuccess implements Action {
  readonly type = CARGAR_OPORTUNIDAD_SUCCESS;

  constructor( public oportunidad: Oportunidad ) {}
}

export class ActualizaOportunidad implements Action {
  readonly type = ACTUALIZA_OPORTUNIDAD;

  constructor( public oportunidad: Oportunidad ) { }
}

export class ActualizaOportunidadFail implements Action {
  readonly type = ACTUALIZA_OPORTUNIDAD_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaOportunidadSuccess implements Action {
  readonly type = ACTUALIZA_OPORTUNIDAD_SUCCESS;

  constructor( public oportunidad: Oportunidad ) {}
}

export class ActualizaOportunidadEstatus implements Action {
  readonly type = ACTUALIZA_OPORTUNIDAD_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaOportunidadEstatusFail implements Action {
  readonly type = ACTUALIZA_OPORTUNIDAD_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaOportunidadEstatusSuccess implements Action {
  readonly type = ACTUALIZA_OPORTUNIDAD_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export class LimpiarOportunidad implements Action {
  readonly type = LIMPIAR_OPORTUNIDAD;

  constructor() {}
}

export type oportunidadAcciones = CargarOportunidades 
                                | CargarOportunidadesFail 
                                | CargarOportunidadesSuccess 
                                | CargarOportunidad 
                                | CargarOportunidadFail 
                                | CargarOportunidadSuccess 
                                | ActualizaOportunidad 
                                | ActualizaOportunidadFail 
                                | ActualizaOportunidadSuccess 
                                | ActualizaOportunidadEstatus 
                                | ActualizaOportunidadEstatusFail 
                                | ActualizaOportunidadEstatusSuccess
                                | LimpiarOportunidad;

