import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-found-record',
  templateUrl: './not-found-record.component.html',
  styleUrls: ['./not-found-record.component.sass']
})
export class NotFoundRecordComponent implements OnInit, OnDestroy {
  public identity: Identity = new Identity(null);
  // @Input() public verLineas = false;
  // @Input() public verDispositivos = false;
  // @Input() public verVehiculos = false;
  // @Input() public verGps = false;
  private subscribeAuth: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private locate: Location
  ) { }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
  }

  back() {
    this.locate.back();
  }

}
