import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/models/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  private url: string;
  private notification: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getNotification() {
    const notification = JSON.parse(localStorage.getItem('notification'));
    return this.notification;
  }

  getNotificaciones(pag = 0, limit = 0, usuario = 0): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/notificaciones/pag/' + pag + '/limit/' + limit + '/usuarios/' + usuario, {headers});
  }

  getNotificacionesFull(pag = 0, limit = 0, usuario = 0, tiposNotificaciones = '', tiposUsuarios = '', search = ''): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = JSON.stringify({tiposNotificaciones, tiposUsuarios, search });
    return this.http.post(this.url + '/notificaciones/pag/' + pag + '/limit/' + limit + '/usuarios/' + usuario, params, {headers});
  }

  setLeidoAll(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/notificaciones/leidos', {headers});
  }

  setLeido(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/notificaciones/leidos/' + id, {headers});
  }

  getPendientes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/notificaciones/pendientes', {headers});
  }

  getPendientesTotal(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/notificaciones/pendientes/total', {headers});
  }

  getInicializaPendientes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/notificaciones/pendientes/inicializa', {headers});
  }

}
