import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';

import { Subscription, Subject } from 'rxjs';
import { Identity, User } from 'src/app/models/user';
import * as CryptoJS from 'crypto-js';
import { GLOBAL } from 'src/app/models/global';
import { HttpRequest, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';

// tslint:disable
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public token: string;
	public identity = new Subject<Identity>();
	// cachedRequests: Array<HttpRequest<any>> = [];
	private url: string;
	private userSubscription: Subscription = new Subscription();
	private usuario: User;
	cachedRequests: Array<HttpRequest<any>> = [];
	private logger = new Subject<boolean>();
	private loggedIn: boolean;

	constructor(
		 private router: Router,
		 private http: HttpClient,
		 protected local: StorageMap
	) {
		this.url = GLOBAL.url;
		this.loggedIn = false;
	}

	getIdentity(): Observable<Identity> {
		return this.local.get('identity').pipe( map( identity => {
			let ident: Identity = null;
			if ( !(identity === undefined || identity === 'undefined') && identity !== '' ) {
				const decrypted: Identity = JSON.parse(this.getEncript(GLOBAL.keyEncr, identity));
				if (decrypted !== undefined && decrypted !== null) {
					ident = decrypted;
				} else {
					ident = new Identity(null);
				}
			} else {
				ident = new Identity(null);
			}
			return ident;
		}));
	}

	printIdentity(): Identity {
		let identity = null;
		identity = localStorage.getItem('identity');
		let ident: Identity = null;
		if ( !(identity === undefined || identity === 'undefined') && identity !== '' && !(identity === null || identity === 'null') ) {
			const decrypted: Identity = JSON.parse(this.getEncript(GLOBAL.keyEncr, identity));
			if (decrypted !== undefined && decrypted !== null) {
				ident = decrypted;
			} else {
				ident = new Identity(null);
			}
		} else {
			ident = new Identity(null);
		}
		return ident;
	}

	setIdentity(identity: Identity) {
		const item = JSON.stringify(identity);
		this.local.set('identity', this.setEncript(GLOBAL.keyEncr, item)).subscribe();
		localStorage.setItem('identity', this.setEncript(GLOBAL.keyEncr, item));
  }

  setUsuarioSession(user: string, pwd: string) {
		const item = JSON.stringify({user, pwd});
		localStorage.setItem('sesionUser', this.setEncript(GLOBAL.keyEncr, item));
  }

  prinUsuarioSession(): any {
		let session = null;
		session = localStorage.getItem('sesionUser');
    let respond: any = null;
		if ( !(session === undefined || session === 'undefined') && session !== '' && !(session === null || session === 'null') ) {
			const decrypted: any = JSON.parse(this.getEncript(GLOBAL.keyEncr, session));
			if (decrypted !== undefined && decrypted !== null) {
				respond = decrypted;
			} else {
				respond = null;
			}
		} else {
			respond = null;
		}
		return respond;
	}

	setToken(token: string) {
		localStorage.setItem('token', token);
		this.local.set('token', token).subscribe();
	}

	setEncript(keys: any, value: any) {
		const key = CryptoJS.enc.Utf8.parse(keys);
		const iv = CryptoJS.enc.Utf8.parse(GLOBAL.keyIV);
		const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
			keySize: 256 / 8,
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});

		return encrypted.toString();
	}

	getToken(): Observable<any> {
		return this.local.get('token').pipe( map( token => {
			if (token !== 'undefined' || !token) {
				token = token;
			} else {
				token = null;
			}
			return token;
		}));
	}

	// The get method is use for decrypt the value.
	getEncript(keys: any, value: any): string {
		try {
			const key = CryptoJS.enc.Utf8.parse(keys);
			const iv = CryptoJS.enc.Utf8.parse(GLOBAL.keyIV);
			const decrypted = CryptoJS.AES.decrypt(value, key, {
				keySize: 256 / 8,
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			});

			return decrypted.toString(CryptoJS.enc.Utf8);
		} catch (err) {
			return null;
		}
	}

	login(usuario: string, password: string, captcha: string, rememberme: boolean): Observable<any> {
		const params = JSON.stringify({usuario, password, captcha, rememberme});
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this.http.post(this.url + '/accesos', params, { headers } );
	}

	logIn(provider: string, providerResponse: string) {
		this.loggedIn = true;
		this.logger.next(this.loggedIn);
	}

	logOut() {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.delete(this.url + '/accesos', {headers} );
	}

	statusLogger() {
		return this.logger.asObservable();
	}

	isAuth() {
		let regresa = false;
		return this.getIdentity()
				.pipe(
				map( (identity: Identity) => {
					if (identity !== undefined && identity !== null && identity.id > 0) {
						regresa = true;
					} else {
						regresa = false;
					}
					return regresa;
				}));
	}

	isNotAuth() {
		let regresa = false;
		return this.getIdentity()
				.pipe(
				map( (identity: Identity) => {
					if (identity !== undefined && identity !== null && identity.id > 0) {
						regresa = false;
					} else {
						regresa = true;
					}
					return regresa;
				}));
	}

	isLoggedIn(): Observable<boolean> {
		return this.logger.asObservable();
	}

	getUsuario() {
		return { ...this.usuario };
	}

	collectFailedRequest (request): void {
		this.cachedRequests.push(request);
	}

	getProfile(): Observable<any> {
		const headers = new HttpHeaders().set('Content-type', 'application/json');
		return this.http.get(this.url + '/usuarios/profile', {headers});
	}

}
