import { Pipe, PipeTransform } from '@angular/core';
import { Actividad } from '../models/actividad';

@Pipe({
  name: 'filtroActividadesConcepto'
})
export class FiltroActividadesConceptoPipe implements PipeTransform {

  transform(value: Actividad[], concepto: number): any {
    if(!value){
      return [];
    }
    if(!concepto){
      return value;
    }
    const listaFiltrada = value.filter( (item: Actividad) => {
      if ( !concepto || (concepto && item.concepto === concepto)){
        return true;
      } else {
        return false;
      }
    });
    return listaFiltrada;
  }

}



