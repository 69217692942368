
<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row mb-3">
  <div class="col-md-6">
    <div class="card main-card mb-3" >
      <div class="card-header">
        Actividades
      </div>
      <div class="card-body">
        <apx-chart
          [series]="series"
          [colors]="[ '#66DA26', '#2E93fA', '#000000', '#98FF89']"
          [chart]="{
            type: 'bar',
            height: 350,
            stacked: true
          }"
          [plotOptions]="{
            bar: {
              horizontal: true
            }
          }"
          [xaxis]="{
            categories: categories
          }"
          [stroke]="{
            width: 1,
            colors: ['#fff']
          }"
          [fill]="{
            opacity: 1
          }"
          [yaxis]="{
            title: {
              text: undefined
            }
          }"
          [legend]="{
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }"
        ></apx-chart>
        <!-- colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']; -->
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card  main-card mb-3" >
      <div class="card-header">
        <div class="card-header-title">Actividades pendientes</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(actividades | anyScope: paginacionActividad.textSearch ).length}} de {{(actividades).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionActividad.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionActividad.textSearch" (ngModelChange)="changeTextSearchActividad()">
                <button class="search-icon" (click)="paginacionActividad.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacionActividad.isActive = false; paginacionActividad.textSearch = ''; listSearchActividad = [];"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActiveActividad" (click)="filterActiveActividad = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchActividad" (click)="removerTextSearchActividad(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <div class="divider" *ngIf="listSearchActividad.length"></div>
        <div class="row">
          <div class="col-md-6" *ngIf="filterActiveActividad">
            <div class="card no-shadow card-border border-light">
              <div class="card-header">
                <div class="card-header-title">Filtrar contenido</div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActiveActividad = false">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="p-3">
                  <div class="form-group">
                    <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacionActividad.pageSize">
                      <option *ngFor="let pagt of paginacionActividad.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                    </select>
                  </div>
                  <!-- <div class="form-group">
                    <label for="" class="control-label">Ordenar por</label>
                    <select class="form-control custom-select" name="sortTypeProducto" id="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType">
                      <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
                    </select>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-area-lg col-md-6" [ngClass]="{'col-md-12': !filterActiveActividad }" *ngIf="actividades.length">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let actividad of actividades | anyScope: paginacionActividad.textSearch | orderBy: [paginacionActividad.sortType] | slice: (paginacionActividad.page-1) * paginacionActividad.pageSize : (paginacionActividad.page-1) * paginacionActividad.pageSize + paginacionActividad.pageSize">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg opacity-10 bg-light "></div>
                            <i *ngIf="actividad.concepto === 2" class="fas fa-phone-alt"></i>
                            <i *ngIf="actividad.concepto === 3" class="far fa-calendar-alt"></i>
                            <i *ngIf="actividad.concepto === 4" class="far fa-envelope"></i>
                            <i *ngIf="actividad.concepto === 5" class="far fa-calendar-alt"></i>
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-chart-subheading text-muted">
                            <span *ngIf="actividad.concepto === 2">Llamada</span>
                            <span *ngIf="actividad.concepto === 3">Reunión</span>
                            <span *ngIf="actividad.concepto === 4">Correo electrónico</span>
                            <span *ngIf="actividad.concepto === 5">Reunión</span>
                          </div>
                          <div class="widget-heading font-weight-bold">{{actividad.descripcion}}</div>
                        </div>
                        <div class="widget-content-right">
                          <i>{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</i>
                        </div>
                      </div>
                      <div class="widget-content-wrapper" style="margin-top: -4px;">
                        <div class="widget-content-left mr-2" style="margin-left: 70px;" *ngIf="actividad.empresa">
                          <div class="widget-description text-muted font-weight-light">
                            Empresa
                          </div>
                        </div>
                        <div class="widget-content-left mr-3" *ngIf="actividad.empresa">
                          <div class="avatar-wrapper avatar-wrapper-overlap">
                            <a [routerLink]="['/auth/empresas/profile', actividad.empresa]" class="avatar-icon-wrapper">
                              <div class="avatar-icon">
                                <img class="rounded-circle" [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/0?v=' + version"  [title]="actividad.desEmpresa" [alt]="actividad.desEmpresa">
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
                          <div class="widget-description text-muted font-weight-light">
                            Contactos
                          </div>
                        </div>
                        <div class="widget-content-left mr-3" *ngIf="actividad.contactos.length">
                          <div class="avatar-wrapper avatar-wrapper-overlap">
                            <a class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos" [routerLink]="['/auth/contactos/profile', contacto.id]">
                              <div class="avatar-icon">
                                <img  [src]="url + '/empresas/perfiles/' + (actividad.empresa ? actividad.empresa : 0) + '/imagenes/'+ contacto.id + '?v=' + version + ';empresa=' + contacto.empresa" [title]="contacto.nombre" [alt]="contacto.nombre">
                              </div>
                            </a>
                          </div>
                        </div>
                        <div class="widget-content-left mr-2" *ngIf="actividad.oportunidades.length">
                          <div class="widget-description text-muted font-weight-light">
                            Oportunidades
                          </div>
                        </div>
                        <div class="widget-content-left mr-3" *ngIf="actividad.oportunidades.length">
                          <div class="avatar-wrapper avatar-wrapper-overlap">
                            <a class="avatar-icon-wrapper" *ngFor="let oportunidad of actividad.oportunidades" [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">
                              <div class="avatar-icon">
                                <img [src]="url + '/empresas/perfiles/' + actividad.empresa + '/imagenes/0?v=' + version" [title]="oportunidad.nombre" [alt]="oportunidad.nombre">
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </perfect-scrollbar>
            </section>
          </div>
          <div class="col-md-6" [ngClass]="{'col-md-12': !filterActiveActividad }" *ngIf="!actividades.length">
            <p class="ml-2 mt-3 h3 text-primary text-center">NO HAY ACTIVIDADES PENDIENTES</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(actividades | anyScope: paginacionActividad.textSearch).length" [(page)]="paginacionActividad.page" [pageSize]="paginacionActividad.pageSize" [maxSize]="5">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-3">
  <div class="col-md-6">
    <div class="card" >
      <div class="card-header">
        <div class="card-header-title">Empresas nuevas</div>
        <div class="btn-actions-pane-right mr-4">
          ({{paginacionEmpresa.totalFiltro}} de {{paginacionEmpresa.totalSize}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionEmpresa.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionEmpresa.textSearch" (ngModelChange)="changeTextSearchEmpresa()">
                <button class="search-icon" (click)="paginacionEmpresa.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacionEmpresa.isActive = false; paginacionEmpresa.textSearch = ''; listSearchEmpresa = [];"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActiveEmpresa" (click)="filterActiveEmpresa = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchEmpresa" (click)="removerTextSearchEmpresa(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <div class="divider" *ngIf="listSearchEmpresa.length"></div>
        <div class="row">
          <div class="col-md-6" *ngIf="filterActiveEmpresa">
            <div class="card no-shadow card-border border-light">
              <div class="card-header">
                <div class="card-header-title">Filtrar contenido</div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActiveEmpresa = false">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="p-3">
                  <div class="form-group">
                    <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacionEmpresa.pageSize" (change)="cargarEmpresas()">
                      <option *ngFor="let pagt of paginacionEmpresa.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="control-label">Ordenar por</label>
                    <select class="form-control custom-select" name="sortTypeEmpresa" id="sortTypeEmpresa" (change)="cargarEmpresas()" #sortTypeEmpresa="ngModel" [(ngModel)]="paginacionEmpresa.sortType">
                      <option *ngFor="let opt of orderTypeEmpresas" [value]="opt.id">{{opt.text}}</option>
                    </select>
                  </div>
                  <div class="v-options-menu vsm-default">
                    <div class="vsm-list">
                      <div class="vsm-header">Opciones</div>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Tipo</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterTipos">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesTipoFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTipoFilterEmpresa{{item.id}}" #selectCheckOpcionesTipoFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeTipos()">
                                              <label class="custom-control-label" for="selectCheckOpcionesTipoFilterEmpresa{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Clases</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterClases">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesClasesFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesClasesFilterEmpresa{{item.id}}" #selectCheckOpcionesClasesFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeClases()">
                                              <label class="custom-control-label" for="selectCheckOpcionesClasesFilterEmpresa{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Clasificaciones</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterClasificaciones">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesClasificacionFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesClasificacionFilterEmpresa{{item.id}}" #selectCheckOpcionesClasificacionFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeClasificaciones()">
                                              <label class="custom-control-label" for="selectCheckOpcionesClasificacionFilterEmpresa{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Países</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterPaises">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesPaisFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesPaisFilterEmpresa{{item.id}}" #selectCheckOpcionesPaisFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changePaises()">
                                              <label class="custom-control-label" for="selectCheckOpcionesPaisFilterEmpresa{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Industrias</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterIndustrias">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}" class="custom-control-input" name="selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}" #selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeIndustrias()">
                                              <label class="custom-control-label" for="selectCheckOpcionesIndustriaFilterEmpresa{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-area-lg col-md-6" [ngClass]="{'col-md-12': !filterActiveEmpresa }" *ngIf="empresas.length">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">



                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let empresa of empresas | orderBy: [paginacionEmpresa.sortType]">
                    <div class="no-gutters row">
                      <div class="col-sm-6 col-md-6 col-xl-6">
                        <div class="card no-shadow bg-transparent text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">#{{empresa.id}}</div>
                              <div class="widget-content-left mr-3">
                                <img width="42" class="rounded-circle" [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/0?v=' + version" alt="Imagen de la empresa">
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading"> <a [routerLink]="['/auth/empresas/profile', empresa.id]" class="text-dark"> {{empresa.nombre}} </a></div>
                                <div class="widget-subheading">Tipo: {{empresa.desTipo}}</div>
                                <div class="widget-description text-muted">
                                  <span>Industria: {{empresa.desIndustria}}<br></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-xl-6">
                        <div class="card no-shadow bg-transparent text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left">
                                <div class="widget-heading text-secondary">{{printDireccion(empresa)}}</div>
                                <div class="widget-subheading">Clase: {{empresa.desClase}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </perfect-scrollbar>
            </section>
          </div>
          <div class="col-md-6" [ngClass]="{'col-md-12': !filterActiveEmpresa }" *ngIf="!empresas.length">
            <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination class="mb-2"
              (pageChange)="cargarEmpresas(false)"
              [collectionSize]="paginacionEmpresa.totalFiltro" [(page)]="paginacionEmpresa.page" [pageSize]="paginacionEmpresa.pageSize" [maxSize]="3">
              <ng-template ngbPaginationFirst>First</ng-template>
              <ng-template ngbPaginationLast>Last</ng-template>
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" >
      <div class="card-header">
        <div class="card-header-title">Contactos nuevos</div>
        <div class="btn-actions-pane-right mr-4">
          ({{paginacionContacto.totalFiltro}} de {{paginacionContacto.totalSize}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionContacto.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionContacto.textSearch" (ngModelChange)="changeTextSearchContacto()">
                <button class="search-icon" (click)="paginacionContacto.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacionContacto.isActive = false; paginacionContacto.textSearch = ''; listSearchContacto = [];"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActiveContacto" (click)="filterActiveContacto = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchContacto" (click)="removerTextSearchContacto(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <div class="divider" *ngIf="listSearchContacto.length"></div>
        <div class="row">
          <div class="col-md-6" *ngIf="filterActiveContacto">
            <div class="card no-shadow card-border border-light">
              <div class="card-header">
                <div class="card-header-title">Filtrar contenido</div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActiveContacto = false">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="p-3">
                  <div class="form-group">
                    <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacionContacto.pageSize">
                      <option *ngFor="let pagt of paginacionContacto.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="control-label">Ordenar por</label>
                    <select class="form-control custom-select" name="sortTypeContacto" id="sortTypeContacto" #sortTypeContacto="ngModel" [(ngModel)]="paginacionContacto.sortType">
                      <option *ngFor="let opt of orderTypeContacto" [value]="opt.id">{{opt.text}}</option>
                    </select>
                  </div>
                  <div class="v-options-menu vsm-default">
                    <div class="vsm-list">
                      <div class="vsm-header">Opciones</div>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Origen del contacto</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterOrigenContacto">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}" #selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeTiposContacto()">
                                              <label class="custom-control-label" for="selectCheckOpcionesTipoOrigenFilterContacto{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Estatus</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterEstatus">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesEstatusFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesEstatusFilterContacto{{item.id}}" #selectCheckOpcionesEstatusFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeEstatus()">
                                              <label class="custom-control-label" for="selectCheckOpcionesEstatusFilterContacto{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Etapas</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterEtapas">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesEtapasFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesEtapasFilterContacto{{item.id}}" #selectCheckOpcionesEtapasFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeEtapas()">
                                              <label class="custom-control-label" for="selectCheckOpcionesEtapasFilterContacto{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                      <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                        <ngb-panel class="p-0">
                          <ng-template ngbPanelHeader>
                            <button ngbPanelToggle class="vsm-link">
                              <span class="vsm-title">Países</span>
                              <i class="vsm-arrow"></i>
                            </button>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="vsm-dropdown">
                              <div class="vsm-list">
                                <div class="vsm-item" *ngFor="let item of filterPaisesContacto">
                                  <a class="vsm-link">
                                    <span class="vsm-title">
                                      <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                          <div class="widget-content-left">
                                            <div class="custom-checkbox custom-control">
                                              <input type="checkbox" id="selectCheckOpcionesPaisFilterContacto{{item.id}}" class="custom-control-input" name="selectCheckOpcionesPaisFilterContacto{{item.id}}" #selectCheckOpcionesPaisFilterContacto{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changePaisesContacto()">
                                              <label class="custom-control-label" for="selectCheckOpcionesPaisFilterContacto{{item.id}}">&nbsp;</label>
                                            </div>
                                          </div>
                                          <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{item.nombre}}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-area-lg col-md-6" [ngClass]="{'col-md-12': !filterActiveContacto }" *ngIf="contactos.length">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let contacto of contactos">
                    <div class="no-gutters row">
                      <div class="col-sm-6 col-md-6 col-xl-6">
                        <div class="card no-shadow bg-transparent text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="todo-indicator bg-primary"></div>
                              <div class="widget-content-left mr-2 pointer"><a [routerLink]="['/auth/contactos/profile', contacto.id]" class="text-dark pointer">#{{contacto.id}}</a></div>
                              <div class="widget-content-left mr-3">
                                <img width="42" class="rounded-circle" [src]="url + '/empresas/perfiles/' + contacto.empresa + '/imagenes/'+ contacto.id + '?v=' + version" alt="Imagen de la empresa">
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading pointer"><a [routerLink]="['/auth/contactos/profile', contacto.id]" class="text-dark pointer"> {{contacto.nombre}} {{contacto.apellido}} </a></div>
                                <div class="widget-subheading pointer">Empresa: <i *ngIf="contacto.empresaPublicidad" class="fas fa-volume-up"></i> <a [routerLink]="['/auth/empresas/profile', contacto.empresa]" class="text-dark pointer"> {{contacto.desEmpresa}} </a></div>
                                <div class="widget-description text-muted">
                                  <span>Cargo: {{contacto.cargo}}<br></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-xl-6">
                        <div class="card no-shadow bg-transparent text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left">
                                <div class="widget-heading text-secondary"> Email: {{contacto.emails}}</div>
                                <div class="widget-subheading">Origen: {{contacto.desTipoOrigen}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </perfect-scrollbar>
            </section>
          </div>
          <div class="col-md-6" [ngClass]="{'col-md-12': !filterActiveContacto }" *ngIf="!contactos.length">
            <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination class="mb-2"
                  [collectionSize]="paginacionContacto.totalFiltro" [(page)]="paginacionContacto.page" [pageSize]="paginacionContacto.pageSize" [maxSize]="5" (pageChange)="cargarContactos(false)">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-3">
  <div class="col-md-6">
    <div class="card main-card mb-3" >
      <div class="card-header">
        Etapas Oportunidades
      </div>
      <div class="card-body">
        <ejs-accumulationchart [legendSettings]="legend" [tooltip]="tooltipSettings"  *ngIf="dataFunnel.length">
          <e-accumulation-series-collection>
            <e-accumulation-series type="Funnel" [dataSource]="dataFunnel" xName="name" yName="value" innerTadius="10%" [dataLabel]="chartLabel">
        
            </e-accumulation-series>
          </e-accumulation-series-collection>
        </ejs-accumulationchart>
        <div *ngIf="!dataFunnel.length">
          <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" >
      <div class="card-header">
        <div class="card-header-title">Oportunidades abiertas</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(oportunidadesAbiertas | anyScope: paginacionOportunidad.textSearch ).length}} de {{(oportunidadesAbiertas).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionOportunidad.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionOportunidad.textSearch" (ngModelChange)="changeTextSearchOportunidad()">
                <button class="search-icon" (click)="paginacionOportunidad.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacionOportunidad.isActive = false; paginacionOportunidad.textSearch = ''; listSearchOportunidad = [];"></button>
            </div>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActiveOportunidad" (click)="filterActiveOportunidad = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchOportunidad" (click)="removerTextSearchOportunidad(texto)">
          {{texto}}
          <i class="ml-1 fas fa-times"></i>
        </button>
        <div class="divider" *ngIf="listSearchOportunidad.length"></div>
        <div class="row">
          <div class="col-md-6" *ngIf="filterActiveOportunidad">
            <div class="card no-shadow card-border border-light">
              <div class="card-header">
                <div class="card-header-title">Filtrar contenido</div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActiveOportunidad = false">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="p-3">
                  <div class="form-group">
                    <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacionOportunidad.pageSize">
                      <option *ngFor="let pagt of paginacionOportunidad.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="" class="control-label">Ordenar por</label>
                    <select class="form-control custom-select" name="sortTypeOportunidad" id="sortTypeOportunidad" #sortTypeOportunidad="ngModel" [(ngModel)]="paginacionOportunidad.sortType">
                      <option *ngFor="let opt of orderTypeOportunidades" [value]="opt.id">{{opt.text}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="table-responsive col-md-6" [ngClass]="{'col-md-12': !filterActiveOportunidad }" *ngIf="oportunidadesAbiertas.length">
              <table class="align-middle mb-0 table table-borderless table-striped table-hover ">
                <thead>
                  <tr>
                    <th class="v-center" (click)="sortOportunidad('nombre')">Nombres
                      <span *ngIf="paginacionOportunidad.sortType === '-nombre'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacionOportunidad.sortType === '+nombre'" class="fa fa-caret-up"></span>
                    </th>
                    <th class="text-right" (click)="sortOportunidad('cantidad')">Importe
                      <span *ngIf="paginacionOportunidad.sortType === '-cantidad'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacionOportunidad.sortType === '+cantidad'" class="fa fa-caret-up"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let oportunidad of oportunidadesAbiertas | anyScope: paginacionOportunidad.textSearch | orderBy: [paginacionOportunidad.sortType] | slice: (paginacionOportunidad.page-1) * paginacionOportunidad.pageSize : (paginacionOportunidad.page-1) * paginacionOportunidad.pageSize + paginacionOportunidad.pageSize">
                    <td class="v-center">{{oportunidad.nombre}}</td>
                    <td class="text-right"> {{oportunidad.cantidad | currency:'MXN':'symbol' }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="v-center"><b>{{oportunidadesAbiertasTotal.nombre}}</b></td>
                    <td class="text-right"><b>{{oportunidadesAbiertasTotal.cantidad | currency:'MXN':'symbol' }}</b></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="col-md-6" [ngClass]="{'col-md-12': !filterActiveOportunidad }" *ngIf="!oportunidadesAbiertas.length">
              <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
            </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="row p-2">
          <div class="col-sm-6">
            <div class="text-left">
              <ngb-pagination
                [collectionSize]="(oportunidadesAbiertas | anyScope: paginacionOportunidad.textSearch).length" [(page)]="paginacionOportunidad.page" [pageSize]="paginacionOportunidad.pageSize" [maxSize]="5">
                <ng-template ngbPaginationFirst>First</ng-template>
                <ng-template ngbPaginationLast>Last</ng-template>
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
