
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AnyScopePipe } from './any-scope.pipe';
import { ByObjectArrayPipe } from './by-object-array.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterListPipe } from './filter-list.pipe';
import { FilterPipe } from './filter.pipe';
import { GroupByDatePipe } from './group-by-date.pipe';
import { GroupByPipe } from './group-by.pipe';
import { PhonePipe } from './phone.pipe';
import { SortPipe } from './sort.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { OrderByPipe } from './order-by.pipe';
import { MultiOpcionesSelectedPipe } from './multi-opciones-selected.pipe';
import { FilterParamInObjectPipe } from './filter-param-in-object.pipe';
import { PlazoVencidoPipe } from './plazo-vencido.pipe';
import { FilterFilialPlanesPipe } from './filter-filial-planes.pipe';
import { FilterParamInObjectEqualPipe } from './filter-param-in-object-equal.pipe';
import { ComplementariosPipe } from './complementarios.pipe';
import { TimeDurationPipe } from './time-duration.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EstatusSuscripcionPipe } from './estatus-suscripcion.pipe';
import { FilterSelectedCampingSendPipe } from './filter-selected-camping-send.pipe';
import { FiltroActividadesConceptoPipe } from './filtro-actividades-concepto.pipe';
import { OptionCheckMultiplePipe } from './option-check-multiple.pipe';
import { MimeFontawesomePipe } from './mime-fontawesome.pipe';
import { MimeImagesPipe } from './mime-images.pipe';
import { DirectorioActualPipe } from './directorio-actual.pipe';
import { multiOpcionesSelectedCountPipe } from './multi-opciones-selected-count.pipe';
import { AnyScopeCountPipe } from './any-scope-count.pipe';
import { DateWithoutTimeZonePipe } from './date-without-time-zone.pipe';

@NgModule({
  declarations: [
    // PIPES
    AnyScopePipe,
    ByObjectArrayPipe,
    FileSizePipe,
    FilterListPipe,
    FilterPipe,
    GroupByDatePipe,
    GroupByPipe,
    PhonePipe,
    SortPipe,
    TimeFormatPipe,
    OrderByPipe,
    MultiOpcionesSelectedPipe,
    FilterParamInObjectPipe,
    PlazoVencidoPipe,
    FilterFilialPlanesPipe,
    FilterParamInObjectEqualPipe,
    ComplementariosPipe,
    TimeDurationPipe,
    SafeHtmlPipe,
    EstatusSuscripcionPipe,
    FilterSelectedCampingSendPipe,
    FiltroActividadesConceptoPipe,
    OptionCheckMultiplePipe,
    MimeFontawesomePipe,
    MimeImagesPipe,
    DirectorioActualPipe,
    multiOpcionesSelectedCountPipe,
    AnyScopeCountPipe,
    DateWithoutTimeZonePipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    AnyScopePipe,
    ByObjectArrayPipe,
    FileSizePipe,
    FilterListPipe,
    FilterPipe,
    GroupByDatePipe,
    GroupByPipe,
    PhonePipe,
    SortPipe,
    TimeFormatPipe,
    OrderByPipe,
    MultiOpcionesSelectedPipe,
    FilterParamInObjectPipe,
    PlazoVencidoPipe,
    FilterParamInObjectEqualPipe,
    ComplementariosPipe,
    TimeDurationPipe,
    SafeHtmlPipe,
    EstatusSuscripcionPipe,
    FilterSelectedCampingSendPipe,
    FiltroActividadesConceptoPipe,
    OptionCheckMultiplePipe,
    MimeFontawesomePipe,
    MimeImagesPipe,
    DirectorioActualPipe,
    multiOpcionesSelectedCountPipe,
    AnyScopeCountPipe,
    DateWithoutTimeZonePipe
  ]
})
export class PipesModule { }
