<!-- <span class="close cursor" (click)="closeModal()">&times;</span> -->
<div class="">
  <!-- <div class="full-slide-image-lightbox" *ngFor="let image of images; let i = index">
    <div class="numbertext">{{i + 1}} / {{images.length}}</div>
    <img [src]="image.big">
  </div>
  <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
  <a class="next" (click)="plusSlides(1)">&#10095;</a>
  <div class="caption-container">
    <p id="caption"></p>
  </div> -->
  <span class="close cursor text-white" data-dismiss="modal" (click)="closeModal()">&times;</span>
  <div class="row">
    <div class="col-12">
      <ngx-slick-carousel class="slick-slider slick-dotted" #slickModalFull="slick-carousel" [config]="slideConfigFull" (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of images; let i = index">
          <div class="full-slide-image-lightbox">
            <div class="numbertext">{{i + 1}} / {{images.length}}</div>
            <img [src]="slide.path" [alt]="'Imagen'" *ngIf="slide.type !== 'mp4'">
            <div class="video" *ngIf="slide.type === 'mp4'">
              <video controls (click)="toggleVideo(i)" id="videoPlayer-{{i}}"  controls>
                <source [src]="slide.path" [type]="'video/' + slide.type" />
                  Browser not supported
              </video>
            </div> 
          </div>
          <div class="caption-container">
            <p *ngIf="!slide.editar">
              Album: {{slide.desAlbum}} - ({{slide.fecRegistro | date: 'dd/MM/yyyy'}}) {{slide.etiquetas}}
              <a (click)="selectEtiqueta(slide)" class="ml-2" class="btn btn-link cursor text-info" title="Clic sí deaseas editar la etiqueta de esta imagen.">
                <i class="fa fa-edit"></i>
              </a>
            </p>
            <div class="input-group" *ngIf="slide.editar">
              <input type="text" class="form-control" #changeTextImage{{i}}="ngModel" [(ngModel)]="slide.etiquetasNuevo">
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-success" (click)="saveChange(slide)">
                  <i class="fa fa-save"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-outline-default" (click)="cancelarEtiquetas(slide)">
                  <i class="fa fa-close"></i> Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-3" *ngFor="let image of images; let i = index">
      <img class="slide-image-lightbox cursor" [src]="image.small" (click)="currentSlide(i + 1)" alt="Image {{i + 1}}">
    </div> -->
    <div class="col-12">
      <ngx-slick-carousel class="slick-slider slick-dotted p-2" #slickModalDots="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of images; let i = index">
          <div class="slider-item" [ngClass]="{'active': slideIndex === i}" (click)="selectImage(i)">
            <img [src]="slide.thumbnail" [alt]="'Imagen' + i" class="thumbnail-gallery">
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
