import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo';
import { Industria } from 'src/app/models/industrias';
import { Contacto, CorreoContacto } from 'src/app/models/contacto';
import { Empresa } from 'src/app/models/empresa';
import { Origen } from 'src/app/models/origen-contacto';
import { Estado, Municipio, Colonia } from 'src/app/models/direccion';
import { Usuario } from 'src/app/models/user';
import { ContactosService } from 'src/app/services/contactos.service';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';
import { DireccionesService } from 'src/app/services/direcciones.service';



@Component({
  selector: 'app-edit-contacto',
  templateUrl: './edit-contacto.component.html',
  styleUrls: ['./edit-contacto.component.sass']
})
export class EditContactoComponent implements OnInit, OnDestroy {
  heading = 'Detalles del contacto';
  subheading = 'Contacto registrando en el sistema';
  icon = 'pe-7s-id icon-gradient bg-dark';
  public identity: Identity = new Identity();
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subcriptionContacto: Subscription = new Subscription();
  public contacto: Contacto  = new Contacto(null);
  public contactoInicial: Contacto  = new Contacto(null);
  public tipos: Catalogo[] = [];
  public origenContacto: Origen[] = [];
  public origenContactoFiltrado: Origen[] = [];
  public industrias: Industria[] = [];
  public industriasFiltradas: Industria[] = [];
  public etapa: Catalogo[] = [];
  public estado: Catalogo[] = [];
  public municipios: Municipio[] = [];
  public municipiosDatoFiscal: Municipio[] = [];
  public municipiosContacto: Municipio[] = [];
  public colonias: Colonia[] = [];
  public coloniasDatoFiscal: Colonia[] = [];
  public coloniasContacto: Colonia[] = [];
  public estados: Estado[] = [];
  public estadosDatoFiscal: Estado[] = [];
  public estadosContacto: Estado[] = [];
  public empresas: Empresa[] = [];
  public empresa: Empresa  = new Empresa(null);
  public loading = false;
  public usuarios: Usuario[] = [];
  public editar = false;
  public editarDatoFiscal = false;
  public editarContacto = false;
  public editarEstaMuni = false;
  public editarEstaMuniDatoFiscal = false;
  public editarEstaMuniContacto = false;
  public editarMunicipio = false;
  public editarMunicipioDatoFiscal = false;
  public editarMunicipioContacto = false;
  public capturaEmpresa = false;
  public ultimaActividad: Date;
  public telefonos = 1;
  public clasificaciones: Catalogo[] = [];
  public paises: Catalogo[] = [];
  public imageChangedEvent: any;
  public showCropper: Boolean = false;
  public email = '';
  public clases: Catalogo[] = [];
  public cpAnterior = '';
  public cpAnteriorDatoFiscal = '';
  public cpAnteriorEmpresa = '';
  public clasificacionAnterior = 99999;
  public cargandoEmpresas = false;
  private pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();
  @ViewChild(ImageCropperComponent, {static: false}) imageCropper: ImageCropperComponent;
  constructor(
    private contactosService: ContactosService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private direccionService: DireccionesService,
  ) {
    this.store.dispatch(new usuariosActions.LimpiarContacto());
    this.store.dispatch(new usuariosActions.ConsultaIndustrias());
    // this.store.dispatch(new usuariosActions.ConsultaTipoEmpresas());
    // this.store.dispatch(new usuariosActions.ConsultaEmpresas());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.CargarEstados());
    this.store.dispatch(new usuariosActions.ConsultaEstadosContactos());
    this.store.dispatch(new usuariosActions.ConsultaEtapasContactos());
    this.store.dispatch(new usuariosActions.ConsultaOrigenContactos());
    this.store.dispatch(new usuariosActions.ConsultaPaises());
    this.store.dispatch(new usuariosActions.ConsultaClasificaciones());
    this.store.dispatch(new usuariosActions.ConsultaClasesEmpresas());
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.cargandoEmpresas = state.cargandoEmpresas;
        this.tipos = [...state.tipos.filter(item => item.status)];
        this.industrias = [...state.industrias.filter(item => item.status)];
        this.empresas = [...state.empresas];
        this.usuarios = [...state.usuarios];
        this.etapa = [...state.etapasContacto.filter(item => item.status)];
        this.estado = [...state.estadosContacto.filter(item => item.status)];
        this.origenContacto = [...state.origenContacto.filter( item => item.status)];
        this.clasificaciones = [...state.clasificaciones.filter(item => item.status)];
        this.paises = [...state.paises.filter(item => item.status)];
        this.clases = [...state.clasesEmpresa.filter(item => item.status)];
        if(this.empresa.clasificacion && this.empresa.clasificacion !== this.clasificacionAnterior && this.industrias.length){
          this.industriasFiltradas = [...this.industrias.filter(item => item.clasificacion === this.empresa.clasificacion)];
          this.clasificacionAnterior = this.empresa.clasificacion;
        } 
      }
    );
    this.subcriptionContacto = this.store.select('contacto').subscribe(
      (state) => {
        this.contacto = {...state.contacto};
        this.contactoInicial = {...state.contacto};
        this.ultimaActividad = state.actividad;

        this.contacto.cumpleanios = this.contacto.cumpleanios ? this.pipeDateWithoutTimeZone.transform(this.contacto.cumpleanios) : null;
        this.contactoInicial.cumpleanios = this.contactoInicial.cumpleanios ? this.pipeDateWithoutTimeZone.transform(this.contactoInicial.cumpleanios) : null;
        // this.cpAnterior = this.contacto.codigoPostal;
        this.consultaCodigoPostalContacto();
        if (this.contacto.id) {
          this.cargarEmpresas();
        } else {
          this.contacto.propietario = this.identity.id;
          this.contacto.estado = 1;
          this.contacto.etapa = 1;
        }
      }
    );
    console.log('Inicializa component contacto-edit...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionContacto.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarContacto(id));
          this.store.dispatch(new usuariosActions.CargarUltimaActividadContacto(id));
        } else {
          this.contacto = new Contacto(null);
          this.contacto.propietario = this.identity.id;
          this.contacto.estado = 1;
          this.contacto.etapa = 1;
          this.contactoInicial = {...this.contacto};
        }
    });
  }

  async onSubmit() {
        Swal.fire({
          title: '¿Deseas registrar el contacto?',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
          cancelButtonText: MESSAGES.M_BTN_CANCEL,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
          showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm:  ()  => {
            return new Promise( (resolve)  => {
              this.contactosService.registraContacto(this.contacto, this.empresa).subscribe(
                (respuesta) => {
                  if (respuesta.contacto) {
                    this.store.dispatch(new usuariosActions.ActualizaContactoSuccess(respuesta.contacto));
                    resolve(respuesta.contacto);
                  } else {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                }, (error) => {
                  this.store.dispatch(new usuariosActions.ActualizaContactoFail(error));
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          },
          allowOutsideClick: false
        }).then(
          (result: any) => {
            if (result.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_CONFIRM,
                text: 'Contacto registrado.',
                icon: 'success'
              });
              this.router.navigate(['/auth/contactos/profile', result.value.id]);
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          }, () => {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () => {}
            }).then( () => {},  () => {});
          }
        );

  }

  async consultaCodigoPostal() {
    if( this.empresa.codigoPostal && this.empresa.pais === 1 && this.empresa.codigoPostal !== this.cpAnteriorEmpresa ) {
      this.cpAnteriorEmpresa = this.empresa.codigoPostal;
      await this.direccionService.obtenerDireccionPostal(this.empresa.codigoPostal).subscribe(
        (respuesta) => {
          if (respuesta.direccion && respuesta.direccion.estado && respuesta.direccion.estado.id) {
            this.empresa.estado = respuesta.direccion.estado.id;
            this.empresa.municipio = respuesta.direccion.municipio.id;
            this.editar = false;
            this.editarMunicipio = false;
            this.consultaEstados();
            this.consultaMunicipio( false );
            this.consultaColonias( false );

          } else {
            this.toastr.warning('No se encontro información sobre el codígo postal');
            this.consultaEstados();
            this.editar = true;
            this.editarMunicipio = false;
            this.empresa.estado = null;
            this.empresa.municipio = null;
            this.empresa.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  
  async consultaEstados( ) {
    if(this.empresa.pais === 1){
      await this.direccionService.obtenerEstados().subscribe(
        (respuesta) => {
          this.estados = [...respuesta];
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaMunicipio( status: boolean = true) {
    if(this.empresa.estado && this.empresa.pais === 1){
      await this.direccionService.obtenerMunicipios(this.empresa.estado).subscribe(
        (respuesta) => {
          this.editarMunicipio = status;
          this.municipios = [...respuesta.municipios];
          if( status ) {
            this.empresa.municipio = null;
            this.empresa.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaColonias( status: boolean = true ) {
    if(this.empresa.estado && this.empresa.municipio && this.empresa.pais === 1){
      await this.direccionService.obtenerColonias(this.empresa.estado, this.empresa.municipio).subscribe(
        (respuesta) => {
          this.colonias = [...respuesta.colonias];
          if( status ) {
            this.empresa.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaCodigoPostalDatoFiscal() {
    if( this.empresa.codigoPostalDatoFiscal && this.empresa.paisDatoFiscal === 1 && this.empresa.codigoPostalDatoFiscal !== this.cpAnteriorDatoFiscal ) {
      this.cpAnteriorDatoFiscal = this.empresa.codigoPostalDatoFiscal;
      await this.direccionService.obtenerDireccionPostal(this.empresa.codigoPostalDatoFiscal).subscribe(
        (respuesta) => {
          if (respuesta.direccion && respuesta.direccion.estado && respuesta.direccion.estado.id) {
            this.empresa.estadoDatoFiscal = respuesta.direccion.estado.id;
            this.empresa.municipioDatoFiscal = respuesta.direccion.municipio.id;
            this.editarDatoFiscal = false;
            this.editarMunicipioDatoFiscal = false;
            this.consultaEstadosDatoFiscal();
            this.consultaMunicipioDatoFiscal( false );
            this.consultaColoniasDatoFiscal( false );

          } else {
            this.toastr.warning('No se encontro información sobre el codígo postal');
            this.consultaEstadosDatoFiscal();
            this.editarDatoFiscal = true;
            this.editarMunicipioDatoFiscal = false;
            this.empresa.estadoDatoFiscal = null;
            this.empresa.municipioDatoFiscal = null;
            this.empresa.coloniaDatoFiscal = null;

          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaEstadosDatoFiscal( ) {
    if(this.empresa.paisDatoFiscal === 1){
      await this.direccionService.obtenerEstados().subscribe(
        (respuesta) => {
          this.estadosDatoFiscal = [...respuesta];
  
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaMunicipioDatoFiscal( status: boolean = true ) {
    if(this.empresa.estadoDatoFiscal && this.empresa.paisDatoFiscal === 1){
      await this.direccionService.obtenerMunicipios(this.empresa.estadoDatoFiscal).subscribe(
        (respuesta) => {
          this.editarMunicipioDatoFiscal = status;
          this.municipiosDatoFiscal = [...respuesta.municipios];
          if( status ) {
            this.empresa.municipioDatoFiscal = null;
            this.empresa.coloniaDatoFiscal = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }


  async consultaColoniasDatoFiscal( status: boolean = true ) {
    if(this.empresa.estadoDatoFiscal && this.empresa.municipioDatoFiscal && this.empresa.paisDatoFiscal === 1){
      await this.direccionService.obtenerColonias(this.empresa.estadoDatoFiscal, this.empresa.municipioDatoFiscal).subscribe(
        (respuesta) => {
          this.coloniasDatoFiscal = [...respuesta.colonias];
          if( status ) {
            this.empresa.coloniaDatoFiscal = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  existeEmpresa() {
    if (this.cargandoEmpresas) {
      setTimeout( () => {
        this.existeEmpresa();
      }, 100);
    } else {
      if (this.contacto.desEmpresa) {
        this.capturaEmpresa = false;
        this.contacto.empresa = 0;
        this.empresas.findIndex(item => {
          if(item.nombre === this.contacto.desEmpresa){
            this.contacto.empresa = item.id;
          }
        });
        if(this.contacto.empresa === 0){
          this.mensajeEmpresa();
        }
      } else {
        this.contacto.empresa = 0;
        this.capturaEmpresa = false;
      }
    }
  }

  mensajeEmpresa(){
    Swal.fire({
      title: 'Empresa no registrada',
      icon: 'info',
      text: '¿Deseas registrar la empresa?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_INFO,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
      cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        return new Promise((resolve) => {
          this.capturaEmpresa = true;
          this.empresa.nombre = this.contacto.desEmpresa;
          this.empresa.propietario = this.identity.id;
          resolve(true);
        });
      }
    }).then( (resul) => {
      if (resul.value) {
        Swal.fire({
          title: MESSAGES.M_TIT_SUCCESS,
          timer: 1200,
          showConfirmButton: false,
          icon: 'success',
          onOpen: () => {}
        }).then(() => {}, () => {});
      } else {
        this.contacto.desEmpresa = null;
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      }
    });
  }

  validaDatos() {
    return new Promise((resolve, reject) => {
      this.cargarDireccionSeleccionada();
      resolve(true);
    });
  }

  filtrarOrigen(){
    this.contacto.especificacion = false;
    this.contacto.tipoOrigen = 0;
    if(this.contacto.desTipoOrigen){
      this.origenContacto.find(item =>{ if(item.nombre === this.contacto.desTipoOrigen ) { this.contacto.especificacion = item.descripcion; this.contacto.tipoOrigen = item.id} });
    }
  }

  cantidadTelefono(evento: number){
    this.telefonos = evento === 1 ? (this.telefonos + 1) : (this.telefonos - 1);
  }

  fileChangeEvent(event: any): void {
    // this.files = event.target.files as Array<File>;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.contacto.imagen = event.base64;
    // console.log(event);
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady() {
    // console.log('Cropper ready');
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.imageCropper.canvasRotation = 1;
  }

  rotateRight() {
    this.imageCropper.canvasRotation = 3;
  }

  flipHorizontal() {
    this.imageCropper.transform.flipH = !this.imageCropper.transform.flipH;
  }

  flipVertical() {
    this.imageCropper.transform.flipV = !this.imageCropper.transform.flipV;
  }

  async consultaCodigoPostalContacto() {
    if( this.contacto.codigoPostal && this.contacto.pais === 1 && this.contacto.codigoPostal !== this.cpAnterior ) {
      this.cpAnterior = this.contacto.codigoPostal;
      await this.direccionService.obtenerDireccionPostal(this.contacto.codigoPostal).subscribe(
        (respuesta) => {
          if (respuesta.direccion && respuesta.direccion.estado && respuesta.direccion.estado.id) {
            this.contacto.estadoDireccion = respuesta.direccion.estado.id;
            this.contacto.municipio = respuesta.direccion.municipio.id;
            this.editarContacto = false;
            this.editarMunicipioContacto = false;
            this.consultaEstadosContacto();
            this.consultaMunicipioContacto( false );
            this.consultaColoniasContacto( false );

          } else {
            this.toastr.warning('No se encontro información sobre el codígo postal');
            this.consultaEstadosContacto();
            this.editarContacto = true;
            this.editarMunicipioContacto = false;
            this.contacto.estadoDireccion = null;
            this.contacto.municipio = null;
            this.contacto.colonia = null;

          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaEstadosContacto( ) {
    if(this.contacto.pais === 1){
      await this.direccionService.obtenerEstados().subscribe(
        (respuesta) => {
          this.estadosContacto = [...respuesta];
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaMunicipioContacto( status: boolean = true ) {
    if(this.contacto.estadoDireccion && this.contacto.pais === 1){
      await this.direccionService.obtenerMunicipios(this.contacto.estadoDireccion).subscribe(
        (respuesta) => {
          this.editarMunicipioContacto = status;
          this.municipiosContacto = [...respuesta.municipios];
          if( status ) {
            this.contacto.municipio = null;
            this.contacto.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }


  async consultaColoniasContacto( status: boolean = true ) {
    if(this.contacto.estadoDireccion && this.contacto.municipio && this.contacto.pais === 1){
      await this.direccionService.obtenerColonias(this.contacto.estadoDireccion, this.contacto.municipio).subscribe(
        (respuesta) => {
          this.coloniasContacto = [...respuesta.colonias];
          if( status ) {
            this.contacto.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }




  agregarEmail() {
    if(this.email.trim() && this.email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)){
      const existe = this.contacto.email.find( item => item.email === this.email );
      if (!existe) {
        this.contactosService.existeContacto(this.contacto.id, this.email).subscribe(
          (respuesta) => {
            if (!respuesta.contacto) {
              // this.contacto.email.unshift(new CorreoContacto({email: this.email}));
              this.contacto.email = [(new CorreoContacto({email: this.email})), ...this.contacto.email];
              this.email = null;
            } else {
              this.email = null;
              this.toastr.warning('EL correo ya se encuentra registrado en otro contacto', 'Correo duplicado');
            }
        });
      } else {
        this.email = '';
      }
    }
  }

  eliminarEmail(email: CorreoContacto) {
    this.contacto.email = this.contacto.email.filter( item => item.email !== email.email);
  }

  limpiarFecha() {
    this.contacto.cumpleanios = null;
  }

  existeIndustria() {
    if(this.empresa.clasificacion && this.empresa.clasificacion !== this.clasificacionAnterior){
      // this.empresa.desIndustria = null;
      this.industriasFiltradas = [...this.industrias.filter(item => item.clasificacion === this.empresa.clasificacion)];
      this.clasificacionAnterior = this.empresa.clasificacion;
    }    
  }

  limpiarFechaFundacion() {
    this.empresa.fundacion = null;
  }

  cargarEmpresas() {
    if (this.contacto.desEmpresa.trim().length >= 3 ) {
      this.store.dispatch(new usuariosActions.ConsultaEmpresas(this.contacto.desEmpresa, 100));
    } else {
      this.empresas = [];
    }
  }

  eliminarContacto() {
    Swal.fire({
      title: '¿Deseas eliminar el contacto?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.contactosService.eliminarContacto(this.contacto).subscribe(
            (response) => {
              if (response.respond) {
                this.store.dispatch(new usuariosActions.EliminarContactoSuccess(this.contacto.id));
                resolve(true);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.EliminarContactoFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Contacto eliminado correctamente.',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false
          });
          this.router.navigate(['/auth/contactos']);
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  cargarDireccionSeleccionada() {
    if (this.contacto.pais === 1) {
      const itemEstado = this.estadosContacto.find(item => item.id === this.contacto.estadoDireccion);
      if (itemEstado.nombre) {
        this.contacto.desEstado = itemEstado.nombre;
      } else {
        this.contacto.desEstado = '';
      }
      const itemMunicipio = this.municipiosContacto.find(item => item.id === this.contacto.municipio);
      if (itemMunicipio.nombre) {
        this.contacto.desMunicipio = itemMunicipio.nombre;
      } else {
        this.contacto.desMunicipio = '';
      }
    }
  }
  

}
