import { Action } from '@ngrx/store';
import { Identity } from 'src/app/models/user';

export const SET_AUTH = '[Auth] Set User';
export const UNSET_AUTH = '[Auth] Unset User';
export const GET_AUTH = '[Auth] Get User';
export const LOAD_AUTH_SUCCESS = '[Auth] Success User';
export const LOAD_AUTH_FAIL = '[Auth] Fail User';
export const CHANGE_PASSWORD_AUTH = '[Auth] Change Password User';
export const CHANGE_PASSWORD_AUTH_SUCCESS = '[Auth] Change Password Success User';
export const CHANGE_PASSWORD_AUTH_FAIL = '[Auth] Change Password Fail User';
export const SET_PROFILE_AUTH = '[Auth] Set Profile User';
export const SET_PROFILE_AUTH_FAIL = '[Auth] Set Profile Fail User';
export const SET_PROFILE_AUTH_SUCCESS = '[Auth] Set Profile Success User';

export class SetAuthAction implements Action {
    readonly type = SET_AUTH;

    constructor( public usuario: string, public password: string, public captcha: string, public rememberme: boolean ) {}
}

export class UnsetAuthAction implements Action {
    readonly type = UNSET_AUTH;
}

export class GetAuthAction implements Action {
    readonly type = GET_AUTH;
}

export class LoadAuthFail implements Action {
    readonly type = LOAD_AUTH_FAIL;

    constructor( public payload: any ) {}
}

export class LoadAuthSuccess implements Action {
    readonly type = LOAD_AUTH_SUCCESS;

    constructor(public identity: Identity) {}
}

export class ChangePasswordAuth implements Action {
    readonly type = CHANGE_PASSWORD_AUTH;

    constructor( public actual: string, public password: string) {}
}

export class ChangePasswordAuthSuccess implements Action {
    readonly type = CHANGE_PASSWORD_AUTH_SUCCESS;

    constructor(public payload: any) {}
}

export class ChangePasswordAuthFail implements Action {
    readonly type = CHANGE_PASSWORD_AUTH_FAIL;

    constructor( public payload: any ) {}
}

export class SetProfileAuthAction implements Action {
    readonly type = SET_PROFILE_AUTH;

    constructor( usuario: any ) {}
}

export class SetProfileAuthFail implements Action {
    readonly type = SET_PROFILE_AUTH_FAIL;

    constructor( public payload: any ) {}
}

export class SetProfileAuthSuccess implements Action {
    readonly type = SET_PROFILE_AUTH_SUCCESS;

    constructor(public nombre: string, public apellidos: string, public email: string) {}
}

export type authAcciones =  SetAuthAction |
                            UnsetAuthAction |
                            GetAuthAction |
                            LoadAuthFail |
                            LoadAuthSuccess |
                            ChangePasswordAuth |
                            ChangePasswordAuthSuccess |
                            ChangePasswordAuthFail |
                            SetProfileAuthAction |
                            SetProfileAuthFail |
                            SetProfileAuthSuccess;
