import { Action } from '@ngrx/store';
import { Contacto } from 'src/app/models/contacto';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Oportunidad } from 'src/app/models/oportunidades';
import { VentaPropietario } from 'src/app/models/reporte';
import { ReporteAutoActualizacion } from '../../models/auto-actualizacion';

export const CARGAR_REPORTE_SEPOMEX = '[Reportes] Cargar reporte sepomex';
export const CARGAR_REPORTE_SEPOMEX_SUCCESS = '[Reportes] Cargar reporte sepomex SUCCESS';
export const CARGAR_REPORTE_SEPOMEX_FAIL = '[Reportes] Cargar reporte sepomex FAIL';
export const LIMPIAR_REPORTE_SEPOMEX = '[Reportes] Limpiar reporte sepomex contacto';
export const CARGAR_REPORTE_CUMPLEANNOS = '[Reportes] Cargar reporte cumpleannos';
export const CARGAR_REPORTE_CUMPLEANNOS_SUCCESS = '[Reportes] Cargar reporte cumpleannos success';
export const CARGAR_REPORTE_CUMPLEANNOS_FAIL = '[Reportes] Cargar reporte cumpleannos fail';
export const LIMPIAR_REPORTE_CUMPLEANNOS = '[Reportes] Limpiar reporte cumpleannos';
export const CARGAR_REPORTE_VENTAS_ETAPAS = '[Reportes] Cargar reporte ventas etapas';
export const CARGAR_REPORTE_VENTAS_ETAPAS_SUCCESS = '[Reportes] Cargar reporte ventas etapas success';
export const CARGAR_REPORTE_VENTAS_ETAPAS_FAIL = '[Reportes] Cargar reporte ventas etapas fail';
export const LIMPIAR_REPORTE_VENTAS_ETAPAS = '[Reportes] Limpiar reporte ventas etapas';
export const CARGAR_REPORTE_VENTAS_PROPIETARIOS = '[Reportes] Cargar reporte ventas propietarios';
export const CARGAR_REPORTE_VENTAS_PROPIETARIOS_SUCCESS = '[Reportes] Cargar reporte ventas propietarios success';
export const CARGAR_REPORTE_VENTAS_PROPIETARIOS_FAIL = '[Reportes] Cargar reporte ventas propietarios fail';
export const LIMPIAR_REPORTE_VENTAS_PROPIETARIOS = '[Reportes] Limpiar reporte ventas propietarios';
export const CARGAR_REPORTE_AUTO_ACTUALICACION = '[Reportes] Cargar reporte auto-actualizacion';
export const CARGAR_REPORTE_AUTO_ACTUALICACION_SUCCESS = '[Reportes] Cargar reporte auto-actualizacion SUCCESS';
export const CARGAR_REPORTE_AUTO_ACTUALICACION_FAIL = '[Reportes] Cargar reporte auto-actualizacion FAIL';
export const LIMPIAR_REPORTE_AUTO_ACTUALIZACION = '[Reportes] Limpiar reporte auto-actualizacion';

export class CargarReporteSepomex implements Action {
  readonly type = CARGAR_REPORTE_SEPOMEX;

  constructor(public opciones: any) {}
}

export class CargarReporteSepomexFail implements Action {
  readonly type = CARGAR_REPORTE_SEPOMEX_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteSepomexSuccess implements Action {
  readonly type = CARGAR_REPORTE_SEPOMEX_SUCCESS;

  constructor(public contactos: Contacto[], public totalFiltro: number, public total: number) {}
}

export class LimpiarReporteSepomex implements Action {
  readonly type = LIMPIAR_REPORTE_SEPOMEX;

  constructor() {}
}

export class CargarReporteCumpleannos implements Action {
  readonly type = CARGAR_REPORTE_CUMPLEANNOS;

  constructor( public inicia: NgbDate, public termina: NgbDate, public opciones: any ) { }
}

export class CargarReporteCumpleannosFail implements Action {
  readonly type = CARGAR_REPORTE_CUMPLEANNOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteCumpleannosSuccess implements Action {
  readonly type = CARGAR_REPORTE_CUMPLEANNOS_SUCCESS;

  constructor( public contactos: Contacto[], public totalFiltro: number, public total: number ) {}
}

export class LimpiarReporteCumpleannos implements Action {
  readonly type = LIMPIAR_REPORTE_CUMPLEANNOS;

  constructor() {}
}

export class CargarReporteVentasEtapas implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_ETAPAS;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteVentasEtapasFail implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_ETAPAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteVentasEtapasSuccess implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_ETAPAS_SUCCESS;

  constructor( public oportunidades: Oportunidad[] ) {}
}

export class LimpiarReporteVentasEtapas implements Action {
  readonly type = LIMPIAR_REPORTE_VENTAS_ETAPAS;

  constructor() {}
}

export class CargarReporteVentasPropietarios implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_PROPIETARIOS;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteVentasPropietariosFail implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_PROPIETARIOS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteVentasPropietariosSuccess implements Action {
  readonly type = CARGAR_REPORTE_VENTAS_PROPIETARIOS_SUCCESS;

  constructor( public reporte: VentaPropietario[] ) {}
}

export class LimpiarReporteVentasPropietarios implements Action {
  readonly type = LIMPIAR_REPORTE_VENTAS_PROPIETARIOS;

  constructor() {}
}

export class CargarReporteAutoActualicacion implements Action {
  readonly type = CARGAR_REPORTE_AUTO_ACTUALICACION;

  constructor(public opciones: any) {}
}

export class CargarReporteAutoActualicacionFail implements Action {
  readonly type = CARGAR_REPORTE_AUTO_ACTUALICACION_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteAutoActualicacionSuccess implements Action {
  readonly type = CARGAR_REPORTE_AUTO_ACTUALICACION_SUCCESS;

  constructor(public contactos: ReporteAutoActualizacion[], public totalFiltro: number, public total: number) {}
}

export class LimpiarReporteAutoActualizacion implements Action {
  readonly type = LIMPIAR_REPORTE_AUTO_ACTUALIZACION;

  constructor() {}
}

export type reportes =  CargarReporteSepomex 
                        | CargarReporteSepomexFail 
                        | CargarReporteSepomexSuccess 
                        | LimpiarReporteSepomex
                        | CargarReporteCumpleannos 
                        | CargarReporteCumpleannosFail 
                        | CargarReporteCumpleannosSuccess
                        | LimpiarReporteCumpleannos
                        | CargarReporteVentasEtapas 
                        | CargarReporteVentasEtapasFail 
                        | CargarReporteVentasEtapasSuccess
                        | LimpiarReporteVentasEtapas
                        | CargarReporteVentasPropietarios 
                        | CargarReporteVentasPropietariosFail 
                        | CargarReporteVentasPropietariosSuccess
                        | LimpiarReporteVentasPropietarios
                        | CargarReporteAutoActualicacion 
                        | CargarReporteAutoActualicacionFail 
                        | CargarReporteAutoActualicacionSuccess 
                        | LimpiarReporteAutoActualizacion;
