import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from 'src/app/models/global';
import { ArchivoGeneral } from '../models/archivo-general';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GaleriaRepositorio } from '../models/galeria-repositorio';
import { Album } from '../models/album';

@Injectable({
  providedIn: 'root'
})
export class GaleriaRepositorioService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  cargarGaleria(etiquetas): Observable<any> {
    const obj = {etiquetas};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/galeria-repositorio/cargar', params, {headers});
  }

  cargarAlbumes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/galeria-repositorio/albumes', {headers});
  }

  cargarAlbumImagenes(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/galeria-repositorio/albumes/' + id, {headers});
  }

  cargarAlbum(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/galeria-repositorio/album/' + id, {headers});
  }

  eliminarAlbum(album: Album): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.delete(this.url + '/galeria-repositorio/albumes/' + album.id, {headers});
  }

  cargarImagen(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/galeria-repositorio/' + id, {headers});
  }

  agregarAlbum(album: Album): Observable<any> {
    const obj = {album};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/galeria-repositorio/albumes', params, {headers});
  }

  eliminarImagen(archivo: GaleriaRepositorio): Observable<any> {
    const obj = {archivo};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.delete(this.url + '/galeria-repositorio/imagenes/' + archivo.imagen, {headers});
  }

  addFileTransfer(files: any[]): Observable<any> {
    const formData: any = new FormData();
    formData.append('files', files);

    return this.http.post(this.url + '/galeria-repositorio/files', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  addFileTransfersGallery(files: File[], obj?: any): Observable<any> {
    const response = { progress: 0, total: 0, data: {}};
    return new Observable(observer => {
      const formData: FormData = new FormData();
      const xhr = new XMLHttpRequest();
      for (const file of files) {
          formData.append('files', file, file.name);
      }
      if (obj) {
			  const data = JSON.stringify(obj);
			  formData.append('datos', data);
		 }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // resolve(JSON.parse(xhr.response));
            // response.progress = 100;
            response.data= xhr.response;
            observer.next(response);
            observer.complete();
          } else {
            observer.error(xhr.response);
            // reject(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (e) => {
        response.progress = e.loaded;
        response.total = e.total;
        observer.next(response);
      }

      xhr.open('POST', this.url + '/galeria-repositorio/files', true);
      xhr.setRequestHeader('Authorization', `${localStorage.getItem('token')}`);
      xhr.send(formData);
      xhr.onload = () => {
        // response.progress = 100;
        observer.next(response);
        observer.complete();
      };
    });
  }

  handleEvent(e) {
    // log.textContent = log.textContent + `${e.type}: ${e.loaded} bytes transferred\n`;
    const texto = `${e.type}: ${e.loaded} bytes transferred\n`;
    return `${e.type}: ${e.loaded} bytes transferred\n`;
  }

  addFileTransferImage(file: GaleriaRepositorio, album: Album): Observable<any> {
    const response = { progress: 0, total: 0, data: {}, error: false};
    return new Observable(observer => {
      const formData: FormData = new FormData();
      const xhr = new XMLHttpRequest();
      formData.append('file', file.file);
      const data = JSON.stringify(file);
      formData.append('datos', data);
      const dataAlbum = JSON.stringify(album);
      formData.append('album', dataAlbum);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // resolve(JSON.parse(xhr.response));
            // response.progress = 100;
            response.data= xhr.response;
            observer.next(response);
            observer.complete();
          } else {
            response.error = true;
            observer.error(xhr.response);
            // reject(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (e) => {
        response.progress = e.loaded;
        response.total = e.total;
        observer.next(response);
      }

      xhr.open('POST', this.url + '/galeria-repositorio/file', true);
      xhr.setRequestHeader('Authorization', `${localStorage.getItem('token')}`);
      xhr.send(formData);
      xhr.onload = () => {
        // response.progress = 100;
        observer.next(response);
        observer.complete();
      };
    });
  }

  updateAlbumLastImage(album: Album): Observable<any> {
    const obj = {album};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/galeria-repositorio/albumes/last', params, {headers});
  }

  actualizarEtiquetas(archivo: GaleriaRepositorio): Observable<any> {
    const obj = {archivo};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.put(this.url + '/galeria-repositorio/images', params, {headers});
  }

}
