import * as fromUsuario from '../actions';
import { Actividad } from 'src/app/models/actividad';

export interface ActividadesEmpresaState {
  actividades: Actividad[];
  actividad: Actividad;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ActividadesEmpresaState = {
  actividades: [],
  actividad: new Actividad(null),
  loaded: false,
  loading: false,
  error: null
};

// tslint:disable-next-line:max-line-length
export function actividadesEmpresaReducer( state = estadoInicial, action: fromUsuario.actividadesEmpresaAcciones ): ActividadesEmpresaState {

  switch ( action.type ) {
    case fromUsuario.CARGAR_ACTIVIDADES_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ACTIVIDADES_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        actividades: [...action.actividades]
      };
    case fromUsuario.CARGAR_ACTIVIDADES_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_ACTIVIDADES_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_ACTIVIDADES_EMPRESA_SUCCESS:
      // action.actividad.fecha.month = action.actividad.fecha.month - 1;
      const itm = state.actividades.find( item => item.concepto === action.actividad.concepto && item.id === action.actividad.id);
      if (itm && itm.id) {
        state.actividades = state.actividades.map( (item) => {
          if (item.concepto === action.actividad.concepto && item.id === action.actividad.id) {
            item = {...action.actividad};
          }
          return item;
        });
      } else {
        state.actividades.push(action.actividad);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_ACTIVIDADES_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS_SUCCESS:
      state.actividades = state.actividades.map( item => {
        if (item.concepto === action.actividad.concepto && item.id === action.actividad.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_ACTIVIDADES:
      return {
        ...state,
				actividades: []
      };
    case fromUsuario.CARGAR_ACTIVIDADES_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ACTIVIDADES_CONTACTO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        actividades: [...action.actividades]
      };
    case fromUsuario.CARGAR_ACTIVIDADES_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_ACTIVIDADES_OPORTUNIDAD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ACTIVIDADES_OPORTUNIDAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        actividades: [...action.actividades]
      };
    case fromUsuario.CARGAR_ACTIVIDADES_OPORTUNIDAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_ACTIVIDADES_PENDIENTES_TOTAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ACTIVIDADES_PENDIENTES_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        actividades: [...action.actividades]
      };
    case fromUsuario.CARGAR_ACTIVIDADES_PENDIENTES_TOTAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

