import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DireccionesService } from 'src/app/services/direcciones.service';
import { ToastrService } from 'ngx-toastr';
import { AppState } from '../app.reducer';
import { Store } from '@ngrx/store';


@Injectable()
export class DireccionEffects {

  constructor(
    private actions$: Actions,
    public direccionService: DireccionesService,
    protected local: StorageMap,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {}

  @Effect()
  estadosDirecciones$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_ESTADOS),
    switchMap( (action: any) => {
      return this.direccionService.obtenerEstados()
        .pipe(
          map( (request: any) => {
            return new usuarioActions.CargarEstadosSuccess(request);
          }),
          catchError( error => of(new usuarioActions.CargarEstadosFail(error)))
        );
    })
  );

  @Effect()
  municipiosDirecciones$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_MUNICIPIOS),
    switchMap( (action: any) => {
      return this.direccionService.obtenerMunicipios(action.estado)
        .pipe(
          map( (request: any) => {
            return new usuarioActions.CargarMunicipiosSuccess(request.municipios, action.estado);
          }),
          catchError( error => of(new usuarioActions.CargarMunicipiosFail(error)))
        );
    })
  );

  @Effect()
  coloniasDirecciones$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_COLONIAS),
    switchMap( (action: any) => {
      return this.direccionService.obtenerColonias(action.estado, action.municipio)
        .pipe(
          map( (request: any) => {
            return new usuarioActions.CargarColoniasSuccess(request.colonias, action.estado, action.municipio);
          }),
          catchError( error => of(new usuarioActions.CargarColoniasFail(error)))
        );
    })
  );

  @Effect()
  codePostalDirecciones$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_CODE_POSTAL),
    switchMap( (action: any) => {
      return this.direccionService.obtenerDireccionPostal(action.codePostal)
        .pipe(
          map( (request: any) => {
            if (request.direccion && request.direccion.estado && request.direccion.estado.id) {
              this.store.dispatch(new usuarioActions.CargarEstados());
              this.store.dispatch(new usuarioActions.CargarMunicipios(request.direccion.estado.id));
              this.store.dispatch(new usuarioActions.CargarColoniasSuccess(
                request.direccion.colonias, request.direccion.estado.id, request.direccion.municipio.id));
              
              return new usuarioActions.CargarCodePostalSuccess(request.direccion.estado.id, request.direccion.municipio.id);
            } else {
              this.toastr.warning('No se encontro información sobre el codígo postal');
              return new usuarioActions.CargarCodePostalSuccess(0, 0);
            }
          }),
          catchError( error => of(new usuarioActions.CargarCodePostalFail(error)))
        );
    })
  );

}
