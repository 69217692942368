import { Action } from '@ngrx/store';
import { Notificacion } from 'src/app/models/notificacion';

export const CARGAR_NOTIFICACIONES = '[Notificaciones] Cargar notificaciones';
export const CARGAR_NOTIFICACIONES_FULL = '[Notificaciones] Cargar notificaciones Full';
export const CARGAR_NOTIFICACIONES_FAIL = '[Notificaciones] Cargar notificaciones FAIL';
export const CARGAR_NOTIFICACIONES_SUCCESS = '[Notificaciones] Cargar notificaciones SUCCESS';

export class CargarNotificaciones implements Action {
  readonly type = CARGAR_NOTIFICACIONES;

  constructor(public pag: number, public limit: number, public usuario: number ) {}
}

export class CargarNotificacionesFull implements Action {
  readonly type = CARGAR_NOTIFICACIONES_FULL;

  constructor(public pag: number, public limit: number, public usuario: number, public tipos: string,
              public tiposUsuarios: string, public search: string ) {}
}

export class CargarNotificacionesFail implements Action {
  readonly type = CARGAR_NOTIFICACIONES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarNotificacionesSuccess implements Action {
  readonly type = CARGAR_NOTIFICACIONES_SUCCESS;

  constructor( public notificaciones: Notificacion[], public total: number, public sinLeer: number) {}
}

export type notificacionesAcciones  = CargarNotificaciones
                                    | CargarNotificacionesFull
                                    | CargarNotificacionesFail
                                    | CargarNotificacionesSuccess;
