export class Directorio {
  public id: number;
  public nombre: string;
  public path: string;
  public directorios: Directorio[];
  public nombreAnterior: string;
  constructor(
    obj?: ObjDirectorio
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.path = obj && obj.path ? obj.path : '';
    this.directorios = obj && obj.directorios ? obj.directorios : [];
    this.nombreAnterior = obj && obj.nombreAnterior ? obj.nombreAnterior : null;
  }
}

interface ObjDirectorio {
  id?: number;
  nombre?: string;
  path?: string;
  directorios?: Directorio[];
  nombreAnterior?: string;
}
