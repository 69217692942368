import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserChangePassword } from 'src/app/models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import * as usuariosActions from 'src/app/store/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.sass'],
  providers: []
})
export class RecoveryComponent implements OnInit {
  public title: string;
  public usuario: UserChangePassword;
  private tokenId: string;
  public loading: boolean;
  public year = 2020;

  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
    this.usuario = new UserChangePassword(0, '', '', '');
    const date = new Date();
    this.year = date.getFullYear();
  }

  ngOnInit() {
    console.log('Incializa componente Recovery...');
    this.title = 'Recuperar usuario';
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id && id !== '' && id !== undefined && id != null) {
          this.tokenId = id;
        } else {
          this.router.navigate(['/public/login']);
        }
      }
    );
  }

  validaCampos() {
    const deferred = new Promise( (resolve, reject) => {
      if (!this.usuario.contrasena) {
        // this.toastr.warning('La contraseña no es valida');
        // angular.element('#contrasenaUsuario').focus();
        resolve(false);
      } else if (this.usuario.contrasena !== this.usuario.confirmaContrasena) {
        // this.toastr.warning('Contraseña no coincide');
        // angular.element('#confirmaContrasenaUsuario').focus();
        resolve(false);
      } else {
        resolve(true);
      }
    });
    return deferred;
  }
  onSubmit(form) {
    this.loading = true;
    this.validaCampos().then(
      (resolve) => {
        this.userService.recoveryPassword(this.usuario.contrasena, this.tokenId).subscribe(
          (respuesta) => {
            this.loading = false;
            if (respuesta.usuario) {
              this.toastr.success('Contraseña actualizada.');
              this.router.navigate(['/public/login']);
            } else {
            this.toastr.warning('Ocurrió un error con la información, le solicitamos vuelva a solicitar la recuperación de su contraseña');
            }
          }, (error) => {
            if (error.code && error.code < 500) {
              this.toastr.warning(error.message, 'Error');
            } else {
              this.toastr.error('Ocurrió un error al intentar actualizar contraseña');
            }
            this.loading = false;
          }
        );
      }, (error) => {
        this.loading = false;
      }
    );
  }
}
