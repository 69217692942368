import { Pipe, PipeTransform } from '@angular/core';

interface FiltroMulti {
  opciones: any[];
  parametro: string;
}

@Pipe({
  name: 'filterSelectedCampingSend'
})
export class FilterSelectedCampingSendPipe implements PipeTransform {

  transform(value: any[], args: FiltroMulti): any {
    if (!value.length) { return []; }
    if (!args.opciones.length) { return value; }
    const ids = args.opciones.filter((item) => {
      return item.checked;
    }).map((item) => {
      return item.estatus;
    });
    if (!ids.length) { return value; }
    return value.filter((it: any) => {
      debugger;
      return ids.some(item => {
        if (it[args.parametro] === item) {
          return true;
        } else {
          return false;
        }
      });
    });
  }

}
