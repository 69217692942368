<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
	<div class="col-lg-6">
		<form id="editContactoForm" name="editContactoForm" #editContactoForm="ngForm" (ngSubmit)="onSubmit()">
			<div class="card main-card mb-3">
				<div class="card-header">
					Captura la información del contacto
					<div class="btn-actions-pane-right" ngbDropdown>
						<div class="btn-group">
							<a [routerLink]="['/auth/contactos/profile/', contacto.id]" *ngIf="contacto.id && (identity.permisos.contactosRegEdit || identity.permisos.contactosRegVer)" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3 mr-3 ml-3">
								Ver perfil
								<span class="pl-2 align-middle opacity-7">
									<i class="fas fa-arrow-right"></i>
								</span>
							</a>
							<button type="button" class="btn btn-icon btn-icon-only btn-link text-muted" ngbDropdownToggle *ngIf="contacto.id && identity.id === contacto.propietario">
								<i class="fa fa-ellipsis-v"></i>
							</button>
							<div ngbDropdownMenu>
								<button type="button" tabindex="0" class="dropdown-item text-danger" (click)="eliminarContacto()">
									<i class="fas fa-trash"></i> <span class="ml-2"> Eliminar</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="nombreContacto" class="control-label">Nombre(s)</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="nombreContacto" name="nombreContacto" #nombreContacto="ngModel" [(ngModel)]="contacto.nombre"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el nombre del contacto." required />
								<!-- <span *ngIf="!nombreContacto.valid && nombreContacto.touched"> El nombre es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="apellidoContacto" class="control-label">Apellidos</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="apellidoContacto" name="apellidoContacto" #apellidoContacto="ngModel" [(ngModel)]="contacto.apellido"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el apellido del contacto." />
								<!-- <span *ngIf="!apellidoContacto.valid && apellidoContacto.touched"> El apellido es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="Contacto" class="control-label">Imagen</label>
							</div>
							<div class="col-sm-8">
								<div class="custom-file mb-3">
									<input type="file" class="custom-file-input"  lang="es" (change)="fileChangeEvent($event)" title="Seleccione una imagen." placeholder="Elige una imagen..." required accept="image/png, image/jpeg, image/jpg" id="validatedCustomFile">
									<label class="custom-file-label" for="validatedCustomFile">Elige un archivo...</label>
									<div class="invalid-feedback">Archivo invalido</div>
								</div>
								<image-cropper
									[imageChangedEvent]="imageChangedEvent"
									[maintainAspectRatio]="true"
									[aspectRatio]="4 / 4"
									[resizeToWidth]="512"
									[cropperMinWidth]="512"
									[onlyScaleDown]="true"
									[roundCropper]="false"
									format="png"
									outputType="base64"
									(imageCropped)="imageCropped($event)"
									(imageLoaded)="imageLoaded()"
									(cropperReady)="cropperReady()"
									(loadImageFailed)="loadImageFailed()"
									[style.display]="showCropper ? null : 'none'"
								></image-cropper>
								<div class="card-footer" *ngIf="imageChangedEvent">
									<div class="text-center">
										<button type="button" class="btn btn-sm m-2 btn-primary" (click)="rotateLeft()">
											<i class="fa fa-undo"></i> Girar izquierda
										</button>
										<button type="button" class="btn btn-sm m-2 btn-primary" (click)="rotateRight()">
											<i class="fa fa-undo fa-flip-horizontal"></i> Girar derecha
										</button>
										<button type="button" class="btn btn-sm m-2 btn-primary" (click)="flipHorizontal()">
											<i class="fab fa-flipboard fa-rotate-270"></i> Invertir horizontal
										</button>
										<button type="button" class="btn btn-sm m-2 btn-primary" (click)="flipVertical()">
											<i class="fab fa-flipboard fa-flip-horizontal"></i> Invertir vertical
										</button>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="cumpleanioContacto" class="control-label">Cumpleaños </label>
							</div>
							<div class="col-sm-8">
								<input type="text"class="form-control" id="cumpleanioContacto" name="cumpleanioContacto" #cumpleanioContacto="ngModel" bsDatepicker [(ngModel)]="contacto.cumpleanios" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" [disabled]="!identity.permisos.contactosRegEdit">
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="propietarioContacto" class="control-label">Propietario</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="usuarios"
									bindLabel="descripcion"
                  bindValue="id"
                  placeholder="Propietario del contacto"
                  id="propietarioContacto"  name="propietarioContacto" #propietarioContacto= "ngModel" [(ngModel)]="contacto.propietario" [disabled]="!identity.permisos.contactosRegEdit" required>
								</ng-select>
								<!-- <span *ngIf="!propietarioContacto.valid && propietarioContacto.touched"> El estado del contacto es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="contacto.id > 0 && ultimaActividad">
						<div class="row">
							<div class="col-sm-4">
								<label class="control-label">Contactado última vez</label>
							</div>
							<div class="col-sm-8" *ngIf="ultimaActividad">
								<!-- {{ultimaActividad | amLocale: 'es' | date:'long' }} -->
								<!-- {{ultimaActividad | amLocale: 'es' | amDateFormat: 'dddd'}}, {{ultimaActividad | amLocale: 'es'  | amDateFormat: 'LL'}} {{ultimaActividad | amLocale: 'es' | amDateFormat: 'h:mm a'}} -->
								{{ultimaActividad | amLocale: 'es' | amDateFormat: 'dddd'}}, {{ultimaActividad | amLocale: 'es'  | amDateFormat: 'LL'}}
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required mb-0">
            <div class="row">
              <div class="col-sm-4">
                <label for="emailContacto" class="control-label">Email</label>
              </div>
              <div class="col-sm-8">
								<div class="row">
									<div class="col-12">
										<input [disabled]="!identity.permisos.contactosRegEdit" type="email" appLowerChart id="emailContacto" name="emailContacto" #emailContacto="ngModel" [(ngModel)]="email"
                  		class="form-control" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxlength="100" (focusout)="agregarEmail()" placeholder="" title="Capture un correo electrónico."/>
									</div>
									<!-- <div class="col-2">
										<button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-primary" [disabled]="!identity.permisos.contactosRegEdit || !emailContacto.valid" (click)="agregarEmail()">
											<i class="fa fa-plus btn-icon-wrapper"> </i>
											<span class="sr-only">Agregar</span>
										</button>
									</div> -->
								</div>
								<div class="main-card mb-3 card no-shadow">
									<ul class="todo-list-wrapper list-group list-group-flush">
										<li class="list-group-item" *ngFor="let correo of contacto.email">
											<div class="todo-indicator bg-primary"></div>
											<div class="widget-content p-0">
												<div class="widget-content-wrapper">
													<div class="widget-content-left">
														<div class="widget-heading"> {{correo.email}} </div>
													</div>
													<div class="widget-content-right widget-content-actions">
														<button type="button" (click)="eliminarEmail(correo)" class="border-0 btn-transition btn btn-outline-danger">
															<i class="fas fa-trash-alt"></i>
														</button>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
              </div>
            </div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="cargoContacto" class="control-label">Cargo</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="cargoContacto" name="cargoContacto" #cargoContacto="ngModel" [(ngModel)]="contacto.cargo"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el cargo del contacto." />
								<!-- <span *ngIf="!cargoContacto.valid && cargoContacto.touched"> El cargo es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="telefonoContacto" class="control-label">Teléfono/Extensión</label>
							</div>
							<div class="col-sm-8">
								<span class="row">
									<div class="col-8">
										<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="telefonoContacto" name="telefonoContacto" #telefonoContacto="ngModel" [(ngModel)]="contacto.telefono"
										class="form-control" maxlength="100" minlength="1" title="Capture el teléfono."/>
									</div>
									<div class="col-4">
										<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="extensionContacto" name="extensionContacto" #extensionContacto="ngModel" [(ngModel)]="contacto.extension"
										class="form-control" maxlength="10" minlength="1" title="Capture el la extensión."/>
									</div>
								</span>
								<span *ngIf="!telefonoContacto.valid && telefonoContacto.touched"> El número de teléfono tiene un formato incorrecto</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="celularContacto" class="control-label">Celular</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="celularContacto" name="celularContacto" #celularContacto="ngModel" [(ngModel)]="contacto.celular"
									class="form-control" maxlength="50" title="Capture un número de teléfono." />
								<!-- <span *ngIf="!celularContacto.valid && celularContacto.touched"> El número del celular es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="tipoOrigenContacto" class="control-label">Origen del contacto</label>
							</div>
							<div class="col-sm-8">
								<div class="row">
									<div class="col-6" [ngClass]="{'col-12': !contacto.especificacion }">
										<input list="tipoOrigenContactoList" type="text" id="tipoOrigenContacto" name="tipoOrigenContacto" #tipoOrigenContacto="ngModel" [(ngModel)]="contacto.desTipoOrigen" class="form-control"
											title="Origen del contacto" maxlength="100" placeholder="" [disabled]="!identity.permisos.contactosRegEdit" (focusout)="filtrarOrigen()" required/>
										<datalist id="tipoOrigenContactoList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
											<option *ngFor="let item of origenContacto" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
										</datalist>
									</div>
									<div class="col-sm-6" *ngIf="contacto.especificacion">
										<input type="text" id="origenContacto" name="origenContacto" #origenContacto="ngModel" [(ngModel)]="contacto.desEspecificacion" class="form-control"
											title="Modelo de tipos" maxlength="100" placeholder="Especifica cual es..." [disabled]="!identity.permisos.contactosRegEdit"/>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="etapaContacto" class="control-label">Etapa</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="etapa" required
									bindLabel="nombre"
									bindValue="id"
									placeholder="Etapa"
									id="etapaContacto"  name="etapaContacto" #etapaContacto= "ngModel" [(ngModel)]="contacto.etapa" [disabled]="!identity.permisos.contactosRegEdit">
								</ng-select>
								<!-- <span *ngIf="!etapaContacto.valid && etapaContacto.touched"> La etapa es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="estatusContacto" class="control-label">Estatus</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="estado" required
									bindLabel="nombre"
									bindValue="id"
									placeholder="Estado del contacto"
									id="estatusContacto"  name="estatusContacto" #estatusContacto= "ngModel" [(ngModel)]="contacto.estado" [disabled]="!identity.permisos.contactosRegEdit">
								</ng-select>
								<!-- <span *ngIf="!estatusContacto.valid && estatusContacto.touched"> El estatus del contacto es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<!-- <fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="empresaContacto" class="control-label">Empresa</label>
							</div>
							<div class="col-sm-8">
								<input list="empresaContactoList" type="text" id="empresaContacto" name="empresaContacto" #empresaContacto="ngModel" [(ngModel)]="contacto.desEmpresa" class="form-control"
									title=" empresas" maxlength="100" placeholder="" [disabled]="!identity.permisos.contactosRegEdit" (change)="existeEmpresa()"/>
								<datalist id="empresaContactoList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of empresas" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset> -->
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="empresaContacto" class="control-label">Empresa</label>
							</div>
							<div class="col-sm-8">
								<input list="empresaContactoList" type="text" id="empresaContacto" name="empresaContacto" #empresaContacto="ngModel" [(ngModel)]="contacto.desEmpresa" class="form-control"
									title=" empresas" maxlength="100" placeholder="" [disabled]="!identity.permisos.contactosRegEdit" (ngModelChange)="cargarEmpresas()" (focusout)="existeEmpresa()"/>
								<datalist id="empresaContactoList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of empresas" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
						<p *ngIf="cargandoEmpresas" class="text-muted">Cargando empresas. Por favor espere...</p>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="opcSipomexContacto" class="control-label">Envio por SEPOMEX</label>
							</div>
							<div class="col-sm-8">
								<bSwitch
									id="opcSipomexContacto"
									name="opcSipomexContacto"
									#opcSipomexContacto="ngModel"
									[(ngModel)]="contacto.opcSepomex"
									[switch-size]="'normal'"
									[switch-on-text]="'Si'"
									[switch-off-text]="'No'"
									[switch-on-color]="'primary'"
									[switch-off-color]="'danger'"
								></bSwitch>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="contacto.opcSepomex">
						<div class="row">
							<div class="col-sm-4">
								<label for="opcPagoContacto" class="control-label">¿Es de pago?</label>
							</div>
							<div class="col-sm-8">
								<bSwitch
									id="opcPagoContacto"
									name="opcPagoContacto"
									#opcPagoContacto="ngModel"
									[(ngModel)]="contacto.opcPago"
									[switch-size]="'normal'"
									[switch-on-text]="'Si'"
									[switch-off-text]="'No'"
									[switch-on-color]="'primary'"
									[switch-off-color]="'danger'"
								></bSwitch>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="paisContacto" class="control-label">País</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="paises" (blur)="consultaCodigoPostalContacto()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el país."
									id="paisContacto"  name="paisContacto" #paisContacto= "ngModel" [(ngModel)]="contacto.pais" [disabled]="!identity.permisos.contactosRegEdit">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" [ngClass]="{'required': contacto.opcSepomex }">
						<div class="row">
							<div class="col-sm-4">
								<label for="codigoPostalContacto" class="control-label">Código postal</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="codigoPostalContacto"
								name="codigoPostalContacto" #codigoPostalContacto="ngModel" [(ngModel)]="contacto.codigoPostal" class="form-control" (blur)="consultaCodigoPostalContacto()"
								placeholder="Número código postal" maxlength="100" minlength="1" title="Capture el código postal de la ubicación." [required]="contacto.opcSepomex"/>
								<!-- <span *ngIf="!codigoPostalContacto.valid && codigoPostalContacto.touched && contacto.opcSepomex"> El código postal es obligatorio!!</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" [ngClass]="{'required': contacto.opcSepomex }">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoContacto" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="estadosContacto" *ngIf="contacto.pais === 1" [required]="contacto.opcSepomex" (blur)="consultaMunicipioContacto()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el estado."
									id="estadoContacto" name="estadoContacto" #estadoContacto="ngModel" [(ngModel)]="contacto.estadoDireccion" 
									[disabled]="!identity.permisos.contactosRegEdit || !editarContacto">
								</ng-select>
								<span *ngIf="contacto.pais !== 1">
									<input  [disabled]="!identity.permisos.contactosRegEdit" type="text" id="estadoContacto" name="estadoContacto" #estadoContacto="ngModel" [(ngModel)]="contacto.desEstadoDireccion"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el estado del contacto." [required]="contacto.opcSepomex"/>
									<!-- <span *ngIf="!estadoContacto.valid && estadoContacto.touched && contacto.opcSepomex"> El estado es obligatorio</span> -->
								</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" [ngClass]="{'required': contacto.opcSepomex }">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioContacto" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="municipiosContacto" (blur)="consultaColoniasContacto()" [required]="contacto.opcSepomex" *ngIf="contacto.pais === 1"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el municipio."
									id="municipioContacto"  name="municipioContacto" #municipioContacto= "ngModel" 
									[(ngModel)]="contacto.municipio" [disabled]="!identity.permisos.contactosRegEdit || !editarMunicipioContacto || !editarContacto">
								</ng-select>
								<span *ngIf="contacto.pais !== 1">
									<input  [disabled]="!identity.permisos.contactosRegEdit" type="text" id="municipioContacto" name="municipioContacto" #municipioContacto="ngModel" [(ngModel)]="contacto.desMunicipio"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el municipio del contacto."  [required]="contacto.opcSepomex"/>
									<!-- <span *ngIf="!municipioContacto.valid && municipioContacto.touched && contacto.opcSepomex"> El municipio/ciudad es obligatorio</span> -->
								</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" [ngClass]="{'required': contacto.opcSepomex }">
						<div class="row">
							<div class="col-sm-4">
								<label for="coloniaContacto" class="control-label">Colonia</label>
							</div>
							<div class="col-sm-8">
								<input list="coloniaContactoList" type="text" id="coloniaContacto" name="coloniaContacto" #coloniaContacto="ngModel" [(ngModel)]="contacto.colonia" class="form-control"
									title="Modelo de colonias" maxlength="100" placeholder="" [disabled]="!identity.permisos.contactosRegEdit" [required]="contacto.opcSepomex"/>
								<datalist id="coloniaContactoList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of coloniasContacto" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
								<!-- <span *ngIf="!coloniaContacto.valid && coloniaContacto.touched && contacto.opcSepomex"> la colonia es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" [ngClass]="{'required': contacto.opcSepomex }">
						<div class="row">
							<div class="col-sm-4">
								<label for="calleContacto" class="control-label">Calle y número</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.contactosRegEdit" type="text" id="calleContacto" name="calleContacto" #calleContacto="ngModel" [(ngModel)]="contacto.calle"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la calle." [required]="contacto.opcSepomex" />
								<!-- <span *ngIf="!calleContacto.valid && calleContacto.touched && contacto.opcSepomex"> La calle es obligatoria</span> -->
							</div>
						</div>
					</fieldset>
				</div>
				<div class="grid-menu grid-menu-2col border-top">
					<div class="no-gutters row">
						<div class="col-sm-6" *ngIf="identity.permisos.contactosListVer || identity.permisos.contactosListEdit">
							<a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/contactos']">
								<i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
							</a>
						</div>
						<div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.contactosRegEdit">
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="(contacto | json) === (contactoInicial | json) || !contacto.email.length">
								<i class="lnr-download btn-icon-wrapper"></i>
								{{(contacto | json) === (contactoInicial | json) ? 'Sin cambios' : 'Registrar cambios'}}
							</button>
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
								<span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
								<br>
								Loading...
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="col-lg-0" [ngClass]="{'col-lg-6': capturaEmpresa }">
		<form id="registerEmpresa" name="registerEmpresa" #registerEmpresa="ngForm">
			<div class="card main-card mb-3" *ngIf="capturaEmpresa">
				<div class="card-header">
					Captura la información de la empresa
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="nombreEmpresa" class="control-label">Nombre</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="nombreEmpresa" name="nombreEmpresa" #nombreEmpresa="ngModel" [(ngModel)]="empresa.nombre"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el nombre de la empresa." required />
								<!-- <span *ngIf="!nombreEmpresa.valid && nombreEmpresa.touched"> El nombre es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="identity.tipo <= 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="propietarioEmpresaContacto" class="control-label">Propietario</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="usuarios" [disabled]="!identity.permisos.empresasRegEdit"
									bindLabel="descripcion"
									bindValue="id"
									placeholder="Propietario de la empresa."
									id="propietarioEmpresaContacto"  name="propietarioEmpresaContacto" #propietarioEmpresaContacto= "ngModel" [(ngModel)]="empresa.propietario" required>
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="claseEmpresa" class="control-label">Clase</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="clases" [disabled]="!identity.permisos.empresasRegEdit"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Clase de la empresa."
									id="claseEmpresa"  name="claseEmpresa" #claseEmpresa= "ngModel" [(ngModel)]="empresa.clase" required>
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="industriaEmpresa" class="control-label">Industria</label>
							</div>
							<div class="col-sm-8">
								<input list="industriaEmpresaList" type="text" id="industriaEmpresa" name="industriaEmpresa" #industriaEmpresa="ngModel" [(ngModel)]="empresa.desIndustria" class="form-control"
									title="Modelo de industrias" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit" required />
								<datalist id="industriaEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of industriasFiltradas" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="clasificacionesEmpresa" class="control-label">Clasificación</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="clasificaciones" [disabled]="!identity.permisos.empresasRegEdit" (focusout)="existeIndustria()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="clasificación."
									id="clasificacionesEmpresa"  name="clasificacionesEmpresa" #clasificacionesEmpresa= "ngModel" [(ngModel)]="empresa.clasificacion" required>
								</ng-select>
							</div>
						</div>
					</fieldset>
					<!-- <fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="tipoEmpresa" class="control-label">Tipo</label>
							</div>
							<div class="col-sm-8">
								<input list="tipoEmpresaList" type="text" id="tipoEmpresa" name="tipoEmpresa" #tipoEmpresa="ngModel" [(ngModel)]="empresa.desTipo" class="form-control"
									title="Modelo de tipos" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit"/>
								<datalist id="tipoEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of tipos" class="list-group-item" [ngValue]="item.id" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset> -->
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="urlEmpresa" class="control-label">Página web</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="urlEmpresa" name="urlEmpresa" #urlEmpresa="ngModel" [(ngModel)]="empresa.url"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la página web." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group ">
						<div class="row">
							<div class="col-sm-4">
								<label for="telefonoUnoEmpresa" class="control-label">Teléfono</label>
							</div>
							<div class="col-sm-8">
								<div [ngClass]="{'form-group': telefonos > 1 }">
									<div class="input-group">
										<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="telefonoUnoEmpresa" name="telefonoUnoEmpresa" #telefonoUnoEmpresa="ngModel" [(ngModel)]="empresa.telefonoUno"
										class="form-control" maxlength="100" title="Capture un número de teléfono." />
										<div class="input-group-append">
											<button type="button" class="btn btn-secondary"  (click)="cantidadTelefono(2)" [disabled]="!identity.permisos.empresasRegEdit || telefonos === 1">
												<i class="fas fa-minus"></i>
											</button>
											<button type="button" class="btn btn-primary" (click)="cantidadTelefono(1)" [disabled]="!identity.permisos.empresasRegEdit || telefonos === 3">
												<i class="fas fa-plus"></i>
											</button>
										</div>
									</div>
									<!-- <span *ngIf="!telefonoUnoEmpresa.valid && telefonoUnoEmpresa.touched"> El número de teléfono tiene un formato incorrecto</span> -->
								</div>
								<fieldset class="form-group " *ngIf="telefonos > 1">
									<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="telefonoDosEmpresa" name="telefonoDosEmpresa" #telefonoDosEmpresa="ngModel" [(ngModel)]="empresa.telefonoDos"
									class="form-control" maxlength="100" title="Capture un número de teléfono."  />
									<!-- <span *ngIf="!telefonoDosEmpresa.valid && telefonoDosEmpresa.touched"> El número de teléfono tiene un formato incorrecto</span> -->
								</fieldset>
								<fieldset class="" *ngIf="telefonos > 2">
									<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="telefonoTresEmpresa" name="telefonoTresEmpresa" #telefonoTresEmpresa="ngModel" [(ngModel)]="empresa.telefonoTres"
									class="form-control" maxlength="100" title="Capture un número de teléfono."  />
									<!-- <span *ngIf="!telefonoTresEmpresa.valid && telefonoTresEmpresa.touched"> El número de teléfono tiene un formato incorrecto</span> -->
								</fieldset>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="fundacionEmpresa" class="control-label">Fecha de fundación </label>
							</div>
							<div class="col-sm-8">
								<input type="text"class="form-control" id="fundacionEmpresa" name="fundacionEmpresa" #fundacionEmpresa="ngModel" bsDatepicker [(ngModel)]="empresa.fundacion" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" [disabled]="!identity.permisos.empresasRegEdit">
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="paisEmpresa" class="control-label">País</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="paises" (blur)="consultaCodigoPostal()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el país."
									id="paisEmpresa"  name="paisEmpresa" #paisEmpresa= "ngModel" [(ngModel)]="empresa.pais" 
									[disabled]="!identity.permisos.empresasRegEdit">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="codigoPostalEmpresa" class="control-label">Código postal</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="codigoPostalEmpresa"
									name="codigoPostalEmpresa" #codigoPostalEmpresa="ngModel" [(ngModel)]="empresa.codigoPostal" class="form-control" (blur)="consultaCodigoPostal()"
									placeholder="Número código postal" maxlength="100" minlength="1" title="Capture el código postal de la ubicación."/>
								<span *ngIf="!codigoPostalEmpresa.valid && codigoPostalEmpresa.touched"> El código postal es obligatorio!!</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="estados" (blur)="consultaMunicipio()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el estado."
									id="estadoEmpresa"  name="estadoEmpresa" #estadoEmpresa= "ngModel" [(ngModel)]="empresa.estado" [disabled]="!identity.permisos.empresasRegEdit || !editar">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="municipios" (blur)="consultaColonias()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el municipio."
									id="municipioEmpresa" name="municipioEmpresa" #municipioEmpresa= "ngModel" [(ngModel)]="empresa.municipio" [disabled]="!identity.permisos.empresasRegEdit || !editarMunicipio || !editar">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="estadoEmpresa" name="estadoEmpresa" #estadoEmpresa="ngModel" [(ngModel)]="empresa.desEstado"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el estado de la empresa."/>
								<span *ngIf="!estadoEmpresa.valid && estadoEmpresa.touched"> El estado es obligatorio</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.pais !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="municipioEmpresa" name="municipioEmpresa" #municipioEmpresa="ngModel" [(ngModel)]="empresa.desMunicipio"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el municipio de la empresa."  />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="coloniaEmpresa" class="control-label">Colonia</label>
							</div>
							<div class="col-sm-8">
								<input list="coloniaEmpresaList" type="text" id="coloniaEmpresa" name="coloniaEmpresa" #coloniaEmpresa="ngModel" [(ngModel)]="empresa.colonia" class="form-control"
									title="Modelo de colonias" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit"/>
								<datalist id="coloniaEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of colonias" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="calleEmpresa" class="control-label">Calle y número</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="calleEmpresa" name="calleEmpresa" #calleEmpresa="ngModel" [(ngModel)]="empresa.calle"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la calle." />
								<span *ngIf="!calleEmpresa.valid && calleEmpresa.touched"> La calle es obligatoria</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="opcDatoFiscalEmpresa" class="control-label">Datos fiscales</label>
							</div>
							<div class="col-sm-8">
								<bSwitch
									id="opcDatoFiscalEmpresa"
									name="opcDatoFiscalEmpresa"
									#opcDatoFiscalEmpresa="ngModel"
									[(ngModel)]="empresa.opcDatoFiscal"
									[switch-size]="'normal'"
									[switch-on-text]="'Si'"
									[switch-off-text]="'No'"
									[switch-on-color]="'primary'"
									[switch-off-color]="'danger'"
								></bSwitch>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="rfcEmpresa" class="control-label">RFC</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="rfcEmpresa" name="rfcEmpresa" #rfcEmpresa="ngModel" [(ngModel)]="empresa.rfc"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el RFC de la empresa." required />
								<span *ngIf="!rfcEmpresa.valid && rfcEmpresa.touched"> El RFC es obligatorio</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="razonSocialEmpresa" class="control-label">Razón social</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="razonSocialEmpresa" name="razonSocialEmpresa" #razonSocialEmpresa="ngModel" [(ngModel)]="empresa.razonSocial"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la razón social de la empresa." required />
								<span *ngIf="!razonSocialEmpresa.valid && razonSocialEmpresa.touched"> La razón social es obligatorio</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="usoCfdiEmpresa" class="control-label">Tipo contribuyente</label>
							</div>
							<div class="col-sm-8">
								<select name="tipoContribuyenteEmpresa" id="tipoContribuyenteEmpresa" #tipoContribuyenteEmpresa="ngModel" [(ngModel)]="empresa.tipoContribuyente" class="form-control">
									<option value="">Selecciona una opción...</option>
									<option value="Moral">Moral</option>
									<option value="Fisica">Fisica</option>
								</select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="usoCfdiEmpresa" class="control-label">Uso CFDI</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="usoCfdiEmpresa" name="usoCfdiEmpresa" #usoCfdiEmpresa="ngModel" [(ngModel)]="empresa.usoCfdi"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Seleccione el tipo de uso CFDI." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="nombreNuentasPagarEmpresa" class="control-label">Nombre contacto cuentas por pagar</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="nombreNuentasPagarEmpresa" name="nombreNuentasPagarEmpresa" #nombreNuentasPagarEmpresa="ngModel" [(ngModel)]="empresa.razonSocial"
									class="form-control" maxlength="100" minlength="2" placeholder="Nombre del contacto..." title="Nombre de la persona de contacto para pagos y facturación." />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="emailCuentasPagarEmpresa" class="control-label">Email Contacto cuentas por pagar</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="email" id="emailCuentasPagarEmpresa" name="emailCuentasPagarEmpresa" #emailCuentasPagarEmpresa="ngModel" [(ngModel)]="empresa.razonSocial"
									class="form-control" maxlength="100" minlength="2" placeholder="Email del contacto..." title="Email de la persona de contacto para pagos y facturación." />
									<span *ngIf="!emailCuentasPagarEmpresa.valid && emailCuentasPagarEmpresa.touched" class="text-danger"> El correo es invalido</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="direccionDatoFiscalEmpresa" class="control-label">¿Es la misma dirección?</label>
							</div>
							<div class="col-sm-8">
								<bSwitch
									id="direccionDatoFiscalEmpresa"
									name="direccionDatoFiscalEmpresa"
									#direccionDatoFiscalEmpresa="ngModel"
									[(ngModel)]="empresa.direccionDatoFiscal"
									[switch-size]="'normal'"
									[switch-on-text]="'Si'"
									[switch-off-text]="'No'"
									[switch-on-color]="'primary'"
									[switch-off-color]="'danger'"
								></bSwitch>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="paisDatoFiscalEmpresa" class="control-label">País</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="paises" (blur)="consultaCodigoPostalDatoFiscal()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el pais."
									id="paisDatoFiscalEmpresa" name="paisDatoFiscalEmpresa" #paisDatoFiscalEmpresa= "ngModel" 
									[(ngModel)]="empresa.paisDatoFiscal" [disabled]="!identity.permisos.empresasRegEdit">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="codigoPostalDatoFiscalEmpresa" class="control-label">Código postal</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="codigoPostalDatoFiscalEmpresa"
									name="codigoPostalDatoFiscalEmpresa" #codigoPostalDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.codigoPostalDatoFiscal" class="form-control" (blur)="consultaCodigoPostalDatoFiscal()"
									placeholder="Número código postal" maxlength="100" minlength="1" title="Capture el código postal de la ubicación."/>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoDatoFiscalEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="estadosDatoFiscal" (focusout)="consultaMunicipioDatoFiscal()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el estado."
									id="estadoDatoFiscalEmpresa" name="estadoDatoFiscalEmpresa" #estadoDatoFiscalEmpresa="ngModel" 
									[(ngModel)]="empresa.estadoDatoFiscal" [disabled]="!identity.permisos.empresasRegEdit || !editarDatoFiscal">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal === 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioDatoFiscalEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="municipiosDatoFiscal" (blur)="consultaColoniasDatoFiscal()"
									bindLabel="nombre"
									bindValue="id"
									placeholder="Capture el municipio."
									id="municipioDatoFiscalEmpresa" name="municipioDatoFiscalEmpresa" #municipioDatoFiscalEmpresa="ngModel" 
									[(ngModel)]="empresa.municipioDatoFiscal" 
									[disabled]="!identity.permisos.empresasRegEdit || !editarMunicipioDatoFiscal || !editarDatoFiscal">
								</ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="estadoDatoFiscalEmpresa" class="control-label">Estado</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="estadoDatoFiscalEmpresa" name="estadoDatoFiscalEmpresa" #estadoDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.desEstadoDatoFiscal"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el estado de la empresa."/>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal && empresa.paisDatoFiscal !== 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="municipioDatoFiscalEmpresa" class="control-label">Municipio/Ciudad</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="municipioDatoFiscalEmpresa" name="municipioDatoFiscalEmpresa" #municipioDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.desMunicipioDatoFiscal"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el municipio de la empresa."  />
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="coloniaDatoFiscalEmpresa" class="control-label">Colonia</label>
							</div>
							<div class="col-sm-8">
								<input list="coloniaDatoFiscalEmpresaList" type="text" id="coloniaDatoFiscalEmpresa" name="coloniaDatoFiscalEmpresa" #coloniaDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.coloniaDatoFiscal" class="form-control"
									title="Modelo de colonias" maxlength="100" placeholder="" [disabled]="!identity.permisos.empresasRegEdit" />
								<datalist id="coloniaDatoFiscalEmpresaList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of coloniasDatoFiscal" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group" *ngIf="empresa.opcDatoFiscal && !empresa.direccionDatoFiscal">
						<div class="row">
							<div class="col-sm-4">
								<label for="calleDatoFiscalEmpresa" class="control-label">Calle</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.empresasRegEdit" type="text" id="calleDatoFiscalEmpresa" name="calleDatoFiscalEmpresa" #calleDatoFiscalEmpresa="ngModel" [(ngModel)]="empresa.calleDatoFiscal"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture la calle." />
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</form>
  	</div>
</div>
