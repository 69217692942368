import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { Contacto } from 'src/app/models/contacto';
import { AutoActualizacionService } from 'src/app/services/auto-actualizacion-campana.service';
import { ConsultasService } from 'src/app/services/consultas.service';
import { Subscription } from 'rxjs';
import { ContactoAutoActualizacion } from 'src/app/models/auto-actualizacion';
import * as usuariosActions from 'src/app/store/actions';
import { Catalogo } from 'src/app/models/catalogo';
import { Industria } from 'src/app/models/industrias';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';


@Component({
  selector: 'app-auto-actualizacion-campana',
  templateUrl: './auto-actualizacion-campana.component.html',
  styleUrls: ['./auto-actualizacion-campana.component.scss'],
  providers: []
})
export class AutoActualizacionCampanaComponent implements OnInit, OnDestroy {
  public title: string;
  public contacto: ContactoAutoActualizacion = new ContactoAutoActualizacion();
  private tokenId = '';
  public loading: boolean;
  public year = 2020;
  public found = true;
  public editarMunicipioContacto = false;
  public editarContacto = false;
  public socio:any[] = [];
  public estados: any[] = [];
  public municipios: any[] = [];
  public colonias: any[] = [];
  public paises: Catalogo[] = [];
  public clasificaciones: Catalogo[];
  public industrias: Industria[];
  public industriasFiltradas: Industria[];
  public clasificacionAnterior: number;
  private subscribePaises: Subscription = new Subscription();

  constructor(
    private autoActualizacionService: AutoActualizacionService,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private consultasService: ConsultasService
  ) {
    const date = new Date();
    this.year = date.getFullYear();
    this.clasificacionAnterior = 99999;

  }

  ngOnInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = "#f2f4f7";
    console.log('Incializa componente Auto Actualizacion...');
    this.title = 'Recuperar usuario';
    this.route.params.subscribe(
      (params) => {
        const id = params.key;
        if (id && id !== '' && id !== undefined && id != null) {
          this.tokenId = id;
          this.autoActualizacionService.validarTokenCorreoContacto(this.tokenId).subscribe(
            (respuesta) => {
              this.contacto = respuesta.contacto;
              const pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();
              this.contacto.fundacion = this.contacto.fundacion ? pipeDateWithoutTimeZone.transform(this.contacto.fundacion) : null;
              this.contacto.cumpleanios = this.contacto.cumpleanios ? pipeDateWithoutTimeZone.transform(this.contacto.cumpleanios) : null;
              if(this.industrias.length){
                this.existeIndustria();
              }
            }, error => {
              // el token no es valido
            });
        } else {
          // this.router.navigate(['/public/login']);
          // this.router.navigate(['/public/login']);
          this.found = false;
        }
      }
    );
    // this.subscribePaises = this.consultasService.obtenerPaisesPublic().subscribe(
    //   (respuesta: any) => {
    //     this.paises = [...respuesta.paises];
    //   }, error => {

    //   }
    // );
    this.subscribePaises = this.store.select('consulta').subscribe(
      (state) => {
        this.paises = [...state.paises.filter(item => item.status)];
        this.clasificaciones = [...state.clasificaciones.filter(item => item.status).map( item => {
          if ( item.id === 2) {
            return new Catalogo({id: item.id, nombre: 'Proveedor de servicios de Seguridad', status: true});
          } else {
            return new Catalogo({id: item.id, nombre: item.nombre, status: true});
          } 
        })];
        this.industrias = [...state.industrias.filter(item => item.status)];
        if(this.industrias.length){
          this.existeIndustria();
        }
      }
    );
    this.store.dispatch(new usuariosActions.ConsultaPaisesPublic());
    this.store.dispatch(new usuariosActions.ConsultaIndustriasPublico());
    this.store.dispatch(new usuariosActions.ConsultaClasificacionesPublico());

  }

  ngOnDestroy() {
    this.subscribePaises.unsubscribe();
  }

  validaCampos() {
    const deferred = new Promise( (resolve, reject) => {
      resolve(true);
    });
    return deferred;
  }
  onSubmit() {
    this.loading = true;
    this.validaCampos().then(
      (resolve) => {
        this.autoActualizacionService.guardarAutoActualizacionInfo(this.contacto, this.tokenId).subscribe(
          (respuesta) => {
            this.loading = false;
            if (respuesta.ok) {
              this.toastr.success('Actualización correcta.');
              this.router.navigate(['/shared/gracias-confirmar/', this.tokenId]);
            } else {
              this.toastr.warning('Ocurrió un error con la información, le solicitamos vuelva a solicitar la recuperación de su contraseña');
            }
          }, (error) => {
            this.loading = false;
          }
        );
      }, (error) => {
        this.loading = false;
      }
    );
  }

  limpiarFecha() {}
  consultaCodigoPostal() {}

  selectPais() {
    const info = this.paises.find( itm => itm.id === this.contacto.pais);
    if (info.id) {
      this.contacto.desPais = info.nombre;
    } else {
      this.contacto.pais = 0;
      this.contacto.desPais = '';
    }
  }

  existeIndustria( status: boolean = false){
    if(this.contacto.tipoEmpresa && this.contacto.tipoEmpresa !== this.clasificacionAnterior){
      // this.empresa.desIndustria = null;
      this.industriasFiltradas = [...this.industrias.filter(item => item.clasificacion === this.contacto.tipoEmpresa)];
      this.clasificacionAnterior = this.contacto.tipoEmpresa;
      if (status){
        this.contacto.industria = null;
      }
    }else if (!this.contacto.tipoEmpresa ){
      this.industriasFiltradas = [...this.industrias];
    }
  }

  existeClasificacion(){
    if( !this.contacto.tipoEmpresa ){
      this.industrias.find( event => {
        if (this.contacto.industria === event.id ) {
          this.clasificacionAnterior = event.clasificacion ;
          this.contacto.tipoEmpresa = event.clasificacion ;
        }
      });
    }
  }
}
