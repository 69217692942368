
<div class="modal-header">
  <h4 class="modal-header-title">Crear lista para la campaña</h4>
  <button type="button" class="close" aria-label="Close" (click)="dissmiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <form id="registerListaCampanaFormList" name="registerListaCampanaFormList" #registerListaCampanaFormList="ngForm"
    (ngSubmit)="guardar()">
    <div class="card main-card no-shadow">
      <div class="card-header text-center" style="color:#3f6ad8;">
        Selecciona a quienes está dirigida esta campaña
      </div>
      <div class="card-body">
        <div *ngIf="cargando" class="text-center">
          <h2 class="text-muted"><i class="fa fa-circle-notch fa-pulse"></i></h2>
        </div>
        <div class="row" *ngIf="!cargando">
          <div class="col-lg-6">
            <div class="v-options-menu vsm-default">
              <div class="vsm-list">
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesPaisFilterLista">Países</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesPaisFilterLista"
                        name="selectCheckOpcionesPaisFilterLista" #selectCheckOpcionesPaisFilterLista="ngModel"
                        [placeholder]="'Paises'" [settings]="dropdownSettings" [data]="filterPaises"
                        [(ngModel)]="listaCampanas.paises" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)" (onDeSelect)="quitarEstadosPais($event)"
                        (onDeSelectAll)="quitarEstadosPais($event)">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group ml-5">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesEstadosFilterLista">Estados</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesEstadosFilterLista"
                        name="selectCheckOpcionesEstadosFilterLista" #selectCheckOpcionesEstadosFilterLista="ngModel"
                        [placeholder]="'Estados'" [settings]="dropdownSettingsEstados" [data]="estados"
                        [(ngModel)]="listaCampanas.estados" [disabled]="!listaCampanas.paises.length">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesPropietarioFilterLista">Propietarios</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesPropietarioFilterLista"
                        name="selectCheckOpcionesPropietarioFilterLista"
                        #selectCheckOpcionesPropietarioFilterLista="ngModel" [placeholder]="'Propietarios'"
                        [settings]="dropdownSettings" [data]="filterPropietarios"
                        [(ngModel)]="listaCampanas.propietarios">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesTipoOrigenContactoFilterLista">Origen de Contacto</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesTipoOrigenContactoFilterLista"
                        name="selectCheckOpcionesTipoOrigenContactoFilterLista"
                        #selectCheckOpcionesTipoOrigenContactoFilterLista="ngModel"
                        [placeholder]="'Origen de Contacto'" [settings]="dropdownSettings"
                        [data]="filterOrigenContacto" [(ngModel)]="listaCampanas.origenContactos">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesEstatusContactoFilterLista">Estatus</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesEstatusContactoFilterLista"
                        name="selectCheckOpcionesEstatusContactoFilterLista"
                        #selectCheckOpcionesEstatusContactoFilterLista="ngModel" [placeholder]="'Estatus'"
                        [settings]="dropdownSettings" [data]="filterEstatus" [(ngModel)]="listaCampanas.estatus">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div class="row">
                    <div class="col-sm-4">
                      <label class="control-label" style="margin-top: 18px">Fecha cumpleaños</label>
                    </div>
                    <div class="col-sm-8">
                      <mat-form-field appearance="outline">
                        <mat-label>Ingresa un rango de fechas</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                          <input matStartDate formControlName="iniciacumple"
                            [(ngModel)]="listaCampanas.fecCumpleInicia" placeholder="Fecha inicio">
                          <input matEndDate formControlName="terminacumple"
                            [(ngModel)]="listaCampanas.fecCumpleTermina"
                          placeholder="Fecha termina">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                      <button class="btn btn-light btn-lg ml-3" style="margin-top: -8px" (click)="limpiarFechaCumple()" type="button">
                        <i class="fas fa-undo-alt"></i>
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="v-options-menu vsm-default">
              <div class="vsm-list">
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesIndustriaEmpresaFilterLista">Industrias</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesIndustriaEmpresaFilterLista"
                        name="selectCheckOpcionesIndustriaEmpresaFilterLista"
                        #selectCheckOpcionesIndustriaEmpresaFilterLista="ngModel" [placeholder]="'Industrias'"
                        [settings]="dropdownSettings" [data]="filterIndustrias"
                        [(ngModel)]="listaCampanas.industrias">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesCatalogoSeguridadEmpresaFilterLista">Catalogo de seguridad</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesCatalogoSeguridadEmpresaFilterLista"
                        name="selectCheckOpcionesCatalogoSeguridadEmpresaFilterLista" #catalogoSeguridad="ngModel"
                        [placeholder]="'Seguirdad'" [settings]="dropdownSettingsDescrip" [data]="filterCatalogoSeguidad"
                        [(ngModel)]="listaCampanas.catalogoSeguridad">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesTipoEmpresaFilterLista">Tipo de empresa</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesTipoEmpresaFilterLista"
                        name="selectCheckOpcionesTipoEmpresaFilterLista" #clasificaciones="ngModel"
                        [placeholder]="'Tipo'" [settings]="dropdownSettings" [data]="filterClasificaciones"
                        [(ngModel)]="listaCampanas.clasificaciones">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesClasesFilterLista">Clases</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesClasesFilterLista"
                        name="selectCheckOpcionesClasesFilterLista" #contactosOportunidad="ngModel"
                        [placeholder]="'Clases'" [settings]="dropdownSettings" [data]="filterClases"
                        [(ngModel)]="listaCampanas.clases">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <!-- <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesClasificacionEmpresaFilterLista">Clasificaciones</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesClasificacionEmpresaFilterLista"
                        name="selectCheckOpcionesClasificacionEmpresaFilterLista"
                        #selectCheckOpcionesClasificacionEmpresaFilterLista="ngModel" [placeholder]="'Clasificaciones'"
                        [settings]="dropdownSettings" [data]="filterTipoEmpresas"
                        [(ngModel)]="listaCampanas.tiposEmpresas">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset> -->
                <fieldset class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label for="selectCheckOpcionesEtapasContactoFilterLista">Etapas</label>
                    </div>
                    <div class="col-sm-8">
                      <ng-multiselect-dropdown id="selectCheckOpcionesEtapasContactoFilterLista"
                        name="selectCheckOpcionesEtapasContactoFilterLista"
                        #selectCheckOpcionesEtapasContactoFilterLista="ngModel" [placeholder]="'Etapas'"
                        [settings]="dropdownSettings" [data]="filterEtapas" [(ngModel)]="listaCampanas.etapas">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div class="row">
                    <div class="col-sm-4">
                      <label class="control-label" style="margin-top: 18px">Fecha fundación</label>
                    </div>
                    <div class="col-sm-8">
                      <mat-form-field appearance="outline">
                        <mat-label>Ingresa un rango de fechas</mat-label>
                        <mat-date-range-input [formGroup]="rangefundacion" [rangePicker]="picker2">
                          <input matStartDate formControlName="iniciafunda"
                            [(ngModel)]="listaCampanas.fecFundacionInicia" placeholder="Fecha inicio">
                          <input matEndDate formControlName="terminafunda"
                            [(ngModel)]="listaCampanas.fecFundacionTermina" 
                          placeholder="Fecha termina">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker2></mat-date-range-picker>
                      </mat-form-field>
                      <button class="btn btn-light btn-lg ml-3" style="margin-top: -8px" (click)="limpiarFechaFundacion()" type="button">
                        <i class="fas fa-undo-alt"></i>
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-menu grid-menu-2col border-top">
        <div class="no-gutters row">
          <div class="col-sm-6">
            <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" (click)="dissmiss()">
              <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
            </a>
          </div>
          <div class="col-sm-6">
            <button type="submit" *ngIf="!loading"
              class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
              [disabled]="registerListaCampanaFormList.invalid || (listaCampanas.fecFundacionInicia != null && !listaCampanas.fecFundacionTermina)  || (listaCampanas.fecCumpleInicia != null && !listaCampanas.fecCumpleTermina)">
              <i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
            </button>
            <button *ngIf="loading" class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
              disabled>
              <i class="fas fa-spinner fa-pulse btn-icon-wrapper"></i> Registrando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>