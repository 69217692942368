import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, User } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { BarGraph, CircleGraph } from 'src/app/models/graph';
import { DashboardResumen, ListaResumen } from 'src/app/models/dashboard-resumen';
import {ApexAxisChartSeries, ApexChart, ChartComponent, ApexResponsive, ApexNonAxisChartSeries, ApexDataLabels, ApexXAxis, ApexPlotOptions,
  ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexFill, ApexLegend} from 'ng-apexcharts';
import { ChartDataSets } from 'chart.js';
import { Ticket } from 'src/app/models/ticket';
import { FiltroCheck } from 'src/app/pipes/option-check-multiple.pipe';
import { Serie } from '../../../../models/dashboard-resumen';
import { Actividad } from 'src/app/models/actividad';
declare var require: any;

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  responsive: ApexResponsive[];
  labels: any;
  series2: ApexNonAxisChartSeries;
}
@Component({
  selector: 'app-dashboard-administradores',
  templateUrl: './dashboard-administradores.component.html',
  styleUrls: ['./dashboard-administradores.component.sass']
})
export class DashboardAdministradoresComponent implements OnInit, OnDestroy {
  loading: boolean;
  public lenguaje = require('apexcharts/dist/locales/es.json');
  public identity: Identity;
  private subscriptionAuth: Subscription = new Subscription();
  private subscriptionDashboard: Subscription = new Subscription();
  private subscriptionDashboardVehiculo: Subscription = new Subscription();
  private subscriptionTickets: Subscription = new Subscription();
  public url: string;
  public resumen: DashboardResumen = new DashboardResumen(null);
  public usuariosConectados: User[] = [];
  public tickets: Ticket[] = [];
  public actividades: Actividad[] = [];
  public hoyContactos = 0;
  public hoyEmpresas = 0;
  public version: number = 1;
  public hoyOportunidades = 0;
  public statusFilter: FiltroCheck[] = [
    { value: 1, text: 'Nuevo', parametro: 'status', checked: true},
    { value: 2, text: 'Actualizado/Respondido', parametro: 'status', checked: true},
    { value: 3, text: 'Cerrado', parametro: 'status', checked: false}
  ];
  public existeFilter: FiltroCheck[] = [
    { value: 1, text: 'Creados', parametro: 'existe', checked: true},
    { value: 2, text: 'Recibidos', parametro: 'existe', checked: true}
  ];
  public opts = {
    prefix: '',
    suffix: '',
    duration: 5,
  };
  public slideConfig6 = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };
  public labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
  public datasets: ChartDataSets[] = [
    {
      label: 'My First dataset',
      data: [65, 59, 80, 81, 46, 55, 38, 59, 80],
    }
  ];
  public datasets2 = [
    {
      label: 'My First dataset',
      data: [46, 55, 59, 80, 81, 38, 65, 59, 80],
      // datalabels: {
      //   display: false,
      // },

    }
  ];

  public datasets3 = [
    {
      label: 'My First dataset',
      data: [65, 59, 80, 81, 55, 38, 59, 80, 46],
      // datalabels: {
      //   display: false,
      // },

    }
  ];
  public lineChartColors: any[] = [
    { // dark grey
      backgroundColor: 'rgba(247, 185, 36, 0.2)',
      borderColor: '#f7b924',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#f7b924',
      pointBackgroundColor: '#fff',
      pointHoverBorderWidth: 4,
      pointRadius: 6,
      pointBorderWidth: 5,
      pointHoverRadius: 8,
      pointHitRadius: 10,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#f7b924',
    },
  ];
  public lineChartColors2: any[] = [
    { // dark grey
      backgroundColor: 'rgba(48, 177, 255, 0.2)',
      borderColor: '#30b1ff',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#30b1ff',
      pointBackgroundColor: '#ffffff',
      pointHoverBorderWidth: 4,
      pointRadius: 6,
      pointBorderWidth: 5,
      pointHoverRadius: 8,
      pointHitRadius: 10,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#30b1ff',
    },
  ];

  public lineChartColors3: any[] = [
    { // dark grey
      backgroundColor: 'rgba(86, 196, 121, 0.2)',
      borderColor: '#56c479',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#56c479',
      pointBackgroundColor: '#fff',
      pointHoverBorderWidth: 4,
      pointRadius: 6,
      pointBorderWidth: 5,
      pointHoverRadius: 8,
      pointHitRadius: 10,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#56c479',
    },
  ];
  public options = {
    layout: {
      padding: {
        left: 0,
        right: 8,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        }
      }],
      xAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };
  public labelsContactos: string[] = [];
  public seriesContactos: Serie[] = [];
  public labelsEmpresas: string[] = [];
  public seriesEmpresas: Serie[] = [
    {
      name: 'Empresas',
      type: 'area',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }
  ];
  public labelsOportunidades: string[] = [];
  public seriesOportunidades: Serie[] = [
    {
      name: 'Oportunidades',
      type: 'area',
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }
  ];

  constructor(
    private store: Store<AppState>
  ) {
    const dt = new Date();
    this.identity = new Identity(null);
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    console.log('Entra al componente dashboard administradores');
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscriptionDashboard = this.store.select('dashboard').subscribe(
      (state) => {
        this.usuariosConectados = state.usuariosConectados;
        this.labelsContactos = state.labelsContactos;
        this.seriesContactos = state.seriesContactos;
        this.labelsEmpresas = state.labelsEmpresas;
        this.seriesEmpresas = state.seriesEmpresas;
        this.labelsOportunidades = state.labelsOportunidades;
        this.seriesOportunidades = state.seriesOportunidades;
        this.hoyContactos = state.hoyContactos;
        this.hoyEmpresas = state.hoyEmpresas;
        this.hoyOportunidades = state.hoyOportunidades;
        this.resumen = state.resumen;
        this.actividades = [...state.actividades];
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscriptionTickets = this.store.select('ticket').subscribe(
      (state) => {
        this.tickets = state.tickets;
      }
    );
    this.inicializa();
  }

  inicializa() {
    setTimeout( () => {
      this.store.dispatch(new usuariosActions.CargarDashboardActividadesPendientesDia());
      this.store.dispatch(new usuariosActions.CargarDashboardResumen());
      this.store.dispatch(new usuariosActions.CargarDashboardLineContactos());
      this.store.dispatch(new usuariosActions.CargarDashboardLineEmpresas());
      this.store.dispatch(new usuariosActions.CargarDashboardLineOportunidades());
      if (this.identity.permisos && this.identity.permisos.usuariosListVer) {
        this.store.dispatch(new usuariosActions.CargarDashboardUsuariosConectados());
      }
      if (this.identity.permisos && this.identity.permisos.ticketsVer) {
        this.store.dispatch(new usuariosActions.CargarTickets());
      }
    }, 10);
  }

  ngOnDestroy(): void {
    this.subscriptionDashboard.unsubscribe();
    this.subscriptionAuth.unsubscribe();
    this.subscriptionDashboardVehiculo.unsubscribe();
    this.subscriptionTickets.unsubscribe();
  }

}
