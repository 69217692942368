import { Component, OnInit } from '@angular/core';
import { pagesAnimation } from 'src/app/animations/pages.animation';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss'],
  animations: [ pagesAnimation ]
})
export class SharedComponent {

  constructor() { }

}
