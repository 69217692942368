import * as fromUsuario from '../actions';
import { Ticket } from 'src/app/models/ticket';

export interface TicketState {
  tickets: Ticket[];
  ticket: Ticket;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: TicketState = {
  tickets: [],
  ticket: new Ticket(null),
  loaded: false,
  loading: false,
  error: null
};

export function ticketReducer( state = estadoInicial, action: fromUsuario.ticketAcciones ): TicketState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_TICKETS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tickets: [...action.tickets]
      };
    case fromUsuario.CARGAR_TICKETS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_TICKET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ticket: {...action.ticket}
      };
    case fromUsuario.CARGAR_TICKET_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_TICKET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_TICKET_SUCCESS:
      const itm = state.tickets.find( item => item.id === action.ticket.id);
      if (itm && itm.id) {
        state.tickets = state.tickets.map( item => {
          if (item.id === action.ticket.id) {
            item = {...action.ticket};
          }
          return item;
        });
      } else {
        state.tickets.push(action.ticket);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_TICKET_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_TICKET_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_TICKET_ESTATUS_SUCCESS:
      if(action.status === 1){
        state.tickets = [...state.tickets.filter(item => item.id !== action.id)];
      }else{
        state.tickets = state.tickets.map( item => {
          if (item.id === action.id) {
            item.status = 3;
          }
          return item;
        });
      }
      
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_TICKET_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
		case fromUsuario.ENVIA_MENSAJE_TICKET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ENVIA_MENSAJE_TICKET_SUCCESS:
			state.ticket.mensajes.push(action.mensaje);
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ENVIA_MENSAJE_TICKET_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_MENSAJE_TICKET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_MENSAJE_TICKET_SUCCESS:
      state.ticket = {...action.ticket};
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromUsuario.ACTUALIZA_MENSAJE_TICKET_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_TICKET:
      return {
        ...state,
				ticket: new Ticket(null)
      };
    default:
      return state;
  }
}

