import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { distinctUntilChanged, scan, map, tap } from 'rxjs/operators';
import { InjectionToken } from '@angular/core'
import { saveAs } from 'file-saver';

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
}

function isHttpProgressEvent( event: HttpEvent<unknown> ): event is HttpProgressEvent {
    return (
        event.type === HttpEventType.DownloadProgress ||
        event.type === HttpEventType.UploadProgress
    );
}

export interface Download {
    content: Blob | null;
    progress: number;
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

export function download( saver?: (b: Blob) => void ): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) => source.pipe(
        scan(
            (download: Download, event): Download => {
                if (isHttpProgressEvent(event)) {
                    return {
                        progress: event.total
                            ? Math.round((100 * event.loaded) / event.total)
                            : download.progress,
                        state: 'IN_PROGRESS',
                        content: null
                    };
                }
                if (isHttpResponse(event)) {
                    if (saver) {
                        saver(event.body);
                    }
                    return {
                        progress: 100,
                        state: 'DONE',
                        content: event.body
                    };
                }
                return download;
            },
            { state: 'PENDING', progress: 0, content: null }
        ),
        distinctUntilChanged((a, b) => a.state === b.state
            && a.progress === b.progress
            && a.content === b.content
        )
    );
}

export type Saver = (blob: Blob, filename?: string) => void
export const SAVER = new InjectionToken<Saver>('saver')
export function getSaver(): Saver {
  return saveAs;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

    constructor(
        private http: HttpClient,
        @Inject(SAVER) private save: Saver
    ) {}

    download(url: string, filename?: string): Observable<Download> {
        return this.http.get(url, {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob'
        }).pipe(download(blob => this.save(blob, filename)))
    }

    downloadExcel(url: string, opciones: any, filename?: string): Observable<Download> {
        const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
        return this.http.post(url, {
            opciones,
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        }, {headers}).pipe(download(blob => this.save(blob, filename)))
    }

    /**
     * Method is use to download file.
     * @param url - Link of download
     */
    blob(url: string, filename?: string): Observable<Blob> {
        return this.http.get(url, {
            responseType: 'blob'
        })
    }
    
}
