import { ValueTransformer } from '@angular/compiler/src/util';
import { Pipe, PipeTransform } from '@angular/core';
import { EstatusSuscriptor } from '../models/campana';

@Pipe({
  name: 'estatusSuscripcion'
})
export class EstatusSuscripcionPipe implements PipeTransform {

  transform(value: EstatusSuscriptor[], arg: EstatusSuscriptor): any {
    if (!value || !value.length) {
      return [];
    }
    if (!arg
        || (!arg.spam
        && !arg.desuscrito
        && !arg.rebotado
        && !arg.confirmado)) {
      return value;
    }
    value = value.filter( (item: EstatusSuscriptor) => {
      if (arg.confirmado > 0 && item.confirmado > 0) {
        return true;
      } else if (arg.desuscrito > 0 && item.desuscrito > 0) {
        return true;
      } else if (arg.rebotado > 0 && item.rebotado > 0) {
        return true;
      } else if (arg.spam > 0 && item.spam > 0) {
        return true;
      } else {
        return false;
      }
    });
    return value;
  }



}
