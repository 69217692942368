<div class="header-dots">
  <div class="btn-group" ngbDropdown autoClose="outside" placement="bottom">
    <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
      <span>
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-danger"></div>
          <i class="lnr-bullhorn text-danger"></i>
          <div class="badge badge-dot badge-dot-sm badge-danger" *ngIf="sinLeer > 0"></div>
        </div>
      </span>
    </button>
    <div class="dropdown-menu-xl" ngbDropdownMenu>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner bg-dark">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-light">
            <h5 class="menu-header-title">Notificaciones</h5>
            <h6 class="menu-header-subtitle">Tienes
              <b>
                <span *ngIf="!sinLeer">0</span>
                <span *ngIf="sinLeer && sinLeer <= 99">{{sinLeer}}</span>
                <span *ngIf="sinLeer > 99">+99</span>
              </b>
               sin leer
            </h6>
          </div>
        </div>
      </div>
      <div class="card-body p-1">
        <div class="scroll-gradient">
          <div class="scroll-area-md shadow-overflow">
            <perfect-scrollbar [autoPropagation]="true">
              <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                <div class="vertical-timeline-item vertical-timeline-element" *ngFor="let nottific of notificaciones">
                  <div>
                    <span class="vertical-timeline-element-icon bounce-in">
                      <i class="badge badge-dot" [ngClass]="{'bg-blue' : nottific.tipo === 1}"></i>
                    </span>
                    <div class="vertical-timeline-element-content bounce-in">
                      <p>
                        <b>{{nottific.desUsuario}}:</b>
                        {{nottific.descripcion}}
                        <b class="text-danger">{{nottific.fecha | amLocale: 'es' | amTimeAgo}}</b>
                      </p>
                      <span class="vertical-timeline-element-date"></span>
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="nav-justified">
          <ul class="nav flex-column">
            <!-- <li class="nav-item-divider nav-item mt-0"></li> -->
            <li class="nav-item-btn text-center nav-item">
              <a class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" [routerLink]="['/auth/notificaciones']">Ver más</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
