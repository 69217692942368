import { Action } from '@ngrx/store';
import { Actividad } from 'src/app/models/actividad';

export const CARGAR_ACTIVIDADES_EMPRESA = '[ActividadEmrpresa] Cargar actividades empresa';
export const CARGAR_ACTIVIDADES_EMPRESA_FAIL = '[ActividadEmrpresa] Cargar actividades empresa FAIL';
export const CARGAR_ACTIVIDADES_EMPRESA_SUCCESS = '[ActividadEmrpresa] Cargar actividades empresa SUCCESS';
export const ACTUALIZA_ACTIVIDADES_EMPRESA = '[ActividadEmrpresa] Actualiza actividades empresa';
export const ACTUALIZA_ACTIVIDADES_EMPRESA_FAIL = '[ActividadEmrpresa] Actualiza actividades empresa FAIL';
export const ACTUALIZA_ACTIVIDADES_EMPRESA_SUCCESS = '[ActividadEmrpresa] Actualiza actividades empresa SUCCESS';
export const ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS = '[ActividadEmrpresa] Actualiza actividades empresa estatus';
export const ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS_FAIL = '[ActividadEmrpresa] Actualiza actividades empresa estatus FAIL';
export const ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS_SUCCESS = '[ActividadEmrpresa] Actualiza actividades empresa estatus SUCCESS';
export const LIMPIAR_ACTIVIDADES = '[Contacto] Limpiar actividades empresa';
export const CARGAR_ACTIVIDADES_CONTACTO = '[ActividadEmrpresa] Cargar actividades contacto';
export const CARGAR_ACTIVIDADES_CONTACTO_FAIL = '[ActividadEmrpresa] Cargar actividades contacto FAIL';
export const CARGAR_ACTIVIDADES_CONTACTO_SUCCESS = '[ActividadEmrpresa] Cargar actividades contacto SUCCESS';
export const CARGAR_ACTIVIDADES_OPORTUNIDAD = '[ActividadEmrpresa] Cargar actividades oportunidad';
export const CARGAR_ACTIVIDADES_OPORTUNIDAD_FAIL = '[ActividadEmrpresa] Cargar actividades oportunidad FAIL';
export const CARGAR_ACTIVIDADES_OPORTUNIDAD_SUCCESS = '[ActividadEmrpresa] Cargar actividades oportunidad SUCCESS';


export const CARGAR_ACTIVIDADES_PENDIENTES_TOTAL = '[ActividadEmrpresa] Cargar actividades pendientes';
export const CARGAR_ACTIVIDADES_PENDIENTES_TOTAL_FAIL = '[ActividadEmrpresa] Cargar actividades pendientes FAIL';
export const CARGAR_ACTIVIDADES_PENDIENTES_TOTAL_SUCCESS = '[ActividadEmrpresa] Cargar actividades pendientes SUCCESS';


export class CargarActividadesEmpresa implements Action {
  readonly type = CARGAR_ACTIVIDADES_EMPRESA;

  constructor(public id: number) {}
}

export class CargarActividadesEmpresaFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesEmpresaSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_EMPRESA_SUCCESS;

  constructor( public actividades: Actividad[] ) {}
}

export class ActualizaActividadesEmpresa implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_EMPRESA;

  constructor( public actividad: Actividad ) { }
}

export class ActualizaActividadesEmpresaFail implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_EMPRESA_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaActividadesEmpresaSuccess implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_EMPRESA_SUCCESS;

  constructor( public actividad: Actividad ) {}
}

export class ActualizaActividadesEmpresaEstatus implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaActividadesEmpresaEstatusFail implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaActividadesEmpresaEstatusSuccess implements Action {
  readonly type = ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS_SUCCESS;

  constructor( public actividad: Actividad ) {}
}

export class LimpiarActividades implements Action {
  readonly type = LIMPIAR_ACTIVIDADES;

  constructor() {}
}

export class CargarActividadesContacto implements Action {
  readonly type = CARGAR_ACTIVIDADES_CONTACTO;

  constructor(public id: number) {}
}

export class CargarActividadesContactoFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_CONTACTO_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesContactoSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_CONTACTO_SUCCESS;

  constructor( public actividades: Actividad[] ) {}
}

export class CargarActividadesOportunidad implements Action {
  readonly type = CARGAR_ACTIVIDADES_OPORTUNIDAD;

  constructor(public id: number) {}
}

export class CargarActividadesOportunidadFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_OPORTUNIDAD_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesOportunidadSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_OPORTUNIDAD_SUCCESS;

  constructor( public actividades: Actividad[] ) {}
}

export class CargarActividadesPendientesTotal implements Action {
  readonly type = CARGAR_ACTIVIDADES_PENDIENTES_TOTAL;

  constructor( public fechas: {inicia: string, termina: string}, public opciones: any ) {}
}
  
export class CargarActividadesPendientesTotalFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_PENDIENTES_TOTAL_FAIL;
  
  constructor( public payload: any ) {}
}
  
export class CargarActividadesPendientesTotalSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_PENDIENTES_TOTAL_SUCCESS;
  
  constructor( public actividades: Actividad[] ) {}
}

export type actividadesEmpresaAcciones = CargarActividadesEmpresa
                                        | CargarActividadesEmpresaFail
                                        | CargarActividadesEmpresaSuccess
                                        | ActualizaActividadesEmpresa
                                        | ActualizaActividadesEmpresaFail
                                        | ActualizaActividadesEmpresaSuccess
                                        | ActualizaActividadesEmpresaEstatus
                                        | ActualizaActividadesEmpresaEstatusFail
                                        | ActualizaActividadesEmpresaEstatusSuccess
                                        | LimpiarActividades
                                        | CargarActividadesContacto
                                        | CargarActividadesContactoFail
                                        | CargarActividadesContactoSuccess
                                        | CargarActividadesOportunidad
                                        | CargarActividadesOportunidadFail
                                        | CargarActividadesOportunidadSuccess 
                                        | CargarActividadesPendientesTotal
                                        | CargarActividadesPendientesTotalFail
                                        | CargarActividadesPendientesTotalSuccess 
                                        ;

