<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="form-group ml-3">
        <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
        [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
        <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #range FechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
        [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
      </div>
    </div>
    <div class="row" *ngIf="identity.permisos">
      <div class="col-md-3" *ngIf="filterActive">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">Filtrar contenido</div>
            <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
              <div class="btn-actions-pane-right actions-icon-btn">
                <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="p-3">
              <div class="form-group">
                <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize" (change)="cargarContactos()">
                  <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="" class="control-label">Ordenar por</label>
                <select class="form-control custom-select" name="sortTypeProducto" #sortTypeProducto="ngModel" [(ngModel)]="paginacion.sortType" (change)="cargarContactos()">
                  <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
        <div class="main-card mb-3 card card-shadow-primary">
          <div class="card-header">
            <div class="card-header-title">Contactos</div>
            <div class="btn-actions-pane-right mr-4">
              ({{paginacion.totalFiltro}} de {{paginacion.totalSize}})
              <div class="btn-group ml-2">
                <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
                  <div class="input-holder">
                    <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchContactos" id="searchContactos" #searchContactos="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="changeTextSearch()">
                    <button class="search-icon" (click)="searchClick()" for="searchContactos"><span></span></button>
                  </div>
                  <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; listSearch = [];"></button>
                </div>
                <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
                  <span>Filtrar</span>
                  <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
              {{texto}}
              <i class="ml-1 fas fa-times"></i>
            </button>
            <div class="table-responsive">
              <table class="table table-hover table-bordered" style="width: 100%;">
                <thead>
                  <tr>
                    <th (click)="sort('nombre')">Nombre(s)
                      <span *ngIf="paginacion.sortType === '-nombre'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacion.sortType === '+nombre'" class="fa fa-caret-up"></span>
                    </th>
                    <th (click)="sort('cumpleanios')">Cumpleaños
                      <span *ngIf="paginacion.sortType === '-cumpleanios'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacion.sortType === '+cumpleanios'" class="fa fa-caret-up"></span>
                    </th>
                    <th (click)="sort('emails')">Email(s)
                      <span *ngIf="paginacion.sortType === '-emails'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacion.sortType === '+emails'" class="fa fa-caret-up"></span>
                    </th>
                    <th (click)="sort('desEmpresa')">Empresa
                      <span *ngIf="paginacion.sortType === '-desEmpresa'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacion.sortType === '+desEmpresa'" class="fa fa-caret-up"></span>
                    </th>
                    <th (click)="sort('telefono')">Teléfono
                      <span *ngIf="paginacion.sortType === '-telefono'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacion.sortType === '+telefono'" class="fa fa-caret-up"></span>
                    </th>
                    <th (click)="sort('celular')">Celular
                      <span *ngIf="paginacion.sortType === '-celular'" class="fa fa-caret-down"></span>
                      <span *ngIf="paginacion.sortType === '+celular'" class="fa fa-caret-up"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of contactos">
                    <td><a [routerLink]="['/auth/contactos/profile', item.id]">{{item.nombre}} {{item.apellido}}</a></td>
                    <td>{{item.cumpleanios | date: 'dd/MM/yyyy'}}</td>
                    <td>{{item.emails}}</td>
                    <td><a [routerLink]="['/auth/empresas/profile', item.empresa]">{{item.desEmpresa}}</a></td>
                    <td>{{item.telefono | phone}}</td>
                    <td>{{item.celular | phone}}</td>
                  </tr>              
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-2">
            <div class="row p-2">
              <div class="col-sm-6">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-chart-content">
                    <ngb-pagination class="mb-2"
                      [collectionSize]="paginacion.totalFiltro" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5" (pageChange)="cargarContactos(false)">
                      <ng-template ngbPaginationFirst>First</ng-template>
                      <ng-template ngbPaginationLast>Last</ng-template>
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                      <ng-template ngbPaginationEllipsis>...</ng-template>
                      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <button type="button" (click)="printCSV()" class="btn btn-primary mb-2">
                    <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
