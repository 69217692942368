<div class="main-card card">
  <div class="card-header">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left mr-2">
          <div class="custom-checkbox custom-control">
            <input type="checkbox" id="todoUsuariosFilter" class="custom-control-input" name="todoUsuariosFilter" #todoUsuariosFilter="ngModel" [(ngModel)]="checkTodo" (change)="changeCheckTodo()">
            <label class="custom-control-label" for="todoUsuariosFilter">&nbsp;</label>
          </div>
        </div>
        <div class="widget-content-left flex2">
          <div class="widget-heading">Seleccionar todos</div>
        </div>
      </div>
    </div>
  </div>
  <ul class="todo-list-wrapper list-group list-group-flush">
    <li class="list-group-item" *ngFor="let item of usuarios">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left mr-2">
            <div class="custom-checkbox custom-control">
              <input type="checkbox" id="selectCheckUsuariosFilter{{item.id}}" class="custom-control-input" name="selectCheckUsuariosFilter{{item.id}}" #selectCheckUsuariosFilter{{item.id}}="ngModel" [(ngModel)]="item.checked" (change)="changeCheck(item)">
              <label class="custom-control-label" for="selectCheckUsuariosFilter{{item.id}}">&nbsp;</label>
            </div>
          </div>
          <div class="widget-content-left flex2">
            <div class="widget-heading">{{item.nombre}}</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
