import { Pipe, PipeTransform } from '@angular/core';
interface FilterParamInObject {
  search: any;
  param: string;
}
@Pipe({
  name: 'filterParamInObject'
})
export class FilterParamInObjectPipe implements PipeTransform {

  transform(value: any, args: FilterParamInObject[]): any {
    if (!value.length) { return []; }
    if (!args.length) { return value; }
    return value.filter( (it: any) =>
      args.some(f => !f.search || f.search === null || f.search === 0 || f.search === '' || f.search === it[f.param])
    );
  }

}
