<div class="col-6">
    <div class="card main-card mb-5 mt-5">
        <div class="card-body">
            <article class="article">
                <div id="placeholders">

                    <h2><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Política de privacidad para el </font></font><span class="highlight preview_company_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">nombre de la empresa</font></font></span></h2>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">En </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> , accesible en </font><span class="highlight preview_website_name"><font style="vertical-align: inherit;">Sitio </font></span></font><span class="highlight preview_website_url"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">web.com</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> , una de nuestras principales prioridades es la privacidad de nuestros visitantes. </font><font style="vertical-align: inherit;">Este documento de Política de privacidad contiene tipos de información que se recopila y registra por </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> y cómo la usamos.</font></font></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Si tiene preguntas adicionales o necesita más información sobre nuestra Política de privacidad, no dude en contactarnos a través de correo electrónico a </font></font><span class="highlight preview_email_address"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">contacto@jivabe.com</font></font></span></p>

                    <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Archivos de registro</font></font></strong></p>

                    <p><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> sigue un procedimiento estándar de uso de archivos de registro. </font><font style="vertical-align: inherit;">Estos archivos registran a los visitantes cuando visitan sitios web. </font><font style="vertical-align: inherit;">Todas las empresas de hosting hacen esto y forman parte de la analítica de los servicios de hosting. </font><font style="vertical-align: inherit;">La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), marca de fecha y hora, páginas de referencia / salida y posiblemente el número de clics. </font><font style="vertical-align: inherit;">Estos no están vinculados a ninguna información que sea personalmente identificable. </font><font style="vertical-align: inherit;">El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica.</font></font></p>

                    <h3><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Cookies y web beacons</font></font></h3>
                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Como cualquier otro sitio web, el </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">nombre del</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> sitio </font><span class="highlight preview_website_name"><font style="vertical-align: inherit;">web</font></span><font style="vertical-align: inherit;"> utiliza "cookies". </font><font style="vertical-align: inherit;">Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes, y las páginas del sitio web a las que el visitante accedió o visitó. </font><font style="vertical-align: inherit;">La información se utiliza para optimizar la experiencia de los usuarios mediante la personalización del contenido de nuestra página web en función del tipo de navegador de los visitantes y / u otra información.</font></font></p>

                    <h3><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Cookie DART de DoubleClick</font></font></h3>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Google es uno de los proveedores externos en nuestro sitio. </font><font style="vertical-align: inherit;">También utiliza cookies, conocidas como cookies de DART, para publicar anuncios para los visitantes de nuestro sitio en función de su visita a www.website.com y otros sitios en Internet. </font><font style="vertical-align: inherit;">Sin embargo, los visitantes pueden optar por rechazar el uso de las cookies de DART visitando la Política de privacidad de la red de anuncios y contenido de Google en la siguiente URL: </font></font><a href="https://policies.google.com/technologies/ads" target="_blank"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">https://policies.google.com/technologies/ads</font></font></a><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> .</font></font></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Algunos de los anunciantes en nuestro sitio pueden usar cookies y balizas web. </font><font style="vertical-align: inherit;">Nuestros socios publicitarios se enumeran a continuación. </font><font style="vertical-align: inherit;">Cada uno de nuestros socios publicitarios tiene su propia Política de privacidad para sus políticas sobre los datos del usuario. </font><font style="vertical-align: inherit;">Para facilitar el acceso, hacemos un hipervínculo a sus políticas de privacidad a continuación.</font></font></p>

                    <ul>
                        <li>
                            <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Google</font></font></p>
                            <p><a href="https://policies.google.com/technologies/ads"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">https://policies.google.com/technologies/ads</font></font></a></p>
                        </li>
                    </ul>

                    <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Políticas de privacidad</font></font></strong></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Puede consultar esta lista para encontrar la Política de privacidad de cada uno de los socios publicitarios de </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">PAPRISA</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> del </font><span class="highlight preview_website_name"><font style="vertical-align: inherit;">smie.jivabe.com</font></span><font style="vertical-align: inherit;"> .</font></font></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Los servidores de anuncios o redes de anuncios de terceros utilizan tecnologías como cookies, JavaScript o balizas web que se utilizan en sus anuncios respectivos y enlaces que aparecen en el </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> , que se envían directamente al navegador de los usuarios. </font><font style="vertical-align: inherit;">Reciben automáticamente su dirección IP cuando esto ocurre. </font><font style="vertical-align: inherit;">Estas tecnologías se utilizan para medir la efectividad de sus campañas publicitarias y / o para personalizar el contenido publicitario que usted ve en los sitios web que visita.</font></font></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Tenga en cuenta que el </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> no tiene acceso ni control sobre estas cookies que utilizan los anunciantes de terceros.</font></font></p>

                    <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Políticas de privacidad de la tercera parte</font></font></strong></p>

                    <p><span class="highlight preview_website_name"><font style="vertical-align: inherit;"></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">La Política de privacidad del </font><span class="highlight preview_website_name"><font style="vertical-align: inherit;">SMIE</font></span><font style="vertical-align: inherit;"> no se aplica a otros anunciantes o sitios web. </font><font style="vertical-align: inherit;">Por lo tanto, le recomendamos que consulte las Políticas de privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada. </font><font style="vertical-align: inherit;">Puede incluir sus prácticas e instrucciones sobre cómo optar por no participar en ciertas opciones. </font><font style="vertical-align: inherit;">Puede encontrar una lista completa de estas Políticas de privacidad y sus enlaces aquí: Enlaces de la Política de privacidad.</font></font></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Puede elegir deshabilitar las cookies a través de las opciones de su navegador individual. </font><font style="vertical-align: inherit;">Para obtener información más detallada sobre la administración de cookies con navegadores web específicos, puede encontrarla en los respectivos sitios web de los navegadores. </font><font style="vertical-align: inherit;">¿Qué son las cookies?</font></font></p>

                    <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Información de los niños</font></font></strong></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Otra parte de nuestra prioridad es agregar protección para los niños mientras usan Internet. </font><font style="vertical-align: inherit;">Alentamos a los padres y tutores a observar, participar y / o monitorear y guiar su actividad en línea.</font></font></p>

                    <p><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">El SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> no recopila a sabiendas información de identificación personal de niños menores de 13 años. Si cree que su hijo proporcionó este tipo de información en nuestro sitio web, le recomendamos encarecidamente que se ponga en contacto con nosotros de inmediato y haremos todo lo posible para hacerlo de inmediato. eliminar dicha información de nuestros registros.</font></font></p>

                    <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Política de privacidad en línea solamente</font></font></strong></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Esta política de privacidad se aplica solo a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que compartieron y / o recopilaron en </font></font><span class="highlight preview_website_name"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">SMIE</font></font></span><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> . </font><font style="vertical-align: inherit;">Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales que no sean este sitio web.</font></font></p>

                    <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Consentimiento</font></font></strong></p>

                    <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y acepta sus Términos y condiciones.</font></font></p>

                </div>
            </article>
        </div>
    </div>
</div>