import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Campana } from 'src/app/models/campana';
import { Catalogo } from 'src/app/models/catalogo';
import { ListaCampana } from 'src/app/models/lista-campana';
import { Pagination } from 'src/app/models/pagination';
import { GuardaryenviarcampanaService } from 'src/app/services/guardaryenviarcampana.service';
import { ListaCampanasService } from 'src/app/services/lista-campanas.service';
import Swal from 'sweetalert2';
import { ModalListaCampanaComponent } from '../modal-lista-campana/modal-lista-campana.component';
import * as usuariosActions from 'src/app/store/actions';
import { Identity } from '../../../../models/user';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthState } from '../../../../store/reducers/auth.reducer';
import { ExcelService } from '../../../../services/excel.service';

@Component({
  selector: 'app-guardar-y-enviar-campana',
  templateUrl: './guardar-y-enviar-campana.component.html',
  styleUrls: ['./guardar-y-enviar-campana.component.scss']
})
export class GuardarYEnviarCampanaComponent implements OnInit, OnDestroy {
  public campanaguardada: Campana = new Campana(null);
  public listSearch: string[] = [];
  public filterActive = false;
  public paginacion: Pagination = new Pagination(null);
  public correos: any[] = [];
  public enviando: boolean = false;
  htmlContent = '';
  config: { removeButtons: string; removePlugins: string; readOnly: boolean }
  public listaCampana: ListaCampana = new ListaCampana();
  public total = 0;
  public enviandoCamapana = false;
  public identity: Identity = new Identity();
  private subcriptionIdentity: Subscription = new Subscription();
  public download = false;
  public descargando = false;
  public sesquota: number;

  constructor(private guardaryenviarsservices: GuardaryenviarcampanaService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private listaCampanasService: ListaCampanasService,
    private store: Store<AppState>,
    private excelService: ExcelService
  ) {
    this.config = {
      removeButtons: 'Source,Save,NewPage,ExportPdf,Print,Preview,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,TextField,Radio,Textarea,Select,Button,ImageButton,HiddenField,Bold,CopyFormatting,NumberedList,Outdent,JustifyLeft,BidiLtr,Blockquote,BidiRtl,JustifyCenter,CreateDiv,Indent,BulletedList,RemoveFormat,Italic,Strike,Underline,Subscript,Superscript,JustifyBlock,Language,JustifyRight,Anchor,Unlink,Link,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,Format,Styles,TextColor,BGColor,ShowBlocks,Maximize,About',
      removePlugins: 'codemirror',
      readOnly: true
    };
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = { ...state.identity };
      }
    );
    // this.route.params.subscribe(params => {
    //   const id = params.id;
    //   this.guardaryenviarsservices.obtenerCampanaGuardada(id).subscribe(respuesta => {
    //     this.campanaguardada = respuesta.campanaguardada;
    //   });

    //   console.log(this.campanaguardada);
    // });
    this.route.params.subscribe(params => {
      const id = params.id;
      this.guardaryenviarsservices.obtenerCampanaGuardada(id).subscribe(respuesta => {
        this.campanaguardada = respuesta.campanaguardada;
        this.sesquota = respuesta.sesquota;
        if (this.campanaguardada.estatus === 1 && this.campanaguardada.activo == true) {
          this.listaCampanasService.obtenerLista(id).subscribe(
            (respuesta2) => {
              if (respuesta2 && respuesta2.listaCampana && respuesta2.listaCampana.id) {
                this.listaCampana = respuesta2.listaCampana;
              } else {
                this.listaCampana.campana = id;
              }
            });
          this.listaCampanasService.obtenerListaContactosTotal(this.campanaguardada.id).subscribe(
            (respuesta3) => {
              this.total = respuesta3.total;
            });
        } else {
          this.router.navigate(['/auth/marketing/allcampanas']);
          Swal.fire(
            'Error',
            'Esta campaña no puede acceder a esta sección.',
            'error'
          )
        };
      });

      // this.guardaryenviarsservices.obtenerCorreos().subscribe(respuesta => {
      //   this.correos = respuesta.datosEmail;
      // });
    });

  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }
  enviarprueba(campanaguardada) {
    let correos = campanaguardada.prueba.split(/, |,| /);
    if (this.sesquota <= correos) {
      Swal.fire(
        'Cancelado',
        'No tienes suficientes e-mails disponibles.',
        'error'
      )
    } else {
      this.enviando = true;
      this.guardaryenviarsservices.pruebaenviarCampana(campanaguardada.id, campanaguardada.prueba).subscribe(
        (respuesta: any) => {
          this.toastr.success('Prueba de Campaña enviada.');
          this.enviando = false;
        }, err => {
          this.enviando = false;
        });
    }
  }
  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter(item => item !== text);
    this.paginacion.textSearch = this.listSearch.join(' ');
  }
  printXLS() {
    // const hoy = new Date();
    // const i = hoy.toLocaleDateString().replace('/', '_');
    // const pipeAnyScope = new AnyScopePipe();
    // let lista = [...this.campanas];
    // lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    // if (lista.length) {
    //   lista = lista.map( itemLista => {
    //     const obj: any = {};
    //     if (itemLista.fecRegistro) {
    //       itemLista.fecRegistro = new Date(itemLista.fecRegistro);
    //     } else {
    //       itemLista.fecRegistro = null;
    //     }
    //     obj.ID = itemLista.id;
    //     obj.TITULO = itemLista.titulo;
    //     obj.DESTINATARIOS = itemLista.destinatarios;
    //     obj.EXPEDIDO = itemLista.expedido;
    //     obj.APERTURAS = itemLista.aperturas;
    //     return obj;
    //   });
    //   this.excelService.exportAsExcelFile(lista, 'Reporte de todas campanias ' + i );
    // }
  }

  guardaryenviar(campanaguardada) {
    if (this.sesquota <= this.total) {
      Swal.fire(
        'Cancelado',
        'No tienes suficientes e-mails disponibles; <br/>' + '<b>'+ 
        'disponibles: ' + this.sesquota + 
        '</b>' + '<br/>' +
        '<b> requeridos: ' + this.total + '</b>.',
        'error'
      )
    } else {
      this.enviandoCamapana = false;
      Swal.fire({
        title: '¿Desea enviar la campaña ' + campanaguardada.tema + '?',
        icon: 'warning',
        text: 'Una vez aceptado, no se podrá cancelar y se mandarán a todos los detinatarios seleccionados.',
        showCancelButton: true,
        confirmButtonText: '!Sí, enviar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.listaCampanasService.obtenerListaContactos(this.campanaguardada.id, this.total).toPromise();
          this.enviandoCamapana = true;
          setTimeout(() => {
            this.router.navigate(['/auth/marketing/allcampanas']);
          }, 1300);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelado',
            'No se ha enviado la campaña',
            'error'
          )
        }
      });
    }



    // this.guardaryenviarsservices.pruebaenviarCampana(campanaguardada.id).subscribe(
    //   (respuesta: any) => {
    //     this.toastr.success('Prueba de Campaña enviada.');
    //   }, err => {
    //     console.log('Ocurrio un error al registrar la campana', err);
    //   }
    // );
  }
  openXl(content) {
    this.modalService.open(content, { windowClass: 'correos-lighbox', backdropClass: 'dark-backdrop' });

  }


  infoprobaremail() {
    console.log('');
  }

  cargarListaCampana() {
    this.listaCampanasService.obtenerLista(this.campanaguardada.id).subscribe(
      (respuesta) => {
        this.listaCampana = respuesta.listaCampana;
      });
    this.listaCampanasService.obtenerListaContactosTotal(this.campanaguardada.id).subscribe(
      (respuesta) => {
        this.total = respuesta.total;
      });
  }

  open() {
    const modalRef = this.modalService.open(ModalListaCampanaComponent, { windowClass: 'correos-lighbox', backdropClass: 'dark-backdrop' });
    modalRef.componentInstance.idCampana = this.campanaguardada.id;
    // modalRef.close( () => {
    //   this.cargarListaCampana();
    // });
    modalRef.result.then((result) => {
      console.log('cerro', result);
      this.cargarListaCampana();
    }).catch(() => {
      console.log('dissmiss modal');
    });
  }

  unirNombre(items: Catalogo[]) {
    return items.map(it => it.nombre).join(', ');
  }

  descargarDestinatarios() {
    this.descargando = true;
    this.listaCampanasService.descargarDestinatarios(this.campanaguardada.id).subscribe(respuesta => {
      this.excelService.exportAsExcelFile(respuesta.contactos, 'destinatarios ' + this.campanaguardada.tema);
      this.descargando = false;
    }, error => {
      this.descargando = false;
    });
  }

}
