import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
  name: 'dateWithoutTimeZone'
})
export class DateWithoutTimeZonePipe implements PipeTransform {
	public datePipe = new DatePipe("en-US");


  transform(date: any, split = '-' ): any {
    if (date) {
      let year: number;
      let month: number;
      let day: number;
			if(typeof(date) === 'string'){
        let data = date.split(split);
        year  = Number(data[0]);
        month = Number(data[1]);
        day   = Number(data[2].substr(0,2));
        if(!(Number(data[0]) > 31)){
          year  = Number(data[2].substr(0,4));
          month = Number(data[1]);
          day   = Number(data[0]);
        }
			}else if(typeof(date) === 'object'){
        year  = Number(this.datePipe.transform(date, 'yyyy'));
        month = Number(this.datePipe.transform(date, 'MM'));
        day   = Number(this.datePipe.transform(date, 'dd'));
        if(!(Number(Number(this.datePipe.transform(date, 'yyyy'))) > 31)){
          day   = Number(this.datePipe.transform(date, 'yyyy'));
          month = Number(this.datePipe.transform(date, 'MM'));
          year  = Number(this.datePipe.transform(date, 'dd'));
        }
      }
      return new Date( year, month - 1, day);
    } else {
      return;
    }
  }

}
