import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, Permisos, UserType, PermisoEdit, Suscripciones } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { Location } from '@angular/common';
import { catalogosAcciones } from '../../../../store/actions/catalogos.actions';

@Component({
  selector: 'app-usuarios-config',
  templateUrl: './usuarios-config.component.html',
  styleUrls: ['./usuarios-config.component.sass']
})
export class UsuariosConfigComponent implements OnInit, OnDestroy {
  public identity: Identity;
  public title: string;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeTipos: Subscription = new Subscription();
  private subscribeOpciones: Subscription = new Subscription();
  public tipos: UserType[] = [];
  public loading = false;
  public url: string;
  public permisos: Permisos = new Permisos(null);
  public tipoSelected: UserType = new UserType(null);
  public suscripciones: Suscripciones[] = [];
  public todo = false;
  public todoSuscripciones = false;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private locate: Location
  ) {
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.tipos = [];
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscribeTipos = this.store.select('catalogos').subscribe(
      (state) => {
        this.tipos = state.tipos;
        this.permisos = state.permisos;
        this.tipoSelected = state.tipo;
        this.suscripciones = state.suscripciones;
        this.validarTodo();
        this.validarTodoSuscripciones();
      }
    );
    this.title = 'Configuración de usuario';
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeTipos.unsubscribe();
    this.subscribeOpciones.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.LoadTypeUsers());
    this.store.dispatch(new usuariosActions.LoadSuscripciones(new UserType({id: 0, nombre: '', checked: false})));
    setTimeout( () => {
      if (this.identity.tipo !== 1) {
        this.locate.back();
      }
    }, 5);
  }

  show(tipoUser: UserType) {
    if (tipoUser.id > 0) {
      this.store.dispatch(new usuariosActions.LoadPermisos(tipoUser));
      this.store.dispatch(new usuariosActions.LoadSuscripciones(tipoUser));
    }
  }

  registrarPermisos() {
    if (this.tipoSelected.nombre && this.tipoSelected.nombre !== '') {
      if (!this.tipoSelected.id) {
        this.tipoSelected.id = 0;
      }
      this.store.dispatch(new usuariosActions.SavePermisos({...this.tipoSelected}, {...this.permisos}, [...this.suscripciones]));
    } else {
      this.toastr.warning('Captura el nombre del perfil');
    }
  }

  cancelar() {
    this.tipoSelected = new UserType(null);
    this.permisos = new Permisos(null);
  }

  nuevo() {
    this.todo = false;
    this.todoSuscripciones = false;
    this.permisos = new Permisos(null);
    this.suscripciones = this.suscripciones.map( item => {
      item.checked = false;
      return item;
    });
    this.tipoSelected = new UserType(null);
  }

  seleccionarTodo() {
    if (this.todo) {
      // tslint:disable-next-line:forin
      for (const field in this.permisos) {
        this.permisos[field] = true;
      }
    } else {
      // tslint:disable-next-line:forin
      for (const field in this.permisos) {
        this.permisos[field] = false;
      }
      this.permisos.archivos = true;
      this.permisos.actividades = true;
      this.permisos.catIndustrias = true;
      // this.permisos.catTipoEmpresas = true;
      this.permisos.contactos = true;
      this.permisos.empresas = true;
      this.permisos.oportunidades = true;
      this.permisos.repositorios = true;
      this.permisos.usuarios = true;
      this.permisos.actividades = true;
      this.permisos.catOrigenes = true;
      this.permisos.catDepartamentos = true;
    }
  }

  validarTodo() {
    setTimeout( () => {
      // tslint:disable-next-line:forin
      for (const field in this.permisos) {
        if (field.search('Edit')) {
          if (this.permisos[field]) {
            const atributo = field.substr(0, field.length - 4) + 'Ver';
            if (atributo in this.permisos) {
              this.permisos[atributo] = true;
            }
          }
        }
      }
      const elements = Object.keys(this.permisos);
      this.todo = elements.every( (key) => this.permisos[key]);
    }, 1);
  }

  seleccionarTodoSuscripciones() {
    if (this.todoSuscripciones) {
      this.suscripciones.map( item => {
        item.checked = true;
      });
    } else {
      this.suscripciones.map( item => {
        item.checked = false;
      });
    }
  }

  validarTodoSuscripciones() {
    this.todoSuscripciones = this.suscripciones.every( (item) => item.checked);
  }

  activarMarketing(){
    this.permisos.web = true;
    this.permisos.campannasEdit = true;
    this.permisos.campannasVer = true;
    this.permisos.plantillasEdit = true;
    this.permisos.plantillasVer = true;
    this.permisos.rptSepomex = true;
    this.permisos.rptAutoActualizacion = true;
    this.permisos.rptCampanas = true;
    this.suscripciones = this.suscripciones.map( item => {
      if (item.id === 9) {
        item.checked = true;
      }
      return item;
    });
  }
  activarECO(){
    this.permisos.web = true;
    this.permisos.contactos = true;
    this.permisos.contactosListEdit = true;
    this.permisos.contactosListVer = true;
    this.permisos.contactosRegEdit = true;
    this.permisos.contactosRegVer = true;
    this.permisos.empresas = true;
    this.permisos.empresasListEdit = true;
    this.permisos.empresasListVer = true;
    this.permisos.empresasRegEdit = true;
    this.permisos.empresasRegVer = true;
    this.permisos.oportunidades = true;
    this.permisos.oportunidadesListEdit = true;
    this.permisos.oportunidadesListVer = true;
    this.permisos.oportunidadesRegEdit = true;
    this.permisos.oportunidadesRegVer = true;
    this.permisos.actividades = true;
    this.permisos.actividadesListEdit = true;
    this.permisos.actividadesListVer = true;
    this.permisos.actividadesRegEdit = true;
    this.permisos.actividadesRegVer = true;
    this.permisos.rptCumpleannos = true;
    this.permisos.rptOportunidadEtapas = true;
    this.permisos.rptOportunidadPropietarios = true;
    this.permisos.rptKpiVendedores = true;
    this.suscripciones = this.suscripciones.map( item => {
      if (item.id === 4 || item.id === 5 || item.id === 6) {
        item.checked = true;
      }
      return item;
    });
  }
  activarReportes(){
    this.permisos.web = true;
    this.permisos.rptCumpleannos = true;
    this.permisos.rptOportunidadEtapas = true;
    this.permisos.rptOportunidadPropietarios = true;
    this.permisos.rptKpiVendedores = true;
    this.permisos.rptSepomex = true;
    this.permisos.rptAutoActualizacion = true;
    this.permisos.rptCampanas = true;
  }
  activarArchivos(){
    this.permisos.web = true;
    this.permisos.archivos = true;
    this.permisos.archivosListEdit = true;
    this.permisos.archivosListVer = true;
    this.permisos.archivosRegEdit = true;
    this.permisos.archivosRegVer = true;
    this.permisos.repositorios = true;
    this.permisos.repositoriosListEdit = true;
    this.permisos.repositoriosListVer = true;
    this.permisos.repositoriosRegEdit = true;
    this.permisos.repositoriosRegVer = true;
    this.suscripciones = this.suscripciones.map( item => {
      if (item.id === 7 || item.id === 8) {
        item.checked = true;
      }
      return item;
    });
  }
  activarTickets(){
    this.permisos.web = true;
    this.permisos.ticketsEdit = true;
    this.permisos.ticketsVer = true;
    this.suscripciones = this.suscripciones.map( item => {
      if (item.id === 10) {
        item.checked = true;
      }
      return item;
    });
  }
  activarCatalogos(){
    this.permisos.web = true;
    this.permisos.catDatosFiscales = true;
    this.permisos.catDepartamentos = true;
    this.permisos.catDepartamentosEdit = true;
    this.permisos.catDepartamentosVer = true;
    this.permisos.catIndustrias = true;
    this.permisos.catIndustriasEdit = true;
    this.permisos.catIndustriasVer = true;
    this.permisos.catOrigenes = true;
    this.permisos.catOrigenesEdit = true;
    this.permisos.catOrigenesVer = true;
    this.permisos.catPaises = true;
    this.permisos.catPaisesEdit = true;
    this.permisos.catPaisesVer = true;
    // this.permisos.catTipoEmpresas = true;
    // this.permisos.catTipoEmpresasEdit = true;
    // this.permisos.catTipoEmpresasVer = true;
    this.suscripciones = this.suscripciones.map( item => {
      if (item.id === 3) {
        item.checked = true;
      }
      return item;
    });
  }

}
