export class Notificacion {
  public id: number;
  public usuario: number;
  public desUsuario: string;
  public tipoUsuario: number;
  public desTipoUsuario: string;
  public tipo: TipoNotificacion;
  public desTipo: string;
  public descripcion: string;
  public razon: string;
  public fecha: Date;
  public leido: boolean;
  constructor(
    obj: ObjNotificacion
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.usuario = obj && obj.usuario ? obj.usuario : 0;
    this.desUsuario = obj && obj.desUsuario ? obj.desUsuario : '';
    this.tipo = obj && obj.tipo ? obj.tipo : 0;
    this.desTipo = obj && obj.desTipo ? obj.desTipo : '';
    this.tipoUsuario = obj && obj.tipoUsuario ? obj.tipoUsuario : 0;
    this.desTipoUsuario = obj && obj.desTipoUsuario ? obj.desTipoUsuario : '';
    this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
    this.razon = obj && obj.razon ? obj.razon : '';
    this.fecha = obj && obj.fecha ? obj.fecha : undefined;
    this.leido = obj && obj.leido ? obj.leido : false;
  }
}

interface ObjNotificacion {
  id: number;
  usuario: number;
  desUsuario: string;
  tipoUsuario: number;
  desTipoUsuario: string;
  tipo: TipoNotificacion;
  desTipo: string;
  descripcion: string;
  razon: string;
  fecha: Date;
  leido: boolean;
}

export enum TipoNotificacion {
  USUARIOS = 1,
  SESION = 2,
}

export class Resumen {
  constructor(
    public usuarios: number,
    public prospectos: number
  ) {}
}

export class LineaChart {
  constructor(
    public label: string,
    public data: any[],
    public labelChart: any[]
  ) {}
}

export class OpcionNotificacion {
  public id: TipoNotificacion;
  public nombre: string;
  public checked: boolean;
  constructor(
    obj: ObjOpcionNotificacion
  ) {
    this.id = obj && obj.id ? obj.id : 1;
    this.nombre = obj && obj.nombre ? obj.nombre : 'Cotizaciones';
    this.checked = obj && obj.checked ? obj.checked : false;
  }
}

interface ObjOpcionNotificacion {
  id: TipoNotificacion;
  nombre: string;
  checked: boolean;
}

export class OpcionesNotificacion {
  public static OPCIONES_NOTIFICACIONES = [
    new OpcionNotificacion({id: TipoNotificacion.USUARIOS, nombre: 'Usuarios', checked: false}),
    new OpcionNotificacion({id: TipoNotificacion.SESION, nombre: 'Secciones', checked: false})
  ];
}
