import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { OportunidadesService } from 'src/app/services/oportunidades.service';

@Injectable()
export class OportunidadEffects {

  constructor(
    private actions$: Actions,
    public oportunidadesService: OportunidadesService
  ) {}

  @Effect()
  cargarOportunidades$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_OPORTUNIDADES ),
    switchMap( () => {
      return this.oportunidadesService.obtenerOportunidades()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarOportunidadesSuccess(respuesta.oportunidades);
          }),
          catchError( error => of(new usuariosActions.CargarOportunidadesFail(error))  )
        );
    })
  );

  @Effect()
  cargarOportunidad$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_OPORTUNIDAD ),
    switchMap( (action: any) => {
      return this.oportunidadesService.obtenerDatoOportunidad(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarOportunidadSuccess(respuesta.oportunidad);
          }),
          catchError( error => of(new usuariosActions.CargarOportunidadFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusOportunidad$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_OPORTUNIDAD_ESTATUS ),
    switchMap( (action: any) => {
      return this.oportunidadesService.actualizaEstatusOportunidad(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaOportunidadEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaOportunidadEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaOportunidad$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_OPORTUNIDAD ),
    switchMap( (action: any) => {
      return this.oportunidadesService.registraOportunidad(action.oportunidad)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaOportunidadSuccess(action.oportunidad);
            }),
            catchError( error => of(new usuariosActions.ActualizaOportunidadFail(error))  )
        );
    })
  );

}
