<div class="row">
  <div class="col-md-5">
    <div class="main-card mb-2.5 card">
      <form id="nuevaCampanaForm" name="nuevaCampanaForm" #nuevaCampanaForm="ngForm">
        <div class="card-body">
          <h5 class="card-title">Nueva Campaña</h5>
          <div class="position-relative form-group required">
            <label class="control-label" for="temaCampana">Asunto</label>
            <input name="temaCampana" id="temaCampana" #temaCampana="ngModel" placeholder="Asunto de la campaña"
              type="text" class="form-control" (focusout)="existeCampana()" [(ngModel)]="campana.tema" required />
            <p *ngif="!nombreValido && temaCampana.touched">El asunto ya existe</p>
          </div>
          <div class="position-relative form-group required">
            <label class="control-label" for="nombre_emisor">Nombre emisor</label>
            <input name="nombre_emisor" id="nombreemisor" placeholder="Nombre del emisor" type="text"
              class="form-control" [(ngModel)]="campana.nombre_emisor" required />
          </div>
          <div class="position-relative form-group required">
            <label class="control-label" for="correo_emisor">Desde el e-mail</label>
            <div class="input-group mb-2">
              <input name="correo_emisor" id="correo_emisor" placeholder="ej: nombre-email" onPaste="return false"
                onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 45 && event.charCode <= 46) || (event.charCode >= 95 && event.charCode <= 95))"
                type="text" class="form-control" [(ngModel)]="campana.correo_emisor" required />
              <div class="input-group-prepend">
                <select *ngIf="dns.length > 1" name="dnsMarketing" id="dnsMarketing" #dnsMarketing="ngModel"
                  [(ngModel)]="campana.dns" class="input-group-text" required>
                  <option value="">Selecciona un dominio</option>
                  <option *ngFor="let itm of dns" [value]="itm" required>{{itm}}</option>
                </select>
                <div class="input-group-text" *ngIf="dns.length === 1">{{dns[0]}}</div>
              </div>
            </div>
          </div>
          <div class="position-relative form-group required">
            <label class="control-label" for="correo_respuesta">E-mail de respuesta</label>
            <div class="input-group mb-2">
              <input name="correo_respuesta" id="correo_respuesta" placeholder="ej: reply@empresa.com" type="input"
                class="form-control"
                onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 45 && event.charCode <= 46) || (event.charCode >= 95 && event.charCode <= 95))"
                onPaste="return false" [(ngModel)]="campana.correo_respuesta" />
              <div class="input-group-prepend">
                <select *ngIf="dns.length > 1" name="dnsMarketingRes" id="dnsMarketingRes" #dnsMarketing="ngModel"
                  [(ngModel)]="campana.dns" class="input-group-text" disabled>
                  <option value=""></option>
                  <option *ngFor="let itm of dns" [value]="itm" required>{{itm}}</option>
                </select>
                <div class="input-group-text" *ngIf="dns.length === 1">{{dns[0]}}</div>
              </div>
            </div>
          </div>
          <div class="position-relative form-group ">
            <label class="control-label" for="texto_plano">Versión de texto sin formato</label>
            <textarea name="texto_plano" id="texto_plano"
              placeholder="Versión en texto plano de este email" class="form-control" [(ngModel)]="campana.texto_plano">
            </textarea>
          </div>
          <!-- <ng-template #popContent>Opcionalmente, agregue una cadena de consulta a todos los enlaces en su boletín de correo electrónico. Un buen caso de uso es el seguimiento de Google Analytics.<br/> <strong>No incluya '?' en su cadena de consulta.</strong><br><img src="https://lh3.googleusercontent.com/proxy/wc6qE-lkew4TCoD_I9Io-tv_tQZxfbqqIajdwFvXer-bmDWoe2DZpPdyqOI6tDPNMrvdMH4m3RBAzqyXaHfvkTDG3ctL02EX3vG4u6yY8flDYjXNe1ZgCau7HJY6MzKCaJgbXw" alt="Query string"  width=250px/> </ng-template>
          <ng-template #popTitle><strong>Cadena de consulta</strong></ng-template>
          <div class="position-relative form-group"><label for="analytics">Cadena de consulta <a><i type="button" class="fas fa-info-circle" placement="right" [ngbPopover]="popContent" [popoverTitle]="popTitle"></i></a></label><input name="analytics" id="analytics" placeholder="p.ej.  utm_source = newsletter & utm_medium = sendy & utm_campaign = email_marketing" type="text" class="form-control" [(ngModel)]="campana.cadenaconsulta"></div> -->
          <!-- <div class="position-relative form-group"><label for="exampleFile">Archivos Adjuntos</label><input name="file" id="archivosad" type="file" class="form-control-file" [(ngModel)]="campana.archivo"></div> -->
          <!-- <small class="form-text text-muted">Archivos permitidos: jpeg, jgp, gif, png, pdf, zip.</small> -->
          <div class="position-relative form-group"><label for="seguimiento_aperturas"><br>Seguimiento al abrir
              correos*</label></div>
          <div class="btn-group-sm btn-group btn-group-toggle" ngbRadioGroup name="seguimiento_aperturas"
            [(ngModel)]="campana.seguimiento_aperturas">
            <bSwitch id="seguimiento_aperturas" name="seguimiento_aperturas" [(ngModel)]="campana.seguimiento_aperturas"
              [switch-size]="'normal'" [switch-on-text]="'Si'" [switch-off-text]="'No'" [switch-on-color]="'primary'"
              [switch-off-color]="'danger'">
            </bSwitch>
          </div>
          <div class="position-relative form-group"><label for="seguimiento_links"><br>Seguimiento al dar click dentro
              del correo</label></div>
          <div class="btn-group-sm btn-group btn-group-toggle" ngbRadioGroup name="seguimiento_links"
            [(ngModel)]="campana.seguimiento_links">
            <bSwitch id="seguimiento_links" name="seguimiento_links" [(ngModel)]="campana.seguimiento_links"
              [switch-size]="'normal'" [switch-on-text]="'Si'" [switch-off-text]="'No'" [switch-on-color]="'primary'"
              [switch-off-color]="'danger'">
            </bSwitch>
          </div>
          <div class="position-relative form-group"><label for="guardarplantilla"><br>Guardar html como
              plantilla</label></div>
          <div class="btn-group-sm btn-group btn-group-toggle" ngbRadioGroup name="guardarplantilla">
            <bSwitch id="guardarplantilla" name="guardarplantilla" [(ngModel)]="campana.guardarhtml"
              [switch-size]="'normal'" [switch-on-text]="'Si'" [switch-off-text]="'No'" [switch-on-color]="'primary'"
              [switch-off-color]="'danger'">
            </bSwitch>
          </div>
        </div>
        <div class="grid-menu grid-menu-2col border-top">
          <div class="no-gutters row">
            <div class="col-sm-6" *ngIf="!loading">
              <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary" (click)="guardar()"
                [disabled]="!nuevaCampanaForm.valid || !nombreValido || !campana.texto_html">
                <i class="fas fa-save btn-icon-wrapper"></i> Guardar
              </button>
            </div>
            <div class="col-sm-6" *ngIf="!loading">
              <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
                (click)="guardarysiguiente()" [disabled]="!nuevaCampanaForm.valid || !nombreValido || !campana.texto_html">
                <i class="fa fa-chevron-right btn-icon-wrapper"></i> Guardar y siguiente
              </button>
              <!-- <button class="mt-1 btn btn-success" type="button" (click)="guardar()"><i class="fas fa-save"></i> Guardar </button>
            <button class="mt-1 ml-1 btn btn-focus" (click)="guardarysiguiente()"> Siguiente <i class="fa fa-chevron-right"></i></button> -->
            </div>
            <div class="col-sm-12" *ngIf="loading">
              <button type="button" class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary"
                disabled>
                <i class="fa fa-spinner fa-pulse btn-icon-wrapper"></i> Registrando...
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-7">
    <div class="card main-card mb-3.5">
      <div class="card-body">
        <div class=" position-relative form-group "><label for="exampleSelect "><i class="fas fa-clone "></i> Selecciona
            una plantilla</label>
          <select name="select" aria-placeholder="selecciona una plantilla" id="seleccionplantilla "
            class="form-control " [(ngModel)]="campana.texto_html">
            <option *ngFor="let plantilla of plantillas" [ngValue]=plantilla.texto_html>{{plantilla.tema}}</option>
          </select>
        </div>
        <ckeditor [(ngModel)]="campana.texto_html" style="height: 670px;" required></ckeditor>
        <div class="divider "></div>
        <div><br />
          <div class="row">
            <div class="col">
              <b>Etiquetas esenciales:</b><br />
              <br />
              <p>
                <strong>Enlace para darse de baja:</strong><br />
                <code>[desuscribe]</code>
              </p>
            </div>
            <div class="col">
              <b>Etiquetas de personalización:</b><br />
              <br />
              <p>
                <strong>Nombre:</strong><br />
                <code>[nombre]</code>
              </p>
              <p>
                <strong>Apellido:</strong><br />
                <code>[apellido]</code>
              </p>
              <p>
                <strong>Email:</strong><br />
                <code>[email]</code>
              </p>
              <p>
                <strong>Teléfono:</strong><br />
                <code>[telefono]</code>
              </p>
              <p>
                <strong>Celular:</strong><br />
                <code>[celular]</code>
              </p>
              <p>
                <strong>Empresa a la que pertenece:</strong><br />
                <code>[empresa]</code>
              </p>
              <p>
                <strong>Fundación a la que pertenece:</strong><br />
                <code>[fundacion]</code>
              </p>
              <p>
                <strong>Cargo empresarial:</strong><br />
                <code>[cargo]</code>
              </p>
              <p>
                <strong>País de recidencia:</strong><br />
                <code>[pais]</code>
              </p>
              <p>
                <strong>Estado de recidencia:</strong><br />
                <code>[estado]</code>
              </p>
              <p>
                <strong>Ciudad de recidencia:</strong><br />
                <code>[ciudad]</code>
              </p>
              <p>
                <strong>Cumpleaños:</strong><br />
                <code>[cumpleanios]</code>
              </p>
              <p>
                <strong>Aniversario:</strong><br />
                <code>[aniversario]</code>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>