import { Action } from '@ngrx/store';
import { User } from 'src/app/models/user';

export const CARGAR_USUARIOS = '[Usuario] Cargar usuarios';
export const CARGAR_USUARIOS_FAIL = '[Usuario] Cargar usuarios FAIL';
export const CARGAR_USUARIOS_SUCCESS = '[Usuario] Cargar usuarios SUCCESS';
export const ELIMINAR_USUARIOS = '[Usuario] Eliminar usuarios';
export const ELIMINAR_USUARIOS_SUCCESS = '[Usuario] Eliminar usuarios SUCCESS';
export const ELIMINAR_USUARIOS_FAIL = '[Usuario] Eliminar usuarios FAIL';

export class CargarUsuarios implements Action {
    readonly type = CARGAR_USUARIOS;
}

export class CargarUsuariosFail implements Action {
    readonly type = CARGAR_USUARIOS_FAIL;

    constructor( public payload: any ) {}
}

export class CargarUsuariosSuccess implements Action {
    readonly type = CARGAR_USUARIOS_SUCCESS;

    constructor( public usuarios: User[] ) {}
}

export class EliminarUsuarios implements Action {
    readonly type = ELIMINAR_USUARIOS;

    constructor( public id: number ) { }
}

export class EliminarUsuariosSuccess implements Action {
    readonly type = ELIMINAR_USUARIOS_SUCCESS;

    constructor( public id: number ) {}
}

export class EliminarUsuariosFail implements Action {
  readonly type = ELIMINAR_USUARIOS_FAIL;

  constructor( public payload: any ) {}
}

export type usuariosAcciones  = CargarUsuarios |
                               CargarUsuariosFail |
                               CargarUsuariosSuccess |
                               EliminarUsuarios |
                               EliminarUsuariosSuccess |
                               EliminarUsuariosFail;
