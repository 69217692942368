<div class="mb-3 card" (dblclick)="routerUrl()" [style.background-color]="colorRight" >
	<div class="card-body p-0">
		<div class="row">
			<div class="p-3 ml-3 left col-auto " [style.background-color]="colorLeft" [style.width]="ancho" [style.height]="largo">
				<i class="icon {{icon}} font-size-10" *ngIf="!imagen"></i>
				<img [src]="src" *ngIf="imagen" >
			</div>
			<div class="mt-3 ml-3 col container">
				<div style="height: 117px; overflow: hidden; overflow-y: auto;">
					<h4 [style.color]="colorText" class="text-uppercase">{{title}}</h4>
					<p class="font-weight-light" [style.color]="colorText">{{subTitle}}</p>
					<footer class="blockquote-footer" [style.color]="colorText" *ngIf="footer">{{footer}}</footer>
				</div>
				<div class="row">
					<div class="mt-auto mr-4">
            <a [routerLink]="'/auth/'+ url" [routerLinkActive]="" class=" btn btn-link">VER</a> 
					</div> 	
				</div>
			</div>
		</div>
	</div>
</div>

