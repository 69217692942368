import { Action } from '@ngrx/store';
import { UserType, Permisos, PermisoEdit, Suscripciones } from 'src/app/models/user';

export const LOAD_TYPE_USERS = '[Catalogos] Load type users';
export const LOAD_TYPE_USERS_SUCCESS = '[Catalogos] Load type users success';
export const LOAD_TYPE_USERS_FAIL = '[Catalogos] Load type users fail';
export const LOAD_PERMISOS = '[Catalogos] Load permisos';
export const LOAD_PERMISOS_SUCCESS = '[Catalogos] Load permisos success';
export const LOAD_PERMISOS_FAIL = '[Catalogos] Load permisos fail';
export const SAVE_PERMISOS = '[Catalogos] Save permisos';
export const SAVE_PERMISOS_SUCCESS = '[Catalogos] Save permisos success';
export const SAVE_PERMISOS_FAIL = '[Catalogos] Save permisos fail';
export const LOAD_SUSCRIPCIONES = '[Catalogos] Load suscripciones';
export const LOAD_SUSCRIPCIONES_SUCCESS = '[Catalogos] Load suscripciones success';
export const LOAD_SUSCRIPCIONES_FAIL = '[Catalogos] Load suscripciones fail';
export const SAVE_SUSCRIPCIONES = '[Catalogos] Save suscripciones';
export const SAVE_SUSCRIPCIONES_SUCCESS = '[Catalogos] Save suscripciones success';
export const SAVE_SUSCRIPCIONES_FAIL = '[Catalogos] Save suscripciones fail';

export class LoadTypeUsers implements Action {
    readonly type = LOAD_TYPE_USERS;
}

export class LoadTypeUsersFail implements Action {
    readonly type = LOAD_TYPE_USERS_FAIL;

    constructor( public payload: any ) {}
}

export class LoadTypeUsersSuccess implements Action {
    readonly type = LOAD_TYPE_USERS_SUCCESS;

    constructor(public tipos: UserType[]) {}
}

export class LoadPermisos implements Action {
  readonly type = LOAD_PERMISOS;

  constructor(public tipo: UserType) {}
}

export class LoadPermisosFail implements Action {
  readonly type = LOAD_PERMISOS_FAIL;

  constructor( public payload: any ) {}
}

export class LoadPermisosSuccess implements Action {
  readonly type = LOAD_PERMISOS_SUCCESS;

  constructor(public tipo: UserType, public permisos: Permisos) {}
}

export class SavePermisos implements Action {
  readonly type = SAVE_PERMISOS;

  constructor(public tipo: UserType, public permisos: Permisos, public suscripciones: Suscripciones[]) {}
}

export class SavePermisosFail implements Action {
  readonly type = SAVE_PERMISOS_FAIL;

  constructor( public payload: any ) {}
}

export class SavePermisosSuccess implements Action {
  readonly type = SAVE_PERMISOS_SUCCESS;

  constructor(public tipo: UserType, public permisos: Permisos, public suscripciones: Suscripciones[]) {}
}

export class LoadSuscripciones implements Action {
  readonly type = LOAD_SUSCRIPCIONES;

  constructor(public tipo: UserType) {}
}

export class LoadSuscripcionesFail implements Action {
  readonly type = LOAD_SUSCRIPCIONES_FAIL;

  constructor( public payload: any ) {}
}

export class LoadSuscripcionesSuccess implements Action {
  readonly type = LOAD_SUSCRIPCIONES_SUCCESS;

  constructor(public tipo: UserType, public suscripciones: Suscripciones[]) {}
}

export type catalogosAcciones = LoadTypeUsers |
                                LoadTypeUsersFail |
                                LoadTypeUsersSuccess |
                                LoadPermisos |
                                LoadPermisosFail |
                                LoadPermisosSuccess |
                                SavePermisos |
                                SavePermisosFail |
                                SavePermisosSuccess |
                                LoadSuscripciones |
                                LoadSuscripcionesFail |
                                LoadSuscripcionesSuccess;
