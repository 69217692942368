export class Album {
  public id: number;
  public nombre: string;
  public path: string;
  public thumbnail: string;
  public imagen: string;
  public descripcion: string;
  public cantidad: number;
  public fecRegistro: Date;
  public fecModificacion: Date;
  public usuarioRegistro: number;
  public desUsuarioRegistro: string;
  public usuarioModifico: number;
  public desUsuarioModifico: string;
  constructor(obj: ObjAlbum) {
    this.id = obj && obj.id ? obj.id : null;
    this.nombre = obj && obj.nombre ? obj.nombre : null;
    this.path = obj && obj.path ? obj.path : null;
    this.thumbnail = obj && obj.thumbnail ? obj.thumbnail : null;
    this.imagen = obj && obj.imagen ? obj.imagen : null;
    this.descripcion = obj && obj.descripcion ? obj.descripcion : null;
    this.cantidad = obj && obj.cantidad ? obj.cantidad : null;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
    this.fecModificacion = obj && obj.fecModificacion ? obj.fecModificacion : null;
    this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : null;
    this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : null;
    this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : null;
    this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico : null;
  }
}

interface ObjAlbum {
  id: number;
  nombre: string;
  path: string;
  thumbnail: string;
  imagen: string;
  descripcion: string;
  cantidad: number;
  fecRegistro: Date;
  fecModificacion: Date;
  usuarioRegistro: number;
  desUsuarioRegistro: string;
  usuarioModifico: number;
  desUsuarioModifico: string;
}
