<!-- <app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title> -->
<div class="container" *ngIf="identity.permisos">
  <div class="h4 text-center">
    Prepara tu archivo antes de subirlo
    <div>
      <small class="text-muted">Al cargar la nueva información agregará los contactos como nuevos y si ya existe el email, reemplazará la información de ese contacto con esta, omitiendo los vacíos. <a (click)="ejemploExcel()" title="Descargar ejemplo." class="text-primary">(Ejemplo)</a></small>
    </div>
  </div>
  <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDropFile (fileDropped)="fileChangeEvent($event)">
    <input hidden type="file" multiple #fileInput (change)="fileChangeEvent($event.target.files)" accept=".xls,.xlsx">
    <!-- <div class="uploadfiletext">Drag & Drop spreadsheets(Excel or CSV) or click here</div> -->
    <div class="m-auto text-center content-upload-files">
      <img [src]="'/assets/images/cloud-up.svg' | safe: 'url'" alt="Cloud up files" style="width: 200px; height: auto;" class="img-squad">
      <div class="uploadfiletext">Drag & Drop spreadsheets(Excel or CSV) or click here...</div>
    </div>
  </div>
</div>
<div class="row" *ngIf="identity.permisos && muestra.length">
  <div class="col-md-12">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Información de muestra</div>
        <div class="btn-actions-pane-right mr-4">({{muestra.length}} de {{total}})</div>
      </div>
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let contacto of muestra; let i = index">
            <div class="no-gutters row">
              <div class="col-sm-6 col-md-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-2">#{{i + 1}}</div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{contacto.nombre}} {{contacto.apellido}}</div>
                        <div class="widget-subheading">Empresa: {{contacto.empresa}}</div>
                        <div class="widget-description text-muted">
                          <span>Cargo: {{contacto.cargo}}<br></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading text-secondary"> Email: {{contacto.email}}</div>
                        <div class="widget-subheading">Teléfono: {{contacto.telefono | phone}}</div>
                        <div class="widget-description text-muted">
                          <span>Celular: {{contacto.celular | phone}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="widget-heading text-secondary">Origen: {{contacto.origen}}</div>
                        <div class="widget-subheading">Etapa: {{contacto.etapa}}</div>
                        <div class="widget-description text-focus">
                          <span>Estatus: {{contacto.estatus}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-2" *ngIf="identity.permisos.contactosListEdit">
        <div class="row p-2">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="text-right mr-4">
              <div class="btn-group ml-2">
                <button class="btn-icon btn-wide btn-outline-2x btn btn-outline-warning btn-sm d-flex mb-3 mt-3 mr-3" type="button" (click)="vaciarLista()" *ngIf="contactos.length">
                  <span class="pr-2 align-middle opacity-7">
                    <i class="far fa-trash-alt"></i>
                  </span>
                  Vaciar contactos
                </button>
                <button class="btn-icon btn-wide btn-outline-2x btn btn-outline-success btn-sm d-flex mb-3 mt-3" type="button" (click)="onSubmit()" *ngIf="contactos.length">
                  <span class="pr-2 align-middle opacity-7">
                    <i class="far fa-save"></i>
                  </span>
                  Guardar contactos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>