import * as fromUsuario from '../actions';
import { Industria } from 'src/app/models/industrias';

export interface IndustriaState {
  industrias: Industria[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: IndustriaState = {
  industrias: [],
  loaded: false,
  loading: false,
  error: null
};

export function industriaReducer( state = initState, action: fromUsuario.industriaAcciones ): IndustriaState {

  switch ( action.type ) {
    case fromUsuario.CARGAR_INDUSTRIAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_INDUSTRIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        industrias: [...action.industrias]
      };
    case fromUsuario.CARGAR_INDUSTRIAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.ACTUALIZA_INDUSTRIA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDUSTRIA_SUCCESS:
      const itm = state.industrias.find( item => item.id === action.industria.id);
      if (itm && itm.id) {
        state.industrias = state.industrias.map( item => {
          if (item.id === action.industria.id) {
            item = {...action.industria};
          }
          return item;
        });
      } else {
        state.industrias.push(action.industria);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDUSTRIA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_INDUSTRIA_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDUSTRIA_ESTATUS_SUCCESS:
      state.industrias = state.industrias.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_INDUSTRIA_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
