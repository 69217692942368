
export class CargaContacto{
	public nombre: string;
	public apellido: string;
	public propietario: string;
	public email: string;
	public cargo: string;
	public telefono: string;
	public celular: string;
	public origen: string;
	public etapa: string;
	public estatus: string;
	public opcSepomex: boolean;
	public opcPago: boolean;
	public pais: string;
	public codigoPostal: string;
	public estado: string;
	public municipio: string;
	public colonia: string;
	public calle: string;
	public empresa: string;
	public clase: string;
	public industria: string;
	public clasificacion: string;
	public tipo: string;
	public rfc: string;
	public razonSocial: string;
	public paisDatoFiscal: string;
	public codigoPostalDatoFiscal: string;
	public estadoDatoFiscal: string;
	public municipioDatoFiscal: string;
	public coloniaDatoFiscal: string;
	public calleDatoFiscal: string;
	public cumpleanios: Date;
	public paginaWeb: string;
	public empresaPublicidad: string;
	public fundacion: Date;
	constructor(
		obj: DataObjCargaContacto
	){
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.apellido = obj && obj.apellido ? obj.apellido : '';
		this.propietario = obj && obj.propietario ? obj.propietario : '';
		this.email = obj && obj.email ? obj.email: '';
		this.cargo = obj && obj.cargo ? obj.cargo : '';
		this.telefono = obj && obj.telefono ? obj.telefono: '0';
		this.celular = obj && obj.celular ? obj.celular: '';
		this.origen = obj && obj.origen ? obj.origen: '';
		this.etapa = obj && obj.etapa ? obj.etapa : '';
		this.estatus = obj && obj.estatus ? obj.estatus : '';
		this.opcSepomex = obj && obj.opcSepomex ? obj.opcSepomex : false;
		this.opcPago = obj && obj.opcPago ? obj.opcPago : false;
		this.pais = obj && obj.pais ? obj.pais : '';
		this.codigoPostal = obj && obj.codigoPostal ? obj.codigoPostal: '';
		this.estado = obj && obj.estado ? obj.estado : '';
		this.municipio = obj && obj.municipio ? obj.municipio: '';
		this.colonia = obj && obj.colonia ? obj.colonia : '';
		this.calle = obj && obj.calle ? obj.calle: '';
		this.empresa = obj && obj.empresa ? obj.empresa: '';
		this.clase = obj && obj.clase ? obj.clase : '';
		this.industria = obj && obj.industria ? obj.industria : '';
		this.clasificacion = obj && obj.clasificacion ? obj.clasificacion : '';
		this.tipo = obj && obj.tipo ? obj.tipo : '';
		this.rfc = obj && obj.rfc ? obj.rfc: '';
		this.razonSocial = obj && obj.razonSocial ? obj.razonSocial: '';
		this.paisDatoFiscal = obj && obj.paisDatoFiscal ? obj.paisDatoFiscal: '';
		this.codigoPostalDatoFiscal = obj && obj.codigoPostalDatoFiscal ? obj.codigoPostalDatoFiscal: '';
		this.estadoDatoFiscal = obj && obj.estadoDatoFiscal ? obj.estadoDatoFiscal: '';
		this.municipioDatoFiscal = obj && obj.municipioDatoFiscal ? obj.municipioDatoFiscal : '';
		this.coloniaDatoFiscal = obj && obj.coloniaDatoFiscal ? obj.coloniaDatoFiscal : '';
		this.calleDatoFiscal = obj && obj.calleDatoFiscal ? obj.calleDatoFiscal : '';
		this.cumpleanios = obj && obj.cumpleanios ? obj.cumpleanios : null;
		this.paginaWeb = obj && obj.paginaWeb ? obj.paginaWeb : '';
		this.empresaPublicidad = obj && obj.empresaPublicidad ? obj.empresaPublicidad : '';
		this.fundacion = obj && obj.fundacion ? obj.fundacion : null;
  }
}

interface DataObjCargaContacto{
	nombre?: string;
	apellido?: string;
	propietario?: string;
	email?: string;
	cargo?: string;
	telefono?: string;
	celular?: string;
	origen?: string;
	etapa?: string;
	estatus?: string;
	opcSepomex?: boolean;
	opcPago?: boolean;
	pais?: string;
	codigoPostal?: string;
	estado?: string;
	municipio?: string;
	colonia?: string;
	calle?: string;
	empresa?: string;
	clase?: string;
	industria?: string;
	clasificacion?: string;
	tipo?: string;
	rfc?: string;
	razonSocial?: string;
	paisDatoFiscal?: string;
	codigoPostalDatoFiscal?: string;
	estadoDatoFiscal?: string;
	municipioDatoFiscal?: string;
	coloniaDatoFiscal?: string;
	calleDatoFiscal?: string;
	cumpleanios?: Date;
	paginaWeb?: string;
	empresaPublicidad?: string;
	fundacion: Date;
}

