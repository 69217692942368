import * as fromUsuario from '../actions';
import { Oportunidad } from 'src/app/models/oportunidades';

export interface OportunidadState {
  oportunidades: Oportunidad[];
  oportunidad: Oportunidad;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: OportunidadState = {
  oportunidades: [],
  oportunidad: new Oportunidad(null),
  loaded: false,
  loading: false,
  error: null
};

export function oportunidadReducer( state = estadoInicial, action: fromUsuario.oportunidadAcciones ): OportunidadState {

  switch ( action.type ) {
    case fromUsuario.CARGAR_OPORTUNIDADES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_OPORTUNIDADES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oportunidades: [...action.oportunidades]
      };
    case fromUsuario.CARGAR_OPORTUNIDADES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_OPORTUNIDAD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_OPORTUNIDAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        oportunidad: {...action.oportunidad}
      };
    case fromUsuario.CARGAR_OPORTUNIDAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_OPORTUNIDAD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_OPORTUNIDAD_SUCCESS:
      const itm = state.oportunidades.find( item => item.id === action.oportunidad.id);
      if (itm && itm.id) {
        state.oportunidades = state.oportunidades.map( item => {
          if (item.id === action.oportunidad.id) {
            item = {...action.oportunidad};
          }
          return item;
        });
      } else {
        state.oportunidades.push(action.oportunidad);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_OPORTUNIDAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_OPORTUNIDAD_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_OPORTUNIDAD_ESTATUS_SUCCESS:
      state.oportunidades = state.oportunidades.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_OPORTUNIDAD_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_OPORTUNIDAD:
      return {
        ...state,
				oportunidad: new Oportunidad(null)
      };
    default:
      return state;
  }
}
