import * as fromUsuario from '../actions';
import { Contacto } from 'src/app/models/contacto';

export interface ContactoState {
  contactos: Contacto[];
  total: number;
  totalFiltro: number;
  contacto: Contacto;
  actividad: Date;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ContactoState = {
  contactos: [],
  total: 0,
  totalFiltro: 0,
  contacto: new Contacto(null),
  actividad: null,
  loaded: false,
  loading: false,
  error: null
};

export function contactoReducer( state = estadoInicial, action: fromUsuario.contactoAcciones ): ContactoState {

  switch ( action.type ) {
    case fromUsuario.CARGAR_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_CONTACTOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contactos: [...action.contactos]
      };
    case fromUsuario.CARGAR_CONTACTOS2_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contactos: [...action.contactos],
        total: action.total,
        totalFiltro: action.totalFiltro
      };
    case fromUsuario.CARGAR_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_CONTACTO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        contacto: {...action.contacto}
      };
    case fromUsuario.CARGAR_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_CONTACTO_SUCCESS:
      const itm = state.contactos.find( item => item.id === action.contacto.id);
      if (itm && itm.id) {
        state.contactos = state.contactos.map( item => {
          if (item.id === action.contacto.id) {
            item = {...action.contacto};
          }
          return item;
        });
      } else {
        state.contactos.push(action.contacto);
      }
      if (state.contacto.id === action.contacto.id) {
        state.contacto = {...action.contacto};
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
			};
    case fromUsuario.ELIMINAR_CONTACTO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINAR_CONTACTO_SUCCESS:
      state.contactos = state.contactos.filter( item => item.id !== action.id );
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINAR_CONTACTO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_CONTACTO:
      return {
        ...state,
				contacto: new Contacto(null)
      };
    case fromUsuario.CARGAR_ULTIMA_ACTIVIDAD_CONTACTOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_ULTIMA_ACTIVDAD_CONTACTOS_SUCCESS:
      state.actividad = action.actividad;
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.CARGAR_ULTIMA_ACTIVDAD_CONTACTOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_CONTACTOS_DUPLICADOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_CONTACTOS_DUPLICADOS_SUCCESS:
      state.contactos.map( obj => { 
        if( obj.id === action.contacto.id ) {
          obj.emails = obj.emails + action.duplicados.reduce( (prev, current) => {
            return prev ? prev + ', ' + current.emails : ', ' + current.emails;
          }, ''); 
        }
        return obj; 
      });
      state.contactos = [ ...state.contactos.filter( obj => !action.duplicados.find( item => obj.id === item.id) ).map( item => {
        item.check = false;
        return item;
      }) ];
      state.total -= action.duplicados.length;
      state.totalFiltro -= action.duplicados.length;
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_CONTACTOS_DUPLICADOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
