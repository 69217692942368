import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ActividadesEmpresasService } from 'src/app/services/actividades-empresas.service';

@Injectable()
export class ActividadesEmpresaEffects {

  constructor(
    private actions$: Actions,
    public actividadesEmpresasService: ActividadesEmpresasService
  ) {}

  @Effect()
  cargarActividadesEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_EMPRESA ),
    switchMap( (action: any) => {
      return this.actividadesEmpresasService.obtenerActividadesEmpresa(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesEmpresaSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusActividadesEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_ACTIVIDADES_EMPRESA_ESTATUS ),
    switchMap( (action: any) => {
      return this.actividadesEmpresasService.actualizaEstatusActividadesEmpresa(action.id)
        .pipe(
            tap( request => new usuariosActions.CargarDashboardActividadesPendientesDia()),
            map( respuesta => {
              return new usuariosActions.ActualizaActividadesEmpresaEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaActividadesEmpresaEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaActividadesEmpresa$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_ACTIVIDADES_EMPRESA ),
    switchMap( (action: any) => {
      return this.actividadesEmpresasService.registraActividadesEmpresa(action.actividad)
        .pipe(
            tap( request => new usuariosActions.CargarDashboardActividadesPendientesDia()),
            map( respuesta => {
              return new usuariosActions.ActualizaActividadesEmpresaSuccess(action.actividad);
            }),
            catchError( error => of(new usuariosActions.ActualizaActividadesEmpresaFail(error))  )
        );
    })
  );

  @Effect()
  cargarActividadesContacto$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_CONTACTO ),
    switchMap( (action: any) => {
      return this.actividadesEmpresasService.obtenerActividadesContactos(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesContactoSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesContactoFail(error))  )
        );
    })
  );

  @Effect()
  cargarActividadesOportunidad$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_OPORTUNIDAD ),
    switchMap( (action: any) => {
      return this.actividadesEmpresasService.obtenerActividadesOportunidades(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesOportunidadSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesOportunidadFail(error))  )
        );
    })
  );

  @Effect()
  cargarActividadesPendientes$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ACTIVIDADES_PENDIENTES_TOTAL ),
    switchMap( (action: any) => {
      return this.actividadesEmpresasService.obtenerActividadesPendientes(action.fechas, action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarActividadesPendientesTotalSuccess(respuesta.actividades);
          }),
          catchError( error => of(new usuariosActions.CargarActividadesPendientesTotalFail(error))  )
        );
    })
  );

}
