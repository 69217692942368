import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Identity, User, FilterType } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions/usuarios.actions';

@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.sass']
})
export class UsersFilterComponent implements OnInit, OnDestroy {
  identity: Identity = new Identity(null);
  @Output() respuesta = new EventEmitter<FilterType[]>();
  usuarios: User[] = [];
  public loading = false;
  private subscripcionAuth: Subscription = new Subscription();
  private subscripcionUsers: Subscription = new Subscription();
  paginacion: Pagination = new Pagination(null);
  checkTodo = false;
  filtrado: FilterType[] = [];

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.subscripcionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscripcionUsers = this.store.select('usuarios').subscribe(
      (state) => {
        this.usuarios = state.usuarios;
      }
    );
    this.inicializa();
  }

  inicializa() {
    if (!this.usuarios.length) {
      this.store.dispatch(new usuariosActions.CargarUsuarios());
    } else {
      this.usuarios = this.usuarios.map(
        element => {
          element.checked = false;
          return element;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subscripcionUsers.unsubscribe();
    this.subscripcionAuth.unsubscribe();
  }

  changeCheck(item: User) {
    this.checkTodo = true;
    this.filtrado = this.usuarios.map( (elemento) => {
      if (item.id !== elemento.id) {
        if (!elemento.checked) { this.checkTodo = false; }
        return {id: elemento.id, nombre: elemento.nombre, checked: elemento.checked};
      }
      if (!item.checked) { this.checkTodo = false; }
      return {id: item.id, nombre: item.nombre, checked: item.checked};
    }).filter( element => element.checked);
    this.respuesta.emit(this.filtrado);
  }

  changeCheckTodo() {
    this.filtrado = this.usuarios.map( (elemento) => {
      elemento.checked = this.checkTodo;
      return {id: elemento.id, nombre: elemento.nombre, checked: elemento.checked};
    });
    this.respuesta.emit(this.filtrado);
  }
}
