import { Component, OnInit, OnDestroy } from '@angular/core';
import { NegocioCatalogo, Oportunidad, ReporteVentaSortType, ColoresCatalogo } from 'src/app/models/oportunidades';
import { Catalogo } from 'src/app/models/catalogo';
import { FilterType, Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import { GLOBAL } from 'src/app/models/global';
import * as moment from 'moment';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AnyScopePipe } from 'src/app/pipes/any-scope.pipe';
import { MultiOpcionesSelectedPipe } from 'src/app/pipes/multi-opciones-selected.pipe';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reporte-ventas-etapas',
  templateUrl: './reporte-ventas-etapas.component.html',
  styleUrls: ['./reporte-ventas-etapas.component.scss']
})
export class ReporteVentasEtapasComponent implements OnInit, OnDestroy {
  heading = 'Reporte de oportunidades por etapas';
  subheading = 'Oportunidades registradas en el sistema por etapas';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  public url: string;
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subscribeReporte: Subscription = new Subscription();
  public oportunidades: Oportunidad[];
  public etapas: Catalogo[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...ReporteVentaSortType];
  public version = 1;
  public listSearch: string[] = [];
  public oportunidadesTotal: { etapa: number, desEtapa: string, oportunidad: Oportunidad[], color: string, collapsed: boolean }[] = [];
  public colores: Catalogo[] = [...ColoresCatalogo];
  public filterTiposNegocios: any[] = [...NegocioCatalogo.map(item => {
    return {id: item.nombre, nombre: item.nombre, checked: false};
  })];
  public filterPropietarios: FilterType[] = [];
  public filterEtapas: FilterType[] = [];
  public dates: Date[] = [];
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public datePipe = new DatePipe("en-US");
  constructor(
    private store: Store<AppState>,
    private excelService: ExcelService,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.oportunidades = [];
    this.etapas = [];
    this.url = GLOBAL.url;
    this.fromDate = calendar.getNext(calendar.getToday(), 'd', - 30);
    this.toDate = calendar.getToday();
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 30);
    this.dates = [ fecha, new Date() ];
  }

  ngOnInit(): void {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeReporte = this.store.select('reportes').subscribe(
      (state) => {
        this.oportunidades = [...state.ventasEtapas];
        this.acutlizaCards();
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.etapas = [...state.etapas.filter(item => item.status)];
        this.filterEtapas = [...state.etapas.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterPropietarios = [...state.usuarios.map(item => {
          return new FilterType({id: item.id, nombre: item.descripcion, checked: false});
        })];
        this.acutlizaCards();
      }
    );
    console.log('Inicializa component Reporte-oportunidades-etapas...');
    this.store.dispatch(new usuariosActions.ConsultaEtapas());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.CargarReporteVentasEtapas(this.fromDate, this.toDate));
  }
  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeReporte.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  acutlizaCards(){
    this.oportunidadesTotal = [];
    this.etapas.find( item => { this.oportunidadesTotal.push({etapa: item.id, desEtapa: item.nombre, oportunidad: [], color:'', collapsed: true}); });
    if(this.oportunidadesTotal.length > 0){
      this.oportunidadesTotal.find(item => {
        item.oportunidad = [...this.oportunidades.filter(itm => itm.etapa === item.etapa)];
        let color = '';
        this.colores.find(ite => { if (ite.id === item.etapa) { color = ite.nombre} });
        item.color = color;
      });
    }
    this.oportunidadesTotal = [...this.oportunidadesTotal.filter(item => item.oportunidad.length > 0 )];
  }

  printCSV() {
    let excel;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    const pipeMultiOpcionesSelect = new MultiOpcionesSelectedPipe();
    let lista = [...this.oportunidades];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterTiposNegocios, parametro: 'tipoNegocio'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterEtapas, parametro: 'etapa'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterPropietarios, parametro: 'propietario'});
    if (lista) {
      excel = lista.map( item => {
        const obj: any = {};
        obj.ETAPA = item.desEtapa;
        obj.NOMBRE = item.nombre;
        obj.EMPRESA = item.desEmpresa;
        obj.IMPORTE = item.importe;
        obj.FECHA_CREACIÓN = (item.fecRegistro && item.fecRegistro != null ? moment(item.fecRegistro).format('DD/MM/YYYY') : '');
        obj.FECHA_CIERRE = (item.fecha && item.fecha != null ? moment(item.fecha).format('DD/MM/YYYY') : '');
        obj.PROPIETARIO = item.desPropietario;
        return obj;
      });
      this.excelService.exportAsExcelFile(excel, 'reporte_oportunidades_etapas' + i);
    }
  } 

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  changeTipoNegocio(lista: FilterType[]) {
    this.filterTiposNegocios = [...lista];
  }

  changeEtapas(lista: FilterType[]) {
    this.filterEtapas = [...lista];
  }

  changePropietarios(lista: FilterType[]) {
    this.filterPropietarios = [...lista];
  }

  onDateSelection(fechas: Date[]) {
    if(fechas && fechas.length){
      const dates: string[] = [...fechas.map(item => { return this.datePipe.transform(item, 'yyyy-MM-dd'); })];
      this.fromDate = this.dateToStructDateModel(dates.shift());
      this.toDate = this.dateToStructDateModel(dates.pop());
      this.store.dispatch(new usuariosActions.CargarReporteVentasEtapas(this.fromDate, this.toDate));
    }
  }

  dateToStructDateModel(date: string) {
    let data: string[] = date.split('-');
    return new NgbDate ( Number(data[0]),  Number(data[1]),  Number(data[2]));
	}

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchOportunidades").focus();
  }


}
