<div class="row">
  <div class="col-md-5">
    <div class="main-card mb-2.5 card">
      <div class="card-body">
        <h5 class="card-title">Editar Campaña</h5>
        <form id="capturarCampanaForm" name="capturarCampanaForm" #capturarCampanaForm="ngForm">
          <div class="position-relative form-group required">
            <label class="control-label" for="temacampana">Asunto</label>
            <input name="temacampana" id="temacampana" (focusout)="existeCampana()" placeholder="Asunto de la campaña"
              type="text" class="form-control" [(ngModel)]="campanaguardada.tema" required />
          </div>
          <div class="position-relative form-group required">
            <label class="control-label" for="nombre_emisor">Nombre emisor</label>
            <input name="nombre_emisor" id="nombreemisor" placeholder="Nombre del emisor" type="text"
              class="form-control" [(ngModel)]="campanaguardada.nombre_emisor" required />
          </div>
          <div class="position-relative form-group required">
            <label class="control-label" for="correo_emisor">Desde el e-mail</label>
            <div class="input-group mb-2">
              <input name="correo_emisor" id="correo_emisor" placeholder="ej: informacion@empresa.com" type="input"
                class="form-control" [(ngModel)]="campanaguardada.correo_emisor" required />
              <div class="input-group-prepend">
                <select *ngIf="dns.length > 1" name="dnsMarketing" id="dnsMarketing" #dnsMarketing="ngModel"
                  [(ngModel)]="campanaguardada.dns" class="input-group-text" required>
                  <option [value]="">Selecciona un dominio</option>
                  <option *ngFor="let itm of dns" [value]="itm">{{itm}}</option>
                </select>
                <div class="input-group-text" *ngIf="dns.length === 1">{{dns[0]}}</div>
              </div>
            </div>
          </div>
          <div class="position-relative form-group required">
            <label class="control-label" for="correo_respuesta">E-mail de respuesta</label>
            <div class="input-group mb-2">
              <input name="correo_respuesta" id="correo_respuesta" placeholder="ej: reply@empresa.com" type="input"
                class="form-control" [(ngModel)]="campanaguardada.correo_respuesta" />
              <div class="input-group-prepend">
                <select *ngIf="dns.length > 1" name="dnsMarketingRes" id="dnsMarketingRes" #dnsMarketing="ngModel"
                  [(ngModel)]="campanaguardada.dns" class="input-group-text" disabled>
                  <option value=""></option>
                  <option *ngFor="let itm of dns" [value]="itm" required>{{itm}}</option>
                </select>
                <div class="input-group-text" *ngIf="dns.length === 1">{{dns[0]}}</div>
              </div>
            </div>
          </div>
          <div class="position-relative form-group">
            <label class="control-label" for="exampleText">Versión de texto sin formato</label>
            <textarea name="texto_plano" id="texto_plano" placeholder="Versión en texto plano de este email"
              class="form-control" [(ngModel)]="campanaguardada.texto_plano">
            </textarea>
          </div>
          <ng-template #popContent>
            Opcionalmente, agregue una cadena de consulta a todos los enlaces en su boletín de
            correo electrónico. Un buen caso de uso es el seguimiento de Google Analytics.<br/> 
            <strong>No incluya '?' en su cadena de consulta.</strong><br>
            <img src="https://code.visualstudio.com/raw/images/1_52/goto-definition.gif" alt="this slowpoke moves" width=350px />
          </ng-template>
          <ng-template #popTitle><strong>Cadena de consulta</strong></ng-template>

          <!-- <div class="position-relative form-group">
            <label for="analytics">Cadena de consulta <a><i type="button" class="fas fa-info-circle" placement="right" [ngbPopover]="popContent" [popoverTitle]="popTitle"></i></a></label>
            <input name="analytics" id="analytics" placeholder="p.ej.  utm_source = newsletter & utm_medium = sendy & utm_campaign = email_marketing" type="text" class="form-control" [(ngModel)]="campanaguardada.cadenaconsulta">
          </div> -->
          <!-- <div class="position-relative form-group"><label for="exampleFile">Archivos Adjuntos</label><input name="file" id="archivosad" type="file" class="form-control-file" [(ngModel)]="campana.archivo"></div> -->
          <!-- <small class="form-text text-muted">Archivos permitidos: jpeg, jgp, gif, png, pdf, zip.</small> -->
          <div class="position-relative form-group">
            <label class="control-label mr-2" for="seguimiento_aperturas">Seguimiento al abrir correos</label>
            <div class="btn-group-sm btn-group btn-group-toggle" ngbRadioGroup name="seguimiento_aperturas"
              [(ngModel)]="campanaguardada.seguimiento_aperturas">
              <bSwitch id="seguimiento_aperturas" name="seguimiento_aperturas" #EditarCampana="ngModel"
                [(ngModel)]="campanaguardada.seguimiento_aperturas" [switch-size]="'normal'" [switch-on-text]="'Si'"
                [switch-off-text]="'No'" [switch-on-color]="'primary'" [switch-off-color]="'danger'">
              </bSwitch>
            </div>
          </div>
          <div class="position-relative form-group">
            <label class="control-label mr-2" for="seguimientoclick">Seguimiento al dar click dentro del correo</label>
            <div class="btn-group-sm btn-group btn-group-toggle" ngbRadioGroup name="seguimiento_links"
              [(ngModel)]="campanaguardada.seguimiento_links">
              <bSwitch id="seguimiento_links" name="seguimiento_links" #EditarCampana="ngModel"
                [(ngModel)]="campanaguardada.seguimiento_links" [switch-size]="'normal'" [switch-on-text]="'Si'"
                [switch-off-text]="'No'" [switch-on-color]="'primary'" [switch-off-color]="'danger'">
              </bSwitch>
            </div>
          </div>
          <!-- <ng-template #popContent>Opcionalmente, agregue una cadena de consulta a todos los enlaces en su boletín de correo electrónico. Un buen caso de uso es el seguimiento de Google Analytics.<br/> <strong>No incluya '?' en su cadena de consulta.</strong><br><img src="https://code.visualstudio.com/raw/images/1_52/goto-definition.gif" alt="this slowpoke moves"  width=350px/> </ng-template>
          <ng-template #popTitle><strong>Cadena de consulta</strong></ng-template>
          <div class="position-relative form-group"><label for="analytics">Cadena de consulta <a><i type="button" class="fas fa-info-circle" placement="right" [ngbPopover]="popContent" [popoverTitle]="popTitle"></i></a></label><input name="analytics" id="analytics" placeholder="p.ej.  utm_source = newsletter & utm_medium = sendy & utm_campaign = email_marketing" type="text" class="form-control" [(ngModel)]="campanaguardada.cadenaconsulta"></div> -->
          <!-- <div class="position-relative form-group"><label for="exampleFile">Archivos Adjuntos</label><input name="file" id="archivosad" type="file" class="form-control-file" [(ngModel)]="campana.archivo"></div> -->
          <!-- <small class="form-text text-muted">Archivos permitidos: jpeg, jgp, gif, png, pdf, zip.</small> -->
        </form>
      </div>
      <div class="grid-menu grid-menu-2col border-top">
        <div class="no-gutters row">
          <div class="col-sm-6">
            <a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
              [routerLink]="['/auth/marketing/allcampanas']">
              <i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
            </a>
          </div>
          <div class="col-sm-6">
            <button type="button" class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
              (click)="ActualizarCampana(campanaguardada)" [disabled]="!capturarCampanaForm.valid || !nombreValido">
              <i class="lnr-download btn-icon-wrapper"></i> Guardar edición
            </button>
            <!-- <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
              <span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
              <br>
              Loading...
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- akicomienza -->
  <div class="col-md-7">
    <div class="card main-card mb-3.5">
      <div class="card-body">
        <blockquote
          style="padding: 10px 15px 5px 15px; margin: 0 0 18px; border-left: 5px solid #e2e6e8; background: #f7f9fc; background-color:rgb(240, 240, 240)">
          <span>
            <div class="badge badge pl-2 pr-2 badge-light">De:
            </div>
            <strong>{{campanaguardada.nombre_emisor}}</strong>
            <strong>
              &#60;{{campanaguardada.correo_emisor}}{{campanaguardada.dns}}&#62;
            </strong>
          </span>
          <br />
          <br />
          <span>
            <div class="badge badge pl-2 pr-2 badge-light">Asunto:</div> <strong> {{campanaguardada.tema}}</strong>
          </span>
          <br>
          <br>
          <span *ngIf="campanaguardada.seguimiento_aperturas">
            <div class="badge badge pl-2 pr-2 badge-light">Seguimiento por apertura:</div>
            <div class="badge badge pl-2 pr-2 badge-success">Activado</div>
          </span>
          <span *ngIf="!campanaguardada.seguimiento_aperturas">
            <div class="badge badge pl-2 pr-2 badge-light">Seguimiento por apertura:</div>
            <div class="badge badge pl-2 pr-2 badge-warning">Desactivado</div>
          </span>
          <br>
          <br>
          <span *ngIf="campanaguardada.seguimiento_links">
            <div class="badge badge pl-2 pr-2 badge-light">Seguimiento por links(clics):</div>
            <div class="badge badge pl-2 pr-2 badge-success">Activado</div>
          </span>
          <span *ngIf="!campanaguardada.seguimiento_links">
            <div class="badge badge pl-2 pr-2 badge-light">Seguimiento por links(clics):</div>
            <div class="badge badge pl-2 pr-2 badge-warning">Desactivado</div>
          </span>
        </blockquote>
        <ckeditor height="638px" [(ngModel)]="campanaguardada.texto_html" [config]="config"></ckeditor>
        <div class="divider">
        </div><br>
        <div class="row">
          <div class="col">
            <b>Etiquetas esenciales:</b><br />
            <br />
            <p>
              <strong>Enlace para darse de baja:</strong><br />
              <code>[desuscribe]</code>
            </p>
          </div>
          <div class="col">
            <b>Etiquetas de personalización:</b><br />
            <br />
            <p>
              <strong>Nombre:</strong><br />
              <code>[nombre]</code>
            </p>
            <p>
              <strong>Apellido:</strong><br />
              <code>[apellido]</code>
            </p>
            <p>
              <strong>Email:</strong><br />
              <code>[email]</code>
            </p>
            <p>
              <strong>Teléfono:</strong><br />
              <code>[telefono]</code>
            </p>
            <p>
              <strong>Celular:</strong><br />
              <code>[celular]</code>
            </p>
            <p>
              <strong>Empresa a la que pertenece:</strong><br />
              <code>[empresa]</code>
            </p>
            <p>
              <strong>Fundación a la que pertenece:</strong><br />
              <code>[fundacion]</code>
            </p>
            <p>
              <strong>Cargo empresarial:</strong><br />
              <code>[cargo]</code>
            </p>
            <p>
              <strong>País de recidencia:</strong><br />
              <code>[pais]</code>
            </p>
            <p>
              <strong>Estado de recidencia:</strong><br />
              <code>[estado]</code>
            </p>
            <p>
              <strong>Ciudad de recidencia:</strong><br />
              <code>[ciudad]</code>
            </p>
            <p>
              <strong>Cumpleaños:</strong><br />
              <code>[cumpleanios]</code>
            </p>
            <p>
              <strong>Aniversario:</strong><br />
              <code>[aniversario]</code>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>