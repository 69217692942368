import { Action } from '@ngrx/store';
import { Catalogo } from 'src/app/models/catalogo';

export const CARGAR_PAISES = '[Pais] Cargar paises';
export const CARGAR_PAISES_SUCCESS = '[Pais] Cargar paises success';
export const CARGAR_PAISES_FAIL = '[Pais] Cargar paises fail';
export const ACTUALIZA_PAIS = '[Pais] Actualiza pais';
export const ACTUALIZA_PAIS_FAIL = '[Pais] Actualiza pais FAIL';
export const ACTUALIZA_PAIS_SUCCESS = '[Pais] Actualiza pais SUCCESS';
export const ACTUALIZA_PAIS_ESTATUS = '[Pais] Actualiza pais estatus';
export const ACTUALIZA_PAIS_ESTATUS_FAIL = '[Pais] Actualiza pais estatus FAIL';
export const ACTUALIZA_PAIS_ESTATUS_SUCCESS = '[Pais] Actualiza pais estatus SUCCESS';


export class CargarPaises implements Action {
  readonly type = CARGAR_PAISES;
}

export class CargarPaisesFail implements Action {
  readonly type = CARGAR_PAISES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarPaisesSuccess implements Action {
  readonly type = CARGAR_PAISES_SUCCESS;

  constructor(public paises: Catalogo[]) {}
}

export class ActualizaPais implements Action {
  readonly type = ACTUALIZA_PAIS;

  constructor( public pais: Catalogo ) { }
}

export class ActualizaPaisFail implements Action {
  readonly type = ACTUALIZA_PAIS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaPaisSuccess implements Action {
  readonly type = ACTUALIZA_PAIS_SUCCESS;

  constructor( public pais: Catalogo ) {}
}

export class ActualizaPaisEstatus implements Action {
  readonly type = ACTUALIZA_PAIS_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaPaisEstatusFail implements Action {
  readonly type = ACTUALIZA_PAIS_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaPaisEstatusSuccess implements Action {
  readonly type = ACTUALIZA_PAIS_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type paisAcciones = CargarPaises 
                                | CargarPaisesFail 
                                | CargarPaisesSuccess
                                | ActualizaPais 
                                | ActualizaPaisFail 
                                | ActualizaPaisSuccess 
                                | ActualizaPaisEstatus 
                                | ActualizaPaisEstatusFail 
                                | ActualizaPaisEstatusSuccess;
