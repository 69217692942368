import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-politica-privacidad',
  templateUrl: './politica-privacidad.component.html',
  styles: []
})
export class PoliticaPrivacidadComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
