<div class="main-posts">
  <div class="card">
    <div class="card-header">
      <a [routerLink]="['/auth/repositorios']" class="btn btn-outline-link btn-lg">
        <i class="fa fa-chevron-left"></i>
      </a>
      ALBUM: <span class="ml-2 text-dark">{{album.nombre}}</span>
      <div class="btn-actions-pane-right">
        ({{(galleryImages | anyScope: paginacion.textSearch ).length}} de {{(galleryImages).length}})
        <div class="btn-group ml-2">
          <div class="search-wrapper active">
            <div class="input-holder">
              <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchAlbumRepositorio" id="searchAlbumRepositorio" #searchAlbumRepositorio="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
              <button class="search-icon"><span></span></button>
            </div>
          </div>
        </div>
        <a class="btn btn-outline-primary" [routerLink]="['/auth/repositorios/register', album.id]" *ngIf="identity.permisos.repositoriosListEdit">
          <i class="fa fa-plus"></i> Agregar
        </a>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
            {{texto}}
            <i class="ml-1 fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2" *ngIf="album.descripcion">Descripción: {{album.descripcion}}</div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-3" *ngFor="let image of galleryImages | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize; let i= index">
          <div class="card" style="background-color: #ebebeb;">
            <div id="over" class="gallery-list">
              <img [src]="image.thumbnail" [alt]="image.nombre" (click)="openModal(i)" class="thumbnail-gallery">
              <div class="btn-transition only-hover icon-helper" ngbDropdown  placement="left-top">
                <i type="button" ngbDropdownToggle class="fa fa-ellipsis-h"></i>
                <div ngbDropdownMenu class="dropdown-menu-hover-link dropdown-menu-left-rounded">
                  <button class="dropdown-item" (click)="openModal(i)"><span>Ver</span></button>
                  <button class="dropdown-item" (click)="deleteFile(image)" *ngIf="identity.permisos.repositoriosListEdit"><span>Eliminar</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="text-center">
            <div class="card no-shadow bg-transparent">
              <div class="widget-chart-content">
                <div class="widget-content-wrapper">
                  <div class="widget-content-outer mx-auto">
                  <ngb-pagination class="mx-auto"
                    [collectionSize]="(galleryImages | anyScope: paginacion.textSearch).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                    <ng-template ngbPaginationFirst>First</ng-template>
                    <ng-template ngbPaginationLast>Last</ng-template>
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <video id="example_video_1" class="video-js vjs-default-skin"
 controls preload="auto" width="640" height="480"
 poster="http://video-js.zencoder.com/oceans-clip.png"
 data-setup='{"example_option":true}'>
<source src="http://localhost/crm/api/info/storage/public/galeria/SampleVideo_1280x720_30mb.flv" type="crm/webm">
</video> -->


<!-- <video id="example_video_1" class="video-js vjs-default-skin"
 controls preload="auto"
 poster="http://video-js.zencoder.com/oceans-clip.png"
 data-setup='{"example_option":true}'>
<source src="http://localhost/crm/api/info/storage/public/galeria/TRUCOS.3gp" type="video/3gpp; codecs='mp4v.20.8, samr'">
</video> -->

<!-- <video controls width="500">
  <embed src="http://localhost/crm/api/info/storage/public/galeria/SampleVideo_1280x720_30mb.flv" type="application/x-shockwave-flash" width="1024" height="798" allowscriptaccess="always" allowfullscreen="true"></embed>
  </video> -->

<!-- <source
src="http://localhost/crm/api/info/storage/public/galeria/TRUCOS.3gp"
type="video/3gpp; codecs='mp4v.20.8, samr'">
</source> -->

<!-- <video width="800" height="374">
  <!-- <source src="my_video.mp4" type="video/mp4" />
  <source src="my_video.ogv" type="video/ogg" /> --

  <object width="800" height="374" type="application/x-shockwave-flash" data="fallback.swf">
          <param name="movie" value="fallback.swf" />
          <param name="flashvars" value="autostart=true&amp;file=http://localhost/crm/api/info/storage/public/galeria/SampleVideo_1280x720_30mb.flv" />
  </object>

</video>  -->

<!-- <Object classid =: código base "idcls D27CDB6E-AE6D-11cf-96B8-444553540000" = "http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,40, 0 "width =" 400 "height =" 300 ">

  <Param name = "movie" value = "http://www.filehost.com/flash.flv" />
  
  <Href = "http://www.filehost.com/flash.flv" width = "400" height = "300" type = "application / x-shockwave-flash" incrustar pluginspage = "http: //www.macromedia .com / go / getflashplayer ">
  
  </ Embed>
  
  </ Object> -->


  <!-- <object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=9,0,16,0" width="320" height="400"> -->
    <!-- <param name="movie" value="http://localhost/crm/api/info/storage/public/galeria/SampleVideo_1280x720_30mb.flv"> -->
    <!-- <embed src="http://localhost/crm/api/info/storage/public/galeria/SampleVideo_1280x720_30mb.flv" width="320" height="400" play="true" loop="false" quality="high" pluginspage="http://www.macromedia.com/go/getflashplayer" type="application/x-shockwave-flash"></embed> -->
  <!-- </object> -->