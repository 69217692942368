import { Action } from '@ngrx/store';
import { KpisVendedores } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
import { Actividad } from 'src/app/models/actividad';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export const CARGAR_REPORTE_ACTIVIDADES = '[ReporteKpisVendedores] Cargar actividades';
export const CARGAR_REPORTE_ACTIVIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar actividades success';
export const CARGAR_REPORTE_ACTIVIDADES_FAIL = '[ReporteKpisVendedores] Cargar actividades fail';
export const CARGAR_REPORTE_ACTIVIDADES_PENDIENTES = '[ReporteKpisVendedores] Cargar actividades pendientes';
export const CARGAR_REPORTE_ACTIVIDADES_PENDIENTES_FAIL = '[ReporteKpisVendedores] Cargar actividades pendientes FAIL';
export const CARGAR_REPORTE_ACTIVIDADES_PENDIENTES_SUCCESS = '[ReporteKpisVendedores] Cargar actividades pendientes SUCCESS';
export const CARGAR_REPORTE_EMPRESAS_REGISTRADAS = '[ReporteKpisVendedores] Cargar empresas registradas';
export const CARGAR_REPORTE_EMPRESAS_REGISTRADAS_FAIL = '[ReporteKpisVendedores] Cargar empresas registradas FAIL';
export const CARGAR_REPORTE_EMPRESAS_REGISTRADAS_SUCCESS = '[ReporteKpisVendedores] Cargar empresas registradas SUCCESS';
export const CARGAR_REPORTE_CONTACTOS_REGISTRADAS = '[ReporteKpisVendedores] Cargar contactos registradas';
export const CARGAR_REPORTE_CONTACTOS_REGISTRADAS_FAIL = '[ReporteKpisVendedores] Cargar contactos registradas FAIL';
export const CARGAR_REPORTE_CONTACTOS_REGISTRADAS_SUCCESS = '[ReporteKpisVendedores] Cargar contactos registradas SUCCESS';
export const CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS = '[ReporteKpisVendedores] Cargar oportunidades abiertas';
export const CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_FAIL = '[ReporteKpisVendedores] Cargar oportunidades abiertas FAIL';
export const CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_SUCCESS = '[ReporteKpisVendedores] Cargar oportunidades abiertas SUCCESS';
export const CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar clasificaciones oportunidades';
export const CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar clasificaciones oportunidades FAIL';
export const CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar clasificaciones oportunidades SUCCESS';
export const CARGAR_REPORTE_CLASES_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar clases oportunidades';
export const CARGAR_REPORTE_CLASES_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar clases oportunidades FAIL';
export const CARGAR_REPORTE_CLASES_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar clases oportunidades SUCCESS';
export const CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar industrias oportunidades';
export const CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar industrias oportunidades FAIL';
export const CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar industrias oportunidades SUCCESS';
export const CARGAR_REPORTE_FUNNEL_OPORTUNIDADES = '[ReporteKpisVendedores] Cargar funnel oportunidades';
export const CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_FAIL = '[ReporteKpisVendedores] Cargar funnel oportunidades FAIL';
export const CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_SUCCESS = '[ReporteKpisVendedores] Cargar funnel oportunidades SUCCESS';

export class CargarReporteActividades implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES;

  constructor( public inicia: NgbDate, public termina: NgbDate, public opciones: any ) { }
}

export class CargarReporteActividadesFail implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteActividadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_SUCCESS;

  constructor( public series: SeriesBar[], public categories: string[], public total: number) {}
}

export class CargarReporteActividadesPendientes implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_PENDIENTES;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteActividadesPendientesFail implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_PENDIENTES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteActividadesPendientesSuccess implements Action {
  readonly type = CARGAR_REPORTE_ACTIVIDADES_PENDIENTES_SUCCESS;

  constructor(  public series: number[], public categories: string[] ) {}
}

export class CargarReporteEmpresasRegistradas implements Action {
  readonly type = CARGAR_REPORTE_EMPRESAS_REGISTRADAS;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteEmpresasRegistradasFail implements Action {
  readonly type = CARGAR_REPORTE_EMPRESAS_REGISTRADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteEmpresasRegistradasSuccess implements Action {
  readonly type = CARGAR_REPORTE_EMPRESAS_REGISTRADAS_SUCCESS;

  constructor( public empresas: KpisVendedores[] ) {}
}

export class CargarReporteContactosRegistradas implements Action {
  readonly type = CARGAR_REPORTE_CONTACTOS_REGISTRADAS;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteContactosRegistradasFail implements Action {
  readonly type = CARGAR_REPORTE_CONTACTOS_REGISTRADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteContactosRegistradasSuccess implements Action {
  readonly type = CARGAR_REPORTE_CONTACTOS_REGISTRADAS_SUCCESS;

  constructor( public contactos: KpisVendedores[] ) {}
}

export class CargarReporteOportunidadesAbiertas implements Action {
  readonly type = CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteOportunidadesAbiertasFail implements Action {
  readonly type = CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteOportunidadesAbiertasSuccess implements Action {
  readonly type = CARGAR_REPORTE_OPORTUNIDADES_ABIERTAS_SUCCESS;

  constructor( public oportunidades: KpisVendedores[] ) {}
}

export class CargarReporteClasificacionOportunidades implements Action {
  readonly type = CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteClasificacionOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteClasificacionOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_CLASIFICACIONES_OPORTUNIDADES_SUCCESS;

  constructor( public series: SeriesBar[], public labels: string[] ) {}
}

export class CargarReporteClasesOportunidades implements Action {
  readonly type = CARGAR_REPORTE_CLASES_OPORTUNIDADES;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteClasesOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_CLASES_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteClasesOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_CLASES_OPORTUNIDADES_SUCCESS;

  constructor( public series: number[], public labels: string[] ) {}
}

export class CargarReporteIndustriasOportunidades implements Action {
  readonly type = CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteIndustriasOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteIndustriasOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_INDUSTRIAS_OPORTUNIDADES_SUCCESS;

  constructor( public series: number[], public categories: string[] ) {}
}

export class CargarReporteFunnelOportunidades implements Action {
  readonly type = CARGAR_REPORTE_FUNNEL_OPORTUNIDADES;

  constructor( public inicia: NgbDate, public termina: NgbDate ) { }
}

export class CargarReporteFunnelOportunidadesFail implements Action {
  readonly type = CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarReporteFunnelOportunidadesSuccess implements Action {
  readonly type = CARGAR_REPORTE_FUNNEL_OPORTUNIDADES_SUCCESS;

  constructor( public datas: FunnelGraphColors[] ) {}
}

export type reporteKpisVendedoresAcciones = CargarReporteActividades 
                                          | CargarReporteActividadesFail 
                                          | CargarReporteActividadesSuccess
                                          | CargarReporteActividadesPendientes 
                                          | CargarReporteActividadesPendientesFail 
                                          | CargarReporteActividadesPendientesSuccess
                                          | CargarReporteEmpresasRegistradas 
                                          | CargarReporteEmpresasRegistradasFail 
                                          | CargarReporteEmpresasRegistradasSuccess
                                          | CargarReporteContactosRegistradas 
                                          | CargarReporteContactosRegistradasFail 
                                          | CargarReporteContactosRegistradasSuccess
                                          | CargarReporteOportunidadesAbiertas 
                                          | CargarReporteOportunidadesAbiertasFail 
                                          | CargarReporteOportunidadesAbiertasSuccess
                                          | CargarReporteClasificacionOportunidades 
                                          | CargarReporteClasificacionOportunidadesFail 
                                          | CargarReporteClasificacionOportunidadesSuccess
                                          | CargarReporteClasesOportunidades 
                                          | CargarReporteClasesOportunidadesFail 
                                          | CargarReporteClasesOportunidadesSuccess
                                          | CargarReporteIndustriasOportunidades 
                                          | CargarReporteIndustriasOportunidadesFail 
                                          | CargarReporteIndustriasOportunidadesSuccess
                                          | CargarReporteFunnelOportunidades 
                                          | CargarReporteFunnelOportunidadesFail 
                                          | CargarReporteFunnelOportunidadesSuccess;
