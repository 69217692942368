import { Usuario } from 'src/app/models/usuario.model';
import * as fromUsuarios from '../actions';
import { User } from 'src/app/models/user';

export interface UsuariosState {
  usuarios: User[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: UsuariosState = {
  usuarios: [],
  loaded: false,
  loading: false,
  error: null
};

export function usuariosReducer( state = estadoInicial, action: fromUsuarios.usuariosAcciones ): UsuariosState {

  switch ( action.type ) {
    case fromUsuarios.CARGAR_USUARIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usuarios: [...action.usuarios.map( item => {
          if (item.fecRegistro) {
            item.fecRegistro = new Date(item.fecRegistro);
          }
          return item;
        })]
      };
    case fromUsuarios.CARGAR_USUARIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuarios.ELIMINAR_USUARIOS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case fromUsuarios.ELIMINAR_USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usuarios: state.usuarios.filter( (user) => user.id !== action.id)
      };
    case fromUsuarios.ELIMINAR_USUARIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }
}

