import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CatalogosService } from 'src/app/services/catalogos.service';


@Injectable()
export class IndustriaEffects {

  constructor(
    private actions$: Actions,
    public catalogosService: CatalogosService
  ) {}

  @Effect()
  cargarIndustrias$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_INDUSTRIAS ),
    switchMap( () => {
      return this.catalogosService.obtenerIndustrias()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarIndustriasSuccess(respuesta.industrias);
          }),
          catchError( error => of(new usuariosActions.CargarIndustriasFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusIndustria$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_INDUSTRIA_ESTATUS),
    switchMap( (action: any) => {
      return this.catalogosService.actualizaEstatusIndustria(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaIndustriaEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaIndustriaEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaIndustria$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_INDUSTRIA),
    switchMap( (action: any) => {
      return this.catalogosService.registraIndustria(action.industria)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaIndustriaSuccess(action.industria);
            }),
            catchError( error => of(new usuariosActions.ActualizaIndustriaFail(error))  )
        );
    })
  );

}
