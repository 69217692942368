import { Pipe, PipeTransform } from '@angular/core';

interface FiltroMulti {
  opciones: any[];
  parametro: string;
  count: string;
}

@Pipe({
  name: 'multiOpcionesSelectedCount'
})
export class multiOpcionesSelectedCountPipe implements PipeTransform {

  transform(value: any, args: FiltroMulti): number {
    let count = 0;
    if (!value.length) { return 0; }
    if (!args.opciones.length || !(args.parametro in value[0])) { return value; }
    const ids = args.opciones.filter((item) => {
      return item.checked;
    }).map( (item) => {
      if (item.id || item.id === 0) {
        return item.id;
      } else if (item.nombre) {
        return item.nombre;
      } else {
        return;
      }
    });
    if (!ids.length) { 
      value.find(item => { count += item[args.count]});
      return count;
    }
    let subValue = [...value.filter( (it: any) => ids.some(f => f === it[args.parametro]) )];
    subValue.find(item => { count += item[args.count]});
    return count;
  }

}
