import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Identity, FilterType, UserType } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions/catalogos.actions';

@Component({
  selector: 'app-user-type-filter',
  templateUrl: './user-type-filter.component.html',
  styleUrls: ['./user-type-filter.component.sass']
})
export class UserTypeFilterComponent implements OnInit, OnDestroy {
  identity: Identity = new Identity(null);
  @Output() respuesta = new EventEmitter<FilterType[]>();
  tipos: UserType[] = [];
  public loading = false;
  private subscripcionAuth: Subscription = new Subscription();
  private subscripcionUsers: Subscription = new Subscription();
  paginacion: Pagination = new Pagination(null);
  checkTodo = false;
  filtrado: FilterType[] = [];

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.subscripcionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscripcionUsers = this.store.select('catalogos').subscribe(
      (state) => {
        this.tipos = state.tipos;
      }
    );
    this.inicializa();
  }

  inicializa() {
    if (!this.tipos.length) {
      this.store.dispatch(new usuariosActions.LoadTypeUsers());
    } else {
      this.tipos = this.tipos.map( element => {
        element.checked = false;
        return element;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscripcionUsers.unsubscribe();
    this.subscripcionAuth.unsubscribe();
  }

  changeCheck(item: UserType) {
    this.checkTodo = true;
    this.filtrado = this.tipos.map( (elemento) => {
      if (item.id !== elemento.id) {
        if (!elemento.checked) { this.checkTodo = false; }
        return {id: elemento.id, nombre: elemento.nombre, checked: elemento.checked};
      }
      if (!item.checked) { this.checkTodo = false; }
      return {id: item.id, nombre: item.nombre, checked: item.checked};
    }).filter( element => element.checked);
    this.respuesta.emit(this.filtrado);
  }

  changeCheckTodo() {
    this.filtrado = this.tipos.map( (elemento) => {
      elemento.checked = this.checkTodo;
      return {id: elemento.id, nombre: elemento.nombre, checked: elemento.checked};
    });
    this.respuesta.emit(this.filtrado);
  }
}
