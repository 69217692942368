import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { ContactosService } from 'src/app/services/contactos.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';

@Injectable()
export class ContactoEffects {

  constructor(
    private actions$: Actions,
    public contactosService: ContactosService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {}

  @Effect()
  cargarContactos$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CONTACTOS ),
    switchMap( () => {
      return this.contactosService.obtenerContactos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarContactosSuccess(respuesta.contactos);
          }),
          catchError( error => of(new usuariosActions.CargarContactosFail(error))  )
        );
    })
  );

  @Effect()
  cargarContactos2$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CONTACTOS2 ),
    switchMap( (action: any) => {
      return this.contactosService.obtenerContactos2(action.opciones)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarContactos2Success(respuesta.contactos, respuesta.totalFiltro, respuesta.total);
          }),
          catchError( error => of(new usuariosActions.CargarContactosFail(error))  )
        );
    })
  );

  @Effect()
  cargarContacto$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_CONTACTO ),
    switchMap( (action: any) => {
      return this.contactosService.obtenerContacto(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarContactoSuccess(respuesta.contacto);
          }),
          catchError( error => of(new usuariosActions.CargarContactoFail(error))  )
        );
    })
  );

  @Effect()
  actualizaContacto$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_CONTACTO ),
    switchMap( (action: any) => {
      return this.contactosService.registraContacto(action.contacto, action.empresa)
        .pipe(
          map( respuesta => {
            return new usuariosActions.ActualizaContactoSuccess(respuesta.contacto);
          }),
          catchError( error => of(new usuariosActions.ActualizaContactoFail(error))  )
        );
    })
  );


  @Effect()
  actualizaEstatusContacto$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_CONTACTO ),
    switchMap( (action: any) => {
      return this.contactosService.eliminarContacto(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.EliminarContactoSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.EliminarContactoFail(error))  )
        );
    })
  );

  @Effect()
  cargarUltimaAcitividadContacto$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ULTIMA_ACTIVIDAD_CONTACTOS ),
    switchMap( (action: any) => {
      return this.contactosService.obtenerUltimaActividadContacto(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.CargarUltimaActividadContactoSuccess(respuesta.contacto.ultimaActividad);
            }),
            catchError( error => of(new usuariosActions.CargarUltimaActividadContactoFail(error))  )
        );
    })
  );


  @Effect()
  actualizaContactosDuplicados$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_CONTACTOS_DUPLICADOS ),
    switchMap( (action: any) => {
      return this.contactosService.actualizaContactosDuplicados(action.contacto, action.duplicados)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaContactosDuplicadosSuccess(action.contacto, action.duplicados);
            }),
            catchError( error => of(new usuariosActions.ActualizaContactosDuplicadosFail(error))  )
        );
    })
  );

  @Effect()
  updateCargaContactos$ = this.actions$.pipe(
    ofType( usuariosActions.UPDATE_CARGA_CONTACTOS ),
    switchMap( (action: any) => {
      return this.contactosService.registraCargaContactos(action.contactos)
        .pipe(
          map( respuesta => {
            return new usuariosActions.UpdateCargaContactosSuccess();
          }),
          catchError( error => of(new usuariosActions.UpdateCargaContactosFail(error))  )
        );
    })
  );

}