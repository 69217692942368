<div class="app-inner-layout mb-3">
  <div class="app-inner-layout__wrapper" [ngClass]="{'open-mobile-menu' : toggleMobileSidebar}">
    <div class="app-inner-layout__content card">
      <div class="table-responsive">
        <div class="app-inner-layout__top-pane card-header">
          <div class="pane-left">
            <!-- <div class="mobile-app-menu-btn">
              <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : toggleMobileSidebar}" (click) = "toggleMobileSidebar = !toggleMobileSidebar">
                <span class="hamburger-box"><span class="hamburger-inner"></span></span>
              </button>
            </div> -->
            <div class="card-header-title">Cargar imágenes</div>
          </div>
          <div class="pane-right">
            <a [routerLink]="['/auth/repositorios']" class="ml-2 btn btn-default">Cancelar</a>
          </div>
        </div>
        <div class="card-body mobile-app-menu-btn border-bottom m-0">
          <ul class="nav flex-column">
            <li class="nav-item mb-3">
              <input type="text" class="form-control" placeholder="Nombre del album" name="nombreAlbum" id="nombreAlbum" #nombreAlbum="ngModel" [(ngModel)]="album.nombre" required>
            </li>
            <li class="nav-item mb-3">
              <textarea type="text" class="form-control" placeholder="Descripción (Opcinal)" name="descripcionAlbum" id="descripcionAlbum" #descripcionAlbum="ngModel" [(ngModel)]="album.descripcion"></textarea>
            </li>
            <li class="nav-item mb-3">
              <small class="text-muted">Las etiquetas se agregan a las nuevas imágenes que esta cargando</small>
              <input type="text" class="form-control" placeholder="Etiquetas para imágenes" name="etiquetasGeneral" id="etiquetasGeneral" #etiquetasGeneral="ngModel" [(ngModel)]="etiquetas">
            </li>
            <li class="nav-item mb-3">
              <div class="d-flex">
                Total de imágenes: {{files.length}}
                <button type="button" class="btn btn-outline-focus btn-pill btn-sm ml-auto" (click)="quitarTodas()">Quitar todas</button>
              </div>
            </li>
            <li class="nav item mb-3">
              <a class="btn btn-link btn-block text-center" (click)="fileInput.click()">
                <i class="fa fa-images"></i> Agregar imágenes
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body" style="min-height: 85vh; max-height: 95vh; overflow: hidden; overflow-y: auto;" appDragDropFile (fileDropped)="uploadFile($event)">
          <div class="h4 text-center text-muted pointer"  (click)="fileInput.click()">Drag & Drop images or click here</div>
          <div class="uploadfilecontainer" (click)="fileInput.click()" *ngIf="!files.length">
            <div class="m-auto text-center" style="margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);">
              <img [src]="'/assets/images/cloud-up.svg' | safe: 'url'" alt="Cloud up files" style="width: 200px; height: auto;" class="img-squad">
              <div class="uploadfiletext">Arrastra fotos aquí...</div>
            </div>
          </div>
          <input hidden type="file" multiple accept="image/*, video/*" #fileInput (change)="uploadFile($event.target.files)">
          <div class="form-group">
            <div class="row mb-5">
              <div class="col-lg-3 col-md-4 col-sm-6 mb-3" *ngFor="let file of files; let i= index">
                <div class="card">
                  <div class="card-header p-0 img-loading">
                    <img class="card-img-top" *ngIf="file.type !== 'mp4'" [ngClass]="{'opacity-5': file.loading}" [src]="file.urlPath | safe: 'url'" [alt]="file.nombre">
                    <img class="card-img-top" *ngIf="file.type === 'mp4'" [ngClass]="{'opacity-5': file.loading}" src="assets/images/video/play-icon.jpg" [alt]="file.nombre">
                  </div>
                  <div class="card-body p-2">
                    <input type="text" placeholder="Etiquetas..." [disabled]="file.loading || file.loaded" class="form-control" #etiquetas{{i}}="ngModel" [(ngModel)]="file.etiquetas">
                    <div class="widget-content p-0 pb-0 small">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-subheading text-uppercase">
                            {{file.type}} {{file.size | fileSize}}
                          </div>
                        </div>
                        <div class="widget-content-right" *ngIf="!file.loading">
                          <button type="button" (click)="deleteAttachment(i)" class="border-0 btn btn-pill btn-outline-danger">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-0 text-center" *ngIf="file.progress">
                    <ngb-progressbar type="success" *ngIf="!file.error" [value]="file.progress" [max]="file.progressTotal" [striped]="true" class="text-center">
                      <span *ngIf="file.progress < file.progressTotal">
                        {{file.progress | fileSize}} / {{file.progressTotal | fileSize}}
                      </span>
                      <span *ngIf="file.loaded">¡Completado!</span>
                    </ngb-progressbar>
                    <ngb-progressbar type="danger" *ngIf="file.error" [value]="100" [max]="100" class="text-center">
                      <span *ngIf="file.error">¡Error!</span>
                    </ngb-progressbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-app-menu-btn m-0 p-3">
          <button class="btn-wide btn-pill btn btn-success btn-lg btn-block" (click)="onSubmit()">Registrar <i class="fa fa-save"></i></button>
        </div>
      </div>
    </div>
    <div class="app-inner-layout__sidebar card" style="width: 320px; flex: 0 0 400px;">
      <div class="card-header">
        <div class="card-header-title">Crear Album</div>
      </div>
      <div class="card-body">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <input type="text" class="form-control" placeholder="Nombre del album" name="nombreAlbum" id="nombreAlbum" #nombreAlbum="ngModel" [(ngModel)]="album.nombre" required>
          </li>
          <li class="nav-item mb-3">
            <textarea type="text" class="form-control" placeholder="Descripción (Opcinal)" name="descripcionAlbum" id="descripcionAlbum" #descripcionAlbum="ngModel" [(ngModel)]="album.descripcion"></textarea>
          </li>
          <li class="nav-item mb-3">
            <small class="text-muted">Las etiquetas se agregan a las nuevas imágenes que esta cargando</small>
            <input type="text" class="form-control" placeholder="Etiquetas para imágenes" name="etiquetasGeneral" id="etiquetasGeneral" #etiquetasGeneral="ngModel" [(ngModel)]="etiquetas">
          </li>
          <li class="nav-item mb-3">
            <div class="d-flex">
              Total de imágenes: {{files.length}}
              <button type="button" class="btn btn-outline-focus btn-pill btn-sm ml-auto" (click)="quitarTodas()">Quitar todas</button>
            </div>
          </li>
          <li class="nav item mb-3">
            <a class="btn btn-link btn-block text-center" (click)="fileInput.click()">
              <i class="fa fa-images"></i> Agregar imágenes
            </a>
          </li>
        </ul>
      </div>
      <div class="app-inner-layout__sidebar-footer pb-3 text-center card-footer">
        <button class="btn-wide btn-pill btn btn-success btn-lg btn-block" (click)="onSubmit()">Registrar <i class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>