import * as fromUsuario from '../actions';
import { Contacto } from 'src/app/models/contacto';
import { Oportunidad } from 'src/app/models/oportunidades';
import { VentaPropietario } from 'src/app/models/reporte';
import { ReporteAutoActualizacion } from '../../models/auto-actualizacion';

export interface ReporteState {
  sepomex: Contacto[];
  totalSepomex: number;
  totalFiltroSepomex: number;
  autoActualizacion: ReporteAutoActualizacion[];
  totalAutoActualizacion: number;
  totalFiltroAutoActualizacion: number;
  cumpleannos: Contacto[];
  totalCumpleannos: number;
  totalFiltroCumpleannos: number;
  ventasEtapas: Oportunidad[];
  ventasPropietario: VentaPropietario[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: ReporteState = {
  sepomex: [],
  totalSepomex: 0,
  totalFiltroSepomex: 0,
  autoActualizacion: [],
  totalAutoActualizacion: 0,
  totalFiltroAutoActualizacion: 0,
  cumpleannos: [],
  totalCumpleannos: 0,
  totalFiltroCumpleannos: 0,
  ventasEtapas: [],
  ventasPropietario: [],
  loaded: false,
  loading: false,
  error: null
};

export function reportesReducer( state = estadoInicial, action: fromUsuario.reportes ): ReporteState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_REPORTE_SEPOMEX:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_SEPOMEX_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sepomex: [...action.contactos],
        totalSepomex: action.total,
        totalFiltroSepomex: action.totalFiltro
      };
    case fromUsuario.CARGAR_REPORTE_SEPOMEX_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_REPORTE_SEPOMEX:
      return {
        ...state,
				sepomex: [],
        totalSepomex: 0,
        totalFiltroSepomex: 0
      };
    case fromUsuario.CARGAR_REPORTE_CUMPLEANNOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_CUMPLEANNOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cumpleannos: [...action.contactos],
        totalCumpleannos: action.total,
        totalFiltroCumpleannos:  action.totalFiltro,
      };
    case fromUsuario.CARGAR_REPORTE_CUMPLEANNOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_REPORTE_CUMPLEANNOS:
      return {
        ...state,
				cumpleannos: [],
        totalCumpleannos: 0,
        totalFiltroCumpleannos: 0,
      };
    case fromUsuario.CARGAR_REPORTE_VENTAS_ETAPAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_VENTAS_ETAPAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ventasEtapas: [...action.oportunidades]
      };
    case fromUsuario.CARGAR_REPORTE_VENTAS_ETAPAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_REPORTE_VENTAS_ETAPAS:
      return {
        ...state,
				ventasEtapas: []
      };
    case fromUsuario.CARGAR_REPORTE_VENTAS_PROPIETARIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_VENTAS_PROPIETARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ventasPropietario: [...action.reporte]
      };
    case fromUsuario.CARGAR_REPORTE_VENTAS_PROPIETARIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_REPORTE_VENTAS_PROPIETARIOS:
      return {
        ...state,
				ventasPropietario: []
      };
    case fromUsuario.CARGAR_REPORTE_AUTO_ACTUALICACION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_REPORTE_AUTO_ACTUALICACION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        autoActualizacion: [...action.contactos],
        totalAutoActualizacion: action.total,
        totalFiltroAutoActualizacion: action.totalFiltro
      };
    case fromUsuario.CARGAR_REPORTE_AUTO_ACTUALICACION_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_REPORTE_AUTO_ACTUALIZACION:
      return {
        ...state,
        autoActualizacion: [],
        totalAutoActualizacion: 0,
        totalFiltroAutoActualizacion: 0
      };
    default:
      return state;
  }
}
