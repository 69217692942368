import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'complementarios'
})
export class ComplementariosPipe implements PipeTransform {

  transform(items: any, filter: any[]): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: any) => this.applyFilter(item, filter));
  }

  applyFilter(scope: any, filter: any[]): boolean {
    const exist = filter.every( (search) => {
      if (search.checked) {
        if (search.requiere) {
          if (scope[search.param]) {
            if (typeof scope[search.param] === 'string') {
              if (scope[search.param] !== '') {
                return true;
              }
            } else if (typeof scope[search.param] === 'number') {
              if (scope[search.param] > 0) {
                return true;
              }
            }
          }
        } else {
          if (!scope[search.param]) {
            return true;
          }
        }
      } else {
        return true;
      }
    });
    return exist;
  }

}
