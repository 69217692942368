import { Component, OnInit, OnDestroy } from '@angular/core';
import { GaleriaRepositorio } from '../../../../models/galeria-repositorio';
import { Pagination } from '../../../../models/pagination';
import { Identity } from '../../../../models/user';
import { Subscription } from 'rxjs';
import { Album } from '../../../../models/album';
import { GaleriaRepositorioService } from '../../../../services/galeria-repositorio.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '../../../../store/app.reducer';
import { Store } from '@ngrx/store';
import * as usuariosActions from 'src/app/store/actions';
import { GLOBAL } from '../../../../models/global';
import { ImagesLightboxModalComponent } from '../../../../shared/components/images-lightbox-modal/images-lightbox-modal.component';
import { AnyScopePipe } from '../../../../pipes/any-scope.pipe';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ver-album-repositorios',
  templateUrl: './ver-album-repositorios.component.html',
  styleUrls: ['./ver-album-repositorios.component.scss']
})
export class VerAlbumRepositoriosComponent implements OnInit, OnDestroy {
  galleryOptions: any[];
  galleryImages: GaleriaRepositorio[] = [];
  paginacion: Pagination = new Pagination(null);
  listSearch: string[] = [];
  identity: Identity = new Identity(null);
  subcriptionAuth: Subscription = new Subscription(null);
  subcriptionGaleria: Subscription = new Subscription(null);
  buscador = false;
  url = '';
  album: Album = new Album(null);

  constructor(
    private archivosService: GaleriaRepositorioService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.url = GLOBAL.url;
    this.paginacion.page = 1;
    this.paginacion.pageSize = 24;
    this.paginacion.optionSize = [
      { value: 12, description: '12 elementos por hoja' },
      { value: 24, description: '24 elementos por hoja' },
      { value: 48, description: '48 elementos por hoja' }
    ];
  }

  ngOnInit() {
    this.subcriptionAuth = this.store.select('auth').subscribe( state => {
      this.identity = state.identity;
    });
    this.subcriptionGaleria = this.store.select('galeria').subscribe( state => {
      this.galleryImages = state.archivos;
      this.album = state.album;
    });
    this.route.params.subscribe(params => {
      const userId = params.id;
      if (userId > 0) {
        this.store.dispatch(new usuariosActions.CargarGaleriaRepositorioAlbumImagenes(userId));
      } else {
        this.album = new Album(null);
        this.galleryImages = [];
      }
    });
  }

  ngOnDestroy() {
    this.subcriptionAuth.unsubscribe();
    this.subcriptionGaleria.unsubscribe();
  }

  openModal(index: number) {
    // document.getElementById('myModal').style.display = 'block';
    const modalRef = this.modalService.open(ImagesLightboxModalComponent, { windowClass:'image-lighbox', backdropClass: 'dark-backdrop' });
    const pipeAnyScope = new AnyScopePipe();
    let lista = [...this.galleryImages];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    modalRef.componentInstance.images = lista;
    modalRef.componentInstance.slideIndex = ( (this.paginacion.page - 1) * this.paginacion.pageSize) + index;
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(',').map( item => item.trim() );
    this.buscador = true;
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(',').trim();
    this.buscador = true;
  }

  deleteFile(archivo: GaleriaRepositorio) {
    this.archivosService.eliminarImagen(archivo).subscribe(
      (response) => {
        this.store.dispatch(new usuariosActions.EliminarImagenGaleriaRepositorioSuccess(archivo));
      }, (error) => {
        this.store.dispatch(new usuariosActions.EliminarImagenGaleriaRepositorioFail(error));
      }
    );
  }

  toggleVideo(i){
    console.log(i);
    let myVideo: any = document.getElementById("videoPlayer-" + i);
    if (myVideo.paused){
      myVideo.play();
    }
    else{
      myVideo.pause();
      // myVideo.currentTime = 0;
    } 
  }

}
