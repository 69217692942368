import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/models/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getResumen(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/resumenes', {headers});
  }

  getLineasAsignadas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/lineas/asignadas', {headers});
  }

  getTiposCompaniasTelefonicas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/tipos/companias-telefonicas', {headers});
  }

  getTiposCompaniasTelefonicasFiliales(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/tipos/companias-telefonicas/filiales', {headers});
  }

  getTiposLineas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/tipos/lineas', {headers});
  }

  getTiposLineasFiliales(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/tipos/lineas/filiales', {headers});
  }

  getEstatusDispositivos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/dispositivos/estatus', {headers});
  }

  getEstatusLineas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/lineas/estatus', {headers});
  }

  getEstatusLineasFiliales(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/lineas/estatus/filiales', {headers});
  }

  getListaTiposCompaniasTelefonicas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/companias-telefonicas', {headers});
  }

  getListaTiposCompaniasTelefonicasFiliales(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/companias-telefonicas/filiales', {headers});
  }

  getTiposDispositivos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/tipos/dispositivos', {headers});
  }

  getDispositivosMarcas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/dispositivos/marcas', {headers});
  }

  getTiposCompaniasTelefonicasDispositivos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/dispositivos/companias-telefonicas', {headers});
  }

  getListaMarcasRadios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/radios/marcas', {headers});
  }

  getListaClientesGps(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/gps/clientes', {headers});
  }

  getTiposGps(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/gps/resumen', {headers});
  }

  getCompaniasLineasGps(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/gps/companias', {headers});
  }

  getLineContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/line/contactos', {headers});
  }

  getLineEmpresas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/line/empresas', {headers});
  }

  getLineOportunidades(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/dashboard/line/oportunidades', {headers});
  }

  getActividadesPendientesDia(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/dashboard/actividades/pendientes/dia', {headers});
  }

}
