import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { UsuariosService } from '../../services/usuarios.service';

@Injectable()
export class UsuariosEffects {

  constructor(
    private actions$: Actions,
    public usuariosService: UsuariosService
  ) {}

  @Effect()
  cargarUsuarios$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_USUARIOS ),
    switchMap( () => {
      return this.usuariosService.getUsers(0)
        .pipe(
          map( (users) => {
            return new usuariosActions.CargarUsuariosSuccess(users.usuarios);
          }),
          catchError( error => of(new usuariosActions.CargarUsuariosFail(error))  )
        );
    })
  );

  @Effect()
  eliminarUsuarios$ = this.actions$.pipe(
    ofType( usuariosActions.ELIMINAR_USUARIOS ),
    switchMap( (action: any) => {
      return this.usuariosService.deleteUser(action.id)
        .pipe(
          tap( request => new usuariosActions.CargarNotificacionesMini(1, 20, 0)),
            map( users => {
              return new usuariosActions.EliminarUsuariosSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.CargarUsuariosFail(error))  )
        );
    })
  );


}
