export interface Serie {
  name?: string;
  type?: string;
  data?: number[];
}

export class DashboardResumen {
  public usuarios: number;
  public empresas: number;
  public contactos: number;
  public oportunidades: number;
  public campanias: number;
  public repositorio: number;
  public archivos: number;
  public tickets: number;
  constructor(
    obj: ObjDashboardResumen
  ) {
    this.usuarios = obj && obj.usuarios ? obj.usuarios : 0;
    this.empresas = obj && obj.empresas ? obj.empresas : 0;
    this.contactos = obj && obj.contactos ? obj.contactos : 0;
    this.oportunidades = obj && obj.oportunidades ? obj.oportunidades : 0;
    this.campanias = obj && obj.campanias ? obj.campanias : 0;
    this.repositorio = obj && obj.repositorio ? obj.repositorio : 0;
    this.archivos = obj && obj.archivos ? obj.archivos : 0;
    this.tickets = obj && obj.tickets ? obj.tickets : 0;
  }
}
interface ObjDashboardResumen {
  usuarios: number;
  empresas: number;
  contactos: number;
  oportunidades: number;
  campanias: number;
  repositorio: number;
  archivos: number;
  tickets: number;
}

export class ListaResumen {
  public id: number;
  public nombre: number;
  public cantidad: number;
  public asignadas: number;
  public suspendidas: number;
  public libres: number;
  constructor(
    obj: ObjListaResumen
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : 0;
    this.cantidad = obj && obj.cantidad ? obj.cantidad : 0;
    this.asignadas = obj && obj.asignadas ? obj.asignadas : 0;
    this.suspendidas = obj && obj.suspendidas ? obj.suspendidas : 0;
    this.libres = obj && obj.libres ? obj.libres : 0;
  }
}
interface ObjListaResumen {
  id: number;
  nombre: number;
  cantidad: number;
  asignadas: number;
  suspendidas: number;
  libres: number;
}

export class ListaGps {
  public cliente: string;
  public total: number;
  public otros: number;
  public fijo: number;
  public portatil: number;
  constructor(
    obj: ObjListaGps
  ) {
    this.cliente = obj && obj.cliente ? obj.cliente : '';
    this.total = obj && obj.total ? obj.total : 0;
    this.otros = obj && obj.otros ? obj.otros : 0;
    this.fijo = obj && obj.fijo ? obj.fijo : 0;
    this.portatil = obj && obj.portatil ? obj.portatil : 0;
  }
}
interface ObjListaGps {
  cliente: string;
  total: number;
  otros: number;
  fijo: number;
  portatil: number;
}

export class CompaniaLineaGps {
  public compania: number;
  public desCompania: string;
  public total: number;
  public filial: number;
  public desFilial: string;
  constructor(
    obj: ObjCompaniaLineaGps
  ) {
    this.compania = obj && obj.compania ? obj.compania : 0;
    this.desCompania = obj && obj.desCompania ? obj.desCompania : '';
    this.total = obj && obj.total ? obj.total : 0;
    this.filial = obj && obj.filial ? obj.filial : 0;
    this.desFilial = obj && obj.desFilial ? obj.desFilial : '';
  }
}
interface ObjCompaniaLineaGps {
  compania: number;
  desCompania: string;
  total: number;
  filial: number;
  desFilial: string;
}
