<div [ngClass]="{
'closed-sidebar' : globals.toggleSidebar,
'closed-sidebar-md' : globals.toggleSidebarMobile,
'settings-open' : globals.toggleThemeOptions,
'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
'header-menu-open' : globals.toggleHeaderMobile,
'drawer-open' : globals.toggleDrawer,
'fixed-footer' : globals.toggleFixedFooter
}" [class]="'app-container app-theme-white'">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="app-main__outer">
    <div class="app-main__inner">
      <div [@baseAnimation]="o.isActivated ? o.activatedRoute : ''">
        <perfect-scrollbar [autoPropagation]="true">
          <router-outlet #o="outlet"></router-outlet>
        </perfect-scrollbar>
      </div>
    </div>
    <app-loader *ngIf="cargando"></app-loader>
    <app-footer></app-footer>
  </div>
  <app-drawer></app-drawer>
  <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
<!-- <ngx-loading [show]="cargando" [template]="customLoadingTemplate"></ngx-loading> -->
<ng-template #customLoadingTemplate>
  <app-loader></app-loader>
</ng-template>
