import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyScope'
})
export class AnyScopePipe implements PipeTransform {

  transform(items: any[], filter: string): any[] {
    if (!items || !filter) {
      return items; 
    }
    filter = this.getCleanedString(filter, true);
    const listSearch = filter.split(' ');
    // let temp = this.getCleanedString(JSON.stringify(items), false);
    // items = JSON.parse(temp);
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: any) => this.applyFilter(item, listSearch));
  }

  /**
   * Perform the filtering.
   *
   * @param {any} scope The object to compare to the filter.
   * @param {string[]} filter The filter to apply.
   * @return {boolean} True if param of object satisfies filters, false if not.
   */
  applyFilter(scope: any, filter: string[]): boolean {
    let temp = this.getCleanedString(JSON.stringify(scope), false);
    scope = JSON.parse(temp);
    // filter items array, items which match and return true will be kept, false will be filtered out
    const exist = filter.every( (search) => {
      for (const field in scope) {
        // if (typeof scope[field] === 'string') {
          if (search && search.trim() !== '') {
            let regresa;
            if (field.toLowerCase() === 'id') {
              regresa = ('#' + scope[field]).toLowerCase().indexOf(search.toLowerCase().trim()) > -1;
            } else {
              regresa = ('' + scope[field]).toLowerCase().indexOf(search.toLowerCase().trim()) > -1;
            }
            if (regresa) {
              return true;
            }
          } else {
            return true;
          }
        // } else if (typeof scope[field] === 'number') {
        //   if (search && search.trim() !== '') {
        //     const regresa = scope[field] === Number(search.trim());
        //     if (regresa) {
        //       return true;
        //     }
        //   } else {
        //     return true;
        //   }
        // }
      }
    });
    return exist;
  }

  getCleanedString(cadena: string, trim?: boolean, lowerCase?: boolean, deleteSimbols?: boolean) {

    if (trim) {
      cadena = cadena.trim();
    }
    // Definimos los caracteres que queremos eliminar
    const specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
 
    if (deleteSimbols) {
    // Los eliminamos todos
      for (var i = 0; i < specialChars.length; i++) {
        cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      }
    }
 
    if (lowerCase) {
      // Lo queremos devolver limpio en minusculas
      cadena = cadena.toLowerCase();
    }
 
    // // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
    // cadena = cadena.replace(/ /g,"_");
 
    // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
    cadena = cadena.replace(/[ÀÁÂÃÄÅÆ]/g,"A");
    cadena = cadena.replace(/[ÈÉÊË]/g,"E");
    cadena = cadena.replace(/[ÌÍÎÏ]/g,"I");
    cadena = cadena.replace(/[ÒÓÔÕÖØ]/g,"O");
    cadena = cadena.replace(/[ÙÚÛÜ]/g,"U");
    cadena = cadena.replace(/Ñ/g,"N");
    cadena = cadena.replace(/[àáâãäåæ]/g,"a");
    cadena = cadena.replace(/[èéêë]/g,"e");
    cadena = cadena.replace(/[ìíîï]/g,"i");
    cadena = cadena.replace(/[òóôõöø]/g,"o");
    cadena = cadena.replace(/[ùúûü]/g,"u");
    cadena = cadena.replace(/ñ/g,"n");
    return cadena;
  }

}
