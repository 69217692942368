export const environment = {
  production: true,
  url: 'https://api.sea.systemsjivabe.com',
  path: 'https://sea.systemsjivabe.com',
  captcha: '6LfbenkiAAAAAN_JaqUYGui5vj9-qkGVfl4FqMlJ',
  encryptKey: 'egIgFxsbHIPg5cjZrEkRJtwxqiCjUgkT',
  encryptIV: 'YG8EsFzpWJdk7OjIUKGycABogkdPy2On',
  apiKeyMaps: 'AIzaSyDDVYUfjeFZRI2VauO3YiATG-UzeF49_Fc',
  emailsDns: [
    '@seguridadenamerica.com.mx'
  ],
  title: 'Gregory - '
};


// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   //url: 'https://api.test.sea.jivabe.com',
//   //path: 'https://test.sea.jivabe.com',
//    url: 'http://localhost/api.sea',
//    path: 'http://localhost:4200',
//   captcha: '6LfbenkiAAAAAN_JaqUYGui5vj9-qkGVfl4FqMlJ',
//   encryptKey: 'FRBCESw3doqSx96pCYW4MiUp9Guzn22X',
//   encryptIV: 'S7I6nxDiVrvF9oRWJ1gi5A21Cj05v2Ow',
//   apiKeyMaps: '',
//   emailsDns: [
//     '@seguridadenamerica.com.mx',
//     '@jivabe.mx'
//   ],
//   title: 'Gregory - '
// };

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
