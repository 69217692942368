import * as fromUsuario from '../actions';
import { Empresa } from 'src/app/models/empresa';

export interface EmpresaState {
  empresas: Empresa[];
  empresa: Empresa;
  loaded: boolean;
  loading: boolean;
  error: any;
  totalFiltro: number;
  total: number;
}

const estadoInicial: EmpresaState = {
  empresas: [],
  empresa: new Empresa(null),
  loaded: false,
  loading: false,
  error: null,
  totalFiltro: 0,
  total: 0
};

export function empresaReducer( state = estadoInicial, action: fromUsuario.empresaAcciones ): EmpresaState {

  switch ( action.type ) {
    case fromUsuario.CARGAR_EMPRESAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        empresas: [...action.empresas]
      };
    case fromUsuario.CARGAR_EMPRESAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_EMPRESAS2:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_EMPRESAS2_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        empresas: [...action.empresas],
        totalFiltro: action.totalFiltro,
        total: action.total
      };
    case fromUsuario.CARGAR_EMPRESAS2_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.CARGAR_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_EMPRESA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        empresa: {...action.empresa}
      };
    case fromUsuario.CARGAR_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_EMPRESA_SUCCESS:
      const itm = state.empresas.find( item => item.id === action.empresa.id);
      if (itm && itm.id) {
        state.empresas = state.empresas.map( item => {
          if (item.id === action.empresa.id) {
            item = {...action.empresa};
          }
          return item;
        });
      } else {
        state.empresas.push(action.empresa);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ELIMINAR_EMPRESA:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ELIMINAR_EMPRESA_SUCCESS:
      state.empresas = state.empresas.filter( item => item.id !== action.id);
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ELIMINAR_EMPRESA_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.LIMPIAR_EMPRESA:
      return {
        ...state,
				empresa: new Empresa(null)
      };
    case fromUsuario.ACTUALIZA_EMPRESAS_DUPLICADOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_EMPRESAS_DUPLICADOS_SUCCESS:
      state.empresas = [ ...state.empresas.filter( obj => !action.duplicados.find( item => obj.id === item.id) ).map( item => {
        item.check = false;
        return item;
      }) ];
      state.total -= action.duplicados.length;
      state.totalFiltro -= action.duplicados.length;
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_EMPRESAS_DUPLICADOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

