import { Action } from '@ngrx/store';
import { Directorio } from 'src/app/models/directorio';
import { ArchivoGeneral } from '../../models/archivo-general';

export const CARGAR_ARCHIVOS_GENERALES = '[Archivos General] Cargar archivos general';
export const CARGAR_ARCHIVOS_GENERALES_SUCCESS = '[Archivos General] Cargar archivos general success';
export const CARGAR_ARCHIVOS_GENERALES_FAIL = '[Archivos General] Cargar archivos general fail';
export const ELIMINAR_ARCHIVO_GRAL = '[Archivos General] Eliminar Archivo';
export const ELIMINAR_ARCHIVO_GRAL_SUCCESS = '[Archivos General] Eliminar Archivo success';
export const ELIMINAR_ARCHIVO_GRAL_FAIL = '[Archivos General] Eliminar Archivo fail';
export const CARGAR_ARCHIVO_GRAL = '[Archivos General] Cargar archivo general';
export const CARGAR_ARCHIVO_GRAL_SUCCESS = '[Archivos General] Cargar archivo general success';
export const CARGAR_ARCHIVO_GRAL_FAIL = '[Archivos General] Cargar archivo general fail';
export const REGISTRAR_ARCHIVO_GENERAL = '[Archivos General] Registrar archivo';
export const REGISTRAR_ARCHIVO_GENERAL_SUCCESS = '[Archivos General] Registrar archivo success';
export const REGISTRAR_ARCHIVO_GENERAL_FAIL = '[Archivos General] Registrar archivo fail';
export const CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL = '[Archivos General] Cargar archivo general directorios';
export const CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL_FAIL = '[Archivos General] Cargar archivo general directorios fail';
export const CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL_SUCCESS = '[Archivos General] Cargar archivo general directorios success';
export const REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL = '[Archivos General] Registrar archivo general directorio';
export const REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL_FAIL = '[Archivos General] Registrar archivo general directorio fail';
export const REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL_SUCCESS = '[Archivos General] Registrar archivo general directorio success';
export const ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL = '[Archivos General] Eliminar archivo general directorio';
export const ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL_FAIL = '[Archivos General] Eliminar archivo general directorio fail';
export const ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL_SUCCESS = '[Archivos General] Eliminar archivo general directorio success';

export class CargarArchivoGeneral implements Action {
  readonly type = CARGAR_ARCHIVO_GRAL;

  constructor( public id: number) {}
}

export class CargarArchivoGeneralFail implements Action {
  readonly type = CARGAR_ARCHIVO_GRAL_FAIL;

  constructor( public payload: any ) {}
}

export class CargarArchivoGeneralSuccess implements Action {
  readonly type = CARGAR_ARCHIVO_GRAL_SUCCESS;

  constructor(public archivo: ArchivoGeneral) {}
}

export class CargarArchivosGeneral implements Action {
  readonly type = CARGAR_ARCHIVOS_GENERALES;
}

export class CargarArchivosGeneralFail implements Action {
  readonly type = CARGAR_ARCHIVOS_GENERALES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarArchivosGeneralSuccess implements Action {
  readonly type = CARGAR_ARCHIVOS_GENERALES_SUCCESS;

  constructor(public archivos: ArchivoGeneral[]) {}
}

export class EliminarArchivoGeneral implements Action {
  readonly type = ELIMINAR_ARCHIVO_GRAL;

  constructor(public archivo: ArchivoGeneral) {}
}

export class EliminarArchivoGeneralFail implements Action {
  readonly type = ELIMINAR_ARCHIVO_GRAL_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarArchivoGeneralSuccess implements Action {
  readonly type = ELIMINAR_ARCHIVO_GRAL_SUCCESS;

  constructor(public archivo: ArchivoGeneral) {}
}

export class RegistrarArchivoGeneral implements Action {
  readonly type = REGISTRAR_ARCHIVO_GENERAL;

  constructor(public archivo: ArchivoGeneral) {}
}

export class RegistrarArchivoGeneralFail implements Action {
  readonly type = REGISTRAR_ARCHIVO_GENERAL_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarArchivoGeneralSuccess implements Action {
  readonly type = REGISTRAR_ARCHIVO_GENERAL_SUCCESS;

  constructor(public archivo: ArchivoGeneral) {}
}

export class CargarDirectoriosArchivoGeneral implements Action {
  readonly type = CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL;
}

export class CargarDirectoriosArchivoGeneralFail implements Action {
  readonly type = CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL_FAIL;

  constructor( public payload: any ) {}
}

export class CargarDirectoriosArchivoGeneralSuccess implements Action {
  readonly type = CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL_SUCCESS;

  constructor(public directorio: Directorio) {}
}

export class RegistrarDirectorioArchivoGeneral implements Action {
  readonly type = REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL;

  constructor(public padre: Directorio, public directorio: Directorio) {}
}

export class RegistrarDirectorioArchivoGeneralFail implements Action {
  readonly type = REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarDirectorioArchivoGeneralSuccess implements Action {
  readonly type = REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL_SUCCESS;

  constructor(public directorio: Directorio) {}
}

export class EliminarDirectorioArchivoGeneral implements Action {
  readonly type = ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL;

  constructor(public directorio: Directorio) {}
}

export class EliminarDirectorioArchivoGeneralFail implements Action {
  readonly type = ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL_FAIL;

  constructor( public payload: any ) {}
}

export class EliminarDirectorioArchivoGeneralSuccess implements Action {
  readonly type = ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL_SUCCESS;

  constructor(public directorio: Directorio) {}
}

export type archivosGeneralesAcciones = CargarArchivoGeneral |
                                        CargarArchivoGeneralFail |
                                        CargarArchivoGeneralSuccess |
                                        CargarArchivosGeneral |
                                        CargarArchivosGeneralFail |
                                        CargarArchivosGeneralSuccess |
                                        EliminarArchivoGeneral |
                                        EliminarArchivoGeneralFail |
                                        EliminarArchivoGeneralSuccess |
                                        RegistrarArchivoGeneral |
                                        RegistrarArchivoGeneralFail |
                                        RegistrarArchivoGeneralSuccess |
                                        CargarDirectoriosArchivoGeneral |
                                        CargarDirectoriosArchivoGeneralFail |
                                        CargarDirectoriosArchivoGeneralSuccess |
                                        RegistrarDirectorioArchivoGeneral |
                                        RegistrarDirectorioArchivoGeneralFail |
                                        RegistrarDirectorioArchivoGeneralSuccess |
                                        EliminarDirectorioArchivoGeneral |
                                        EliminarDirectorioArchivoGeneralFail |
                                        EliminarDirectorioArchivoGeneralSuccess;
