import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EstatusSuscriptor, FilterTypeCampanaStatus, ReporteCampana } from 'src/app/models/campana';
import { ReportescampanaService } from 'src/app/services/reportescampanas.service';
import { Pagination } from 'src/app/models/pagination';
import { AnyScopePipe } from 'src/app/pipes/any-scope.pipe';
import { ExcelService } from 'src/app/services/excel.service';
import { GLOBAL } from 'src/app/models/global';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes-campana.component.html',
  styleUrls: ['./reportes-campana.component.scss']
})
export class ReportesCampanaComponent implements OnInit {
  heading = 'Campaña ';
  subheading = 'Información sobre el rendimiento y el contenido de la campaña ';
  icon = 'pe-7s-mail icon-gradient bg-dark';
  public loading = false;
  public filterActive = false;
  public link = [];
  public estatussuscriptores: EstatusSuscriptor[] = [];
  public campanaenviada: ReporteCampana = new ReporteCampana(null);
  public paginacion: Pagination = new Pagination(null);
  public listSearch: string[] = [];
  public isCollapsed = false;
  public orderType: any[] = [];
  public series: any[] = [];
  public url = '';
  public hoy = new Date();
  public total = 0;
  public contry = 'mx';
  public filterTipos: FilterTypeCampanaStatus[] = [];
  public informacion: boolean = false;
  constructor(
    private reportesservices: ReportescampanaService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private excelService: ExcelService
  ) {
    this.url = GLOBAL.url;
    this.filterTipos = [
      
      new FilterTypeCampanaStatus({ estatus: 2, nombre: 'Desuscrito', checked: false }),
      new FilterTypeCampanaStatus({ estatus: 3, nombre: 'Rebotado', checked: false }),
      new FilterTypeCampanaStatus({ estatus: 4, nombre: 'Spam', checked: false }),
      new FilterTypeCampanaStatus({ estatus: 5, nombre: 'Error Amazon', checked: false })
    ]
  }

  ngOnInit() {
    if (!this.loading) {
      this.route.params.subscribe(params => {
        const id = params.id;
        this.reportesservices.obtenerLinks(id).subscribe(respuesta => {
          this.link = respuesta.link;
          this.total = respuesta.total;
        });
        this.reportesservices.obtenerEstatusSuscriptor(id).subscribe(respuesta => {
          this.estatussuscriptores = respuesta.estatussuscriptores;
        });
        this.reportesservices.obtenerCampanaEnviada(id).subscribe(respuesta => {
          this.campanaenviada = respuesta.campanaenviada;
          this.loading = true;
          if (this.campanaenviada.estatus === 3) {
            console.log(this.campanaenviada);
          } else {
            this.router.navigate(['/auth/marketing/allcampanas']);
            Swal.fire(
              'Error',
              'Esta campaña no puede acceder a esta sección',
              'error'
            )
          };
        });
      });
    }
    console.log('Inicializa el componente Ver Reportes...');
  }

  showTipos(event) {
    this.filterTipos = [...event];
  }

  changeCheck() {
    this.filterTipos = [...this.filterTipos];
    setTimeout(() => {
      this.filterTipos = [...this.filterTipos];
    }, 5);
    console.log('presionaste');
  }


  printXLS() {
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    let lista = [...this.link];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    if (lista.length) {
      lista = lista.map(itemLista => {
        const obj: any = {};
        obj.NOMBRE = itemLista.url;
        obj.CLICKS_UNICOS = itemLista.clicksunicos;
        obj.CLICKS_TOTALES = itemLista.clickstotales;
        obj.PORCENTAJE = ((itemLista.clicksunicos / this.total) * 100).toFixed(2);
        return obj;
      });
      this.excelService.exportAsExcelFile(lista, 'actividad de links ' + i);
    }

  }

  printXLS_SUS() {
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    let lista = [...this.estatussuscriptores];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    if (lista.length) {
      lista = lista.map(itemLista => {
        const obj: any = {};
        obj.URL = itemLista.nombre;
        obj.CORREO = itemLista.email;
        obj.SUSCRITO = itemLista.confirmado;
        obj.SPAM = itemLista.spam;
        obj.REBOTADO = itemLista.rebotado;
        return obj;
      });
      this.excelService.exportAsExcelFile(lista, 'actividad de links ' + i);
    }
  }

  changeContry() {
    if (this.contry === 'mx') {
      this.contry = 'us';
    } else {
      this.contry = 'mx';
    }
  }

}
