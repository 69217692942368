import { Component, OnInit, OnDestroy } from '@angular/core';
import { VentaPropietario, VentaPropietarioSortType } from 'src/app/models/reporte';
import { Catalogo } from 'src/app/models/catalogo';
import { FilterType, Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { ExcelService } from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import { GLOBAL } from 'src/app/models/global';
import * as moment from 'moment';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AnyScopePipe } from 'src/app/pipes/any-scope.pipe';
import { MultiOpcionesSelectedPipe } from 'src/app/pipes/multi-opciones-selected.pipe';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-reporte-ventas-propietarios',
  templateUrl: './reporte-ventas-propietarios.component.html',
  styleUrls: ['./reporte-ventas-propietarios.component.scss']
})
export class ReporteVentasPropietariosComponent implements OnInit, OnDestroy {
  heading = 'Reporte de oportunidades por propietario';
  subheading = 'Oportunidades registradas en el sistema por propietario';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  public url: string;
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subscribeReporte: Subscription = new Subscription();
  public reportes: VentaPropietario[];
  public etapas: Catalogo[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public paginacionResponsable: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...VentaPropietarioSortType];
  public version = 1;
  public listSearch: string[] = [];
  public reportesTotal: { propietario: number, desPropietario: string, reporte: VentaPropietario[], collapsed: boolean }[] = [];
  public filterPropietarios: FilterType[] = [];
  public filterEtapas: FilterType[] = [];
  public dates: Date[] = [];
  public fromDate: NgbDate | null;
  public toDate: NgbDate | null;
  public sortType: string;
  public datePipe = new DatePipe("en-US");
  constructor(
    private store: Store<AppState>,
    private excelService: ExcelService,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.paginacion.sortType = '+desEtapa';
    this.paginacionResponsable.sortType = '+desPropietario';
    this.identity = new Identity(null);
    this.reportes = [];
    this.etapas = [];
    this.url = GLOBAL.url;
    this.fromDate = calendar.getNext(calendar.getToday(), 'd', - 30);
    this.toDate = calendar.getToday();
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 30);
    this.dates = [ fecha, new Date() ];
   }

  ngOnInit(): void {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeReporte = this.store.select('reportes').subscribe(
      (state) => {
        this.reportes = [...state.ventasPropietario];
        this.acutlizaCards();
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.etapas = [...state.etapas.filter(item => item.status)];
        this.filterEtapas = [...state.etapas.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterPropietarios = [...state.usuarios.map(item => {
          return new FilterType({id: item.id, nombre: item.descripcion, checked: false});
        })];
        this.acutlizaCards();
      }
    );
    console.log('Inicializa component Reporte-ventas-propietarios...');
    this.store.dispatch(new usuariosActions.ConsultaEtapas());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.CargarReporteVentasPropietarios(this.fromDate, this.toDate));
  }
  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeReporte.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  acutlizaCards(){
    this.reportesTotal = [];
    this.filterPropietarios.find( item => { this.reportesTotal.push({propietario: item.id, desPropietario: item.nombre, reporte: [], collapsed: true}); });
    if(this.reportesTotal.length > 0){
      this.reportesTotal.find(item => {
        item.reporte = [...this.reportes.filter(itm => itm.propietario === item.propietario)];
      });
    }
    this.reportesTotal = [...this.reportesTotal.filter(item => item.reporte.length > 0 )];
  }

  printCSV() {
    let excel;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    const pipeMultiOpcionesSelect = new MultiOpcionesSelectedPipe();
    let lista = [...this.reportes];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterPropietarios, parametro: 'propietario'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterEtapas, parametro: 'etapa'});
    if (lista) {
      excel = lista.map( item => {
        const obj: any = {};
        obj.PROPIETARIO = item.desPropietario;
        obj.ETAPA = item.desEtapa;
        obj.REGISTROS = item.cantidad;
        obj.IMPORTE = item.importe;
        return obj;
      });
      this.excelService.exportAsExcelFile(excel, 'reporte_oportunidades_propietarios' + i);
    }
  } 

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  changeEtapas(lista: FilterType[]) {
    this.filterEtapas = [...lista];
  }

  changePropietarios(lista: FilterType[]) {
    this.filterPropietarios = [...lista];
  }

  onDateSelection(fechas: Date[]) {
    if(fechas && fechas.length){
      const dates: string[] = [...fechas.map(item => { return this.datePipe.transform(item, 'yyyy-MM-dd'); })];
      this.fromDate = this.dateToStructDateModel(dates.shift());
      this.toDate = this.dateToStructDateModel(dates.pop());
      this.store.dispatch(new usuariosActions.CargarReporteVentasPropietarios(this.fromDate, this.toDate));
    }
  }

  dateToStructDateModel(date: string) {
    let data: string[] = date.split('-');
    return new NgbDate ( Number(data[0]),  Number(data[1]),  Number(data[2]));
	}

  searchClick() {
    this.paginacion.isActive = true;

    document.getElementById("searchOportunidades").focus();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacionResponsable.sortType || ('+' + descriptionSort) === this.paginacionResponsable.sortType ) {
      this.paginacionResponsable.sortReverse = !this.paginacionResponsable.sortReverse;
      if (this.paginacionResponsable.sortReverse) {
        this.paginacionResponsable.sortType = '-' + descriptionSort;
      } else {
        this.paginacionResponsable.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacionResponsable.sortType = '+' + descriptionSort;
      this.paginacionResponsable.sortReverse = false;
    }
  }


}
