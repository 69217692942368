import {Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {ConfigActions} from '../../themeoptions/store/config.actions';
import {ThemeOptions} from '../../theme-options';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { UiState, ToastrState, TypeAlert } from 'src/app/store/reducers';
import { baseAnimation } from 'src/app/animations/base.animation';
import { ToastrService } from 'ngx-toastr';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  animations: [ baseAnimation ]
})

export class BaseLayoutComponent implements OnInit, OnDestroy, AfterViewChecked {

  @select('config') public config$: Observable<any>;
  public cargando: boolean;
  private subscripcion: Subscription = new Subscription();
  private subscription: Subscription = new Subscription();
  title = 'Proyecto Jivabe';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    private store: Store<AppState>,
    private loadingBar: LoadingBarService,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService
  ) {
    this.cargando = false;
  }

  ngOnInit() {
    this.subscripcion = this.store.select('ui').subscribe(
      (state: UiState) => {
        this.cargando = state.isLoading || false;
        if (this.cargando) {
          this.loadingBar.start();
        } else {
          this.loadingBar.complete();
        }
      }
    );
    this.subscription = this.store.select('toastr').subscribe(
      (state: ToastrState) => {
        if (state.message || state.title) {
          switch (state.type) {
            case TypeAlert.SUCCESS:
              this.toastr.success(state.message, state.title);
              break;
            case TypeAlert.ERROR:
              this.toastr.error(state.message, state.title);
              break;
            case TypeAlert.WARNING:
              this.toastr.warning(state.message, state.title);
              break;
            case TypeAlert.INFO:
              this.toastr.info(state.message, state.title);
              break;
            default:
              break;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscripcion.unsubscribe();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }


}



