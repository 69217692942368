<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <!-- <div class="col-lg-1"> col-sm-7</div> -->
  <div class="col-lg-6">
		<form id="editOportunidadForm" name="editOportunidadForm" #editOportunidadForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="card main-card mb-3">
				<div class="card-header">
					Captura la información de la Oportunidad
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="nombreOportunidad" class="control-label">Nombre de la oportunidad</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.oportunidadesRegEdit" type="text" id="nombreOportunidad" name="nombreOportunidad" #nombreOportunidad="ngModel" [(ngModel)]="oportunidad.nombre"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el nombre de la oportunidad." required />
								<span *ngIf="!nombreOportunidad.valid && nombreOportunidad.touched"> El nombre es obligatorio</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required" *ngIf="identity.tipo <= 1">
						<div class="row">
							<div class="col-sm-4">
								<label for="propietarioOportunidad" class="control-label">Propietario</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="propietarios" [disabled]="!identity.permisos.oportunidadesRegEdit"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Propietario de la oportunidad."
									[selectOnTab]="true"
                  id="propietarioOportunidad"  name="propietarioOportunidad" #propietarioOportunidad="ngModel" [(ngModel)]="oportunidad.propietario" required>
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="importeOportunidad" class="control-label">Importe</label>
							</div>
							<div class="col-sm-8">
								<!-- <input [disabled]="!identity.permisos.oportunidadesRegEdit" type="text" number pattern="^[0-9]{4,5}$ || 0" id="importeOportunidad" name="importeOportunidad" #importeOportunidad="ngModel"
								[(ngModel)]="oportunidad.importe" class="form-control" placeholder="" maxlength="12" minlength="4" title="Capture el importe de la oportunidad." required/> -->


								<input [disabled]="!identity.permisos.oportunidadesRegEdit" id="importeOportunidad" name="importeOportunidad" #importeOportunidad="ngModel" [(ngModel)]="oportunidad.importe" class="form-control"
									autonumeric [options]="currencySettings" placeholder="" maxlength="12" minlength="4" title="Capture el importe de la oportunidad." required/>
								<!-- <span *ngIf="!importeOportunidad.valid && importeOportunidad.touched"> El importe es obligatorio</span> -->
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="fechaOportunidad" class="control-label">Fecha estimada</label>
							</div>
							<div class="col-sm-8">
								<input type="text"class="form-control" id="fechaOportunidad" name="fechaOportunidad" #fechaOportunidad="ngModel" bsDatepicker [(ngModel)]="oportunidad.fecha" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" [disabled]="!identity.permisos.oportunidadesRegEdit">
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="tipoNegocioOportunidad" class="control-label">Tipo de negocio</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="negocios" [disabled]="!identity.permisos.oportunidadesRegEdit"
                  bindLabel="nombre"
                  bindValue="nombre"
                  placeholder="Capture la etapa."
									[selectOnTab]="true"
                  id="tipoNegocioOportunidad"  name="tipoNegocioOportunidad" #tipoNegocioOportunidad="ngModel" [(ngModel)]="oportunidad.tipoNegocio" required>
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="etapaOportunidad" class="control-label">Etapa</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="etapas" [disabled]="!identity.permisos.oportunidadesRegEdit"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture la etapa."
									[selectOnTab]="true"
                  id="etapaOportunidad"  name="etapaOportunidad" #etapaOportunidad="ngModel" [(ngModel)]="oportunidad.etapa" required>
                </ng-select>
							</div>
						</div>
					</fieldset>
					<!-- <fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="empresasOportunidad" class="control-label">Empresa</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="empresas" (focusout)="contactosEmpresa()" [disabled]="!identity.permisos.oportunidadesRegEdit"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Capture la empresa."
									[selectOnTab]="true"
									[minTermLength]="3"
                  id="empresasOportunidad" name="empresasOportunidad" #empresasOportunidad="ngModel" [(ngModel)]="oportunidad.empresa" required>
                </ng-select>
							</div>
						</div>
					</fieldset> -->
					<fieldset class="form-group">
						<div class="row">
							<div class="col-sm-4">
								<label for="empresasOportunidad" class="control-label">Empresa</label>
							</div>
							<div class="col-sm-8">
								<input list="empresasOportunidadList" type="text" id="empresasOportunidad" name="empresasOportunidad" #empresasOportunidad="ngModel" [(ngModel)]="oportunidad.desEmpresa" class="form-control"
									title=" empresas" maxlength="100" placeholder="" [disabled]="!identity.permisos.oportunidadesRegEdit" (ngModelChange)="cargarEmpresas()" (focusout)="contactosEmpresa()"/>
								<datalist id="empresasOportunidadList" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" role="listitem" aria-autocomplete="list" >
									<option *ngFor="let item of empresas" class="list-group-item" [ngValue]="item.nombre" autocomplete="on" autocorrect="on" autocapitalize="off" spellcheck="false" role="listitem">{{item.nombre}}</option>
								</datalist>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="contactosOportunidad" class="control-label">Contactos</label>
							</div>
							<div class="col-sm-8">
								<ng-multiselect-dropdown [disabled]="!identity.permisos.oportunidadesRegEdit || !editar"
									id="contactosOportunidad"
									name="contactosOportunidad"
									#contactosOportunidad="ngModel"
									[placeholder]="'Contactos'"
									[settings]="dropdownSettings"
									[data]="contactos"
									[(ngModel)]="oportunidad.contactos"
									(onSelect)="onItemSelect($event)"
									(onSelectAll)="onSelectAll($event)"
									required>
								</ng-multiselect-dropdown>
							</div>
						</div>
					</fieldset>
				</div>
				<div class="grid-menu grid-menu-2col border-top">
					<div class="no-gutters row">
						<div class="col-sm-6" *ngIf="identity.permisos.oportunidadesListVer || identity.permisos.oportunidadesListEdit">
							<a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/oportunidades']">
								<i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
							</a>
						</div>
						<div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.oportunidadesRegEdit">
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="!editOportunidadForm.valid || (oportunidad | json) === (oportunidadInicial | json) || !oportunidad.contactos">
								<i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
							</button>
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
								<span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
								<br>
								Loading...
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
  </div>
  <div class="col-lg-6"></div>
</div>