<!-- <app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title> -->
<div class="row">
  <div class="col-lg-3">
    <div class="card">
      <img width="42" class="card-img-top" *ngIf="empresa.id" [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/0?v=' + version" alt="imagen de la empresa">
      <div class="card-body">
        <p class="text-center">Información de la empresa  <a [routerLink]="['/auth/empresas/register/' + empresa.id]"> <i class="fas fa-edit"></i></a></p>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Nombre </b>
          <span class="card-text"> {{empresa.nombre}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Industria</b>
          <span class="card-text">{{empresa.desIndustria}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Clasificación</b>
          <span class="card-text">{{empresa.desClasificacion}}</span>
        </div>
        <!-- <div class="mb-2">
          <b class="text-uppercase mr-3">Tipo</b>
          <span class="card-text">{{empresa.desTipo}}</span>
        </div> -->
        <div class="mb-2">
          <b class="text-uppercase mr-3">Clase</b>
          <span class="card-text">{{empresa.desClase}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Pagina web</b>
          <a class="card-text" [href]="empresa.url" target="_blank">{{empresa.url}}</a>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Propietario</b>
          <span class="card-text">{{empresa.desPropietario}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Publicidad</b>
          <span class="card-text">{{ empresa.publicidad ? 'Sí' : 'No'}} {{empresa.pagaPublicidad ? ' de paga' : ''}} {{empresa.publicidadCompetencia ? ' en ' + empresa.publicidadCompetencia : ''}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Fecha fundación</b>
          <span class="card-text">{{empresa.fecFundacion | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Teléfono</b>
          <span class="card-text">{{empresa.telefonoUno | phone}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Dirección</b>
          <span class="card-text">{{printDireccion()}}</span>
        </div>
        <div class="mb-2">
          <b class="text-uppercase mr-3">Categorías en seguridad</b>
          <ul class="">
            <li class="card-text" *ngIf="empresa.permisos.alarma">Alarma</li>
            <li class="card-text" *ngIf="empresa.permisos.asociacion">Asociación</li>
            <li class="card-text" *ngIf="empresa.permisos.blindaje">Blindaje</li>
            <li class="card-text" *ngIf="empresa.permisos.cableado">Cableado</li>
            <li class="card-text" *ngIf="empresa.permisos.cajaFuerte">Caja fuerte</li>
            <li class="card-text" *ngIf="empresa.permisos.controlAcceso">Control de acceso</li>
            <li class="card-text" *ngIf="empresa.permisos.controlConfianza">Control de confianza</li>
            <li class="card-text" *ngIf="empresa.permisos.consultoria">Consultoría</li>
            <li class="card-text" *ngIf="empresa.permisos.cursosEntrenamiento">Cursos de entrenamiento</li>
            <li class="card-text" *ngIf="empresa.permisos.custodiaMercancia">Custodia de mercancía</li>
            <li class="card-text" *ngIf="empresa.permisos.distribuidorMayorista">Distribuidor mayorista</li>
            <li class="card-text" *ngIf="empresa.permisos.equipamientoPolicia">Equipamiento Policíaco</li>
            <li class="card-text" *ngIf="empresa.permisos.equipoInspeccion">Equipo de inspección</li>
            <li class="card-text" *ngIf="empresa.permisos.guardia">Guardia</li>
            <li class="card-text" *ngIf="empresa.permisos.escolta">Escolta</li>
            <li class="card-text" *ngIf="empresa.permisos.incendio">Incendio</li>
            <li class="card-text" *ngIf="empresa.permisos.integradores">Integradores</li>
            <li class="card-text" *ngIf="empresa.permisos.investigacion">Investigación</li>
            <li class="card-text" *ngIf="empresa.permisos.rastreoVehicular">Rastreo vehicular</li>
            <li class="card-text" *ngIf="empresa.permisos.telecomTI">Telecom TI</li>
            <li class="card-text" *ngIf="empresa.permisos.trasladoValor">Traslado de valor</li>
            <li class="card-text" *ngIf="empresa.permisos.videovigilancia">Video vigilancia</li>
            <li class="card-text" *ngIf="empresa.permisos.otros">empresa.permisos.descripcion</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
		<div class="card mb-3 card-tabbed-header"  id="tabActividades">
			<ngb-tabset class="tabs-animated tabs-animated-line" [justify]="'fill'">
				<ngb-tab >
					<ng-template ngbTabTitle class="bg-white"><span>Historial</span></ng-template>
					<ng-template ngbTabContent style="background-color: transparent; padding: 0;" class="p-0 bg-white" >
            <div class="main-card mb-3 card card-shadow-primary" *ngFor="let actividad of actividades | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
              <div class="card-header with-border">
                <div class="card-header-title">
                  <div class="card-header-title" *ngIf="actividad.concepto === 1"> <i class="fas fa-edit"></i> Nota</div>
                  <div class="card-header-title" *ngIf="actividad.concepto === 2"> <i class="fas fa-phone-alt"></i> Llamada</div>
                  <div class="card-header-title" *ngIf="actividad.concepto === 3"> <i class="far fa-calendar-alt"></i> Reunión</div>
                  <div class="card-header-title" *ngIf="actividad.concepto === 4"> <i class="far fa-envelope"></i> Correo electrónico</div>
                  <div class="card-header-title" *ngIf="actividad.concepto === 5"> <i class="far fa-calendar-alt"></i> Reunión</div>
                </div>
                <div class="btn-actions-pane-right mr-4 text-black-50">
                  {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'dddd'}}, {{actividad.fecModifico | amLocale: 'es'  | amDateFormat: 'LL'}} {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'h:mm a'}}
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">Descripción</h5>
                <p class="card-text">{{actividad.descripcion}}</p>
                <div class="divider" *ngIf="actividad.concepto !== 1"></div>
                <h5 class="card-title" *ngIf="actividad.concepto === 2 || actividad.concepto === 3 || actividad.concepto === 4">Resultado</h5>
                <h5 class="card-title" *ngIf="actividad.concepto === 5">Motivo</h5>
                <p class="card-text" *ngIf="actividad.concepto !== 1">{{actividad.resultado}}</p>
                <div class="row" *ngIf="actividad.concepto !== 1">
                  <div class="col-3">
                    <h5 class="card-title">Fecha</h5>
                    <p class="card-text">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</p>
                  </div>
                  <div class="col-3">
                    <h5 class="card-title">Hora</h5>
                    <p class="card-text">{{actividad.hora | amLocale: 'es' | amDateFormat: 'LTS' | timeFormat}}</p>
                  </div>
                  <div class="col-3" *ngIf="actividad.concepto === 3">
                    <h5 class="card-title">Duración</h5>
                    <p class="card-text">{{actividad.duracion | timeDuration}}</p>
                  </div>
                </div>
                <div class="divider" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0"></div>
                <span class="row" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0">
                  <span class="col-6" *ngIf="actividad.contactos">
                    <h5 class="card-title">Contactos</h5>
                    <div class="avatar-wrapper avatar-wrapper-overlap">
                      <div class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos">
                        <div class="avatar-icon"><img  [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/'+ contacto.id + '?v=' + version" [title]="contacto.nombre" [alt]="contacto.nombre"></div>
                      </div>
                    </div>
                  </span>
                  <span class="col-6" *ngIf="actividad.oportunidades.length > 0">
                    <h5 class="card-title">Oportunidades</h5>
                    <ul *ngFor="let oportunidad of actividad.oportunidades">
                      <li [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">{{oportunidad.nombre}}</li>
                    </ul>
                  </span>
                </span>
              </div>
              <div class="card-footer bg-transparent">
                <div class="widget-content-left mr-3">
                  <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + actividad.usuarioRegistro + '/imagenes'" alt="Imagen de perfil de usuario">
                </div>
                <div class="widget-content-left">
                  <div class="widget-heading">{{actividad.desUsuarioRegistro}}</div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="row">
                <div class="mx-auto">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-chart-content">
                      <ngb-pagination class="mb-2" [boundaryLinks]="true"
                        [collectionSize]="(actividades | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Notas</span></ng-template>
					<ng-template ngbTabContent>
            <div class="text-right">
              <div class="btn-group ml-2 mb-4">
                <button type="button" (click)="open(1, 0, content)"  class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex">
                  Registrar nuevo
                  <span class="pl-2 align-middle opacity-7">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="main-card mb-3 card card-shadow-primary" *ngFor="let actividad of actividades | filtroActividadesConcepto: 1 | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
              <div class="card-header with-border">
                <div class="card-header-title">
                  <div class="card-header-title"> <i class="fas fa-edit"></i> Nota</div>
                </div>
                <div class="btn-actions-pane-right mr-4 text-black-50">
                  {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'dddd'}}, {{actividad.fecModifico | amLocale: 'es'  | amDateFormat: 'LL'}} {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'h:mm a'}}
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">Descripción</h5>
                <p class="card-text">{{actividad.descripcion}}</p>
                <div class="divider" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0"></div>
                <span class="row" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0">
                  <span class="col-6" *ngIf="actividad.contactos">
                    <h5 class="card-title">Contactos</h5>
                    <div class="avatar-wrapper avatar-wrapper-overlap">
                      <div class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos">
                        <div class="avatar-icon"><img  [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/'+ contacto.id + '?v=' + version" [title]="contacto.nombre" [alt]="contacto.nombre"></div>
                      </div>
                    </div>
                  </span>
                  <span class="col-6" *ngIf="actividad.oportunidades.length > 0">
                    <h5 class="card-title">Oportunidades</h5>
                    <ul *ngFor="let oportunidad of actividad.oportunidades">
                      <li [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">{{oportunidad.nombre}}</li>
                    </ul>
                  </span>
                </span>
              </div>
              <div class="card-footer bg-transparent">
                <div class="widget-content-left mr-3">
                  <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + actividad.usuarioRegistro + '/imagenes'" alt="Imagen de perfil de usuario">
                </div>
                <div class="widget-content-left">
                  <div class="widget-heading">{{actividad.desUsuarioRegistro}}</div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="row">
                <div class="mx-auto">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-chart-content">
                      <ngb-pagination class="mb-2" [boundaryLinks]="true"
                        [collectionSize]="(actividades | filtroActividadesConcepto: 1 | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Correos electrónicos</span></ng-template>
					<ng-template ngbTabContent>
            <div class="text-right">
              <div class="btn-group ml-2 mb-4">
                <button type="button" (click)="open(4, 0, content)"  class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex">
                  Registrar nuevo
                  <span class="pl-2 align-middle opacity-7">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="main-card mb-3 card card-shadow-primary" *ngFor="let actividad of actividades | filtroActividadesConcepto: 4 | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
              <div class="card-header with-border">
                <div class="card-header-title">
                  <div class="card-header-title"> <i class="far fa-envelope"></i> Correo electrónico</div>
                </div>
                <div class="btn-actions-pane-right mr-4 text-black-50">
                  {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'dddd'}}, {{actividad.fecModifico | amLocale: 'es'  | amDateFormat: 'LL'}} {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'h:mm a'}}
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">Descripción</h5>
                <p class="card-text">{{actividad.descripcion}}</p>
                <div class="divider"></div>
                <h5 class="card-title">Resultado</h5>
                <p class="card-text">{{actividad.resultado}}</p>
                <div class="row">
                  <div class="col-3">
                    <h5 class="card-title">Fecha</h5>
                    <p class="card-text">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</p>
                  </div>
                  <div class="col-3">
                    <h5 class="card-title">Hora</h5>
                    <p class="card-text">{{actividad.hora | amLocale: 'es' | amDateFormat: 'LTS' | timeFormat}}</p>
                  </div>
                </div>
                <div class="divider" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0"></div>
                <span class="row" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0">
                  <span class="col-6" *ngIf="actividad.contactos">
                    <h5 class="card-title">Contactos</h5>
                    <div class="avatar-wrapper avatar-wrapper-overlap">
                      <div class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos">
                        <div class="avatar-icon"><img  [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/'+ contacto.id + '?v=' + version" [title]="contacto.nombre" [alt]="contacto.nombre"></div>
                      </div>
                    </div>
                  </span>
                  <span class="col-6" *ngIf="actividad.oportunidades.length > 0">
                    <h5 class="card-title">Oportunidades</h5>
                    <ul *ngFor="let oportunidad of actividad.oportunidades">
                      <li [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">{{oportunidad.nombre}}</li>
                    </ul>
                  </span>
                </span>
              </div>
              <div class="card-footer bg-transparent">
                <div class="widget-content-left mr-3">
                  <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + actividad.usuarioRegistro + '/imagenes'" alt="Imagen de perfil de usuario">
                </div>
                <div class="widget-content-left">
                  <div class="widget-heading">{{actividad.desUsuarioRegistro}}</div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="row">
                <div class="mx-auto">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-chart-content">
                      <ngb-pagination class="mb-2" [boundaryLinks]="true"
                        [collectionSize]="(actividades | filtroActividadesConcepto: 4 | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Llamadas</span></ng-template>
					<ng-template ngbTabContent>
            <div class="text-right">
              <div class="btn-group ml-2 mb-4">
                <button type="button" (click)="open(2, 0, content)"  class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex">
                  Registrar nuevo
                  <span class="pl-2 align-middle opacity-7">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="main-card mb-3 card card-shadow-primary" *ngFor="let actividad of actividades | filtroActividadesConcepto: 2 | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
              <div class="card-header with-border">
                <div class="card-header-title">
                  <div class="card-header-title"> <i class="fas fa-phone-alt"></i> Llamada</div>
                </div>
                <div class="btn-actions-pane-right mr-4 text-black-50">
                  {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'dddd'}}, {{actividad.fecModifico | amLocale: 'es'  | amDateFormat: 'LL'}} {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'h:mm a'}}
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">Descripción</h5>
                <p class="card-text">{{actividad.descripcion}}</p>
                <div class="divider"></div>
                <h5 class="card-title">Resultado</h5>
                <p class="card-text">{{actividad.resultado}}</p>
                <div class="row">
                  <div class="col-3">
                    <h5 class="card-title">Fecha</h5>
                    <p class="card-text">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</p>
                  </div>
                  <div class="col-3">
                    <h5 class="card-title">Hora</h5>
                    <p class="card-text">{{actividad.hora | amLocale: 'es' | amDateFormat: 'LTS' | timeFormat}}</p>
                  </div>
                </div>
                <div class="divider" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0"></div>
                <span class="row" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0">
                  <span class="col-6" *ngIf="actividad.contactos">
                    <h5 class="card-title">Contactos</h5>
                    <div class="avatar-wrapper avatar-wrapper-overlap">
                      <div class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos">
                        <div class="avatar-icon"><img  [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/'+ contacto.id + '?v=' + version" [title]="contacto.nombre" [alt]="contacto.nombre"></div>
                      </div>
                    </div>
                  </span>
                  <span class="col-6" *ngIf="actividad.oportunidades.length > 0">
                    <h5 class="card-title">Oportunidades</h5>
                    <ul *ngFor="let oportunidad of actividad.oportunidades">
                      <li [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">{{oportunidad.nombre}}</li>
                    </ul>
                  </span>
                </span>
              </div>
              <div class="card-footer bg-transparent">
                <div class="widget-content-left mr-3">
                  <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + actividad.usuarioRegistro + '/imagenes'" alt="Imagen de perfil de usuario">
                </div>
                <div class="widget-content-left">
                  <div class="widget-heading">{{actividad.desUsuarioRegistro}}</div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="row">
                <div class="mx-auto">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-chart-content">
                      <ngb-pagination class="mb-2" [boundaryLinks]="true"
                        [collectionSize]="(actividades | filtroActividadesConcepto: 2 | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</ng-template>
				</ngb-tab>
				<ngb-tab>
					<ng-template ngbTabTitle><span>Reuniones</span></ng-template>
					<ng-template ngbTabContent>
            <div class="text-right">
              <div class="btn-group ml-2 mb-4">
                <button type="button" (click)="open(3, 0, content)"  class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex">
                  Registrar nuevo
                  <span class="pl-2 align-middle opacity-7">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="main-card mb-3 card card-shadow-primary" *ngFor="let actividad of actividades | filtroActividadesConcepto: 3 | anyScope: paginacion.textSearch | orderBy: [paginacion.sortType] | slice: (paginacion.page-1) * paginacion.pageSize : (paginacion.page-1) * paginacion.pageSize + paginacion.pageSize" (dblclick)="open(actividad.concepto , actividad.id, content)">
              <div class="card-header with-border">
                <div class="card-header-title">
                  <div class="card-header-title"> <i class="far fa-calendar-alt"></i> Reunión</div>
                </div>
                <div class="btn-actions-pane-right mr-4 text-black-50">
                  {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'dddd'}}, {{actividad.fecModifico | amLocale: 'es'  | amDateFormat: 'LL'}} {{actividad.fecModifico | amLocale: 'es' | amDateFormat: 'h:mm a'}}
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title">Descripción</h5>
                <p class="card-text">{{actividad.descripcion}}</p>
                <div class="divider" ></div>
                <h5 class="card-title">Resultado</h5>
                <p class="card-text">{{actividad.resultado}}</p>
                <div class="row">
                  <div class="col-3">
                    <h5 class="card-title">Fecha</h5>
                    <p class="card-text">{{actividad.fecha | amLocale: 'es' | amDateFormat: 'L'}}</p>
                  </div>
                  <div class="col-3">
                    <h5 class="card-title">Hora</h5>
                    <p class="card-text">{{actividad.hora | amLocale: 'es' | amDateFormat: 'LTS' | timeFormat}}</p>
                  </div>
                  <div class="col-3">
                    <h5 class="card-title">Duración</h5>
                    <p class="card-text">{{actividad.duracion | timeDuration}}</p>
                  </div>
                </div>
                <div class="divider" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0"></div>
                <span class="row" *ngIf="actividad.contactos.length > 0 || actividad.oportunidades.length > 0">
                  <span class="col-6" *ngIf="actividad.contactos">
                    <h5 class="card-title">Contactos</h5>
                    <div class="avatar-wrapper avatar-wrapper-overlap">
                      <div class="avatar-icon-wrapper" *ngFor="let contacto of actividad.contactos">
                        <div class="avatar-icon"><img  [src]="url + '/empresas/perfiles/' + empresa.id + '/imagenes/'+ contacto.id + '?v=' + version" [title]="contacto.nombre" [alt]="contacto.nombre"></div>
                      </div>
                    </div>
                  </span>
                  <span class="col-6" *ngIf="actividad.oportunidades.length > 0">
                    <h5 class="card-title">Oportunidades</h5>
                    <ul *ngFor="let oportunidad of actividad.oportunidades">
                      <li [routerLink]="['/auth/oportunidades/profile/' + oportunidad.id]">{{oportunidad.nombre}}</li>
                    </ul>
                  </span>
                </span>
              </div>
              <div class="card-footer bg-transparent">
                <div class="widget-content-left mr-3">
                  <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + actividad.usuarioRegistro + '/imagenes'" alt="Imagen de perfil de usuario">
                </div>
                <div class="widget-content-left">
                  <div class="widget-heading">{{actividad.desUsuarioRegistro}}</div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="row">
                <div class="mx-auto">
                  <div class="card no-shadow bg-transparent text-left">
                    <div class="widget-chart-content">
                      <ngb-pagination class="mb-2" [boundaryLinks]="true"
                        [collectionSize]="(actividades | filtroActividadesConcepto: 3 | anyScope: paginacion.textSearch ).length" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                      </ngb-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</div>
  </div>
  <div class="col-lg-3">
    <app-empresa-profile-actions></app-empresa-profile-actions>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="actividad.concepto === 1">Nota</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 2">Llamada</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 3">Reunión</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 4">Correo electrónico</h4>
    <h4 class="modal-title" *ngIf="actividad.concepto === 5">Reunión</h4>
  </div>
  <div class="modal-body">
    <form #empresaProfileForm="ngForm" (ngSubmit)="onSubmit()" class="col-lg-12">
      <div class="row">
        <div class="col-lg-6" *ngIf="actividad.concepto !== 1">
          <span *ngIf="actividad.concepto === 2">
            <fieldset class="form-group required">
              <label for="actividadResultado" class="control-label">Resultado </label>
              <ng-select [items]="llamadas"
                bindLabel="nombre"
                bindValue="nombre"
                placeholder="Capture el resultado de la llamada."
                id="actividadResultado"  name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                [disabled]="!identity.permisos.empresasRegEdit" required>
              </ng-select>
            </fieldset>
          </span>
          <span *ngIf="actividad.concepto === 3">
            <fieldset class="form-group required" >
              <label for="actividadResultado" class="control-label">Resultado </label>
              <ng-select [items]="Reuniones"
                bindLabel="nombre"
                bindValue="nombre"
                placeholder="Capture el resultado de la llamada."
                id="actividadResultado"  name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                [disabled]="!identity.permisos.empresasRegEdit" required>
              </ng-select>
            </fieldset>
          </span>
          <span *ngIf="actividad.concepto === 4">
            <fieldset class="form-group required" >
              <label for="actividadResultado" class="control-label">Resultado </label>
              <ng-select [items]="correos"
                bindLabel="nombre"
                bindValue="nombre"
                placeholder="Capture el resultado de la llamada."
                id="actividadResultado"  name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                [disabled]="!identity.permisos.empresasRegEdit" required>
              </ng-select>
            </fieldset>
          </span>
          <span *ngIf="actividad.concepto === 5">
            <fieldset class="form-group required">
              <label for="actividadResultado" class="control-label">Motivo</label>
              <input autocomplete="off" [disabled]="!identity.permisos.empresasRegEdit" type="text" id="actividadResultado" name="actividadResultado" #actividadResultado="ngModel" [(ngModel)]="actividad.resultado"
                class="form-control" maxlength="100" minlength="2" placeholder="¿De qué trata la reunión?" title="¿De qué trata la reunión?." required />
              <!-- <span *ngIf="!actividadResultado.valid && actividadResultado.touched">El motivo de la reunión es obligatorio</span> -->
            </fieldset>
          </span>
        </div>
      </div>
      <div class="row" *ngIf="actividad.concepto !== 1">
        <div class="col-lg-4">
          <fieldset class="form-group required">
            <label for="actividadFecha" class="control-label">Fecha </label>
            <input type="text"class="form-control" id="actividadFecha" name="actividadFecha" #actividadFecha="ngModel" bsDatepicker [(ngModel)]="actividad.fecha" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off">

          </fieldset>
        </div>
        <div class="col-lg-4">
          <fieldset class="form-group required">
            <label for="actividadHora"  class="control-label">Hora</label>
            <ngb-timepicker id="actividadHora" name="actividadHora" #actividadHora="ngModel" [(ngModel)]="actividad.hora" [meridian]="true" [spinners]="false"></ngb-timepicker>
          </fieldset>
        </div>
        <div class="col-lg-4" *ngIf="actividad.concepto === 3">
          <fieldset class="form-group required">
            <label for="actividadDuracion"  class="control-label">Duración</label>
            <ngb-timepicker id="actividadDuracion" name="actividadDuracion" #actividadDuracion="ngModel" [(ngModel)]="actividad.duracion" [spinners]="false"></ngb-timepicker>
          </fieldset>
        </div>
      </div>
      <fieldset class="form-group required">
        <label for="actividadDescripcion" class="control-label">Descripción</label>
          <textarea [disabled]="!identity.permisos.empresasRegEdit" id="actividadDescripcion" name="actividadDescripcion" #actividadDescripcion="ngModel" [(ngModel)]="actividad.descripcion"
          class="form-control" rows="3" placeholder="Escribe aquí..." title="Descripción de la actividad." required></textarea>
        <!-- <span *ngIf="!actividadDescripcion.valid && actividadDescripcion.touched">La descripción es obligatoria</span> -->
      </fieldset>
      <div class="row">
        <div class="col-lg-6">
          <fieldset class="form-group">
            <label for="actividadContactos"  class="control-label">Contactos</label>
            <ng-multiselect-dropdown autocomplete="off"
              id="actividadContactos"
              name="actividadContactos"
              #actividadContactos="ngModel"
              [placeholder]="'Contactos'"
              [settings]="dropdownSettings"
              [data]="contactos"
              [(ngModel)]="actividad.contactos"
              (onSelect)="verificarResponsable()"
              (onSelectAll)="verificarResponsable()"
              (onDeSelect)="verificarResponsable()"
              (onDeSelectAll)="verificarResponsable()"
              (onDropDownClose)="verificarResponsable()">
            </ng-multiselect-dropdown>
          </fieldset>
        </div>
        <div class="col-lg-6">
          <fieldset class="form-group">
            <label for="actividadOportunidad"  class="control-label">Oportunidades</label>
            <ng-multiselect-dropdown autocomplete="off"
              id="actividadOportunidad"
              name="actividadOportunidad"
              #actividadOportunidad="ngModel"
              [placeholder]="'oportunidades'"
              [settings]="dropdownSettings"
              [data]="oportunidades"
              [(ngModel)]="actividad.oportunidades"
              (onSelect)="verificarResponsable()"
              (onSelectAll)="verificarResponsable()"
              (onDeSelect)="verificarResponsable()"
              (onDeSelectAll)="verificarResponsable()"
              (onDropDownClose)="verificarResponsable()">
            </ng-multiselect-dropdown>
          </fieldset>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
    <button type="button" class="btn btn-primary"  (click)="onSubmit()" [disabled]="!empresaProfileForm.form.valid || !loading"> Guardar cambios</button>
  </div>
</ng-template>
