import { Component, OnInit, Output, OnDestroy, EventEmitter } from '@angular/core';
import { UserType, Identity, FilterType } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import * as usuariosActions from 'src/app/store/actions';
import { TipoNotificacion, OpcionesNotificacion } from 'src/app/models/notificacion';

@Component({
  selector: 'app-filter-type-notifications',
  templateUrl: './filter-type-notifications.component.html',
  styleUrls: ['./filter-type-notifications.component.sass']
})
export class FilterTypeNotificationsComponent implements OnInit, OnDestroy {
  identity: Identity = new Identity(null);
  @Output() respuesta = new EventEmitter<FilterType[]>();
  tipos = [...OpcionesNotificacion.OPCIONES_NOTIFICACIONES];
  public loading = false;
  private subscripcionAuth: Subscription = new Subscription();
  private subscripcionUsers: Subscription = new Subscription();
  paginacion: Pagination = new Pagination(null);
  checkTodo = false;
  filtrado: FilterType[] = [];
  public tipoNotificacion = TipoNotificacion;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.subscripcionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscripcionUsers.unsubscribe();
    this.subscripcionAuth.unsubscribe();
  }

  changeCheck(item: UserType) {
    this.checkTodo = true;
    this.filtrado = this.tipos.map( (elemento) => {
      if (item.id !== elemento.id) {
        if (!elemento.checked) { this.checkTodo = false; }
        return {id: elemento.id, nombre: elemento.nombre, checked: elemento.checked};
      }
      if (!item.checked) { this.checkTodo = false; }
      return {id: item.id, nombre: item.nombre, checked: item.checked};
    }).filter( element => element.checked);
    this.respuesta.emit(this.filtrado);
  }

  changeCheckTodo() {
    this.filtrado = this.tipos.map( (elemento) => {
      elemento.checked = this.checkTodo;
      return {id: elemento.id, nombre: elemento.nombre, checked: elemento.checked};
    });
    this.respuesta.emit(this.filtrado);
  }
}
