import { Component, OnInit, OnDestroy } from '@angular/core';
import { ArchivoGeneral, ArchivosGeneralSortType } from '../../../models/archivo-general';
import { Pagination } from '../../../models/pagination';
import { Identity, FilterType } from '../../../models/user';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppState } from '../../../store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { ExcelService } from '../../../services/excel.service';
import { GLOBAL } from '../../../models/global';
import * as archivosActions from 'src/app/store/actions';
import Swal from 'sweetalert2';
import { MESSAGES } from '../../../models/messages';
import { AnyScopePipe } from '../../../pipes/any-scope.pipe';
import { GENERALES } from '../../../models/generales';
import { ArchivosGeneralesService } from '../../../services/archivos-generales.service';
import { CargarArchivosGeneral, EliminarArchivoGeneralFail } from '../../../store/actions/archivos-generales.actions';

@Component({
  selector: 'app-lista-archivos',
  templateUrl: './lista-archivos.component.html',
  styleUrls: ['./lista-archivos.component.sass']
})
export class ListaArchivosComponent implements OnInit, OnDestroy {
  public url: string;
  public status: string;
  public page: number;
  public nextPage: number;
  public prevPage: number;
  public total: number;
  public pages: number;
  public archivos: ArchivoGeneral[] = [];
  public table: any;
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public identity: Identity = new Identity(null);
  public filterTipos: FilterType[] = [];
  public filterCategoria: FilterType[] = [];
  public tipoSearch = 0;
  heading = 'Información de archivos';
  subheading = 'Contenido de archivos activos en el sistema';
  icon = 'pe-7s-users icon-gradient bg-dark';
  cargando: boolean;
  subscriptionArchivos: Subscription = new Subscription();
  subscriptionAuth: Subscription = new Subscription();
  subcriptionCatalogo: Subscription = new Subscription();
  public orderType = [...ArchivosGeneralSortType];
  public isCollapsed = false;
  public filterActive = false;
  public listSearch: string[] = [];
  public files: ArchivoGeneral[] = [];
  public toggleMobileSidebar;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private archivosService: ArchivosGeneralesService,
    private excelService: ExcelService
  ) {
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacion.sortType = '-fecRegistro';
    console.log('Inicializo componente Archivos...');
  }

  ngOnInit() {
    this.subscriptionArchivos = this.store.select('archivos').subscribe(
      (state: any) => {
        this.archivos = [...state.archivos];
      }
    );
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state: any) => {
        this.identity = state.identity;
      }
    );
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscriptionArchivos.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new archivosActions.CargarArchivosGeneral());
  }

  eliminarArchivo(archivo: ArchivoGeneral) {
    this.loading = true;
    Swal.fire({
      title: '¿Deseas eliminar el archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_WARNING,
      confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
      cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        return new Promise((resolve) => {
          this.archivosService.eliminarArchivo(archivo).subscribe(
            (response) => {
              this.store.dispatch(new archivosActions.EliminarArchivoGeneralSuccess(archivo));
              resolve(response);
            }, (error) => {
              this.store.dispatch(new archivosActions.EliminarArchivoGeneralFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      }
    }).then( (resul) => {
      if (resul.value) {
        this.store.dispatch(new archivosActions.CargarNotificacionesMini(1, 20, 0));
        Swal.fire({
          title: MESSAGES.M_TIT_SUCCESS,
          timer: 1200,
          showConfirmButton: false,
          icon: 'success',
          onOpen: () => {}
        }).then(() => {}, () => {});
      } else {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      }
      this.loading = false;
    }, (error) => {
      Swal.fire({
        title: MESSAGES.M_TIT_CANCELADO,
        timer: 1200,
        showConfirmButton: false,
        icon: 'error',
        onOpen: () =>  {}
      }).then(() => {}, () => {});
      this.loading = false;
    });

  }

  editarArchivo(archivo: ArchivoGeneral) {
    this.router.navigate(['/auth/archivos/register', archivo.id]);
  }

  sort(descriptionSort: string) {
    if (descriptionSort === this.paginacion.sortType) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
    } else {
      this.paginacion.sortReverse = false;
    }
    this.paginacion.sortType = descriptionSort;
  }

  printXLSX() {
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    let lista = [...this.archivos];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    if (lista.length) {
      lista = lista.map( itemLista => {
        const obj: any = {};
        if (itemLista.fecRegistro) {
          itemLista.fecRegistro = new Date(itemLista.fecRegistro);
        } else {
          itemLista.fecRegistro = null;
        }
        obj.ARCHIVO = itemLista.nombre;
        obj.CARPETA = itemLista.folderName;
        obj.TIPO = itemLista.type;
        obj.FECHA_REGISTRO = GENERALES.ddmmyyyy(itemLista.fecRegistro);
        return obj;
      });
      this.excelService.exportAsExcelFile(lista, 'Reporte de archivos ' + i );
    }
  }

  showTipos(event) {
    this.filterTipos = [...event];
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

}
