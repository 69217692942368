import { Action } from '@ngrx/store';
import { Notificacion } from 'src/app/models/notificacion';

export const CARGAR_NOTIFICACIONES_MINI = '[Notificaciones] Cargar mini notificaciones';
export const CARGAR_NOTIFICACIONES_MINI_FAIL = '[Notificaciones] Cargar mini notificaciones FAIL';
export const CARGAR_NOTIFICACIONES_MINI_SUCCESS = '[Notificaciones] Cargar mini notificaciones SUCCESS';

export class CargarNotificacionesMini implements Action {
  readonly type = CARGAR_NOTIFICACIONES_MINI;

  constructor(public pag: number, public limit: number, public usuario: number ) {}
}

export class CargarNotificacionesMiniFail implements Action {
  readonly type = CARGAR_NOTIFICACIONES_MINI_FAIL;

  constructor( public payload: any ) {}
}

export class CargarNotificacionesMiniSuccess implements Action {
  readonly type = CARGAR_NOTIFICACIONES_MINI_SUCCESS;

  constructor( public notificaciones: Notificacion[], public total: number, public sinLeer: number) {}
}

export type notificacionesMiniAcciones  = CargarNotificacionesMini
                                    | CargarNotificacionesMiniFail
                                    | CargarNotificacionesMiniSuccess;
