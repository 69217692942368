import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesLightboxModalComponent } from './components/images-lightbox-modal/images-lightbox-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HtmlViewerCampanaComponent } from './components/html-viewer-campana/html-viewer-campana.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SharedComponent } from './shared.component';
import { ListTreeComponent } from './components/list-tree/list-tree.component';
import { PipesModule } from '../pipes/pipes.module';
import { AutoActualizacionCampanaComponent } from './components/auto-actualizacion-campana/auto-actualizacion-campana.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { GraciasConfirmarComponent } from './components/auto-actualizacion-campana/gracias-confirmar/gracias-confirmar.component';
import { DesuscripcionComponent } from './components/desuscripcion/desuscripcion/desuscripcion.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';




@NgModule({
  declarations: [
    SharedComponent,
    ImagesLightboxModalComponent,
    AutoActualizacionCampanaComponent,
    HtmlViewerCampanaComponent,
    ListTreeComponent,
    GraciasConfirmarComponent,
    DesuscripcionComponent,

  ],
  exports: [
    SharedComponent,
    ImagesLightboxModalComponent,
    AutoActualizacionCampanaComponent,
    HtmlViewerCampanaComponent,
    ListTreeComponent,
    
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    NgbModule,
    SlickCarouselModule,
    RouterModule,
    FormsModule,
    PipesModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    NgSelectModule,
    JwBootstrapSwitchNg2Module
  ],
  bootstrap: [
    ImagesLightboxModalComponent,
    ListTreeComponent
  ],
})
export class SharedModule { }
