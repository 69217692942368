import { Action } from '@ngrx/store';
import { Industria } from 'src/app/models/industrias';

export const CARGAR_INDUSTRIAS = '[Industria] Cargar industrias';
export const CARGAR_INDUSTRIAS_SUCCESS = '[Industria] Cargar industrias success';
export const CARGAR_INDUSTRIAS_FAIL = '[Industria] Cargar industrias fail';
export const ACTUALIZA_INDUSTRIA = '[Industria] Actualiza industria';
export const ACTUALIZA_INDUSTRIA_FAIL = '[Industria] Actualiza industria FAIL';
export const ACTUALIZA_INDUSTRIA_SUCCESS = '[Industria] Actualiza industria SUCCESS';
export const ACTUALIZA_INDUSTRIA_ESTATUS = '[Industria] Actualiza industria estatus';
export const ACTUALIZA_INDUSTRIA_ESTATUS_FAIL = '[Industria] Actualiza industria estatus FAIL';
export const ACTUALIZA_INDUSTRIA_ESTATUS_SUCCESS = '[Industria] Actualiza industria estatus SUCCESS';

export class CargarIndustrias implements Action {
  readonly type = CARGAR_INDUSTRIAS;
}

export class CargarIndustriasFail implements Action {
  readonly type = CARGAR_INDUSTRIAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarIndustriasSuccess implements Action {
  readonly type = CARGAR_INDUSTRIAS_SUCCESS;

  constructor(public industrias: Industria[]) {}
}

export class ActualizaIndustria implements Action {
  readonly type = ACTUALIZA_INDUSTRIA;

  constructor( public industria: Industria ) { }
}

export class ActualizaIndustriaFail implements Action {
  readonly type = ACTUALIZA_INDUSTRIA_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaIndustriaSuccess implements Action {
  readonly type = ACTUALIZA_INDUSTRIA_SUCCESS;

  constructor( public industria: Industria ) {}
}

export class ActualizaIndustriaEstatus implements Action {
  readonly type = ACTUALIZA_INDUSTRIA_ESTATUS;

  constructor( public id: number ) { }
}

export class ActualizaIndustriaEstatusFail implements Action {
  readonly type = ACTUALIZA_INDUSTRIA_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaIndustriaEstatusSuccess implements Action {
  readonly type = ACTUALIZA_INDUSTRIA_ESTATUS_SUCCESS;

  constructor( public id: number ) {}
}

export type industriaAcciones = CargarIndustrias 
                                | CargarIndustriasFail 
                                | CargarIndustriasSuccess
                                | ActualizaIndustria 
                                | ActualizaIndustriaFail 
                                | ActualizaIndustriaSuccess 
                                | ActualizaIndustriaEstatus 
                                | ActualizaIndustriaEstatusFail 
                                | ActualizaIndustriaEstatusSuccess;
