import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo';
import { Industria } from 'src/app/models/industrias';
import { Empresa } from 'src/app/models/empresa';
import { Estado, Municipio, Colonia } from 'src/app/models/direccion';
import { EmpresasService } from 'src/app/services/empresa.service';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/user';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { UsoCfdi } from 'src/app/models/uso-cfdi';
import { DateWithoutTimeZonePipe } from 'src/app/pipes/date-without-time-zone.pipe';
import { DireccionesService } from 'src/app/services/direcciones.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-empresas-edit',
  templateUrl: './empresas-edit.component.html',
  styleUrls: ['./empresas-edit.component.sass']
})
export class EmpresasEditComponent implements OnInit, OnDestroy {
  heading = 'Detalles de la empresa';
  subheading = 'Empresa registrada en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subcriptionEmpresa: Subscription = new Subscription();
  public empresa: Empresa  = new Empresa(null);
  public empresaInicial: Empresa  = new Empresa(null);
  public tipos: Catalogo[];
  public industrias: Industria[];
  public industriasFiltradas: Industria[];
  public estados: Estado[];
  public municipios: Municipio[];
  public colonias: Colonia[];
  public estadosDatoFiscal: Estado[];
  public municipiosDatoFiscal: Municipio[];
  public coloniasDatoFiscal: Colonia[];
  public loading: boolean;
  public editar: Boolean = false;
  public editarEstaMuni: Boolean = false;
  public editarMunicipio: Boolean = false;
  public editarDatoFiscal: Boolean = false;
  public editarEstaMuniDatoFiscal: Boolean = false;
  public editarMunicipioDatoFiscal: Boolean = false;
  public imageChangedEvent: any;
  public showCropper: Boolean = false;
  public telefonos: number = 1;
  public propietarios: Usuario[];
  public clasificaciones: Catalogo[];
  public clases: Catalogo[];
  public paises: Catalogo[];
  public cpAnterior: string;
  public cpAnteriorDatoFiscal: string;
  public clasificacionAnterior: number;
  public tiposContribuyentes = [ {nombre: 'Físicas'}, {nombre: 'Morales'} ];
  public usosCfdis: UsoCfdi[] = [];

  @ViewChild(ImageCropperComponent, {static: false}) imageCropper: ImageCropperComponent;
  constructor(
    private empresasService: EmpresasService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private direccionService: DireccionesService,
    private toastr: ToastrService,

  ) {
    this.loading = false;
    this.identity = new Identity(null);
    this.tipos = [];
    this.industrias = [];
    this.industriasFiltradas = [];
    this.colonias = [];
    this.municipios = [];
    this.estados = [];
    this.propietarios = [];
    this.estadosDatoFiscal = [];
    this.municipiosDatoFiscal = [];
    this.clases = [];
    this.coloniasDatoFiscal = [];
    this.cpAnterior = '';
    this.cpAnteriorDatoFiscal = '';
    this.clasificacionAnterior = 99999;
    this.store.dispatch(new usuariosActions.LimpiarEmpresa());
   }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.tipos = [...state.tipos.filter(item => item.status)];
        this.industrias = [...state.industrias.filter(item => item.status)];
        this.propietarios = [...state.usuarios];
        this.clasificaciones = [...state.clasificaciones.filter(item => item.status)];
        this.paises = [...state.paises.filter(item => item.status)];
        this.clases = [...state.clasesEmpresa.filter(item => item.status)];
        this.usosCfdis = [...state.usosCfdis];
        if(this.empresa.clasificacion && this.empresa.clasificacion !== this.clasificacionAnterior && this.industrias.length){
          this.industriasFiltradas = [...this.industrias.filter(item => item.clasificacion === this.empresa.clasificacion)];
          this.clasificacionAnterior = this.empresa.clasificacion;
        }
      }
    );
    this.subcriptionEmpresa = this.store.select('empresa').subscribe(
      (state) => {
        this.empresa = {...state.empresa};
        this.empresaInicial = {...state.empresa};

        const pipeDateWithoutTimeZone = new DateWithoutTimeZonePipe();
        this.empresa.fundacion = this.empresa.fundacion ? pipeDateWithoutTimeZone.transform(this.empresa.fundacion) : null;
        this.empresaInicial.fundacion = this.empresaInicial.fundacion ? pipeDateWithoutTimeZone.transform(this.empresaInicial.fundacion) : null;
        if(this.empresa.pais === 1 && !this.loading && this.estados.length === 0 && this.municipios.length === 0){
          this.consultaCodigoPostal();
        }else if(this.empresa.paisDatoFiscal === 1 && !this.loading && this.estadosDatoFiscal.length === 0 && this.municipiosDatoFiscal.length === 0 ){
          this.consultaCodigoPostalDatoFiscal();
        }
      }
    );
    console.log('Inicializa component empresas-edit...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeConsulta.unsubscribe();
    this.subcriptionEmpresa.unsubscribe();
  }

  inicializa() {
    this.route.params.subscribe(
      (params) => {
        const id = params.id;
        if (id > 0) {
          this.store.dispatch(new usuariosActions.CargarEmpresa(id));
        } else {
          this.empresa = new Empresa(null);
          this.empresaInicial = new Empresa(null);
          this.empresa.propietario = this.identity.id;
          this.empresaInicial.propietario = this.identity.id;
          this.empresa.clase = 3;
          this.empresaInicial.clase = 3;
          this.empresa.clasificacion = 1;
          this.empresaInicial.clasificacion = 1;
        }
    });
    this.store.dispatch(new usuariosActions.ConsultaIndustrias());
    // this.store.dispatch(new usuariosActions.ConsultaTipoEmpresas());
    this.store.dispatch(new usuariosActions.CargarEstados());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaPaises());
    this.store.dispatch(new usuariosActions.ConsultaClasificaciones());
    this.store.dispatch(new usuariosActions.ConsultaClasesEmpresas());
    this.store.dispatch(new usuariosActions.ConsultaUsosCfdis());
  }

  async onSubmit() {
    await this.validaEmpresa().then(
      (resultado) => {
        Swal.fire({
          title: '¿Deseas registrar la empresa?',
          showCancelButton: true,
          confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
          cancelButtonColor: MESSAGES.C_BTN_CANCEL,
          confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
          cancelButtonText: MESSAGES.M_BTN_CANCEL,
          focusConfirm: false,
          focusCancel: true,
          animation: true,
          showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
          showLoaderOnConfirm: true,
          preConfirm:  ()  => {
            return new Promise( (resolve)  => {
              this.empresasService.registraEmpresa(this.empresa).subscribe(
                (respuesta) => {
                  if (respuesta.empresa) {
                    this.store.dispatch(new usuariosActions.ActualizaEmpresaSuccess(respuesta.empresa));
                    resolve(respuesta.empresa);
                  } else {
                    Swal.showValidationMessage('Ocurrió un error en la operación');
                    resolve(false);
                  }
                }, (error) => {
                  this.store.dispatch(new usuariosActions.ActualizaEmpresaFail(error));
                  Swal.showValidationMessage('Ocurrió un error en la operación');
                  resolve(false);
                }
              );
            });
          },
          allowOutsideClick: false
        }).then(
          (result: any) => {
            if (result.value) {
              Swal.fire({
                title: MESSAGES.M_TIT_CONFIRM,
                text: 'Empresa registrada.',
                icon: 'success'
              });
              this.router.navigate(['/auth/empresas/profile', result.value.id]);
            } else {
              Swal.fire({
                title: MESSAGES.M_TIT_CANCELADO,
                timer: 1200,
                showConfirmButton: false,
                icon: 'error',
                onOpen: () =>  {}
              }).then(() => {}, () => {});
            }
          }, () => {
            Swal.fire({
              title: MESSAGES.M_TIT_CANCELADO,
              timer: 1200,
              showConfirmButton: false,
              icon: 'error',
              onOpen: () => {}
            }).then( () => {},  () => {});
          }
        );
    }, (error) => { }
    );
  }

  async consultaCodigoPostal() {
    if( this.empresa.codigoPostal && this.empresa.pais === 1 && this.empresa.codigoPostal !== this.cpAnterior ) {
      this.cpAnterior = this.empresa.codigoPostal;
      await this.direccionService.obtenerDireccionPostal(this.empresa.codigoPostal).subscribe(
        (respuesta) => {
          if (respuesta.direccion && respuesta.direccion.estado && respuesta.direccion.estado.id) {
            this.empresa.estado = respuesta.direccion.estado.id;
            this.empresa.municipio = respuesta.direccion.municipio.id;
            this.editar = false;
            this.editarMunicipio = false;
            this.consultaEstados();
            this.consultaMunicipio( false );
            this.consultaColonias( false );

          } else {
            this.toastr.warning('No se encontro información sobre el codígo postal');
            this.consultaEstados();
            this.editar = true;
            this.editarMunicipio = false;
            this.empresa.estado = null;
            this.empresa.municipio = null;
            this.empresa.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  
  async consultaEstados( ) {
    if(this.empresa.pais === 1){
      await this.direccionService.obtenerEstados().subscribe(
        (respuesta) => {
          this.estados = [...respuesta];
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaMunicipio( status: boolean = true) {
    if(this.empresa.estado && this.empresa.pais === 1){
      await this.direccionService.obtenerMunicipios(this.empresa.estado).subscribe(
        (respuesta) => {
          this.editarMunicipio = status;
          this.municipios = [...respuesta.municipios];
          if( status ) {
            this.empresa.municipio = null;
            this.empresa.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaColonias( status: boolean = true ) {
    if(this.empresa.estado && this.empresa.municipio && this.empresa.pais === 1){
      await this.direccionService.obtenerColonias(this.empresa.estado, this.empresa.municipio).subscribe(
        (respuesta) => {
          this.colonias = [...respuesta.colonias];
          if( status ) {
            this.empresa.colonia = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  validaEmpresa() {
    const deferred = new Promise((resolve, reject) => {
      // this.empresa.tipo = 0;

      if(this.empresa.clasificacion === 2){
        this.empresa.permisos.descripcion = this.empresa.permisos.otros ? this.empresa.permisos.descripcion : '';
      }

      if(this.empresa.pais === 1){
        this.estados.find(item => {
          if(item.id === this.empresa.estado){
            this.empresa.desEstado = item.nombre;
          }
        });
        this.municipios.find(item => {
          if(item.id === this.empresa.municipio){
            this.empresa.desMunicipio = item.nombre;
          }
        });
      }
      if(this.empresa.paisDatoFiscal === 1 && this.empresa.opcDatoFiscal && !this.empresa.direccionDatoFiscal){

        this.estadosDatoFiscal.find(item => {
          if(item.id === this.empresa.estadoDatoFiscal){
            this.empresa.desEstadoDatoFiscal = item.nombre;
          }
        });
        this.municipiosDatoFiscal.find(item => {
          if(item.id === this.empresa.municipioDatoFiscal){
            this.empresa.desMunicipioDatoFiscal = item.nombre;
          }
        });
      }
      // this.tipos.find(item => {
      //   if(item.nombre === this.empresa.desTipo){
      //     this.empresa.tipo = item.id;
      //   }
      // });
      this.clasificaciones.find(item => {
        if(item.id === this.empresa.clasificacion){
          this.empresa.desClasificacion = item.nombre;
        }
      });
      this.empresa.industria = 0;
      this.industrias.find(item => {
        if(item.nombre === this.empresa.desIndustria){
          this.empresa.industria = item.id;
        }
      });
      this.paises.find(item => {
        if(item.id === this.empresa.pais){
          this.empresa.desPais = item.nombre;
        }
      });
      this.existsEmpresa().then( (respuesta) => {
        if (!respuesta) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, (err) => {
        resolve(false);
      });
    });
    return deferred;
  }

  fileChangeEvent(event: any): void {
    // this.files = event.target.files as Array<File>;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.empresa.imagen = event.base64;
    // console.log(event);
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady() {
    // console.log('Cropper ready');
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.imageCropper.canvasRotation = 1;
  }

  rotateRight() {
    this.imageCropper.canvasRotation = 3;
  }

  flipHorizontal() {
    this.imageCropper.transform.flipH = !this.imageCropper.transform.flipH;
  }

  flipVertical() {
    this.imageCropper.transform.flipV = !this.imageCropper.transform.flipV;
  }


  cantidadTelefono(evento: number){
    this.telefonos = evento === 1 ? (this.telefonos + 1) : (this.telefonos - 1);
  }


  async consultaCodigoPostalDatoFiscal() {
    if( this.empresa.codigoPostalDatoFiscal && this.empresa.paisDatoFiscal === 1 && this.empresa.codigoPostalDatoFiscal !== this.cpAnteriorDatoFiscal ) {
      this.cpAnteriorDatoFiscal = this.empresa.codigoPostalDatoFiscal;
      await this.direccionService.obtenerDireccionPostal(this.empresa.codigoPostalDatoFiscal).subscribe(
        (respuesta) => {
          if (respuesta.direccion && respuesta.direccion.estado && respuesta.direccion.estado.id) {
            this.empresa.estadoDatoFiscal = respuesta.direccion.estado.id;
            this.empresa.municipioDatoFiscal = respuesta.direccion.municipio.id;
            this.editarDatoFiscal = false;
            this.editarMunicipioDatoFiscal = false;
            this.consultaEstadosDatoFiscal();
            this.consultaMunicipioDatoFiscal( false );
            this.consultaColoniasDatoFiscal( false );

          } else {
            this.toastr.warning('No se encontro información sobre el codígo postal');
            this.consultaEstadosDatoFiscal();
            this.editarDatoFiscal = true;
            this.editarMunicipioDatoFiscal = false;
            this.empresa.estadoDatoFiscal = null;
            this.empresa.municipioDatoFiscal = null;
            this.empresa.coloniaDatoFiscal = null;

          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaEstadosDatoFiscal( ) {
    if(this.empresa.paisDatoFiscal === 1){
      await this.direccionService.obtenerEstados().subscribe(
        (respuesta) => {
          this.estadosDatoFiscal = [...respuesta];
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }

  async consultaMunicipioDatoFiscal( status: boolean = true ) {
    if(this.empresa.estadoDatoFiscal && this.empresa.paisDatoFiscal === 1){
      await this.direccionService.obtenerMunicipios(this.empresa.estadoDatoFiscal).subscribe(
        (respuesta) => {
          this.editarMunicipioDatoFiscal = status;
          this.municipiosDatoFiscal = [...respuesta.municipios];
          if( status ) {
            this.empresa.municipioDatoFiscal = null;
            this.empresa.coloniaDatoFiscal = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }


  async consultaColoniasDatoFiscal( status: boolean = true ) {
    if(this.empresa.estadoDatoFiscal && this.empresa.municipioDatoFiscal && this.empresa.paisDatoFiscal === 1){
      await this.direccionService.obtenerColonias(this.empresa.estadoDatoFiscal, this.empresa.municipioDatoFiscal).subscribe(
        (respuesta) => {
          this.coloniasDatoFiscal = [...respuesta.colonias];
          if( status ) {
            this.empresa.coloniaDatoFiscal = null;
          }
        }, (error) => {
          this.toastr.warning('Ocurrió un error en la operación');
        }
      );
    }
  }



  existeIndustria(){
    if(this.empresa.clasificacion && this.empresa.clasificacion !== this.clasificacionAnterior){
      // this.empresa.desIndustria = null;
      this.industriasFiltradas = [...this.industrias.filter(item => item.clasificacion === this.empresa.clasificacion)];
      this.clasificacionAnterior = this.empresa.clasificacion;
    }    
  }

  limpiarFecha() {
    this.empresa.fundacion = null;
  }

  existsEmpresa() {
    return new Promise( (resolve, reject) => {
      this.empresasService.existeEmpresa(this.empresa).subscribe(
        (resultado: any) => {
          if (resultado.empresa && resultado.empresa.id) {
            Swal.fire({
              title: '¿El nombre de la empresa ya existe, deseas cargar su información?',
              text: 'Si aceptas cargara la información de esa empresa, si cancelas, tienes que cambiar el nombre de la empresa para continuar',
              showCancelButton: true,
              confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
              cancelButtonColor: MESSAGES.C_BTN_CANCEL,
              confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
              cancelButtonText: MESSAGES.M_BTN_CANCEL,
              focusConfirm: false,
              focusCancel: true,
              animation: true,
              showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
              showLoaderOnConfirm: false
            }).then(
              (result: any) => {
                if (result.value) {
                  Swal.fire({
                    title: MESSAGES.M_TIT_CONFIRM,
                    timer: 1200,
                    showConfirmButton: false,
                  });
                  this.router.navigate(['/auth/empresas/register', resultado.empresa.id]);
                  resolve(false);
                } else {
                  Swal.fire({
                    title: MESSAGES.M_TIT_CANCELADO,
                    timer: 1200,
                    showConfirmButton: false,
                    icon: 'error',
                    onOpen: () =>  {}
                  }).then(() => {}, () => {});
                  resolve(false);
                }
              }, () => {
                Swal.fire({
                  title: MESSAGES.M_TIT_CANCELADO,
                  timer: 1200,
                  showConfirmButton: false,
                  icon: 'error',
                  onOpen: () => {}
                }).then( () => {},  () => {});
              }
            );
        } else {
          resolve(false);
        }
      }, (error) => {
        resolve(false);
      });
    });
  }

}


