import * as fromUsuario from '../actions';
import { Catalogo } from 'src/app/models/catalogo';

export interface PaisesState {
  paises: Catalogo[];
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: PaisesState = {
  paises: [],
  loaded: false,
  loading: false,
  error: null
};

export function paisEmpresaReducer( state = initState, action: fromUsuario.paisAcciones ): PaisesState {
  switch ( action.type ) {
    case fromUsuario.CARGAR_PAISES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_PAISES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        paises: [...action.paises]
      };
    case fromUsuario.CARGAR_PAISES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromUsuario.ACTUALIZA_PAIS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_PAIS_SUCCESS:
      const itm = state.paises.find( item => item.id === action.pais.id);
      if (itm && itm.id) {
        state.paises = state.paises.map( item => {
          if (item.id === action.pais.id) {
            item = {...action.pais};
          }
          return item;
        });
      } else {
        state.paises.push(action.pais);
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_PAIS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.ACTUALIZA_PAIS_ESTATUS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.ACTUALIZA_PAIS_ESTATUS_SUCCESS:
      state.paises = state.paises.map( item => {
        if (item.id === action.id) {
          item.status = !item.status;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.ACTUALIZA_PAIS_ESTATUS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}
