import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesLightboxModalComponent } from '../../../shared/components/images-lightbox-modal/images-lightbox-modal.component';
import { Pagination } from '../../../models/pagination';
import { Identity } from '../../../models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { GaleriaRepositorio } from '../../../models/galeria-repositorio';
import { GaleriaRepositorioService } from '../../../services/galeria-repositorio.service';
import * as usuarioActions from 'src/app/store/actions';
import { GLOBAL } from '../../../models/global';
import { AnyScopePipe } from '../../../pipes/any-scope.pipe';
import Swal from 'sweetalert2';
import { MESSAGES } from '../../../models/messages';
declare let $: any;
import * as usuariosActions from 'src/app/store/actions';
import { Album } from '../../../models/album';

@Component({
  selector: 'app-repositorios-editorial',
  templateUrl: './repositorios-editorial.component.html',
  styleUrls: ['./repositorios-editorial.component.scss']
})
export class RepositoriosEditorialComponent implements OnInit, OnDestroy {
  galleryOptions: any[];
  galleryImages: GaleriaRepositorio[] = [];
  paginacion: Pagination = new Pagination(null);
  listSearch: string[] = [];
  identity: Identity = new Identity(null);
  subcriptionAuth: Subscription = new Subscription(null);
  subcriptionGaleria: Subscription = new Subscription(null);
  buscador = false;
  url = '';
  albumes: Album[] = [];
  public activeTab = 1;

  constructor(
    private archivosService: GaleriaRepositorioService,
    private modalService: NgbModal,
    private store: Store<AppState>
  ) {
    this.url = GLOBAL.url;
    this.paginacion.page = 1;
    this.paginacion.pageSize = 24;
    this.paginacion.optionSize = [
      { value: 12, description: '12 elementos por hoja' },
      { value: 24, description: '24 elementos por hoja' },
      { value: 48, description: '48 elementos por hoja' }
    ];
  }

  ngOnInit() {
    this.subcriptionAuth = this.store.select('auth').subscribe( state => {
      this.identity = state.identity;
    });
    this.subcriptionGaleria = this.store.select('galeria').subscribe( state => {
      this.galleryImages = state.archivos;
      this.albumes = state.albumes;
    });
    this.store.dispatch(new usuarioActions.CargarGaleriaRepositorioTodas(''));
    this.store.dispatch(new usuarioActions.CargarGaleriaRepositorioAlbumes());
  }

  ngOnDestroy() {
    this.subcriptionAuth.unsubscribe();
    this.subcriptionGaleria.unsubscribe();
  }

  openModal(index: number) {
    const modalRef = this.modalService.open(ImagesLightboxModalComponent, { windowClass:'image-lighbox', backdropClass: 'dark-backdrop' });
    const pipeAnyScope = new AnyScopePipe();
    let lista = [...this.galleryImages];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    modalRef.componentInstance.images = lista;
    modalRef.componentInstance.slideIndex = ( (this.paginacion.page - 1) * this.paginacion.pageSize) + index;
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(',').map( item => item.trim() );
    this.buscador = true;
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(',').trim();
    this.buscador = true;
  }

  deleteFile(archivo: GaleriaRepositorio) {
    this.archivosService.eliminarImagen(archivo).subscribe(
      (response) => {
        this.store.dispatch(new usuariosActions.EliminarImagenGaleriaRepositorioSuccess(archivo));
      }, (error) => {
        this.store.dispatch(new usuariosActions.EliminarImagenGaleriaRepositorioFail(error));
      }
    );
  }

  deleteAlbum(album: Album) {
    Swal.fire({
      title: '¿Deseas eliminar el album?',
      text: 'Al eliminar el album, todas las imágenes que contiene se perderán.',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_DANGER,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      // showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.store.dispatch(new usuariosActions.EliminarGaleriaRepositorioAlbum(album));
          this.albumes = this.albumes.filter( element => element.id !== album.id);
          resolve(album);
          // this.archivosService.eliminarAlbum(album).subscribe(
          //   () => {
          //     this.store.dispatch(new usuariosActions.EliminarGaleriaRepositorioAlbumSuccess(album));
          //     resolve(true);
          //   }, (error) => {
          //     this.store.dispatch(new usuariosActions.EliminarGaleriaRepositorioAlbumFail(error));
          //     Swal.showValidationMessage('Ocurrió un error en la operación');
          //     resolve(null);
          //   }
          // );
        });
      },
      allowOutsideClick: false
    }).then(
      (result: any) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Actualización correctamente.',
            icon: 'success'
          });
        } else {
          Swal.close();
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  show(event) {
    console.log(event);
  }

}
