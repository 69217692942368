<div class="app-inner-layout mb-3">
  <div class="app-inner-layout__wrapper" [ngClass]="{'open-mobile-menu' : toggleMobileSidebar}">
    <div class="app-inner-layout__content card">
      <div class="table-responsive">
        <div class="app-inner-layout__top-pane card-header">
          <div class="pane-left">
            <div class="card-header-title page-titles">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" (click)="chargeListFiles(0)">
                  <a><i class="fa fa-home"></i></a>
                </li>
                <li class="breadcrumb-item" *ngFor="let brd of breadcrumb; let i = index;" (click)="breadcrumbNav(i)">
                  <a>{{brd}}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="pane-right">
            <div class="form-inline">
              <div class="btn-actions-pane-right">
                <!-- <span >({{(files | anyScope: paginacion.textSearch ).length}} de {{(files).length}})</span> -->
                <div class="btn-group ml-2">
                  <div class="search-wrapper active">
                    <div class="input-holder">
                      <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchArchivosRepositorio" id="searchArchivosRepositorio" #searchArchivosRepositorio="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                      <button class="search-icon"><span></span></button>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-outline-focus mr-2" (click)="crearCarpeta(0, '')">
                <i class="fa fa-folder-o"></i> Crear carpeta
              </button>
              <button type="button" class="btn btn-outline-focus" (click)="fileInput.click()">
                <i class="fa fa-file-o"></i> Agregar archivos
              </button>
            </div>
          </div>
        </div>
        <div class="card-body" appDragDropFolderFile (fileDropped)="uploadFile(currentDirectorio, $event)">
          <mat-progress-bar *ngIf="downloading"
            [mode]="downloadState.state == 'PENDING' ? 'buffer' : 'determinate'" 
            [value]="downloadState.progress">
          </mat-progress-bar>
          <div class="text-muted text-center pointer" style="align-content: center; vertical-align: middle;" *ngIf="!downloading" (click)="fileInput.click()">
            <h4>Drag and Drop yours files here...</h4>
          </div>
        </div>
        <div class="card-body pt-0" style="overflow: hidden; overflow-y: auto; min-height: 85vh; max-height: 95vh; width: 100%;">
          <input hidden type="file" multiple accept="*/*" #fileInput (change)="uploadFile(currentDirectorio, $event.target.files)">
          <div class="form-group">
            <div class="row mb-5">
              <div class="col-lg-3 col-md-4 col-sm-6 mb-3" *ngFor="let folder of currentDirectorio.directorios | anyScope: paginacion.textSearch">
                <div class="card card-gallery" appDragDropFolderFile [folder]="folder" (fileDropped)="uploadFile(folder, $event)" (startDrag)="moviendoFiles($event)" (endDrag)="moviendoFiles($event)">
                  <div class="gallery-list text-center" (click)="chargeListFiles(folder.id)">
                    <div class="thumbnail-gallery">
                      <a class="text-muted">
                        <img style="height: 170px; width: auto;" src="assets/images/folder.png" alt="Folder">
                      </a>
                    </div>
                  </div>
                  <div class="card-body" style="padding: 0 0.5rem 0.5rem 0.5rem">
                    <div class="widget-content p-0 pb-0 small">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left" style="max-width: 90%;">
                          <div class="font-album-card line-clamp" [title]="folder.nombre" (click)="chargeListFiles(folder.id)">
                            {{folder.nombre}}
                          </div>
                          <div class="widget-subheading text-uppercase">
                            Directorio
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <button type="button" class="border-0 btn btn-pill btn-outline-secondary" *ngIf="!downloading" ngbDropdown placement="left-top">
                            <i type="button" ngbDropdownToggle class="fas fa-ellipsis-v"></i>
                            <div ngbDropdownMenu class="dropdown-menu-hover-link dropdown-menu-left">
                              <button class="dropdown-item" (click)="eliminarCarpeta(folder)" *ngIf="identity.permisos.archivosListEdit">
                                <span>Eliminar</span>
                              </button>
                              <button class="dropdown-item" (click)="crearCarpeta(folder.id, folder.nombre)" *ngIf="identity.permisos.archivosListEdit">
                                <span>Renombrar</span>
                              </button>
                              <!-- <a class="dropdown-item" [href]="url + '/archivos/download/directorios/' + folder.id + '?token=' + token" download *ngIf="identity.permisos.archivosListEdit">
                                <span>Descargar</span>
                              </a> -->
                              <a class="dropdown-item" (click)="download(folder.nombre + '.zip', url + '/archivos/download/directorios/' + folder.id)" *ngIf="identity.permisos.archivosListEdit">
                                <span>Descargar</span>
                              </a>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div>
                  <a class="font-album-card" (click)="chargeListFiles(folder.id)">{{folder.nombre}}</a>
                </div> -->
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mb-3" *ngFor="let file of currentFiles | directorioActual: currentDirectorio.id | anyScope: paginacion.textSearch; let i= index;">
                <div class="card" appDragDropFolderFile [archivo]="file" (startDrag)="moviendoFiles($event)" (endDrag)="moviendoFiles($event)">
                  <div class="card-header p-0 img-loading text-center mx-auto">
                    <div class="text-center mx-auto">
                      <img class="" [src]="file.mimeType | mimeImages">
                      <!-- <i class="fa-10x" [className]="file.mimeType | mimeFontawesome" [ngClass]="{'opacity-5': file.loading}"></i> -->
                    </div>
                    <!-- <img class="card-img-top" [ngClass]="{'opacity-5': file.loading}" [src]="file.urlPath | safe: 'url'" [alt]="file.nombre"> -->
                  </div>
                  <div class="card-body p-2">
                    <div class="widget-content p-0 pb-0 small">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left" style="max-width: 90%;">
                          <div class="line-clamp" [title]="file.name">{{file.name}}</div>
                          <div class="widget-subheading text-uppercase">
                            <!-- {{file.mimeType}} {{file.size | fileSize}} -->
                            {{file.size | fileSize}}
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <button type="button" class="border-0 btn btn-pill btn-outline-secondary" *ngIf="!downloading" ngbDropdown placement="left-top">
                            <i type="button" ngbDropdownToggle class="fas fa-ellipsis-v"></i>
                            <div ngbDropdownMenu class="dropdown-menu-hover-link dropdown-menu-left">
                              <button class="dropdown-item" (click)="eliminarFile(file)" *ngIf="identity.permisos.archivosListEdit">
                                <span>Eliminar</span>
                              </button>
                              <a class="dropdown-item" (click)="download(file.name, url + '/archivos/download/archivos/' + file.id )" *ngIf="identity.permisos.archivosListEdit">
                                <span>Descargar</span>
                              </a>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mb-3" *ngFor="let file of archivosTemp | directorioActual: currentDirectorio.id | anyScope: paginacion.textSearch; let i= index;">
                <div class="card">
                  <div class="card-header p-0 img-loading text-center mx-auto">
                    <div class="text-center mx-auto">
                      <img class="" [src]="file.mimeType | mimeImages" [ngClass]="{'opacity-5': file.loading}">
                      <!-- <i class="fa-10x" [className]="file.mimeType | mimeFontawesome" [ngClass]="{'opacity-5': file.loading}"></i> -->
                    </div>
                    <!-- <img class="card-img-top" [ngClass]="{'opacity-5': file.loading}" [src]="file.urlPath | safe: 'url'" [alt]="file.nombre"> -->
                  </div>
                  <div class="card-body p-2">
                    <div class="widget-content p-0 pb-0 small">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="line-clamp" [title]="file.name">{{file.name}}</div>
                          <div class="widget-subheading text-uppercase">
                            <!-- {{file.mimeType}} {{file.size | fileSize}} -->
                            {{file.size | fileSize}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-0 text-center" *ngIf="file.progress">
                    <ngb-progressbar type="success" *ngIf="!file.error" [value]="file.progress" [max]="file.progressTotal" [striped]="true" class="text-center">
                      <span *ngIf="file.progress < file.progressTotal">
                        {{file.progress | fileSize}} / {{file.progressTotal | fileSize}}
                      </span>
                      <span *ngIf="file.loaded">¡Completado!</span>
                    </ngb-progressbar>
                    <ngb-progressbar type="danger" *ngIf="file.error" [value]="100" [max]="100" class="text-center">
                      <span *ngIf="file.error">¡Error!</span>
                    </ngb-progressbar>
                  </div>
                </div>
              </div>
              <div class="col-12" appDragDropFolderFile (fileDropped)="uploadFile(currentDirectorio, $event)">
                <div class="text-muted text-center p-5" style="height: 50vh;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-inner-layout__sidebar card">
      <div class="card-header">
        <div class="card-header-title">Navegación</div>
      </div>
      <div class="card-body">
        <ul class="listree" id="navArchivos">
          <app-list-tree [actual]="actual" [lista]="[directorio]" (seleccionado)="chargeListFiles($event)"></app-list-tree>
        </ul>
      </div>
    </div>
  </div>
</div>
