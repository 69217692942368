import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Empresa, EmpresaSortType } from 'src/app/models/empresa';
import { OportunidadesService } from 'src/app/services/oportunidades.service';
import { NegocioCatalogo, Oportunidad, ColoresCatalogo } from 'src/app/models/oportunidades';
import { Catalogo } from 'src/app/models/catalogo';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { FilterType, Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import {ExcelService} from 'src/app/services/excel.service';
import * as usuariosActions from 'src/app/store/actions';
import { GLOBAL } from 'src/app/models/global';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { OptionCheckMultiplePipe } from 'src/app/pipes/option-check-multiple.pipe';
import { MultiOpcionesSelectedPipe } from 'src/app/pipes/multi-opciones-selected.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-oportunidades',
  templateUrl: './oportunidades.component.html',
  styleUrls: ['./oportunidades.component.sass']
})
export class OportunidadesComponent implements OnInit, OnDestroy {
  heading = 'Detalles de las oportunidades';
  subheading = 'Oportunidades registradas en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public url: string;
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  private subscribeOportunidad: Subscription = new Subscription();
  public oportunidades: Oportunidad[];
  public etapas: Catalogo[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive: boolean = false;
  public orderType = [...EmpresaSortType];
  public version = 1;
  public listSearch: string[] = [];
  public oportunidadesTotal: { etapa: Catalogo, oportunidad: Oportunidad[], total: number, color: string }[] = [];
  public colores: Catalogo[] = [...ColoresCatalogo];
  public filterTiposNegocios: any[] = [...NegocioCatalogo.map(item => {
    return {id: item.nombre, nombre: item.nombre, checked: false};
  })];
  public filterEtapas: FilterType[] = [];

  constructor(
    private oportunidadesService: OportunidadesService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private router: Router,
    private excelService: ExcelService,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.oportunidades = [];
    this.etapas = [];
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeOportunidad = this.store.select('oportunidad').subscribe(
      (state) => {
        this.oportunidades = [...state.oportunidades];
        this.actualizaCards();
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.etapas = [...state.etapas.filter(item => item.status)];
        this.filterEtapas = [...state.etapas.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.actualizaCards();
      }
    );
    console.log('Inicializa component oportunidades...');
    this.store.dispatch(new usuariosActions.ConsultaEtapas());
    this.store.dispatch(new usuariosActions.CargarOportunidades());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeOportunidad.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  actualizaCards(){
    this.oportunidadesTotal = [];
    this.etapas.find( item => { this.oportunidadesTotal.push({etapa: {...item}, oportunidad: [], total: 0, color:''}); });
    if(this.oportunidadesTotal.length > 0){
      this.oportunidadesTotal.find(item => {
        item.oportunidad = [...this.oportunidades.filter(itm => itm.etapa === item.etapa.id)];
        item.oportunidad.find(itm => { item.total += itm.importe });
        let color = '';
        this.colores.find(ite => { if (ite.id === item.etapa.id) { color = ite.nombre} });
        item.color = color;
      });
    }
  }

  printCSV() {
    let excel;
    excel = this.oportunidades.map( item => {
      const obj: any = {};
      obj.NOMBRE = item.nombre;
      obj.PROPIETARIO = item.desPropietario;
      obj.IMPORTE = item.importe;
      obj.FECHA = (item.fecha && item.fecha != null ? moment(item.fecha).format('DD/MM/YYYY') : '');;      
      obj.ETAPA = item.desEtapa;
      obj.EMPRESA = item.desEmpresa;
      return obj;
    });
    this.excelService.exportAsExcelFile(excel, 'oportunidades');
  } 

  drop(event: CdkDragDrop<Oportunidad[]>, etapa: number) {

    let oportunidad: Oportunidad = event.previousContainer.data[event.previousIndex];
    oportunidad.etapa = etapa;
    oportunidad.mostrar = false;

    // const version:{etapa: Catalogo, oportunidad:Oportunidad[], total: number}[]  = [...this.oportunidadesTotal];
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      oportunidad.mostrar = true;
    } else {
      // transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);{}
      this.oportunidadesService.actualizaEtapaOportunidad(oportunidad).subscribe(
        repuesta => {
          transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);{}
          this.oportunidadesTotal.find(item => { item.total = 0; });
          this.oportunidadesTotal.find(item => { item.oportunidad.find(itm => { item.total += itm.importe; }); });
          this.toastr.success('Se actualizó la etapa de la oportunidad','Oportunidad actualizada');
          oportunidad.mostrar = true;
          this.actualizaCards();
        },error =>{
          console.log(<any>error);
          oportunidad.mostrar = true;
          // this.oportunidadesTotal = [...version];
      });
    }
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

  editarOportunidad(oportunidad: Oportunidad) {
    if (oportunidad.id > 0 ) {
      this.router.navigate(['/auth/oportunidades/register', oportunidad.id]);
    }
  }

  changeTipoNegocio(lista: FilterType[]) {
    this.filterTiposNegocios = [...lista];
  }

  changeEtapas(lista: FilterType[]) {
    this.filterEtapas = [...lista];
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchOportunidades").focus();
  }

}
