<div class="ui-theme-settings">
  <button type="button" placement="left" ngbTooltip="Open Layout Configurator" class="btn-open-options btn btn-warning"
          (click)="toggleOptionsDrawer()">
    <i class="fa fa-3x fa-cog spin"></i>
  </button>
  <div class="theme-settings__inner">
    <div class="scrollbar-container">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="theme-settings__options-wrapper">
          <h3 class="themeoptions-heading">
            Layout Options
          </h3>
          <div class="p-3">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                      <!-- <mat-checkbox (change)="toggleFixedFooter()"></mat-checkbox> -->
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading">Fixed Footer
                      </div>
                      <div class="widget-subheading">Makes the app footer bottom fixed, always visible!
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <h3 class="themeoptions-heading">
            <div>
              Header Options
            </div>
            <button type="button" (click)="configActions.updateConfig({headerTheme: '' })"
                    class="btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm">
              Restore Default
            </button>
          </h3>
          <div class="p-3">
            <ul class="list-group">
              <li class="list-group-item">
                <h5 class="pb-2">
                  Choose Color Scheme
                </h5>
                <div class="theme-settings-swatches">
                  <div class="swatch-holder bg-primary"
                       (click)="configActions.updateConfig({headerTheme: 'bg-primary text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-secondary"
                       (click)="configActions.updateConfig({headerTheme: 'bg-secondary text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-success"
                       (click)="configActions.updateConfig({headerTheme: 'bg-success text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-info"
                       (click)="configActions.updateConfig({headerTheme: 'bg-info text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-warning"
                       (click)="configActions.updateConfig({headerTheme: 'bg-warning text-darker' })">
                  </div>
                  <div class="swatch-holder bg-danger"
                       (click)="configActions.updateConfig({headerTheme: 'bg-danger text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-light"
                       (click)="configActions.updateConfig({headerTheme: 'bg-light text-darker' })">
                  </div>
                  <div class="swatch-holder bg-dark"
                       (click)="configActions.updateConfig({headerTheme: 'bg-dark text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-focus"
                       (click)="configActions.updateConfig({headerTheme: 'bg-focus text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-alternate"
                       (click)="configActions.updateConfig({headerTheme: 'bg-alternate text-lighter' })">
                  </div>
                  <div class="divider">
                  </div>
                  <div class="swatch-holder bg-vicious-stance"
                       (click)="configActions.updateConfig({headerTheme: 'bg-vicious-stance text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-midnight-bloom"
                       (click)="configActions.updateConfig({headerTheme: 'bg-midnight-bloom text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-night-sky"
                       (click)="configActions.updateConfig({headerTheme: 'bg-night-sky text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-slick-carbon"
                       (click)="configActions.updateConfig({headerTheme: 'bg-slick-carbon text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-asteroid"
                       (click)="configActions.updateConfig({headerTheme: 'bg-asteroid text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-royal"
                       (click)="configActions.updateConfig({headerTheme: 'bg-royal text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-warm-flame"
                       (click)="configActions.updateConfig({headerTheme: 'bg-warm-flame text-darker' })">
                  </div>
                  <div class="swatch-holder bg-night-fade"
                       (click)="configActions.updateConfig({headerTheme: 'bg-night-fade text-darker' })">
                  </div>
                  <div class="swatch-holder bg-sunny-morning"
                       (click)="configActions.updateConfig({headerTheme: 'bg-sunny-morning text-darker' })">
                  </div>
                  <div class="swatch-holder bg-tempting-azure"
                       (click)="configActions.updateConfig({headerTheme: 'bg-tempting-azure text-darker' })">
                  </div>
                  <div class="swatch-holder bg-amy-crisp"
                       (click)="configActions.updateConfig({headerTheme: 'bg-amy-crisp text-darker' })">
                  </div>
                  <div class="swatch-holder bg-heavy-rain"
                       (click)="configActions.updateConfig({headerTheme: 'bg-heavy-rain text-darker' })">
                  </div>
                  <div class="swatch-holder bg-dark"
                       (click)="configActions.updateConfig({headerTheme: 'bg-dark text-darker' })">
                  </div>
                  <div class="swatch-holder bg-malibu-beach"
                       (click)="configActions.updateConfig({headerTheme: 'bg-malibu-beach text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-deep-blue"
                       (click)="configActions.updateConfig({headerTheme: 'bg-deep-blue text-darker' })">
                  </div>
                  <div class="swatch-holder bg-ripe-malin"
                       (click)="configActions.updateConfig({headerTheme: 'bg-ripe-malin text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-arielle-smile"
                       (click)="configActions.updateConfig({headerTheme: 'bg-arielle-smile text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-plum-plate"
                       (click)="configActions.updateConfig({headerTheme: 'bg-plum-plate text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-happy-fisher"
                       (click)="configActions.updateConfig({headerTheme: 'bg-happy-fisher text-darker' })">
                  </div>
                  <div class="swatch-holder bg-happy-itmeo"
                       (click)="configActions.updateConfig({headerTheme: 'bg-happy-itmeo text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-mixed-hopes"
                       (click)="configActions.updateConfig({headerTheme: 'bg-mixed-hopes text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-strong-bliss"
                       (click)="configActions.updateConfig({headerTheme: 'bg-strong-bliss text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-grow-early"
                       (click)="configActions.updateConfig({headerTheme: 'bg-grow-early text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-love-kiss"
                       (click)="configActions.updateConfig({headerTheme: 'bg-love-kiss text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-premium-dark"
                       (click)="configActions.updateConfig({headerTheme: 'bg-premium-dark text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-happy-green"
                       (click)="configActions.updateConfig({headerTheme: 'bg-happy-green text-lighter' })">
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <h3 class="themeoptions-heading">
            <div>Sidebar Options</div>
            <button type="button" (click)="configActions.updateConfig({sidebarTheme: '' })"
                    class="btn-pill btn-shadow btn-wide ml-auto btn btn-focus btn-sm switch-sidebar-cs-class"
                   >
              Restore Default
            </button>
          </h3>
          <div class="p-3">
            <ul class="list-group">
              <li class="list-group-item">
                <h5 class="pb-2">Choose Color Scheme
                </h5>
                <div class="theme-settings-swatches">
                  <div class="swatch-holder bg-primary"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-primary text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-secondary"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-secondary text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-success"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-success text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-info"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-info text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-warning"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-warning text-darker' })">
                  </div>
                  <div class="swatch-holder bg-danger"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-danger text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-light"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-light text-darker' })">
                  </div>
                  <div class="swatch-holder bg-dark"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-dark text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-focus"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-focus text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-alternate"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-alternate text-lighter' })">
                  </div>
                  <div class="divider">
                  </div>
                  <div class="swatch-holder bg-vicious-stance"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-vicious-stance text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-midnight-bloom"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-midnight-bloom text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-night-sky"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-night-sky text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-slick-carbon"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-slick-carbon text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-asteroid"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-asteroid text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-royal"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-royal text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-warm-flame"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-warm-flame text-darker' })">
                  </div>
                  <div class="swatch-holder bg-night-fade"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-night-fade text-darker' })">
                  </div>
                  <div class="swatch-holder bg-sunny-morning"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-sunny-morning text-darker' })">
                  </div>
                  <div class="swatch-holder bg-tempting-azure"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-tempting-azure text-darker' })">
                  </div>
                  <div class="swatch-holder bg-amy-crisp"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-amy-crisp text-darker' })">
                  </div>
                  <div class="swatch-holder bg-heavy-rain"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-heavy-rain text-darker' })">
                  </div>
                  <div class="swatch-holder bg-dark"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-dark text-darker' })">
                  </div>
                  <div class="swatch-holder bg-malibu-beach"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-malibu-beach text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-deep-blue"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-deep-blue text-darker' })">
                  </div>
                  <div class="swatch-holder bg-ripe-malin"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-ripe-malin text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-arielle-smile"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-arielle-smile text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-plum-plate"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-plum-plate text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-happy-fisher"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-happy-fisher text-darker' })">
                  </div>
                  <div class="swatch-holder bg-happy-itmeo"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-happy-itmeo text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-mixed-hopes"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-mixed-hopes text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-strong-bliss"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-strong-bliss text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-grow-early"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-grow-early text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-love-kiss"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-love-kiss text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-premium-dark"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-premium-dark text-lighter' })">
                  </div>
                  <div class="swatch-holder bg-happy-green"
                       (click)="configActions.updateConfig({sidebarTheme: 'bg-happy-green text-lighter' })">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
