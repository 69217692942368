import { Pipe, PipeTransform } from '@angular/core';

interface Filtro {
  parametro: string;
  count: string;
}


@Pipe({
  name: 'anyScopeCount'
})
export class AnyScopeCountPipe implements PipeTransform {

  transform(items: any[], filter: Filtro): number {

    let total = 0;
    if (!items || !filter.parametro) {
      items.find(item => { total += item[filter.count]});
      return total;
    }
    filter.parametro = this.getCleanedString(filter.parametro, true);
    const listSearch = filter.parametro.split(' ');
    let temp = this.getCleanedString(JSON.stringify(items), false);
    items = JSON.parse(temp);
     // filter items array, items which match and return true will be kept, false will be filtered out
    items.filter((item: any) => this.applyFilter(item, listSearch)).find(item => { total += item[filter.count]});
    return total;
  }

  /**
   * Perform the filtering.
   *
   * @param {any} scope The object to compare to the filter.
   * @param {string[]} filter The filter to apply.
   * @return {boolean} True if param of object satisfies filters, false if not.
   */
  applyFilter(scope: any, filter: string[]): boolean {
    const exist = filter.every( (search) => {
      for (const field in scope) {
        // if (typeof scope[field] === 'string') {
          if (search && search.trim() !== '') {
            let regresa;
            if (field.toLowerCase() === 'id') {
              regresa = ('#' + scope[field]).toLowerCase().indexOf(search.toLowerCase().trim()) > -1;
            } else {
              regresa = ('' + scope[field]).toLowerCase().indexOf(search.toLowerCase().trim()) > -1;
            }
            if (regresa) {
              return true;
            }
          } else {
            return true;
          }
        // } else if (typeof scope[field] === 'number') {
        //   if (search && search.trim() !== '') {
        //     const regresa = scope[field] === Number(search.trim());
        //     if (regresa) {
        //       return true;
        //     }
        //   } else {
        //     return true;
        //   }
        // }
      }
    });
    return exist;
  }

  getCleanedString(cadena: string, trim?: boolean, lowerCase?: boolean, deleteSimbols?: boolean) {

    if (trim) {
      cadena = cadena.trim();
    }
    // Definimos los caracteres que queremos eliminar
    const specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
 
    if (deleteSimbols) {
    // Los eliminamos todos
      for (var i = 0; i < specialChars.length; i++) {
        cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      }
    }
 
    if (lowerCase) {
      // Lo queremos devolver limpio en minusculas
      cadena = cadena.toLowerCase();
    }
 
    // // Quitamos espacios y los sustituimos por _ porque nos gusta mas asi
    // cadena = cadena.replace(/ /g,"_");
 
    // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
    cadena = cadena.replace(/[ÀÁÂÃÄÅÆ]/gi,"A");
    cadena = cadena.replace(/[ÈÉÊË]/gi,"E");
    cadena = cadena.replace(/[ÌÍÎÏ]/gi,"I");
    cadena = cadena.replace(/[ÒÓÔÕÖØ]/gi,"O");
    cadena = cadena.replace(/[ÙÚÛÜ]/gi,"U");
    cadena = cadena.replace(/Ñ/gi,"N");
    cadena = cadena.replace(/[àáâãäåæ]/gi,"a");
    cadena = cadena.replace(/[èéêë]/gi,"e");
    cadena = cadena.replace(/[ìíîï]/gi,"i");
    cadena = cadena.replace(/[òóôõöø]/gi,"o");
    cadena = cadena.replace(/[ùúûü]/gi,"u");
    cadena = cadena.replace(/ñ/gi,"n");
    return cadena;
  }

}
