import { Directive, Input, HostBinding, ElementRef, HostListener } from '@angular/core';
import { PhonePipe } from '../pipes/phone.pipe';

@Directive({
  selector: '[phone-number]',
  providers: [ PhonePipe ]
})
export class PhoneInputDirective  {
  private phoneNumber: string;

  constructor(private elementRef: ElementRef, private currentPipe: PhonePipe ) {}

  @HostListener('keydown', ['$event']) onkeydown(event: any) {
    this.phoneNumber = event.currentTarget.value;
    const text = this.phoneNumber.replace(/[^0-9]/g, '');
    if (text === '' || text === '0') {
      event.currentTarget.value = null;
    } else {
      event.currentTarget.value = Number(text);
    }
  }

  @HostListener('focusout', ['$event.target.value']) onFocusOut(value: any) {
    this.elementRef.nativeElement.value = this.currentPipe.transform(value, null);
  }

  @HostListener('change', ['$event.target.value']) onChange(value: any) {
    this.elementRef.nativeElement.value = this.currentPipe.transform(value, null);
  }

}
