import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CatalogosService } from 'src/app/services/catalogos.service';


@Injectable()
export class DepartamentoEffects {

  constructor(
    private actions$: Actions,
    public catalogosService: CatalogosService
  ) {}

  @Effect()
  cargarDepartamento$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_DEPARTAMENTOS ),
    switchMap( () => {
      return this.catalogosService.obtenerDepartamentos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarDepartamentosSuccess(respuesta.departamentos);
          }),
          catchError( error => of(new usuariosActions.CargarDepartamentosFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusDepartamento$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_DEPARTAMENTO_ESTATUS),
    switchMap( (action: any) => {
      return this.catalogosService.actualizaEstatusDepartamento(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaDepartamentoEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaDepartamentoEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaDepartamento$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_DEPARTAMENTO),
    switchMap( (action: any) => {
      return this.catalogosService.registraDepartamento(action.departamento)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaDepartamentoSuccess(action.departamento);
            }),
            catchError( error => of(new usuariosActions.ActualizaDepartamentoFail(error))  )
        );
    })
  );

}
