import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { Campana } from 'src/app/models/campana';
import { GLOBAL } from 'src/app/models/global';
import { GuardaryenviarcampanaService } from 'src/app/services/guardaryenviarcampana.service';
import { ListaCampanasService } from 'src/app/services/lista-campanas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editar-campana',
  templateUrl: './editar-campana.component.html',
  styleUrls: ['./editar-campana.component.scss']
})
export class EditarCampanaComponent implements OnInit {
  public campanaguardada: Campana = new Campana(null);
  public dns: string[] = [];
  public config: { readOnly: boolean } = {
    // removeButtons: 'Source,Save,NewPage,ExportPdf,Print,Preview,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,TextField,Radio,Textarea,Select,Button,ImageButton,HiddenField,Bold,CopyFormatting,NumberedList,Outdent,JustifyLeft,BidiLtr,Blockquote,BidiRtl,JustifyCenter,CreateDiv,Indent,BulletedList,RemoveFormat,Italic,Strike,Underline,Subscript,Superscript,JustifyBlock,Language,JustifyRight,Anchor,Unlink,Link,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,Format,Styles,TextColor,BGColor,ShowBlocks,Maximize,About',
    // removePlugins: 'codemirror',
    readOnly: false
  };
  htmlContent = '';
  public nombreValido = true;

  constructor(private editarcampanaservices: GuardaryenviarcampanaService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private serviceCampana: ListaCampanasService) {
    this.dns = GLOBAL.emailsDns;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params.id;
      this.editarcampanaservices.obtenerCampanaGuardada(id).subscribe(respuesta => {
        this.campanaguardada = respuesta.campanaguardada;
        if (this.campanaguardada.estatus === 1 && this.campanaguardada.activo == true) {
          // console.log(this.campanaguardada);
        } else {
          this.router.navigate(['/auth/marketing/allcampanas']);
          Swal.fire(
            'Error',
            'Esta campaña no puede acceder a esta sección.',
            'error'
          )
        };
      });
    });
  }

  ActualizarCampana(campanaguardada) {
    if (!this.campanaguardada.dns) {
      if (this.dns.length) {
        this.campanaguardada.dns = this.dns[0];
      } else {
        this.campanaguardada.dns = '';
      }
    }
    this.editarcampanaservices.ActualizarCampana(campanaguardada.id, campanaguardada).subscribe(
      (respuesta: any) => {
        this.toastr.success('Se actualizó la campana.');
        // Puedes cambiar de ruta o no hacer nada
        this.router.navigate(['/auth/marketing/allcampanas']);
        // this.campana = respuesta.campana;
      }, err => {
        console.log('Ocurrio un error al actualizar la campana', err);
      }
    );
  }

  existeCampana() {
    this.serviceCampana.existeCampna(this.campanaguardada.id, this.campanaguardada.tema).subscribe(
      resultado => {
        if (resultado.campana) {
          this.toastr.warning('El nombre de la campaña ya existe');
          this.nombreValido = false;
        } else {
          this.nombreValido = true;
        }
      }, (err: any) => {
        this.nombreValido = false;
      }
    );
  }

}
