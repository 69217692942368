
<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row mt-3">

  <div class="form-group ml-3 ">
    <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
    [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
    <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #range FechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
    [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
  </div>


    <button type="button" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex ml-auto mb-auto mr-3" printSectionId="print-section" ngxPrint #print [useExistingCss]="true" printTitle="Reporte de los KPIs vendedores">
      <span class="pr-2 align-middle opacity-7">
        <i class="fas fa-file-pdf-o"></i>
      </span>
      PDF
    </button>
    <div class="row" id="print-section">
      <div class="col-md-6">
        <div class="card main-card mb-3">
          <div class="card-header">
            Actividades
          </div>
          <div class="card-body">
            <apx-chart
              [series]="series"
              [chart]="{
                type: 'bar',
                height: 350,
                stacked: true
              }"
              [plotOptions]="{
                bar: {
                  horizontal: true
                }
              }"
              [xaxis]="{
                categories: categories
              }"
              [stroke]="{
                width: 1,
                colors: ['#fff']
              }"
              [fill]="{
                opacity: 1
              }"
              [yaxis]="{
                title: {
                  text: undefined
                }
              }"
              [legend]="{
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }"
            ></apx-chart>
            <!-- colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']; -->
          </div>
          <div class="mt-2">
            <div class="row p-2">
              <div class="col-sm-6">
                <div class="card no-shadow bg-transparent text-left">
                  <div class="widget-chart-content">
                    <ngb-pagination class="mb-2"
                      [collectionSize]="paginacionActividad.totalSize" [(page)]="paginacionActividad.page" [pageSize]="paginacionActividad.pageSize" [maxSize]="5" (pageChange)="cargarActividades(false)">
                      <ng-template ngbPaginationFirst>First</ng-template>
                      <ng-template ngbPaginationLast>Last</ng-template>
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                      <ng-template ngbPaginationEllipsis>...</ng-template>
                      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card main-card mb-3">
          <div class="card-header">
            Actividades
          </div>
          <div class="card-body">
            <apx-chart
              [series]="seriesActividades"
              [chart]="{
                type: 'polarArea',
                height: 350
              }"
              [labels]="categoriesActividades"
              [fill]="{
                opacity: 0.8
              }"
              [stroke]="{
                colors: ['#fff']
              }"
              [responsive]="[
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }
              ]"
              [yaxis]="{
                show: false
              }"
            ></apx-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card main-card mb-3" >
          <div class="card-header">
            <div class="card-header-title">Empresas nuevas</div>
            <div class="btn-actions-pane-right mr-4">
              ({{(empresas | anyScope: paginacionEmpresa.textSearch ).length}} de {{(empresas).length}})
              <div class="btn-group ml-2">
                <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionEmpresa.isActive">
                  <div class="input-holder">
                    <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionEmpresa.textSearch" (ngModelChange)="changeTextSearchEmpresa()">
                    <button class="search-icon" (click)="paginacionEmpresa.isActive = true"><span></span></button>
                  </div>
                  <button class="close" (click)="paginacionEmpresa.isActive = false; paginacionEmpresa.textSearch = ''; listSearchEmpresa = [];"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchEmpresa" (click)="removerTextSearchEmpresa(texto)">
              {{texto}}
              <i class="ml-1 fas fa-times"></i>
            </button>
            <div class="divider" *ngIf="listSearchEmpresa.length"></div>
            <div class="row">
              <div class="table-responsive col-md-12" *ngIf="empresas.length">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="v-center" (click)="sortEmpresa('nombre')">Nombres
                        <span *ngIf="paginacionEmpresa.sortType === '-nombre'" class="fa fa-caret-down"></span>
                        <span *ngIf="paginacionEmpresa.sortType === '+nombre'" class="fa fa-caret-up"></span>
                      </th>
                      <th class="text-right" (click)="sortEmpresa('cantidad')">Recuento de registros
                        <span *ngIf="paginacionEmpresa.sortType === '-cantidad'" class="fa fa-caret-down"></span>
                        <span *ngIf="paginacionEmpresa.sortType === '+cantidad'" class="fa fa-caret-up"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let empresa of empresas | anyScope: paginacionEmpresa.textSearch | orderBy: [paginacionEmpresa.sortType] | slice: (paginacionEmpresa.page-1) * paginacionEmpresa.pageSize : (paginacionEmpresa.page-1) * paginacionEmpresa.pageSize + paginacionEmpresa.pageSize">
                      <td class="v-center">
                        <div class="widget-content p-0">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left mr-3">
                              <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + empresa.id + '/imagenes'" alt="Imagen de perfil de usuario">
                            </div>
                            <div class="widget-content-left">
                              <div class="widget-heading">{{empresa.nombre}}</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-right"> {{empresa.cantidad}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="v-center"><b>{{empresasTotal.nombre}}</b></td>
                      <td class="text-right"><b>{{empresasTotal.cantidad}}</b></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="col-md-12" *ngIf="!empresas.length">
                <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="row p-2">
              <div class="col-sm-6">
                <div class="text-left">
                  <ngb-pagination
                    [collectionSize]="(empresas | anyScope: paginacionEmpresa.textSearch).length" [(page)]="paginacionEmpresa.page" [pageSize]="paginacionEmpresa.pageSize" [maxSize]="5">
                    <ng-template ngbPaginationFirst>First</ng-template>
                    <ng-template ngbPaginationLast>Last</ng-template>
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card main-card mb-3" >
          <div class="card-header">
            <div class="card-header-title">Contactos nuevos</div>
            <div class="btn-actions-pane-right mr-4">
              ({{(contactos | anyScope: paginacionContacto.textSearch ).length}} de {{(contactos).length}})
              <div class="btn-group ml-2">
                <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionContacto.isActive">
                  <div class="input-holder">
                    <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionContacto.textSearch" (ngModelChange)="changeTextSearchContacto()">
                    <button class="search-icon" (click)="paginacionContacto.isActive = true"><span></span></button>
                  </div>
                  <button class="close" (click)="paginacionContacto.isActive = false; paginacionContacto.textSearch = ''; listSearchContacto = [];"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchContacto" (click)="removerTextSearchContacto(texto)">
              {{texto}}
              <i class="ml-1 fas fa-times"></i>
            </button>
            <div class="divider" *ngIf="listSearchContacto.length"></div>
            <div class="row">
                <div class="table-responsive col-md-12" *ngIf="contactos.length">
                  <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="v-center" (click)="sortContacto('nombre')">Nombres
                          <span *ngIf="paginacionContacto.sortType === '-nombre'" class="fa fa-caret-down"></span>
                          <span *ngIf="paginacionContacto.sortType === '+nombre'" class="fa fa-caret-up"></span>
                        </th>
                        <th class="text-right" (click)="sortContacto('cantidad')">Recuento de registros
                          <span *ngIf="paginacionContacto.sortType === '-cantidad'" class="fa fa-caret-down"></span>
                          <span *ngIf="paginacionContacto.sortType === '+cantidad'" class="fa fa-caret-up"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let contacto of contactos | anyScope: paginacionContacto.textSearch | orderBy: [paginacionContacto.sortType] | slice: (paginacionContacto.page-1) * paginacionContacto.pageSize : (paginacionContacto.page-1) * paginacionContacto.pageSize + paginacionContacto.pageSize">
                        <td class="v-center">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-3">
                                <img width="42" class="rounded-circle" [src]="url + '/usuarios/perfiles/' + contacto.id + '/imagenes'" alt="Imagen de perfil de usuario">
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading">{{contacto.nombre}}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-right"> {{contacto.cantidad}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="v-center"><b>{{contactosTotal.nombre}}</b></td>
                        <td class="text-right"><b>{{contactosTotal.cantidad}}</b></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="col-md-12" *ngIf="!contactos.length">
                  <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
                </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="row p-2">
              <div class="col-sm-6">
                <div class="text-left">
                  <ngb-pagination
                    [collectionSize]="(contactos | anyScope: paginacionContacto.textSearch).length" [(page)]="paginacionContacto.page" [pageSize]="paginacionContacto.pageSize" [maxSize]="5">
                    <ng-template ngbPaginationFirst>First</ng-template>
                    <ng-template ngbPaginationLast>Last</ng-template>
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card main-card mb-3" >
          <div class="card-header">
            Oportunidades por etapas
          </div>
          <div class="card-body">
            <ejs-accumulationchart [legendSettings]="legend" [tooltip]="tooltipSettings" *ngIf="dataFunnel.length">
              <e-accumulation-series-collection>
                <e-accumulation-series type="Funnel" [dataSource]="dataFunnel" xName="name" yName="value"  innerTadius="10%" [dataLabel]="chartLabel" [palettes]="colorPalettes"></e-accumulation-series>
              </e-accumulation-series-collection>
            </ejs-accumulationchart>
            <div *ngIf="!dataFunnel.length">
              <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card main-card mb-3">
          <div class="card-header">
            <div class="card-header-title">Oportunidades abiertas</div>
            <div class="btn-actions-pane-right mr-4">
              ({{(oportunidadesAbiertas | anyScope: paginacionOportunidad.textSearch ).length}} de {{(oportunidadesAbiertas).length}})
              <div class="btn-group ml-2">
                <div class="search-wrapper mb-2 mt-2" [class.active]="paginacionOportunidad.isActive">
                  <div class="input-holder">
                    <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchusuarios" id="searchusuarios" #searchusuarios="ngModel" [(ngModel)]="paginacionOportunidad.textSearch" (ngModelChange)="changeTextSearchOportunidad()">
                    <button class="search-icon" (click)="paginacionOportunidad.isActive = true"><span></span></button>
                  </div>
                  <button class="close" (click)="paginacionOportunidad.isActive = false; paginacionOportunidad.textSearch = ''; listSearchOportunidad = [];"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <button type="button" class="mr-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearchOportunidad" (click)="removerTextSearchOportunidad(texto)">
              {{texto}}
              <i class="ml-1 fas fa-times"></i>
            </button>
            <div class="divider" *ngIf="listSearchOportunidad.length"></div>
            <div class="row">
              <div class="col-md-12 table-responsive" style="height: 445px;" *ngIf="oportunidadesAbiertas.length">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="v-center" style="width: 85%" (click)="sortOportunidad('nombre')">Nombres
                        <span *ngIf="paginacionOportunidad.sortType === '-nombre'" class="fa fa-caret-down"></span>
                        <span *ngIf="paginacionOportunidad.sortType === '+nombre'" class="fa fa-caret-up"></span>
                      </th>
                      <th class="text-right" style="width: 15%" (click)="sortOportunidad('cantidad')">Importe
                        <span *ngIf="paginacionOportunidad.sortType === '-cantidad'" class="fa fa-caret-down"></span>
                        <span *ngIf="paginacionOportunidad.sortType === '+cantidad'" class="fa fa-caret-up"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="height: 355px;">
                      <tr *ngFor="let oportunidad of oportunidadesAbiertas | anyScope: paginacionOportunidad.textSearch | orderBy: [paginacionOportunidad.sortType] | slice: (paginacionOportunidad.page-1) * paginacionOportunidad.pageSize : (paginacionOportunidad.page-1) * paginacionOportunidad.pageSize + paginacionOportunidad.pageSize">
                        <td class="v-center line-clamp"><a [routerLink]="['/auth/oportunidades/profile', oportunidad.id]">{{oportunidad.nombre}}</a></td>
                        <td class="text-right"> {{oportunidad.cantidad | currency:'MXN':'symbol' }}</td>
                      </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="v-center"><b>{{oportunidadesAbiertasTotal.nombre}}</b></td>
                      <td class="text-right"><b>{{oportunidadesAbiertasTotal.cantidad | currency:'MXN':'symbol' }}</b></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="col-md-12" *ngIf="!oportunidadesAbiertas.length">
                <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
              </div>
            </div>
          </div>
          <div class="row pl-2">
            <div class="col-sm-6">
              <div class="text-left">
                <ngb-pagination
                  [collectionSize]="(oportunidadesAbiertas | anyScope: paginacionOportunidad.textSearch).length" [(page)]="paginacionOportunidad.page" [pageSize]="paginacionOportunidad.pageSize" [maxSize]="5">
                  <ng-template ngbPaginationFirst>First</ng-template>
                  <ng-template ngbPaginationLast>Last</ng-template>
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                  <ng-template ngbPaginationEllipsis>...</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card main-card mb-3">
          <div class="card-header">
            Oportunidades por clasificaciones
          </div>
          <div class="card-body">
            <apx-chart *ngIf="seriesIndustrias.length"
              [series]="seriesClasificaciones"
              [colors]="[ '#66DA26', '#2E93fA']"
              [chart]="{
                height: '395',
                type: 'bar',
                stacked: true
              }"
              [plotOptions]="{
                bar: {
                  horizontal: false,
                  columnWidth: '70%'
                }
              }"
              [stroke]="{
                width: 1,
                colors: ['#fff']
              }"
              [legend]="{
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }"
              [xaxis]="{
                categories: labelsClasificaciones
              }"
              [yaxis]="labelsOptionsY"
            ></apx-chart>
              <div class="col-md-12"  *ngIf="!seriesClasificaciones.length">
                <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- <div class="card main-card mb-3">
          <div class="card-header">
            Oportunidades por clases
          </div>
          <div class="card-body">
            <apx-chart *ngIf="seriesClases.length"
                  [series]="seriesClases"
                  [chart]="{
                    height: 380,
                    type: 'donut'
                  }"
                  [labels]="labelsClases"
                  [responsive]="[
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200
                      },
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }
                ]"
            ></apx-chart>
            <div class="col-md-12"  *ngIf="!seriesClases.length">
              <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
            </div>
          </div>
        </div> -->
        <div class="card main-card mb-3">
          <div class="card-header">
            Oportunidades por industrias
          </div>
          <div class="card-body">
            <apx-chart *ngIf="seriesIndustrias.length"
              [series]="seriesIndustrias"
              [chart]="{
                height: '395',
                type: 'pie'
              }"
              [labels]="labelsIndustrias"
              [responsive]="[
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }
              ]"
            ></apx-chart>
            <div class="col-md-12"  *ngIf="!seriesIndustrias.length">
              <p class="h3 text-primary text-center">NO HAY INFORMACIÓN REGISTRADA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</div>