import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { CatalogosService } from 'src/app/services/catalogos.service';


@Injectable()
export class OrigenContactoEffects {

  constructor(
    private actions$: Actions,
    public catalogosService: CatalogosService
  ) {}

  @Effect()
  cargarOrigenContactos$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_ORIGEN_CONTACTOS ),
    switchMap( () => {
      return this.catalogosService.obtenerOrigenContactos()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarOrigenContactosSuccess(respuesta.origenes);
          }),
          catchError( error => of(new usuariosActions.CargarOrigenContactosFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusOrigenContacto$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_ORIGEN_CONTACTO_ESTATUS),
    switchMap( (action: any) => {
      return this.catalogosService.actualizaEstatusOrigenContacto(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaOrigenContactoEstatusSuccess(action.id);
            }),
            catchError( error => of(new usuariosActions.ActualizaOrigenContactoEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaOrigenContacto$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_ORIGEN_CONTACTO),
    switchMap( (action: any) => {
      return this.catalogosService.registraOrigenContacto(action.origen)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaOrigenContactoSuccess(action.origen);
            }),
            catchError( error => of(new usuariosActions.ActualizaOrigenContactoFail(error))  )
        );
    })
  );

}
