import {Component, OnInit} from '@angular/core';
import { pagesAnimation } from 'src/app/animations/pages.animation';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  animations: [ pagesAnimation]
})
export class PagesLayoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
