import { SortOrder } from './sort';

export class Industria {
  public id: number;
  public nombre: string;
	public clasificacion: number;
	public desClasificacion: string;
  public status: boolean;
  constructor(
    obj: ObjIndustria
  ) {
    this.id = obj && obj.id ? obj.id : 0;
		this.nombre = obj && obj.nombre ? obj.nombre : '';
		this.clasificacion = obj && obj.clasificacion ? obj.clasificacion : null;
		this.desClasificacion = obj && obj.desClasificacion ? obj.desClasificacion : '';
    this.status = obj && obj.status ? obj.status : false;
  }
}

interface ObjIndustria {
  id: number;
  nombre: string;
  clasificacion: number;
	desClasificacion: string;
  status: boolean;
}


export const IndustriaSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombre'},
	{ id: '-nombre', text: 'Nombre desc'},
	{ id: '+desClasificacion', text: 'Clasificación'},
  { id: '-desClasificacion', text: 'Clasificación desc'}
];

