import { Routes } from '@angular/router';
import { AutoActualizacionCampanaComponent } from './components/auto-actualizacion-campana/auto-actualizacion-campana.component';
import { GraciasConfirmarComponent } from './components/auto-actualizacion-campana/gracias-confirmar/gracias-confirmar.component';
import { DesuscripcionComponent } from './components/desuscripcion/desuscripcion/desuscripcion.component';
// import { HtmlViewerCampanaComponent } from './components/html-viewer-campana/html-viewer-campana.component';
import { LoginComponent } from '../components/public/login/login.component';



export const sharedRoutes: Routes = [
  // { path: 'html-viewer', component: HtmlViewerCampanaComponent },
  { path: 'campana-auto-actualizacion/:key', component: AutoActualizacionCampanaComponent, data: { extraParameter: 'pagesMenu' } },
  { path: 'gracias-confirmar/:key', component: GraciasConfirmarComponent, data: { extraParameter: 'pagesMenu' } },
  { path: 'hasta-pronto', component: DesuscripcionComponent, data: { extraParameter: 'pagesMenu' } },
  { path: '**', component: LoginComponent }
];
