import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { UsuariosService } from '../../services/usuarios.service';
import { Router } from '@angular/router';
import { CargarUsuario, EditarUsuario, EliminarUsuario } from '../actions';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user';

@Injectable()
export class UsuarioEffects {

    constructor(
      private actions$: Actions,
      public usuariosService: UsuariosService,
      public router: Router,
      private toastr: ToastrService,
      private store: Store<AppState>,
      private location: Location,
      private authService: AuthService
    ) {}

    @Effect()
    cargarUsuario$ = this.actions$.pipe(
      ofType( usuarioActions.CARGAR_USUARIO ),
      switchMap( (action: any ) => {
        return this.usuariosService.getUser(action.id)
          .pipe(
            map( user => new usuarioActions.CargarUsuarioSuccess(user.usuario) ),
            catchError( error => of(new usuarioActions.CargarUsuarioFail(error))  )
          );
      })
    );

    @Effect()
    editarUsuario$ = this.actions$.pipe(
      ofType( usuarioActions.EDITAR_USUARIO),
      switchMap( (action: EditarUsuario) => {
        return this.usuariosService.registerUser(action.usuario).pipe(
          tap( request => new usuarioActions.CargarNotificacionesMini(1, 20, 0)),
          map( (response) => {
            if (response.usuario && response.usuario.id) {
              this.router.navigate(['/auth/users']);
            }
            this.toastr.success('Usuario actualizado correctamente');
            return new usuarioActions.CargarUsuarioSuccess(response.usuario);
          }), catchError( error => of(new usuarioActions.CargarUsuarioFail(error)))
        );
      })
    );

    @Effect()
    eliminaUsuario$ = this.actions$.pipe(
      ofType( usuarioActions.ELIMINAR_USUARIO),
      switchMap( (action: EliminarUsuario) => {
        return this.usuariosService.deleteUser(action.id).pipe(
          tap( request => new usuarioActions.CargarNotificacionesMini(1, 20, 0)),
          map( (response) => {
            if (response.usuario && response.usuario.id) {
              this.router.navigate(['/auth/usuarios']);
            }
            this.toastr.success('Usuario eliminado correctamente');
            this.store.dispatch(new usuarioActions.EliminarUsuarios(action.id));
            return new usuarioActions.EliminarUsuarioSuccess(response.usuario);
          }), catchError( error => of(new usuarioActions.EditarUsuarioFail(error)))
        );
      })
    );

    @Effect()
    editarPerfil$ = this.actions$.pipe(
      ofType( usuarioActions.EDITAR_PERFIL),
      switchMap( (action: EditarUsuario) => {
        return this.usuariosService.updateUserPerfil(action.usuario).pipe(
          tap( request => new usuarioActions.CargarNotificacionesMini(1, 20, 0)),
          map( (response) => {
            const usr: User = response.usuario;
            if (response.usuario && response.usuario.id) {
              this.router.navigate(['/auth/users']);
            }
            this.toastr.success('Usuario actualizado correctamente');
            const ident = this.authService.printIdentity();
            ident.nombre = usr.nombre;
            ident.apellidos = usr.apellidoPaterno + ' ' + usr.apellidoMaterno;
            ident.email = usr.email;
            this.location.back();
            this.authService.setIdentity(ident);
            return new usuarioActions.LoadAuthSuccess(ident);
          }), catchError( error => of(new usuarioActions.CargarUsuarioFail(error)))
        );
      })
    );

}
