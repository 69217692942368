import { SortOrder } from './sort';

export class KpisVendedores {
  public id: number;
  public nombre: string;
  public cantidad: number;
  constructor(
    obj: ObjKpisVendedores
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.cantidad = obj && obj.cantidad ? obj.cantidad : 0;
  }
}

interface ObjKpisVendedores {
  id: number;
  nombre: string;
  cantidad: number;
}


export const KpisVendedorSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombres'},
  { id: '-nombre', text: 'Nombres desc'},
  { id: '+cantidad', text: 'Recuento de registros'},
  { id: '-cantidad', text: 'Recuento de registros desc'}
];

export const KpisVendedorOportunidadSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombres'},
  { id: '-nombre', text: 'Nombres desc'},
  { id: '+cantidad', text: 'Importe'},
  { id: '-cantidad', text: 'Importe desc'}
];