import { SortOrder } from './sort';
import { Catalogo } from './catalogo'

export class Ticket {
  public id: number;
  public descripcion: string;
  public asunto: string;
  public departamento: number;
  public desDepartamento: string;
  public departamentoEmisor: number;
  public desDepartamentoEmisor: string;
  public usuarioRegistro: number;
  public desUsuarioRegistro: string;
  public fecRegistro: Date;
  public fecModificacion: Date;
  public usuarioRespondio: number;
  public desUsuarioRespondio: string;
  public usuariosReceptores: Catalogo[];
  public status: number;
  public mensajes: TicketMensaje[];
  public adjuntos: AdjuntosMensaje[];
  public adjuntosEliminados: AdjuntosMensaje[];
  public existe: number | boolean;
  constructor(
    obj: ObjTicket
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
    this.asunto = obj &&obj.asunto ? obj.asunto : '';
    this.departamento = obj &&obj.departamento ? obj.departamento : null;
    this.desDepartamento = obj &&obj.desDepartamento ? obj.desDepartamento : '';
    this.departamentoEmisor = obj &&obj.departamentoEmisor ? obj.departamentoEmisor : null;
    this.desDepartamentoEmisor = obj &&obj.desDepartamentoEmisor ? obj.desDepartamentoEmisor : '';
    this.usuarioRegistro = obj &&obj.usuarioRegistro ? obj.usuarioRegistro : null;
    this.desUsuarioRegistro = obj &&obj.desUsuarioRegistro ? obj.desUsuarioRegistro : '';
    this.fecRegistro = obj &&obj.fecRegistro ? obj.fecRegistro : null;
    this.fecModificacion = obj &&obj.fecModificacion ? obj.fecModificacion : null;
    this.usuarioRespondio = obj &&obj.usuarioRespondio ? obj.usuarioRespondio : null;
    this.desUsuarioRespondio = obj &&obj.desUsuarioRespondio ? obj.desUsuarioRespondio : '';
    this.usuariosReceptores = obj && obj.usuariosReceptores ? obj.usuariosReceptores : [];
    this.status = obj && obj.status ? obj.status : 0;
    this.mensajes = obj && obj.mensajes ? obj.mensajes : [];
    this.adjuntos = obj && obj.adjuntos ? obj.adjuntos : [];
    this.adjuntosEliminados = obj && obj.adjuntosEliminados ? obj.adjuntosEliminados : [];
    this.existe = obj && obj.existe ? obj.existe : 0;
  }
}

interface ObjTicket {
  id: number;
  descripcion: string;
  asunto: string;
  departamento: number;
  desDepartamento: string;
  departamentoEmisor: number;
  desDepartamentoEmisor: string;
  usuarioRegistro: number;
  desUsuarioRegistro: string;
  fecRegistro: Date;
  fecModificacion: Date;
  usuarioRespondio: number;
  desUsuarioRespondio: string;
  usuariosReceptores: Catalogo[];
  status: number;
  mensajes: TicketMensaje[];
  adjuntos: AdjuntosMensaje[];
  adjuntosEliminados: AdjuntosMensaje[];
  existe: number | boolean;
}

export const TicketSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+nombre', text: 'Nombre'},
  { id: '-nombre', text: 'Nombre desc'}
];

export const TicketsSortType: SortOrder[] = [
  { id: '+id', text: 'ID'},
  { id: '-id', text: 'ID desc'},
  { id: '+asunto', text: 'Nombre'},
  { id: '-asunto', text: 'Nombre desc'},
  { id: '+desDepartamento', text: 'Departamento receptor'},
  { id: '-desDepartamento', text: 'Departamento receptor desc'},
  { id: '+desDepartamentoEmisor', text: 'Departamento emisor'},
  { id: '-desDepartamentoEmisor', text: 'Departamento emisor desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'},
  { id: '+fecModificacion', text: 'Última modificación'},
  { id: '-fecModificacion', text: 'Última modificación desc'}
];

export class TicketMensaje {
  public id: number;
  public ticket: number;
  public mensaje: string;
  public usuario: number;
  public desUsuario: string;
  public fecRegistro: Date;
  public departamento: number;
  public desDepartamento: string;
  public adjuntos: AdjuntosMensaje[];
  constructor(
    obj: ObjTicketMensaje
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.ticket = obj && obj.ticket ? obj.ticket : null;
    this.mensaje = obj && obj.mensaje ? obj.mensaje : null;
    this.usuario = obj && obj.usuario ? obj.usuario : null;
    this.desUsuario = obj && obj.desUsuario ? obj.desUsuario : null;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
    this.departamento = obj &&obj.departamento ? obj.departamento : null;
    this.desDepartamento = obj &&obj.desDepartamento ? obj.desDepartamento : '';
    this.adjuntos = obj && obj.adjuntos ? obj.adjuntos : [];
  }
}

interface ObjTicketMensaje {
  id: number;
  ticket: number;
  mensaje: string;
  usuario: number;
  desUsuario: string;
  fecRegistro: Date;
  departamento: number;
  desDepartamento: string;
  adjuntos: AdjuntosMensaje[];
}


export class AdjuntosMensaje {
  id: number;
  ticket: number;
  mensaje: number;
  urlPath: any;
  type: string;
  size: number;
  file: File;
  name: string;
  usuario: number;
  desUsuario: string;
  fecRegistro: Date;
  fecCreacion: Date;
  constructor(
    obj: ObjAdjuntosMensaje
  ) {
    this.id = obj && obj.id ? obj.id : null;
    this.ticket = obj && obj.ticket ? obj.ticket : null;
    this.mensaje = obj && obj.mensaje ? obj.mensaje : null;
    this.urlPath = obj && obj.urlPath ? obj.urlPath : null;
    this.type = obj && obj.type ? obj.type : null;
    this.size = obj && obj.size ? obj.size : null;
    this.file = obj && obj.file ? obj.file : null;
    this.name = obj && obj.name ? obj.name : null;
    this.usuario = obj && obj.usuario ? obj.usuario : null;
    this.desUsuario = obj && obj.desUsuario ? obj.desUsuario : null;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
    this.fecCreacion = obj && obj.fecCreacion ? obj.fecCreacion : null;
  }
}

interface ObjAdjuntosMensaje {
  id: number;
  ticket: number;
  mensaje: number;
  urlPath: any;
  type: string;
  size: number;
  file: File;
  name: string;
  usuario: number;
  desUsuario: string;
  fecRegistro: Date;
  fecCreacion: Date;
}
