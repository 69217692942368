import * as fromUsuario from '../actions';
import { User } from 'src/app/models/user';

export interface UsuarioState {
  usuario: User;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: UsuarioState = {
  usuario: new User(null),
  loaded: false,
  loading: false,
  error: null
};

export function usuarioReducer( state = estadoInicial, action: fromUsuario.usuarioAcciones ): UsuarioState {

  switch ( action.type ) {
    case fromUsuario.CARGAR_USUARIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.CARGAR_USUARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        usuario: {...action.usuario}
      };
    case fromUsuario.CARGAR_USUARIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    case fromUsuario.EDITAR_USUARIO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuario.EDITAR_PERFIL:
      return {
        ...state,
        usuario: {...action.usuario},
        loading: true,
        error: null
      };
    case fromUsuario.EDITAR_USUARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case fromUsuario.EDITAR_USUARIO_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          ...action.payload
        }
      };
    default:
      return state;
  }
}

