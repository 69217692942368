<app-page-title [heading]="title" [icon]="'pe-7s-id icon-gradient bg-dark'"
  [subheading]="'Editado de los permisos de usuarios'"></app-page-title>
<div class="row">
  <div class="col-lg-6">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Tipos de usuario</div>
        <div class="btn-actions-pane-right">
          <div role="group">
            <button type="button" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-2" (click)="nuevo()">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="scroll-area-lg">
          <section class="scrollbar-container">
            <perfect-scrollbar [autoPropagation]="true">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let tipo of tipos">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="card no-shadow bg-transparent text-left">
                        <div class="widget-content p-0">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left mr-2">#{{tipo.id}}</div>
                            <div class="widget-content-left">
                              <div class="widget-heading">{{tipo.nombre}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="card no-shadow bg-transparent text-right">
                        <div class="widget-content p-0">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-right">
                              <button class="btn-square btn btn-sm btn-outline-secondary" (click)="show(tipo)" title="Ver y editar los permisos y opciones de notificaciones de los tipos de usuarios">
                                <i class="fa fa-user-cog"></i> Mostrar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </section>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <form #formPermisos="ngForm" name="formPermisos" (ngSubmit)="registrarPermisos()" autocomplete="off">
      <div class="main-card mb-3 card">
        <div class="card-header">Permisos de usuario</div>
        <div class="card-body p-0">
          <div class="col-12 mt-3">
            <div class="form-group">
              <input type="text" class="form-control" title="Nombre de tipo de perfil de usuario" placeholder="Nombre de perfil..." id="nombrePermiso" name="nombrePermiso" #nombrePermiso="ngModel" [(ngModel)]="tipoSelected.nombre" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" required>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group">
              <label for="descripcionPermiso">Describe el rol para este perfil de usuario</label>
              <textarea type="text" class="form-control" title="Notas de descripción del perfil de usuario" placeholder="Escribe aquí..." id="descripcionPermiso" name="descripcionPermiso" #descripcionPermiso="ngModel" [(ngModel)]="tipoSelected.descripcion" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" required></textarea>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group">
              <div class="row">
                <div class="col-6 col-sm-4">
                  <button type="button" class="btn btn-secondary btn-block mb-2" (click)="activarMarketing()" title="Creación campañas de marketing, ver reporte y plantillas">Marketing</button>
                </div>
                <div class="col-6 col-sm-4">
                  <button type="button" class="btn btn-secondary btn-block mb-2" (click)="activarECO()" title="Ver y editar contactos, empresa y oportunidades">Vendedor</button>
                </div>
                <div class="col-6 col-sm-4">
                  <button type="button" class="btn btn-secondary btn-block mb-2" (click)="activarReportes()" title="Activar todos los reportes">Todos reportes</button>
                </div>
                <div class="col-6 col-sm-4">
                  <button type="button" class="btn btn-secondary btn-block mb-2" (click)="activarArchivos()" title="Ver y editar reporte editorial y gestor de archivos">Gestor archivos</button>
                </div>
                <div class="col-6 col-sm-4">
                  <button type="button" class="btn btn-secondary btn-block mb-2" (click)="activarTickets()" title="Contestar tickets">Tickets</button>
                </div>
                <div class="col-6 col-sm-4">
                  <button type="button" class="btn btn-secondary btn-block mb-2" (click)="activarCatalogos()" title="Configuración de los catálogos de departamentos, países, origenes de contactos, industrias y tipos de empresas">Catálogos</button>
                </div>
              </div>
            </div>
          </div>
          <ngb-tabset class="tabs-animated tabs-animated-line" justify="justified">
            <ngb-tab>
              <ng-template ngbTabTitle><span>Permisos</span></ng-template>
              <ng-template ngbTabContent>
                <ul class="todo-list-wrapper list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-2">
                          <div class="custom-checkbox custom-control">
                            <input type="checkbox" id="allPermisosForm" name="allPermisosForm" #allPermisosForm="ngModel" [(ngModel)]="todo" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="seleccionarTodo()">
                            <label class="custom-control-label" for="allPermisosForm">&nbsp; Seleccionar todo</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <hr class="p-0 m-0">
                <div class="scroll-area-lg">
                  <div class="scrollbar-container">
                    <perfect-scrollbar [autoPropagation]="true">
                      <ul class="todo-list-wrapper list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoForm" name="permisoForm" #permisoForm="ngModel" [(ngModel)]="permisos.web" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoForm">&nbsp; Entrar en web</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormusuariosListEdit" name="permisoFormusuariosListEdit" #permisoFormusuariosListEdit="ngModel" [(ngModel)]="permisos.usuariosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormusuariosListEdit">&nbsp; Editar lista de usuarios</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormusuariosListVer" name="permisoFormusuariosListVer" #permisoFormusuariosListVer="ngModel" [(ngModel)]="permisos.usuariosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormusuariosListVer">&nbsp; Ver lista de usuarios</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormusuariosRegEdit" name="permisoFormusuariosRegEdit" #permisoFormusuariosRegEdit="ngModel" [(ngModel)]="permisos.usuariosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormusuariosRegEdit">&nbsp; Editar detalle de usuarios</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormusuariosRegVer" name="permisoFormusuariosRegVer" #permisoFormusuariosRegVer="ngModel" [(ngModel)]="permisos.usuariosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormusuariosRegVer">&nbsp; Ver detalle de usuarios</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormUsuariosDownload" name="permisoFormUsuariosDownload" #permisoFormUsuariosDownload="ngModel" [(ngModel)]="permisos.usuariosDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormUsuariosDownload">&nbsp; Descargar usuarios en XLSX</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormEmpresasListEdit" name="permisoFormEmpresasListEdit" #permisoFormEmpresasListEdit="ngModel" [(ngModel)]="permisos.empresasListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormEmpresasListEdit">&nbsp; Editar lista de empresas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormempresasListVer" name="permisoFormempresasListVer" #permisoFormempresasListVer="ngModel" [(ngModel)]="permisos.empresasListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormempresasListVer">&nbsp; Ver lista de empresas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormempresasRegEdit" name="permisoFormempresasRegEdit" #permisoFormempresasRegEdit="ngModel" [(ngModel)]="permisos.empresasRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormempresasRegEdit">&nbsp; Editar detalle de empresas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormempresasRegVer" name="permisoFormempresasRegVer" #permisoFormempresasRegVer="ngModel" [(ngModel)]="permisos.empresasRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormempresasRegVer">&nbsp; Ver detalle de empresas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormEmpresasDownload" name="permisoFormEmpresasDownload" #permisoFormEmpresasDownload="ngModel" [(ngModel)]="permisos.empresasDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormEmpresasDownload">&nbsp; Descargar empresas en XLSX</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormcontactosListEdit" name="permisoFormcontactosListEdit" #permisoFormcontactosListEdit="ngModel" [(ngModel)]="permisos.contactosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormcontactosListEdit">&nbsp; Editar lista de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormcontactosListVer" name="permisoFormcontactosListVer" #permisoFormcontactosListVer="ngModel" [(ngModel)]="permisos.contactosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormcontactosListVer">&nbsp; Ver lista de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormcontactosRegEdit" name="permisoFormcontactosRegEdit" #permisoFormcontactosRegEdit="ngModel" [(ngModel)]="permisos.contactosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormcontactosRegEdit">&nbsp; Editar detalle de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormcontactosRegVer" name="permisoFormcontactosRegVer" #permisoFormcontactosRegVer="ngModel" [(ngModel)]="permisos.contactosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormcontactosRegVer">&nbsp; Ver detalle de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormContactosDownload" name="permisoFormContactosDownload" #permisoFormContactosDownload="ngModel" [(ngModel)]="permisos.contactosDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormContactosDownload">&nbsp; Descargar contactos en XLSX</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormoportunidadesListEdit" name="permisoFormoportunidadesListEdit" #permisoFormoportunidadesListEdit="ngModel" [(ngModel)]="permisos.oportunidadesListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormoportunidadesListEdit">&nbsp; Editar lista de oportunidades</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormoportunidadesListVer" name="permisoFormoportunidadesListVer" #permisoFormoportunidadesListVer="ngModel" [(ngModel)]="permisos.oportunidadesListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormoportunidadesListVer">&nbsp; Ver lista de oportunidades</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormoportunidadesRegEdit" name="permisoFormoportunidadesRegEdit" #permisoFormoportunidadesRegEdit="ngModel" [(ngModel)]="permisos.oportunidadesRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormoportunidadesRegEdit">&nbsp; Editar detalle de oportunidades</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormoportunidadesRegVer" name="permisoFormoportunidadesRegVer" #permisoFormoportunidadesRegVer="ngModel" [(ngModel)]="permisos.oportunidadesRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormoportunidadesRegVer">&nbsp; Ver detalle de oportunidades</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormOportunidadesDownload" name="permisoFormOportunidadesDownload" #permisoFormOportunidadesDownload="ngModel" [(ngModel)]="permisos.oportunidadesDownload" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormOportunidadesDownload">&nbsp; Descargar oportunidades en XLSX</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormarchivosListEdit" name="permisoFormarchivosListEdit" #permisoFormarchivosListEdit="ngModel" [(ngModel)]="permisos.archivosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormarchivosListEdit">&nbsp; Editar lista de archivos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormarchivosListVer" name="permisoFormarchivosListVer" #permisoFormarchivosListVer="ngModel" [(ngModel)]="permisos.archivosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormarchivosListVer">&nbsp; Ver lista de archivos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormarchivosRegEdit" name="permisoFormarchivosRegEdit" #permisoFormarchivosRegEdit="ngModel" [(ngModel)]="permisos.archivosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormarchivosRegEdit">&nbsp; Editar detalle de archivos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormarchivosRegVer" name="permisoFormarchivosRegVer" #permisoFormarchivosRegVer="ngModel" [(ngModel)]="permisos.archivosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormarchivosRegVer">&nbsp; Ver detalle de archivos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormrepositoriosListEdit" name="permisoFormrepositoriosListEdit" #permisoFormrepositoriosListEdit="ngModel" [(ngModel)]="permisos.repositoriosListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormrepositoriosListEdit">&nbsp; Editar lista de repositorio editorial</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormrepositoriosListVer" name="permisoFormrepositoriosListVer" #permisoFormrepositoriosListVer="ngModel" [(ngModel)]="permisos.repositoriosListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormrepositoriosListVer">&nbsp; Ver lista de repositorio editorial</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormrepositoriosRegEdit" name="permisoFormrepositoriosRegEdit" #permisoFormrepositoriosRegEdit="ngModel" [(ngModel)]="permisos.repositoriosRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormrepositoriosRegEdit">&nbsp; Editar detalle de repositorio editorial</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormrepositoriosRegVer" name="permisoFormrepositoriosRegVer" #permisoFormrepositoriosRegVer="ngModel" [(ngModel)]="permisos.repositoriosRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormrepositoriosRegVer">&nbsp; Ver detalle de repositorio editorial</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormActividadesListEdit" name="permisoFormActividadesListEdit" #permisoFormActividadesListEdit="ngModel" [(ngModel)]="permisos.actividadesListEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormActividadesListEdit">&nbsp; Editar lista de actividades de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormActividadesListVer" name="permisoFormActividadesListVer" #permisoFormActividadesListVer="ngModel" [(ngModel)]="permisos.actividadesListVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormActividadesListVer">&nbsp; Ver lista de actividades de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormActividadesRegEdit" name="permisoFormActividadesRegEdit" #permisoFormActividadesRegEdit="ngModel" [(ngModel)]="permisos.actividadesRegEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormActividadesRegEdit">&nbsp; Editar detalle de actividades de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormActividadesRegVer" name="permisoFormActividadesRegVer" #permisoFormActividadesRegVer="ngModel" [(ngModel)]="permisos.actividadesRegVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormActividadesRegVer">&nbsp; Ver detalle de actividades de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormCatOrigenesRegEdit" name="permisoFormCatOrigenesRegEdit" #permisoFormCatOrigenesRegEdit="ngModel" [(ngModel)]="permisos.catOrigenesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormCatOrigenesRegEdit">&nbsp; Editar detalle de origenes de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormCatOrigenesVer" name="permisoFormCatOrigenesVer" #permisoFormCatOrigenesVer="ngModel" [(ngModel)]="permisos.catOrigenesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormCatOrigenesVer">&nbsp; Ver detalle de origenes de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormPaisesRegEdit" name="permisoFormPaisesRegEdit" #permisoFormPaisesRegEdit="ngModel" [(ngModel)]="permisos.catPaisesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormPaisesRegEdit">&nbsp; Editar detalle de países</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormPaisesVer" name="permisoFormPaisesVer" #permisoFormPaisesVer="ngModel" [(ngModel)]="permisos.catPaisesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormPaisesVer">&nbsp; Ver detalle de países</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormPlantillasRegEdit" name="permisoFormPlantillasRegEdit" #permisoFormPlantillasRegEdit="ngModel" [(ngModel)]="permisos.plantillasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormPlantillasRegEdit">&nbsp; Editar detalle de plantillas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormPlantillasVer" name="permisoFormPlantillasVer" #permisoFormPlantillasVer="ngModel" [(ngModel)]="permisos.plantillasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormPlantillasVer">&nbsp; Ver detalle de plantillas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormCampanasRegEdit" name="permisoFormCampanasRegEdit" #permisoFormCampanasRegEdit="ngModel" [(ngModel)]="permisos.campannasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormCampanasRegEdit">&nbsp; Editar detalle de campañas de marketing</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormCampanasVer" name="permisoFormCampanasVer" #permisoFormCampanasVer="ngModel" [(ngModel)]="permisos.campannasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormCampanasVer">&nbsp; Ver detalle de campañas de marketing</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormCampanasEnviarVer" name="permisoFormCampanasEnviarVer" #permisoFormCampanasEnviarVer="ngModel" [(ngModel)]="permisos.envioPlantillas" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormCampanasEnviarVer">&nbsp; Enviar campañas de marketing</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormTicketsRegEdit" name="permisoFormTicketsRegEdit" #permisoFormTicketsRegEdit="ngModel" [(ngModel)]="permisos.ticketsEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormTicketsRegEdit">&nbsp; Editar detalle de tickets</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormTicketsVer" name="permisoFormTicketsVer" #permisoFormTicketsVer="ngModel" [(ngModel)]="permisos.ticketsVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormTicketsVer">&nbsp; Ver detalle de tickets</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormDepartamentosRegEdit" name="permisoFormDepartamentosRegEdit" #permisoFormDepartamentosRegEdit="ngModel" [(ngModel)]="permisos.catDepartamentosEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormDepartamentosRegEdit">&nbsp; Editar detalle del catálogo departamentos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormDepartamentosVer" name="permisoFormDepartamentosVer" #permisoFormDepartamentosVer="ngModel" [(ngModel)]="permisos.catDepartamentosVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormDepartamentosVer">&nbsp; Ver detalle del catálogo departamentos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormOrigenesContactoRegEdit" name="permisoFormOrigenesContactoRegEdit" #permisoFormOrigenesContactoRegEdit="ngModel" [(ngModel)]="permisos.catOrigenes" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormOrigenesContactoRegEdit">&nbsp; Editar detalle del catálogo orígenes de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormOrigenesContactoVer" name="permisoFormOrigenesContactoVer" #permisoFormOrigenesContactoVer="ngModel" [(ngModel)]="permisos.catOrigenesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormOrigenesContactoVer">&nbsp; Ver detalle del catálogo orígenes de contactos</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormIndustriasRegEdit" name="permisoFormIndustriasRegEdit" #permisoFormIndustriasRegEdit="ngModel" [(ngModel)]="permisos.catIndustriasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormIndustriasRegEdit">&nbsp; Editar detalle del catálogo industrias</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormIndustriasVer" name="permisoFormIndustriasVer" #permisoFormIndustriasVer="ngModel" [(ngModel)]="permisos.catIndustriasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormIndustriasVer">&nbsp; Ver detalle del catálogo industrias</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormPaisesRegEdit" name="permisoFormPaisesRegEdit" #permisoFormPaisesRegEdit="ngModel" [(ngModel)]="permisos.catPaisesEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormPaisesRegEdit">&nbsp; Editar detalle del catálogo países</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormPaisesVer" name="permisoFormPaisesVer" #permisoFormPaisesVer="ngModel" [(ngModel)]="permisos.catPaisesVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormPaisesVer">&nbsp; Ver detalle del catálogo países</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <!-- <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormTiposEmpresasRegEdit" name="permisoFormTiposEmpresasRegEdit" #permisoFormTiposEmpresasRegEdit="ngModel" [(ngModel)]="permisos.catTipoEmpresasEdit" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormTiposEmpresasRegEdit">&nbsp; Editar detalle del catálogo países</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormTiposEmpresasVer" name="permisoFormTiposEmpresasVer" #permisoFormTiposEmpresasVer="ngModel" [(ngModel)]="permisos.catTipoEmpresasVer" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormTiposEmpresasVer">&nbsp; Ver detalle del catálogo tipos de empresas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li> -->
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormRptKpisVendedoresVer" name="permisoFormRptKpisVendedoresVer" #permisoFormRptKpisVendedoresVer="ngModel" [(ngModel)]="permisos.rptKpiVendedores" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormRptKpisVendedoresVer">&nbsp; Ver reporte de KPIs de vendedores</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormRptSepomexVer" name="permisoFormRptSepomexVer" #permisoFormRptSepomexVer="ngModel" [(ngModel)]="permisos.rptSepomex" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormRptSepomexVer">&nbsp; Ver reporte de SEPOMEX</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormRptCumpleanios" name="permisoFormRptCumpleanios" #permisoFormRptCumpleanios="ngModel" [(ngModel)]="permisos.rptCumpleannos" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormRptCumpleanios">&nbsp; Ver reporte de cumpleaños</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormRptOportunidadesXEtapas" name="permisoFormRptOportunidadesXEtapas" #permisoFormRptOportunidadesXEtapas="ngModel" [(ngModel)]="permisos.rptOportunidadEtapas" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormRptOportunidadesXEtapas">&nbsp; Ver reporte de oportunidades por etapas</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormRptOportunidadesXPropietario" name="permisoFormRptOportunidadesXPropietario" #permisoFormRptOportunidadesXPropietario="ngModel" [(ngModel)]="permisos.rptOportunidadPropietarios" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormRptOportunidadesXPropietario">&nbsp; Ver reporte de oportunidades por propietarios</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="permisoFormRptAutoActualizacion" name="permisoFormRptAutoActualizacion" #permisoFormRptAutoActualizacion="ngModel" [(ngModel)]="permisos.rptAutoActualizacion" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodo()">
                                  <label class="custom-control-label" for="permisoFormRptAutoActualizacion">&nbsp; Ver reporte de auto-actualización</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab>
              <ng-template ngbTabTitle><span>Notificaciones</span></ng-template>
              <ng-template ngbTabContent>
                <ul class="todo-list-wrapper list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-2">
                          <div class="custom-checkbox custom-control">
                            <input type="checkbox" id="allSuscripcionesForm" name="allSuscripcionesForm" #allSuscripcionesForm="ngModel" [(ngModel)]="todoSuscripciones" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="seleccionarTodoSuscripciones()">
                            <label class="custom-control-label" for="allSuscripcionesForm">&nbsp; Seleccionar todo</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <hr class="p-0 m-0">
                <div class="scroll-area-lg">
                  <div class="scrollbar-container">
                    <perfect-scrollbar [autoPropagation]="true">
                      <ul class="todo-list-wrapper list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let item of suscripciones">
                          <div class="todo-indicator bg-warning"></div>
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="suscripcionForm{{item.nombre}}" name="suscripcionForm{{item.nombre}}" [(ngModel)]="item.checked" class="custom-control-input" [disabled]="(tipoSelected.id === 1 || tipoSelected.id === 2)" (change)="validarTodoSuscripciones()">
                                  <label class="custom-control-label" for="suscripcionForm{{item.nombre}}">&nbsp; {{item.nombre}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
        <div class="card-footer">
          <button type="button" class="mr-2 btn btn-link btn-sm mr-auto" (click)="cancelar()">Cancel</button>
          <button class="btn btn-success btn-lg flex-first" *ngIf="tipoSelected.id!==1 && tipoSelected.id!==2" [disabled]="formPermisos.invalid">
            <span *ngIf="!tipoSelected.id">Registrar</span>
            <span *ngIf="tipoSelected.id">Actualizar</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
