import { trigger, transition, animate, style, query } from '@angular/animations';

// Routable animations
export const pagesAnimation = trigger('pagesAnimation', [
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        opacity: 0,
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
      }),
    ], {optional: true} ),
    query(':enter', [
      animate('600ms ease-in', style({opacity: 1})),
    ], {optional: true} ),
    query(':leave', [
      animate('600ms ease-in-out', style({opacity: 0})),
    ], {optional: true})
  ]),
]);
