import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute } from '@angular/router';
import { Identity, UserType, User, DataObjUser } from 'src/app/models/user';
import { GLOBAL } from 'src/app/models/global';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { Subscription } from 'rxjs';
import { UsuarioState, AuthState, TiposState } from 'src/app/store/reducers';
import { ToastrService } from 'ngx-toastr';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Catalogo } from '../../../../models/catalogo';

@Component({
  selector: 'app-edit',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.sass']
})
export class EditUserComponent implements OnInit, OnDestroy {
  public title: string;
  public url: string;
  public identity: Identity;
  public user: User = new User(null);
  public status: any;
  public tipos: UserType[];
  public cargando = false;
  public subcriptionIdentity: Subscription = new Subscription();
  public subcriptionUsuario: Subscription = new Subscription();
  public subcriptionCatalogo: Subscription = new Subscription();
  public subcriptionDepartamento: Subscription = new Subscription();
  heading = 'Detalle de usuario';
  subheading = 'Información de usuario registrado en el sistema';
  icon = 'pe-7s-user icon-gradient bg-dark';
  @ViewChild('usuarioUser') usuarioText: ElementRef;
  @ViewChild('nombreUser') nombreText: ElementRef;
  @ViewChild('apellidoPaternoUser') apellidoPaternoText: ElementRef;
  @ViewChild('apellidoMaternoUser') apellidoMaternoText: ElementRef;
  @ViewChild('emailUser') emailText: ElementRef;
  @ViewChild('passwordUser') contrasenaText: ElementRef;
  @ViewChild('passwordConfirmUser') confirmContrasenaText: ElementRef;
  imageChangedEvent: any = '';
  files: any[] = [];
  showCropper = false;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  public departamentos: Catalogo[] = [];
  @ViewChild('registerUsuarioForm') registerForm: HTMLFormElement;
  public textDescripcionUser = '';

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
    this.url = GLOBAL.url;
    this.tipos = [];
    this.store.dispatch(new usuariosActions.LoadTypeUsers());
    this.store.dispatch(new usuariosActions.ConsultaDepartamentos());
  }

  ngOnInit() {
    this.title = 'Registrar usuario';
    this.subcriptionUsuario = this.store.select('usuario').subscribe(
      (state: UsuarioState) => {
        this.user = state.usuario;
        this.cargando = state.loading;
        this.changeTipo();
      }
    );
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subcriptionCatalogo = this.store.select('catalogos').subscribe(
      (state: TiposState) => {
        this.tipos = state.tipos;
      }
    );
    this.subcriptionDepartamento = this.store.select('consulta').subscribe(
      (state) => {
        this.departamentos = state.departamentos;
      }
    );
    this.route.params.subscribe(params => {
      const userId = params.id;
      if (userId > 0) {
        this.store.dispatch(new usuariosActions.CargarUsuario(userId));
      } else {
        this.user = new User(null);
      }
    });
    console.log('Inicializa el componente Register Usuarios...');
  }

  ngOnDestroy() {
    this.subcriptionUsuario.unsubscribe();
    this.subcriptionCatalogo.unsubscribe();
    this.subcriptionIdentity.unsubscribe();
    this.subcriptionDepartamento.unsubscribe();
  }

  onSubmit() {
    this.validaCampos().then(
      (resul) => {
        this.store.dispatch(new usuariosActions.EditarUsuario(this.user));
      }, (err) => {
        if (err !== '') {
          this.toastr.warning(err, 'Advertencia');
        }
      }
    );
  }

  validaCampos() {
    return new Promise((resolve, reject) => {
      this.user.usuario = this.user.usuario.toLocaleLowerCase().trim();
      if (!this.user.usuario) {
        reject('Capture nombre de usuario');
      } else {
        this.userService.existsUser(this.user.usuario).subscribe(
          (respuesta) => {
            if (respuesta.usuario && !this.user.id) {
              reject('El nombre usuario ya existe');
            } else if (!this.user.nombre) {
              reject('Capture nombre');
            } else if (!this.user.apellidoPaterno) {
              reject('Capture apellido paterno');
            } else {
              this.userService.verificateEmail(this.user.id, this.user.email).subscribe(
                (respuestaEmail) => {
                  if (respuestaEmail.email) {
                    reject('El correo electrónico ya existe');
                  } else if (!this.user.password && !this.user.id) {
                    reject('La contraseña no es valida');
                  } else if (this.user.password !== this.user.passwordConfirmar && !this.user.id) {
                    reject('Contraseña no coincide');
                  } else if (!this.user.tipo || this.user.tipo < 1) {
                    reject('Capture tipo de usuario');
                  } else if (!this.user.departamento) {
                    reject('Capture el departamento del usuario');
                  } else {
                    resolve(true);
                  }
                }, (error) => {
                  reject('');
                }
              );
            }
          }, (error) => {
            reject('');
          }
        );
      }
    });
  }

  usuarioLower() {
    this.user.usuario = this.user.usuario.toLocaleLowerCase();
  }

  fileChangeEvent(event: any): void {
    // this.files = event.target.files as Array<File>;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.user.imagen = event.base64;
    // console.log(event);
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady() {
    // console.log('Cropper ready');
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.imageCropper.canvasRotation = 1;
  }

  rotateRight() {
    this.imageCropper.canvasRotation = 3;
  }

  flipHorizontal() {
    this.imageCropper.transform.flipH = !this.imageCropper.transform.flipH;
  }

  flipVertical() {
    this.imageCropper.transform.flipV = !this.imageCropper.transform.flipV;
  }

  changeTipo() {
    if (this.user.tipo > 0) {
      const i = this.tipos.find( item => item.id === this.user.tipo);
      this.textDescripcionUser = i.descripcion;
    }
  }

}
