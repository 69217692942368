import { Component, OnInit, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Identity } from 'src/app/models/user';
import { Pagination } from 'src/app/models/pagination';
import { PlantillasService } from 'src/app/services/plantillas.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyScopePipe } from '../../../../pipes/any-scope.pipe';
import { ExcelService } from '../../../../services/excel.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { GLOBAL } from 'src/app/models/global';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.scss']
})
export class PlantillasComponent implements OnInit {
  heading = 'Plantillas';
  subheading = 'Información de plantillas creadas';
  icon = 'fas fa-envelope-open-text icon-gradient bg-dark';
  public url: string;
  public identity: Identity = new Identity(null);
  public plantillas:any[] = [];
  public subscribeAuth: Subscription = new Subscription();
  public paginacion: Pagination = new Pagination(null);
  public listSearch: string[] = [];
  public filterActive = false;
  public orderType: any[] = [];


  // @ts-ignore

  closeResult: string;

  constructor( private config: NgbModalConfig,
               private modalService: NgbModal,
               private toastr: ToastrService,
               private plantillasservices: PlantillasService,
               private excelService: ExcelService,
               
  ) {this.url = GLOBAL.url;}

  open(content) {
    this.modalService.open(content,  { windowClass:'correos-lighbox', backdropClass: 'dark-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any) {
    throw new Error('Method not implemented.');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }
  EditarPlantilla(plantilla){
    console.log('Editar: ' + plantilla.id)
  }

  EliminarPlantilla(plantilla:any) {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      text: '¿Desea eliminar la campaña ' + plantilla.tema + '? Esta acción no se podrá deshacer',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.plantillasservices.eliminarPlantilla(plantilla.id).subscribe(
          (respuesta: any) => {
            this.toastr.success('Se ha eliminado la campaña: ' + plantilla.tema);
            this.plantillas = this.plantillas.filter(item => item.id !== plantilla.id);
          }, err => {
            console.log('Ocurrio un error al eliminar la campaña', err);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          'No se ha eliminado la plantilla',
          'error'
        )
      }
    });
  }
  printCSV() {
    console.log('Descargar la listas de plantillas? quizá un html con la plantilla.')
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  ngOnInit() {
    this.plantillasservices.obtenerPlantillas().subscribe(respuesta => {
      this.plantillas = respuesta.plantillas;
    });
  }


  
  

}
