import * as fromUI from '../actions/ui.actions';

export interface UiState {
  isLoading: boolean;
  stack: string[];
}

const initState: UiState = {
  isLoading: false,
  stack: []
};

export function uiReducer( state = initState, action: fromUI.loaderAcciones ): UiState {

  switch ( action.type ) {
    case fromUI.ACTIVAR_LOADING:
      return {
        ...state,
        isLoading: true,
        stack: [ ...state.stack, action.url]
      };
    case fromUI.DESACTIVAR_LOADING:
      let loading = state.isLoading;
      const lista = state.stack.filter( item => item !== action.url);
      if (!state.stack.length) {
        loading = false;
      }
      return {
        ...state,
        isLoading: loading,
        stack: [ ...lista ]
      };
    default:
      return state;
  }
}
