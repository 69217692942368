import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions/archivos-generales.actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Identity } from 'src/app/models/user';
import { ArchivosGeneralesService } from '../../services/archivos-generales.service';


@Injectable()
export class ArchivosGeneralesEffects {

  constructor(
    private actions$: Actions,
    public archivosService: ArchivosGeneralesService,
    protected local: StorageMap
  ) {}

  @Effect()
  cargarArchivos$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_ARCHIVOS_GENERALES ),
    switchMap( (action: any) => {
      return this.archivosService.cargarArchivos()
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarArchivosGeneralSuccess(respuesta.archivos);
          }),
          catchError( error => of(new usuarioActions.CargarArchivosGeneralFail(error))  )
        );
    })
  );

  @Effect()
  cargarArchivo$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_ARCHIVO_GRAL ),
    switchMap( (action: any) => {
      return this.archivosService.cargarArchivo(action.id)
        .pipe(
          map( (respuesta: Identity) => {
            return new usuarioActions.CargarArchivoGeneralSuccess(action.archivo);
          }),
          catchError( error => of(new usuarioActions.CargarArchivoGeneralFail(error))  )
        );
    })
  );

  @Effect()
  registrarArchivoGeneral$ = this.actions$.pipe(
    ofType(usuarioActions.REGISTRAR_ARCHIVO_GENERAL ),
    switchMap( (action: any) => {
      return this.archivosService.registrarArchivo(action.archivo)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.RegistrarArchivoGeneralSuccess(respond.archivo);
          }),
          catchError( error => of(new usuarioActions.RegistrarArchivoGeneralFail(error))  )
        );
    })
  );

  @Effect()
  eliminarArchivoGeneral$ = this.actions$.pipe(
    ofType(usuarioActions.ELIMINAR_ARCHIVO_GRAL ),
    switchMap( (action: any) => {
      return this.archivosService.eliminarArchivo(action.archivo)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.EliminarArchivoGeneralSuccess(respond.archivo);
          }),
          catchError( error => of(new usuarioActions.EliminarArchivoGeneralFail(error))  )
        );
    })
  );

  @Effect()
  cargarDirectoriosArchivoGeneral$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_DIRTECTORIOS_ARCHIVO_GENERAL ),
    switchMap( (action: any) => {
      return this.archivosService.cargarDirectorios()
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarDirectoriosArchivoGeneralSuccess(respuesta.directorio);
          }),
          catchError( error => of(new usuarioActions.CargarDirectoriosArchivoGeneralFail(error))  )
        );
    })
  );

  @Effect()
  registrarDirectorioArchivoGeneral$ = this.actions$.pipe(
    ofType(usuarioActions.REGISTRAR_DIRECTORIO_ARCHIVO_GENERAL ),
    switchMap( (action: any) => {
      return this.archivosService.registrarDirectorio(action.padre, action.directorio)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.RegistrarDirectorioArchivoGeneralSuccess(respond.directorio);
          }),
          catchError( error => of(new usuarioActions.RegistrarDirectorioArchivoGeneralFail(error))  )
        );
    })
  );

  @Effect()
  eliminarDirectorioArchivoGeneral$ = this.actions$.pipe(
    ofType(usuarioActions.ELIMINAR_DIRECTORIO_ARCHIVO_GENERAL ),
    switchMap( (action: any) => {
      return this.archivosService.eliminarDirectorio(action.directorio)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.EliminarDirectorioArchivoGeneralSuccess(respond.directorio);
          }),
          catchError( error => of(new usuarioActions.EliminarDirectorioArchivoGeneralFail(error))  )
        );
    })
  );

}
