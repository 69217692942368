<li *ngFor="let item of lista" [attr.data-sectionvalue]="item.id" class="listree-item line-clamp" [title]="item.nombre">
  <div class="listree-submenu-heading" *ngIf="item.directorios.length" (click)="selectedItem(item.id)">
    <span *ngIf="item.id">{{item.nombre}}</span>
    <span *ngIf="!item.id"><i class="fa fa-home"></i> Home</span>
  </div>
  <ul class="listree-submenu-items" *ngIf="item.directorios.length">
    <app-list-tree [lista]="item.directorios" (seleccionado)="nuevoSeleccion($event)"></app-list-tree>
  </ul>
  <a *ngIf="!item.directorios.length && item.id" (click)="selectedItem(item.id)">
    {{item.nombre}}
  </a>
  <a *ngIf="!item.directorios.length && !item.id" (click)="selectedItem(item.id)">
    <i class="fa fa-home"></i> Home
  </a>
</li>

<!-- <li>
  <div class="listree-submenu-heading"><a (click)="chargeListFiles(0)"><i class="fa fa-home"></i></a></div>
  <ul class="listree-submenu-items">
    <app-list-tree [lista]="directorio.directorios" (seleccionado)="chargeListFiles($event)"></app-list-tree>
  </ul>
</li> -->



