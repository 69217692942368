import { Usuario } from 'src/app/models/usuario.model';
import * as fromUsuarios from '../actions';
import { Notificacion } from 'src/app/models/notificacion';

export interface NotificacionesState {
  notificaciones: Notificacion[];
  total: number;
  sinLeer: number;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const estadoInicial: NotificacionesState = {
  notificaciones: [],
  total: 0,
  sinLeer: 0,
  loaded: false,
  loading: false,
  error: null
};

export function notificacionesReducer( state = estadoInicial, action: fromUsuarios.notificacionesAcciones ): NotificacionesState {

  switch ( action.type ) {
    case fromUsuarios.CARGAR_NOTIFICACIONES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromUsuarios.CARGAR_NOTIFICACIONES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notificaciones: [...action.notificaciones],
        total: action.total,
        sinLeer: action.sinLeer
      };
    case fromUsuarios.CARGAR_NOTIFICACIONES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    default:
      return state;
  }

}

