<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row" *ngIf="identity.permisos">
  <div class="col-md-3" *ngIf="filterActive">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Filtrar contenido</div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="" class="control-label">Ordenar por</label>
            <select class="form-control custom-select" name="sortType" #sortType="ngModel" [(ngModel)]="paginacion.sortType">
              <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
            </select>
          </div>
          <div class="v-options-menu vsm-default">
            <div class="vsm-list">
              <div class="vsm-header">Opciones</div>
              <app-option-filter-collapse [titulo]="'Tipo negocio'" [lista]="filterTiposNegocios" (regresa)="changeTipoNegocio($event)"></app-option-filter-collapse>
              <app-option-filter-collapse [titulo]="'Etapas'" [lista]="filterEtapas" (regresa)="changeEtapas($event)"></app-option-filter-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
    <div class="main-card mb-3 card card-shadow-primary">
      <div class="card-header">
        <div class="card-header-title">Oportunidades</div>
        <div class="btn-actions-pane-right mr-4">
          ({{(oportunidades | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: filterEtapas, parametro: 'etapa' } | multiOpcionesSelected : { opciones: filterTiposNegocios, parametro: 'tipoNegocio' } ).length}} de {{(oportunidades).length}})
          <div class="btn-group ml-2">
            <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchOportunidades" id="searchOportunidades" #searchOportunidades="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                <button class="search-icon" (click)="searchClick()"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
            </div>
            <button type="button" *ngIf="identity.permisos.oportunidadesListEdit" [routerLink]="['/auth/oportunidades/register', 0]" [routerLinkActive]="" class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex mb-3 mt-3">
              Registrar nuevo
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
            <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
              <span>Filtrar</span>
              <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th class="text-center" *ngFor="let item of oportunidadesTotal" style="min-width: 220px !important;">{{item.etapa.nombre}}
                  <!-- <ngb-progressbar type="success" [value]="25"></ngb-progressbar> -->
                  <!-- <ngb-progressbar [value]="100" height="8px" style="background-color: #794c8a;" ></ngb-progressbar> -->
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="widget-progress-wrapper col-8">
                      <div class="progress-bar-sm progress">
                        <div class="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" [style.background-color]="item.color" ></div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr cdkDropListGroup>
                <td *ngFor="let item of oportunidadesTotal" cdkDropList [cdkDropListData]="item.oportunidad" (cdkDropListDropped)="drop($event, item.etapa.id)" >
                  <div class="card-columns row m-1" *ngFor="let list of item.oportunidad | anyScope: paginacion.textSearch | multiOpcionesSelected : { opciones: filterEtapas, parametro: 'etapa' } | multiOpcionesSelected : { opciones: filterTiposNegocios, parametro: 'tipoNegocio' }" cdkDrag (dblclick)="editarOportunidad(list)">
                    <div class="card" *ngIf="list.mostrar">
                      <div class="card-body">
                        <h5 class="card-title"><a [routerLink]="['/auth/oportunidades/profile', list.id]" class="text-dark"> {{list.nombre}} </a></h5>
                        <p class="card-text">Importe: {{list.importe | currency:'MXN':'symbol' }}</p>
                        <p class="card-text">Fecha estimada de cierre: {{list.fecha | amLocale: 'es' | date }}</p>
                        <p class="card-text">
                          <a [routerLink]="['/auth/empresas/profile', list.empresa]">
                            <img *ngIf="list.empresa > 0" width="30" class="rounded-circle" [src]="url + '/empresas/perfiles/' + (list.empresa ? list.empresa : 0) + '/imagenes/0?v=' + version" alt="Imagen de la empresa">
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-center" *ngFor="let item of oportunidadesTotal"><b>Total: {{item.total | currency:'MXN':'symbol'}}</b></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="mt-2" *ngIf="identity.permisos.oportunidadesListVer">
        <div class="row p-2">
          <div class="col-sm-12">
            <div class="text-right">
              <button type="button" *ngIf="identity.permisos.oportunidadesDownload" (click)="printCSV()" class="btn btn-primary mb-2">
                <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="table-responsive">
	<table class="table table-hover table-bordered">
		<thead>
			<tr>
				<th *ngFor="let item of oportunidadesTotal" style="min-width: 220px !important;">{{item.etapa.nombre}}</th>
			</tr>
		</thead>
		<tbody>
			<tr cdkDropListGroup>
				<td *ngFor="let item of oportunidadesTotal" cdkDropList [cdkDropListData]="item.oportunidad" (cdkDropListDropped)="drop($event)">
					<div class="card-columns row m-1" *ngFor="let list of item.oportunidad" cdkDrag>
						<div class="card">
							<div class="card-body">
								<h5 class="card-title">{{list.nombre}}</h5>
								<p class="card-text">Cantidad: ${{list.importe }}</p>
								<p class="card-text">Fecha de cierre {{list.fecha | amLocale: 'es' | date }}</p>
								<p class="card-text"><img width="30" class="rounded-circle" [src]="url + '/empresas/perfiles/' + list.empresa + '/imagenes?v=' + version" alt="Imagen de la empresa"></p>
							</div>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
		<tfoot>
			<tr>
				<td class="text-center" *ngFor="let item of oportunidadesTotal"><b>Total: ${{item.total}}</b></td>
			</tr>
		</tfoot>
	</table>
</div> -->
