import {Component, OnInit, OnDestroy} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {ConfigActions} from '../../themeoptions/store/config.actions';
import {ThemeOptions} from '../../theme-options';
import {animate, query, style, transition, trigger} from '@angular/animations';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { appsAnimation } from 'src/app/animations/apps.animation';

@Component({
  selector: 'app-apps-layout',
  templateUrl: './apps-layout.component.html',
  animations: [ appsAnimation ]
})

export class AppsLayoutComponent implements OnInit, OnDestroy {

  @select('config') public config$: Observable<any>;
  cargando: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    private store: Store<AppState>
  ) {
    this.cargando = false;
  }

  ngOnInit() {
    this.subscription = this.store.select('ui').subscribe(
      (state) => {
        this.cargando = state.isLoading;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}



