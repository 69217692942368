import { trigger, transition, animate, style, query } from '@angular/animations';

// Routable animations
export const appsAnimation = trigger('appsAnimation', [
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        opacity: 0,
        display: 'flex',
        flex: '1',
        transform: 'translateY(-20px)',
        flexDirection: 'column'

      }),
    ], {optional: true} ),
    query(':enter', [
      animate('600ms ease-in', style({opacity: 1, transform: 'translateY(0)'})),
    ], {optional: true} ),
    query(':leave', [
      animate('600ms ease-in-out', style({opacity: 0, transform: 'translateY(-20px)'})),
    ], {optional: true})
  ]),
]);
