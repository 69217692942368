import {Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {
  public year = 2020;
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  constructor(
    public store: Store<AppState>
  ) {
    const date = new Date();
    this.year = date.getFullYear();
  }

  ngOnInit() {
  }

  onSubmit( data: any) {
    // this.auth.resetPassword(data.email);
  }

}
