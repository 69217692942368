import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'img',
  // tslint:disable-next-line:no-host-metadata-property
  host: { '(error)': 'updateUrl()', '[src]': 'src' }
})
export class ImagePreloadDirective {

  @Input() src: string;

  constructor() { }

  updateUrl() {
    this.src = 'assets/images/image-not-found.png';
  }

}
