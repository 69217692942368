import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { PermisoEdit, User, Identity } from 'src/app/models/user';


@Injectable()
export class CatalogoEffects {

  constructor(
    private actions$: Actions,
    public usuarioService: UsuariosService,
    protected local: StorageMap
  ) {}

  @Effect()
  tipoUsuariosCatalogo$ = this.actions$.pipe(
    ofType(usuarioActions.LOAD_TYPE_USERS ),
    switchMap( (action: any) => {
      return this.usuarioService.getTypeUser()
        .pipe(
          map( (user: any) => {
            return new usuarioActions.LoadTypeUsersSuccess(user.tipos);
          }),
          catchError( error => of(new usuarioActions.LoadTypeUsersFail(error))  )
        );
    })
  );

  @Effect()
  tipoPermisosCatalogo$ = this.actions$.pipe(
    ofType(usuarioActions.LOAD_PERMISOS ),
    switchMap( (action: any) => {
      return this.usuarioService.getTypePermsiosUser(action.tipo.id)
        .pipe(
          map( (user: Identity) => {
            return new usuarioActions.LoadPermisosSuccess(action.tipo, user.permisos);
          }),
          catchError( error => of(new usuarioActions.LoadPermisosFail(error))  )
        );
    })
  );

  @Effect()
  saveTipoPermisosCatalogo$ = this.actions$.pipe(
    ofType(usuarioActions.SAVE_PERMISOS ),
    switchMap( (action: any) => {
      return this.usuarioService.saveTypePermsiosUser(action.tipo, action.permisos, action.suscripciones)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.SavePermisosSuccess(respond.tipo, action.permisos, action.suscripciones);
          }),
          catchError( error => of(new usuarioActions.SavePermisosFail(error))  )
        );
    })
  );

  @Effect()
  getSubscriptionsCatalogo$ = this.actions$.pipe(
    ofType(usuarioActions.LOAD_SUSCRIPCIONES ),
    switchMap( (action: any) => {
      return this.usuarioService.getSubscriptionsUser(action.tipo.id)
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.LoadSuscripcionesSuccess(action.tipo, respuesta.suscripciones);
          }),
          catchError( error => of(new usuarioActions.LoadSuscripcionesFail(error))  )
        );
    })
  );

}
