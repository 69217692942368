import { Pipe, PipeTransform } from '@angular/core';
import { ArchivoGeneral } from '../models/archivo-general';

@Pipe({
  name: 'directorioActual'
})
export class DirectorioActualPipe implements PipeTransform {

  transform(value: ArchivoGeneral[], args: number) {
    if (!value || !value.length ) {
      return [];
    }
    args = !args ? 0 : args;
    return value.filter( itm => itm.directorio === args);
  }

}
