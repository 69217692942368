<div class="bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto  col-md-6">
      <div class="app-logo-long mx-auto mb-3"></div>
      <form #f="ngForm" #f="ngForm" (ngSubmit)="onSubmit()">
        <div class="modal-dialog w-100">
          <div class="modal-content">
            <div class="modal-header">
              <div class="h5 modal-title">
                ¿Quieres recuperar tu cuenta?
                <h6 class="mt-1 mb-0 opacity-8">
                  <span>Usa el siguiente formulario para recuperarlo.</span>
                </h6>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <fieldset class="form-group" id="__BVID__132">
                    <div tabindex="-1" role="group">
                      <label for="emailRestore">Email</label>
                      <input type="email" appLowerChart id="emailRestore" name="emailRestore" #emailRestore="ngModel" [(ngModel)]="email" placeholder="Escribe tu correo..." title="Ingresa el correo con el que tienes tu usuario" class="form-control" minlength="5" maxlength="100" required>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="divider"></div>
              <h6 class="mb-0">
                <a [routerLink]="['/public/login']" class="text-primary">Inciar sesión con una cuenta existente</a>
              </h6>
            </div>
            <div class="modal-footer clearfix">
              <div class="float-right">
                <button type="submit" data-style="zoom-in" class="btn btn-primary btn-lg bg-night-sky" [disabled]="!f.valid" *ngIf="!loading">Recuperar contraseña <i class="fa fa-send-o"></i></button>
                <button type="submit" data-style="zoom-in" class="btn btn-primary btn-lg bg-night-sky" disabled *ngIf="loading">Enviando <span class="fa fa-spinner fa-pulse mr-1"></span></button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="text-center text-white opacity-8">
        Copyright © Jivabe Rating and Consulting {{year}}
      </div>
    </div>
  </div>
</div>
