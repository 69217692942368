import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Contacto } from 'src/app/models/contacto';
import { Empresa } from 'src/app/models/empresa';
import { CargaContacto } from 'src/app/models/carga-contactos';
import { CorreoContacto } from '../models/contacto';

@Injectable({
  providedIn: 'root'
})
export class ContactosService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  registraContacto(contacto: Contacto, empresa: Empresa): Observable<any> {
    const obj = {contacto, empresa};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/contactos', params, {headers});
  }

  modificaEstatusCorreo(correo:CorreoContacto): Observable<any> {
    const obj = {correo};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/contactos/estatusCorreo', params, {headers});
  }

  obtenerContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/contactos', {headers});
  }

  obtenerContactos2(opciones: any): Observable<any> {
    const obj = {opciones};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/contactos/get', params, {headers});
  }

  obtenerContacto(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/contactos/' + id, {headers});
  }

  existeContacto(id: number, email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/contactos/'+ id + '/existe/' + email, {headers});
  }

  eliminarContacto(contacto: Contacto): Observable<any> {
    const param = JSON.stringify({contacto});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/contactos/estatus', param, {headers});
  }
  
  obtenerUltimaActividadContacto(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/contactos/actividad/' + id, {headers});
  }

  registraCargaContactos(contactos: CargaContacto[]): Observable<any> {
    const params = JSON.stringify({contactos});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/contactos/carga', params, {headers});
  }

  actualizaContactosDuplicados(contacto: Contacto, duplicados: Contacto[]): Observable<any> {
    const param = JSON.stringify({contacto, duplicados});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/contactos/duplicados', param, {headers});
  }

  downloadExcelContactos(opciones: any): Observable<any> {
    const obj = {
      opciones,
      responseType: 'blob'
    };
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/contactos/excel', params, {headers});
  }

}
