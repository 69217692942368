import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EmpresaPermiFilter } from 'src/app/models/empresa';
import { Pagination } from 'src/app/models/pagination';
import { FilterType, Identity } from 'src/app/models/user';
import { AppState } from 'src/app/store/app.reducer';
import { Contacto } from 'src/app/models/contacto';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListaCampanasService } from 'src/app/services/lista-campanas.service';
import { ListaCampana } from 'src/app/models/lista-campana';
import { ConsultasService } from 'src/app/services/consultas.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';


export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM"
  },
  display: {
    dateInput: "DD/MM",
    monthYearLabel: "DD MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "DD MMMM"
  }
};

@Component({
  selector: 'app-modal-lista-campana',
  templateUrl: './modal-lista-campana.component.html',
  styleUrls: ['./modal-lista-campana.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ModalListaCampanaComponent implements OnInit, OnDestroy {
  public identity: Identity;
  private subscribeConsulta: Subscription = new Subscription();
  public estados: { nombre: string, checked: boolean, pais: number }[] = [];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive = false;
  public version = 1;
  public listSearch: string[] = [];
  public filterTipos: FilterType[] = [];
  public filterIndustrias: FilterType[] = [];
  public filterPropietarios: FilterType[] = [];
  public filterClasificaciones: FilterType[] = [];
  public filterPaises: FilterType[] = [];
  public filterEstatus: FilterType[] = [];
  public filterEtapas: FilterType[] = [];
  public filterClases: FilterType[] = [];
  public filterOrigenContacto: FilterType[] = [];
  public filterTipoContacto: FilterType[] = [];
  public filterEstados: FilterType[] = [];
  public filterTipoEmpresas: FilterType[] = [];
  public filterCatalogoSeguidad = [...EmpresaPermiFilter];
  public listaCampanas: ListaCampana = new ListaCampana();
  range = new FormGroup({
    iniciacumple: new FormControl(),
    terminacumple: new FormControl()
  });
  rangefundacion = new FormGroup({
    iniciafunda: new FormControl(),
    terminafunda: new FormControl()
  });
  @Input() idCampana = 0;

  heading = 'Detalles de los contactos';
  subheading = 'Contactos registrandos en el sistema';
  icon = 'pe-7s-id icon-gradient bg-dark';
  startDate = new Date(1990, 0, 1);
  date2 = new FormControl(moment([2017, 0, 1]));
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  private subscribeAuth: Subscription = new Subscription();
  private subscribeContacto: Subscription = new Subscription();
  public title: string;
  public contactos: Contacto[] = [];
  public cargando = false;

  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };

  public dropdownSettingsEstados: IDropdownSettings = {
    singleSelection: false,
    idField: 'nombre',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };

  public dropdownSettingsDescrip: IDropdownSettings = {
    singleSelection: false,
    idField: 'descripcion',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };

  constructor(
    private store: Store<AppState>,
    private listaCampanasService: ListaCampanasService,
    private consultaService: ConsultasService,
    private modal: NgbActiveModal
  ) {
    this.identity = new Identity(null);
    this.loading = false;
    this.contactos = [];
    this.paginacion.sortType = '-id';
    this.paginacion.sortType = '-id';
    this.identity = new Identity(null);
  }

  ngOnInit() {
    this.title = 'Contacto';
    console.log('Inicializa el componente Contacto ...');
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = { ...state.identity };
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeContacto = this.store.select('contacto').subscribe(
      (state) => {
        this.contactos = [...state.contactos];
      }
    );

    this.inicializa();
  }

  limpiarFechaCumple() {
    this.listaCampanas.fecCumpleInicia = null;
    setTimeout(() => {
      this.listaCampanas.fecCumpleTermina = null;
    }, 10);

  }

  limpiarFechaFundacion() {
    this.listaCampanas.fecFundacionInicia = null;
    setTimeout(() => {
      this.listaCampanas.fecFundacionTermina = null;
    }, 10)
  }

  ngOnDestroy() {
    this.subscribeConsulta.unsubscribe();
    this.subscribeAuth.unsubscribe();
    this.subscribeContacto.unsubscribe();
  }

  onItemSelect(item: any) {
    this.consultaService.obtenerEstadosPais(item.id).subscribe(
      respuesta => {
        this.estados = [...this.estados, ...respuesta.estados];
      }, err => {

      }
    );
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

  todosFiltros() {
    return Promise.all([
      new Promise((resolve, reject) => {
        this.listaCampanas.catalogoSeguridad = this.listaCampanas.catalogoSeguridad.map(item => {
          this.filterCatalogoSeguidad.forEach(element => {
            if (element.descripcion == item.descripcion) {
              item.nombre = element.nombre;
            }
          });
          return item;
        });
        resolve(true);
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerClasificaciones().subscribe((respuesta) => {
          this.filterClasificaciones = respuesta.clasificaciones.filter(item => item.status);
          this.listaCampanas.clasificaciones = this.listaCampanas.clasificaciones.map(item => {
            this.filterClasificaciones.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerPaises().subscribe((respuesta) => {
          respuesta.paises.unshift({ id: 0, nombre: 'Sin especificar', checked: false, status: true });
          this.filterPaises = respuesta.paises.filter(item => item.status);
          this.listaCampanas.paises = this.listaCampanas.paises.map(item => {
            this.filterPaises.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
                this.obtenerEstadosPais(element.id);
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerOrigenContactos().subscribe((respuesta) => {
          this.filterOrigenContacto = respuesta.origen.filter(item => item.status);
          this.listaCampanas.origenContactos = this.listaCampanas.origenContactos.map(item => {
            this.filterOrigenContacto.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerIndustrias().subscribe((respuesta) => {
          respuesta.industrias.unshift({ id: 0, nombre: 'Sin especificar', checked: false, status: true });
          this.filterIndustrias = respuesta.industrias.filter(item => item.status);
          this.listaCampanas.industrias = this.listaCampanas.industrias.map(item => {
            this.filterIndustrias.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerUsuarios().subscribe((respuesta) => {
          this.filterPropietarios = respuesta.usuarios.map((item: any) => {
            return { id: item.id, nombre: item.descripcion, checked: false, status: true }
          });
          this.listaCampanas.propietarios = this.listaCampanas.propietarios.map(item => {
            this.filterPropietarios.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerEtapas().subscribe((respuesta) => {
          this.filterEtapas = respuesta.etapas.filter(item => item.status);
          this.listaCampanas.etapas = this.listaCampanas.etapas.map(item => {
            this.filterEtapas.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerClasesEmpresas().subscribe((respuesta) => {
          this.filterClases = respuesta.clases.filter(item => item.status);
          this.listaCampanas.clases = this.listaCampanas.clases.map(item => {
            this.filterClases.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerEstadosContactos().subscribe((respuesta) => {
          this.filterEstatus = respuesta.estados.filter(item => item.status);
          this.listaCampanas.estatus = this.listaCampanas.estatus.map(item => {
            this.filterEstatus.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerTiposEmpresa().subscribe((respuesta) => {
          respuesta.tipos.unshift({ id: 0, nombre: 'Sin especificar', checked: false, status: true });
          this.filterTipoEmpresas = respuesta.tipos.filter(item => item.status);
          this.listaCampanas.tiposEmpresas = this.listaCampanas.tiposEmpresas.map(item => {
            this.filterTipoEmpresas.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
      new Promise((resolve, reject) => {
        this.consultaService.obtenerTiposEmpresa().subscribe((respuesta) => {
          respuesta.tipos.unshift({ id: 0, nombre: 'Sin especificar', checked: false, status: true });
          this.filterTipoEmpresas = respuesta.tipos.filter(item => item.status);
          this.listaCampanas.tiposEmpresas = this.listaCampanas.tiposEmpresas.map(item => {
            this.filterTipoEmpresas.forEach(element => {
              if (element.id == item.id) {
                item.nombre = element.nombre;
              }
            });
            return item;
          });
          resolve(true);
        }, err => resolve(false));
      }),
    ]);
  }

  inicializa() {
    this.cargando = true;
    if (this.idCampana) {
      this.listaCampanasService.obtenerLista(this.idCampana).subscribe(
        (respuesta) => {
          if (respuesta && respuesta.listaCampana && respuesta.listaCampana.id) {
            this.listaCampanas = respuesta.listaCampana;
          } else {
            this.listaCampanas.campana = this.idCampana;
          }
          this.todosFiltros().then(([var1, var2, var3]) => {
            this.cargando = false;
          }).catch(() => {
            this.cargando = false;
          });
        });
    } else {
      this.todosFiltros().then(([var1, var2, var3]) => {
        this.cargando = false;
      }).catch(() => {
        this.cargando = false;
      });
    }
  }

  guardar() {
    this.loading = true;
    this.listaCampanasService.guardarLista(this.listaCampanas).subscribe(
      (respuesta) => {
        // this.modalService.close();
        this.loading = false;
        this.cerrar();
      }, err => {
        this.loading = false;
      }
    );
  }

  cerrar() {
    this.modal.close();
  }

  dissmiss() {
    this.modal.dismiss();
  }

  obtenerEstadosPais(idPais: number) {
    this.consultaService.obtenerEstadosPais(idPais).subscribe(
      respuesta => {
        this.estados = [...this.estados, ...respuesta.estados];
      }, err => {

      }
    );
  }

  quitarEstadosPais(itemPais: any) {
    this.estados = this.estados.filter(item => item.pais != itemPais.id);
    if (this.listaCampanas.paises.length <= 1) {
      this.estados = [];
      this.listaCampanas.estados = [];
    }
  }

}
