import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { Campana, NuevaPlantilla } from 'src/app/models/campana';
import { CampanasService } from 'src/app/services/campanas.service';
import { ListaCampanasService } from 'src/app/services/lista-campanas.service';
import { PlantillasService } from 'src/app/services/plantillas.service';
import Swal from 'sweetalert2';
import { GLOBAL } from '../../../../models/global';

@Component({
  selector: 'app-nuevacampana',
  templateUrl: './nuevacampana.component.html',
  styleUrls: ['./nuevacampana.component.scss']
})
export class NuevacampanaComponent implements OnInit {
  heading = 'WYSIWYG Editors';
  subheading = 'Need to add formatting to your forms? Use these Vue2 widgets.';
  icon = 'pe-7s-like icon-gradient bg-love-kiss';
  public campana: Campana = new Campana(null);
  public nuevaplantilla: any;
  public user_id: number;
  public app: number;
  public tema: string;
  public plantillas: any[] = [];
  public dns: string[] = [];
  public nombreValido = true;
  public loading = false;

  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '670px',
    minHeight: '5rem',
    placeholder: 'Ingresa texto aquí',
    translate: 'no',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  constructor(
    private campanaService: CampanasService,
    private toastr: ToastrService,
    private router: Router,
    private plantillasservices: PlantillasService,
    private serviceCampana: ListaCampanasService
  ) {
    this.dns = GLOBAL.emailsDns;
  }

  ngOnInit() {
    this.plantillasservices.obtenerPlantillas().subscribe(respuesta => {
      this.plantillas = respuesta.plantillas;
    });
  }

  guardar() {
    if (!this.campana.dns) {
      if (this.dns.length) {
        this.campana.dns = this.dns[0];
      } else {
        this.campana.dns = '';
      }
    }
    if (this.campana.guardarhtml == true) {
      this.nuevaplantilla = {
        tema: this.campana.tema,
        texto_plano: this.campana.texto_plano,
        html: this.campana.texto_html
      };
      this.plantillasservices.guardarPlantilla(this.nuevaplantilla).subscribe(
        (respuesta: any) => {
        }, err => {
          console.log('Ocurrio un error al registrar la plantilla', err);
        }
      );
    }
    this.loading = true;
    this.campanaService.guardarCampanas(this.campana).subscribe(
      (respuesta: any) => {
        this.toastr.success('Registro la campaña.');
        // Puedes cambiar de ruta o no hacer nada
        this.loading = false;
        this.router.navigate(['/auth/marketing/allcampanas']);
        // this.campana = respuesta.campana;
      }, err => {
        this.loading = false;
      }
    );
  }

  guardarysiguiente() {
    if (!this.campana.dns) {
      if (this.dns.length) {
        this.campana.dns = this.dns[0];
      } else {
        this.campana.dns = '';
      }
    }
    if (this.campana.guardarhtml == true) {
      this.nuevaplantilla = {
        tema: this.campana.tema,
        texto_plano: this.campana.texto_plano,
        texto_html: this.campana.texto_html
      };
      this.plantillasservices.guardarPlantilla(this.nuevaplantilla).subscribe(
        (respuesta: any) => {
        }, err => {
          // console.log('Ocurrio un error al registrar la plantilla', err);
        }
      );
    }
    this.loading = true;
    this.campanaService.guardarCampanas(this.campana).subscribe(
      (respuesta: any) => {
        this.toastr.success('Registro la campaña.');
        this.loading = false;
        this.router.navigate(['/auth/marketing/guardar-y-enviar-campana', respuesta.campana]);
      }, err => {
        this.loading = false;
      }
    );
  }

  existeCampana() {
    this.serviceCampana.existeCampna(0, this.campana.tema).subscribe(
      resultado => {
        if (resultado.campana) {
          this.toastr.warning('El nombre de la campaña ya existe');
          this.nombreValido = false;
        } else {
          this.nombreValido = true;
        }
      }, (err: any) => {
        this.nombreValido = false;
      }
    );
  }

}
