<app-page-title [heading]="title" [subheading]="'Información de actualizaciones en el sistema.'" [icon]="'pe-7s-news-paper icon-gradient bg-dark'"></app-page-title>
<div class="row">
  <div class="col-md-3">
    <div class="card">
      <div class="card-body p-0">
        <h5 class="menu-header-title text-capitalize ml-3 mt-3">
          Filtrar contenido
        </h5>
        <div class="p-3">
          <div class="form-group">
            <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize" (change)="inicializa()">
              <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
            </select>
          </div>
          <div class="form-group">
            <select class="custom-select block mb-2" name="sortTypeProspectos" [(ngModel)]="usuarioSearch" (change)="inicializa()">
              <option [ngValue]="0">Filtrar por usuario</option>
              <option *ngFor="let opt of usuariosFilter" [value]="opt.id">{{opt.descripcion}}</option>
            </select>
          </div>
        </div>
        <h5 class="menu-header-title text-capitalize ml-3">
          Filtrar notificaciones
        </h5>
        <div class="card-body p-0" id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="scroll-area-sm mb-2">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">
                <app-filter-type-notifications (respuesta)="showTiposNotificaciones($event)"></app-filter-type-notifications>
              </perfect-scrollbar>
            </section>
          </div>
        </div>
        <h5 class="menu-header-title text-capitalize ml-3">
          Filtrar tipos usuarios
        </h5>
        <div class="card-body p-0" id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="scroll-area-sm mb-2">
            <section class="scrollbar-container">
              <perfect-scrollbar [autoPropagation]="true">
                <app-user-type-filter (respuesta)="showTipos($event)"></app-user-type-filter>
              </perfect-scrollbar>
            </section>
          </div>
          <ngx-loading [show]="loading"></ngx-loading>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="main-card mb-3 card card-info">
      <div class="card-header with-border">
        <div class="card-header-title">Actividades recientes</div>
        <div class="btn-actions-pane-right">
          <div class="btn-group ml-2">
            <div class="search-wrapper" [class.active]="paginacion.isActive">
              <div class="input-holder">
                <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchNotificaciones" id="searchNotificaciones" #searchNotificaciones="ngModel" [(ngModel)]="paginacion.textSearch" (blur)="inicializa()">
                <button class="search-icon" (click)="paginacion.isActive = true"><span></span></button>
              </div>
              <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = '';"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-1" id="list-notification">
        <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div class="vertical-timeline-item vertical-timeline-element" *ngFor="let notification of notificaciones" >
            <div>
              <span class="vertical-timeline-element-icon bounce-in">
                <i class="badge badge-dot badge-dot-xl badge-success" *ngIf="notification.tipo === tipoNotificacion.SESION"></i>
                <i class="badge badge-dot badge-dot-xl badge-danger" *ngIf="notification.tipo === tipoNotificacion.USUARIOS"></i>
              </span>
              <div class="vertical-timeline-element-content bounce-in">
                <!-- <img class="image-circle image-list" [src]="url + '/usuarios/perfiles/' + notification.usuario + '/imagenes'" alt="User image"> -->
                <h4 class="timeline-title">{{notification.desUsuario}}</h4>
                <p>{{notification.descripcion}}</p>
                <span class="vertical-timeline-element-date">{{notification.fecha | amLocale: 'es' | amCalendar: { lastWeek: 'dddd [a las] LT'} }}</span>
              </div>
            </div>
          </div>
        </div>
        <ngx-loading [show]="loading"></ngx-loading>
      </div>
      <div class="mt-2 p-3">
        <ngb-pagination
          [collectionSize]="total" [(page)]="paginacion.page" [pageSize]="paginacion.pageSize" [maxSize]="5" [size]="'sm'" (pageChange)="inicializa()">
          <ng-template ngbPaginationFirst>First</ng-template>
          <ng-template ngbPaginationLast>Last</ng-template>
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
