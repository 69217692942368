<div class="app-inner-layout mb-3">
  <div class="app-inner-layout__wrapper" [ngClass]="{'open-mobile-menu' : toggleMobileSidebar}">
    <div class="app-inner-layout__content card">
      <div class="table-responsive">
        <div class="app-inner-layout__top-pane card-header">
          <div class="pane-left">
            <div class="card-header-title">Navegación de archivos</div>
          </div>
        </div>
        <div class="card-body mobile-app-menu-btn border-bottom m-0">
          <ul class="nav flex-column">
            <li class="nav-item mb-3">
              <div class="d-flex">
                Total de archivos: {{files.length}}
              </div>
            </li>
          </ul>
        </div>
        <div class="card-body" style="min-height: 85vh; max-height: 95vh; overflow: hidden; overflow-y: auto;">
          <div class="form-group">
            <div class="row mb-5">
              <div class="col-lg-3 col-md-4 col-sm-6 mb-3" *ngFor="let file of files; let i= index">
                <div class="card">
                  <div class="card-header p-0 img-loading">
                    <img class="card-img-top" [ngClass]="{'opacity-5': file.loading}" [src]="file.urlPath | safe: 'url'" [alt]="file.nombre">
                  </div>
                  <div class="card-body p-2">
                    {{file.name}}
                    <div class="widget-content p-0 pb-0 small">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-subheading text-uppercase">
                            {{file.type}} {{file.size | fileSize}}
                          </div>
                        </div>
                        <div class="widget-content-right" *ngIf="!file.loading">
                          <button type="button" (click)="deleteAttachment(file)" class="border-0 btn btn-pill btn-outline-danger">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-inner-layout__sidebar card" style="width: 320px; flex: 0 0 400px;">
      <div class="card-header">
        <div class="card-header-title">Nuevos archivos</div>
      </div>
      <div class="card-body">
        <ul class="nav flex-column">
          <li class="nav-item mb-3">
            <div class="d-flex">
              Total de archivos: {{files.length}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
