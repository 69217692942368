<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-lg-6">
		<form id="registerTicketForm" name="registerTicketForm" #registerTicketForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="card main-card mb-3">
				<div class="card-header">
					Captura la información del ticket
				</div>
				<div class="card-body">
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="asuntoTicket" class="control-label">Asunto</label>
							</div>
							<div class="col-sm-8">
								<input [disabled]="!identity.permisos.ticketsEdit" type="text" id="asuntoTicket" name="asuntoTicket" #asuntoTicket="ngModel" [(ngModel)]="ticket.asunto"
									class="form-control" maxlength="100" minlength="2" placeholder="" title="Capture el asunto del ticket." required />
								<span *ngIf="!asuntoTicket.valid && asuntoTicket.touched"> El nombre es obligatorio</span>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="departamentoTicket" class="control-label">Departamento</label>
							</div>
							<div class="col-sm-8">
								<ng-select [items]="departamentos" [disabled]="!identity.permisos.ticketsEdit" (blur)="usuariosDepartamento()"
                  bindLabel="nombre"
                  bindValue="id"
                  placeholder="Departamento a comunicar."
                  id="departamentoTicket"  name="departamentoTicket" #departamentoTicket= "ngModel" [(ngModel)]="ticket.departamento" required>
                </ng-select>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="usuariosReceptoresTicket" class="control-label">Usuarios receptores</label>
							</div>
							<div class="col-sm-8">
								<ng-multiselect-dropdown [disabled]="!identity.permisos.ticketsEdit"
								id="usuariosReceptoresTicket"
								name="usuariosReceptoresTicket"
								 #usuariosReceptoresTicket="ngModel"
								[placeholder]="'Usuarios'"
								[settings]="dropdownSettings"
								[data]="usuariosFilter"
								[(ngModel)]="ticket.usuariosReceptores"
								(onSelect)="onItemSelect($event)"
								(onSelectAll)="onSelectAll($event)">
							</ng-multiselect-dropdown>
							</div>
						</div>
					</fieldset>
					<fieldset class="form-group required">
						<div class="row">
							<div class="col-sm-4">
								<label for="descripcionTicket" class="control-label">Descripción</label>
							</div>
							<div class="col-sm-8">
								<textarea [disabled]="!identity.permisos.ticketsEdit" id="descripcionTicket" name="descripcionTicket" #descripcionTicket="ngModel" [(ngModel)]="ticket.descripcion"
									class="form-control" rows="3" placeholder="Escribe aquí..." title="Descripción de la actividad." required>
								</textarea>
								<!-- <span *ngIf="!descripcionTicket.valid && descripcionTicket.touched">La descripción es obligatoria</span> -->
							</div>
						</div>
					</fieldset>
				</div>
				<div class="grid-menu grid-menu-2col border-top">
					<div class="no-gutters row">
						<div class="col-sm-6" *ngIf="identity.permisos.ticketsVer">
							<a class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger" [routerLink]="['/auth/tickets']">
								<i class="lnr-arrow-left btn-icon-wrapper"></i> Cancelar
							</a>
						</div>
						<div class="col-sm-6" *ngIf="identity.permisos && identity.permisos.ticketsEdit">
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success" *ngIf="!loading" [disabled]="!registerTicketForm.valid || (ticket | json) === (ticketInicial | json) || !ticket.usuariosReceptores.length">
								<i class="lnr-download btn-icon-wrapper"></i> Registrar cambios
							</button>
							<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary" type="button" disabled *ngIf="loading">
								<span class="spinner-grow spinner-grow-lg" role="status" aria-hidden="true"></span>
								<br>
								Loading...
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
  </div>
  <div class="col-lg-6">
		<div class="main-card card">
			<div class="card-header">
				<div class="card-header-title">Registro de adjuntos</div>
			</div>
			<div class="card-body">
				<div class="form-group">
					<div class="row">
						<div class="col-sm-3 col-md-3 col-lg-3">
							<label for="nombreAnexo" class="control-label">Nombre</label>
						</div>
						<div class="col-sm-9 col-md-9 col-lg-9">
							<input type="text" class="form-control" id="nombreAnexo" name="nombreAnexo" #nombreAnexo="ngModel" [(ngModel)]="adjunto.name">
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-sm-3 col-md-3 col-lg-3">
							<label for="fileAnexo" class="control-label">Subir archivo</label>
						</div>
						<div class="col-sm-9 col-md-9 col-lg-9">
							<div class="box box-file">
								<input type="file" id="fileArchivo" name="fileAdjunto" #fileArchivo="ngModel" [(ngModel)]="textFile" class="inputfile avatar-file" (change)="fileChangeEvent($event)" accept="image/jpeg,image/gif,image/png,application/pdf" title="Seleccione un archivo tipo imagen o pdf como evidencia." data-max-size="2048"/>
								<label for="fileArchivo"><span class="fa fa-file"></span><span *ngIf="!textFile"> Elige un archivo&hellip;</span><span *ngIf="textFile"> {{textFile}}</span></label>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="ml-auto mr-2 mb-2">
						<button type="button" class="btn-icon btn btn-primary btn-flat" (click)="addFile()" [disabled]="!adjunto.name || !textFile || !identity.permisos.ticketsEdit">
							<span class="pr-2 align-middle opacity-7">
								<i class="far fa-save"></i>
							</span>
							Agregar archivo
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 attachment-mail">
						<span class="help-block">Información de los archivos adjuntos registrados.</span>
						<h2 class="empty-content" *ngIf="!ticket.adjuntos.length">Sin Archivos</h2>
						<ul class="mb-1" *ngFor="let adjunto of ticket.adjuntos">
							<li class="d-inline" >
								<div class="row">
									<h5 class="text-primary">	<i class="fa fa-paperclip"></i>  {{adjunto.name}}</h5>
								<div class="ml-auto mr-2">
									<button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-danger" (click)="deleteFile(adjunto)">
										<i class="pe-7s-trash btn-icon-wrapper"> </i>
										<span class="sr-only">Eliminar</span>
									</button> 
								</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>