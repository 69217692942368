import { Action } from '@ngrx/store';
import { KpisVendedores } from 'src/app/models/kpis-vendedores';
import { SeriesBar, FunnelGraphColors } from 'src/app/models/graph';
import { Actividad } from 'src/app/models/actividad';
import { Contacto } from 'src/app/models/contacto';
import { Empresa } from 'src/app/models/empresa';

export const CARGAR_ACTIVIDADES = '[KpisVendedores] Cargar actividades';
export const CARGAR_ACTIVIDADES_SUCCESS = '[KpisVendedores] Cargar actividades success';
export const CARGAR_ACTIVIDADES_FAIL = '[KpisVendedores] Cargar actividades fail';
export const CARGAR_ACTIVIDADES_PENDIENTES = '[KpisVendedores] Cargar actividades pendientes';
export const CARGAR_ACTIVIDADES_PENDIENTES_FAIL = '[KpisVendedores] Cargar actividades pendientes FAIL';
export const CARGAR_ACTIVIDADES_PENDIENTES_SUCCESS = '[KpisVendedores] Cargar actividades pendientes SUCCESS';
export const CARGAR_EMPRESAS_REGISTRADAS = '[KpisVendedores] Cargar empresas registradas';
export const CARGAR_EMPRESAS_REGISTRADAS_FAIL = '[KpisVendedores] Cargar empresas registradas FAIL';
export const CARGAR_EMPRESAS_REGISTRADAS_SUCCESS = '[KpisVendedores] Cargar empresas registradas SUCCESS';
export const CARGAR_CONTACTOS_REGISTRADAS = '[KpisVendedores] Cargar contactos registradas';
export const CARGAR_CONTACTOS_REGISTRADAS_FAIL = '[KpisVendedores] Cargar contactos registradas FAIL';
export const CARGAR_CONTACTOS_REGISTRADAS_SUCCESS = '[KpisVendedores] Cargar contactos registradas SUCCESS';
export const CARGAR_OPORTUNIDADES_ABIERTAS = '[KpisVendedores] Cargar oportunidades abiertas';
export const CARGAR_OPORTUNIDADES_ABIERTAS_FAIL = '[KpisVendedores] Cargar oportunidades abiertas FAIL';
export const CARGAR_OPORTUNIDADES_ABIERTAS_SUCCESS = '[KpisVendedores] Cargar oportunidades abiertas SUCCESS';
export const CARGAR_FUNNEL_OPORTUNIDADES = '[KpisVendedores] Cargar funnel oportunidades';
export const CARGAR_FUNNEL_OPORTUNIDADES_FAIL = '[KpisVendedores] Cargar funnel oportunidades FAIL';
export const CARGAR_FUNNEL_OPORTUNIDADES_SUCCESS = '[KpisVendedores] Cargar funnel oportunidades SUCCESS';


export class CargarActividades implements Action {
  readonly type = CARGAR_ACTIVIDADES;
}

export class CargarActividadesFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_SUCCESS;

  constructor( public series: SeriesBar[], public categories: string[] ) {}
}

export class CargarActividadesPendientes implements Action {
  readonly type = CARGAR_ACTIVIDADES_PENDIENTES;
}

export class CargarActividadesPendientesFail implements Action {
  readonly type = CARGAR_ACTIVIDADES_PENDIENTES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarActividadesPendientesSuccess implements Action {
  readonly type = CARGAR_ACTIVIDADES_PENDIENTES_SUCCESS;

  constructor( public actividades: Actividad[] ) {}
}

export class CargarEmpresasRegistradas implements Action {
  readonly type = CARGAR_EMPRESAS_REGISTRADAS;

  constructor(public opciones: any) {}
}

export class CargarEmpresasRegistradasFail implements Action {
  readonly type = CARGAR_EMPRESAS_REGISTRADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarEmpresasRegistradasSuccess implements Action {
  readonly type = CARGAR_EMPRESAS_REGISTRADAS_SUCCESS;

  constructor( public empresas: Empresa[], public totalFiltro: number, public total: number ) {}
}

export class CargarContactosRegistradas implements Action {
  readonly type = CARGAR_CONTACTOS_REGISTRADAS;

  constructor(public opciones: any) {}
}

export class CargarContactosRegistradasFail implements Action {
  readonly type = CARGAR_CONTACTOS_REGISTRADAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarContactosRegistradasSuccess implements Action {
  readonly type = CARGAR_CONTACTOS_REGISTRADAS_SUCCESS;

  constructor( public contactos: Contacto[], public totalFiltro: number, public total: number ) {}
}

export class CargarOportunidadesAbiertas implements Action {
  readonly type = CARGAR_OPORTUNIDADES_ABIERTAS;
}

export class CargarOportunidadesAbiertasFail implements Action {
  readonly type = CARGAR_OPORTUNIDADES_ABIERTAS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarOportunidadesAbiertasSuccess implements Action {
  readonly type = CARGAR_OPORTUNIDADES_ABIERTAS_SUCCESS;

  constructor( public oportunidades: KpisVendedores[] ) {}
}

export class CargarFunnelOportunidades implements Action {
  readonly type = CARGAR_FUNNEL_OPORTUNIDADES;
}

export class CargarFunnelOportunidadesFail implements Action {
  readonly type = CARGAR_FUNNEL_OPORTUNIDADES_FAIL;

  constructor( public payload: any ) {}
}

export class CargarFunnelOportunidadesSuccess implements Action {
  readonly type = CARGAR_FUNNEL_OPORTUNIDADES_SUCCESS;

  constructor( public datas: FunnelGraphColors[] ) {}
}

export type kpisVendedoresAcciones = CargarActividades 
																		| CargarActividadesFail 
																		| CargarActividadesSuccess
																		| CargarActividadesPendientes 
																		| CargarActividadesPendientesFail 
																		| CargarActividadesPendientesSuccess
																		| CargarEmpresasRegistradas 
																		| CargarEmpresasRegistradasFail 
																		| CargarEmpresasRegistradasSuccess
																		| CargarContactosRegistradas 
																		| CargarContactosRegistradasFail 
																		| CargarContactosRegistradasSuccess
																		| CargarOportunidadesAbiertas 
																		| CargarOportunidadesAbiertasFail 
																		| CargarOportunidadesAbiertasSuccess
                                    | CargarFunnelOportunidades 
																		| CargarFunnelOportunidadesFail 
																		| CargarFunnelOportunidadesSuccess;
