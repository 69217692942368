import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable, throwError } from 'rxjs';
import { Ticket, TicketMensaje, AdjuntosMensaje } from 'src/app/models/ticket';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerTickets(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(this.url + '/tickets', {headers});
  }

	obtenerDatoTicket(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');

    return this.http.get(this.url + '/tickets/' + id, {headers});
  }

  actualizaEstatusTicket(ticket: Ticket): Observable<any> {
    const param = JSON.stringify({ticket});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(this.url + '/tickets/estatus', param, {headers});
  }

  registraTicket(ticket: Ticket): Observable<any> {
    const param = JSON.stringify({ticket});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/tickets', param, {headers});
  }

	registraMensajeTicket(mensaje: TicketMensaje): Observable<any> {
    const param = JSON.stringify({mensaje});
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.url + '/tickets/mensaje', param, {headers});
  }

  actualizaMensajesTicket(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');

    return this.http.get(this.url + '/tickets/mensaje/' + id, {headers});
  }

  addFileTransfers(files: AdjuntosMensaje[], id: number): Observable<any> {
    const formData: any = new FormData();

    for (const file of files) {
      formData.append('files[]', file.file, file.file.name);
      const data = JSON.stringify(file);
      formData.append('datos[]', data);
    }
    formData.append('ticket', id);
    
    return this.http.post(this.url + '/tickets/files', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }


  addFileTransfersMensaje(files: AdjuntosMensaje[], id: number, mensaje: number): Observable<any> {
    const formData: any = new FormData();

    for (const file of files) {
      formData.append('files[]', file.file, file.file.name);
      const data = JSON.stringify(file);
      formData.append('datos[]', data);
    }
    formData.append('ticket', id);
    formData.append('mensaje', mensaje);
    
    return this.http.post(this.url + '/tickets/mensaje/files', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }




  // makeFileRequest(url: string, params: any, files: File[], token: string, name: string, obj?: any) {
  //   return new Promise( (resolve, reject) => {
  //     const formData: FormData = new FormData();
  //     const xhr = new XMLHttpRequest();
  //     for (const file of files) {
  //         formData.append(name, file, file.name);
  //     }
  //     if (obj) {
	// 		  const data = JSON.stringify(obj);
	// 		  formData.append('datos', data);
	// 	 }

  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         if (xhr.status === 200) {
  //           resolve(JSON.parse(xhr.response));
  //         } else {
  //           reject(xhr.response);
  //         }
  //       }
  //     };

  //     xhr.open('POST', url, true);
  //     xhr.setRequestHeader('Authorization', token);
  //     xhr.send(formData);
  //   });
  // }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }



}
