import { Directive, ElementRef, HostListener } from '@angular/core';
import { isEmpty } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input:not([type=checkbox]):not([type=file]):not([type=password]):not([type=email])'
})
export class UpperFirstChartDirective {

  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('focusout', ['$event.target.value']) onFocusOut(value: any) {
    if (value !== '' && value !== isEmpty) {
      this.elementRef.nativeElement.value = value.charAt(0).toUpperCase() + value.slice(1);
    }
  }

}
