import { Pipe, PipeTransform } from '@angular/core';
interface PlazoDate {
  fecha: Date;
  aplicar: boolean;
  param: string;
}

@Pipe({
  name: 'plazoVencido'
})
export class PlazoVencidoPipe implements PipeTransform {

  transform(values: any[], args: PlazoDate): any {
    if (!values.length) { return []; }
    if (!args.aplicar || !args.fecha) {
      return values;
    }
    if (Object.prototype.toString.call(args.fecha) === '[object Date]' &&
      !isNaN(args.fecha.getDate())) {
        return values.filter( (it: any) => {
          if (it[args.param] && Object.prototype.toString.call(it[args.param]) === '[object Date]' &&
            !isNaN(it[args.param].getDate())) {
            return it[args.param] <= args.fecha;
          } else {
            return false;
          }
        });
    } else {
      return values;
    }
  }

}
