import { Injectable } from '@angular/core';
import { GLOBAL } from 'src/app/models/global';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public url: string;

  constructor() {
    this.url = GLOBAL.url;
  }

  // makeFileRequest(url: string, params: any, files: Array<File>, token: string, name: string) {
  //   return new Promise( (resolve, reject) => {
  //     const formData: any = new FormData();
  //     const xhr = new XMLHttpRequest();
  //     for (const file of files) {
  //         formData.append(name, file, file.name);
  //     }

  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4) {
  //         if (xhr.status === 200) {
  //           resolve(JSON.parse(xhr.response));
  //         } else {
  //           reject(xhr.response);
  //         }
  //       }
  //     };

  //     xhr.open('POST', url, true);
  //     xhr.setRequestHeader('Authorization', token);
  //     xhr.send(formData);
  //   });
  // }


  makeFileRequest(url: string, params: any, files: File[], token: string, name: string, obj?: any) {
    return new Promise( (resolve, reject) => {
      const formData: FormData = new FormData();
      const xhr = new XMLHttpRequest();
      for (const file of files) {
          formData.append(name, file, file.name);
      }
      if (obj) {
			  const data = JSON.stringify(obj);
			  formData.append('datos', data);
		 }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', token);
      xhr.send(formData);
    });
  }

}
