import * as fromDireccion from '../actions/direccion.actions';
import { Municipio, Estado, Colonia } from 'src/app/models/direccion';

export interface DireccionState {
  estados: Estado[];
  municipios: Municipio[];
  colonias: Colonia[];
  estado: number;
  municipio: number;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initState: DireccionState = {
  estados: [],
  municipios: [],
  colonias: [],
  estado: 0,
  municipio: 0,
  loaded: false,
  loading: false,
  error: null
};

export function direccionReducer( state = initState, action: fromDireccion.direccionAcciones ): DireccionState {

  switch ( action.type ) {
    case fromDireccion.CARGAR_ESTADOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.CARGAR_ESTADOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        estados: [...action.estados],
        // municipios: [],
        // colonias: []
      };
    case fromDireccion.CARGAR_ESTADOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromDireccion.CARGAR_MUNICIPIOS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.CARGAR_MUNICIPIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        municipios: [...action.municipios],
        estado: action.estado
      };
    case fromDireccion.CARGAR_MUNICIPIOS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromDireccion.CARGAR_COLONIAS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.CARGAR_COLONIAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        colonias: [...action.colonias],
        estado: action.estado,
        municipio: action.municipio
      };
    case fromDireccion.CARGAR_COLONIAS_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };
    case fromDireccion.CARGAR_CODE_POSTAL:
      return {
        ...state,
        loading: true,
        error: null
      };
    case fromDireccion.CARGAR_CODE_POSTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        estado: action.estado,
        municipio: action.municipio
      };
    case fromDireccion.CARGAR_CODE_POSTAL_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          url: action.payload.url
        }
      };

    default:
      return state;
  }
}
