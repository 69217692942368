import { SortOrder } from './sort';

export class VentaPropietario{
	public importe: number;
	public cantidad: number;
	public etapa: number;
	public desEtapa: string;
	public propietario: number;
	public desPropietario: string;
	constructor(
		obj: DataObjVentaPropietario
	){
		this.importe = obj && obj.importe ? obj.importe : 0;
		this.cantidad = obj && obj.cantidad ? obj.cantidad : 0;
		this.etapa = obj && obj.etapa ? obj.etapa : null;
		this.desEtapa = obj && obj.desEtapa ? obj.desEtapa : null;
		this.propietario = obj && obj.propietario ? obj.propietario : null;
		this.desPropietario = obj && obj.desPropietario ? obj.desPropietario : '';
	}
}

interface DataObjVentaPropietario{
	importe: number;
	cantidad: number;
	etapa: number;
	desEtapa: string;
	propietario: number;
	desPropietario: string;
}

export const VentaPropietarioSortType: SortOrder[] = [
  { id: '+cantidad', text: 'Cantidad'},
  { id: '-cantidad', text: 'Cantidad desc'},
  { id: '+desEtapa', text: 'Etapa'},
  { id: '-desEtapa', text: 'Etapa desc'},
  { id: '+importe', text: 'Importe'},
  { id: '-importe', text: 'Importe desc'},
];