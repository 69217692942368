import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';

@Injectable({
  providedIn: 'root'
})
export class AutoActualizacionService {

  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  AutoEnvioCorreosContactos(tokenId: string): Observable<any> {
    const param = JSON.stringify({tokenId});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/campanaauto/autosend/' + tokenId, param,  {headers});
  } //pasar a un controlador privado o que requiera permisos

  validarTokenCorreoContacto(tokenId: string): Observable<any> {
    const param = JSON.stringify({tokenId});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/campanaauto/verify/' + tokenId, param,  {headers});
  }

  guardarAutoActualizacionInfo(contacto: any, tokenId: string): Observable<any> {
    const param = JSON.stringify({contacto, tokenId});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/campanaauto/info', param,  {headers});
  }

  confirmacionAutoActualizacionInfo(tokenId: string): Observable<any> {
    const param = JSON.stringify({tokenId});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/campanaauto/info/confirm', param,  {headers});
  }

}
