import { Action } from '@ngrx/store';
import { Ticket, TicketMensaje, AdjuntosMensaje } from 'src/app/models/ticket';

export const CARGAR_TICKETS = '[Ticket] Cargar tickets';
export const CARGAR_TICKETS_FAIL = '[Ticket] Cargar tickets FAIL';
export const CARGAR_TICKETS_SUCCESS = '[Ticket] Cargar tickets SUCCESS';
export const CARGAR_TICKET = '[Ticket] Cargar ticket';
export const CARGAR_TICKET_FAIL = '[Ticket] Cargar ticket FAIL';
export const CARGAR_TICKET_SUCCESS = '[Ticket] Cargar ticket SUCCESS';
export const ACTUALIZA_TICKET = '[Ticket] Actualiza ticket';
export const ACTUALIZA_TICKET_FAIL = '[Ticket] Actualiza ticket FAIL';
export const ACTUALIZA_TICKET_SUCCESS = '[Ticket] Actualiza ticket SUCCESS';
export const ACTUALIZA_TICKET_ESTATUS = '[Ticket] Actualiza ticket estatus';
export const ACTUALIZA_TICKET_ESTATUS_FAIL = '[Ticket] Actualiza ticket estatus FAIL';
export const ACTUALIZA_TICKET_ESTATUS_SUCCESS = '[Ticket] Actualiza ticket estatus SUCCESS';
export const LIMPIAR_TICKET = '[Ticket] Limpiar ticket';
export const ENVIA_MENSAJE_TICKET = '[Ticket] Envia mensaje ticket';
export const ENVIA_MENSAJE_TICKET_FAIL = '[Ticket] Envia mensaje ticket FAIL';
export const ENVIA_MENSAJE_TICKET_SUCCESS = '[Ticket] Envia mensaje ticket SUCCESS';
export const ACTUALIZA_MENSAJE_TICKET = '[Ticket] Actualiza mensaje ticket';
export const ACTUALIZA_MENSAJE_TICKET_FAIL = '[Ticket] Actualiza mensaje ticket FAIL';
export const ACTUALIZA_MENSAJE_TICKET_SUCCESS = '[Ticket] Actualiza mensaje ticket SUCCESS';
export const REGISTRAR_ARCHIVOS_MENSAJE = '[Ticket] registrar archivos mensaje';
export const REGISTRAR_ARCHIVOS_MENSAJE_FAIL = '[Ticket] registrar archivos mensaje FAIL';
export const REGISTRAR_ARCHIVOS_MENSAJE_SUCCESS = '[Ticket] registrar archivos mensaje SUCCESS';


export class CargarTickets implements Action {
  readonly type = CARGAR_TICKETS;
}

export class CargarTicketsFail implements Action {
  readonly type = CARGAR_TICKETS_FAIL;

  constructor( public payload: any ) {}
}

export class CargarTicketsSuccess implements Action {
  readonly type = CARGAR_TICKETS_SUCCESS;

  constructor( public tickets: Ticket[] ) {}
}

export class CargarTicket implements Action {
  readonly type = CARGAR_TICKET;

  constructor(public id: number) {}
}

export class CargarTicketFail implements Action {
  readonly type = CARGAR_TICKET_FAIL;

  constructor( public payload: any ) {}
}

export class CargarTicketSuccess implements Action {
  readonly type = CARGAR_TICKET_SUCCESS;

  constructor( public ticket: Ticket ) {}
}

export class ActualizaTicket implements Action {
  readonly type = ACTUALIZA_TICKET;

  constructor( public ticket: Ticket ) { }
}

export class ActualizaTicketFail implements Action {
  readonly type = ACTUALIZA_TICKET_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTicketSuccess implements Action {
  readonly type = ACTUALIZA_TICKET_SUCCESS;

  constructor( public ticket: Ticket ) {}
}

export class ActualizaTicketEstatus implements Action {
  readonly type = ACTUALIZA_TICKET_ESTATUS;

  constructor( public id: number, public status: number ) { }
}

export class ActualizaTicketEstatusFail implements Action {
  readonly type = ACTUALIZA_TICKET_ESTATUS_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaTicketEstatusSuccess implements Action {
  readonly type = ACTUALIZA_TICKET_ESTATUS_SUCCESS;

  constructor( public id: number, public status: number ) {}
}

export class LimpiarTicket implements Action {
  readonly type = LIMPIAR_TICKET;

  constructor() {}
}

export class EnviaMensajeTicket implements Action {
  readonly type = ENVIA_MENSAJE_TICKET;

  constructor( public mensaje: TicketMensaje ) { }
}

export class EnviaMensajeTicketFail implements Action {
  readonly type = ENVIA_MENSAJE_TICKET_FAIL;

  constructor( public payload: any ) {}
}

export class EnviaMensajeTicketSuccess implements Action {
  readonly type = ENVIA_MENSAJE_TICKET_SUCCESS;

  constructor( public mensaje: TicketMensaje ) {}
}

export class ActualizaMensajeTicket implements Action {
  readonly type = ACTUALIZA_MENSAJE_TICKET;

  constructor(public id: number) {}
}

export class ActualizaMensajeTicketFail implements Action {
  readonly type = ACTUALIZA_MENSAJE_TICKET_FAIL;

  constructor( public payload: any ) {}
}

export class ActualizaMensajeTicketSuccess implements Action {
  readonly type = ACTUALIZA_MENSAJE_TICKET_SUCCESS;

  constructor( public ticket: Ticket ) {}
}

export class RegistrarArchivoMensaje implements Action {
  readonly type = REGISTRAR_ARCHIVOS_MENSAJE;

  constructor(public adjuntos: AdjuntosMensaje[], public ticket: number, public mensaje: number ) {}
}

export class RegistrarArchivoMensajeFail implements Action {
  readonly type = REGISTRAR_ARCHIVOS_MENSAJE_FAIL;

  constructor( public payload: any ) {}
}

export class RegistrarArchivoMensajeSuccess implements Action {
  readonly type = REGISTRAR_ARCHIVOS_MENSAJE_SUCCESS;

  constructor( public archivos: AdjuntosMensaje[] ) {}
}

export type ticketAcciones = CargarTickets
													| CargarTicketsFail
													| CargarTicketsSuccess
													| CargarTicket
													| CargarTicketFail
													| CargarTicketSuccess
													| ActualizaTicket
													| ActualizaTicketFail
													| ActualizaTicketSuccess
													| ActualizaTicketEstatus
													| ActualizaTicketEstatusFail
													| ActualizaTicketEstatusSuccess
													| EnviaMensajeTicket
													| EnviaMensajeTicketFail
													| EnviaMensajeTicketSuccess
													| LimpiarTicket
                          | ActualizaMensajeTicket
													| ActualizaMensajeTicketFail
													| ActualizaMensajeTicketSuccess
                          | RegistrarArchivoMensaje
													| RegistrarArchivoMensajeFail
													| RegistrarArchivoMensajeSuccess;

