import { Catalogo } from "./catalogo";
import { UserType } from "./user";

export class ListaCampana {
  public id: number;
  public campana: number;
  public tiposEmpresas: Catalogo[];
  public origenContactos: Catalogo[];
  public paises: Catalogo[];
  public clasificaciones: Catalogo[];
  public industrias: Catalogo[];
  public propietarios: Catalogo[];
  public etapas: Catalogo[];
  public estatus: Catalogo[];
  public estados: Catalogo[];
  public clases: Catalogo[];
  public catalogoSeguridad: UserType[];
  public usuarioRegistro: number;
  public usuarioModifico: number;
  public fecRegistro: Date;
  public fecModifico: Date;
  public desUsuarioRegistro: string;
  public desUsuarioModifico: string;
  public textPropietarios: string;
  public textPaises: string;
  public textOrigenesContactos: string;
  public textIndustrias: string;
  public textClasificaciones: string;
  public textClases: string;
  public textEtapas: string;
  public textEstatus: string;
  public textCatalogoSeguridad: string;
  public fecCumpleInicia: Date;
  public fecCumpleTermina: Date;
  public fecFundacionInicia: Date;
  public fecFundacionTermina: Date;
  constructor(
    obj?: DataObjListaCampana
  ) {
    this.id = obj && obj.id ? obj.id : null;
    this.campana = obj && obj.campana ? obj.campana : null;
    this.tiposEmpresas = obj && obj.tiposEmpresas ? obj.tiposEmpresas : [];
    this.origenContactos = obj && obj.origenContactos ? obj.origenContactos : [];
    this.paises = obj && obj.paises ? obj.paises : [];
    this.clasificaciones = obj && obj.clasificaciones ? obj.clasificaciones : [];
    this.industrias = obj && obj.industrias ? obj.industrias : [];
    this.propietarios = obj && obj.propietarios ? obj.propietarios : [];
    this.etapas = obj && obj.etapas ? obj.etapas : [];
    this.estatus = obj && obj.estatus ? obj.estatus : [];
    this.estados = obj && obj.estados ? obj.estados : [];
    this.clases = obj && obj.clases ? obj.clases : [];
    this.catalogoSeguridad = obj && obj.catalogoSeguridad ? obj.catalogoSeguridad : [];
    this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : null;
    this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : null;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
    this.fecModifico = obj && obj.fecModifico ? obj.fecModifico : null;
    this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : '';
    this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico : '';
    this.textPropietarios = obj && obj.textPropietarios ? obj.textPropietarios : '';
    this.textPaises = obj && obj.textPaises ? obj.textPaises : '';
    this.textOrigenesContactos = obj && obj.textOrigenesContactos ? obj.textOrigenesContactos : '';
    this.textIndustrias = obj && obj.textIndustrias ? obj.textIndustrias : '';
    this.textClasificaciones = obj && obj.textClasificaciones ? obj.textClasificaciones : '';
    this.textClases = obj && obj.textClases ? obj.textClases : '';
    this.textEtapas = obj && obj.textEtapas ? obj.textEtapas : '';
    this.textEstatus = obj && obj.textEstatus ? obj.textEstatus : '';
    this.textCatalogoSeguridad = obj && obj.textCatalogoSeguridad ? obj.textCatalogoSeguridad : '';
    this.fecCumpleInicia = obj && obj.fecCumpleInicia ? obj.fecCumpleInicia : null;
    this.fecCumpleTermina = obj && obj.fecCumpleTermina ? obj.fecCumpleTermina : null;
    this.fecFundacionInicia = obj && obj.fecFundacionInicia ? obj.fecFundacionInicia : null;
    this.fecFundacionTermina = obj && obj.fecFundacionTermina ? obj.fecFundacionTermina : null;

  }
}
interface DataObjListaCampana {
  id: number;
  campana: number;
  tiposEmpresas: Catalogo[];
  origenContactos: Catalogo[];
  paises: Catalogo[];
  clasificaciones: Catalogo[];
  industrias: Catalogo[];
  propietarios: Catalogo[];
  etapas: Catalogo[];
  estatus: Catalogo[];
  estados: Catalogo[];
  clases: Catalogo[];
  catalogoSeguridad: UserType[];
  usuarioRegistro: number;
  usuarioModifico: number;
  fecRegistro: Date;
  fecModifico: Date;
  desUsuarioRegistro: string;
  desUsuarioModifico: string;
  textPropietarios: string;
  textPaises: string;
  textOrigenesContactos: string;
  textIndustrias: string;
  textClasificaciones: string;
  textClases: string;
  textEtapas: string;
  textEstatus: string;
  textCatalogoSeguridad: string;
  fecCumpleInicia: Date;
  fecCumpleTermina: Date;
  fecFundacionInicia: Date;
  fecFundacionTermina: Date;
}
