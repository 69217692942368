import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byObjectArray'
})
export class ByObjectArrayPipe implements PipeTransform {

  transform(items: any[], filter: any): any[] {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item: any) => this.applyFilter(item, filter));
  }

  /**
   * Perform the filtering.
   *
   * @param any The object to compare to the filter.
   * @param filter The filter to apply.
   * @return True if object satisfies filters, false if not.
   */
  applyFilter(scope: any, filter: any): boolean {
    for (const field in filter) {
      if (filter[field] !== undefined && filter[field] !== null && filter[field] !== 'null') {
        if (isNaN(scope[field])) {
          scope[field] = '' + scope[field];
        } else {
          // tslint:disable-next-line:radix
          scope[field] = parseInt(scope[field]);
        }
        if (isNaN(filter[field])) {
          filter[field] = '' + filter[field];
        } else {
          // tslint:disable-next-line:radix
          filter[field] = parseInt(filter[field]);
        }
        if (typeof scope[field] === 'string' && typeof filter[field] === 'string') {
          if (filter[field] && filter[field].trim() !== '') {
            const regresa = scope[field].toLowerCase().indexOf(filter[field].toLowerCase().trim()) > -1;
            if (regresa) {
              return true;
            }
          } else {
            return true;
          }
        } else if (typeof scope[field] === 'number' && typeof filter[field] === 'number') {
          const regresa = scope[field] === filter[field];
          if (regresa) {
            return true;
          }
        } else if (typeof scope[field] === 'boolean' && typeof filter[field] === 'boolean') {
          const regresa = scope[field] === filter[field];
          if (regresa) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

}
