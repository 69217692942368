import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { delay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AutoActualizacionService } from '../../../../services/auto-actualizacion-campana.service';
import { ContactoAutoActualizacion } from '../../../../models/auto-actualizacion';

@Component({
  selector: 'app-gracias-confirmar',
  templateUrl: './gracias-confirmar.component.html',
  styleUrls: ['./gracias-confirmar.component.scss']
})
export class GraciasConfirmarComponent implements OnInit {
  private tokenId = '';
  public contacto: ContactoAutoActualizacion = new ContactoAutoActualizacion();
  public loading: boolean;
  public year = 2020;
  public found = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private autoActualizacionService: AutoActualizacionService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params) => {
        const id = params.key;
        if (id && id !== '' && id !== undefined && id != null) {
          this.tokenId = id;
          this.autoActualizacionService.confirmacionAutoActualizacionInfo(this.tokenId).subscribe(
            (respuesta) => {
              setTimeout(() => {
                this.document.location.href = 'https://seguridadenamerica.com.mx';
              }, 5000);
            }, error => {
              this.found = false;
            });
        } else {
          this.found = false;
        }
      }
    );
  }

}
