import { Pipe, PipeTransform } from '@angular/core';

export interface FiltroCheck {
  checked: boolean;
  value: any;
  text: string;
  parametro: string;
}

@Pipe({
  name: 'optionCheckMultiple'
})
export class OptionCheckMultiplePipe implements PipeTransform {

  transform(value: any, args: FiltroCheck[]): any {
    if (!value.length) { return []; }
    if (!args.length) { return value; }
    const ids = args.filter((item) => {
      return item.checked && item.parametro in value[0];
    });
    if (!ids.length) { return value; }
    return value.filter( (it: any) => ids.some(f => f.value === it[f.parametro]));
  }

}
