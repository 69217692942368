import { Component, OnInit, OnDestroy } from '@angular/core';
import { Industria, IndustriaSortType } from 'src/app/models/industrias';
import { Catalogo } from 'src/app/models/catalogo';
import { CatalogosService } from 'src/app/services/catalogos.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import { Pagination } from 'src/app/models/pagination';
import { MESSAGES } from 'src/app/models/messages';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-industrias',
  templateUrl: './industrias.component.html',
  styleUrls: ['./industrias.component.sass']
})
export class IndustriasComponent implements OnInit, OnDestroy {
  heading = 'Detalles de las industrias';
  subheading = 'Industrias registradas en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity;
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeIndustria: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public industria: Industria;
  public industriaInicial: Industria = new Industria(null);
  public industrias: Industria[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive = false;
  public orderType = [...IndustriaSortType];
  public clasificaciones: Catalogo[];
  constructor(
    private catalogosService: CatalogosService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {
    this.industria = new Industria(null);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.industrias = [];
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeIndustria = this.store.select('industria').subscribe(
      (state) => {
        this.industrias = [...state.industrias];
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.clasificaciones = [...state.clasificaciones.filter(item => item.status)];
      }
    );
    console.log('Inicializa component Industria...');
    this.store.dispatch(new usuariosActions.CargarIndustrias());
    this.store.dispatch(new usuariosActions.ConsultaClasificaciones());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeIndustria.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }


  editarIndustria(zon: Industria) {
    this.industria = {...zon};
    this.industriaInicial = {...zon};
  }

  estatusIndustria(industria: Industria) {
    if (industria.id > 0) {
      this.loading = true;
      this.catalogosService.actualizaEstatusIndustria(industria).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaIndustriaEstatusSuccess(industria.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.toastr.error('Ocurrió un error.', 'Error');
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    Swal.fire({
      title: '¿Deseas registrar la industria?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.catalogosService.registraIndustria(this.industria).subscribe(
            (respuesta) => {
              if (respuesta.industria) {
                this.store.dispatch(new usuariosActions.ActualizaIndustriaSuccess(respuesta.industria));
                resolve(respuesta.industria);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaIndustriaFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.industria = new Industria(null);
          this.industriaInicial = new Industria(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Industria registrada.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  limpiar(form) {
    this.industria = new Industria(null);
    this.industriaInicial = new Industria(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
