import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-info',
  templateUrl: './footer-info.component.html'
})
export class FooterInfoComponent implements OnInit {
  public year = 2020;
  constructor() {
    const date = new Date();
    this.year = date.getFullYear();
  }

  ngOnInit() {
  }

}
