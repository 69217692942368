<div class="row">
  <div class="col-md-5">
    <div class="main-card mb-2 card">
      <div class="card-body p-0">
        <li class="list-group-item list-group-item-primary">
          <i class="fas fa-info-circle"></i> Prueba esta campaña antes de enviarla!
        </li>
        <form class="p-3 mb-2" #formPruebaEmailCampana="ngForm">
          <div class="form-group">
            <label for="emailPruebaCamapa">Email(s)</label>
            <input name="emailPruebaCamapa" id="emailPruebaCamapa" placeholder="e-mail" type="input"
              class="form-control" [(ngModel)]="campanaguardada.prueba" required minlength="4" required />
          </div>
          <div class="float-right mb-3">
            <button class="btn btn-success" type="button" (click)="enviarprueba(campanaguardada)" *ngIf="!enviando"
              [disabled]="!formPruebaEmailCampana.valid">
              <i class="fas fa-paper-plane"></i> Enviar prueba
            </button>
            <button class="btn btn-success" type="button" (click)="enviarprueba(campanaguardada)" *ngIf="enviando"
              disabled>
              <i class="fas fa-spinner fa-pulse"></i> Enviando
            </button>
          </div>
        </form>
      </div>
    </div>
    <form id="enviarcampana" name="enviarcampana" #datoFiscalForm="ngForm">
      <div class="main-card mb-2.5 card">
        <div class="card-header">
          <div class="card-header-title">Selección de contactos</div>
        </div>
        <div class="card-body">
          <div class="form-group form-inline">
            <label for="tema">Definir destinatarios</label>
            <button type="button" (click)="open()"
              class="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm d-flex ml-auto">
              Filtros
              <span class="pl-2 align-middle opacity-7">
                <i class="fa fa-angle-right"></i>
              </span>
            </button>
          </div>
          <div class="card mb-3" style="border: 1px solid rgba(0, 0, 0, 0.03);">
            <div class="card-header">
              <div class="card-header-title">Lista</div>
            </div>
            <div class="card-body p-0">
              <table class="table table-striped table-bordered mb-0" style="border: 1px solid rgba(0, 0, 0, 0.03);">
                <thead>
                  <tr>
                    <th scope="col">Filtros</th>
                    <th scope="col">Cantidad seleccionada</th>
                  </tr>
                </thead>
                <tbody style="border: 1px solid rgba(0, 0, 0, 0.03);">
                  <tr *ngIf="listaCampana.paises.length">
                    <td>Países</td>
                    <td>{{listaCampana.paises.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.propietarios.length">
                    <td>Propietarios</td>
                    <td>{{listaCampana.propietarios.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.origenContactos.length">
                    <td>Origen de Contacto</td>
                    <td>{{listaCampana.origenContactos.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.estatus.length">
                    <td>Estatus</td>
                    <td>{{listaCampana.estatus.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.etapas.length">
                    <td>Etapas</td>
                    <td>{{listaCampana.etapas.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.estados.length">
                    <td>Estados</td>
                    <td>{{listaCampana.estados.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.industrias.length">
                    <td>Industrias</td>
                    <td>{{listaCampana.industrias.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.catalogoSeguridad.length">
                    <td>Catalogo de seguridad</td>
                    <td>{{listaCampana.catalogoSeguridad.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.clasificaciones.length">
                    <td>Tipo de empresa</td>
                    <td>{{listaCampana.clasificaciones.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.clases.length">
                    <td>Clases</td>
                    <td>{{listaCampana.clases.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.tiposEmpresas.length">
                    <td>Tipo de empresa</td>
                    <td>{{listaCampana.tiposEmpresas.length}}</td>
                  </tr>
                  <tr *ngIf="listaCampana.fecCumpleInicia">
                    <td>Fecha cumpleaños</td>
                    <td>
                      {{ 'Del ' + (listaCampana.fecCumpleInicia | date: 'dd/MM') + ' al ' + (listaCampana.fecCumpleTermina | date: 'dd/MM') }}
                    </td>
                  </tr>
                  <tr *ngIf="listaCampana.fecFundacionInicia">
                    <td>Fecha fundación</td>
                    <td>
                      {{ 'Del ' + (listaCampana.fecFundacionInicia | date: 'dd/MM') + ' al ' + (listaCampana.fecFundacionTermina | date: 'dd/MM') }}
                    </td>
                  </tr>
                  <tr
                    *ngIf="!listaCampana.paises.length && !listaCampana.propietarios.length && !listaCampana.origenContactos.length 
                    && !listaCampana.estatus.length && !listaCampana.etapas.length && !listaCampana.estados.length 
                    && !listaCampana.industrias.length && !listaCampana.clasificaciones.length && !listaCampana.clases.length 
                    && !listaCampana.tiposEmpresas.length && !listaCampana.fecCumpleInicia && !listaCampana.fecFundacionInicia && !listaCampana.catalogoSeguridad.length">
                    <td colspan="2" class="p-0">
                      <li class="list-group-item list-group-item-warning">
                        <i class="fas fa-info-circle"></i> No hay ningún filtro seleccionado, esta campaña se enviará a
                        TODOS los contactos.
                      </li>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="col-sm-6">
                <a>Destinatarios: <strong>{{total}}</strong></a> <br/>
                <a>E-mails disponibles: <strong>{{sesquota}}</strong></a>
              </div>
              <div class="col-sm-6" *ngIf="identity.permisos.contactosDownload">
                <button type="button" class="btn btn-outline-secondary btn-pill float-right ml-auto" (click)="descargarDestinatarios()" *ngIf="!descargando">
                  Descargar lista
                </button>
                <button type="button" class="btn btn-outline-secondary btn-pill float-right ml-auto" disabled *ngIf="descargando">
                  Descargando... <i class="fas fa-spinner fa-pulse"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="float-right">
            <button class="mt-1 ml-1 btn btn-lg btn-focus btn-pill" (click)="guardaryenviar(campanaguardada)"
              *ngIf="!enviandoCamapana" [disabled]="!this.total">
              Enviar campaña ahora! &nbsp; <i class="fa fa-paper-plane"></i>
            </button>
            <button class="mt-1 ml-1 btn btn-lg btn-focus btn-pill" disabled *ngIf="enviandoCamapana">
              Enviando campaña... <i class="fas fa-spinner fa-pulse"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- akicomienza -->
  <div class="col-md-7">
    <div class="card main-card mb-3">
      <div class="card-header">
        <div class="card-header-title">
          Información del correo
        </div>
        <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
          <div class="btn-actions-pane-right actions-icon-btn">
            <a [routerLink]="['/auth/marketing/editar-campana', campanaguardada.id]" class="btn btn-warning">Editar <i
                class="fa fa-edit"></i></a>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <blockquote
          style="padding: 15px; margin: 0; border-left: 5px solid #e2e6e8; background: #f7f9fc; background-color:rgb(240, 240, 240)">
          <div class="mb-2">
            <div class="badge pl-2 pr-2 mr-2 badge-light">De: </div>
            <strong>{{campanaguardada.nombre_emisor}}</strong>
            <strong>
              &#60;{{campanaguardada.correo_emisor}}{{campanaguardada.dns}}&#62;
            </strong>
          </div>
          <div class="mb-2">
            <div class="badge pl-2 pr-2 mr-2 badge-light">Asunto:</div> <strong> {{campanaguardada.tema}}</strong>
          </div>
          <div class="mb-2" *ngIf="campanaguardada.seguimiento_aperturas">
            <div class="badge pl-2 pr-2 mr-2 badge-light">Seguimiento por apertura: </div>
            <div class="badge pl-2 pr-2 mr-2 badge-success">Activado</div>
          </div>
          <div class="mb-2" *ngIf="!campanaguardada.seguimiento_aperturas">
            <div class="badge pl-2 pr-2 mr-2 badge-light">Seguimiento por apertura: </div>
            <div class="badge pl-2 pr-2 mr-2 badge-warning">Desactivado</div>
          </div>
          <div class="mb-2" *ngIf="campanaguardada.seguimiento_links">
            <div class="badge pl-2 pr-2 mr-2 badge-light">Seguimiento por links(clics): </div>
            <div class="badge pl-2 pr-2 mr-2 badge-success">Activado</div>
          </div>
          <div class="mb-2" *ngIf="!campanaguardada.seguimiento_links">
            <div class="badge pl-2 pr-2 mr-2 badge-light">Seguimiento por links(clics): </div>
            <div class="badge pl-2 pr-2 mr-2 badge-warning">Desactivado</div>
          </div>
        </blockquote>
        <ckeditor style="border: 0" height="638px" [(ngModel)]="campanaguardada.texto_html" [config]="config">
        </ckeditor>
        <!-- <div class="divider"></div> -->
      </div>
    </div>
  </div>
</div>
<!-- <ng-template #content let-modal>
  <app-modal-lista-campana [idCampana]="campanaguardada.id" ></app-modal-lista-campana>
</ng-template> -->