import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GaleriaRepositorio } from '../../../models/galeria-repositorio';
import { GaleriaRepositorioService } from '../../../services/galeria-repositorio.service';
import { ToastrService } from 'ngx-toastr';

export interface IImagesLightbox {
  small?: string;
  medium?: string;
  big?: string;
  description?: string;
  url?: string;
  type?: string;
  label?: string;
}
export declare class GalleryImagesLightbox implements IImagesLightbox {
  small?: string;
  medium?: string;
  big?: string;
  description?: string;
  url?: string;
  type?: string;
  label?: string;
  constructor(obj: IImagesLightbox);
}

export interface IOptionsImagesLightbox {
  width?: string;
  height?: string;
  breakpoint?: number;
  fullWidth?: boolean;
  layout?: string;
  startIndex?: number;
  linkTarget?: string;
  lazyLoading?: boolean;
  image?: boolean;
  imagePercent?: number;
  imageArrows?: boolean;
  imageArrowsAutoHide?: boolean;
  imageSwipe?: boolean;
  imageAnimation?: string;
  imageSize?: string;
  imageAutoPlay?: boolean;
  imageAutoPlayInterval?: number;
  imageAutoPlayPauseOnHover?: boolean;
  imageInfinityMove?: boolean;
  // imageActions?: NgxGalleryAction[];
  imageDescription?: boolean;
  imageBullets?: boolean;
  thumbnails?: boolean;
  thumbnailsColumns?: number;
  thumbnailsRows?: number;
  thumbnailsPercent?: number;
  thumbnailsMargin?: number;
  thumbnailsArrows?: boolean;
  thumbnailsArrowsAutoHide?: boolean;
  thumbnailsSwipe?: boolean;
  thumbnailsMoveSize?: number;
  // thumbnailsOrder?: NgxGalleryOrder;
  thumbnailsRemainingCount?: boolean;
  thumbnailsAsLinks?: boolean;
  thumbnailsAutoHide?: boolean;
  thumbnailMargin?: number;
  thumbnailSize?: string;
  // thumbnailActions?: NgxGalleryAction[];
  thumbnailClasses?: string[];
  preview?: boolean;
  previewDescription?: boolean;
  previewArrows?: boolean;
  previewArrowsAutoHide?: boolean;
  previewSwipe?: boolean;
  previewFullscreen?: boolean;
  previewForceFullscreen?: boolean;
  previewCloseOnClick?: boolean;
  previewCloseOnEsc?: boolean;
  previewKeyboardNavigation?: boolean;
  previewAnimation?: boolean;
  previewAutoPlay?: boolean;
  previewAutoPlayInterval?: number;
  previewAutoPlayPauseOnHover?: boolean;
  previewInfinityMove?: boolean;
  previewZoom?: boolean;
  previewZoomStep?: number;
  previewZoomMax?: number;
  previewZoomMin?: number;
  previewRotate?: boolean;
  previewDownload?: boolean;
  previewCustom?: (index: number) => void;
  previewBullets?: boolean;
  arrowPrevIcon?: string;
  arrowNextIcon?: string;
  closeIcon?: string;
  fullscreenIcon?: string;
  spinnerIcon?: string;
  zoomInIcon?: string;
  zoomOutIcon?: string;
  rotateLeftIcon?: string;
  rotateRightIcon?: string;
  downloadIcon?: string;
  // actions?: NgxGalleryAction[];
}
export declare class OptionsImagesLightbox implements IOptionsImagesLightbox {
  width?: string;
  height?: string;
  breakpoint?: number;
  fullWidth?: boolean;
  layout?: string;
  startIndex?: number;
  linkTarget?: string;
  lazyLoading?: boolean;
  image?: boolean;
  imagePercent?: number;
  imageArrows?: boolean;
  imageArrowsAutoHide?: boolean;
  imageSwipe?: boolean;
  imageAnimation?: string;
  imageSize?: string;
  imageAutoPlay?: boolean;
  imageAutoPlayInterval?: number;
  imageAutoPlayPauseOnHover?: boolean;
  imageInfinityMove?: boolean;
  // imageActions?: NgxGalleryAction[];
  imageDescription?: boolean;
  imageBullets?: boolean;
  thumbnails?: boolean;
  thumbnailsColumns?: number;
  thumbnailsRows?: number;
  thumbnailsPercent?: number;
  thumbnailsMargin?: number;
  thumbnailsArrows?: boolean;
  thumbnailsArrowsAutoHide?: boolean;
  thumbnailsSwipe?: boolean;
  thumbnailsMoveSize?: number;
  // thumbnailsOrder?: NgxGalleryOrder;
  thumbnailsRemainingCount?: boolean;
  thumbnailsAsLinks?: boolean;
  thumbnailsAutoHide?: boolean;
  thumbnailMargin?: number;
  thumbnailSize?: string;
  // thumbnailActions?: NgxGalleryAction[];
  thumbnailClasses?: string[];
  preview?: boolean;
  previewDescription?: boolean;
  previewArrows?: boolean;
  previewArrowsAutoHide?: boolean;
  previewSwipe?: boolean;
  previewFullscreen?: boolean;
  previewForceFullscreen?: boolean;
  previewCloseOnClick?: boolean;
  previewCloseOnEsc?: boolean;
  previewKeyboardNavigation?: boolean;
  previewAnimation?: boolean;
  previewAutoPlay?: boolean;
  previewAutoPlayInterval?: number;
  previewAutoPlayPauseOnHover?: boolean;
  previewInfinityMove?: boolean;
  previewZoom?: boolean;
  previewZoomStep?: number;
  previewZoomMax?: number;
  previewZoomMin?: number;
  previewRotate?: boolean;
  previewDownload?: boolean;
  previewCustom?: (index: number) => void;
  previewBullets?: boolean;
  arrowPrevIcon?: string;
  arrowNextIcon?: string;
  closeIcon?: string;
  fullscreenIcon?: string;
  spinnerIcon?: string;
  zoomInIcon?: string;
  zoomOutIcon?: string;
  rotateLeftIcon?: string;
  rotateRightIcon?: string;
  downloadIcon?: string;
  // actions?: NgxGalleryAction[];
  constructor(obj: IOptionsImagesLightbox);
}

@Component({
  selector: 'app-images-lightbox-modal',
  templateUrl: './images-lightbox-modal.component.html',
  styleUrls: ['./images-lightbox-modal.component.scss']
})
export class ImagesLightboxModalComponent implements OnInit {
  public slideIndex = 1;
  currentIndex = 1;
  public options: OptionsImagesLightbox[];
  // public images: GalleryImagesLightbox[];
  public images: GaleriaRepositorio[];
  slideConfigFull: any = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>',
    prev: (event) => {
      console.log(event);
    },
    next: () => {
      console.log();
    },
  };

  slideConfig = {
    dots: true,
    customPaging: (slider, i: number) => {
      // var thumb = $(slider.$slides[i]).data();
      return '<a>' + ((i * 1) + 1) + '</a>';
    },
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      }
    ]
  };
  @ViewChild('slickModalFull') slickModalFull: SlickCarouselComponent;
  @ViewChild('slickModalDots') slickModalDots: SlickCarouselComponent;

  constructor(
    public modal: NgbActiveModal,
    private archivosService: GaleriaRepositorioService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    setTimeout( () => {
      // this.slideConfigFull.slideConfigFull = this.slideIndex;
      this.slickModalFull.slickGoTo(this.slideIndex);
      this.slickModalDots.slickGoTo(this.slideIndex);
      // this.showSlides(this.slideIndex);
    }, 300);
  }

  showSlides( n ) {
    let i = 0;
    const slides = document.getElementsByClassName('full-slide-image-lightbox') as HTMLCollectionOf<HTMLElement>;
    const dots = document.getElementsByClassName('slide-image-lightbox') as HTMLCollectionOf<HTMLImageElement>;
    const captionText = document.getElementById('caption');
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      if (slides[i].style) {
        slides[i].style.display = 'none';
      }
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slides.length && slides.length >= this.slideIndex && slides[this.slideIndex - 1]) {
      slides[this.slideIndex-1].style.display = 'block';
      dots[this.slideIndex-1].className += ' active';
      captionText.innerHTML = dots[this.slideIndex-1].alt;
    }
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  selectImage(index) {
    this.slideIndex = index;
    this.slickModalFull.slickGoTo(index);
  }

  closeModal() {
    this.modal.close();
  }

  afterChange(event: any) {
    let myVideo: any = document.getElementById("videoPlayer-" + event.nextSlide);
    myVideo.pause();
    this.slideIndex = event.currentSlide;
    this.slickModalDots.slickGoTo(this.slideIndex);
  }

  beforeChange(event: any) {
    let myVideo: any = document.getElementById("videoPlayer-" + event.currentSlide);
    myVideo.pause();
    this.slideIndex = event.nextSlide;
    
    this.slickModalDots.slickGoTo(this.slideIndex);
  }

  saveChange(archivo: GaleriaRepositorio) {
    if (archivo.etiquetasNuevo) {
      this.archivosService.actualizarEtiquetas(archivo).subscribe(
        respuesta => {
          archivo.editar = false;
          archivo.etiquetas = '' + archivo.etiquetasNuevo;
          this.toastr.success('Actualizado con éxito.');
        }, error => {}
      );
    } else {
      this.toastr.warning('Necesita una descripción.');
    }
  }

  selectEtiqueta(archivo: GaleriaRepositorio) {
    archivo.editar = true;
    archivo.etiquetasNuevo = '' + archivo.etiquetas;
  }

  cancelarEtiquetas(archivo: GaleriaRepositorio) {
    archivo.editar = false;
    archivo.etiquetasNuevo = '';
  }

  toggleVideo(i){
    let myVideo: any = document.getElementById("videoPlayer-" + i);
    if (!myVideo.paused){
      myVideo.play();
    }
    else{
      myVideo.pause();
      // myVideo.currentTime = 0;
    } 
  }

}
