import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input:not([type=checkbox]).form-control,textarea:not([type=checkbox]).form-control'
})
export class ValidarInputFormDirective {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document
  ) {}

  @HostListener('focusout', ['$event']) onFocusOut(value: FocusEvent) {
    if (this.elementRef.nativeElement.type === 'password') {
      this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.trim();
    }
    this.elementRef.nativeElement.classList.remove('is-invalid');
    // this.elementRef.nativeElement.classList.remove('is-valid');
    if (this.elementRef.nativeElement.validity.valid) {
      // this.elementRef.nativeElement.classList.add('is-valid');
    } else {
      this.elementRef.nativeElement.classList.add('is-invalid');
      let child = this.document.createElement('div');
      child.classList.add('invalid-feedback');
      var newContent = this.document.createTextNode(this.elementRef.nativeElement.validationMessage);
      this.renderer.appendChild(child, newContent);
      this.renderer.appendChild(this.elementRef.nativeElement.parentElement, child);
      setTimeout( () => {
        this.renderer.removeChild(this.elementRef.nativeElement.parentElement, child);
      }, 4000);
    }
    // if (value !== '' && value !== isEmpty) {
    //   if (this.elementRef.nativeElement && this.elementRef.nativeElement.value) {
    //     this.elementRef.nativeElement.value = value.charAt(0).toUpperCase() + value.slice(1);
    //   }
    // }
  }

}
