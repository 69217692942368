<div *ngIf="loading">
  <div class="text-center d-block p-3">
    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary btn-lg mr-3" data-fancybox data-type="iframe"
      [attr.data-src]="url + '/marketing/plantilla/camp/' + campanaenviada.id" href="javascript:;">
      <span class="mr-2 opacity-7">
        <i class="icon icon-anim-pulse ion-ios-analytics-outline"></i>
      </span>
      <span class="mr-1">Ver la campaña enviada</span>
    </button>
    <button type="button" class="btn-pill btn-shadow btn-wide fsize-1 btn btn-secondary btn-lg mr-3"
      printSectionId="print-campana" ngxPrint #print [useExistingCss]="true"
      [printTitle]="'Reporte de ' + campanaenviada.tema">
      <span class="pr-2 align-middle opacity-7">
        <i class="fas fa-file-pdf-o"></i>
      </span>
      Imprimir PDF
    </button>
    <button type="button" class="btn-pill btn-shadow btn-wide fsize-1 btn btn-warning btn-lg" (click)="changeContry()">
      <!-- <div class="font-icon-wrapper">
        <div class="flag-wrapper">
          <div class="font-flag-wrapper flag flag-icon-background flag-icon-mx" *ngIf="contry === 'mx'"></div>
          <div class="font-flag-wrapper flag flag-icon-background flag-icon-us" *ngIf="contry === 'us'"></div>
        </div>
      </div> -->
      <div class="form-inline">
        Cambiar Idioma
        <div class="language-icon flag flag-icon-background flag-icon-mx ml-2" *ngIf="contry === 'mx'"></div>
        <div class="language-icon flag flag-icon-background flag-icon-us ml-2" *ngIf="contry === 'us'"></div>
      </div>
    </button>
  </div>
  <div class="container" id="print-campana">
    <div class="mb-3 card shadow-none">
      <div class="form-inline" style="margin-left: 15px; padding: 30px 10px;">
        <img style="width: 220px; height: auto; margin-right: auto;" src="assets/images/sea-media.png" alt="Logo" />
        <!-- <h3 class="col-6  text-center">
          <span *ngIf="contry === 'mx'">REPORTE</span>
          <span *ngIf="contry === 'us'">REPORT</span> 
        </h3> -->
        <img style="width: 550px; height: auto; margin-left: auto;" src="assets/images/logo-original.png" alt="Logo" />
      </div>

        <h3 class="text-center" style="margin-left: 15px; padding: 30px 10px;">
          {{campanaenviada.tema}}
        </h3>


        
        <div class="card-header-tab card-header">
          <div class="card-header-title font-size-lg font-weight-normal">
            <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
            <span *ngIf="contry === 'mx'">Envio de la campaña:</span>
            <span *ngIf="contry === 'us'">Campaing Sent</span>
          </div>
          <div class="btn-actions-pane-right mr-4">
            <i>{{campanaenviada.expedido | date: 'dd/MM/yyyy'}}</i>
          </div>
        </div>
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg font-weight-normal">
          <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
          
          <span *ngIf="contry === 'mx'">Rendimiento de la campaña</span>
          <span *ngIf="contry === 'us'">Campaign Performance</span>
        </div>
        <div class="btn-actions-pane-right mr-4">
          <i>{{hoy | date: 'dd/MM/yyyy'}}</i>
        </div>
      </div>
      <div class="no-gutters row">
        <div style="width: 33%;">
          <div class="no-shadow rm-border bg-transparent widget-chart text-left card">
            <div class="progress-circle-wrapper">
              <div class="round-progress-text">
                <span>{{((campanaenviada.destinatarios - campanaenviada.rebotados) / campanaenviada.destinatarios) * 100
                  | number: '1.0-2'}}%</span>
                <round-progress
                  [current]="((campanaenviada.destinatarios - campanaenviada.rebotados) / campanaenviada.destinatarios) * 100"
                  [max]="100" [color]="'#3ac47d'" [background]="'#e3e3e3'" [radius]="88" [stroke]="11"
                  [semicircle]="false" [rounded]="true" [clockwise]="false" [responsive]="true" [duration]="1400"
                  [animation]="'easeInOutQuart'" [animationDelay]="0"></round-progress>
              </div>
            </div>
            <div class="widget-chart-content">
              <div class="widget-subheading" *ngIf="contry === 'mx'">Enviados</div>
              <div class="widget-subheading" *ngIf="contry === 'us'">Sent</div>
              <div class="widget-numbers"><span>{{campanaenviada.destinatarios}}</span></div>
              <div class="widget-description text-focus">
                <span *ngIf="contry === 'mx'">Entregados correctamente</span>
                <span *ngIf="contry === 'us'">Successfully delivered</span>
                <span class=" pl-1" [ngClass]="{
                  'text-danger': ((campanaenviada.destinatarios - campanaenviada.rebotados) / campanaenviada.destinatarios) * 100 <= 50,
                  'text-warning': ((campanaenviada.destinatarios - campanaenviada.rebotados) / campanaenviada.destinatarios) * 100 > 50 && ((campanaenviada.destinatarios - campanaenviada.rebotados) * 100 / campanaenviada.destinatarios) < 80,
                  'text-success': ((campanaenviada.destinatarios - campanaenviada.rebotados) / campanaenviada.destinatarios) * 100 >= 80
                }">
                  <span class="pl-1">{{(campanaenviada.destinatarios - campanaenviada.rebotados)}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 33%;">
          <div class="no-shadow rm-border bg-transparent widget-chart text-left card">
            <div class="progress-circle-wrapper">
              <div class="round-progress-text">
                <span>{{((campanaenviada.aperturas_unicas)/(campanaenviada.destinatarios - campanaenviada.rebotados)) *
                  100 | number: '1.0-2'}}%</span>
                <round-progress
                  [current]="((campanaenviada.aperturas_unicas)/(campanaenviada.destinatarios - campanaenviada.rebotados)) * 100"
                  [max]="100" [color]="'#3f6ad8'" [background]="'#e3e3e3'" [radius]="88" [stroke]="11"
                  [semicircle]="false" [rounded]="true" [clockwise]="false" [responsive]="true" [duration]="1400"
                  [animation]="'easeInOutQuart'" [animationDelay]="0"></round-progress>
              </div>
            </div>
            <div class="widget-chart-content">
              <div class="widget-subheading" *ngIf="contry === 'mx'">Tasa de aperturas</div>
              <div class="widget-subheading" *ngIf="contry === 'us'">Open rate</div>
              <div class="widget-numbers">
                <span>{{(campanaenviada.aperturas_unicas)}}</span>
              </div>
              <div class="widget-description text-focus">
                {{(campanaenviada.destinatarios - campanaenviada.rebotados) - campanaenviada.aperturas_unicas}}
                <span *ngIf="contry === 'mx'">pendientes de abrir</span>
                <span *ngIf="contry === 'us'">pending to open</span>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 33%;">
          <div class="no-shadow rm-border bg-transparent widget-chart text-left card">
            <div class="progress-circle-wrapper">
              <div class="round-progress-text">
                <span>{{ ((campanaenviada.clicks_unicos)/(campanaenviada.destinatarios - campanaenviada.rebotados)) *
                  100 | number: '1.0-2'}}%</span>
                <round-progress
                  [current]="((campanaenviada.clicks_unicos)/(campanaenviada.destinatarios - campanaenviada.rebotados)) * 100"
                  [max]="100" [color]="'#f7b924'" [background]="'#e3e3e3'" [radius]="88" [stroke]="11"
                  [semicircle]="false" [rounded]="true" [clockwise]="false" [responsive]="true" [duration]="1400"
                  [animation]="'easeInOutQuart'" [animationDelay]="0"></round-progress>
              </div>
            </div>
            <div class="widget-chart-content">
              <div class="widget-subheading" *ngIf="contry === 'mx'">Tasa de clics</div>
              <div class="widget-subheading" *ngIf="contry === 'us'">Click rate</div>
              <div class="widget-numbers">
                <span>{{(campanaenviada.clicks_unicos)}}</span>
              </div>
              <div class="widget-description text-focus">
                {{(campanaenviada.destinatarios - campanaenviada.rebotados) - campanaenviada.clicks_unicos}}
                <span *ngIf="contry === 'mx'">pendientes de clics</span>
                <span *ngIf="contry === 'us'">pending to click</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg font-weight-normal">
          <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
          <span *ngIf="contry === 'mx'">Información general</span>
          <span *ngIf="contry === 'us'">General Information</span>
        </div>
      </div>
      <div class="no-gutters row">
        <div style="width: 50%;">
          <ul class="list-group list-group-flush">
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="lnr-rocket text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Enviados</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Sent</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">E-mails enviados en esta campaña</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Emails sent in this campaign
                      </div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" [countUp]="campanaenviada.destinatarios"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="pe-7s-mail-open text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Aperturas únicas</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Unique openings</div>
                      <div class="widget-subheading">
                        <span *ngIf="contry === 'mx'">Todas las veces que han abierto tu campaña</span>
                        <span *ngIf="contry === 'us'">Every time your campaign has been opened</span>
                        <span>
                          <span class="pl-1"><b>{{campanaenviada.aperturas_totales}}</b></span>
                        </span>
                      </div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" [countUp]="campanaenviada.aperturas_unicas"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="lnr-unlink text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Rebotados</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Bounced</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">E-mails rebotados</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Bounced emails</div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" [countUp]="campanaenviada.rebotados"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="pe-7s-paper-plane text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Envios exitosos</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Successful sent</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">E-mails recibidos correcamente</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Emails received correctly
                      </div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text"
                        [countUp]="campanaenviada.destinatarios - campanaenviada.rebotados">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="pe-7s-phone text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Aperturas desde un celular</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Openings from a cell phone</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">Total de aperturas hechas a través de un
                        celular</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Total number of openings made through a
                        cell phone</div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" *ngIf="!campanaenviada.aperturas_totales">0%</div>
                      <div class="widget-numbers text" *ngIf="campanaenviada.aperturas_totales">
                        {{(campanaenviada.celulares_totales)/(campanaenviada.aperturas_totales)*100 | number:'1.2-2'}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div style="width: 50%;">
          <ul class="list-group list-group-flush">
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="lnr-select text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Clics únicos a enlaces</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Unique clicks to links</div>
                      <div class="widget-subheading">
                        <span *ngIf="contry === 'mx'">Todos los clics en los enlaces</span>
                        <span *ngIf="contry === 'us'">All clicks on the links</span>
                        <span>
                          <span class="pl-1"><b>{{campanaenviada.clicks_totales}}</b></span>
                        </span>
                      </div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" [countUp]="(campanaenviada.clicks_unicos)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="pe-7s-mail text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Sin abrir</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Unopened</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">Total de emails sin abrir</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Total unopened emails</div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text"
                        [countUp]="((campanaenviada.destinatarios - campanaenviada.rebotados) - campanaenviada.aperturas_unicas)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="lnr-exit text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Desuscritos</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Unsubscribed</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">Total de suscriptores desuscritos</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Total unsubscribed subscribers</div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" [countUp]="campanaenviada.desuscritos"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="pe-7s-flag text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Reportes de spam</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Spam reports</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">Cantidad de correos reportados como spam
                      </div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Number of emails reported as spam</div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" [countUp]="campanaenviada.spams"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="bg-transparent list-group-item">
              <div class="widget-content p-0">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="icon-wrapper rounded-circle" style="margin-left: 0;margin-right: 25px;">
                      <div class="icon-wrapper-bg opacity-9 bg-light" style="margin-left: 0;margin-right: 0px;"></div>
                      <i class="lnr-screen text-black"></i>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading" *ngIf="contry === 'mx'">Aperturas desde una computadora</div>
                      <div class="widget-heading" *ngIf="contry === 'us'">Openings from a computer</div>
                      <div class="widget-subheading" *ngIf="contry === 'mx'">Total de aperturas a través de una
                        computadora</div>
                      <div class="widget-subheading" *ngIf="contry === 'us'">Total openings through a computer</div>
                    </div>
                    <div class="widget-content-right">
                      <div class="widget-numbers text" *ngIf="!campanaenviada.aperturas_totales">0%</div>
                      <div class="widget-numbers text" *ngIf="campanaenviada.aperturas_totales">
                        {{(campanaenviada.computadoras_totales)/(campanaenviada.aperturas_totales)*100 |
                        number:'1.2-2'}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg font-weight-normal">
          <i class="header-icon lnr-charts icon-gradient bg-happy-green"></i>
          <span *ngIf="contry === 'mx'">Información de los enlaces</span>
          <span *ngIf="contry === 'us'">Links Information</span>
        </div>
      </div>
      <div class="no-gutters row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="align-middle mb-0 table table-borderless table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-center">
                    <span *ngIf="contry === 'mx'">Enlaces (URL)</span>
                    <span *ngIf="contry === 'us'">Links (URL)</span>
                  </th>
                  <th style="width: 100px;" class="text-center">
                    <span *ngIf="contry === 'mx'">Único</span>
                    <span *ngIf="contry === 'us'">Unique</span>
                  </th>
                  <th style="width: 100px;" class="text-center">
                    <span *ngIf="contry === 'mx'">Total</span>
                    <span *ngIf="contry === 'us'">Total</span>
                  </th>
                  <th style="width: 80px;" class="text-center">%</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let link of link">
                  <td class="text-left line-clamp" [title]="link.url">
                    <a [href]="link.url" target="_blank" class="text-dark">{{link.url}}</a>
                  </td>
                  <td class="text-center">
                    <span *ngIf="link.clicksunicos">
                      <div><b>{{link.clicksunicos}}</b></div>
                    </span><b></b>
                    <span *ngIf="!link.clicksunicos">
                      <div>-</div>
                    </span>
                  </td>
                  <td class="text-center">
                    <span *ngIf="link.clickstotales">
                      <div><b>{{link.clickstotales}}</b></div>
                    </span>
                    <span *ngIf="!link.clickstotales">
                      <div>-</div>
                    </span>
                  </td>
                  <td class="text-center">
                    {{(link.clicksunicos / total)*100 | number: '1.0-2'}} %
                  </td>
                </tr>
                <tr *ngIf="campanaenviada.clicks_unicos == 0">
                  <td colspan="4" class="p-2 text-center">
                    <li class="list-group-item list-group-item-info">
                      <i class="fas fa-info-circle"></i>
                      <span *ngIf="contry === 'mx'">Aún no hay actividad en ningún enlace.</span>
                      <span *ngIf="contry === 'us'">There is no subscriber activity yet.</span>
                    </li>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <button type="button" (click)="printXLS()" class="btn btn-link">
                  <i class="fa fa-file-excel-o"></i>&nbsp;
                  <span *ngIf="contry === 'mx'">Imprimir XLSX</span>
                  <span *ngIf="contry === 'us'">Printt XLSX</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3" *ngIf="filterActive">
          <div class="card no-shadow">
            <div class="card-header">
              <div class="card-header-title">Filtrar contenido</div>
              <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
                <div class="btn-actions-pane-right actions-icon-btn">
                  <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="p-3">
                <div class="form-group">
                </div>
              </div>
              <h5 class="menu-header-title font-size-md text-capitalize ml-3">
                Filtrar tipos
              </h5>
              <div class="card-body p-0" id="collapseExample" [ngbCollapse]="isCollapsed">
                <div class="scroll-area-lg mb-2">
                  <section class="scrollbar-container">
                    <perfect-scrollbar [autoPropagation]="true">
                      <ul class="todo-list-wrapper list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let item of filterTipos">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                  <input type="checkbox" id="selectCheckTiposSuscriptoresStatusFilter{{item.nombre}}"
                                    class="custom-control-input"
                                    name="selectCheckTiposSuscriptoresStatusFilter{{item.nombre}}"
                                    [(ngModel)]="item.checked" (change)="changeCheck()">
                                  <label class="custom-control-label"
                                    for="selectCheckTiposSuscriptoresStatusFilter{{item.nombre}}">&nbsp;</label>
                                </div>
                              </div>
                              <div class="widget-content-left flex2">
                                <div class="widget-heading">{{item.nombre}}</div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>        
        <div class="col-md-9 no-shadow" [ngClass]="{'col-md-12': !filterActive }">
          <div class="card-header">
            <div class="card-header-title">
              <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
              <span *ngIf="contry === 'mx'" (click)="informacion = !informacion">Información de suscriptores</span>
              <span *ngIf="contry === 'us'" (click)="informacion = !informacion">Subscribers Information</span>
            </div>
            <div class="btn-actions-pane-right actions-icon-btn">
              <div class="btn-actions-pane-right mr-4">
                ({{(estatussuscriptores | filterSelectedCampingSend : { opciones: filterTipos, parametro:
                'estatus'} | anyScope:
                paginacion.textSearch ).length}}
                de {{(estatussuscriptores).length}})
                <div class="btn-group ml-2">
                  <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive"
                    (click)="filterActive = true">
                    <span>Filtrar</span>
                    <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
                  </button>
                </div>
                <i class="fas ml-2" (click)="informacion = !informacion" [ngClass]="{'fa-chevron-up': informacion, 'fa-chevron-down': !informacion }"></i>
              </div>
            </div>
          </div>
          <div class="card-body p-0" *ngIf="informacion"> 
            <div class="table-responsive">
              <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-center">
                      <span *ngIf="contry === 'mx'">Nombre</span>
                      <span *ngIf="contry === 'us'">Name</span>
                    </th>
                    <th class="text-center">
                      <span *ngIf="contry === 'mx'">Email</span>
                      <span *ngIf="contry === 'us'">Email</span>
                    </th>
                    <th class="text-center">
                      <span *ngIf="contry === 'mx'">Estatus</span>
                      <span *ngIf="contry === 'us'">Status</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let estatussuscriptor of estatussuscriptores | filterSelectedCampingSend : { opciones: filterTipos, parametro: 'estatus'}">
                    <td class="text-left" [title]="estatussuscriptor.nombre + ' ' + estatussuscriptor.apellido">
                      <a class="text-dark"
                        [routerLink]="['/auth/contactos/profile', estatussuscriptor.id]">{{estatussuscriptor.nombre + '
                        '
                        + estatussuscriptor.apellido}}</a>
                    </td>
                    <td class="text-left" [title]="estatussuscriptor.email">
                      <a class="text-dark" [href]="'mailto:' + estatussuscriptor.email">{{estatussuscriptor.email}}</a>
                    </td>
                    <td class="text-center">
                      <div class="mb-2 mr-2 badge badge-pill badge-danger" *ngIf="estatussuscriptor.estatus == 2 ">
                        <span *ngIf="contry === 'mx'">Desuscrito</span>
                        <span *ngIf="contry === 'us'">Unsubcribed</span>
                      </div>
                      <div class="mb-2 mr-2 badge badge-pill badge-focus" *ngIf="estatussuscriptor.estatus == 3 ">
                        <span *ngIf="contry === 'mx'">Rebotado</span>
                        <span *ngIf="contry === 'us'">Bounced</span>
                      </div>
                      <div class="mb-2 mr-2 badge badge-pill badge-secondary" *ngIf="estatussuscriptor.estatus == 4 ">
                        <span *ngIf="contry === 'mx'">Spam</span>
                        <span *ngIf="contry === 'us'">Spam</span>
                      </div>
                      <div class="mb-2 mr-2 badge badge-pill badge-focus" *ngIf="estatussuscriptor.estatus == 5 ">
                        <span *ngIf="contry === 'mx'">Error Amazon</span>
                        <span *ngIf="contry === 'us'">Error Amazon</span>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="!estatussuscriptores.length">
                    <td colspan="3" class="p-2 text-center">
                      <li class="list-group-item list-group-item-info ">
                        <i class="fas fa-info-circle"></i>
                        <span *ngIf="contry === 'mx'">Aún no hay actividad de suscriptores.</span>
                        <span *ngIf="contry === 'us'">There is no subscriber activity yet.</span>
                      </li>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12 text-center text-muted">
          <p class="mb-0" *ngIf="contry === 'mx'">** Este archivo es solo es un reporte con fines de información y
            estadisticas de nuestros resultados. **</p>
          <p class="mb-0" *ngIf="contry === 'us'">** This file is only a report for information and statistics
            purposes
            of our results. **</p>
          <p class="mt-0" *ngIf="contry === 'mx'">** Consulte más información en <a
              href="http://seguridadenamerica.com.mx" target="_blank">seguridadenamerica.com.mx</a> **</p>
          <p class="mt-0" *ngIf="contry === 'us'">** See more information at <a href="http://seguridadenamerica.com.mx"
              target="_blank">seguridadenamerica.com.mx</a> **</p>
        </div>
      </div>
    </div>
  </div>



