import { Pipe, PipeTransform } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

  transform(time: NgbTimeStruct): any {
    if (time) {
      let duration = '';
      if(time.hour === 0 && time.minute === 0){
        duration = 'Sin duración';
      }
      if(time.hour === 0 && time.minute === 1){
        duration = time.minute + ' minuto';
      }
      if(time.hour === 0 && time.minute > 1){
        duration = time.minute + ' minutos';
      }
      if(time.hour === 1  && time.minute === 0){
        duration = time.hour + ' hora';
      }
      if(time.hour === 1 && time.minute === 1){
        duration = time.hour + ' hora '+ time.minute + ' minuto';
      }
      if(time.hour === 1 && time.minute > 1){
        duration = time.hour + ' hora '+ time.minute + ' minutos';
      }
      if(time.hour > 1 && time.minute === 0){
        duration = time.hour + ' horas';
      }
      if(time.hour > 1 && time.minute === 1){
        duration = time.hour + ' horas '+ time.minute + ' minuto';
      }
      if(time.hour > 1 && time.minute > 1){
        duration = time.hour + ' horas '+ time.minute + ' minutos';
      }
      return `${duration}`;
    } else {
      return;
    }
  }

}
