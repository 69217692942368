import {Component, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { GLOBAL } from 'src/app/models/global';

import { AppState } from 'src/app/store/app.reducer';
declare var grecaptcha: any;
import * as usuariosActions from 'src/app/store/actions';
import { User } from 'src/app/models/user';
import { AuthState } from 'src/app/store/reducers';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth/auth.service';

// tslint:disable

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styles: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	token: string;
	cargando: boolean;
	error: any;
	subscription: Subscription = new Subscription;
	captcha: string;
	reCaptcha: string;
	usuario: User;
	cargo: boolean;
	@ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
	// @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;
	public loadingTemplate: TemplateRef<any>;
  public rememberme = false;
  public year = 2020;

	slideConfig2 = {
		className: 'center',
		centerMode: true,
		infinite: true,
		centerPadding: '0',
		slidesToShow: 1,
		speed: 500,
    dots: true,
    autoplay: true
	};

	constructor(
		private store: Store<AppState>,
		private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
	) {
		this.reCaptcha = GLOBAL.captcha;
		this.usuario = new User(null);
		this.cargando = false;
		this.cargo = false;
    this.captcha = '';
    const date = new Date();
    this.year = date.getFullYear();
	}

	ngOnInit() {
    let cargo = false;
		this.subscription = this.store.select('auth').subscribe(
			(state: AuthState) => {
				this.cargando = state.loading;
				this.error = state.error;
				if (state.identity && state.identity.id > 0 && this.route.snapshot.routeConfig.path === 'login' && !cargo) {
          this.router.navigate(['/auth/dashboard']);
          cargo = true;
				}
		});
		if ( (localStorage.getItem('rememberme')) === 'true') {
      const session = this.authService.prinUsuarioSession();
      if (session && session.user) {
        this.usuario.usuario = session.user;
        this.usuario.password = session.pwd;
        this.rememberme = true;
      }
    } else {
      this.usuario.usuario = '';
      this.usuario.password = '';
      this.rememberme = false;
    }
		this.addRecaptchaScript();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	getCaptcha() {
		const gResponse = grecaptcha.getResponse();
		if (gResponse) {
			this.captcha = gResponse;
		}
	}

	onSubmit() {
		const gResponse = grecaptcha.getResponse();
		if (!gResponse) {
			// console.log(gResponse);
		} else {
			this.captcha = gResponse;
			this.store.dispatch( new usuariosActions.SetAuthAction(this.usuario.usuario, this.usuario.password, this.captcha, this.rememberme) );
			grecaptcha.reset();
		}
	}

	renderReCaptch() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
			'sitekey' : this.reCaptcha,
			'callback': () => {
				if (!document.getElementById('recaptcha')) {
					return;
				}
			}
		});
	}

	addRecaptchaScript() {

		window['grecaptchaCallback'] = () => {
			this.renderReCaptch();
		}

		(function(d, s, id, obj) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { obj.renderReCaptch(); return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));

	}

}
