import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Catalogo, CatalogoSortType } from 'src/app/models/catalogo';
import { Pagination } from 'src/app/models/pagination';
import { Identity } from 'src/app/models/user';
import { CatalogosService } from 'src/app/services/catalogos.service';
import { AppState } from 'src/app/store/app.reducer';
import { AuthState } from 'src/app/store/reducers';
import * as usuariosActions from 'src/app/store/actions';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.scss']
})
export class DepartamentosComponent implements OnInit {
  heading = 'Detalles del tipo de empresas';
  subheading = 'Tipo de empresas registradas en el sistema';
  icon = 'pe-7s-notebook icon-gradient bg-dark';
  public identity: Identity = new Identity();
  private subcriptionIdentity: Subscription = new Subscription();
  private subscribeTipo: Subscription = new Subscription();
  public tipo: Catalogo = new Catalogo();
  public tipoInicial: Catalogo = new Catalogo(null);
  public departamentos: Catalogo[];
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public filterActive = false;
  public orderType = [...CatalogoSortType];
  constructor(
    private catalogosService: CatalogosService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {
    this.tipo = new Catalogo(null);
    this.paginacion.sortType = '+nombre';
    this.identity = new Identity(null);
    this.departamentos = [];
  }

  ngOnInit() {
    this.subcriptionIdentity = this.store.select('auth').subscribe(
      (state: AuthState) => {
        this.identity = {...state.identity};
      }
    );
    this.subscribeTipo = this.store.select('departamentos').subscribe(
      (state) => {
        this.departamentos = [...state.departamentos];
      }
    );
    console.log('Inicializa component Catalogo de departamentos...');
    this.store.dispatch(new usuariosActions.CargarDepartamentos());
  }

  ngOnDestroy() {
    this.subcriptionIdentity.unsubscribe();
    this.subscribeTipo.unsubscribe();
  }


  editarTipo(depto: Catalogo) {
    this.tipo = {...depto};
    this.tipoInicial = {...depto};
  }

  estatusTipo(tipo: Catalogo) {
    if (tipo.id > 0) {
      this.loading = true;
      this.catalogosService.actualizaEstatusDepartamento(tipo).subscribe(
        (response) => {
          this.store.dispatch(new usuariosActions.ActualizaDepartamentoEstatusSuccess(tipo.id));
          this.toastr.success('Actualización correctamente.', 'Exito');
          this.loading = false;
        }, (error) => {
          this.store.dispatch(new usuariosActions.ActualizaDepartamentoEstatusFail(error));
          this.loading = false;
        }
      );
    }
  }

  onSubmit(form) {
    Swal.fire({
      title: '¿Deseas registrar el departamento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.catalogosService.registraTipoEmpresa(this.tipo).subscribe(
            (respuesta) => {
              if (respuesta.tipo) {
                this.store.dispatch(new usuariosActions.ActualizaDepartamento(respuesta.tipo));
                resolve(respuesta.tipo);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.ActualizaDepartamentoFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          form.reset();
          this.tipo = new Catalogo(null);
          this.tipoInicial = new Catalogo(null);
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Departamento registrado.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  limpiar(form) {
    this.tipo = new Catalogo(null);
    this.tipoInicial = new Catalogo(null);
    form.reset();
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
  }

}
