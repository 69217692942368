import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';
import { EditarPlantilla, NuevaPlantilla } from '../models/campana';


@Injectable({
  providedIn: 'root'
})
export class PlantillasService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }
  
  guardarPlantilla(nuevaplantilla: NuevaPlantilla): Observable<any> {
    const param = JSON.stringify({nuevaplantilla});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/plantillas/nueva-plantilla', param, {headers});   
  }

  eliminarPlantilla(id: number): Observable<any> {
    const param = JSON.stringify({id});

    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/plantillas/eliminar/' + id, param,  {headers});
  }

  actualizarPlantilla(id: number, editarplantilla: EditarPlantilla): Observable<any> {
    const param = JSON.stringify({editarplantilla});
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/marketing/plantillas/editar-plantilla/' + id, param, {headers});
  }

  obtenerPlantillaAuth(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/plantilla/auth/' + id, {headers});
  }
  
  obtenerPlantillas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/marketing/plantillas', {headers});
  }

}
