//-------------------------------Nueva campana-----------------------------------
export class Campana {
  public id: number;
  public tema: string;
  public nombre_emisor: string;
  public correo_emisor: string;
  public prueba: string;
  public correo_respuesta: string;
  public dns: string;
  public texto_plano: string;
  public texto_html: string;
  public cadenaconsulta: string;
  public recipientes: number;
  public seguimiento_aperturas: boolean;
  public seguimiento_links: boolean;
  public guardarhtml: boolean;
  public estatus: number;
  public activo: boolean;
  constructor(obj: ObjCampana) {
    this.id = (obj && obj.id) ? obj.id : null;
    this.tema = (obj && obj.tema) ? obj.tema : null;
    this.nombre_emisor = (obj && obj.nombre_emisor) ? obj.nombre_emisor : null;
    this.dns = (obj && obj.dns) ? obj.dns : '';
    this.correo_emisor = (obj && obj.correo_emisor) ? obj.correo_emisor : '';
    this.prueba = (obj && obj.prueba) ? obj.prueba : null;
    this.correo_respuesta = (obj && obj.correo_respuesta) ? obj.correo_respuesta : '';
    this.texto_html = (obj && obj.texto_html) ? obj.texto_html : null;
    this.texto_plano = (obj && obj.texto_plano) ? obj.texto_plano : '';
    this.cadenaconsulta = (obj && obj.cadenaconsulta) ? obj.cadenaconsulta : null;
    this.recipientes = (obj && obj.recipientes) ? obj.recipientes : 0;
    this.seguimiento_aperturas = (obj && obj.seguimiento_aperturas) ? obj.seguimiento_aperturas : true;
    this.seguimiento_links = (obj && obj.seguimiento_links) ? obj.seguimiento_links : true;
    this.guardarhtml = (obj && obj.guardarhtml) ? obj.guardarhtml : false;
    this.estatus = (obj && obj.estatus) ? obj.estatus : 1;
    this.activo = (obj && obj.activo) ? obj.activo : false;
  }
}

interface ObjCampana {
  id: number;
  tema: string;
  nombre_emisor: string;
  correo_emisor: string;
  prueba: string;
  correo_respuesta: string;
  texto_plano: string;
  dns: string;
  texto_html: string;
  cadenaconsulta: string;
  recipientes: number;
  seguimiento_aperturas: boolean;
  seguimiento_links: boolean;
  guardarhtml: boolean;
  estatus: number;
  activo: boolean;
}

//-------------------------------Nueva plantilla-----------------------------------
export class NuevaPlantilla {
  public user_id: number;
  public app: number;
  public tema: string;
  public texto_plano: string;
  public texto_html: string;

  constructor(obj: ObjPlantilla) {
    this.user_id = (obj && obj.user_id) ? obj.user_id : 1;
    this.app = (obj && obj.app) ? obj.app : 1;
    this.tema = (obj && obj.tema) ? obj.tema : '';
    this.texto_html = (obj && obj.texto_html) ? obj.texto_html : null;
    this.texto_plano = (obj && obj.texto_plano) ? obj.texto_plano : null;

  }
}

interface ObjPlantilla {
  user_id: number;
  app: number;
  tema: string;
  texto_plano: string;
  texto_html: string;
  // fecha: Date;
}
//-------------------------------Editar plantilla-----------------------------------
export class EditarPlantilla {
  public user_id: number;
  public id: number;
  public app: number;
  public tema: string;
  public texto_plano: string;
  public texto_html: string;
  public activo: boolean;

  constructor(obj: ObjPlantilla) {
    this.user_id = (obj && obj.user_id) ? obj.user_id : 1;
    this.id = (obj && obj.id) ? obj.id : 1;
    this.app = (obj && obj.app) ? obj.app : 1;
    this.tema = (obj && obj.nombre) ? obj.nombre : '';
    this.texto_html = (obj && obj.texto_html) ? obj.texto_html : '';
    this.texto_plano = (obj && obj.texto_plano) ? obj.texto_plano : null;
    this.activo = (obj && obj.activo) ? obj.activo : false;

  }
}

interface ObjPlantilla {
  user_id: number;
  id: number;
  app: number;
  nombre: string;
  texto_plano: string;
  texto_html: string;
  activo: boolean;
  // fecha: Date;
}

//-------------------------------Filtro Campana-----------------------------------
export class FilterTypeAllCampana {
  public estatus: number;
  public nombre: string;
  public checked: boolean;
  constructor(
    obj: ObjFilterType
  ) {
    this.estatus = obj && obj.estatus ? obj.estatus : 1;
    this.nombre = obj && obj.nombre ? obj.nombre : 'Borrador';
    this.checked = obj && obj.checked ? obj.checked : false;
  }
}

interface ObjFilterType {
  estatus: number;
  nombre: string;
  checked: boolean;
}

//-------------------------------Filtro Campana-----------------------------------
export class FilterTypeCampanaStatus {
  public estatus: number;
  public nombre: string;
  public checked: boolean;
  constructor(
    obj: ObjFilterType
  ) {
    this.estatus = obj && obj.estatus ? obj.estatus : 1;
    this.nombre = obj && obj.nombre ? obj.nombre : 'Normal';
    this.checked = obj && obj.checked ? obj.checked : false;
  }
}

interface ObjFilterType {
  estatus: number;
  nombre: string;
  checked: boolean;
}

//-------------------------------estatus correos-----------------------------------
export class EstatusSuscriptor {
  public id: number;
  public nombre: string;
  public apellido: string;
  public email: string;
  public confirmado: number;
  public desuscrito: number;
  public rebotado: number;
  public spam: number;
  public estatus: string;
  constructor(
    obj: ObjEstatusSuscriptor
  ) {
    this.id = obj && obj.id ? obj.id : null;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.apellido = obj && obj.apellido ? obj.apellido : '';
    this.email = obj && obj.email ? obj.email : '';
    this.confirmado = obj && obj.confirmado ? obj.confirmado : null;
    this.desuscrito = obj && obj.desuscrito ? obj.desuscrito : null;
    this.rebotado = obj && obj.rebotado ? obj.rebotado : null;
    this.spam = obj && obj.spam ? obj.spam : null;
    this.estatus = obj && obj.estatus ? obj.estatus : 'Indefinido';
  }
}

interface ObjEstatusSuscriptor {
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  confirmado: number;
  desuscrito: number;
  rebotado: number;
  spam: number;
  estatus: string;
}

//-------------------------------reportes-----------------------------------
export class ReporteCampana {
  public id: number;
  public tema: string;
  public destinatarios: number;
  public expedido: string;
  public rebotados: number;
  public spams: number;
  public aperturas_unicas: number;
  public aperturas_totales: number;
  public clicks_unicos: number;
  public clicks_totales: number;
  public desuscritos: number;
  public estatus: number;
  public computadoras: number;
  public celulares: number;
  public computadoras_totales: number;
  public celulares_totales: number;
  constructor(
    obj: ObjReporteCampana
  ) {
    this.id = obj && obj.id ? obj.id : null;
    this.tema = obj && obj.tema ? obj.tema : null;
    this.destinatarios = obj && obj.destinatarios ? obj.destinatarios : 0;
    this.expedido = obj && obj.expedido ? obj.expedido : null;
    this.rebotados = obj && obj.rebotados ? obj.rebotados : 0;
    this.spams = obj && obj.spams ? obj.spams : 0;
    this.aperturas_unicas = obj && obj.aperturas_unicas ? obj.aperturas_unicas : 0;
    this.clicks_unicos = obj && obj.clicks_unicos ? obj.clicks_unicos : 0;
    this.clicks_totales = obj && obj.clicks_totales ? obj.clicks_totales : 0;
    this.desuscritos = obj && obj.desuscritos ? obj.desuscritos : 0;
    this.estatus = obj && obj.estatus ? obj.estatus : 1;
    this.computadoras = obj && obj.computadoras ? obj.computadoras : 0;
    this.celulares = obj && obj.celulares ? obj.celulares : 0;
    this.computadoras_totales = obj && obj.computadoras_totales ? obj.computadoras_totales : 0;
    this.celulares_totales = obj && obj.celulares_totales ? obj.celulares_totales : 0;
  }
}

interface ObjReporteCampana {
  id: number;
  tema: string;
  destinatarios: number;
  expedido: string;
  aperturas_unicas: number;
  rebotados: number;
  spams: number;
  clicks_unicos: number;
  clicks_totales: number;
  desuscritos: number;
  estatus: number;
  computadoras: number;
  celulares: number;
  computadoras_totales: number;
  celulares_totales: number;
}

export class LinkCampana {
  url: string;
  clickstotales: number;
  clicksunicos: number;
  constructor(
    obj: ObjLinkCampana
  ) {
    this.url = obj && obj.url ? obj.url : '';
    this.clickstotales = obj && obj.clickstotales ? obj.clickstotales : 0;
    this.clicksunicos = obj && obj.clicksunicos ? obj.clicksunicos : 0;
  }
}

interface ObjLinkCampana {
  url: string;
  clickstotales: number;
  clicksunicos: number;
}
