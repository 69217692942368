import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, User } from 'src/app/models/user';
import { Subscription, Subject } from 'rxjs';
import { Contacto, ContactoSortType } from 'src/app/models/contacto';
import { Pagination } from 'src/app/models/pagination';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { ContactosService } from 'src/app/services/contactos.service';
import {ExcelService} from 'src/app/services/excel.service';
import { FilterType } from '../../../models/user';
import { AnyScopePipe } from '../../../pipes/any-scope.pipe';
import { MultiOpcionesSelectedPipe } from '../../../pipes/multi-opciones-selected.pipe';
import { FiltroCheck, OptionCheckMultiplePipe } from '../../../pipes/option-check-multiple.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment.prod';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';


@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.sass']
})
export class ContactosComponent implements OnInit, OnDestroy {
  heading = 'Detalles de los contactos';
  subheading = 'Información registrada en el sistema';
  icon = 'pe-7s-id icon-gradient bg-dark';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeContacto: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public identity: Identity;
  public title = 'Contactos';
  public contactos: Contacto[] = [];
  public loading = false;
  public url: string;
  public orderType = [...ContactoSortType];
  public paginacion = new Pagination({sortType: '-fecModificacion', optionSize: [
    { value: 10, description: '10 elementos por hoja' },
    { value: 20, description: '20 elementos por hoja' },
    { value: 50, description: '50 elementos por hoja' }
  ]});
  public filterActive = false;
  public version = 1;
  public listSearch: string[] = [];
  public duplicados:{contacto: Contacto, duplicados: Contacto[]} = {contacto: new Contacto(null), duplicados: []};
  public opcDuplicados: Boolean = false;
  public filterOrigenContacto: FilterType[] = [];
  public filterEmpresas: FilterType[] = [];
  public filterPropietarios: FilterType[] = [];
  public filterEtapas: FilterType[] = [];
  public filterPaises: FilterType[] = [];
  public filterEstatus: FilterType[] = [];

  public filterOrigenContactoCheck: FilterType[] = [];
  public filterEstatusCheck: FilterType[] = [];
  public filterEtapasCheck: FilterType[] = [];
  public filterPaisesCheck: FilterType[] = [];
  public filterPropietariosCheck: FilterType[] = [];



  public sepomexFilter: FiltroCheck[] = [
    { value: false, text: 'Sin envío', parametro: 'opcSepomex', checked: false},
    { value: true, text: 'Con envío', parametro: 'opcSepomex', checked: false},
    { value: false, text: 'Gratuito', parametro: 'opcPago', checked: false},
    { value: true, text: 'Pago', parametro: 'opcPago', checked: false}
  ];
  public downloading = false;
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nombre',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: 'Buscar',
    noDataAvailablePlaceholderText: 'Sin datos'
  };
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private excelService: ExcelService,
    private contactosService: ContactosService,
    private modalService: NgbModal,
    private downloadService: DownloadFileService,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.title);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.contactos = [];
    this.duplicados = {contacto: new Contacto(null), duplicados: []};
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeContacto = this.store.select('contacto').subscribe(
      (state) => {
        this.contactos = [...state.contactos.map(itm => {
          this.duplicados.duplicados.forEach(element => {
            if (itm.id === element.id) {
              itm.check = true;
            }
          });
          return itm;
        })];
        this.paginacion.totalSize = state.total;
        this.paginacion.totalFiltro = state.totalFiltro;
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.filterEtapasCheck = [...state.etapasContacto.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterEstatusCheck = [...state.estadosContacto.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterOrigenContactoCheck = [...state.origenContacto.filter( item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterPropietariosCheck = [...state.usuarios.map(item => {
          return new FilterType({id: item.id, nombre: item.descripcion, checked: false});
        })];
        this.filterPaisesCheck = [...state.paises.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
      }
    );
    console.log('Inicializa el componente Contacto ...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeContacto.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  inicializa() {
    // this.store.dispatch(new usuariosActions.CargarContactos());
    this.store.dispatch(new usuariosActions.ConsultaTipoEmpresas());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaEstadosContactos());
    this.store.dispatch(new usuariosActions.ConsultaEtapasContactos());
    this.store.dispatch(new usuariosActions.ConsultaOrigenContactos());
    this.store.dispatch(new usuariosActions.ConsultaPaises());
    this.cargarContactos();
  }

  consultaDetalle(contacto: Contacto) {
    if (contacto.id && contacto.id > 0) {
      this.router.navigate(['/contacto/register/', contacto.id]);
    }
  }

  sort(descriptionSort: string) {
    if (descriptionSort === this.paginacion.sortType) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
    } else {
      this.paginacion.sortReverse = false;
    }
    this.paginacion.sortType = descriptionSort;
  }

  eliminarContacto(contacto: Contacto){
    Swal.fire({
      title: '¿Deseas eliminar el contacto?',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_SUCCESS,
      cancelButtonColor: MESSAGES.C_BTN_CANCEL,
      confirmButtonText: MESSAGES.M_BTN_ACEPTAR,
      cancelButtonText: MESSAGES.M_BTN_CANCEL,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm:  ()  => {
        return new Promise( (resolve)  => {
          this.contactosService.eliminarContacto(contacto).subscribe(
            (response) => {
              if (response.respond) {
                this.store.dispatch(new usuariosActions.EliminarContactoSuccess(contacto.id));
                resolve(true);
              } else {
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(false);
              }
            }, (error) => {
              this.store.dispatch(new usuariosActions.EliminarContactoFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      },
      allowOutsideClick: false
    }).then(
      (result) => {
        if (result.value) {
          Swal.fire({
            title: MESSAGES.M_TIT_CONFIRM,
            text: 'Contacto eliminado correctamente.',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, () => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () => {}
        }).then( () => {},  () => {});
      }
    );
  }

  printCSV() {
    let excel;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    const pipeMultiOpcionesSelect = new MultiOpcionesSelectedPipe();
    const pipeOptionCheckMultiple = new OptionCheckMultiplePipe();
    let lista = [...this.contactos];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterOrigenContacto, parametro: 'tipoOrigen'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterPaises, parametro: 'pais'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterEtapas, parametro: 'etapa'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterPropietarios, parametro: 'propietario'});
    lista = pipeMultiOpcionesSelect.transform(lista, { opciones: this.filterEstatus, parametro: 'estado'});
    lista = pipeOptionCheckMultiple.transform(lista, this.sepomexFilter);
    if (lista) {
      excel = lista.map( item => {
        const obj: any = {};
        obj.NOMBRE = item.nombre;
        obj.APELLIDO = item.apellido;
        obj.PROPIETARIO = item.desPropietario;
        obj.EMAIL = item.emails;
        obj.CARGO = item.cargo;
        obj.TELEFONO = item.telefono;
        obj.CELULAR = item.celular;
        obj.ORIGEN_CONTACTO = item.desTipoOrigen;
        obj.ETAPA = item.desEtapa;
        obj.ESTATUS = item.desEstado;
        obj.SEPOMEX = item.opcSepomex;
        obj.SEPOMEX_PAGA = item.opcPago ?  'Sí' : '';
        obj.ESTADO = item.desEstadoDireccion;
        obj.MUNICIPIO = item.desMunicipio;
        obj.COLONIA = item.colonia;
        obj.CALLE = item.calle;
        obj.EMPRESA = item.desEmpresa;
        return obj;
      });
      this.excelService.exportAsExcelFile(excel, 'contactos_' + i);
    }
  }

  downloadExcel() {
    this.downloading = true;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    const opciones: any = {};
    opciones.numPage = 0;
    opciones.numLimit = null;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desOrigen = this.filterOrigenContacto.map( itm => itm.id).join(',');
    opciones.desEstado = this.filterEstatus.map( itm => itm.id).join(',');
    opciones.desEtapa = this.filterEtapas.map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.map( itm => itm.id).join(',');
    opciones.desEmpresa = this.filterEmpresas.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPropietario = this.filterPropietarios.map( itm => itm.id).join(',');
    this.contactosService.downloadExcelContactos(opciones).subscribe( respuesta => {
    // this.downloadService.downloadExcel(this.url + '/contactos/excel', opciones, 'contactos_' + i + '.xls').subscribe( respuesta => {
      // console.log(respuesta);
      // const blob = new Blob([respuesta.blob()], { type : 'application/vnd.ms.excel' });
      // const file = new File([blob], 'excel.xlsx', { type: 'application/vnd.ms.excel' });
      // saveAs(file);
      this.excelService.exportAsExcelFile(respuesta.contactos, 'contactos_' + i);
      this.downloading = false;
      // this.downloading = true;
      // // this.downloadState = respuesta;
      // if (respuesta.state === 'DONE') {
      //   setTimeout( () => {
      //     this.downloading = false;
      //   }, 2000 );
      // }
    }, error => {
      console.log('esto');
      this.downloading = false;
    });
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarContactos();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarContactos();
  }

  changeSepomex() {
    this.sepomexFilter = [...this.sepomexFilter];
    this.cargarContactos();
  }

  changeEmpresas() {
    this.filterEmpresas = [...this.filterEmpresas];
    this.cargarContactos();
  }



  contactosDuplicados(contacto: Contacto) {
    this.duplicados.contacto = new Contacto(null);
    const item = this.duplicados.duplicados.find( element => element.id === contacto.id);
    if (item && item.id && item.id > 0) {
      this.duplicados.duplicados = this.duplicados.duplicados.filter( item => item.id !== contacto.id );
    } else {
      this.duplicados.duplicados.push({...contacto});
    }
  }

  cancelarDuplicados() {
    this.duplicados = {contacto: new Contacto(null), duplicados: []};
    this.opcDuplicados = false;
    this.contactos.map( item => {
      item.check = false;
      return item;
    });
  }

  open(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  contactoDuplicado(contacto: Contacto) {
    this.duplicados.contacto = {...contacto};
  }

  guardarDuplicados() {
    const duplicados =  [...this.duplicados.duplicados.filter(item => item.id !== this.duplicados.contacto.id)];
    this.modalService.dismissAll('Close');
    this.store.dispatch(new usuariosActions.ActualizaContactosDuplicados( this.duplicados.contacto, duplicados ));
    this.opcDuplicados = false;
    this.duplicados = { contacto: new Contacto(null), duplicados: [] };
  }

  cargarContactos(reset = true) {
    setTimeout( () => {
      if (reset) {
        this.paginacion.page = 1;
      }
      const opciones: any = {};
      this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
      opciones.numPage = this.paginacion.page;
      opciones.numLimit = this.paginacion.pageSize;
      opciones.desOrderBy = this.paginacion.sortType;
      opciones.textSearch = this.listSearch.join('%');
      opciones.desOrigen = this.filterOrigenContacto.map( itm => itm.id).join(',');
      opciones.desEstado = this.filterEstatus.map( itm => itm.id).join(',');
      opciones.desEtapa = this.filterEtapas.map( itm => itm.id).join(',');
      opciones.desPais = this.filterPaises.map( itm => itm.id).join(',');
      opciones.desEmpresa = this.filterEmpresas.filter( itm => itm.checked).map( itm => itm.id).join(',');
      opciones.desPropietario = this.filterPropietarios.map( itm => itm.id).join(',');
      this.store.dispatch(new usuariosActions.CargarContactos2(opciones));
    }, 500);
  }
  
  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchContactos").focus();
  }

  closeSearch() {
    this.paginacion.isActive = false;
    this.paginacion.textSearch = '';
    this.listSearch = [];
    this.cargarContactos();
  }

  quitarDuplicado(contacto: Contacto) {
    this.duplicados.duplicados = this.duplicados.duplicados.filter( item => item.id !== contacto.id);
  }

}
