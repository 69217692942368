import { Permisos } from './empresa';
import { SortOrder } from './sort';
import { UserType } from './user';


export class ContactoAutoActualizacion{
	public id: number;
	public imagen: string;
	public email: string;
	public nombre: string;
	public apellido: string;
	public cargo: string;
	public telefono: string;
	public extension: string;
	public celular: string;
	public tipoEmpresa: number;
	public desTipoEmpresa: string;
	public especificacion: boolean;
	public desEspecificacion: string;
	public etapa: number;
	public desEtapa: string;
	public estado: number;
	public desEstado: string;
	public propietario: number;
	public desPropietario: string;
	public empresa: number;
	public desEmpresa: string;
	public opcSepomex: boolean;
	public status: boolean;
	public pais: number;
	public desPais: string;
	public codigoPostal: string;
	public municipio: number;
	public desMunicipio: string;
	public estadoDireccion: number;
	public desEstadoDireccion: string;
	public colonia: string;
	public calleynumero: string;
  public opcPago: boolean;
  public fecRegistro: Date;
	public fecModificacion: Date;
	public cumpleanios: Date;
	public paginaWeb: string;
	public empresaPublicidad: string;
	public check: boolean;
	public fecAutoActualizacion: Date;
  public fundacion: Date;
	public ciudad: string;
	public industria: number;
	public desIndustria: string;
	public permisos: Permisos;

	constructor(
		obj?: DataObjContacto
	){
		this.id = obj && obj.id ? obj.id : 0;
		this.imagen = obj && obj.imagen ? obj.imagen : '';
		this.email = obj && obj.email ? obj.email : '';
		this.nombre = obj && obj.nombre ? obj.nombre: '';
		this.apellido = obj && obj.apellido ? obj.apellido : '';
		this.cargo = obj && obj.cargo ? obj.cargo: '';
		this.telefono = obj && obj.telefono ? obj.telefono: '';
		this.extension = obj && obj.extension ? obj.extension: null;
		this.celular = obj && obj.celular ? obj.celular : '';
		this.tipoEmpresa = obj && obj.tipoEmpresa ? obj.tipoEmpresa : null;
		this.desTipoEmpresa = obj && obj.desTipoEmpresa ? obj.desTipoEmpresa : '';
		this.especificacion = obj && obj.especificacion ? obj.especificacion : false;
		this.desEspecificacion = obj && obj.desEspecificacion ? obj.desEspecificacion : '';
		this.etapa = obj && obj.etapa ? obj.etapa : null;
		this.desEtapa = obj && obj.desEtapa ? obj.desEtapa: '';
		this.estado = obj && obj.estado ? obj.estado : null;
		this.desEstado = obj && obj.desEstado ? obj.desEstado: '';
		this.propietario = obj && obj.propietario ? obj.propietario: null;
		this.desPropietario = obj && obj.desPropietario ? obj.desPropietario: '';
		this.empresa = obj && obj.empresa ? obj.empresa: null;
		this.desEmpresa = obj && obj.desEmpresa ? obj.desEmpresa : '';
		this.opcSepomex = obj && obj.opcSepomex ? obj.opcSepomex : false;
		this.status = obj && obj.status ? obj.status : false;
		this.pais = obj && obj.pais ? obj.pais : null;
		this.desPais = obj && obj.desPais ? obj.desPais: '';
		this.codigoPostal = obj && obj.codigoPostal ? obj.codigoPostal: null;
		this.municipio = obj && obj.municipio ? obj.municipio: null;
		this.desMunicipio = obj && obj.desMunicipio ? obj.desMunicipio: '';
		this.estadoDireccion = obj && obj.estadoDireccion ? obj.estadoDireccion: null;
		this.desEstadoDireccion = obj && obj.desEstadoDireccion ? obj.desEstadoDireccion : '';
		this.colonia = obj && obj.colonia ? obj.colonia : '';
		this.calleynumero = obj && obj.calleynumero ? obj.calleynumero : '';
		this.opcPago = obj && obj.opcPago ? obj.opcPago : false;
		this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
		this.fecModificacion = obj && obj.fecModificacion ? obj.fecModificacion : null;
		this.cumpleanios = obj && obj.cumpleanios ? obj.cumpleanios : null;
		this.paginaWeb = obj && obj.paginaWeb ? obj.paginaWeb : null;
		this.empresaPublicidad = obj && obj.empresaPublicidad ? obj.empresaPublicidad : null;
		this.check = obj && obj.check ? obj.check : false;
		this.fecAutoActualizacion = obj && obj.fecAutoActualizacion ? obj.fecAutoActualizacion : null;
    this.fundacion = obj && obj.fundacion ? obj.fundacion : null;
		this.ciudad = obj && obj.ciudad ? obj.ciudad : '';
		this.industria = obj && obj.industria ? obj.industria: null;
		this.desIndustria = obj && obj.desIndustria ? obj.desIndustria : '';
		this.permisos = obj && obj.permisos ? obj.permisos : new Permisos(null);

  }
}

interface DataObjContacto{
	id?: number;
	imagen?: string;
	email?: string;
	nombre?: string;
	apellido?: string;
	cargo?: string;
	telefono?: string;
	extension?: string;
	celular?: string;
	tipoEmpresa?: number;
	desTipoEmpresa?: string;
	especificacion?: boolean;
	desEspecificacion?: string;
	etapa?: number;
	desEtapa?: string;
	estado?: number;
	desEstado?: string;
	propietario?: number;
	desPropietario?: string;
	empresa?: number;
	desEmpresa?: string;
	opcSepomex?: boolean;
	status?: boolean;
	pais?: number;
	desPais?: string;
	codigoPostal?: string;
	municipio?: number;
	desMunicipio?: string;
	estadoDireccion?: number;
	desEstadoDireccion?: string;
	colonia?: string;
	calleynumero?: string;
	opcPago?: boolean;
	fecRegistro?: Date;
	fecModificacion?: Date;
	cumpleanios?: Date;
	paginaWeb?: string;
	empresaPublicidad?: string;
	check?: boolean;
	fecAutoActualizacion?: Date;
  fundacion?: Date;
	ciudad?: string;
	industria?: number;
	desIndustria?: string;
	permisos?: Permisos;

}

export class ReporteAutoActualizacion{
	public id: number;
	public email: string;
	public contacto: string;
	public desEmail: string;
	public spam: string;
	public rebotado: string;
	public nombres: string;
	public apellido: string;
	public fecRegistro: Date;
	public fecExito: Date;
	constructor(
		obj?: DataObjReporteAutoActualizacion
	){
		this.id = obj && obj.id ? obj.id : null;
		this.email = obj && obj.email ? obj.email : null;
		this.contacto = obj && obj.contacto ? obj.contacto : null;
		this.desEmail = obj && obj.desEmail ? obj.desEmail : null;
		this.spam = obj && obj.spam ? obj.spam : null;
		this.rebotado = obj && obj.rebotado ? obj.rebotado : null;
		this.nombres = obj && obj.nombres ? obj.nombres : null;
		this.apellido = obj && obj.apellido ? obj.apellido : null;
		this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : null;
		this.fecExito = obj && obj.fecExito ? obj.fecExito : null;
  }
}

interface DataObjReporteAutoActualizacion{
	id?: number;
	email?: string;
	contacto?: string;
	desEmail?: string;
	spam?: string;
	rebotado?: string;
	nombres?: string;
	apellido?: string;
	fecRegistro?: Date;
	fecExito?: Date;
}

export const ReporteAutoActualizacionSortType: SortOrder[] = [
	{ id: '+id', text: 'ID'},
	{ id: '-id', text: 'ID desc'},
	{ id: '+email', text: 'Email'},
	{ id: '-email', text: 'Email desc'},
	{ id: '+nombre', text: 'Nombres'},
	{ id: '-nombre', text: 'Nombres desc'},
	{ id: '+fecExito', text: 'Fecha de actualizó'},
	{ id: '-fecExito', text: 'Fecha de actualizó desc'}
];



export const StatusActualizacionFilter: UserType[] = [
  { id: 1, nombre: 'Spam', descripcion:'spam', checked: false },
  { id: 2, nombre: 'Rebotado', descripcion:'rebotado', checked: false },
  { id: 3, nombre: 'Actualizó', descripcion:'actualizo', checked: false },
  { id: 4, nombre: 'No ha actualizado', descripcion:'noactualizado', checked: false }
];