import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { GLOBAL } from 'src/app/models/global';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { Actividad } from 'src/app/models/actividad';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dots-actividades-pendientes',
  templateUrl: './dots-actividades-pendientes.component.html'
})
export class DotsActividadesPendientesComponent implements OnInit, OnDestroy {
  public identity: Identity;
  public token: string;
  public url: string;
  public loading: boolean;
  public actividades: Actividad[] = [];
  public otro: any;
  public afList: any;
  public listObservable: any;
  public changeNotification: string;
  public name: any;
  public msgVal = '';
  public count = 0;
  private subscriptionAuth: Subscription = new Subscription();
  private subscriptionDashboard: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { 
    this.identity = new Identity(null);
    this.url = GLOBAL.url;
    this.actividades = [];
    this.loading = false;
  }

  ngOnInit(): void {
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscriptionDashboard = this.store.select('dashboard').subscribe(
      (state) => {
        this.actividades = [...state.actividades];
      }
    );
    console.log('Inicializa componente Header');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscriptionAuth.unsubscribe();
    this.subscriptionDashboard.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.CargarDashboardActividadesPendientesDia());
  }

  routerUrl(actividad: Actividad) {
    if (actividad.empresa){
      let url = '/auth/empresas/profile/' + actividad.empresa;
      this.router.navigate([url]);
    }else if(actividad.contactos.length){
      let url = '/auth/contactos/profile/' + actividad.contactos.shift().id;
      this.router.navigate([url]);
    }else if(actividad.oportunidades.length){
      let url = '/auth/oportunidades/profile/' + actividad.oportunidades.shift().id;
      this.router.navigate([url]);
    }
  }

}
