import {Routes} from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { RestoreComponent } from './restore/restore.component';
import { NotFoundComponent } from 'src/app/layout/components/not-found/not-found.component';

export const publicRoutes: Routes = [
  { path: '', component: LoginComponent, data: { extraParameter: ''} },
  { path: 'login', component: LoginComponent, data: { extraParameter: ''} },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { extraParameter: ''} },
  { path: 'recovery/key/:id', component: RecoveryComponent, data: { extraParameter: 'pagesMenu'} },
  { path: 'restore', component: RestoreComponent, data: { extraParameter: 'pagesMenu'} },
  { path: '**', component: LoginComponent }
];
