<div class="main-card mb-3 card card-shadow-primary">
	<div class="card-header with-border">
		<div class="card-header-title">Referencias</div>
	</div>
	<div class="card-body">

		<div class="v-options-menu vsm-default">
			<div class="vsm-list">
				<ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
					<ngb-panel class="p-0">
						<ng-template ngbPanelHeader>
							<button ngbPanelToggle class="vsm-link">
								<span class="vsm-title">Empresa</span>
								<i class="vsm-arrow"></i>
							</button>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="vsm-dropdown">
								<div class="vsm-list">
									<div class="vsm-item">
										<a class="vsm-link" [routerLink]="['/auth/empresas/profile/' + oportunidad.empresa]">
											<span class="vsm-title">
												<div class="widget-content p-0">
													<div class="widget-content-wrapper line-clamp">
														<div class="widget-content-left" [title]="oportunidad.desEmpresa">
															<div class="widget-heading">{{oportunidad.desEmpresa}}</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
								</div>
							</div>
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
		<div class="v-options-menu vsm-default">
			<div class="vsm-list">
				<!-- <div class="vsm-header">Contactos</div> -->
				<ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
					<ngb-panel class="p-0">
						<ng-template ngbPanelHeader>
							<button ngbPanelToggle class="vsm-link">
								<span class="vsm-title">Contactos ({{contactos.length}})</span>
								<i class="vsm-arrow"></i>
							</button>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="vsm-dropdown">
								<div class="vsm-list">
									<div class="vsm-item" *ngFor="let item of contactos">
										<a class="vsm-link" [routerLink]="['/auth/contactos/profile/' + item.id]">
											<span class="vsm-title">
												<div class="widget-content p-0">
													<div class="widget-content-wrapper line-clamp">
														<div class="widget-content-left" [title]="item.nombre + ' ' + item.apellido">
															<!-- <div class="widget-heading"> <i class="far fa-envelope" *ngIf="item.opcSepomex"></i> {{item.nombre}}</div> -->
															<div class="widget-heading"> 
																<i class="far fa-envelope" *ngIf="item.opcSepomex"></i> 
																<i class="far fa-money-bill-alt pl-1" *ngIf="item.opcPago"></i> 
																{{item.nombre}} {{item.apellido}}
															</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
								</div>
							</div>
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
		<div class="v-options-menu vsm-default">
			<div class="vsm-list">
				<!-- <div class="vsm-header">Contactos</div> -->
				<ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
					<ngb-panel class="p-0">
						<ng-template ngbPanelHeader>
							<button ngbPanelToggle class="vsm-link">
								<span class="vsm-title">Archivos adjuntos ({{archivos.length}})</span>
								<i class="vsm-arrow"></i>
							</button>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="vsm-dropdown">
								<div class="text-right">
                  <button type="button" (click)="open(content)"  class="mb-0 border-0 btn-transition btn btn-outline-primary">
										Agregar
									</button>
                </div>
								<div class="vsm-list">
									<div class="vsm-item" *ngFor="let item of archivos">
										<a class="vsm-link" [href]="url + '/archivos/descargar/' + item.nombre + '/formato/' + item.tipo + '/empresa/' + id + '/tipo/' + concepto"> 
											<span class="vsm-title">
												<div class="widget-content p-0">
													<div class="widget-content-wrapper line-clamp">
														<div class="widget-content-left" [title]="item.nombre + '.' + item.tipo">
															<div class="widget-heading" >{{item.nombre}}.{{item.tipo}}</div>
														</div>
													</div>
												</div>
											</span>
										</a>
									</div>
								</div>
							</div>
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
	</div>
</div>
<!-- <div class="col-sm-12 ">
	<div class="main-card card">
		<div class="card-header">
			<div class="card-header-title">Registro de anexos</div>
		</div>
		<div class="card-body">
			<div class="form-group">
				<div class="row">
					<div class="col-sm-2 col-md-2 col-lg-2">
						<label for="nombreAnexo" class="control-label pull-right">Nombre</label>
					</div>
					<div class="col-sm-10 col-md-10 col-lg-10">
						<input type="text" class="form-control" id="nombreAnexo" name="nombreAnexo" #nombreAnexo="ngModel" [(ngModel)]="adjunto.nombre">
					</div>
				</div>
			</div>
			<div class="form-group">
				<div class="row">
					<div class="col-sm-2 col-md-2 col-lg-2">
						<label for="fileAnexo" class="control-label pull-right">Subir archivo</label>
					</div>
					<div class="col-sm-10 col-md-10 col-lg-10">
						<div class="box box-file">
							<input type="file" id="fileAnexo" name="fileAdjunto" #fileAnexo="ngModel" [(ngModel)]="textFile" class="inputfile avatar-file" (change)="fileChangeEvent($event)" accept="image/jpeg,image/gif,image/png,application/pdf" title="Seleccione un archivo tipo imagen o pdf como evidencia." data-max-size="2048"/>
							<label for="fileAnexo"><span class="fa fa-file"></span><span *ngIf="!textFile"> Elige un archivo&hellip;</span><span *ngIf="textFile"> {{textFile}}</span></label>
						</div>
					</div>
				</div>
			</div>
			<button type="button" class="btn btn-primary btn-flat pull-right" (click)="addFile()" [disabled]="!adjunto.nombre || !adjunto.tipo ||  !identity.permisos.actividadesRegEdit">
				<span class="fa fa-save"></span> Agregar archivo
			</button>
		</div>
	</div>
</div> -->


<!-- <button type="button" class="mr-2 btn-icon btn-icon-only btn btn-outline-danger" (click)="eliminarArchivo()">
	<i class="pe-7s-trash btn-icon-wrapper"> </i>
	<span class="sr-only">Eliminar</span>
</button> -->


<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Registro de archivo adjunto</h4>
  </div>
  <div class="modal-body">
    <form #registerOportunidadActionForm="ngForm" class="col-lg-12">
			<fieldset class="form-group required">
				<div class="row">
					<div class="col-sm-4">
						<label for="nombreArchivo" class="control-label">Nombre</label>
					</div>
					<div class="col-sm-8">
						<input type="text" class="form-control" id="nombreArchivo" name="nombreArchivo" #nombreArchivo="ngModel" [(ngModel)]="adjunto.nombre" required/>
						<!-- <span *ngIf="!nombreArchivo.valid && nombreArchivo.touched"> El nombre es obligatorio</span> -->
					</div>
				</div>
			</fieldset>
			<fieldset class="form-group required">
				<div class="row">
					<div class="col-sm-4">
						<label for="fileArchivo" class="control-label">Subir archivo</label>
					</div>
					<div class="col-sm-8">
						<div class="box box-file">
							<input type="file" id="fileArchivo" name="fileAdjunto" #fileArchivo="ngModel" [(ngModel)]="textFile" class="inputfile avatar-file" (change)="fileChangeEvent($event)" accept="image/jpeg,image/gif,image/png,application/pdf" title="Seleccione un archivo tipo imagen o pdf como evidencia." data-max-size="2048"/>
							<label for="fileArchivo"><span class="fa fa-file"></span><span *ngIf="!textFile"> Elige un archivo&hellip;</span><span *ngIf="textFile"> {{textFile}}</span></label>
						</div>
						<span *ngIf="!fileArchivo.valid && fileArchivo.touched"> El archivo es obligatorio</span>
					</div>
				</div>
			</fieldset>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cerrar</button>
		<button type="button" class="btn btn-primary"  (click)="addFile()" [disabled]="!registerOportunidadActionForm.form.valid || !adjunto.tipo || !identity.permisos.actividadesRegEdit">Guardar cambios</button>
  </div>
</ng-template>