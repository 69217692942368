
import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
  usuarios: reducers.UsuariosState;
  usuario: reducers.UsuarioState;
  auth: reducers.AuthState;
  ui: reducers.UiState;
  catalogos: reducers.TiposState;
  toastr: reducers.ToastrState;
  notificacion: reducers.NotificacionesState;
  notificacionMini: reducers.NotificacionesMiniState;
  dashboard: reducers.DashboardState;
  direccion: reducers.DireccionState;
  archivos: reducers.ArchivoGeneralState;
  contacto: reducers.ContactoState;
  empresa: reducers.EmpresaState;
  industria: reducers.IndustriaState;
  tipoEmpresa: reducers.TipoEmpresaState;
  consulta: reducers.ConsutaState;
  actividadEmpresa: reducers.ActividadesEmpresaState;
  oportunidad: reducers.OportunidadState;
  actividadArchivos: reducers.ActividadesArchivosState;
  origenContacto: reducers.OrigenContactoState;
  pais: reducers.PaisesState;
  galeria: reducers.GaleriaRepositorioState;
  kpiVendedor: reducers.KPIsVendedoresState;
  reporteKpiVendedor: reducers.ReporteKPIsVendedoresState;
  reportes: reducers.ReporteState;
  ticket: reducers.TicketState;
  departamentos: reducers.DepartamentoState;
}

export const appReducers: ActionReducerMap<AppState> = {
  usuarios: reducers.usuariosReducer,
  usuario: reducers.usuarioReducer,
  auth: reducers.authReducer,
  ui: reducers.uiReducer,
  catalogos: reducers.catalogosReducer,
  toastr: reducers.toastrReducer,
  notificacion: reducers.notificacionesReducer,
  notificacionMini: reducers.notificacionesMiniReducer,
  dashboard: reducers.dashboardReducer,
  direccion: reducers.direccionReducer,
  archivos: reducers.archivosGeneralesReducer,
  contacto: reducers.contactoReducer,
  empresa: reducers.empresaReducer,
  industria: reducers.industriaReducer,
  tipoEmpresa: reducers.tipoEmpresaReducer,
  consulta: reducers.consultasReducer,
  actividadEmpresa: reducers.actividadesEmpresaReducer,
  oportunidad: reducers.oportunidadReducer,
  actividadArchivos: reducers.actividadesArchivosReducer,
  origenContacto: reducers.origenContactoReducer,
  pais: reducers.paisEmpresaReducer,
  galeria: reducers.galeriaRepositorioReducer,
  kpiVendedor: reducers.kpisVendedoresReducer,
  reporteKpiVendedor: reducers.reporteKpisVendedoresReducer,
  ticket: reducers.ticketReducer,
  reportes: reducers.reportesReducer,
  departamentos: reducers.departamentosReducer
};
