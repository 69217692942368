import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../models/global';
import { Observable } from 'rxjs';
import { Oportunidad } from 'src/app/models/oportunidades';

@Injectable({
  providedIn: 'root'
})
export class OportunidadesService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerOportunidades(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/oportunidades', {headers});
  }

  actualizaEstatusOportunidad(oportunidad: Oportunidad): Observable<any> {
    const param = JSON.stringify({oportunidad});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/oportunidades/estatus', param, {headers});
  }

  registraOportunidad(oportunidad: Oportunidad): Observable<any> {
    const param = JSON.stringify({oportunidad});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/oportunidades', param, {headers});
  }


  obtenerDatoOportunidad(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/oportunidades/' + id, {headers});
  }

  actualizaEtapaOportunidad(oportunidad: Oportunidad): Observable<any> {
    const param = JSON.stringify({oportunidad});

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.url + '/oportunidades/etapa', param, {headers});
  }


}
