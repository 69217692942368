<div [class]="'app-header header-shadow text-lighter bg-night-sky'">
  <div class="logo-src mr-5 p-5"></div>
  <div class="app-header__content">
    <div class="app-header-left mr-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : !globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <div class="justify-content d-flex">
        <img src="assets/images/site_logo-original.png" style="width: 250px; height: auto;" class="mr-auto ml-3">
      </div>
    </div>
    <div class="justify-content d-flex">
      <img src="assets/images/site_logo-4.png" style="width: 185px; height: auto;" class="mr-auto ml-3">
    </div>
    <div class="app-header-right">
      <div class="justify-content d-flex">
        <img src="assets/images/sea-media-2.png" style="width: 100px; height: auto;" class="ml-auto mr-5">
      </div>
      <app-dots-actividades-pendientes></app-dots-actividades-pendientes>
      <app-dots></app-dots>
      <app-user-box></app-user-box>
      <!-- <div class="d-flex">
        <div class="header-btn-lg pr-0">
          <div class="header-dots">
            <h2 class="title-salt">CRM</h2>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <div class="justify-content d-flex">
      <img src="assets/images/site_logo.png" style="width: 185px;" class="mr-auto ml-3">
    </div>
  </div>
</div>
