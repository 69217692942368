import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GaleriaRepositorio } from 'src/app/models/galeria-repositorio';
import { GaleriaRepositorioService } from '../../../../services/galeria-repositorio.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Identity } from '../../../../models/user';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';
import * as usuariosActions from 'src/app/store/actions';
import { Album } from '../../../../models/album';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-edit-repositorio',
  templateUrl: './edit-repositorio.component.html',
  styleUrls: ['./edit-repositorio.component.scss']
})
export class EditRepositorioComponent implements OnInit, OnDestroy {
  public files: GaleriaRepositorio[] = [];
  public title = 'Actualizacion ';
  progress = 0;
  etiquetas = '';
  toggleMobileSidebar: any;
  public album: Album = new Album(null);
  public identity: Identity = new Identity(null);
  private subscripcionAuth: Subscription = new Subscription();
  private subscripcionGaleria: Subscription = new Subscription();

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  constructor(
    private archivosService: GaleriaRepositorioService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.title + 'Actualización de repositorio editorial');
  }

  ngOnInit() {
    this.subscripcionAuth = this.store.select('auth').subscribe( state => {
      this.identity = state.identity;
    });
    this.subscripcionGaleria = this.store.select('galeria').subscribe( state => {
      this.album = state.album;
    });
    this.route.params.subscribe(params => {
      const userId = params.id;
      if (userId > 0) {
        this.store.dispatch(new usuariosActions.CargarGaleriaRepositorioAlbum(userId));
      } else {
        this.album = new Album(null);
      }
    });
  }

  ngOnDestroy() {
    this.subscripcionAuth.unsubscribe();
    this.subscripcionGaleria.unsubscribe();
  }

  uploadFile(event: FileList) {
    let archivos: File[] = Array.from(event);
    console.log(archivos);
    if (archivos && archivos.length) {
      let validaImage = true;
      archivos = archivos.filter(element => {
        const mimeType = element.type;
        if (mimeType.match(/image\/*/) == null && mimeType.match(/video\/*/) == null) {
          validaImage = false;
          return false;
        } else {
          return true;
        }
      });
      const gr = archivos.map( (item) => {
        const otro = new GaleriaRepositorio(null);
        const reader = new FileReader();
        reader.readAsDataURL(item);
        if(item.type.split('/')[0] !== 'video'){
          reader.onload = (_event) => {
            otro.urlPath = reader.result;
          }
        }
       
        otro.type = item.type.split('/')[1];
        otro.size = item.size;
        otro.name = item.name;
        otro.file = item;
        otro.fecArchivo = new Date(item.lastModified);
        otro.etiquetas = '' + this.etiquetas;
        return otro;
      });
      this.files = [ ...gr, ...this.files ];
      if (!validaImage) {
        this.toastr.warning('Solo acepta archivos de imagen.');
      }
    }
    console.log(this.files);
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  validaImagenes() {
    return new Promise( (resolve, reject) => {
      let valida = false;
      if (!this.album.nombre) {
        this.toastr.warning('Captura el nombre del album.');
      } else if (!this.files.length) {
        this.toastr.warning('Agrega al menos una imagen al album.');
      } else {
        valida = this.files.every( (item) => {
          if (item.etiquetas) {
            return true;
          } else {
            return false;
          }
        });
        if (!valida) {
          this.toastr.warning('Es necesario que todas las imágenes contengan etiquetas.');
        }
      }
      if (valida) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  onSubmit() {
    this.validaImagenes().then( () => {
      this.archivosService.agregarAlbum(this.album).subscribe( (respuestaAlbum) => {
        this.album = respuestaAlbum.album;
        this.files = this.files.filter( item => !item.loaded);
        this.files.forEach( (item, index, ar) => {
          item.loading = true;
          item.album = this.album.id;
          item.desAlbum = this.album.nombre;
          this.archivosService.addFileTransferImage(item, this.album).subscribe(
            (respuesta) => {
            if (respuesta.progress) {
              item.progressTotal = respuesta.total;
              item.progress = respuesta.progress;
              if (item.progress >= item.progressTotal) {
                item.loaded = true;
                item.loading = false;
              }
              // item.progress = Math.round(respuesta.progress / respuesta.total * 100);
            }
            // if (respuesta.data) {
            //   setTimeout(() => {
            //     item.progress = 0;
            //   }, 1500);
            // }
          }, error => {
            item.error = true;
            item.loaded = true;
            item.loading = false;
          }, () => {
            item.progress = item.progressTotal;
            item.loaded = true;
            item.loading = false;
            if (ar.every(element => element.loaded)) {
              if (ar.every(element => !element.error)) {
                this.store.dispatch(new usuariosActions.UpdateGaleriaRepositorioAlbumLastImage(this.album));
                setTimeout( () => {
                  this.router.navigate(['auth/repositorios']);
                }, 100);
              } else {
                this.toastr.error('Ocurrio un error');
              }
            }
          });
        });
      });
    });
  }

  progressPorcent(item: GaleriaRepositorio): number {
    if (item.progress && item.progressTotal) {
      return Math.round(item.progress / item.progressTotal * 100);
    } else {
      return 0;
    }
  }




  quitarTodas() {
    this.files = [];
  }

}
