import { SortOrder } from './sort';

export class GaleriaRepositorio {
  public id: number;
  public nombre: string;
  public album: number;
  public desAlbum: string;
  public etiquetas: string;
  public type: string;
  public size: number;
  public name: string;
  public path: string;
  public thumbnail: string;
  public imagen: string;
  public fecArchivo: Date;
  public fecRegistro: Date;
  public fecActualizo: Date;
  public usuarioRegistro: number;
  public desUsuarioRegistro: string;
  public usuarioModifico: number;
  public desUsuarioModifico: string;
  public file: File;
  public urlPath: any;
  public progress: number;
  public progressTotal: number;
  public loaded: boolean;
  public loading: boolean;
  public error: boolean;
  public editar: boolean;
  public etiquetasNuevo: string;
  constructor(
    obj: ObjGaleriaRepositorio
  ) {
    this.id = obj && obj.id ? obj.id : 0;
    this.nombre = obj && obj.nombre ? obj.nombre : '';
    this.album = obj && obj.album ? obj.album : 0;
    this.desAlbum = obj && obj.desAlbum ? obj.desAlbum : '';
    this.etiquetas = obj && obj.etiquetas ? obj.etiquetas : '';
    this.type = obj && obj.type ? obj.type : null;
    this.size = obj && obj.size ? obj.size : 0;
    this.path = obj && obj.path ? obj.path : '';
    this.thumbnail = obj && obj.thumbnail ? obj.thumbnail : '';
    this.imagen = obj && obj.imagen ? obj.imagen : '';
    this.name = obj && obj.name ? obj.name : '';
    this.fecArchivo = obj && obj.fecArchivo ? obj.fecArchivo : null;
    this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : new Date();
    this.fecActualizo = obj && obj.fecActualizo ? obj.fecActualizo : null;
    this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : null;
    this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : null;
    this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : null;
    this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico : null;
    this.file = obj && obj.file ? obj.file : null;
    this.urlPath = obj && obj.urlPath ? obj.urlPath : null;
    this.progress = obj && obj.progress ? obj.progress : 0;
    this.progressTotal = obj && obj.progressTotal ? obj.progressTotal : 0;
    this.loaded = obj && obj.loaded ? obj.loaded : false;
    this.loading = obj && obj.loading ? obj.loading : false;
    this.error = obj && obj.error ? obj.error : false;
    this.editar = obj && obj.editar ? obj.editar : false;
    this.etiquetasNuevo = obj && obj.etiquetasNuevo ? obj.etiquetasNuevo : '';
  }
}

interface ObjGaleriaRepositorio {
  id: number;
  nombre: string;
  album: number;
  desAlbum: string;
  etiquetas: string;
  type: string;
  size: number;
  path: string;
  thumbnail: string;
  imagen: string;
  name: string;
  fecArchivo: Date;
  fecRegistro: Date;
  fecActualizo: Date;
  usuarioRegistro: number;
  desUsuarioRegistro: string;
  usuarioModifico: number;
  desUsuarioModifico: string;
  file: File;
  urlPath: any;
  progress: number;
  progressTotal: number;
  loaded: boolean;
  loading: boolean;
  error: boolean;
  editar: boolean;
  etiquetasNuevo: string;
}

export const ArchivosGeneralSortType: SortOrder[] = [
  { id: '+nombre', text: 'Nombre de Archivo'},
  { id: '-nombre', text: 'Nombre de Archivo desc'},
  { id: '+tipo', text: 'Tipo de Archivo'},
  { id: '-tipo', text: 'Tipo de Archivo desc'},
  { id: '+fecRegistro', text: 'Fecha de alta'},
  { id: '-fecRegistro', text: 'Fecha de alta desc'}
];
