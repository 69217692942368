import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, Identity, UserType, UsuariosSortType, FilterType } from 'src/app/models/user';
import { MESSAGES } from 'src/app/models/messages';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { GENERALES } from 'src/app/models/generales';
import * as usuariosActions from 'src/app/store/actions';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GLOBAL } from 'src/app/models/global';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from 'src/app/models/pagination';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { AnyScopePipe } from '../../../../pipes/any-scope.pipe';
import { ExcelService } from '../../../../services/excel.service';
import { MultiOpcionesSelectedPipe } from '../../../../pipes/multi-opciones-selected.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.sass'],
  providers: []
})
export class ListUsersComponent implements OnInit, OnDestroy {
  public url: string;
  public status: string;
  public page: number;
  public nextPage: number;
  public prevPage: number;
  public total: number;
  public pages: number;
  public usuarios: User[] = [];
  public table: any;
  public loading: boolean;
  public paginacion: Pagination = new Pagination(null);
  public identity: Identity = new Identity(null);
  public filterTipos: FilterType[] = [];
  public departamentos: FilterType[] = [];
  public tipoSearch = 0;
  heading = 'Información de usuarios';
  subheading = 'Contenido de usuarios activos en el sistema';
  icon = 'pe-7s-users icon-gradient bg-dark';
  cargando: boolean;
  subscriptionUsuarios: Subscription = new Subscription();
  subscriptionAuth: Subscription = new Subscription();
  subcriptionCatalogo: Subscription = new Subscription();
  subcriptionConsulta: Subscription = new Subscription();
  public orderType = [...UsuariosSortType];
  public isCollapsed = false;
  public filterActive = false;
  public listSearch: string[] = [];

  constructor(
    private userService: UsersService,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private usuariosService: UsuariosService,
    private excelService: ExcelService,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacion.sortType = '-fecRegistro';
    console.log('Inicializo componente Usuarios...');
  }

  ngOnInit() {
    this.subscriptionUsuarios = this.store.select('usuarios').subscribe(
      (state: any) => {
        this.usuarios = [...state.usuarios];
      }
    );
    this.subscriptionAuth = this.store.select('auth').subscribe(
      (state: any) => {
        this.identity = state.identity;
      }
    );
    this.subcriptionCatalogo = this.store.select('catalogos').subscribe(
      (state: any) => {
        this.filterTipos = [...state.tipos.map( item => {
          item.checked = false;
          return item;
        })];
      }
    );
    this.subcriptionConsulta = this.store.select('consulta').subscribe(
      (state: any) => {
        this.departamentos = [...state.departamentos.map( item => {
          item.checked = false;
          return item;
        })];
      }
    );
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscriptionUsuarios.unsubscribe();
    this.subcriptionCatalogo.unsubscribe();
    this.subscriptionAuth.unsubscribe();
    this.subcriptionConsulta.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.CargarUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaDepartamentos());
    this.store.dispatch(new usuariosActions.LoadTypeUsers());
  }

  deleteUser(userDelete: number, index: number) {
    this.loading = true;
    Swal.fire({
      title: '¿Deseas eliminar el usuario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: MESSAGES.C_BTN_WARNING,
      confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
      cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
      focusConfirm: false,
      focusCancel: true,
      animation: true,
      showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        return new Promise((resolve) => {
          this.userService.deleteUser(userDelete).subscribe(
            (response) => {
              this.store.dispatch(new usuariosActions.EliminarUsuariosSuccess(userDelete));
              resolve(response);
            }, (error) => {
              this.store.dispatch(new usuariosActions.EliminarUsuariosFail(error));
              Swal.showValidationMessage('Ocurrió un error en la operación');
              resolve(false);
            }
          );
        });
      }
    }).then( (resul) => {
      if (resul.value) {
        this.store.dispatch(new usuariosActions.CargarNotificacionesMini(1, 20, 0));
        Swal.fire({
          title: MESSAGES.M_TIT_SUCCESS,
          timer: 1200,
          showConfirmButton: false,
          icon: 'success',
          onOpen: () => {}
        }).then(() => {}, () => {});
      } else {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      }
      this.loading = false;
    }, (error) => {
      Swal.fire({
        title: MESSAGES.M_TIT_CANCELADO,
        timer: 1200,
        showConfirmButton: false,
        icon: 'error',
        onOpen: () =>  {}
      }).then(() => {}, () => {});
      this.loading = false;
    });

  }

  editarUsuario(index: number, user: User) {
    this.router.navigate(['/auth/users/register', user.id]);
  }

  sort(descriptionSort: string) {
    if (descriptionSort === this.paginacion.sortType) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
    } else {
      this.paginacion.sortReverse = false;
    }
    this.paginacion.sortType = descriptionSort;
  }

  eliminarUsuario(user: User) {
    if (user.id > 0) {
      Swal.fire({
        title: '¿Deseas eliminar el usuario?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: MESSAGES.C_BTN_WARNING,
        confirmButtonText: MESSAGES.M_BTN_ACTUALIZA,
        cancelButtonText: MESSAGES.M_BTN_CANCEL_A,
        focusConfirm: false,
        focusCancel: true,
        animation: true,
        showClass: { popup: 'animated zoomIn' }, hideClass: { popup: 'animated zoomOut' },
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          return new Promise((resolve) => {
            this.usuariosService.deleteUser(user.id).subscribe(
              (respuesta) => {
                this.store.dispatch(new usuariosActions.EliminarUsuariosSuccess(user.id));
                resolve(respuesta);
              }, (error) => {
                this.store.dispatch(new usuariosActions.EliminarUsuariosFail(error));
                Swal.showValidationMessage('Ocurrió un error en la operación');
                resolve(null);
              }
            );
          });
        }
      }).then( (resul) => {
        if (resul.value) {
          this.store.dispatch(new usuariosActions.CargarNotificacionesMini(1, 20, 0));
          Swal.fire({
            title: MESSAGES.M_TIT_SUCCESS,
            timer: 1200,
            showConfirmButton: false,
            icon: 'success',
            onOpen: () => {}
          }).then(() => {}, () => {});
        } else {
          Swal.fire({
            title: MESSAGES.M_TIT_CANCELADO,
            timer: 1200,
            showConfirmButton: false,
            icon: 'error',
            onOpen: () =>  {}
          }).then(() => {}, () => {});
        }
      }, (error) => {
        Swal.fire({
          title: MESSAGES.M_TIT_CANCELADO,
          timer: 1200,
          showConfirmButton: false,
          icon: 'error',
          onOpen: () =>  {}
        }).then(() => {}, () => {});
      });
    }
  }

  printCSV() {
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const pipeAnyScope = new AnyScopePipe();
    const pipeMultiOpcionesSelected = new MultiOpcionesSelectedPipe();
    let lista = [...this.usuarios];
    lista = pipeAnyScope.transform(lista, this.paginacion.textSearch);
    lista = pipeMultiOpcionesSelected.transform(lista, { opciones: this.filterTipos, parametro: 'tipo'} );
    lista = pipeMultiOpcionesSelected.transform(lista, { opciones: this.departamentos, parametro: 'departamento'} );
    if (lista.length) {
      lista = lista.map( itemLista => {
        const obj: any = {};
        if (itemLista.fecRegistro) {
          itemLista.fecRegistro = new Date(itemLista.fecRegistro);
        } else {
          itemLista.fecRegistro = null;
        }
        obj.USUARIO = itemLista.usuario;
        obj.NOMBRES = itemLista.descripcion;
        obj.TIPO = itemLista.desTipo;
        obj.PUESTO = itemLista.puesto;
        obj.DEPARTAMENTO = itemLista.desDepartamento;
        obj.FECHA_REGISTRO = GENERALES.ddmmyyyy(itemLista.registro);
        return obj;
      });
      this.excelService.exportAsExcelFile(lista, 'Reporte de usuarios ' + i );
    }
  }

  changeTipos() {
    this.filterTipos = [...this.filterTipos];
  }

  changeDepartamentos() {
    this.departamentos = [...this.departamentos];
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.split(' ');
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
  }

}
