import { Component, OnInit, OnDestroy } from '@angular/core';

import { Notificacion, TipoNotificacion, OpcionesNotificacion } from 'src/app/models/notificacion';
import { GLOBAL } from 'src/app/models/global';
import { Subscription } from 'rxjs';
import { Identity, User, FilterType } from 'src/app/models/user';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
declare var $: any;
import * as usuariosActions from 'src/app/store/actions';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
  providers: [  NotificacionesService ]
})
export class NotificacionesComponent implements OnInit, OnDestroy {
  public title: string;
  public identity: Identity;
  private subscribeAuth: Subscription = new Subscription();
  private subscribeNotificacion: Subscription = new Subscription();
  private subscribeUsuario: Subscription = new Subscription();
  public notificaciones: Notificacion[];
  public loading = false;
  public url: string;
  private subscription: Subscription = new Subscription();
  public paginacion = new Pagination(null);
  public total = 0;
  public usuarioSearch = 0;
  public usuariosFilter: User[] = [];
  public isCollapsed = false;
  public filterTipos: FilterType[] = [];
  public filterTiposNotificaciones: FilterType[] = [];
  public tipoNotificacion = TipoNotificacion;
  public opcionesNotificaciones = [...OpcionesNotificacion.OPCIONES_NOTIFICACIONES];

  constructor(
    private notificacionService: NotificacionesService,
    private store: Store<AppState>
  ) {
    this.identity = new Identity(null);
    this.notificaciones = [];
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = state.identity;
      }
    );
    this.subscribeUsuario = this.store.select('usuarios').subscribe(
      (state) => {
        if (this.identity.id > 0) {
          const user = new User(null);
          user.id = this.identity.id;
          user.nombre = this.identity.nombre;
          user.usuario = this.identity.usuario;
          user.apellidoPaterno = this.identity.apellidos;
          user.descripcion = this.identity.nombre + ' ' + this.identity.apellidos;
          this.usuariosFilter = [
            user,
            ...state.usuarios
          ];
        } else {
          this.usuariosFilter = [...state.usuarios];
        }
      }
    );
    this.subscribeNotificacion = this.store.select('notificacion').subscribe(
      (state) => {
        this.notificaciones = state.notificaciones;
        this.total = state.total;
      }
    );
    this.title = 'Notificaciones';
    // this.store.dispatch(new usuariosActions.CargarNotificaciones(this.paginacion.page, this.paginacion.pageSize, this.usuarioSearch));
    this.inicializa();
    this.store.dispatch(new usuariosActions.CargarUsuarios());
    this.notificacionService.setLeidoAll().subscribe(
      (response) => {}, (error) => {}
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscribeAuth.unsubscribe();
    this.subscribeNotificacion.unsubscribe();
  }

  inicializa() {
    const consTipos = this.filterTiposNotificaciones.filter(item => item.checked).map(item => item.id).join(',');
    const consTiposUsuarios = this.filterTipos.filter(item => item.checked).map(item => item.id).join(',');
    this.store.dispatch(new usuariosActions.CargarNotificacionesFull(
      this.paginacion.page, this.paginacion.pageSize, this.usuarioSearch, consTipos, consTiposUsuarios, this.paginacion.textSearch));
  }

  showTipos(event) {
    this.filterTipos = [...event];
    this.inicializa();
  }

  showTiposNotificaciones(event) {
    this.filterTiposNotificaciones = [...event];
    this.inicializa();
  }

}
