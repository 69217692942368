import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { CargarNotificaciones, CargarNotificacionesFull } from '../actions';

@Injectable()
export class NotificacionesEffects {

  constructor(
    private actions$: Actions,
    public notificacionesService: NotificacionesService
  ) {}

  @Effect()
  cargarNotificaciones$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_NOTIFICACIONES ),
    switchMap( (action: CargarNotificaciones ) => {
      return this.notificacionesService.getNotificaciones(action.pag, action.limit, action.usuario)
        .pipe(
          map( (notificaciones) => {
            return new usuariosActions.CargarNotificacionesSuccess(notificaciones.notificaciones,
               notificaciones.total, notificaciones.sinLeer);
          }),
          catchError( error => of(new usuariosActions.CargarNotificacionesFail(error))  )
        );
    })
  );

  @Effect()
  cargarNotificacionesFull$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_NOTIFICACIONES_FULL ),
    switchMap( (action: CargarNotificacionesFull ) => {
      return this.notificacionesService.getNotificacionesFull(
        action.pag, action.limit, action.usuario, action.tipos, action.tiposUsuarios, action.search)
        .pipe(
          map( (notificaciones) => {
            return new usuariosActions.CargarNotificacionesSuccess(notificaciones.notificaciones,
               notificaciones.total, notificaciones.sinLeer);
          }),
          catchError( error => of(new usuariosActions.CargarNotificacionesFail(error))  )
        );
    })
  );

}
