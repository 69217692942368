import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GLOBAL } from 'src/app/models/global';
import { ArchivoGeneral } from '../models/archivo-general';
import { catchError } from 'rxjs/operators';
import { Directorio } from '../models/directorio';

@Injectable({
  providedIn: 'root'
})
export class ArchivosGeneralesService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  cargarArchivos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/archivos', {headers});
  }

  cargarArchivo(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/archivos/' + id, {headers});
  }

  eliminarArchivo(archivo: ArchivoGeneral): Observable<any> {
    const obj = {archivo};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.delete(this.url + '/archivos/' + archivo.id, {headers});
  }

  registrarArchivo(archivo: ArchivoGeneral): Observable<any> {
    const obj = {archivo};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/archivos', params, {headers});
  }

  cargarDirectorios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/archivos/directorios', {headers});
  }

  registrarDirectorio(padre: Directorio, directorio: Directorio): Observable<any> {
    const obj = {padre, directorio};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/archivos/directorios', params, {headers});
  }

  eliminarDirectorio(directorio: Directorio): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.delete(this.url + '/archivos/directorios/' + directorio.id, {headers});
  }

  addFileTransfers(files: any[]): Observable<any> {
    const formData: any = new FormData();
    formData.append('files', files);

    return this.http.post(this.url + '/archivos/files', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  addFileTransfer(file: ArchivoGeneral): Observable<any> {
    const response = { progress: 0, total: 0, data: {}, error: false};
    return new Observable(observer => {
      const formData: FormData = new FormData();
      const xhr = new XMLHttpRequest();
      formData.append('file', file.file);
      delete file.file; 
      const data = JSON.stringify(file);
      formData.append('datos', data);
      // const dataAlbum = JSON.stringify(album);
      // formData.append('album', dataAlbum);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // resolve(JSON.parse(xhr.response));
            // response.progress = 100;
            response.data = xhr.response;
            observer.next(response);
            observer.complete();
          } else {
            response.error = true;
            observer.error(xhr.response);
            // reject(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (e) => {
        response.progress = e.loaded;
        response.total = e.total;
        observer.next(response);
        // response.progress = e.total ? Math.round((100 * e.loaded) / e.total) : e.loaded,
      }

      xhr.open('POST', this.url + '/archivos/files', true);
      xhr.setRequestHeader('Authorization', `${localStorage.getItem('token')}`);
      xhr.send(formData);
      xhr.onload = () => {
        // response.progress = 100;
        observer.next(response);
        observer.complete();
      };
    });
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  handleEvent(e) {
    // log.textContent = log.textContent + `${e.type}: ${e.loaded} bytes transferred\n`;
    const texto = `${e.type}: ${e.loaded} bytes transferred\n`;
    return `${e.type}: ${e.loaded} bytes transferred\n`;
  }

  descargarDirectorio(id: number): Observable<any> {
    return this.http.get(this.url + '/archivos/download/directorios/' + id);
  }

  descargarArchivo(id: number): Observable<any> {
    return this.http.get(this.url + '/archivos/download/archivos/' + id);
  }

  moverArchivo(archivo: ArchivoGeneral, destino: Directorio): Observable<any> {
    const obj = {archivo, destino};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/archivos/mover/archivos', params, {headers});
  }

  moverCarpeta(directorio: Directorio, destino: Directorio): Observable<any> {
    const obj = {directorio, destino};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.post(this.url + '/archivos/mover/carpetas', params, {headers});
  }

}
