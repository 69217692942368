import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NuevaPlantilla } from 'src/app/models/campana';
import { PlantillasService } from 'src/app/services/plantillas.service';

@Component({
  selector: 'app-nuevaplantilla',
  templateUrl: './nuevaplantilla.component.html',
  styleUrls: ['./nuevaplantilla.component.scss']
})
export class NuevaplantillaComponent implements OnInit {

  public nuevaplantilla: NuevaPlantilla = new NuevaPlantilla(null);
  public isShown: boolean = false 

  constructor(private plantillasservices: PlantillasService,
              private router: Router,
              private toastr: ToastrService) {
    
   }

  ngOnInit(): void {
    
  }

  toggleShow() {

    this.isShown = ! this.isShown;
    
  }

  guardar(nuevaplantilla) {
    this.plantillasservices.guardarPlantilla(this.nuevaplantilla).subscribe(
      (respuesta: any) => {
        this.toastr.success('Registró la plantilla.');
        // Puedes cambiar de ruta o no hacer nada
        this.router.navigate(['/auth/marketing/plantillas']);
        // this.campana = respuesta.campana;
      }, err => {
        console.log(err);
      }
    );
  }
}
