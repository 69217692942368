
import {NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CreditCardImageDirective } from './credit-card-image.directive';
import { DecimalDirective } from './decimal.directive';
import { DecimalsDirective } from './decimals.directive';
import { ImagePreloadDirective } from './image-preload.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { PhoneInputDirective } from './phone-input.directive';
import { UpperFirstChartDirective } from './upper-first-chart.directive';
import { UpperFirstChartTextareaDirective } from './upper-first-chart-textarea.directive';
import { LowerChartDirective } from './lower-chart.directive';
import { UpperChartDirective } from './upper-chart.directive';
import { DragDropFileDirective } from './drag-drop-file.directive';
import { DragDropFolderFileDirective } from './drag-drop-folder-file.directive';
import { ValidarInputFormDirective } from './validar-input-form.directive';

@NgModule({
  declarations: [
    // DECORATIONS
    CreditCardImageDirective,
    DecimalDirective,
    DecimalsDirective,
    ImagePreloadDirective,
    OnlyNumberDirective,
    PhoneInputDirective,
    UpperFirstChartDirective,
    UpperFirstChartTextareaDirective,
    LowerChartDirective,
    UpperChartDirective,
    DragDropFileDirective,
    DragDropFolderFileDirective,
    ValidarInputFormDirective,
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    CreditCardImageDirective,
    DecimalDirective,
    DecimalsDirective,
    ImagePreloadDirective,
    OnlyNumberDirective,
    PhoneInputDirective,
    UpperFirstChartDirective,
    UpperFirstChartTextareaDirective,
    LowerChartDirective,
    UpperChartDirective,
    DragDropFileDirective,
    DragDropFolderFileDirective,
    ValidarInputFormDirective,
  ]
})
export class DecorationsModule { }
