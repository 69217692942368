<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="form-group ml-3">
        <input type="text" placeholder="Fecha" class="form-control d-none d-lg-block" placement="right" name="rangeFechasReportes" #rangeFechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
        [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
        <input type="text" placeholder="Fecha" class="form-control d-lg-none" name="rangeFechasReportes" #range FechasReportes="ngModel" bsDaterangepicker [(ngModel)]="dates"
        [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}" (bsValueChange)="onDateSelection($event)" autocomplete="off">
      </div>
    </div>
    <div class="row" *ngIf="identity.permisos">
      <div class="col-md-3" *ngIf="filterActive">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">Filtrar contenido</div>
            <div class="btn-actions-pane-right text-capitalize actions-icon-btn dropdown" ngbdropdown="">
              <div class="btn-actions-pane-right actions-icon-btn">
                <button type="button" class="btn-icon btn-icon-only btn btn-link" (click)="filterActive = false">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="p-3">
              <!-- <div class="form-group">
                <select class="form-control custom-select" name="pageSize" [(ngModel)]="paginacion.pageSize">
                  <option *ngFor="let pagt of paginacion.optionSize" [ngValue]="pagt.value">{{pagt.description}}</option>
                </select>
              </div> -->
              <div class="form-group">
                <label for="" class="control-label">Ordenar por</label>
                <select class="form-control custom-select" name="sortType" #sortType="ngModel" [(ngModel)]="paginacion.sortType">
                  <option *ngFor="let opt of orderType" [value]="opt.id">{{opt.text}}</option>
                </select>
              </div>
              <div class="v-options-menu vsm-default">
                <div class="vsm-list">
                  <div class="vsm-header">Opciones</div>
                  <app-option-filter-collapse [titulo]="'Etapas'" [lista]="filterEtapas" (regresa)="changeEtapas($event)"></app-option-filter-collapse>
                  <app-option-filter-collapse [titulo]="'Tipo negocio'" [lista]="filterTiposNegocios" (regresa)="changeTipoNegocio($event)"></app-option-filter-collapse>
                  <app-option-filter-collapse [titulo]="'Propietarios'" [lista]="filterPropietarios" (regresa)="changePropietarios($event)"></app-option-filter-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9" [ngClass]="{'col-md-12': !filterActive }">
        <div class="main-card mb-3 card card-shadow-primary">
          <div class="card-header">
            <div class="card-header-title">Oportunidades</div>
            <div class="btn-actions-pane-right mr-4">
              ({{(oportunidades | anyScope: paginacion.textSearch 
                                | multiOpcionesSelected : { opciones: filterTiposNegocios, parametro: 'tipoNegocio' }
                                | multiOpcionesSelected : { opciones: filterEtapas, parametro: 'etapa' }
                                | multiOpcionesSelected : { opciones: filterPropietarios, parametro: 'propietario' } ).length}} de {{(oportunidades).length}})
              <div class="btn-group ml-2">
                <div class="search-wrapper mb-2 mt-2" [class.active]="paginacion.isActive">
                  <div class="input-holder">
                    <input type="text" placeholder="Escribe para buscar..." class="search-input" name="searchOportunidades" id="searchOportunidades" #searchOportunidades="ngModel" [(ngModel)]="paginacion.textSearch" (ngModelChange)="changeTextSearch()">
                    <button class="search-icon" (click)="searchClick()"><span></span></button>
                  </div>
                  <button class="close" (click)="paginacion.isActive = false; paginacion.textSearch = ''; listSearch = [];"></button>
                </div>
                <button class="btn btn-icon btn-icon-only text-black-50" *ngIf="!filterActive" (click)="filterActive = true">
                  <span>Filtrar</span>
                  <i class="pe-7s-filter btn-icon-wrapper" style="font-size: 2em;"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <button type="button" class="m-2 btn-hover-shine btn-pill btn btn-light" *ngFor="let texto of listSearch" (click)="removerTextSearch(texto)">
              {{texto}}
              <i class="ml-1 fas fa-times"></i>
            </button>
            <div class="table-responsive">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Nombre de la oportunidad</th>
                    <th>Empresa</th>
                    <th>Importe</th>
                    <th>Fecha de creación</th>
                    <th>Fecha de cierre</th>
                    <th>Propietario de la oportunidad</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of oportunidadesTotal | multiOpcionesSelected : { opciones: filterEtapas, parametro: 'etapa' }">
                  <tr [style.background-color]="item.color">
                    <td (click)="item.collapsed = !item.collapsed" [attr.aria-expanded]="!item.collapsed" aria-controls="collapse" class="text-white">{{item.desEtapa}}</td>
                    <td (click)="item.collapsed = !item.collapsed" [attr.aria-expanded]="!item.collapsed" aria-controls="collapse"></td>
                    <td (click)="item.collapsed = !item.collapsed" [attr.aria-expanded]="!item.collapsed" aria-controls="collapse" class="text-white">{{ (item.oportunidad  | anyScope: paginacion.textSearch 
                                                                                                                                                                            | multiOpcionesSelected : { opciones: filterTiposNegocios, parametro: 'tipoNegocio' } 
                                                                                                                                                                            | multiOpcionesSelectedCount : { opciones: filterPropietarios, parametro: 'propietario', count: 'importe' }) 
                                                                                                                                                                            | currency:'MXN':'symbol'}}</td>
                    <td (click)="item.collapsed = !item.collapsed" [attr.aria-expanded]="!item.collapsed" aria-controls="collapse"></td>
                    <td (click)="item.collapsed = !item.collapsed" [attr.aria-expanded]="!item.collapsed" aria-controls="collapse"></td>
                    <td (click)="item.collapsed = !item.collapsed" [attr.aria-expanded]="!item.collapsed" aria-controls="collapse"></td>
                  </tr>  
                  <tr id="collapse" [ngbCollapse]="item.collapsed" *ngFor="let oportunidad of item.oportunidad  | anyScope: paginacion.textSearch 
                                                                                                                | orderBy: [paginacion.sortType] 
                                                                                                                | multiOpcionesSelected : { opciones: filterTiposNegocios, parametro: 'tipoNegocio' } 
                                                                                                                | multiOpcionesSelected : { opciones: filterPropietarios, parametro: 'propietario' } ">
                    <td>{{oportunidad.nombre}}</td>
                    <td>{{oportunidad.desEmpresa}}</td>
                    <td>{{oportunidad.importe | currency:'MXN':'symbol'}}</td>
                    <td>{{oportunidad.fecRegistro | amLocale: 'es' | amDateFormat: 'L'}}</td>
                    <td>{{ (oportunidad.fecha ?  ( oportunidad.fecha | amLocale: 'es' | amDateFormat: 'L' ) : '') }}</td>
                    <td>{{oportunidad.desPropietario}}</td>
                  </tr> 
                </tbody>
                <tfoot>
                  <tr [style.background-color]="'#6c757d'">

                    <td class="v-center text-white"><b>TOTALES</b></td>
                    <td></td>
                    <td class="text-white"><b>{{ (oportunidades | anyScope: paginacion.textSearch 
                                                                | multiOpcionesSelected : { opciones: filterEtapas, parametro: 'etapa' }
                                                                | multiOpcionesSelected : { opciones: filterPropietarios, parametro: 'propietario' } 
                                                                | multiOpcionesSelectedCount : { opciones: filterTiposNegocios, parametro: 'tipoNegocio', count: 'importe' }) 
                                                                | currency:'MXN':'symbol'}}</b></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="mt-2" *ngIf="identity.permisos.oportunidadesListVer">
            <div class="row p-2">
              <div class="col-sm-12">
                <div class="text-right">
                  <button type="button" (click)="printCSV()" class="btn btn-primary mb-2">
                    <i class="fa fa-file-excel-o"></i>&nbsp; Imprimir XLSX
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
