<!-- <pre>{{contacto | json}}</pre> -->
<div class="bg-animation" *ngIf="found">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container mx-auto">
      <a class="navbar-brand" href="#">
        <img class="center" style="height: 60px; width: auto;" src="https://www.seguridadenamerica.com.mx/noticias//img/site_logo.png?v=1.0.3" alt="">
      </a>
    </div>
  </nav>
  <div class="container mt-5" *ngIf="found">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <div class="text-center mb-3">Favor de actualizar sus datos, para enviarle información precisa, de acuerdo a su perfil.
        </div> 
        <form name="formAutoActualizacionCampana" #formAutoActualizacionCampana="ngForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <fieldset class="form-group required col-md-4">
              <label for="nombreSuscriptor" class="control-label">Nombre(s)</label>
              <input type="text" id="nombreSuscriptor" name="nombreSuscriptor" #nombreSuscriptor="ngModel" [(ngModel)]="contacto.nombre"
                class="form-control" maxlength="150" minlength="2" placeholder="" required/>
            </fieldset>
            <fieldset class="form-group required col-md-4">
              <label for="apellidoSuscriptor" class="control-label">Apellidos</label>
              <input type="text" id="apellidoSuscriptor" name="apellidoSuscriptor" #apellidoSuscriptor="ngModel" [(ngModel)]="contacto.apellido"
                class="form-control" maxlength="150" minlength="2" placeholder="" required/>
            </fieldset>
            <fieldset class="form-group required col-md-4">
              <label for="cumpleaniosSuscriptor" class="control-label">Fecha de nacimiento</label>
              <input type="text"class="form-control" id="cumpleaniosSuscriptor" name="cumpleaniosSuscriptor" #cumpleaniosSuscriptor="ngModel" bsDatepicker [(ngModel)]="contacto.cumpleanios" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off" required>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="empresaSuscriptor" class="control-label">Empresa</label>
              <input type="text" id="empresaSuscriptor" name="empresaSuscriptor" #empresaSuscriptor="ngModel" [(ngModel)]="contacto.desEmpresa"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group  col-md-4" [ngClass]="{'required': contacto.desEmpresa }">
              <label for="tipoEmpresaSuscriptor" class="control-label">Tipo de empresa</label>
              <ng-select [items]="clasificaciones"
                (change)="existeIndustria( true )"
                [disabled]="!contacto.desEmpresa"
                [required]="contacto.desEmpresa"
                bindLabel="nombre"
                bindValue="id"
                id="tipoEmpresaSuscriptor"  name="tipoEmpresaSuscriptor" #tipoEmpresaSuscriptor= "ngModel"
                [(ngModel)]="contacto.tipoEmpresa"  >
              </ng-select>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="fundacionSuscriptor" class="control-label">Fecha de fundación</label>
              <input type="text"class="form-control" id="fundacionSuscriptor" name="fundacionSuscriptor" [disabled]="!contacto.desEmpresa" #fundacionSuscriptor="ngModel" bsDatepicker [(ngModel)]="contacto.fundacion" [bsConfig]="{ isAnimated: true, containerClass:'theme-blue'}"  autocomplete="off">
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="cargoSuscriptor" class="control-label">Cargo</label>
              <input type="text" id="cargoSuscriptor" name="cargoSuscriptor" #cargoSuscriptor="ngModel" [(ngModel)]="contacto.cargo"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group required col-md-4">
              <label for="emailSuscriptor" class="control-label">Correo electrónico</label>
              <input type="text" id="emailSuscriptor" name="emailSuscriptor" #emailSuscriptor="ngModel" [(ngModel)]="contacto.email"
                class="form-control" maxlength="150" minlength="2" placeholder="" required/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="industriaSuscriptor" class="control-label">Industria</label>
              <ng-select [items]="industriasFiltradas"
                (blur)="existeClasificacion()"
                [disabled]="!contacto.desEmpresa"
                bindLabel="nombre"
                bindValue="id"
                id="industriaSuscriptor"  name="industriaSuscriptor" #industriaSuscriptor= "ngModel"
                [(ngModel)]="contacto.industria" >
              </ng-select>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="telefonoSuscriptor" class="control-label">Teléfono</label>
              <input type="text" id="telefonoSuscriptor" name="telefonoSuscriptor" #telefonoSuscriptor="ngModel" [(ngModel)]="contacto.telefono"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="paisSuscriptor" class="control-label">País</label>
              <ng-select [items]="paises"
                bindLabel="nombre"
                bindValue="id"
                id="paisSuscriptor"  name="paisSuscriptor" #paisSuscriptor= "ngModel"
                [(ngModel)]="contacto.pais" >
              </ng-select>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="desEstadoSuscriptor" class="control-label">Estado</label>
              <input type="text" id="desEstadoSuscriptor" name="desEstadoSuscriptor" #desEstadoSuscriptor="ngModel" [(ngModel)]="contacto.desEstado"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="celularSuscriptor" class="control-label">Celular/Móvil</label>
              <input type="text" id="celularSuscriptor" name="celularSuscriptor" #celularSuscriptor="ngModel" [(ngModel)]="contacto.celular"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="calleynumeroSuscriptor" class="control-label">Calle y número</label>
              <input type="text" id="calleynumeroSuscriptor" name="calleynumeroSuscriptor" #calleynumeroSuscriptor="ngModel" [(ngModel)]="contacto.calleynumero"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="coloniaSuscriptor" class="control-label">Colonia</label>
              <input type="text" id="coloniaSuscriptor" name="coloniaSuscriptor" #coloniaSuscriptor="ngModel" [(ngModel)]="contacto.colonia"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="codigoPostalSuscriptor" class="control-label">Código postal</label>
              <input type="text" id="codigoPostalSuscriptor" name="codigoPostalSuscriptor" #codigoPostalSuscriptor="ngModel" [(ngModel)]="contacto.codigoPostal"
                class="form-control" maxlength="5" minlength="4" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-4">
              <label for="desMunicipioSuscriptor" class="control-label">Municipio/Ciudad/Departamento</label>
              <input type="text" id="desMunicipioSuscriptor" name="desMunicipioSuscriptor" #desMunicipioSuscriptor="ngModel" [(ngModel)]="contacto.desMunicipio"
                class="form-control" maxlength="150" minlength="2" placeholder=""/>
            </fieldset>
            <fieldset class="form-group col-md-12 mb-3" *ngIf="contacto.desEmpresa && contacto.tipoEmpresa === 2">
              <label for="desMunicipioSuscriptor" class="control-label">Seleccione las especialidades que desarrolla su empresa</label>
              <div class="row col-md-6">
                <div class="col-lg-6">
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcAlarma" class="control-label">Alarmas</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcAlarma"
                          name="opcAlarma"
                          #opcAlarma="ngModel"
                          [(ngModel)]="contacto.permisos.alarma"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcAsociacion" class="control-label">Asociación</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcAsociacion"
                          name="opcAsociacion"
                          #opcAsociacion="ngModel"
                          [(ngModel)]="contacto.permisos.asociacion"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcBlindaje" class="control-label">Blindaje</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcBlindaje"
                          name="opcBlindaje"
                          #opcBlindaje="ngModel"
                          [(ngModel)]="contacto.permisos.blindaje"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcCableado" class="control-label">Cableado</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcCableado"
                          name="opcCableado"
                          #opcCableado="ngModel"
                          [(ngModel)]="contacto.permisos.cableado"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcCajaFuerte" class="control-label">Cajas fuertes</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcCajaFuerte"
                          name="opcCajaFuerte"
                          #opcCajaFuerte="ngModel"
                          [(ngModel)]="contacto.permisos.cajaFuerte"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcControlAcceso" class="control-label">Control de acceso</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcControlAcceso"
                          name="opcControlAcceso"
                          #opcControlAcceso="ngModel"
                          [(ngModel)]="contacto.permisos.controlAcceso"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcControlConfianza" class="control-label">Control de confianza</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcControlConfianza"
                          name="opcControlConfianza"
                          #opcControlConfianza="ngModel"
                          [(ngModel)]="contacto.permisos.controlConfianza"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcConsultoria" class="control-label">Consultoría</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcConsultoria"
                          name="opcConsultoria"
                          #opcConsultoria="ngModel"
                          [(ngModel)]="contacto.permisos.consultoria"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcCursoEntrenamiento" class="control-label">Cursos y entrenamiento</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcCursoEntrenamiento"
                          name="opcCursoEntrenamiento"
                          #opcCursoEntrenamiento="ngModel"
                          [(ngModel)]="contacto.permisos.cursosEntrenamiento"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcCustodiaMercancia" class="control-label">Custodia de mercancías</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcCustodiaMercancia"
                          name="opcCustodiaMercancia"
                          #opcCustodiaMercancia="ngModel"
                          [(ngModel)]="contacto.permisos.custodiaMercancia"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcDistribuidorMayorista" class="control-label">Distribuidor mayorista</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcDistribuidorMayorista"
                          name="opcDistribuidorMayorista"
                          #opcDistribuidorMayorista="ngModel"
                          [(ngModel)]="contacto.permisos.distribuidorMayorista"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcOtros" class="control-label">Otros</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcOtros"
                          name="opcOtros"
                          #opcOtros="ngModel"
                          [(ngModel)]="contacto.permisos.otros"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div class="col-lg-6">
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcEquipamientoPolicia" class="control-label">Equipamiento policiaco</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcEquipamientoPolicia"
                          name="opcEquipamientoPolicia"
                          #opcEquipamientoPolicia="ngModel"
                          [(ngModel)]="contacto.permisos.equipamientoPolicia"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcEquipoInspeccion" class="control-label">Equipo de inspección</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcEquipoInspeccion"
                          name="opcEquipoInspeccion"
                          #opcEquipoInspeccion="ngModel"
                          [(ngModel)]="contacto.permisos.equipoInspeccion"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcGuardia" class="control-label">Guardias</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcGuardia"
                          name="opcGuardia"
                          #opcGuardia="ngModel"
                          [(ngModel)]="contacto.permisos.guardia"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcEscolta" class="control-label">Escoltas</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcEscolta"
                          name="opcEscolta"
                          #opcEscolta="ngModel"
                          [(ngModel)]="contacto.permisos.escolta"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcIncendio" class="control-label">Incendios</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcIncendio"
                          name="opcIncendio"
                          #opcIncendio="ngModel"
                          [(ngModel)]="contacto.permisos.incendio"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcIntegrador" class="control-label">Integrador de tecnología</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcIntegrador"
                          name="opcIntegrador"
                          #opcIntegrador="ngModel"
                          [(ngModel)]="contacto.permisos.integradores"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcInvestigacion" class="control-label">Investigaciones</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcInvestigacion"
                          name="opcInvestigacion"
                          #opcInvestigacion="ngModel"
                          [(ngModel)]="contacto.permisos.investigacion"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcRastreoVehicular" class="control-label">Rastreo vehicular</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcRastreoVehicular"
                          name="opcRastreoVehicular"
                          #opcRastreoVehicular="ngModel"
                          [(ngModel)]="contacto.permisos.rastreoVehicular"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcTelecomTI" class="control-label">Telecom y TI</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcTelecomTI"
                          name="opcTelecomTI"
                          #opcTelecomTI="ngModel"
                          [(ngModel)]="contacto.permisos.telecomTI"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcTrasladoValor" class="control-label">Traslado de valores</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcTrasladoValor"
                          name="opcTrasladoValor"
                          #opcTrasladoValor="ngModel"
                          [(ngModel)]="contacto.permisos.trasladoValor"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="opcVideovigilancia" class="control-label">Videovigilancia</label>
                      </div>
                      <div class="col-sm-6">
                        <bSwitch
                          id="opcVideovigilancia"
                          name="opcVideovigilancia"
                          #opcVideovigilancia="ngModel"
                          [(ngModel)]="contacto.permisos.videovigilancia"
                          [switch-size]="'normal'"
                          [switch-on-text]="'Si'"
                          [switch-off-text]="'No'"
                          [switch-on-color]="'primary'"
                          [switch-off-color]="'danger'"
                        ></bSwitch>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <fieldset class="form-group required col-10" *ngIf="contacto.permisos.otros">
                  <label for="opcDescripcion" class="control-label">Especifique</label>
                  <input type="text" id="opcDescripcion" name="opcDescripcion" #opcDescripcion="ngModel" [(ngModel)]="contacto.permisos.descripcion"
                    class="form-control" maxlength="100" minlength="2" placeholder="" title="Especifique la especialidad en seguridad." required/>
                </fieldset>
              </div>
            </fieldset>
          </div>
          <div class="row mt-2">
            <div class="col-6 mx-auto">
              <button style="font-size: 25px;" class="btn btn-block btn-lg btn-pill btn-lg btn-success pull-right" *ngIf="!loading" [disabled]="!formAutoActualizacionCampana.valid">Actualizar</button>
              <button style="font-size: 25px;" type="button" class="btn btn-block btn-pill btn-success pull-right" *ngIf="loading" [disabled]="true">Actualizando...</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="text-center opacity-8">
      <div class="row p-3">
        <div class="col-12">
          Editorial Seguridad en América, S. A. de C. V., con domicilio en Cto. Fundadores no. 14 int. 1, Cd. Satélite, Zona Verde, Estado de México, C. P. 53100
        </div>
      </div>
      <div class="row p-3 mb-3">
        <div class="col-6">
          <a class="text-gray" href="https://www.seguridadenamerica.com.mx/noticias/formulario/14736/template-politicas-de-privacidad" target="_blank">Políticas de privacidad</a>
        </div>
        <div class="col-6">
          <a href="https://www.seguridadenamerica.com.mx/noticias/formulario/14134/contacto-sea" target="_blank">Contacto</a>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-5" *ngIf="!found">
    <div class="main-card mb-3 my-auto card">
      <div class="card-body">
        <h3 class="text-center">Lo sentimos tu solicitud no está disponible</h3>
        <div class="text-center text-muted">
          <p>Te solicitamos verifiques el link que este correctamente o utilizar uno más reciente ya que posiblemente no es válido.</p>
        </div>
      </div>
    </div>
    <div class="text-center opacity-8">
      <div class="row p-3">
        <div class="col-12">
          Editorial Seguridad en América, S. A. de C. V., con domicilio en Cto. Fundadores no. 14 int. 1, Cd. Satélite, Zona Verde, Estado de México, C. P. 53100
        </div>
      </div>
      <div class="row p-3 mb-3">
        <div class="col-6">
          <a class="text-gray" href="https://www.seguridadenamerica.com.mx/noticias/formulario/14736/template-politicas-de-privacidad" target="_blank">Políticas de privacidad</a>
        </div>
        <div class="col-6">
          <a href="https://www.seguridadenamerica.com.mx/noticias/formulario/14134/contacto-sea" target="_blank">Contacto</a>
        </div>
      </div>
    </div>
  </div>
</div>
