import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as usuariosActions from '../actions';

import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { TicketsService } from 'src/app/services/tickets.services';

@Injectable()
export class TicketEffects {
  public mensaje: number;
  constructor(
    private actions$: Actions,
    public ticketsService: TicketsService
  ) {}

  @Effect()
  cargarTickets$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_TICKETS ),
    switchMap( () => {
      return this.ticketsService.obtenerTickets()
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarTicketsSuccess(respuesta.tickets);
          }),
          catchError( error => of(new usuariosActions.CargarTicketsFail(error))  )
        );
    })
  );

  @Effect()
  cargarTicket$ = this.actions$.pipe(
    ofType( usuariosActions.CARGAR_TICKET ),
    switchMap( (action: any) => {
      return this.ticketsService.obtenerDatoTicket(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.CargarTicketSuccess(respuesta.ticket);
          }),
          catchError( error => of(new usuariosActions.CargarTicketFail(error))  )
        );
    })
  );

  @Effect()
  actualizaEstatusTicket$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_TICKET_ESTATUS ),
    switchMap( (action: any) => {
      return this.ticketsService.actualizaEstatusTicket(action.id)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaTicketEstatusSuccess(action.id, action.status);
            }),
            catchError( error => of(new usuariosActions.ActualizaTicketEstatusFail(error))  )
        );
    })
  );

  @Effect()
  actualizaTicket$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_TICKET ),
    switchMap( (action: any) => {
      return this.ticketsService.registraTicket(action.ticket)
        .pipe(
            map( respuesta => {
              return new usuariosActions.ActualizaTicketSuccess(action.ticket);
            }),
            catchError( error => of(new usuariosActions.ActualizaTicketFail(error))  )
        );
    })
  );

	@Effect()
  actualizaMensajeTicket$ = this.actions$.pipe(
    ofType( usuariosActions.ENVIA_MENSAJE_TICKET ),
    switchMap( (action: any) => {
      return this.ticketsService.registraMensajeTicket(action.mensaje)
        .pipe( 
            map (respuesta => {
              this.mensaje = respuesta.mensaje.id;
              if(action.mensaje.adjuntos.length){
                this.mensaje = action.mensaje.ticket;
                return new usuariosActions.RegistrarArchivoMensaje(action.mensaje.adjuntos, action.mensaje.ticket, respuesta.mensaje.id);
              }else{
                return new usuariosActions.ActualizaMensajeTicket( action.mensaje.ticket);
              }
            }),
            catchError( error => of(new usuariosActions.RegistrarArchivoFail(error))  )
        );
    })
  );



  @Effect()
  actualizaMensajesTicket$ = this.actions$.pipe(
    ofType( usuariosActions.ACTUALIZA_MENSAJE_TICKET ),
    switchMap( (action: any) => {
      return this.ticketsService.actualizaMensajesTicket(action.id)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ActualizaMensajeTicketSuccess(respuesta.ticket);
          }),
          catchError( error => of(new usuariosActions.ActualizaMensajeTicketFail(error))  )
        );
    })
  );

  @Effect()
  cargarMensajes$ = this.actions$.pipe(
    ofType( usuariosActions.REGISTRAR_ARCHIVOS_MENSAJE ),
    switchMap( (action: any) => {
      return this.ticketsService.addFileTransfersMensaje(action.adjuntos, action.ticket, action.mensaje)
        .pipe(
          map( (respuesta) => {
            return new usuariosActions.ActualizaMensajeTicket(this.mensaje);
          }),
          catchError( error => of(new usuariosActions.RegistrarArchivoMensajeFail(error))  )
        );
    })
  );

}
