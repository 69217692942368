import {Component, HostListener, OnInit, OnDestroy} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { AppState } from 'src/app/store/app.reducer';
import { Store } from '@ngrx/store';
import { Identity } from 'src/app/models/user';
import * as usuariosActions from 'src/app/store/actions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  public extraParameter: any;
  public identity: Identity;
  private subscripcion = new Subscription();
  public ocultar = true;

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {
    this.identity = new Identity(null);
  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1600) {
        this.globals.toggleSidebar = true;
      }
    });

    if ( this.activatedRoute.snapshot.firstChild
       && this.activatedRoute.snapshot.firstChild.data
       && this.activatedRoute.snapshot.firstChild.data.extraParameter) {
      this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
    } else {
      this.extraParameter = '';
    }

    this.subscripcion = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
      }
    );

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1600) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

  ngOnDestroy() {
    this.subscripcion.unsubscribe();
  }

  logout() {
    this.store.dispatch( new usuariosActions.UnsetAuthAction() );
  }

}
