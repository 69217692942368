import { Catalogo } from './catalogo';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class Actividad{
	public id: number;
	public descripcion: string;
	public concepto: number;
	public status: boolean;
	public fecRegistro: Date;
	public usuarioRegistro: number;
	public desUsuarioRegistro: string;
	public fecModifico: Date;
	public usuarioModifico: number;
	public desUsuarioModifico: string;
	public contactos: Catalogo[];
	public empresa: number;
	public desEmpresa: string;
	public oportunidades: Catalogo[];
	public resultado: string;
	public fecha: Date;
	public hora: NgbTimeStruct;
	public duracion: NgbTimeStruct;
	constructor(
		obj: DataObjActividad
	){
		this.id = obj && obj.id ? obj.id : 0;
		this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
		this.concepto = obj && obj.concepto ? obj.concepto : 0;
		this.contactos = obj && obj.contactos ? obj.contactos : [];
		this.empresa = obj && obj.empresa ? obj.empresa : null;
		this.status = obj && obj.status ? obj.status: false;
		this.desEmpresa = obj && obj.desEmpresa ? obj.desEmpresa :'';
		this.oportunidades = obj && obj.oportunidades ? obj.oportunidades : [];
		this.status = obj && obj.status ? obj.status: false;
		this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : new Date ();
		this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : 0;
		this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : '';
		this.fecModifico = obj && obj.fecModifico ? obj.fecModifico : new Date ();
		this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : 0;
		this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico: '';
		this.resultado = obj && obj.resultado ? obj.resultado : null;
		const date = new Date();
		this.fecha = obj && obj.fecha ? obj.fecha : null;
		this.hora = obj && obj.hora ? obj.hora : { hour: 0, minute: 0, second: 0 };
		this.duracion = obj && obj.duracion ? obj.duracion : { hour: 0, minute: 0, second: 0 };
	}
}

interface DataObjActividad{
id: number;
descripcion: string;
concepto: number;
status: boolean;
fecRegistro: Date;
usuarioRegistro: number;
desUsuarioRegistro: string;
fecModifico: Date;
usuarioModifico: number;
desUsuarioModifico: string;
contactos: Catalogo[];
empresa: number;
desEmpresa: string;
oportunidades: Catalogo[];
resultado: string;
fecha: Date;
hora: NgbTimeStruct;
duracion: NgbTimeStruct;
}

export const LlamadaCatalogo: Catalogo[] = [
	{ id: 1, nombre: 'Programada', status: true},
	{ id: 2, nombre: 'Ocupado', status: true},
	{ id: 3, nombre: 'Contactado', status: true},
	{ id: 4, nombre: 'Dejó un mensaje de voz', status: true},
	{ id: 5, nombre: 'Sin respuesta', status: true},
	{ id: 6, nombre: 'Número incorrecto', status: true}
];

export const ReunionCatalogo: Catalogo[] = [
	{ id: 2, nombre: 'Ninguna', status: true},
	{ id: 1, nombre: 'Programada', status: true},
	{ id: 3, nombre: 'Completadas', status: true},
	{ id: 4, nombre: 'No asistió', status: true},
	{ id: 5, nombre: 'Canceladas', status: true},
	{ id: 6, nombre: 'Reprogramada', status: true},
];

export const CorreoCatalogo: Catalogo[] = [
	{ id: 1, nombre: 'Programada', status: true},
	{ id: 2, nombre: 'Enviado', status: true}
];

// export class Actividad{
// 	public id: number;
// 	public descripcion: string;
// 	public concepto: number;
// 	public actividad: Reuniones | Llamadas | Correos;
// 	public contactos: Catalogo[];
// 	public empresas: Catalogo[];
// 	public oportunidades: Catalogo[];
// 	public status: boolean;
// 	public fecRegistro: Date;
// 	public usuarioRegistro: number;
// 	public desUsuarioRegistro: string;
// 	public fecModifico: Date;
// 	public usuarioModifico: number;
// 	public desUsuarioModifico: string;

// 	constructor(
// 		obj: DataObjActividad
// 	){
// 		this.id = obj && obj.id ? obj.id :0
// 		this.descripcion = obj && obj.descripcion ? obj.descripcion : '';
// 		this.concepto = obj && obj.concepto ? obj.concepto : 0;
// 		this.actividad = obj && obj.actividad ? obj.actividad : null;
// 		this.contactos = obj && obj.contactos ? obj.contactos : [];
// 		this.empresas = obj && obj.empresas ? obj.empresas : [];
// 		this.oportunidades = obj && obj.oportunidades ? obj.oportunidades : [];
// 		this.status = obj && obj.status ? obj.status: false;
// 		this.fecRegistro = obj && obj.fecRegistro ? obj.fecRegistro : new Date ();
// 		this.usuarioRegistro = obj && obj.usuarioRegistro ? obj.usuarioRegistro : 0;
// 		this.desUsuarioRegistro = obj && obj.desUsuarioRegistro ? obj.desUsuarioRegistro : '';
// 		this.fecModifico = obj && obj.fecModifico ? obj.fecModifico : new Date ();
// 		this.usuarioModifico = obj && obj.usuarioModifico ? obj.usuarioModifico : 0;
// 		this.desUsuarioModifico = obj && obj.desUsuarioModifico ? obj.desUsuarioModifico: '';
// 	}
//   }
//   interface DataObjActividad{
// 	id: number;
// 	descripcion: string;
// 	concepto: number;
// 	actividad: Reuniones | Llamadas | Correos;
// 	contactos: Catalogo[];
// 	empresas: Catalogo[];
// 	oportunidades: Catalogo[];
// 	status: boolean;
// 	fecRegistro: Date;
// 	usuarioRegistro: number;
// 	desUsuarioRegistro: string;
// 	fecModifico: Date;
// 	usuarioModifico: number;
// 	desUsuarioModifico: string;

//   }


// export class Correos{
// 	public fecha: string;
// 	public hora: string;

// 	constructor(
// 		obj: DataObjCorreos
// 	){
// 		this.fecha = obj && obj.fecha ? obj.fecha : '';
// 		this.hora = obj && obj.hora ? obj.hora : '';
// 		}
//   }
//   interface DataObjCorreos{
// 	fecha: string;
// 	hora: string;
// 	}


// export class Llamadas{
// 	public resultado: string;
// 	public fecha: string;
// 	public hora: string;

// 	constructor(
// 		obj: DataObjLlamadas
// 	){
// 		this.resultado = obj && obj.resultado ? obj.resultado : '';
// 		this.fecha = obj && obj.fecha ? obj.fecha : '';
// 		this.hora = obj && obj.hora ? obj.hora : '';
// 		}
//   }
//   interface DataObjLlamadas{
// 	resultado: string;
// 	fecha: string;
// 	hora: string;
// 	}



// export class Reuniones{
// 	public resultado: string;
// 	public fecha: string;
// 	public hora: string;
// 	public duracion: string;

// 	constructor(
// 		obj: DataObjReuniones
// 	){
// 		this.resultado = obj && obj.resultado ? obj.resultado : '';
// 		this.fecha = obj && obj.fecha ? obj.fecha : '';
// 		this.hora = obj && obj.hora ? obj.hora : '';
// 		this.duracion = obj && obj.duracion ? obj.duracion : '';
// 		}
//   }
//   interface DataObjReuniones{
// 	resultado: string;
// 	fecha: string;
// 	hora: string;
// 	duracion: string;
// 	}
