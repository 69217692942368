import { Pipe, PipeTransform } from '@angular/core';

interface FilterItem {
  name: string;
  value: any;
}

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {

  transform(items: any[], args: FilterItem): any {
    if (!items) {
      return [];
    }
    if (!args.name) {
      return items;
    }
    return items.filter( it => {
      return it[args.name] === args.value;
    });
  }

}
