
import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { AppsLayoutComponent } from './apps-layout/apps-layout.component';
import { PagesLayoutComponent } from './pages-layout/pages-layout.component';
import { OptionsDrawerComponent } from '../themeoptions/options-drawer/options-drawer.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { HeaderComponent } from './components/header/header.component';
import { DotsComponent } from './components/header/elements/dots/dots.component';
import { MegamenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { MegapopoverComponent } from './components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import { UserBoxComponent } from './components/header/elements/user-box/user-box.component';
import { DrawerComponent } from './components/header/elements/drawer/drawer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LogoComponent } from './components/sidebar/elements/logo/logo.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterDotsComponent } from './components/footer/elements/footer-dots/footer-dots.component';
import { FooterMenuComponent } from './components/footer/elements/footer-menu/footer-menu.component';
import { FooterInfoComponent } from './components/footer/elements/footer-info/footer-info.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { LaddaModule } from 'angular2-ladda';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
// import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
// import { RoundProgressModule } from 'angular-svg-round-progressbar';
// import { MomentModule } from 'angular2-moment';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { CalendarModule } from 'angular-calendar';
// import { DateAdapter, MatCheckboxModule, MatProgressBarModule } from '@angular/material';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
// import { NouisliderModule } from 'ng2-nouislider';
import { ThemeOptions } from '../theme-options';
// import { TrendModule } from 'ngx-trend';
// import { ChartsModule } from 'ng2-charts';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MomentModule } from 'ngx-moment';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
import { DotsActividadesPendientesComponent } from './components/header/elements/dots-actividades-pendientes/dots-actividades-pendientes.component';
import { PipesModule } from 'src/app/pipes/pipes.module';


@NgModule({
  declarations: [

    // LAYOUT
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    DotsComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,
    DotsActividadesPendientesComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    FooterInfoComponent,
    TermsAndConditionsComponent,
    PoliticaPrivacidadComponent,
    PrivacyPolicyComponent,
    LoaderComponent,
    NotFoundComponent,

    // ThemeOptions,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    PipesModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    // LaddaModule,
    ReactiveFormsModule,
    // NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true
    }),
    // RoundProgressModule,
    MomentModule,
    // SweetAlert2Module.forRoot({
    //   buttonsStyling: false,
    //   customClass: 'modal-content',
    //   confirmButtonClass: 'btn btn-primary',
    //   cancelButtonClass: 'btn'
    // }),
    // ToastrModule.forRoot({
    //   timeOut: 10000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    // }),
    // SlickCarouselModule,
    // FlatpickrModule.forRoot(),
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    // SortablejsModule.forRoot({ animation: 150 }),
    // CountUpModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.apiKeyMaps
    // }),
    // ImageCropperModule,
    // AngularStickyThingsModule,
    // NouisliderModule,
    // NgSelectModule,
    // SelectDropDownModule,
    // NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    // AngularEditorModule,
    // HttpClientModule,
    // TextMaskModule,
    // ClipboardModule,
    // TextareaAutosizeModule,
    // ColorPickerModule,
    // DropzoneModule,

    // // Charts

    // ChartsModule,
    // NgApexchartsModule,
    // GaugeModule.forRoot(),
    // TrendModule,

    // // Angular Material Components


    // MatCheckboxModule,
    // MatButtonModule,
    // MatInputModule,
    // MatAutocompleteModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    // MatFormFieldModule,
    // MatRadioModule,
    // MatSelectModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatMenuModule,
    // MatSidenavModule,
    // MatToolbarModule,
    // MatListModule,
    // MatGridListModule,
    // MatCardModule,
    // MatStepperModule,
    // MatTabsModule,
    // MatExpansionModule,
    // MatButtonToggleModule,
    // MatChipsModule,
    // MatIconModule,
    // MatProgressSpinnerModule,
    MatProgressBarModule,
    // MatDialogModule,
    // MatTooltipModule,
    // MatSnackBarModule,
    // MatTableModule,
    // MatSortModule,
    // MatPaginatorModule,
    // MatTreeModule,
    // MatRippleModule,
  ],
  exports: [
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    DotsComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,
    DotsActividadesPendientesComponent,

    // SIDEBAR
    SidebarComponent,
    LogoComponent,

    // FOOTER
    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,
    FooterInfoComponent,
    TermsAndConditionsComponent,
    PoliticaPrivacidadComponent,
    PrivacyPolicyComponent,
    LoaderComponent,
    NotFoundComponent,
  ]
})
export class LayoutModule { }
