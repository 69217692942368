import * as moment from 'moment';
import { Catalogo } from './catalogo';

// tslint:disable-next-line:one-variable-per-declaration
export const GENERALES: any = {
  indexOfObject: (array: any[], attr: any, value: any, lower?: boolean) => {
    for (let i = 0; i < array.length; i += 1) {
      if (lower) {
        // tslint:disable-next-line:triple-equals
        if (array[i][attr].toLowerCase() == value.toLowerCase()) {
          return i;
        }
      } else {
        // tslint:disable-next-line:triple-equals
        if (array[i][attr] == value) {
          return i;
        }
      }
    }
    return -1;
  },
  indexOfObjectLessThat: (array: any[], attr: any, value: any) => {
    for (let i = 0; i < array.length; i += 1) {
      array[i][attr] = ( array[i][attr] !== '' && array[i][attr] != null ? array[i][attr] : 0 );
      if (parseFloat(array[i][attr]) < value) {
        return i;
      }
    }
    return -1;
  },
  indexOfObjectGreaterThat: (array: any[], attr: any, value: any) => {
    for (let i = 0; i < array.length; i++) {
      array[i][attr] = ( array[i][attr] !== '' && array[i][attr] != null ? array[i][attr] : 0 );
      if (parseFloat(array[i][attr]) > value) {
        return i;
      }
    }
    return -1;
  },
  getMaxOfObjectArray: (array: any[], attr: any) => {
    let value = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.length; i++) {
      array[i][attr] = ( array[i][attr] !== '' && array[i][attr] != null ? array[i][attr] : 0 );
      if (parseFloat(array[i][attr]) > value) {
        value = parseFloat(array[i][attr]);
      }
    }
    return value;
  },
  getMinOfObjectArray: (array: any[], attr: any) => {
    let value = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.length; i++) {
      array[i][attr] = ( array[i][attr] !== '' && array[i][attr] != null ? array[i][attr] : 0 );
      if (parseFloat(array[i][attr]) < value) {
        value = parseFloat(array[i][attr]);
      }
    }
    return value;
  },
  unixToDate: (x: string, format: string = 'YYYYMMDD'): Date => {
    return moment(x, format).toDate();
  },
  pad(str: string, max: number, char = '0') {
    str = str.toString();
    return str.length < max ? this.pad(char + str, max) : str;
  },
  minutos: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
    51, 52, 53, 54, 55, 56, 57, 58, 59
  ],
  months: [
    { id: 1, long: 'Enero', short: 'Ene', m: 1, mm: '01' },
    { id: 2, long: 'Febrero', short: 'Feb', m: 2, mm: '02' },
    { id: 3, long: 'Marzo', short: 'Mar', m: 3, mm: '03' },
    { id: 4, long: 'Abril', short: 'Abr', m: 4, mm: '04' },
    { id: 5, long: 'Mayo', short: 'May', m: 5, mm: '05' },
    { id: 6, long: 'Junio', short: 'Jun', m: 6, mm: '06' },
    { id: 7, long: 'Julio', short: 'Jul', m: 7, mm: '07' },
    { id: 8, long: 'Agosto', short: 'Ago', m: 8, mm: '08' },
    { id: 9, long: 'Septiembre', short: 'Sep', m: 9, mm: '09' },
    { id: 10, long: 'Octubre', short: 'Oct', m: 10, mm: '10' },
    { id: 11, long: 'Noviembre', short: 'Nov', m: 11, mm: '11' },
    { id: 12, long: 'Diciembre', short: 'Dic', m: 12, mm: '12' }
  ],
  ddmmyyyy: (date: Date) => {
    try {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      if (typeof date.getMonth === 'function') {
        const mm = date.getMonth() + 1;
        const dd = date.getDate();
        if (mm && dd) {
          return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            date.getFullYear()
          ].join('/');
        } else {
          return '';
        }
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  },
  hhmm: (date: Date) => {
    try {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      if (typeof date.getMonth === 'function') {
        const hh = date.getHours();
        const mm = date.getMinutes();
        if (hh && mm) {
          return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
          ].join(':');
        } else {
          return '';
        }
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  },
  tiposContribuyentes: [
    new Catalogo({ id: 1, nombre: 'Fisíca', status: true }),
    new Catalogo({ id: 2, nombre: 'Moral', status: true })
  ] as Catalogo[]
};

export class ChartStructure {
  constructor(
      public type: string  = '',
      public data: any[] = [],
      public labels: any[] = [],
      public legend: string = '',
      public colors: any[] = [],
      public options: any = {}
  ) {}
}

export class Archivo {
  constructor(
      public id: number,
      public folio: number,
      public nombre: string,
      public tipo: string,
      public size: number,
      public nameFile: string,
      public fecha?: Date
  ) {}
}
