import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as usuarioActions from '../actions/galeria-repositorio.actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Identity } from 'src/app/models/user';
import { GaleriaRepositorioService } from '../../services/galeria-repositorio.service';


@Injectable()
export class GaleriaRepositorioEffects {

  constructor(
    private actions$: Actions,
    public archivosService: GaleriaRepositorioService,
    protected local: StorageMap
  ) {}

  @Effect()
  cargarGaleriaRepositorioTodas$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_GALERIA_REPOSITORIO_TODAS ),
    switchMap( (action: any) => {
      return this.archivosService.cargarGaleria(action.etiquetas)
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarGaleriaRepositorioTodasSuccess(respuesta.archivos);
          }),
          catchError( error => of(new usuarioActions.CargarGaleriaRepositorioTodasFail(error))  )
        );
    })
  );

  @Effect()
  cargarGaleriaRepositorioAlbumes$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_GALERIA_REPOSITORIO_ALBUMES ),
    switchMap( (action: any) => {
      return this.archivosService.cargarAlbumes()
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarGaleriaRepositorioAlbumesSuccess(respuesta.albumes);
          }),
          catchError( error => of(new usuarioActions.CargarGaleriaRepositorioAlbumesFail(error))  )
        );
    })
  );

  @Effect()
  cargarGaleriaRepositorioAlbumImagenes$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_GALERIA_REPOSITORIO_ALBUM_IMAGENES ),
    switchMap( (action: any) => {
      return this.archivosService.cargarAlbumImagenes(action.id)
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarGaleriaRepositorioAlbumImagenesSuccess(respuesta.archivos, respuesta.album);
          }),
          catchError( error => of(new usuarioActions.CargarGaleriaRepositorioAlbumImagenesFail(error))  )
        );
    })
  );

  @Effect()
  cargarGaleriaRepositorioAlbum$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_GALERIA_REPOSITORIO_ALBUM ),
    switchMap( (action: any) => {
      return this.archivosService.cargarAlbum(action.id)
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarGaleriaRepositorioAlbumSuccess( respuesta.album);
          }),
          catchError( error => of(new usuarioActions.CargarGaleriaRepositorioAlbumFail(error))  )
        );
    })
  );

  @Effect()
  cargarImagenGaleriaRepositorio$ = this.actions$.pipe(
    ofType(usuarioActions.CARGAR_IMAGEN_GALERIA_REPOSITORIO ),
    switchMap( (action: any) => {
      return this.archivosService.cargarImagen(action.id)
        .pipe(
          map( (respuesta: any) => {
            return new usuarioActions.CargarImagenGaleriaRepositorioSuccess(respuesta.archivo);
          }),
          catchError( error => of(new usuarioActions.CargarImagenGaleriaRepositorioFail(error))  )
        );
    })
  );

  @Effect()
  registrarGaleriaRespositorio$ = this.actions$.pipe(
    ofType(usuarioActions.REGISTRAR_GALERIA_REPOSITORIO ),
    switchMap( (action: any) => {
      return this.archivosService.addFileTransfersGallery(action.archivos)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.RegistrarGaleriaRepositorioSuccess(respond.archivo);
          }),
          catchError( error => of(new usuarioActions.RegistrarGaleriaRepositorioFail(error))  )
        );
    })
  );

  @Effect()
  updateGaleriaRepositorioLastImage$ = this.actions$.pipe(
    ofType(usuarioActions.UPDATE_GALERIA_REPOSITORIO_ALBUM_LAST_IMAGE ),
    switchMap( (action: any) => {
      return this.archivosService.updateAlbumLastImage(action.album)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.UpdateGaleriaRepositorioAlbumLastImageSuccess(respond.album);
          }),
          catchError( error => of(new usuarioActions.UpdateGaleriaRepositorioAlbumLastImageFail(error))  )
        );
    })
  );

  @Effect()
  eliminarGaleriaRepositorioAlbum$ = this.actions$.pipe(
    ofType(usuarioActions.ELIMINAR_GALERIA_REPOSITORIO_ALBUM ),
    switchMap( (action: any) => {
      return this.archivosService.eliminarAlbum(action.album)
        .pipe(
          map( (respond: any) => {
            return new usuarioActions.EliminarGaleriaRepositorioAlbumSuccess(action.album);
          }),
          catchError( error => of(new usuarioActions.EliminarGaleriaRepositorioAlbumFail(error))  )
        );
    })
  );

}
