import { Component } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';
import { ConfigActions } from 'src/app/themeoptions/store/config.actions';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent {

  toggleOptionsDrawer() {
    this.globals.toggleThemeOptions = !this.globals.toggleThemeOptions;
  }

  toggleFixedFooter() {
    this.globals.toggleFixedFooter = !this.globals.toggleFixedFooter;
  }

  constructor(public globals: ThemeOptions,
              public configActions: ConfigActions) {
  }

}
