<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-6">
      <div class="app-logo-long mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-header">
            <div class="h5 modal-title">
              Forgot your Password?
              <h6 class="mt-1 mb-0 opacity-8">
                <span>Use the form below to recover it.</span>
              </h6>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <form #f="ngForm" (ngSubmit)="onSubmit(f.value)">
                <div class="row">
                  <div class="col-md-12">
                    <fieldset class="form-group" id="__BVID__132">
                      <div tabindex="-1" role="group">
                        <label for="exampleEmail">Email</label>
                        <input id="exampleEmail" name="email" type="email" appLowerChart placeholder="Email here..." class="form-control">
                      </div>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
            <div class="divider"></div>
            <h6 class="mb-0">
              <a [routerLink]="['/public/login']" class="text-primary">Sign in existing account</a>
            </h6>
          </div>
          <div class="modal-footer clearfix">
            <div class="float-right">
              <button type="submit" class="btn btn-primary btn-lg">Recover Password</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Jivabe Rating and Consulting {{year}}
      </div>
    </div>
  </div>
</div>
