import {Component, OnInit, OnDestroy} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import { Identity } from 'src/app/models/user';

// tslint:disable
@Component({
	selector: 'app-user-box',
	templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit, OnDestroy {

	nombre: string;
	identity: Identity;
	subscription: Subscription = new Subscription();
  url: string;
  public version = 1;

	toggleDrawer() {
		this.globals.toggleDrawer = !this.globals.toggleDrawer;
	}

	constructor(
		public globals: ThemeOptions,
		private store: Store<AppState>
	) {
		this.identity = new Identity(null);
		this.url = GLOBAL.url;
		this.nombre = '';
	}

	ngOnInit() {
		this.subscription = this.store.select('auth').subscribe(
			(state) => {
        const date = new Date();
        this.identity = {...state.identity};
        this.version = date.getTime();
			}
		);
		this.store.dispatch(new usuariosActions.GetAuthAction());
	}

	// fb_logout() {
	// 	this.ingresoEgresoService.cancelarSubscriptions();
	// }

	logout() {
		this.store.dispatch( new usuariosActions.UnsetAuthAction() );
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
