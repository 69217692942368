import { Component, OnInit, OnDestroy } from '@angular/core';
import { Identity, User } from 'src/app/models/user';
import { Subscription, Subject } from 'rxjs';
import { Contacto, ContactoSortType } from 'src/app/models/contacto';
import { Pagination } from 'src/app/models/pagination';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/app.reducer';
import { GLOBAL } from 'src/app/models/global';
import * as usuariosActions from 'src/app/store/actions';
import Swal from 'sweetalert2';
import { MESSAGES } from 'src/app/models/messages';
import { ContactosService } from 'src/app/services/contactos.service';
import {ExcelService} from 'src/app/services/excel.service';
import { FilterType } from 'src/app/models/user';
import { AnyScopePipe } from 'src/app/pipes/any-scope.pipe';
import { MultiOpcionesSelectedPipe } from 'src/app/pipes/multi-opciones-selected.pipe';
import { FiltroCheck, OptionCheckMultiplePipe } from 'src/app/pipes/option-check-multiple.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportesService } from 'src/app/services/reportes.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reporte-sepomex',
  templateUrl: './reporte-sepomex.component.html',
  styleUrls: ['./reporte-sepomex.component.scss']
})
export class ReporteSepomexComponent implements OnInit, OnDestroy  {
  heading = 'Reporte de SEPOMEX';
  subheading = 'Reporte de los contactos con envió SEPOMEX';
  icon = 'pe-7s-display2 icon-gradient bg-dark';
  private subscribeAuth: Subscription = new Subscription();
  private subscribeReporte: Subscription = new Subscription();
  private subscribeConsulta: Subscription = new Subscription();
  public identity: Identity;
  public loading = false;
  public url: string;
  public version = 1;
  public paginacion = new Pagination(null);
  public filterOrigenContacto: FilterType[] = [];
  public filterPropietarios: FilterType[] = [];
  public filterEtapas: FilterType[] = [];
  public filterPaises: FilterType[] = [];
  public filterEstatus: FilterType[] = [];
  public listSearch: string[] = [];
  public filterActive = false;
  public orderType = [...ContactoSortType];
  public contactos: Contacto[] = [];
  public downloading = false;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private excelService: ExcelService,
    private reportesService: ReportesService,
    private titleService: Title
  ) {
    this.titleService.setTitle(GLOBAL.title + this.heading);
    this.identity = new Identity(null);
    this.loading = false;
    this.url = GLOBAL.url;
    this.paginacion.sortType = '-id';
    this.paginacion.pageSize = 5;
  }

  ngOnInit(): void {
    this.subscribeAuth = this.store.select('auth').subscribe(
      (state) => {
        this.identity = {...state.identity};
        const date = new Date();
        this.version = date.getTime();
      }
    );
    this.subscribeReporte = this.store.select('reportes').subscribe(
      (state) => {
        this.contactos = [...state.sepomex];
        this.paginacion.totalSize = state.totalSepomex;
        this.paginacion.totalFiltro = state.totalFiltroSepomex;
      }
    );
    this.subscribeConsulta = this.store.select('consulta').subscribe(
      (state) => {
        this.filterEtapas = [...state.etapasContacto.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterEstatus = [...state.estadosContacto.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterOrigenContacto = [...state.origenContacto.filter( item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
        this.filterPropietarios = [...state.usuarios.map(item => {
          return new FilterType({id: item.id, nombre: item.descripcion, checked: false});
        })];
        this.filterPaises = [...state.paises.filter(item => item.status)].map(item => {
          return new FilterType({id: item.id, nombre: item.nombre, checked: false});
        });
      }
    );
    console.log('Inicializa el componente Reporte-sepomex ...');
    this.inicializa();
  }

  ngOnDestroy() {
    this.subscribeAuth.unsubscribe();
    this.subscribeReporte.unsubscribe();
    this.subscribeConsulta.unsubscribe();
  }

  inicializa() {
    this.store.dispatch(new usuariosActions.ConsultaPaises());
    this.store.dispatch(new usuariosActions.ConsultaOrigenContactos());
    this.store.dispatch(new usuariosActions.ConsultaUsuarios());
    this.store.dispatch(new usuariosActions.ConsultaEstadosContactos());
    this.store.dispatch(new usuariosActions.ConsultaEtapasContactos());
    this.cargarContactos();
  }

  changeTextSearch() {
    this.listSearch = this.paginacion.textSearch.trim().split(' ').filter( itm => itm);
    if (this.paginacion.textSearch.trim() !== this.paginacion.textSearchAnterior) {
      this.cargarContactos();
    }
  }

  removerTextSearch(text: string) {
    this.listSearch = this.listSearch.filter( item => item !== text );
    this.paginacion.textSearch = this.listSearch.join(' ');
    this.cargarContactos();
  }

  changeTipos() {
    this.filterOrigenContacto = [...this.filterOrigenContacto];
    this.cargarContactos();
  }

  changeEstatus() {
    this.filterEstatus = [...this.filterEstatus];
    this.cargarContactos();
  }

  changeEtapas() {
    this.filterEtapas = [...this.filterEtapas];
    this.cargarContactos();
  }

  changePaises() {
    this.filterPaises = [...this.filterPaises];
    this.cargarContactos();
  }

  changePropietarios() {
    this.filterPropietarios = [...this.filterPropietarios];
    this.cargarContactos();
  }

  searchClick() {
    this.paginacion.isActive = true;
    document.getElementById("searchContactos").focus();
  }

  printCSV() {
    this.downloading = true;
    const hoy = new Date();
    const i = hoy.toLocaleDateString().replace('/', '_');
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = 0;
    opciones.numLimit = null;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desOrigen = this.filterOrigenContacto.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desEstado = this.filterEstatus.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desEtapa = this.filterEtapas.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPropietario = this.filterPropietarios.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.reportesService.downloadExcelReporteSepomex(opciones).subscribe(
      respuesta => {
        this.excelService.exportAsExcelFile(respuesta.reporte, 'reporte_sepomex_' + i);
        this.downloading = false;
        // this.downloading = true;
        // // this.downloadState = respuesta;
        // if (respuesta.state === 'DONE') {
        //   setTimeout( () => {
        //     this.downloading = false;
        //   }, 2000 );
        // }
      }, error => {
        this.downloading = false;
    });
  }

  cargarContactos (reset = true) {
    if (reset) {
      this.paginacion.page = 1;
    }
    const opciones: any = {};
    this.paginacion.textSearchAnterior = '' + this.paginacion.textSearch.trim();
    opciones.numPage = this.paginacion.page;
    opciones.numLimit = this.paginacion.pageSize;
    // opciones.numLimit = 5;
    opciones.desOrderBy = this.paginacion.sortType;
    opciones.textSearch = this.listSearch.join('%');
    opciones.desOrigen = this.filterOrigenContacto.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desEstado = this.filterEstatus.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desEtapa = this.filterEtapas.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPais = this.filterPaises.filter( itm => itm.checked).map( itm => itm.id).join(',');
    opciones.desPropietario = this.filterPropietarios.filter( itm => itm.checked).map( itm => itm.id).join(',');
    this.store.dispatch(new usuariosActions.CargarReporteSepomex(opciones));
  }

  consultaDetalle(contacto: Contacto) {
    if (contacto.id && contacto.id > 0) {
      this.router.navigate(['/contacto/register/', contacto.id]);
    }
  }

  sort(descriptionSort: string) {
    if (('-' + descriptionSort) === this.paginacion.sortType || ('+' + descriptionSort) === this.paginacion.sortType ) {
      this.paginacion.sortReverse = !this.paginacion.sortReverse;
      if (this.paginacion.sortReverse) {
        this.paginacion.sortType = '-' + descriptionSort;
      } else {
        this.paginacion.sortType = '+' + descriptionSort;
      }
    } else {
      this.paginacion.sortType = '+' + descriptionSort;
      this.paginacion.sortReverse = false;
    }
    this.cargarContactos();
  }

}
