import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from 'src/app/models/global';

@Injectable({
  providedIn: 'root'
})
export class ConsultasService {
  public url: string;

  constructor(public http: HttpClient) {
    this.url = GLOBAL.url;
  }

  obtenerDatosFiscales(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/datos-fiscales', {headers});
  }

  obtenerTiposEmpresa(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/tipo-empresa', {headers});
  }

  obtenerIndustrias(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/industrias', {headers});
  }

  obtenerEmpresas(searchText: string, limite: number): Observable<any> {
    const obj = {opciones: {searchText, limite}};
    const params = JSON.stringify(obj);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.url + '/consultas/empresas/get', params, {headers});
  }

  obtenerContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/contactos', {headers});
  }

  obtenerUsuarios(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/usuarios', {headers});
  }

  obtenerContactosEmpresa(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/contactos/empresa/' + id, {headers});
  }

  obtenerOportunidadesEmpresa(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/oportunidades/empresa/' + id, {headers});
  }

  obtenerEtapas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/etapas', {headers});
  }

  obtenerPaises(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/paises', {headers});
  }

  obtenerClasificaciones(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/clasificaciones', {headers});
  }

  obtenerEstadosContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/estados/contactos', {headers});
  }

  obtenerEtapasContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/etapas/contactos', {headers});
  }

  obtenerOrigenContactos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/origen/contactos', {headers});
  }

  obtenerTiposOrigen(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/tipos/origen', {headers});
  }

  obtenerOportunidadesContacto(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/oportunidades/contacto/' + id, {headers});
  }

  obtenerDepartamentos(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/departamentos', {headers});
  }

  obtenerContactosOportunidad(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/contactos/oportunidad/' + id, {headers});
  }

  obtenerClasesEmpresas(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/clases/empresas', {headers});
  }

  obtenerOportunidadesGanadasPerdidasEmpresa(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/oportunidades/ganadas/perdidas/empresa/' + id, {headers});
  }

  obtenerOportunidadesGanadasPerdidasContacto(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json; charset=UTF-8');
    return this.http.get(this.url + '/consultas/oportunidades/ganadas/perdidas/contacto/' + id, {headers});
  }

  obtenerUsosCfdis(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/usos-cfdis', {headers});
  }

  obtenerEstadosPais(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/pais/' + id + '/estados', {headers});
  }

  obtenerPaisesPublic(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/paises/public', {headers});
  }

  obtenerIndustriasPublico(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/industrias/public', {headers});
  }

  obtenerClasificacionesPublico(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.url + '/consultas/clasificaciones/public', {headers});
  }

}
